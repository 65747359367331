import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {MenuProps} from "antd";
import Menu from "../../../../../../../components/Menu";
import {IAfterProcesses, IPropsChildren, IRoundPlan} from "../../../../../../../util/varibles/interface";
import {ROUND_JOB} from "../../../../../../../util/varibles/constants";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";
import PopupAddBufferTime from "./RoundJob/PopupAddBufferTime";
import RoundJob from "./RoundJob";
import {cloneObj} from "../../../../../../../util/varibles/global";
import {useRoundMap} from "../../../../../../../contexts/RoundMapContext";

export interface IProps extends IPropsChildren {
    roundId: string
    after_processes: IRoundPlan['after_processes']

    onUpdateAfterRound?(state: any): void

    getLocations?(): void
}

const Round: React.FC<IProps> = (props) => {
    const {
        roundId,
        after_processes,
        children,
        onUpdateAfterRound,
        getLocations
    } = props;
    const [{bufferTime, roundJobs}, setForm] = useState<{
        bufferTime?: IAfterProcesses,
        roundJobs: ({ index: number } & IAfterProcesses)[]
    }>({roundJobs: []});
    const [addLocation, setAddLocation] = useState(false);
    const {isEdit} = useRoundMap()

    useEffect(() => {
        let source: ({ index: number } & IAfterProcesses)[] = [], bufferTime, isGetWater = false;
        after_processes.forEach((item, index) => {
            const {type} = item;
            if (type === ROUND_JOB.BufferTime) {
                bufferTime = {...item, index};
            } else {
                if (type === ROUND_JOB.GetWater)
                    isGetWater = true;
                source.push({...item, index})
            }
        })
        setAddLocation(false)
        if (isGetWater && getLocations)
            getLocations();

        setForm({roundJobs: source, bufferTime});
    }, [after_processes]);

    const update = (prev: (arr: IAfterProcesses[]) => IAfterProcesses[]) => {
        if (onUpdateAfterRound)
            onUpdateAfterRound({[roundId]: prev(cloneObj(after_processes))})
    }

    const items: MenuProps['items'] = [
        {
            label: 'Add get water',
            key: '1',
            onClick: () => {
                setAddLocation(true);
                getLocations && getLocations();
            }
        },
        {
            label: bufferTime ? 'Update buffer time' : 'Add buffer time',
            key: '2',
            onClick: () => {
                const el = openPopup(<PopupAddBufferTime
                    duration={bufferTime?.duration}
                    onSave={update}
                    onClose={() => el.remove()}
                />)
            }
        },
    ];

    return <div>
        <div className={styles.round}>
            <div className='font-[600] text-[12px] leading-[12px] pb-[8px]'>ROUND {roundId}</div>
            {children}
            {isEdit && <Menu items={items} trigger={['contextMenu']}>
                <div className={styles.layout}/>
            </Menu>}
        </div>
        <div className='flex justify-between gap-1 w-full mt-[8px]'>
            <RoundJob
                bufferTime={bufferTime}
                roundJobs={addLocation ? [...roundJobs, {
                    type: ROUND_JOB.GetWater,
                    index: roundJobs.length
                }] : roundJobs}
                onUpdateAfterRound={update}
            />
            {isEdit && <Menu items={items} placement='bottomRight'>
                <div className='three-dot mt-[3px]'/>
            </Menu>}
        </div>
    </div>

};

export default Round;
