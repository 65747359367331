import {DISEASE_TYPE} from "../../../../util/varibles/constants";

export enum KeyChart {
    FemaleLice = 'female_lice',
    IlaConfirmed = 'ila_confirmed',
    IlaSuspected = 'ila_suspected',
    PdConfirmed = 'pd_confirmed',
    PdSuspected = 'pd_suspected',
    MobileLice = 'mobile_lice',
    Mortality = 'mortality',
    MortalityPercent = 'mortality_percent',
    SeaTemperature = 'sea_temperature',
    StationaryLice = 'stationary_lice',
    TotalLice = 'total_lice',
    DiseaseLocal = 'disease_local',
}

export enum UnitOfChart {
    Fish = 'fish',
    Temperature = '°C',
    Lice = 'lice/fish',
    Percent = '%'
}

export const colorOfYAxis: { [id: string]: string } = {
    [UnitOfChart.Temperature]: '#2F8ED5',
    [UnitOfChart.Lice]: '#5DACA5',
    [UnitOfChart.Percent]: '#E95D5D',
}

interface IPropertiesOfChart {
    [key: KeyChart | string]: {
        id: KeyChart
        name?: string
        unit?: UnitOfChart
        color?: string
        type?: any
        zlevel?: number
        lineColor?: string
    }
}

export const propertiesOfChart: IPropertiesOfChart = {
    [KeyChart.Mortality]: {
        id: KeyChart.Mortality,
        name: 'Mortality count',
        unit: UnitOfChart.Fish,
        color: '#FFFFFF',
        zlevel: 0,
    },
    [KeyChart.MortalityPercent]: {
        id: KeyChart.MortalityPercent,
        name: '% Mortality',
        unit: UnitOfChart.Percent,
        color: '#E95D5D',
        zlevel: 1,
    },
    [KeyChart.SeaTemperature]: {
        id: KeyChart.SeaTemperature,
        name: 'Sea temperature',
        unit: UnitOfChart.Temperature,
        color: '#2F8ED5',
        zlevel: 1,
    },
    [KeyChart.IlaConfirmed]: {
        id: KeyChart.IlaConfirmed,
        name: 'Ila confirmed',
        color: '#7352DF',
        type: DISEASE_TYPE.ISA,
    },
    [KeyChart.IlaSuspected]: {
        id: KeyChart.IlaSuspected,
        name: 'Ila suspected',
        color: '#897BB7',
        type: DISEASE_TYPE.ISA,
    },
    [KeyChart.PdConfirmed]: {
        id: KeyChart.PdConfirmed,
        name: 'Pd confirmed',
        color: '#F2E660',
        type: DISEASE_TYPE.PD,
    },
    [KeyChart.PdSuspected]: {
        id: KeyChart.PdSuspected,
        name: 'Pd suspected',
        color: '#CBC687',
        type: DISEASE_TYPE.PD,
    },
    [KeyChart.MobileLice]: {
        id: KeyChart.MobileLice,
        name: 'Mobile lice',
        unit: UnitOfChart.Lice,
        color: '#3E5979',
        zlevel: 1,
    },
    [KeyChart.StationaryLice]: {
        id: KeyChart.StationaryLice,
        name: 'Stationary lice',
        unit: UnitOfChart.Lice,
        color: '#5DACA5',
        zlevel: 1,
    },
    [KeyChart.FemaleLice]: {
        id: KeyChart.FemaleLice,
        name: 'Female lice',
        unit: UnitOfChart.Lice,
        color: '#72A64E',
        zlevel: 1,
    },
    [KeyChart.TotalLice]: {
        id: KeyChart.TotalLice,
        name: 'Total lice',
        unit: UnitOfChart.Lice,
        zlevel: 1,
    },
    [KeyChart.DiseaseLocal]: {
        id: KeyChart.TotalLice,
        color: '#F69C6F'
    },
}

export function addLiceThreshold(count: number, yLine: any) {
    return {
        name: '',
        type: 'line',
        data: [],
        markLine: {
            animation: false,
            data: [
                [
                    {
                        yAxis: 0.5,
                        xAxis: 0,
                        symbol: 'none',
                    },
                    {
                        yAxis: 0.5,
                        xAxis: 15,
                        symbol: 'none',
                        lineStyle: {
                            color: "red",
                        },
                        emphasis: {
                            label: {
                                color: 'red',
                                formatter: 'Lice threshold 0.5',
                            },
                            lineStyle: {
                                width: 1
                            }
                        },
                        label: {
                            formatter: '',
                            position: 'insideMiddleBottom'
                        },
                    }
                ],
                [
                    {
                        yAxis: 0.2,
                        xAxis: 15,
                        symbol: 'none',
                    },
                    {
                        yAxis: 0.2,
                        xAxis: 20,
                        symbol: 'none',
                        lineStyle: {
                            color: "red",
                        },
                        emphasis: {
                            label: {
                                color: 'red',
                                formatter: 'Lice threshold 0.2',
                            },
                            lineStyle: {
                                width: 1
                            }
                        },
                        label: {
                            formatter: '',
                            position: 'insideMiddleBottom'
                        },
                    }
                ],
                [
                    {
                        yAxis: 0.5,
                        xAxis: 20,
                        symbol: 'none',
                    },
                    {
                        yAxis: 0.5,
                        xAxis: count - 1,
                        symbol: 'none',
                        lineStyle: {
                            color: "red",
                        },
                        emphasis: {
                            label: {
                                color: 'red',
                                formatter: 'Lice threshold 0.5',
                            },
                            lineStyle: {
                                width: 1
                            }
                        },
                        label: {
                            formatter: '',
                            position: 'insideMiddleBottom'
                        },
                    }
                ]
            ],
        },
        yAxisIndex: yLine[UnitOfChart.Lice],
    }
}

