import {CERTAIN_TYPE, OPERATION_TYPE, SITE_TYPE} from "../../../../../../util/varibles/constants";
import {IUnit, IVessel} from "../../../../../../util/varibles/interface";

interface ICreateTransport {
    vessel: IVessel
    unit: IUnit
    count: number
    properties: any
}

export const createTransport = (args: ICreateTransport) => {
    const {vessel, unit, count, properties} = args;
    const {capacity} = vessel;
    const {fish_amount, avg_weight, site_id, site_name, site_type} = unit;
    let amountOrder = fish_amount, amountStore, totalWeightOrder;
    if (site_type === SITE_TYPE.INTERNAL) {
        const amountDefault = Math.floor(capacity / avg_weight);
        if (amountOrder > amountDefault) {
            amountOrder = amountDefault;
            amountStore = fish_amount - amountDefault;
        } else
            amountStore = 0;

        totalWeightOrder = amountOrder * avg_weight;
    } else {
        amountOrder = 0;
        amountStore = 0;
        totalWeightOrder = 0;
    }

    return {
        operation: {
            ...properties,
            operation_code: count + 1,
            source_id: site_id,
            source_name: site_name,
            source_site_type: site_type,
            note: '',
            operation_type: OPERATION_TYPE.TRANSPORT,
            sub_destinations: [{
                ...unit,
                avg_weight,
                fish_amount: amountOrder,
                total_weight: totalWeightOrder,
            }],
            fish_amount: amountOrder,
            total_weight: totalWeightOrder,
            certain_mode: CERTAIN_TYPE.NONE
        },
        amountStore
    }
}


export function createTransports({...args}) {
    let {data, store, vessel, isFull = false, properties} = args;

    const newOperations = data.reduce((operations: any, sub: any) => {
        const {id, fish_amount, site_type} = sub;
        let amountStore = fish_amount;
        const keyStore = id + '|-';
        if (site_type === SITE_TYPE.EXTERNAL) {
            const {operation} = createTransport({
                vessel,
                unit: {...store[keyStore], fish_amount: 0},
                count: operations.length,
                properties
            })
            operations.push(operation);
        } else {
            let count = 0;
            while (amountStore > 0) {
                const {operation, amountStore: newAmountStore} = createTransport({
                    vessel,
                    unit: {...store[keyStore], fish_amount: amountStore},
                    count: operations.length,
                    properties
                })
                operations.push(operation);
                amountStore = newAmountStore;
                count++;

                if (isFull || count === 10)
                    break;
            }
        }


        return operations
    }, [])

    return {operations: [...newOperations]}
}

interface IGetSubData {
    sub_destinations: IUnit[]
    source_site_type: SITE_TYPE
}

export function getSubData(item: IGetSubData) {
    let sub_destinations = item.sub_destinations.filter((item: any) => item.delivery_id && item.fish_amount > 0);
    if (item.source_site_type === SITE_TYPE.EXTERNAL) {
        sub_destinations = sub_destinations.map((item: any) => {
            const {fish_amount, total_weight} = item;
            return {...item, avg_weight: Math.round(total_weight / fish_amount)}
        })
    }
    const sub_operations = sub_destinations.reduce((list: any, item: any) => {
        const {id, fish_amount, total_weight} = item;
        if (list[id]) {
            list[id].fish_amount += fish_amount;
            list[id].total_weight += total_weight
        } else
            list[id] = item;
        return list;
    }, {})
    return {
        sub_destinations,
        sub_operations: Object.keys(sub_operations).reduce((list: any, key) => [...list, sub_operations[key]], [])
    }
}