import React, {Component} from 'react';
import styles from './style.module.scss';
import {KeyOfStep, OPERATION_MODE} from "../../util/varibles/constants";
import {connect} from "react-redux";
import {IActivityLog, IStateGlobal} from "../../util/varibles/interface";
import {getTimeByOpMode} from "../../pages/PlanOperationPage/util/function_operation/constants";
import {datetime} from "../../util/library/datetime";

const mapStateToProps = (state: IStateGlobal) => {
    const {operation_mode = OPERATION_MODE.PLAN} = state.login.user.tenant;
    return {
        operation_mode
    }
}

interface IProps {
    operation_mode: OPERATION_MODE

    visible?: boolean
    activity_log: IActivityLog[]
    format: string
    current_process: any
}

class ArrivalTimeAtFactory extends Component<IProps> {
    static defaultProps = {
        visible: true,
        format: 'HH:mm'
    }

    render() {
        if (!this.props.visible) return null;
        const {activity_log, format, operation_mode} = this.props;
        const index = activity_log.findIndex((item: any) => item.key === KeyOfStep.ARRIVE_FACTORY);

        if (index === -1)
            return null

        const time = getTimeByOpMode[operation_mode](activity_log[index]) || 0;

        return <div
            className={[styles['margin-left'], styles.time].join(' ')}
            data-time-type='factory'
            data-is-more='true'
        >
            {datetime(time).format(format)}
        </div>;
    }
}

export default connect(mapStateToProps, {})(ArrivalTimeAtFactory);
