import React, {useCallback, useState} from 'react';
import {Switch} from "antd";
import {ITenant} from "../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {editWeatherStatusFetch} from "../../../util/services/tenant";
import {useDispatch} from "react-redux";
import {userActions} from "../reducer";

interface IProps {
    data: ITenant
}

const WeatherCustomer: React.FC<IProps> = ({data}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleChange = useCallback((weather_license: boolean) => {
        setLoading(true);
        new Promise(resolve => resolve(editWeatherStatusFetch({
            tenant_id: data.tenant_id,
            weather_license
        })))
            .then(() => {
                notify.success(NotifyCode.S2)(['Weather']);
                dispatch(userActions.updateTenant({...data, weather_license}));
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }, [data, dispatch]);


    return <Switch
        loading={loading}
        checkedChildren="On"
        unCheckedChildren="Off"
        checked={data.weather_license}
        onChange={handleChange}
    />;
};

export default WeatherCustomer;