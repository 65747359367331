import {getKeyWeek} from "../../util/varibles/global";
import {ILevelByVessel, INoteCalender, IOperations, IVessel} from "../../util/varibles/interface";
import {NOTE_TYPE, OPERATION_MODE} from "../../util/varibles/constants";
import {datetime} from "../../util/library/datetime";

export const CALENDAR_ID = 'content-calendar';

function createCurrentTime(): { weeks: any[] } {
    const weeks = [];
    const monday = datetime().startOf('week').value;
    for (let i = -7; i < 7; i++) {
        const time = datetime(monday).add(i, 'week').time;
        const key = getKeyWeek(time);
        weeks.push({key, time});
    }
    return {weeks};
}

export function createKeyOfNote(time: number, type: NOTE_TYPE) {
    return [datetime(time).startOf('day').time, type].join('|')
}

export interface OverviewState {
    beforeCount: boolean
    afterCount: boolean
    opMode: OPERATION_MODE

    operations: IOperations
    level: ILevelByVessel
    vessels: IVessel[]
    weeks: { key: string, time: number }[]
    loadingData: boolean
    loadingLazy: boolean

    notes: { [index: string]: INoteCalender[] }
}

export const initialState: OverviewState = {
    beforeCount: false,
    afterCount: false,
    opMode: OPERATION_MODE.PLAN,
    operations: {},
    level: {},
    vessels: [],
    loadingData: false,
    loadingLazy: false,
    notes: {},
    ...createCurrentTime()
};