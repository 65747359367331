import React, {Component} from 'react';
import {connect} from 'react-redux';
import stylesPage from "../style.module.scss";
import styles from "./style.module.scss";
import {tabs} from "../constants";
import {Badge} from "antd";
import Chat from "../Component/Chat";
import Operation from "../Component/Operation";
import WSService from "../../../web-socket/web-socket-service";
import {addRouterListener, getSearchFromUrl} from "../../../util/library/Router";
import {messageActions} from "../reducer";
import {CHAT_TYPE} from '../../../util/varibles/constants';
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {chatNew, operationsNew} = state.message
    return {
        count: {chatNew, operationsNew}
    }
}

interface IProps {
    count: any

    getMessages(payload: any): void
}

class WrapperMessage extends Component<IProps> {
    state = {
        tab: CHAT_TYPE.USER,
        id: ''
    }

    renderContent: any = {
        [CHAT_TYPE.USER]: (id: string) => <Chat chatId={id} clearId={() => this.setState({id: ''})}/>,
        [CHAT_TYPE.OPERATION]: (id: string) => <Operation chatId={id} clearId={() => this.setState({id: ''})}/>
    }
    socket: WSService | undefined;
    listen: any;
    actions: any = {};

    constructor(props: IProps) {
        super(props);

        const search = getSearchFromUrl();
        this.getState(search, (values: any) => this.setState(values))

        this.listen = addRouterListener((state) => {
            const search = getSearchFromUrl(state)
            this.getState(search, (values: any) => this.setState({...values}))
        })
    }

    componentWillUnmount() {
        if (this.listen)
            this.listen()
    }

    getState = (params: string[], action: any) => {
        const keys = new Set(['chatType', 'id']);
        const values: any = {};
        params.forEach((item: string) => {
            keys.forEach((key) => {
                if (item.indexOf(key) === 0) {
                    keys.delete(key);
                    values[key] = item.slice(key.length + 1);
                }
            })
        })

        if (keys.size === 0) {
            const {chatType, id} = values;
            action({tab: chatType, id});
            this.props.getMessages({id, index: 1});
        }
    }

    render() {
        const {count} = this.props;
        const {tab, id} = this.state;

        return <div className={styles.wrapper}>
            <div className={stylesPage.sidebar}>
                {tabs.map((item) => {
                    return <div
                        key={item.id}
                        className={stylesPage.section}
                        data-actived={item.id === tab}
                        onClick={() => item.id === tab ? null : this.setState({tab: item.id})}
                    >
                        <Badge className={stylesPage.badge} count={count[item.keyNew]} offset={[6, 1]}
                               overflowCount={99}>
                            <div className={stylesPage[item.icon]}/>
                        </Badge>
                        <div data-lev='name'>{item.name}</div>
                    </div>
                })}
            </div>

            <div className={styles.messages}>
                {tabs.map(item => <div key={item.id} className={styles.section} data-actived={item.id === tab}>
                    {this.renderContent[item.id](id)}
                </div>)}
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getMessages: messageActions.getMessagesRequest
})(WrapperMessage);
