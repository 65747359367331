import React, {useEffect, useRef} from 'react';
import styles from './style.module.scss';
import ErrorOfOperation from "../../../_Component/ErrorOfOperation";
import {IPropsChildren, TOperation} from "../../../../../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";
import {planValidation, VALIDATION, VALIDATION_STATUS} from "../../../../../util/validation";
import {getEditOp, setEditOp} from "../../../../../../../contexts/EditOperationContext";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../../../util/store/selectors";

export interface IProps extends IPropsChildren {
    isAllowDrag?: boolean
    isAllowError?: boolean

    harvest: TOperation
    indexHarvest: any
}


const Operation: React.FC<IProps> = (props) => {
    const {indexHarvest, isAllowDrag = true, isAllowError = true, harvest} = props;
    const routeIds = useSelector(selectPlan.routeIds)
    const {sub_operations, error_detail = {},} = harvest;

    const rootRef = useRef<HTMLDivElement>(null);
    const btDrag = useRef<HTMLDivElement>(null);
    const get = getEditOp();
    const set = setEditOp();
    const relHeight = sub_operations.length * 44 + 38;

    useEffect(() => {
        const el = btDrag.current;
        if (el && isAllowDrag) {
            el.addEventListener('mousedown', onMouseDown);
        }
        return () => {
            if (el) {
                el.removeEventListener('mousedown', onMouseDown);
            }
        }
    }, [btDrag]);

    const onMouseDown = (e: React.MouseEvent | any) => {
        const {pageX, pageY} = e;
        const {x = 0, y = 0} = rootRef.current?.getBoundingClientRect() || {};
        const {offsetWidth = 0} = rootRef.current || {};
        const el = rootRef.current as HTMLDivElement
        el.style.height = '0';
        el.style.opacity = '0';
        el.style.visibility = 'hidden';
        set({
            dragData: {
                x: pageX - x,
                y: pageY - y,
                height: relHeight,
                width: offsetWidth,
                View: <Operation {...props} isAllowDrag={false} isAllowError={false}/>,
                merge: handleMerge,
                cancel: () => {
                    const el = rootRef.current as HTMLDivElement;
                    el.style.opacity = '1';
                    el.style.visibility = 'visible';
                    el.style.height = 'unset';
                }
            }
        })
    };

    const handleMerge = (indexHarvestTarget: number) => {

        if (indexHarvestTarget === indexHarvest) return;
        const {ops, vessel} = get();
        const harvestTarget = {...ops[indexHarvestTarget]};
        const totalWeight = harvestTarget.total_weight + harvest.total_weight;
        const {capacity = 0} = vessel || {};

        if (totalWeight > capacity) {
            notify.error(NotifyCode.E1)();
            return;
        }

        const {sub_operations, sites = []} = harvest;
        const {factory_id, factory_name, error_detail = {}} = harvestTarget;

        const sitesNew = sites.reduce((list: any, item: any) => {
            const {id} = item;
            const isExist = list.some((sub: any) => sub.id === id);
            if (!isExist) {
                const {status} = planValidation[VALIDATION.ROUTE]({
                    source: item,
                    destination: {id: factory_id, name: factory_name}
                }, routeIds);
                return [...list, {...item, isRoute: status !== VALIDATION_STATUS.ERROR}]
            }
            return list;
        }, harvestTarget.sites || []);

        const isRoute = sitesNew.some((item: any) => item.isRoute);

        if (!isRoute)
            error_detail.route = sitesNew.map((item: any) => ({
                source_id: item.id,
                destination_id: factory_id,
                message: notify[NotifyCode.E20]([item.name, factory_name])
            }))
        else if (error_detail.route) {
            delete error_detail.route;
        }
        const subOperationsNew = sub_operations.reduce((list: any, itemNew: any) => {
            const index = list.findIndex((itemOld: any) => itemOld.id === itemNew.id && itemOld.harvest_id === itemNew.harvest_id);
            if (index !== -1) {
                const {fish_amount, total_weight, delivery_types} = itemNew;
                list[index].fish_amount += fish_amount;
                list[index].total_weight += total_weight;
                list[index].delivery_types = delivery_types.reduce((subList: any, subItem: any) => {
                    const {id, fish_amount: subFishAmount, total_weight: subTotalWeight} = subItem;
                    const indexSub = subList.findIndex((deliveryType: any) => deliveryType.id === id);
                    if (indexSub !== -1) {
                        subList[indexSub].fish_amount += subFishAmount;
                        subList[indexSub].total_weight += subTotalWeight;
                    } else {
                        subList.push(subItem);
                    }
                    return subList
                }, list[index].delivery_types)
            } else {
                list.push(itemNew);
            }
            return list;
        }, [...harvestTarget.sub_operations]);

        const operation = {
            ...harvestTarget,
            sites: sitesNew,
            sub_operations: subOperationsNew,
            total_amount: (harvestTarget.total_amount || 0) + (harvest.total_amount || 0),
            total_weight: totalWeight,
            error_detail
        };

        Object.assign(ops[indexHarvestTarget], operation)
        ops.splice(indexHarvest, 1);
        set({ops})
    }

    const style = !isAllowDrag ? {opacity: 1, cursor: 'grabbing'} : {cursor: 'grab', transition: 'all .2s'};
    const error = Object.keys(error_detail).length > 0;

    return <div ref={rootRef} className={styles.contentOp} style={{minHeight: relHeight + 'px'}}>
        <div {...{
            id: indexHarvest,
            className: styles.operation + ' no-select',
            'data-lev': 'operation',
            'data-error': error,
            'data-is-dragging': !isAllowDrag
        }}>
            <div className={styles['bt-drag']} ref={btDrag} style={style}/>
            {props.children}
        </div>
        {(isAllowError && isAllowDrag) && <ErrorOfOperation {...{
            error_detail,
            style: error ? {} : {display: 'none'}
        }}/>}
    </div>;
};

export default Operation;

