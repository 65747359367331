import React, {Component} from 'react';
import styles from './style.module.scss';
import {Button, Form, Input, notification} from "antd";
import {UserService} from "../../../../util/services/user";
import {FormInstance} from "antd/lib/form";
import {showErrorResponse} from "../../../../util/varibles/global";

interface IProps {
    user: any

    onClose(): void
}

class ChangePassword extends Component<IProps> {
    formRef: any = React.createRef<FormInstance>();
    modal: any;
    state = {
        loading: false,
        password: '',
    }

    toggleFocus = (key: any, value: boolean) => this.setState({[key]: value})

    handleChange = (values: any) => {
        const {username} = this.props.user;
        const {password} = values;
        this.setState({loading: true});
        new Promise((resolve) => {
            resolve(UserService.changePassword({username, password}))
        }).then(() => {
            this.props.onClose();
            this.setState({loading: false})
            notification.success({message: 'Success', description: 'Change password successful'})
        }).catch((error) => {
            showErrorResponse('Error', error)
            this.setState({loading: false})
        })
    }

    render() {
        const {loading, password} = this.state;
        const propsPassword: any = {
            name: 'password',
            className: 'input-login'
        }

        return <Form ref={this.formRef} onFinish={this.handleChange}>
            <Form.Item
                name='password'
                rules={[{
                    required: true,
                    message: `"Password" is required`
                }]}
            >
                <div
                    className={'container-input'}
                    onFocus={() => this.toggleFocus('password', true)}
                    onBlur={() => this.toggleFocus('password', false)}
                >
                    <Input.Password {...propsPassword}/>
                    <Form.Item noStyle
                               shouldUpdate={(prev: any, current: any) => prev.password !== current.password}>
                        {({getFieldValue}: any) => {
                            const value = getFieldValue('password') || '';
                            return <label
                                className={(password || value.length > 0) ? '' : 'label-float'}
                                htmlFor="password"
                            >
                                Password
                            </label>
                        }}
                    </Form.Item>
                </div>
            </Form.Item>
            <div className='d-flex justify-content-end mt-12'>
                <Button className='bt-default' onClick={this.props.onClose}>Cancel</Button>
                <Button loading={loading} className={styles['bt-change']} htmlType='submit'>Save</Button>
            </div>
        </Form>
    }
}

export default ChangePassword;