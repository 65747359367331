import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty, onChangeRequired} from "./constants";
import styles from "./style.module.scss";

interface IProps extends IPropsPropertyElement {

}

class DropdownElement extends Component<IProps> {

    handleChangeMultipleSelections = (value: boolean) => {
        const {selected} = this.props;
        if (!selected) return null;

        let {optionDefault} = selected.properties;
        const properties = {
            multipleSelections: value,
            optionDefault
        }
        const valuesDefault = JSON.parse(optionDefault);
        if (valuesDefault && valuesDefault.length !== 0) {
            if (value)
                optionDefault = JSON.stringify([valuesDefault]);
            else
                optionDefault = JSON.stringify(valuesDefault[0]);
            properties.optionDefault = optionDefault;
        }
        onChangeProperty(properties, this.props)
    }

    render() {
        const {selected} = this.props;
        if (!selected) return null;

        const {
            label,
            properties: {required, subLabel, multipleSelections, options, optionDefault = ''}
        } = selected;
        const data = options.length > 0 ? options.split('\n').reduce((list: any[], item: string, index: number) => {
            if (item.trim().length > 0)
                list = [...list, {value: index, label: item}]
            return list;
        }, []) : [];
        return <>
            <div className={styles['title']}>Dropdown Properties</div>
            <div className={styles['property-element']}>
                <label>Label</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
                <span>Type a text field label.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Required</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('required')}>
                        <input id='required' type="checkbox" checked={required}
                               onChange={() => onChangeProperty({required: !required}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Options</label>
                <textarea value={options}
                          onChange={(e) => onChangeProperty({options: e.currentTarget.value}, this.props)}
                />
                <span>Type your options to select from. Separate each on a new line.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Multiple Selections</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('multiple-selections')}>
                        <input id='multiple-selections' type="checkbox" checked={multipleSelections}
                               onChange={() => this.handleChangeMultipleSelections(!multipleSelections)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Allow users to select multiple options.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Selected by default</label>
                {/*<Select options={ data } styles={ selectStyle }*/}
                {/*        value={ JSON.parse(optionDefault) }*/}
                {/*        isMulti={ multipleSelections }*/}
                {/*        closeMenuOnSelect={ !multipleSelections }*/}
                {/*        onChange={ (item: any) => onChangeProperty({ optionDefault: JSON.stringify(item || '') }, this.props) }*/}
                {/*/>*/}
                <span>Choose an option to be selected by default.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Sub label</label>
                <input type="text" value={subLabel}
                       onChange={(e) => onChangeProperty({subLabel: e.currentTarget.value}, this.props)}
                />
                <span>Add a small description below the input field.</span>
            </div>
        </>;
    }
}

export default DropdownElement;
