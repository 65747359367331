import React from 'react';
import {IOperation, IStateGlobal} from "../../../util/varibles/interface";
import {useSelector} from "react-redux";
import LoadingBox from "../../LoadingBox";

interface IProps {
    data: IOperation[]
}

const Loading: React.FC<IProps> = ({data}) => {
    const loading = useSelector((state: IStateGlobal) => state.login.loadingDashboard);

    if (!loading && data.length > 0)
        return null

    return <LoadingBox loading={loading} message="There is no operation coming."/>;
};

export default Loading;
