import React, {RefObject} from 'react';
import styles from './style.module.scss';

interface IProps {
    disabled?: boolean
    reverse?: string
    className?: string
    color?: string
    id?: string
    name?: string
    refInput?: RefObject<HTMLInputElement>
    label?: string
    width?: string
    lineHeight?: string
    minWidth?: string
    maxWidth?: string
    checked: boolean

    onChange(...args: any[]): void
}

const InputCheckbox: React.FC<IProps> = (props) => {

    const {
        id,
        name,
        className = '',
        refInput,
        label,
        color = '#5DACA5',
        checked = false,
        disabled,
        reverse= '0',
        width,
        maxWidth,
        minWidth,
        lineHeight,
        onChange
    } = props;
    const style = {'--input-color': color, width, maxWidth, minWidth, lineHeight};

    return <label
        className={[styles.checkbox, className].join(' ')}
        data-disabled={disabled}
        style={style}
        data-reverse={reverse}
    >
        <input type='checkbox' id={id} disabled={disabled} name={name} checked={checked} ref={refInput}
               onChange={onChange}/>
        <span className={styles.box}/>
        <span className={styles.text}>{label}</span>
    </label>;
};

export default InputCheckbox;
