import {INTEGRATION_MODE, OPERATION_MODE, OPERATION_TYPE, USER_ROLE, VESSEL_TYPE} from "../../util/varibles/constants";
import {IOperation, IPosition, IVessel} from "../../util/varibles/interface";

export interface IUser {
    user_id: string
    username: string
    email: string
    regions: {
        id: string
        name: string
        field: string
        description: string
        tenant_id: string
    }[]
    sites: any[]
    factories: any[]
    roles: USER_ROLE[]
    tenant_id: string
    tenant_name: string
    authTime: number
    expiresIn: number
    setting: any
    default_vessel?: string
    tenant: {
        id: string
        name: string
        tenant_id: string
        integration?: any
        integration_mode?: INTEGRATION_MODE
        weather_license?: boolean
        auto_route_license?: boolean
        operation_mode?: OPERATION_MODE
        density?: {
            [index: string]: number[]
        }
        default_op_type?: { global: OPERATION_TYPE } & {
            [id: string]: {
                name: string
                type: OPERATION_TYPE
            }
        }
        enable_change_operation_status?: boolean
        customer_type?: string
        domain?: string
        field?: string
    }
    permission: any
}

export interface IBarentsSwatch {
    access_token: string
    expires_in: number
    scope: string
    token_type: string
    time: number
}

export interface IVesselOfUser extends IVessel {
    vessel_id: string
    vessel_type: VESSEL_TYPE
    iot_name: string
    image_url: string
    name: string
    position: IPosition
    operations: IOperation[]
}

export interface LoginState {
    loadingSetting: boolean
    loadingDashboard: boolean
    domains: any[]
    user: IUser
    barentsSwatch: IBarentsSwatch
    vessels: IVesselOfUser[]
}

const initUser = {
    user_id: '',
    username: '',
    email: '',
    regions: [],
    roles: [],
    sites: [],
    factories: [],
    tenant_id: '',
    tenant_name: '',
    authTime: Date.now(),
    expiresIn: 0,
    setting: {},
    tenant: {
        id: '',
        name: '',
        tenant_id: ''
    },
    permission: {}
}

export const initialState: LoginState = {
    loadingSetting: false,
    loadingDashboard: false,
    domains: [],
    vessels: [],
    user: initUser,
    barentsSwatch: {
        access_token: '',
        expires_in: 0,
        scope: 'api',
        token_type: 'Bearer',
        time: 0
    }
};