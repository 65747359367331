import React from 'react';
import styles from './style.module.scss';
import stylesWeek from '../Weeks/style.module.scss';
import {Button} from "antd";
import {getKeyWeek, jumpToToday, SCROLL_TYPE} from "../../../../util/varibles/global";
import {CALENDAR_ID} from "../../constants";
import WeekControl from "./WeekControl";
import VesselFilter from "./VesselFilter";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {VIEW_SMOOTH} from "../../../../util/varibles/constants";

interface IProps {
}

const Header: React.FC<IProps> = () => {
    const week = getKeyWeek(Date.now());

    const onPush = (value: number) => {
        const calendar = document.getElementById(CALENDAR_ID);
        if (calendar) {
            const {scrollLeft} = calendar;
            const {offsetWidth = 0}: any = calendar.getElementsByClassName(stylesWeek.week)[0] || {};

            const left = Math.ceil(scrollLeft / offsetWidth) * offsetWidth + (value * offsetWidth);
            calendar.scroll({left, ...VIEW_SMOOTH})
        }
    }

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <div className={styles.today}>
                <Button
                    className='bt-default h-32'
                    onClick={() => jumpToToday(CALENDAR_ID, week, SCROLL_TYPE.HORIZONTAL, 162)}
                >
                    Today
                </Button>
                <div className={styles.prev} onClick={() => onPush(-1)}>
                    <Icon icon={ICON_NAME.ARROW_2}/>
                </div>
                <div className={styles.next} onClick={() => onPush(1)}>
                    <Icon icon={ICON_NAME.ARROW_2}/>
                </div>
            </div>
            <WeekControl/>
            <VesselFilter/>
        </div>
    </div>;
};

export default Header;
