import React, {useEffect, useState} from 'react';
import {EVENT_TYPE, eventType} from "../../../../../../../../util/varibles/constants";
import styles from "../style.module.scss";
import Icon, {ICON_NAME} from "../../../../../../../../components/Icon";
import {MenuProps} from "antd";
import InputDuration from "../../../../../../../../components/Basic/InputDuration";
import Menu from "../../../../../../../../components/Menu";
import {IPropsChild} from "./";
import {useRoundMap} from "../../../../../../../../contexts/RoundMapContext";
import Location from "../../../../_Component/Location";
import {convertNumberTimeToStringTime} from "../../../../../../../../util/varibles/global";

const GetWater: React.FC<IPropsChild> = (props) => {
    const {duration, destination_id, destination_name, onUpdate, onDelete} = props;
    const [form, setForm] = useState({duration, destination_id});
    const {isEdit} = useRoundMap();

    useEffect(() => {
        setForm({duration, destination_id})
    }, [duration, destination_id]);

    const handleChangeLocation = ({label, value}: { label: string, value: string }) => {
        if (form.duration)
            onUpdate({...form, destination_id: value, destination_name: label})
        else
            setForm(prev => ({...prev, destination_id: value, destination_name: label}))
    }
    const handleChangeDuration = (value: number) => {
        if (form.destination_id)
            onUpdate({...form, duration: value})
        else {
            setForm(prev => ({...prev, duration: value}))
        }
    }

    const items: MenuProps['items'] = isEdit ? [
        {
            label: <div className='menu-line' data-danger='true'>
                <Icon icon={ICON_NAME.DELETE}/>
                <span className='color-danger'>Delete</span>
            </div>,
            key: '2',
            onClick: onDelete
        },
    ] : [];

    return <Menu items={items} trigger={['contextMenu']} placement='bottomRight'>
        <div className={styles.getWater}>
            <Icon className='ml-2' icon={ICON_NAME.EVENT_OLD}
                  dataAttribute={{"data-event-type": EVENT_TYPE.GET_WATER}}/>
            {isEdit
                ? <>
                    <Location className='select-no-border min-w-[180px] ml-2' value={form.destination_id} onChange={handleChangeLocation} />
                    <InputDuration value={form.duration || 0} className={styles.inputCustom} onChange={handleChangeDuration}/>
                </>
                : <div className='ml-1'>
                    {eventType[EVENT_TYPE.GET_WATER].name} at <b className='font-[600]'>{destination_name}</b> in <b className='font-[600]'>{convertNumberTimeToStringTime(duration)}</b>
                </div>}
        </div>
    </Menu>
};

export default GetWater;
