import React, {Component} from 'react';
import styles from './style.module.scss';
import {VESSEL_TYPE} from "../../util/varibles/constants";
import {IVessel, TOperation} from "../../util/varibles/interface";

interface IProps {
    visible?: boolean
    operation: TOperation
    vessel: IVessel
}

class RequireClosedTransport extends Component<IProps> {
    static defaultProps = {
        visible: true,
    }

    render() {
        const {visible} = this.props
        if (!visible || !this.props.vessel || this.props.vessel.type !== VESSEL_TYPE.WELL_BOAT || this.props.operation.is_waiting_unit)
            return null

        return <div className={styles['require-closed-transport']} title='Require closed transport'/>
    }
}

export default RequireClosedTransport;
