import React from 'react';
import WrapperTask from "../WrapperTask";
import {Select} from "antd";
import styles from "./style.module.scss";
import SelectCapabilities from "../SelectEquipment";
import InputDuration from "../../../../../../../components/Basic/InputDuration";
import {uppercaseFirstLetter} from "../../../../../../../util/varibles/global";
import {callEventEditOp, IUnitWhenEdit, useEditOp} from "../../../../../../../contexts/EditOperationContext";
import {ITaskService} from "../../../../../../../util/varibles/interface";

interface IProps {
    data: ITaskService
    unit: IUnitWhenEdit
    isAllowDrag?: boolean

    onUpdate(value: ITaskService): void

    onDelete(): void
}

const Task: React.FC<IProps> = (props) => {
    const {
        data,
        unit,
        isAllowDrag = true,
        onUpdate,
        onDelete
    } = props;
    const [serviceTasks] = useEditOp(state => state.serviceTasks);
    const {turnOnCalculate} = callEventEditOp();

    const handleUpdateTask = (change: Partial<ITaskService>) => {
        onUpdate({...data, ...change});
    }

    const {id, error = {}} = data;

    const styleError = {height: '0'}
    let isError = false;
    if (Object.keys(error).length > 0) {
        styleError.height = (Object.keys(error).length * 20 + 3) + 'px';
        isError = true;
    }

    return <>
        <WrapperTask {...{
            data: {elementId: 1, ...data},
            unit,
            isError,
            isAllowDrag,
            onDelete,
        }}>
            <Select
                className={styles.select}
                placeholder="Task"
                variant={"borderless"}
                value={id}
                options={serviceTasks.map(item => {
                    return {value: item.id, label: uppercaseFirstLetter(item.name), data: item};
                })}
                onSelect={(...args) => {
                    const {capabilities = []} = args[1]?.data || {};
                    handleUpdateTask({
                        id: args[1]?.value,
                        name: args[1]?.label,
                        duration: '',
                        capabilities,
                        error: {},
                    })
                }}
                popupMatchSelectWidth={false}
            />
            <SelectCapabilities
                serviceTasks={serviceTasks}
                taskId={id}
                value={data.capabilities}
                onChange={capabilities => handleUpdateTask({capabilities})}
            />
            <InputDuration
                className={styles.input}
                value={data.duration}
                onChange={duration => {
                    handleUpdateTask({duration})
                    turnOnCalculate()
                }}
            />
        </WrapperTask>
        {<div className={styles['task-error']} style={styleError}>
            {Object.keys(error).map(key => <div key={key} dangerouslySetInnerHTML={{__html: error[key]}}/>)}
        </div>}
    </>;
};

export default Task;
