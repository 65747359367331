import React, {Component} from 'react';
import styles from './style.module.scss';
import {aWeekMillisecond} from "../../../util/varibles/constants";
import Time from "./Time";
import Itinerary from "../../../components/Itinerary";
import {getVesselSchedule} from "../../../util/services/vessel";
import {datetime} from "../../../util/library/datetime";

const initTime = () => [datetime().startOf('week').time - (4 * aWeekMillisecond), Date.now()];

interface IProps {
    vesselId: string
}

class ActivityLog extends Component<IProps> {
    state = {
        loading: false,
        time: [],
        schedule: [],
        isFilter: false,
    }

    componentDidMount() {
        const time = initTime();
        this.getData(time, time);
    }

    getData = (value: number[], time: any) => {
        const {loading} = this.state;
        const {vesselId} = this.props;
        if (loading)
            return;
        const [start_time, finish_time] = value;
        const params = {
            id: vesselId,
            start_time,
            finish_time
        }
        this.setState({loading: true});
        new Promise(resolve => resolve(getVesselSchedule(params)))
            .then((rs: any) => {
                const {schedule} = this.state;
                this.setState({time, loading: false, schedule: [...rs, ...schedule]});
            })
            .catch(() => {
                this.setState({loading: false});
            })
    }

    handleLoadMore = () => {
        const [start, finish] = this.state.time;
        const time = [start - (3 * aWeekMillisecond), finish];
        const value = [start - (3 * aWeekMillisecond), start - 1];
        this.getData(value, time);
    }

    handleFilter = (filter: any) => {
        this.setState({time: [], schedule: [], isFilter: !!filter});
        if (filter) {
            const [start, finish] = filter;
            const value = [start.valueOf(), finish.valueOf()];
            this.getData(value, value);
        } else {
            const time = initTime();
            this.getData(time, time);
        }
    }

    render() {
        const {time, schedule, isFilter, loading} = this.state;

        return <div className={styles.wrapper}>
            <div className={styles.content}>
                <Time onChange={this.handleFilter}/>
                <div className={styles.schedule}>
                    <Itinerary
                        time={time}
                        isFilter={isFilter}
                        source={schedule}
                        loading={loading}
                        loadMore={this.handleLoadMore}
                    />
                </div>
            </div>
        </div>;
    }
}

export default ActivityLog;

