import callApi from "../api/callApi";
import {urlDashboard} from "../api/v1";

export function getDashboardsOfVesselFetch(vesselId: string) {
    return callApi(urlDashboard + "/" + vesselId, {
        method: "GET"
    })
}

export function createDashboardFetch(body: any) {
    return callApi(urlDashboard, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function updateDashboardFetch(body: any) {
    return callApi(urlDashboard, {
        method: 'PUT',
        body: JSON.stringify(body)
    })
}

export function deleteDashboardFetch(body: any) {
    return callApi(urlDashboard, {
        method: 'DELETE',
        body: JSON.stringify(body)
    })
}
