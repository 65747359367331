import React from 'react';
import styles from "./style.module.scss";
import {Skeleton} from "antd";

interface IProps {
    loading?: boolean
}

const Loading: React.FC<IProps> = ({loading}) => {
    if (!loading)
        return null;

    return <div className={styles.loading}>
        <div className={styles.left}>
            <Skeleton.Button active shape='round' block />
        </div>
        <div className={styles.right}>
            <Skeleton.Button active shape='round' block />
        </div>
    </div>;
};

export default Loading;