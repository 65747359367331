import React from 'react';
import styles from "./style.module.scss";
import Menu from "../../../../components/Menu";

interface IProps {
    menu: any

    onDragStart?(e: any): void

    onDragEnd?(e: any): void
}

const Drag: React.FC<IProps> = ({menu, onDragStart, onDragEnd}) => {
    return <Menu items={menu} trigger={['contextMenu']}>
        <div
            className={styles['drag-view']}
            draggable={true}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        />
    </Menu>;
};

export default Drag;