import React, {ReactNode, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectWeather} from "../../../util/store/selectors";
import Section from "../Component/Section";
import styles from '../Component/Section/style.module.scss';
import {WEATHER_TYPE} from "../util";
import {renderWeathers} from "../";
import Filter from "../Component/Filter";

interface IProps {
    weathers: any
}

const Sites: React.FC<IProps> = (props) => {
    const [filter, setFilter] = useState([]);
    const [content, setContent] = useState<{ view: null | ReactNode, options: { label: string, value: string }[], count: number }>({
        view: null,
        options: [],
        count: 0
    });

    const sites = useSelector(selectWeather.locations);
    const {weathers} = props;

    useEffect(() => {
        const siteSelected = new Set<string>(filter)
        let count = 0;
        const sitesOpts = Object.keys(sites).map(key => ({value: key, label: sites[key]?.name || ''}))
        const group = weathers.reduce((rs: any, item: any) => {
            const {group_id} = item;
            const [, siteId,] = group_id.split('#');
            const {id, name = 'Other'} = sites[siteId] || {};
            const {list} = rs[id] || {};
            if (siteSelected.size === 0 || siteSelected.has(siteId)) {
                count++;
                rs[id] = {...rs[id], name, list: [...list || [], item]}
            }
            return rs;
        }, {})
        const newOpts: { label: string, value: string }[] = [];
        const newView = Object.keys(group).map(key => {
            newOpts.push({label: group[key].name, value: key});
            return <div className={styles.group} key={key}>
                <div className={styles.groupName}>{group[key].name}</div>
                {renderWeathers(group[key].list, sitesOpts)}
            </div>
        })
        setContent({
            view: newView,
            options: newOpts,
            count,
        })
    }, [weathers, filter, sites]);

    return <Section
        title={`Site limit`}
        type={WEATHER_TYPE.SITE}
        extra={<Filter options={content.options} value={filter} onChange={setFilter}/>}
        count={content.count}
    >
        {content.view}
    </Section>;
};

export default Sites;
