import callApi from "../api/callApi";
import {urlCombineType} from "../api/v1";

export function getTreatmentsFetch() {
    return callApi(urlCombineType + 's', {
        method: 'GET'
    })
}

interface ICreateCombine {
    name: string
    sub_types: any[]
}

export function createCombineFetch(params: ICreateCombine) {
    return callApi(urlCombineType, {
        method: 'POST',
        body: JSON.stringify(params)
    })
}

interface IUpdateCombine {
    id: string
    name: string
    treatment_sub_types: any[]
}

export function updateCombineFetch(params: IUpdateCombine) {
    return callApi(urlCombineType, {
        method: 'PUT',
        body: JSON.stringify(params)
    })
}

interface IDeleteCombine {
    id: string
}

export function deleteCombineFetch(params: IDeleteCombine) {
    return callApi(urlCombineType, {
        method: 'DELETE',
        body: JSON.stringify(params)
    })
}
