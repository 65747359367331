import React, {useState} from 'react';
import styles from "../style.module.scss";
import {Button, Divider, Input, Table, Tooltip} from "antd";
import {ACTION} from "../../../util/varibles/permission";
import {PlusOutlined} from "@ant-design/icons";
import {ILocation} from "../../../util/varibles/interface";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEdit from "./PopupEdit";
import Action from "./Action";
import {useSelector} from "react-redux";
import {uppercaseFirstLetter} from "../../../util/varibles/global";
import {selectLocation} from "../../../util/store/location/constants";
import {selectRegion} from "../../../util/store/region/constants";

const column = {
    name: {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        render: (data: any) => <span>{data}</span>,
    },
    type: {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (data: any) => <span>{uppercaseFirstLetter(data)}</span>,
    },
    position: {
        title: 'Lat, Long',
        dataIndex: 'position',
        key: 'position',
        render: (data: any) => <span>{[data.latitude, data.longitude].join(', ')}</span>,
    },
    capabilities: {
        title: 'Capabilities',
        dataIndex: 'capabilities',
        key: 'capabilities',
        render: (data: any) => {
            if (!Array.isArray(data))
                return null
            return data.map(item => item.name).join(', ')
        },
    }
}

interface IProps {
    permission: any
}

const Locations: React.FC<IProps> = ({permission}) => {
    const [search, setSearch] = useState('');
    const locations = useSelector(selectLocation.locations);
    const regions = useSelector(selectRegion.regions);
    const loading = useSelector(selectLocation.loading);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Escape')
            setSearch('')
    }

    const handleClick = (data?: ILocation) => {
        const el = openPopup(<PopupEdit
            location={data}
            onClose={() => el.remove()}
        />)
    }

    return <div className={styles.content}>
        <div className='d-flex justify-content-end mb-10'>
            <Input
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder='Search by name'
                className={styles.search}
            />
            {permission[ACTION.LOCATION.CREATE] && <>
                <Divider className='divider-vertical' type='vertical'/>
                <Tooltip title="Add location">
                    <Button className='bt-primary bt-plus' type="primary" shape="circle"
                            icon={<PlusOutlined style={{fontSize: '18px'}}/>}
                            onClick={() => handleClick()}
                    />
                </Tooltip>
            </>}
        </div>
        <Table {...{
            rowKey: "id",
            pagination: false,
            columns: [
                column.name,
                column.type,
                {
                    title: 'Region',
                    dataIndex: 'region_id',
                    key: 'region_id',
                    render: (regionId: any) => {
                        const {name = ''} = regions.find(item => item.id === regionId) || {};
                        return name;
                    }
                },
                column.position,
                column.capabilities,
                {
                    key: 'action',
                    width: 80,
                    title: ' ',
                    className: 'p-0',
                    render: (data: ILocation) => <Action data={data} handleEdit={handleClick}/>
                }
            ],
            loading,
            dataSource: locations.filter(({name}) => {
                return search.length === 0 || name.toLowerCase().includes(search.trim().toLowerCase())
            })
        }} />
    </div>;
};

export default Locations;
