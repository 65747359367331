import {initialState} from "./constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IVessel} from "../../util/varibles/interface";

const vesselManagementSlice = createSlice({
    name: 'vesselManagement',
    initialState,
    reducers: {
        updateOffer: (state, action) => {
            const {vesselId, isOwn, ...data} = action.payload;
            if (isOwn) {
                const {ownVessels} = state;
                state.ownVessels = ownVessels.map((item: any) => {
                    return item.id === vesselId ? {
                        ...item,
                        contracts: item.contracts.map((sub: any) => sub.id === data.contract_id ? {
                            ...sub,
                            spot_status: data.spot_status
                        } : sub)
                    } : item
                })
            } else {
                const {hiredVessels} = state;
                state.hiredVessels = hiredVessels.map((item: any) => item.id === vesselId ? {
                    ...item,
                    contracts: item.contracts.map((sub: any) => sub.id === data.contract_id ? {
                        ...sub,
                        spot_status: data.spot_status
                    } : sub)
                } : item)
            }
        },
        updateContract: (state, action) => {
            const {vesselId, ...data} = action.payload;
            const {ownVessels} = state;
            state.loading = {...state.loading, approve: false};
            state.ownVessels = ownVessels.map((item: any) => item.id === vesselId ? {
                ...item,
                contracts: item.contracts.map((sub: any) => sub.id === data.id ? {
                    ...sub,
                    permission: data.permission
                } : sub)
            } : item)
        },
        addVessel: (state, action: PayloadAction<IVessel>) => {
            const vessel = action.payload;
            const {ownVessels} = state;
            state.ownVessels = [...ownVessels, vessel].sort((a, b) => a.name.localeCompare(b.name));
        },
        updateVessel: (state, action: PayloadAction<IVessel>) => {
            const vessel = action.payload;
            const {id} = vessel;
            const {ownVessels} = state;
            state.ownVessels = ownVessels.map(item => item.id === id ? vessel : item);
        },
        getListRequest: (state) => {
            state.loadingList = true;
        },
        getListSuccess: (state, action) => {
            const {ownVessels, hiredVessels} = action.payload;
            state.ownVessels = ownVessels;
            state.hiredVessels = hiredVessels;
            state.loadingList = false
        },
        getListFailure: (state) => {
            state.loadingList = false
        },
    },
})

export const vesselManagementActions = vesselManagementSlice.actions

const vesselManagementReducer = vesselManagementSlice.reducer;

export default vesselManagementReducer;
