import React from 'react';
import Note from "../Note";
import {IOperation, IPropsChildren} from "../../../../../util/varibles/interface";
import {openPopup} from "../../../Component/WrapperPopup";
import {OP_STATUS} from "../../../../../util/varibles/constants";
import Site from "../../../../../pages/PlanOperationPage/Popup/Site";
import {useSelector} from "react-redux";
import {selectOpMode, selectTenantId} from "../../../../../util/store/selectors";
import {getTimeByOpMode} from "../../../../../pages/PlanOperationPage/util/function_operation/constants";
import {datetime} from "../../../../../util/library/datetime";

interface IProps extends IPropsChildren {
    isEdit: boolean
    data: IOperation

    handleUpdateNote(note: string): void
}

const BoxOpInfo: React.FC<IProps> = (props) => {
    const opMode = useSelector(selectOpMode);
    const userTenantId = useSelector(selectTenantId);
    const {isEdit, handleUpdateNote} = props;
    const {
        note = '',
        status,
        site_id,
        source_id,
        site_name,
        source_name,
        sub_operations,
        tenant_id
    } = props.data.operation;
    const id = site_id || source_id;
    const isAnalysis = status !== OP_STATUS.CANCELED && id && tenant_id === userTenantId

    const openSite = () => {
        const start = getTimeByOpMode[opMode](props.data.activity_log[0])
        const el = openPopup(<Site
            site={{id, name: site_name || source_name}}
            unitId={sub_operations[0].id}
            year={datetime(start).year}
            onClose={() => el.remove()}
        />)
    }

    return <div className='mt-10 text-[15px]'>
        <div className='font-w4 mb-10'>Operation info. {isAnalysis &&
            <span className='link ml-2' onClick={openSite}>Analytics</span>}</div>
        {props.children}
        {(note || '').length > 0 && <Note {...{isEdit, note, handleUpdate: handleUpdateNote}}/>}
    </div>;
};

export default BoxOpInfo;
