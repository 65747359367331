import React from 'react';
import styles from './style.module.scss';
import {diseasedZone, diseaseType, liceOpts, LineKey, lineStatus} from "../../../../util/varibles/constants";
import DiseaseStatus from "../../../../components/Location/FishDisease/DiseaseStatus";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {uppercaseFirstLetter} from "../../../../util/varibles/global";

interface IProps {
    data: any
    unit_diseases?: any
    is_lice?: boolean
}

const FishDisease: React.FC<IProps> = ({data = [], unit_diseases, is_lice = true}) => {
    const {diseases = [], cases = [], lice = {}} = data[0] || {};
    const {disease = null, status = ''} = cases[0] || {};
    const {message = ''} = diseaseType[disease] || {};
    const {types = []} = unit_diseases || {};
    const isEnableLice = Object.keys(lice || {}).length > 0;
    const bigNumber = +lice[LineKey.adultFemaleLice]?.value;

    return <div className={styles.wrapper}>
        <div className={styles.logo}>
            {!isNaN(bigNumber) && <div className={styles.liceNumber}>{Math.round(bigNumber * 100) / 100}</div>}
            {disease && <DiseaseStatus status={disease} size='large'/>}
        </div>
        <div className={styles.subLogo}>
            {is_lice && <div>
                {isEnableLice
                    ? <>
                        <span>Female lice per fish</span>
                        <span className="text-comment">(Lice threshold: 0.5)</span>
                    </>
                    : 'Has not counted lice this week'}
            </div>}
            <div>{disease ? uppercaseFirstLetter([status, message].join(' ')) : 'Neither ISA nor PD confirmed / suspected'}</div>
        </div>
        {isEnableLice && <div className={styles.liceDetail}>
            {liceOpts.map(item => {
                const {
                    state,
                    value = (item.sum || []).reduce((rs: number, subId: LineKey) => rs + lice[subId]?.value || 0, 0),
                } = lice[item.id] || {}
                const {nameCss} = lineStatus[state] || {};
                return <div className={styles.line} key={item.id}>
                    <div data-lev='name'>{item.name}</div>
                    <div>{typeof (+value) === 'number' ? (Math.round(value * 100) / 100) : value}</div>
                    <div className={styles.arrow} data-status={nameCss}></div>
                </div>
            })}
        </div>}
        {types.length > 0 && <div className={styles.unitDiseases}>
            <Icon icon={ICON_NAME.DISEASE}/>
            <span>Disease:</span>
            <span>{types.map((item: any) => item.name).join(', ')}</span>
        </div>}
        <ul className={styles.diseases}>
            {diseases.map((item: any, index: any) => {
                const {name = ''} = diseasedZone[item.type] || {};
                const time = item.forskNr.split('FOR-');
                const link = `https://lovdata.no/dokument/LF/forskrift/${time[1]}`;

                return <li key={index}>
                    <span className='text-[15px] mr-10'>The site is in {name}</span>
                    (<a className='text-[15px] link font-w4' href={link}>{item.forskNr}</a>)
                </li>
            })}
        </ul>
    </div>;
};

export default FishDisease;