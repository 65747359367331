import React, {useCallback, useEffect, useState} from 'react';
import Icon, {ICON_NAME} from "../../Icon";
import styles from './style.module.scss';
import {DatePicker, notification} from "antd";
import {updateTimeStepVesselSideFetch} from "../../../util/services/vessel-side";
import {IOperation} from "../../../util/varibles/interface";
import {useDispatch} from "react-redux";
import {loginActions} from "../../../pages/LoginPage/reducer";
import {datetime} from "../../../util/library/datetime";
import {propContainer} from "../../../util/varibles/constants";
import LoadingBox from "../../LoadingBox";

let abort = new AbortController();

const formatTime = {format: 'HH:mm'};

interface IProps {
    data: IOperation
    time: number
    step: number

    onUpdate?(operation: any): void
}

const EditTime: React.FC<IProps> = ({data, time, step, onUpdate}) => {
    const {operation} = data;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onClickOutSide = useCallback((e: any) => {
        if (e.target.closest(`.${styles.button}`)) {
            return false;
        }

        if (!e.target.closest(`.${styles.popup}`)) {
            setOpen(false);
            document.removeEventListener('click', onClickOutSide);
        }
    }, []);

    useEffect(() => {
        return () => {
            setOpen(false);
            document.removeEventListener('click', onClickOutSide);
            if (abort)
                abort.abort();
        }
    }, [onClickOutSide]);

    const onClick = () => {
        if (loading)
            return;
        setOpen(!open);
    };

    function save(value: any) {
        const newTime = datetime(value).time;

        if (newTime !== time) {
            const params = {
                operation_id: operation.id,
                tenant_id: operation.tenant_id,
                time: newTime,
                index: step
            };
            abort = new AbortController();
            setLoading(true);
            new Promise(resolve => resolve(updateTimeStepVesselSideFetch(params, abort.signal)))
                .then((rs: any) => {
                    setLoading(false);
                    const {operation, activity_log} = rs;
                    const operationNew = {...data, activity_log, operation: {...data.operation, ...operation}};
                    dispatch(loginActions.updateOpOfVessel(operationNew))
                    if (onUpdate)
                        onUpdate(operationNew)
                    notification.success({message: 'Update time successfully'})
                })
                .catch(error => {
                    console.log(error)
                    setLoading(false);
                    notification.error({message: 'Update time failed'})
                })
        }
        setOpen(false);
        document.removeEventListener('click', onClickOutSide);
    }

    return <div className={styles.wrapper}>
        <div
            className={styles.button}
            data-loading={loading}
            onClick={onClick}
            onMouseUp={() => {
                if (!open) {
                    document.addEventListener('click', onClickOutSide);
                }
            }}
        >
            {!loading && <Icon icon={ICON_NAME.EDIT} size='large'/>}
            <div className={styles.loading}>
                <LoadingBox loading={true} spinnerSize={18}/>
            </div>
        </div>
        <DatePicker
            {...propContainer}
            value={datetime(time).value}
            className={styles.picker}
            open={open}
            showTime={formatTime}
            panelRender={(node: any) => <div className={styles.popup}>{node}</div>}
            onChange={save}
        />
    </div>;
};

export default EditTime;
