import React, {Component} from 'react';
import styles from "./style.module.scss";
import {Spin} from "antd";

interface IProps {
}

class Loading extends Component<IProps> {
    render() {
        return <div className={styles['loading']}>
            <Spin/>
        </div>;
    }
}

export default Loading;