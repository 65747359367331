import React from 'react';
import styles from "../style.module.scss";
import {useSelector} from "react-redux";
import {selectVessel} from "../../../util/store/selectors";

interface IProps {
}

const Title: React.FC<IProps> = () => {
    const vessel = useSelector(selectVessel.vessel);

    return <div className={styles.header}>
        <div className={styles['vessel-name']}>
            <span>{vessel && vessel.name}</span>
        </div>
    </div>;
};

export default Title;
