import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectVessel} from "../../../../util/store/selectors";
import styles from './style.module.scss'
import {vesselDetailActions} from "../../reducer";
import GoogleMap from "../../../../contexts/WorldMapContext/GoogleMap";
import {Checkbox} from "antd";
import {useMap} from "@vis.gl/react-google-maps";
import {VesselMarker} from "../../../../contexts/WorldMapContext/Marker";

interface IProps {
    currentData: any
}

const Map: React.FC<IProps> = React.memo(({currentData}) => {
    const isTracking = useSelector(selectVessel.isTracking);
    const vessel = useSelector(selectVessel.vessel);
    const dispatch = useDispatch();
    const map = useMap();
    const updateTracking = useCallback((value: boolean) => {
        dispatch(vesselDetailActions.update({isTracking: value}))
    }, [dispatch]);

    const {gps} = currentData;
    const {Latitude = null, Longitude = null} = gps || {};

    useEffect(() => {
        if (isTracking && gps) {
            if (Latitude !== null && Longitude !== null) {
                map?.setCenter({lat: +Latitude, lng: +Longitude});
                map?.setZoom(8)
            }
        }
    }, [isTracking, gps, map]);

    return <>
        <div className='w-full h-full'>
            <GoogleMap onDragstart={() => updateTracking(false)}>
                {(Latitude !== null && Longitude !== null) && <VesselMarker data={{...vessel, gps}}/>}
            </GoogleMap>
        </div>
        <div className={styles['menu-map']}>
            <Checkbox {...{
                className: styles['track-vessel'],
                checked: isTracking,
                onChange: () => updateTracking(!isTracking)
            }} >
                Track vessel
            </Checkbox>;
        </div>
    </>
}, (prev, next) => JSON.stringify(prev.currentData) === JSON.stringify(next.currentData));

export default Map;

