import React from 'react';
import styles from './style.module.scss';
import {levelDensity, LEVEL_DENSITY} from "../constants";
import {operatorObject} from "../../../WeatherPage/util";
import Condition from "./Condition";

interface IProps {
    value: number[]
}

const Conditions: React.FC<IProps> = ({value = []}) => {
    const source = value.filter(item => item.toString().length > 0);
    if (source.length === 0)
        return null;

    const low = {
        ...levelDensity[LEVEL_DENSITY.LOW],
        name: <>{levelDensity[LEVEL_DENSITY.LOW].name} <span>{operatorObject['<='].labelHtml}</span> {source[0]} t/&#13221;</>
    }

    const high = {
        ...levelDensity[LEVEL_DENSITY.HIGH],
        name: <>{levelDensity[LEVEL_DENSITY.HIGH].name} <span>{operatorObject['>'].labelHtml}</span> {source[source.length - 1]} t/&#13221;</>
    };

    let list;
    if (source.length > 1) {
        const medium: any = [];
        for (let i = 0; i < source.length - 1; i++) {
            if (source[i + 1].toString().length > 0) {
                const {name = ''} = levelDensity[LEVEL_DENSITY.MEDIUM][i] || {};
                medium.push({
                    ...levelDensity[LEVEL_DENSITY.MEDIUM][i] || {},
                    name: <>{source[i]} t/&#13221;<span>{'<'}</span>{name}
                        <span>{operatorObject['<='].labelHtml}</span>{source[i + 1]} t/&#13221;</>
                })
            }
        }
        list = [low, ...medium, high]
    } else {
        list = [low, high]
    }

    return <div className={styles.conditions}>
        {list.map((item, i) => <Condition key={i} background={item.color} name={item.name}/>)}
    </div>;
};

export default Conditions;
