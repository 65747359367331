import React, {useCallback, useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {IDisease} from "../../../util/varibles/interface";
import styles from "./style.module.scss";
import Header from "../../../components/Popup/Component/Header";
import {FormInstance} from "antd/lib/form";
import {showErrorResponse} from "../../../util/varibles/global";
import {propsModal} from "../../../util/varibles/constants";

const rules = {
    name: [{required: true, message: `"Name" is required`}]
}

interface IProps {
    open: boolean
    data: any
    title: string
    okText?: string

    request(params: any): Promise<IDisease>

    onOk(data: IDisease): void

    onClose(): void
}

const PopupEdit: React.FC<IProps> = ({open, data, title, okText = 'Save', request, onOk, onClose}) => {
    const formRef = React.createRef<FormInstance>();
    const [visible, setVisible] = useState(open);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values: any) => {
        setLoading(true);
        const params = {...data, ...values};
        request(params)
            .then((rs: any) => {
                setLoading(false);
                onOk(rs);
                handleClose();
            })
            .catch((error) => {
                setLoading(false);
                showErrorResponse(title + ' failed', error);
            })
    }

    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            setTimeout(onClose, 300);
    }, [onClose]);

    return <Modal
        {...propsModal}
        className={styles.modal}
        title={<Header title={title} onClose={handleClose}/>}
        onCancel={handleClose}
        open={visible}
    >
        <Form
            ref={formRef}
            onFinish={handleSubmit}
            layout='vertical'
            initialValues={data}
        >
            <Form.Item className={styles['picker-date']} name="name" label='Name'
                       rules={rules.name}
            >
                <Input className='h-36'/>
            </Form.Item>
            <Form.Item className={styles['select-speed']} name="description" label='Description'>
                <Input className='h-36'/>
            </Form.Item>
            <Button
                className={[styles.button, 'bt-primary'].join(' ')}
                data-full={true}
                loading={loading}
                htmlType='submit'
            >
                {okText}
            </Button>
        </Form>
    </Modal>;
};

export default PopupEdit;