import React from 'react';
import styles from './style.module.scss';
import LoadingBox from "../../../../../components/LoadingBox";


interface IProps {
    id?: string
    loading: boolean
    isFull?: boolean
}

const Loading: React.FC<IProps> = ({id, loading, isFull = true}) => {
    const handleContentMenu = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    return <div
        id={id}
        className={['container-spinner', styles.loading].join(' ')}
        data-loading={loading}
        data-is-full={isFull}
        onContextMenu={handleContentMenu}
    >
        {loading && <LoadingBox loading={true} spinnerSize={20}/>}
    </div>;
};

export default Loading;
