import React, {ReactNode, useMemo} from 'react';
import {datetime} from "../../../../../../../util/library/datetime";
import styles from "./style.module.scss"
import Density from "../../../../../../../components/Operation/Density2";
import {listOfStep} from "../../../../../../../util/varibles/constants";
import {cloneObj, durationTxt, formatNumber} from "../../../../../../../util/varibles/global";
import Menu from "../../../../../../../components/Menu";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import {PullRequestOutlined} from "@ant-design/icons";
import {IRoundPlan} from "../../../../../../../util/varibles/interface";
import {useRoundMap} from "../../../../../../../contexts/RoundMapContext";

const convertJobsKey = (keys: string[]) => {
    return keys.reduce((rs: string[], key: string) => {
        const {name = ''} = listOfStep[key] || {};
        if (name.length > 0) {
            rs.push(name)
        } else
            rs.push(key)
        return rs
    }, [])
}


const Info: React.FC<{
    data: any
    name: string
    total_weight?: number
    max_density?: number
    menu: { key: string, label: ReactNode }[]
}> = ({data, name, total_weight, max_density, menu}) => {
    const {keys = [], density} = data || {};

    return <Menu trigger={['contextMenu']} items={menu} placement={"bottom"}>
        <div className={styles.tank}>
            <div className={['h-[52px]', styles.tankInfo].join(' ')} data-lev='job'>
                <span>{total_weight ? <>{formatNumber(Math.round(total_weight / 1000) / 1000)}t</> : '--'}</span>
                <span>
                {keys.length > 0 ? <>{convertJobsKey(keys).join(', ')}: {durationTxt(data.duration)}</> : '--'}
            </span>
            </div>
            <div className={['h-[24px]', styles.tankInfo].join(' ')}>
                <span>{name}</span>
                {density !== undefined ?
                    <Density  density={density} max_density={max_density} /> :
                    <div>--</div>}
            </div>
        </div>
    </Menu>;
};

const Job: React.FC<{ data: { keys: string[], [key: string]: any } }> = ({data}) => {
    const {start_time, finish_time, keys = [], waiting_equipment} = data;
    const names = Array.from(keys);

    return <div className='relative'>
        <div className={styles.time}>
            {waiting_equipment && <span className='text-warn text-right pr-3 pt-[5px] mr-1 whitespace-nowrap'>
                ({durationTxt(waiting_equipment)}) Waiting for equipment
            </span>}
            <span>{datetime(start_time).format('HH:mm')}</span>
        </div>
        <Label
            className='pr-3'
            names={convertJobsKey(names).map(key => key.replace('unit', data.destination_name))}
            bold={400}
        />
        <div className={styles.arrow}/>
        <div className={styles.time}>{datetime(finish_time).format('HH:mm')}</div>
    </div>;
};

const Label: React.FC<{ names: string[], className?: string, bold?: number }> = ({
                                                                                     names = [],
                                                                                     className,
                                                                                     bold = 600
                                                                                 }) => {
    return <div className={`flex flex-col items-end py-[5px] ${className} min-h-[32px]`}>
        {names.map((item, i) => <span key={i} className={`font-[${bold}] text-[14px]`}>
            {item}
        </span>)}
    </div>;
};

interface IProps {
    data: IRoundPlan['tanks'][0]
    nextUnit: boolean

    onParallel?(): void

    onChangeTank?(isAdd: boolean): void
}

const Tank: React.FC<IProps> = React.memo(({
                                               data,
                                               nextUnit,
                                               onParallel = () => null,
                                               onChangeTank = () => null
                                           }) => {
    const {start_places = [], finish_places = [], processes = [], tank, total_weight, density, max_density} = data;
    const {isEdit} = useRoundMap();

    const {loading, tankData, tasks} = useMemo(() => {
        const jobs: IRoundPlan['tanks'][0]['processes'] = cloneObj(processes);
        const first = (jobs || []).shift() || {keys: []};
        const result: any = {};
        if ((first.keys || []).some((key: string) => key === 'loading')) {
            result.loading = <Job data={first}/>;
            result.tasks = jobs;
        } else {
            result.loading = <div className='h-[76px]'/>;
            result.tasks = cloneObj(processes);
        }
        const tankIndex = result.tasks.findIndex((job: any) => job.type === 'tank');
        const tankData = tankIndex > -1 ? result.tasks.splice(tankIndex, 1)[0] : {}

        return {...result, tankData}
    }, [processes]);

    const menu = useMemo(() => isEdit ? total_weight ? [{
        key: 'delete',
        label: <div
            className='menu-line'
            onClick={() => onChangeTank(true)}
        >
            <Icon icon={ICON_NAME.ADD}/>
            <span>Add tank</span>
        </div>
    }, {
        key: 'delete',
        label: <div
            className='menu-line'
            data-danger='true'
            onClick={() => onChangeTank(false)}
        >
            <Icon icon={ICON_NAME.DELETE} danger/>
            <span>Delete tank</span>
        </div>
    }] : (nextUnit ? [{
        key: 'merge',
        label: <div className='menu-line' onClick={onParallel}>
            <PullRequestOutlined/>
            <span>Allow processing of next unit</span>
        </div>
    }] : []) : [], [total_weight, nextUnit, onParallel, onChangeTank, isEdit]);

    return <div>
        <Label names={start_places}/>
        {loading}
        <Info
            menu={menu}
            data={{...tankData, density: tankData?.density || density}}
            name={tank}
            total_weight={total_weight}
            max_density={max_density}
        />
        {tasks.map((item: any, i: number) => <Job key={i} data={item}/>)}
        <Label names={finish_places}/>
    </div>;
}, (prev, next) => JSON.stringify(prev.data) === JSON.stringify(next.data));

export default Tank;