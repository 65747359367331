import {aDayMillisecond, aHourMillisecond, aMinutesMillisecond} from "../../../util/varibles/constants";
import {datetime} from "../../../util/library/datetime";

export const listOfTimeType = [
    {
        textForOne: 'a minute ago', suffix: ' mins ago', step: aMinutesMillisecond, getCount: (time: any) => {
            const sec = datetime(time).second;
            return aMinutesMillisecond - (sec * 1000)
        }
    },
    {
        textForOne: 'an hour ago', suffix: ' hours ago', step: aHourMillisecond, getCount: (time: any) => {
            const min = datetime(time).minute;
            const sec = datetime(time).second;
            return aHourMillisecond - (min * aMinutesMillisecond) - (sec * 1000);
        }
    },
    {
        textForOne: 'a day ago', suffix: ' days ago', step: aDayMillisecond, getCount: (time: any) => {
            const hour = datetime(time).hour;
            const min = datetime(time).minute;
            const sec = datetime(time).second;
            return aDayMillisecond - (hour * aHourMillisecond) - (min * aMinutesMillisecond) - (sec * 1000);
        }
    }
]