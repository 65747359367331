import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IStateGlobal} from "../../../util/varibles/interface";
import ChatBox from "../../../components/Chat/ChatBox";
import {messageActions} from "../../MessagePage/reducer";
import {CHAT_TYPE} from "../../../util/varibles/constants";
import styles from './style.module.scss';

const mapStateToProps = (state: IStateGlobal) => ({
    messages: state.message.messages
})


interface IProps {
    messages: any
    id: string

    sendMessage(payload: any): void
}

class Chat extends Component<IProps> {
    handleSend = (params: any) => {
        this.props.sendMessage({...params, type: CHAT_TYPE.OPERATION, group_id: this.props.id})
    }

    render() {
        const {id} = this.props;
        const {messages = [], pref: isMore = false, loading = false} = this.props.messages[id] || {}
        return <div className={styles.chat}>
            <ChatBox
                data={{
                    type: CHAT_TYPE.OPERATION,
                    group_chat: {id, members: []}
                }}
                messages={messages}
                loading={loading}
                isMore={isMore}
                onSend={this.handleSend}
            />
        </div>;
    }
}


export default connect(mapStateToProps, {
    sendMessage: messageActions.sendMessageRequest
})(Chat);