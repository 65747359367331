import React from 'react';
import styles from "./style.module.scss";
import {convertNumberTimeToStringTime, uppercaseFirstLetter} from "../../../../../../util/varibles/global";
import Note from "./Note";

interface IProps {
    data: any
}

const ServiceTable: React.FC<IProps> = (props) => {
    const {tasks, sub_operations = []} = props.data.operation;
    const sub = sub_operations.reduce((rs: any, item: any) => {
        rs[item.id] = item;
        return rs;
    }, {});

    const list: any = tasks.reduce((rs: any, item: any) => {
        const {id: unitId, unit_id} = item.sub_operation;
        if (rs[unitId]) {
            rs[unitId].tasks.push(item);
        } else {
            const {note = ''} = sub[unitId] || {};
            rs[unitId] = {
                note,
                unit_id,
                tasks: [item]
            }
        }
        return rs;
    }, {});

    return <div className={styles.wrapper}>
        <div className={styles.table}>
            <div className={styles.header}>
                <div>Task</div>
                <div>Equipment</div>
                <div>Duration</div>
            </div>
            {Object.keys(list).map((key) => {
                const {note, unit_id, tasks} = list[key];
                return [
                    <div className={styles.title} key={[key, 'title'].join(' ')}>
                        <div className={styles.unitId}>{unit_id}</div>
                        <Note data={note}/>
                    </div>,
                    tasks.map((sub: any) => <div className={styles.line} key={sub.id}>
                        <div>{uppercaseFirstLetter(sub.name) || '-'}</div>
                        <div>{sub.capabilities.map((sub: any) => uppercaseFirstLetter(sub.name)).join(', ')}</div>
                        <div>{convertNumberTimeToStringTime(sub.duration)}</div>
                    </div>)
                ]
            })}
        </div>
    </div>;
};

export default ServiceTable;
