import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IRoute} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import RouteItem from "./RouteItem";
import InputCheckbox from "../../../../components/Basic/Checkbox";
import {Spin} from "antd";
import Empty from "./Empty";
import {routeActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    routes: state.route.routes,
    params: state.route.params,
    isShowAll: state.route.isShowAll,
    loading: state.route.loading.routes,
});

interface IProps {
    routes: IRoute[]
    params: any
    isShowAll: boolean
    loading: boolean

    changeShowAll(): void
}

class RouteTable extends Component<IProps> {

    render() {
        const {params, isShowAll, loading} = this.props;
        const {source, destination} = params;
        if (!source || !destination) return null;
        const routes = [...this.props.routes] || []
        return <Spin spinning={loading}>
            <div className={styles['container-routes']}>
                {routes.length === 0 && !loading && <Empty/>}
                {routes.length > 0 && <>
                    <InputCheckbox
                        className='mr-2'
                        label="Show all routes"
                        lineHeight="34px"
                        reverse="1"
                        checked={isShowAll}
                        width='100%'
                        onChange={this.props.changeShowAll}
                    />
                    {routes.map((item, index) => <RouteItem key={item.id} data={item} index={index}/>)}
                </>}
            </div>
        </Spin>;
    }
}

export default connect(mapStateToProps, {
    changeShowAll: routeActions.changeShowAll
})(RouteTable);
