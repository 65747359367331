import {all, call, put, takeEvery} from "redux-saga/effects";
import {getDevicesFetch, getSensorsDataFetch} from "../../util/services/device";
import {getOperationByVesselFetch, getPositionVessels2Fetch} from "../../util/services/vessel";
import {getDashboardsOfVesselFetch} from "../../util/services/dashboard";
import {vesselDetailActions} from "./reducer";
import {PayloadAction} from "@reduxjs/toolkit";

function* getVesselDetailWatcher(args: PayloadAction<{ id: string, callback?: Function }>): Generator<any, any, any> {
    try {
        const {id: vesselId, callback} = args.payload;
        const {vessel} = yield call(getOperationByVesselFetch, vesselId);
        const {iot_name} = vessel;
        const location = yield call(getPositionVessels2Fetch, [iot_name]);
        yield put(vesselDetailActions.getVesselSuccess({vessel, location: location[0] || {}, callback}));
        const devices = yield call(getDevicesFetch, iot_name)
        yield put(vesselDetailActions.getDevices(devices));
    } catch (error) {
        yield put(vesselDetailActions.getVesselFailure())
    }
}

function* getDashboardsWatcher(args: PayloadAction<string>): Generator<any, any, any> {
    try {
        const vesselId = args.payload;
        const dashboards = yield call(getDashboardsOfVesselFetch, vesselId);
        const dashboard = dashboards.find((item: any) => item.is_default);
        if (!dashboard) {
            yield put(vesselDetailActions.getDashboardsSuccess({dashboards, dashboard, keys: []}));
            return;
        }
        const content = JSON.parse(dashboard.content);
        const keys: any = [];
        const params = content.reduce((list: any, {devices = [], sensors = []}: any) => [
            ...list,
            ...devices.reduce((listDevices: any, {device_id}: any) => [
                ...listDevices,
                ...sensors.reduce((listSensors: any, {name}: any) => {
                    const isExist = list.some((param: any) => param.device_id === device_id && param.sensor === name);
                    keys.push({device_id, sensor: name, key: device_id + '/' + name});
                    return isExist ? listSensors : [...listSensors, {device_id, sensor: name}]
                }, [])
            ], [])
        ], [])
        yield put(vesselDetailActions.getDashboardsSuccess({dashboards, dashboard, keys}));
        if (params.length > 0)
            yield put(vesselDetailActions.getCurrentSensorDataRequest(params));
    } catch (error) {
        yield put(vesselDetailActions.getDashboardsFailure());
    }
}


function* getCurrentSensorDatWatcher({...args}): Generator {
    try {
        const params = args.payload;
        const sensors = yield call(getSensorsDataFetch, params);
        yield put(vesselDetailActions.getCurrentSensorDataSuccess(sensors))
    } catch (error) {
        yield put(vesselDetailActions.getCurrentSensorDataFailure())
    }
}

export default function* vesselDetailSaga() {
    yield all([
        takeEvery(vesselDetailActions.getVesselRequest.type, getVesselDetailWatcher),
        takeEvery(vesselDetailActions.getDashboardsRequest.type, getDashboardsWatcher),
        takeEvery(vesselDetailActions.getCurrentSensorDataRequest.type, getCurrentSensorDatWatcher),
    ])
}
