import React, {Component} from 'react';
import {connect} from 'react-redux';
import stylesContainer from "../../style.module.scss";
import styles from './style.module.scss';
import {
    DELIVERY_TYPE,
    deliveryType,
    deliveryTypes,
    initAutoPlanConfig,
    initCloseTransport,
    initVesselConfig,
    VESSEL_TYPE,
    vesselType
} from "../../../../util/varibles/constants";
import {Checkbox, Radio} from "antd";
import {loginActions} from "../../../LoginPage/reducer";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {AppState} from "../../../../util/store/store";

const key = PreferenceSetting.AUTO_PLAN;
const mapStateToProps = (state: AppState) => {
    const autoPlanConfig = getSetting(state.login.user.setting, key);
    return {
        autoPlanConfig
    }
}


interface IProps {
    autoPlanConfig: any

    updateUserSetting(payload: any[]): void
}

class ConfigurePlan extends Component<IProps> {
    state = {
        autoPlanConfig: initAutoPlanConfig
    }

    constructor(props: IProps) {
        super(props);
        const {autoPlanConfig} = this.props;
        this.state = {
            autoPlanConfig
        }
    }

    handleChange = (autoPlanConfig: any) => {
        this.setState({autoPlanConfig})

        this.props.updateUserSetting([{[key]: autoPlanConfig}]);
    }

    render() {
        const {autoPlanConfig} = this.state;
        const {vessel = initVesselConfig, close_transport = initCloseTransport, merge}: any = autoPlanConfig;

        return <>
            <div className={stylesContainer.header}>
                <div>
                    <div className={stylesContainer.title}>
                        Configure plan operation
                    </div>
                    <div className={[stylesContainer['description'], 'mt-12'].join(' ')}>
                        Below conditions will be applied when using auto plan (for harvest - STHP browser).
                    </div>
                </div>
            </div>
            <div className={styles['wrapper-config']}>
                <div className={styles['wrapper-vessels']}>
                    <div className={styles['wrapper-vessel']}>
                        <div className={styles['name-vessel']} data-vessel-type={VESSEL_TYPE.WELL_BOAT}>
                            {vesselType[VESSEL_TYPE.WELL_BOAT].name}
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Delivery method</div>
                            <Radio.Group
                                value={vessel[VESSEL_TYPE.WELL_BOAT].delivery_type}
                                onChange={(e: any) => {
                                    vessel[VESSEL_TYPE.WELL_BOAT].delivery_type = e.target.value
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                <div className={styles['wrapper-options']}>
                                    {deliveryTypes.map(({id, name}) => <Radio key={id} value={id}>
                                        {name}
                                    </Radio>)}
                                </div>
                            </Radio.Group>
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Prioritize arrival time at</div>
                            <Radio.Group
                                value={vessel[VESSEL_TYPE.WELL_BOAT].priority}
                                onChange={(e: any) => {
                                    vessel[VESSEL_TYPE.WELL_BOAT].priority = e.target.value;
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                <div className={styles['wrapper-options']}>
                                    <Radio value={0}>Site</Radio>
                                    <Radio value={1}>Factory</Radio>
                                </div>
                            </Radio.Group>
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Add travelling to site step for harvest operation
                            </div>
                            <Checkbox
                                value={vessel[VESSEL_TYPE.WELL_BOAT].is_journey_to_site}
                                onChange={(value: any) => {
                                    vessel[VESSEL_TYPE.WELL_BOAT].is_journey_to_site = value;
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                Add travelling to site
                            </Checkbox>
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Add cleaning step for harvest operation</div>
                            <Checkbox.Group
                                value={vessel[VESSEL_TYPE.WELL_BOAT].cleaning_types}
                                onChange={(value: any) => {
                                    vessel[VESSEL_TYPE.WELL_BOAT].cleaning_types = value;
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                <div className={styles['wrapper-options']}>
                                    {deliveryTypes.map(({id, name}) => <Checkbox key={id} value={id}>
                                        {name} delivery
                                    </Checkbox>)}
                                </div>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div className={styles['wrapper-vessel']}>
                        <div className={styles['name-vessel']} data-vessel-type={VESSEL_TYPE.PROCESS_VESSEL}>
                            {vesselType[VESSEL_TYPE.PROCESS_VESSEL].name}
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Delivery method</div>
                            <Radio.Group
                                value={vessel[VESSEL_TYPE.PROCESS_VESSEL].delivery_type}
                                onChange={(e: any) => {
                                    vessel[VESSEL_TYPE.PROCESS_VESSEL].delivery_type = e.target.value;
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                <div className={styles['wrapper-options']}>
                                    <Radio value={DELIVERY_TYPE.DIRECT}>
                                        {deliveryType[DELIVERY_TYPE.DIRECT].name}
                                    </Radio>
                                </div>
                            </Radio.Group>
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Prioritize arrival time at</div>
                            <Radio.Group
                                value={vessel[VESSEL_TYPE.PROCESS_VESSEL].priority}
                                onChange={(e: any) => {
                                    vessel[VESSEL_TYPE.PROCESS_VESSEL].priority = e.target.value;
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                <div className={styles['wrapper-options']}>
                                    <Radio value={0}>Site</Radio>
                                    <Radio value={1}>Factory</Radio>
                                </div>
                            </Radio.Group>
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Add travelling to site step for harvest operation
                            </div>
                            <Checkbox
                                value={vessel[VESSEL_TYPE.PROCESS_VESSEL].is_journey_to_site}
                                onChange={(value: any) => {
                                    vessel[VESSEL_TYPE.PROCESS_VESSEL].is_journey_to_site = value;
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                Add travelling to site
                            </Checkbox>
                        </div>
                        <div className={styles['block-config']}>
                            <div data-block-type='title'>Add cleaning step for harvest operation</div>
                            <Checkbox.Group
                                value={vessel[VESSEL_TYPE.PROCESS_VESSEL].cleaning_types}
                                onChange={(value: any) => {
                                    vessel[VESSEL_TYPE.PROCESS_VESSEL].cleaning_types = value;
                                    this.handleChange({...autoPlanConfig, vessel})
                                }}
                            >
                                <Checkbox value={DELIVERY_TYPE.DIRECT}>Add cleaning step</Checkbox>
                            </Checkbox.Group>
                        </div>
                    </div>
                </div>
                <div className={styles['divide']}/>
                <div className={styles['block-config']}>
                    <div data-block-type='title'>
                        Merge
                    </div>
                    <div className={styles['wrapper-options']}>
                        <Checkbox
                            checked={merge}
                            onChange={(e: any) => {
                                this.handleChange({...autoPlanConfig, merge: e.target.checked})
                            }}
                        >
                            Merge harvests which having packing date less than <span
                            className='link'>5 days</span> apart.
                        </Checkbox>
                        <div className='text-comment font-italic font-s4'>If sum weight of these harvests less then a
                            vessel’s capacity
                        </div>
                    </div>
                </div>
                <div className={styles['block-config']}>
                    <div data-block-type='title'>
                        Prioritize to plan closed transport/ High mortality risk harvest for
                    </div>
                    <div className={styles['wrapper-options']}>
                        <Checkbox
                            checked={close_transport.process}
                            onChange={(e: any) => {
                                close_transport.process = e.target.checked;
                                this.handleChange({...autoPlanConfig, close_transport})
                            }}
                        >
                            Process vessel
                        </Checkbox>
                        <Checkbox
                            checked={close_transport.wellboat}
                            onChange={(e: any) => {
                                close_transport.wellboat = e.target.checked;
                                this.handleChange({...autoPlanConfig, close_transport})
                            }}
                        >
                            Or it can be Wellboat but prioritize to choose waiting cage/ tank delivery
                        </Checkbox>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting
})(ConfigurePlan);