import React from 'react';
import styles from "./style.module.scss";
import {OPERATION_TYPE} from "../../../../../../../util/varibles/constants";
import {Divider} from "antd";
import Certain from "../../../_Component/Certain";
import {cloneObj} from "../../../../../../../util/varibles/global";
import Operation from "./Operation";
import AddNote from "../../../_Component/AddNote";
import TankDensities from "../../../../../../../components/Operation/TankDensities";
import {TOperation} from "../../../../../../../util/varibles/interface";
import {useEditOp} from "../../../../../../../contexts/EditOperationContext";

interface IProps {
}

const Operations: React.FC<IProps> = () => {
    const [ops, set] = useEditOp(state => state.ops);

    const handleUpdateOp = (value: TOperation, index: number, isCalculate?: boolean) => {
        set(({ops, isCalculate: old}) => {
            const result = cloneObj(ops);
            result.splice(index, 1, value);
            return {ops: result, isCalculate: old || isCalculate}
        })
    }

    return <div className={styles['operations']}>
        {ops.length === 0 && <div className={styles['line']} data-lev='empty'>No trip</div>}
        <div className={[styles['body'], 'scroll-small'].join(' ')}>
            <div className={styles['line']} data-lev='header'>
                <div>Started unit</div>
                <div className='pl-3'>Deliv. site</div>
                <div className='pl-3'>Deliv. unit</div>
                <div className='text-center'>Amount</div>
                <div className='text-center'>Total weight</div>
                <div/>
            </div>
            {ops.map((item, index: number) => <div key={index} className={styles['container-op']}>
                <div className={styles['op-title']}>
                    <div className='pl-2' data-lev='title'>
                        <div>Trip {index + 1}</div>
                        <TankDensities
                            isEmpty
                            className={styles.tanks}
                            type={OPERATION_TYPE.TRANSPORT}
                            storages={(item.sub_destinations || [])[0].storages}
                        />
                    </div>
                    <div data-lev='action'>
                        <Certain
                            value={item.certain_mode}
                            onChange={certain_mode => handleUpdateOp({...item, certain_mode}, index)}
                        />
                        <Divider type='vertical'/>
                        <AddNote
                            title={item.operation_code.length > 0 ? `#${item.operation_code}` : `Trip ${index + 1}`}
                            value={item.note}
                            onChange={note => handleUpdateOp({...item, note}, index)}
                        />
                    </div>
                </div>
                <Operation
                    key={index}
                    data={item}
                    index={index}
                    onChange={value => handleUpdateOp(value, index, true)}
                />
            </div>)}
        </div>
    </div>;
};

export default Operations;
