import React, { Component } from 'react';
import ContainerElement from "./ContainerElement";
import { IPropsElement } from "./constants";
import { Input } from 'antd';

interface IProps extends IPropsElement {

}

class ShortTextElement extends Component<IProps> {
    render() {
        const { placeHolder } = this.props.properties;
        return <ContainerElement { ...this.props } >
            <Input placeholder={ placeHolder }/>
        </ContainerElement>;
    }
}

export default ShortTextElement;
