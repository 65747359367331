import React from 'react';
import styles from './style.module.scss';

interface IProps {
    username: string
}

const Author: React.FC<IProps> = ({username}) => {
    const [name] = username.split('@');
    const shortName = name.split('.');
    return <div className={styles.author}>
        <div className={styles.shortName}>{shortName.map(item => item[0])}</div>
        <div className={styles.name}>by {username}</div>
    </div>;
};

export default Author;
