import React, {Component} from 'react';
import Task from "../Task";
import styles from './style.module.scss';
import stylesContainer from "../../../style.module.scss";
import {formatNumber} from "../../../../../../util/varibles/global";
import {Divider} from "antd";
import {IOperation, IUnit} from "../../../../../../util/varibles/interface";
import TankDensities from "../../../../../Operation/TankDensities";
import {OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import UnitLice from "../../../../../Operation/UnitLice";
import Round from "../../../../../Operation/Round";

interface IProps {
    data: IOperation
}

class Tasks extends Component<IProps> {
    state = {
        focusId: ''
    }

    constructor(props: IProps) {
        super(props);
        const task = this.props.data.operation.tasks[0];
        const {id = ''} = task.sub_operation || {};
        this.state = {focusId: id}
    }


    componentDidMount() {
        setTimeout(() => {
            const task = this.props.data.operation.tasks[0];
            const {id = ''} = task.sub_operation || {};
            const el = document.getElementById(id);
            if (el) {
                const {parentElement} = el;
                if (parentElement)
                    parentElement.scrollLeft = el.offsetLeft;
            }
        }, 100)
    }

    handleClick = (e: any, key: string) => {
        this.setState({focusId: key});
    }


    render() {
        const {site, tasks, sub_operations, diseases = []} = this.props.data.operation;

        const {focusId} = this.state;

        const tasksByUnit = tasks.reduce((rs: any, item: any) => {
            const {
                id = null,
                unit_id = null,
            } = item.sub_operation || item.sub_operations[0] || {};
            if (!id)
                return rs

            let unit = sub_operations.find((sub: IUnit) => sub.id === id);
            if (!unit) {
                unit = item.sub_operation || item.sub_operations[0];
            }

            if (!unit)
                return rs;


            if (!rs[id]) {
                const {
                    avg_weight = 0,
                    fish_amount = 0,
                    total_weight = 0,
                    mortality_risk = false,
                    note = '',
                    storages,
                    lice,
                    round_number
                } = unit;

                rs[id] = {
                    name: unit_id,
                    avg_weight,
                    fish_amount,
                    total_weight,
                    mortality_risk,
                    note,
                    storages,
                    lice,
                    round_number,
                    list: [item]
                }
            } else
                rs[id].list = [...rs[id].list || [], item]
            return rs;
        }, {});

        const count = Object.keys(tasksByUnit).length;

        return <div className='mt-10'>
            <div className={styles.tabs}>
                {Object.keys(tasksByUnit).map((key) => {
                    return <div
                        key={key}
                        className={styles['tab-unit']}
                        data-active={key === focusId}
                        onClick={(e) => count > 1 ? this.handleClick(e, key) : null}
                    >
                        {[site.name, tasksByUnit[key].name].join(' - ')}
                    </div>
                })}
            </div>

            <div className={styles['container-tasks']}>
                {Object.keys(tasksByUnit).map(key => {
                    const {
                        list,
                        avg_weight = 0,
                        fish_amount = 0,
                        total_weight = 0,
                        note,
                        lice,
                        storages
                    } = tasksByUnit[key];
                    return <div key={key} className={styles.unit} data-active={key === focusId}>
                        <div className={styles['unit-info']}>
                            <div className={[stylesContainer['container-box'], 'mb-1 pl-1'].join(' ')}>
                                <div>Avg. weight:</div>
                                <div className='ml-1 font-w4'>{formatNumber(avg_weight)} gr</div>
                            </div>
                            <Divider type='vertical'/>
                            <div className={[stylesContainer['container-box'], 'mb-1 pl-1'].join(' ')}>
                                <div>Amount:</div>
                                <div className='ml-1 font-w4'>{formatNumber(fish_amount)}</div>
                            </div>
                            <Divider type='vertical'/>
                            <div className={[stylesContainer['container-box'], 'mb-1 pl-1'].join(' ')}>
                                <div>Total weight:</div>
                                <div className='ml-1 font-w4'>
                                    {Math.round(total_weight / 1000) / 1000} t
                                </div>
                            </div>
                        </div>
                        <Round unit={tasksByUnit[key]}/>
                        <div className='mb-[8px]'>
                            <UnitLice lice={lice || (diseases[0] || {}).lice}/>
                        </div>
                        <div className='mb-[8px]'>
                            <TankDensities type={OPERATION_TYPE.TREATMENT} storages={storages}/>
                        </div>
                        {note.length > 0 && <div className={[stylesContainer['container-box'], 'mb-10 pl-1'].join(' ')}>
                            <div>Unit's note:</div>
                            <div className='ml-[1px]'>{note}</div>
                        </div>}
                        <div className={styles.tasks}>
                            <div className='mr-[10px]' data-lev='title'>Task:</div>
                            <div data-lev='list'>
                                {list.map((item: any) => <Task
                                    key={item.group_id}
                                    data={item}
                                />)}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>;
    }
}

export default Tasks;