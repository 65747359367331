import React, {useEffect, useState} from 'react';
import styles from './style.module.scss'
import {aHourMillisecond, SHORT_TIME_FORMAT} from "../../../../../util/varibles/constants";
import {datetime} from "../../../../../util/library/datetime";

interface IProps {
    startPoint: number
}

const CurrentTimeLine: React.FC<IProps> = ({startPoint}) => {
    const [currentTime, setCurrentTime] = useState<number>(Date.now());
    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(Date.now()), 10000)
        return () => {
            clearInterval(interval)
        };
    }, []);

    const style = {top: `${(currentTime - startPoint) / aHourMillisecond * 5}px`};
    return <div id='current-timeline' className={styles['current-time-line']} style={style}>
        <span>{datetime(currentTime).format(SHORT_TIME_FORMAT)}</span>
    </div>;
};

export default CurrentTimeLine;

