import React from 'react';
import {useDispatch} from 'react-redux';
import stylesContainer from "../style.module.scss";
import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Filter from "./Filter";
import AddExternalSite from "../_Component/AddExternalSite";
import AvgWeight from "../_Component/AvgWeight";
import LiceSort from "../_Component/LiceSort";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import CountSelected from "../_Component/CountSelected";

interface IProps {
    searchValue: any
    weightFilter: [number | null, number | null],
    sites: any;
    liceSort: null | string

    handleSearch(value: { searchValue?: string, weightFilter?: [number | null, number | null] }): void
}


const HeaderSmoltComponent: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const handleChangeLice = (value: any) => {
        dispatch(loginActions.updateUserSetting([{[PlanSetting.SMOLT_LICE_SORT]: value}]))
    }

    const {weightFilter, liceSort, sites, handleSearch} = props;

    return <div className={stylesContainer['tab-header']}>
        <div className={stylesContainer['header-left']} data-operaion-type='spot'>
            <LiceSort lice={liceSort} onChange={handleChangeLice}/>
            <CountSelected/>
            <Input
                className={[stylesContainer['search']].join(' ')}
                placeholder="Search smolt site"
                allowClear
                onChange={({target: {value}}: any) => handleSearch({searchValue: value})}
                suffix={<SearchOutlined className='text-comment'/>}
            />
            <AvgWeight weight={weightFilter} onChange={value => handleSearch({weightFilter: value})}/>
        </div>
        <div className={stylesContainer['header-right']}>
            <Filter {...{sites}}/>
            <AddExternalSite/>
        </div>
    </div>;
};

export default HeaderSmoltComponent;
