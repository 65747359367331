import React, {Component} from 'react';
import {durationTxtByStartAndFinish, uppercaseFirstLetter} from "../../../../util/varibles/global";
import Operation from "../../_Component/Operation";
import Vessel from "../../_Component/Vessel";
import Route from "../../_Component/Route";
import stylesOp from "../../_Component/Operation/style.module.scss";
import AttachFile from "../../../../components/Popup/PopupOpInfo/Component/AttachFile";
import FishDisease from "../../../../components/Location/FishDisease";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import SectionRight from "../../_Component/SectionRight";
import ServiceTable from "../../../../components/Popup/PopupOpInfo/Info/Service/ServiceTable";
import WrapperActivityLog from "../../_Component/WrapperActivityLog";
import {IActivityLogByUnit} from "../../../../components/TrackingOp/ActivitiesLog/ActivityLogs2";
import {IOperation} from "../../../../util/varibles/interface";
import ViewNote from "../../_Component/ViewNote";
import PlannedTime from "../../_Component/PlannedTime";
import LiceList from "../../../../components/Operation/LiceList";

interface IProps {
    data: IOperation
    isOwn: boolean
    viewMode: VIEW_MODE
}

class Service extends Component<IProps> {

    render() {
        const {data} = this.props;
        const {note = '', files = [], sub_operations} = data.operation;
        const isNote = note.length === 0;

        const {activity_log} = data;
        const est_start_time = activity_log[0].est_start_time;
        const est_finish_time = activity_log[activity_log.length - 1].est_start_time;

        let tasks = JSON.parse(JSON.stringify(data.operation.tasks));

        const tasksObject = tasks.reduce((rs: any, item: any) => {
            rs[item.id] = item;
            return rs;
        }, {})

        const activityLog: IActivityLogByUnit[] = activity_log.reduce((list: any, item: any, index: number) => {
            const [prefix, taskId] = item.key.split('_');

            const {name = ''} = tasksObject[taskId] || {};
            const steps = [{id: taskId, name: uppercaseFirstLetter([prefix, name].join(' '))}];
            const {unit_id} = item;
            const isExist = list.findIndex((sub: any) => sub.id === unit_id);
            const value = {...item, steps, index};
            if (isExist === -1) {
                return [...list, {id: unit_id, name: unit_id, list: [value]}];
            }
            list[isExist].list.push(value);
            return list
        }, []);

        return <>
            <div>
                <Operation {...{isNote}}>
                    <div className={stylesOp['info-line']}>
                        <span>Operation type:</span>
                        <span>Service</span>
                    </div>
                    <PlannedTime activity_log={activity_log}/>
                    <div className={stylesOp['info-line']}>
                        <span>Planned duration: </span>
                        <span>{durationTxtByStartAndFinish(est_start_time, est_finish_time)}</span>
                    </div>
                    <div className={stylesOp['info-line']}>
                        <AttachFile files={files}/>
                    </div>
                    <FishDisease diseases={data.operation.diseases || []} units={sub_operations} wrapperClass='mb-5'/>
                    <LiceList diseases={data.operation.diseases || []}/>
                    <div className={stylesOp['info-line']}>
                        <ServiceTable data={data}/>
                    </div>
                    <ViewNote data={this.props.data} isOwn={this.props.isOwn} note={note}/>
                </Operation>
                <Vessel/>
                <Route/>
            </div>
            <SectionRight>
                <WrapperActivityLog
                    isOwn={this.props.isOwn}
                    data={this.props.data}
                    activity_log={activityLog}
                    activity_log_root={activity_log}
                />
            </SectionRight>
        </>;
    }
}

export default Service;
