import React, {Component} from 'react';

import styles from './style.module.scss';
import HarvestView from "../../../../PlanOperationPage/_Component/ContentOperation/HarvestView";
import {IActivityLog} from "../../../../../util/varibles/interface";

interface IProps {
    operation: any
    activity_log: IActivityLog[]
    vessel: any
}

class Operation extends Component<IProps> {
    render() {
        const {operation, activity_log, vessel} = this.props;

        return <div className={styles.operation}>
            <HarvestView status={operation.status} isOwn={true} data={{operation, vessel, activity_log}}/>
        </div>;
    }
}

export default Operation;
