import React, {useEffect, useState} from 'react';
import {ICapability, IServiceTask} from "../../../../../../../util/varibles/interface";
import styles from './style.module.scss';
import {uppercaseFirstLetter} from "../../../../../../../util/varibles/global";
import {Dropdown, notification} from "antd";
import {propContainer} from "../../../../../../../util/varibles/constants";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../../../util/store/selectors";

const getCapabilitiesIdsOfTask = (list: IServiceTask[], taskType: string) => {
    const {capabilities = []} = list.find(item => item.id === taskType) || {}
    return capabilities.map(item => item.id)
}

interface IProps {
    serviceTasks: IServiceTask[]
    taskId: string
    value: ICapability[]

    onChange(value: any): void
}

const SelectCapabilities: React.FC<IProps> = ({serviceTasks, taskId, value = [], onChange}) => {
    const [required, setRequired] = useState(new Set(getCapabilitiesIdsOfTask(serviceTasks, taskId)));
    const [open, setOpen] = useState(false);
    const {vessel} = useSelector(selectPlan.popupEdit)?.values || {};
    const {capabilities = []} = vessel || {}

    useEffect(() => {
        setRequired(new Set(getCapabilitiesIdsOfTask(serviceTasks, taskId)));
    }, [serviceTasks, taskId]);

    const selectIds = new Set(value.map((item: any) => item.id));

    const handleChange = (id: any) => {
        if (required.has(id)) {
            notification.error({message: `This is a mandatory equipment that you can't delete`})
            return;
        }

        if (selectIds.has(id)) {
            onChange(value.filter((item: any) => item.id !== id));
        } else {
            onChange([...value, capabilities.find(item => item.id === id)])
        }
    };

    const items = [
        {
            key: '1',
            disabled: true,
            label: <div className={styles.options}>
                {capabilities.map(item => <div
                    key={item.id}
                    className={[styles.box, styles.option].join(' ')}
                    data-active={selectIds.has(item.id)}
                    data-required={required.has(item.id)}
                    onClick={() => handleChange(item.id)}
                >
                    {uppercaseFirstLetter(item.name)}
                </div>)}
            </div>,
        }
    ]

    return <div className={styles.wrapper} data-open={open}>
        <Dropdown
            {...propContainer}
            menu={{items}}
            placement="bottomLeft"
            arrow={false}
            trigger={['click']}
            overlayClassName={styles.dropdown}
            onOpenChange={(openChange) => setOpen(openChange)}
        >
            <div className={styles.selected}>
                {value.map(item => <div key={item.id} className={[styles.box, styles.item].join(' ')}>
                    {uppercaseFirstLetter(item.name)}
                </div>)}
                {value.length === 0 && <div className={styles.placeholder}>Select equipment</div>}
            </div>
        </Dropdown>
    </div>
};

export default SelectCapabilities;
