import {ICombineTreatment} from "../../util/varibles/interface";

export interface CombineTreatmentState {
    list: ICombineTreatment[]
    loadingList: boolean
}

export const initialState: CombineTreatmentState = {
    list: [],
    loadingList: false
};
