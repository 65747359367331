import React, {Component} from 'react';
import styles from './style.module.scss';
import {weatherStatus} from "../../pages/PlanOperationPage/Plan/Calendar/Operations/constants";
import {TOperation} from "../../util/varibles/interface";


interface IProps {
    visible?: boolean
    operation: TOperation
}

class Weather extends Component<IProps> {
    static defaultProps = {
        visible: true,
    }

    render() {
        const {typical_condition = null} = this.props.operation;
        const {visible} = this.props;

        if (!visible || typical_condition === null) return null;

        return <div
            className={styles.weather}
            data-weather-type={typical_condition}
            title={weatherStatus[typical_condition].name}
        />
    }
}

export default Weather;
