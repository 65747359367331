import React from 'react';
import Cancel from "./Cancel";
import BoxSites from "./_Component/BoxSites";
import {BiologyProvider} from "../../../contexts/PlanOperationContext/Biology";

const BiologyBrowser: React.FC = () => {
    return <BiologyProvider>
        <BoxSites/>
        <Cancel/>
    </BiologyProvider>
};

export default BiologyBrowser;
