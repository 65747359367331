import React from 'react';
import styles from '../style.module.scss';
import {Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {selectOneSetting} from "../../../../util/store/selectors";

interface IProps {
}

const ProductionArea: React.FC<IProps> = ({}) => {
    const dispatch = useDispatch();
    const checked = useSelector(selectOneSetting(DashboardSetting.PRODUCT_AREA))
    const onChange = (visible: any) => {
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.PRODUCT_AREA]: visible}]))
    };

    return <div className={styles.line}>
        <div className='text-[15px]'>Production areas</div>
        <Switch checked={checked} onChange={onChange}/>
    </div>;
};

export default ProductionArea;
