import {initialState} from "./constatns";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {compareInCircle, convertAutoRoute, showErrorResponse} from "../../util/varibles/global";
import {ILocation} from "../../util/varibles/interface";
import GoogleMap from "../../util/library/googlemap/googlemap";

const routeSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        initialDataRequest: (state, action) => {
            const {locations} = state;
            const {source_id, destination_id} = action.payload;
            const source = locations.find(item => item.id === source_id);
            const destination = locations.find(item => item.id === destination_id);
            state.params = {...state.params, ...action.payload, source, destination};
            state.loading = {...state.loading, routes: true};
        },
        initialDataSuccess: (state, action) => {
            const routes = action.payload
            const {id = ''} = routes[0] || {};
            state.routes = routes.sort((a: any, b: any) => a.name.localeCompare(b.name));
            state.showId = id;
            state.loading = {...state.loading, routes: false};
        },
        initialDataFailure: (state) => {
            state.loading = {...state.loading, routes: false}
        },
        show: (state, action) => {
            state.showId = action.payload;
        },
        changeMode: (state, action) => {
            const {mode, route} = action.payload;
            state.mode = mode;
            if (route)
                state.route = route;
        },
        editRoute: (state, action) => {
            state.route = action.payload
        },
        addRoute: (state, action) => {
            const route = action.payload
            const {routes} = state;
            const {source, destination} = state.params;
            const routeNew = {...route, source, destination}
            state.route = route;
            state.routes = [...routes, routeNew];
        },
        updateRoute: (state, action) => {
            const route = action.payload
            const {routes} = state;
            const {source, destination} = state.params;
            const routeNew = {...route, source, destination}
            state.route = route;
            state.routes = routes.map(item => item.id === route.id ? routeNew : item);
        },
        updateDefault: (state, action) => {
            const routeId = action.payload;
            const {routes} = state;
            state.routes = routes.map((item: any) => ({...item, default: item.id === routeId}))
        },
        deleteRoute: (state, action) => {
            const routeId = action.payload;
            const {routes} = state;
            state.routes = routes.filter((item: any) => item.id !== routeId)
        },
        changeShowAll: (state) => {
            state.isShowAll = !state.isShowAll
        },
        updateParams: (state, action) => {
            state.routes = [];
            state.params = action.payload
        },
        getLocationsRequest: (state) => {
            state.loadingLocations = true;
        },
        getLocationsSuccess: (state, action: PayloadAction<ILocation[]>) => {
            const locations = action.payload;
            const {source_id, destination_id} = state.params;
            const source = locations.find(item => item.id === source_id);
            const destination = locations.find(item => item.id === destination_id);
            state.params = {...state.params, source, destination};
            state.loadingLocations = false;
            state.locations = locations;
        },
        getLocationsFailure: (state) => {
            state.loadingLocations = false
        },
        autoRouteRequest: (state, action) => {
            const {request_id} = action.payload.body;
            state.requestId = request_id;
            state.loadingAuto = true;
        },
        autoRouteSuccess: (state, action) => {
            const data = action.payload;
            const {requestId = '', route = {}} = state;

            if (data.request_id === requestId) {
                if (data.status === 200) {
                    let points = convertAutoRoute(data.message);
                    const start = points[0];
                    const finish = points[points.length - 1];
                    const {source = {}, destination = {}} = state.params || {};
                    const {
                        latitude: source_lat = 0,
                        longitude: source_lng = 0
                    } = source.sea_position || source.position || {};
                    const {
                        latitude: destination_lat = 0,
                        longitude: destination_lng = 0
                    } = destination.sea_position || destination.position || {};
                    const isStart = compareInCircle({
                        point: {x: start.lng, y: start.lng},
                        o: {x: source_lng, y: source_lat},
                        radius: 2
                    });
                    if (isStart) {
                        points = [{lat: source_lat, lng: source_lng, name: source.name}, ...points];
                    }
                    const isFinish = compareInCircle({
                        point: {x: finish.lng, y: finish.lng},
                        o: {x: destination_lng, y: destination_lat},
                        radius: 2
                    });
                    if (isFinish) {
                        points.push({lat: destination_lat, lng: destination_lng, name: destination.name});
                    }
                    state.route = {...route, points,}
                } else {
                    showErrorResponse('Auto route failure', data.message);
                }
            }
            state.loadingAuto = false;
        },
        autoRouteFailure: (state) => {
            state.loadingAuto = false
        },
        loadMap: (state, action: PayloadAction<GoogleMap>) => {
            state.map = action.payload;
        }
    },
})

export const routeActions = routeSlice.actions

const routeReducer = routeSlice.reducer;

export default routeReducer;

