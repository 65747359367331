import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import {IUser, IVesselOfUser,} from "./constants";
import {AccountService} from "../../util/services/account";
import {Auth} from 'aws-amplify';
import {KEY_STORAGE, PATHNAME, timeRequestSetting} from "../../util/varibles/constants";
import {selectPermission, selectUser, selectVesselsSide} from "../../util/store/selectors";
import {getDashboardOfVesselSideFetch} from "../../util/services/vessel-side";
import {showErrorResponse} from "../../util/varibles/global";
import {loginActions} from "./reducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {nextPage, prevPage} from "../../util/library/Router";
import {IOperation} from "../../util/varibles/interface";
import {generateActions, getViewMode, VIEW_MODE} from "../../util/varibles/permission";
import {PreferenceSetting} from "../../util/varibles/userSetting";
import {getSetting} from "../../util/varibles/defaultSetting";

function* updateUserSettingWatcher({...params}): Generator<any, any, IUser> {
    try {
        const user = yield select(selectUser);
        const [setting, args] = params.payload;
        const {default_vessel} = args || user;
        yield delay(timeRequestSetting);
        yield call(AccountService.updateSetting, {
            id: user.user_id,
            setting: {
                ...user.setting || {},
                ...setting
            },
            default_vessel
        });
        yield put(loginActions.updateLoadingUserSetting());
    } catch (error) {
        showErrorResponse('Update setting failure', error);
        yield put(loginActions.updateLoadingUserSetting());
    }
}

function checkUser(user?: IUser) {
    if (!user) throw Error('Missing data')
}

function* updateUser(authentication: any): Generator<any, any, any> {
    const {idToken} = authentication.signInUserSession;
    localStorage.setItem(KEY_STORAGE.AUTHENTICATION, JSON.stringify(idToken));
    const user = yield call(AccountService.info);
    checkUser(user);
    const {setting = {}, roles = []} = user;
    const permission = generateActions(roles);
    yield put(loginActions.getUserSuccess({...user, permission}));
    const mode = getSetting(setting, PreferenceSetting.VIEW_MODE);
    const realMode = getViewMode(permission, mode);
    if (realMode !== mode) {
        yield put(loginActions.updateUserSetting([{[PreferenceSetting.VIEW_MODE]: realMode}]));
    }
    const theme = getSetting(setting, PreferenceSetting.THEME_COLOR);
    localStorage.setItem(KEY_STORAGE.THEME, theme);
}

function* getUserWatcher(args: PayloadAction<{ action?: Function }>): Generator<any, any, any> {
    try {
        const authentication = yield call(() => Auth.currentAuthenticatedUser());
        yield updateUser(authentication);
        const {action} = args.payload || {};
        if (action)
            action()
    } catch (error) {
        showErrorResponse('Get user information failure', error);
        yield put(loginActions.getUserFailure())
        yield put(loginActions.logout());
    }
}

function* logoutWatcher(): Generator {
    try {
        yield delay(200);
        localStorage.clear();
        yield call(() => Auth.signOut({global: true}));
        nextPage(PATHNAME.LOGIN);
    } catch (error) {
        showErrorResponse('Logout failed', error);
    }
}

function* loginWatcher({...args}): Generator<any, any, any> {
    try {
        const authentication = args.payload;
        yield updateUser(authentication);
        prevPage();
    } catch (error) {
        showErrorResponse('Login failed', error);
        yield put(loginActions.getUserFailure())
        yield put(loginActions.logout());
    }
}

function* getDomainsWatcher(): Generator {
    try {
        const domains = yield call(AccountService.domain);
        yield put(loginActions.getDomainsSuccess(domains));
        return domains
    } catch (error) {
    }
}

function* getOperationsVesselSideWatcher(): Generator {
    try {
        const vessels = yield call(getDashboardOfVesselSideFetch, {vessels: []});
        yield put(loginActions.getOpsOfVesselSuccess(vessels));
    } catch (error) {
        yield put(loginActions.getOpsOfVesselFailure());
    }
}

function* deleteOpTrackingWatcher(args: PayloadAction<string[]>): Generator<any, any, IVesselOfUser[]> {
    try {
        const vessels = yield select(selectVesselsSide);
        const ids = new Set(args.payload);
        const isExist = vessels.some(vessel => vessel.operations.some(sub => ids.has(sub.operation.id)));
        if (isExist)
            yield put(loginActions.getOpsOfVesselRequest())
    } catch (e) {
        showErrorResponse('Load data failed', e);
    }
}

function* updateOpsTrackingWatcher({...args}): Generator<{}, any, IVesselOfUser[]> {
    try {
        const permission: any = yield select(selectPermission);
        if (!permission[VIEW_MODE.VESSEL])
            return;

        const operations = args.payload;
        const vessels = yield select(selectVesselsSide);
        const isRefresh = operations.some((item: IOperation) => {
            const {id, status, vessel_id} = item.operation;
            const {operations = []} = vessels.find(vessel => vessel.id === vessel_id) || {};
            return operations.length === 0 || operations.some(sub => sub.operation.id === id && sub.operation.status !== status);
        });

        if (isRefresh)
            yield put(loginActions.getOpsOfVesselRequest())
    } catch (e) {
        console.log(e);
        showErrorResponse('Load data failed', e);
    }
}

export default function* loginSaga() {
    yield all([
        takeLatest(loginActions.updateUserSetting.type, updateUserSettingWatcher),
        takeLatest(loginActions.logout.type, logoutWatcher),
        takeEvery(loginActions.getUserRequest.type, getUserWatcher),
        takeEvery(loginActions.loginRequest.type, loginWatcher),
        takeEvery(loginActions.getDomainsRequest.type, getDomainsWatcher),
        takeEvery(loginActions.getOpsOfVesselRequest.type, getOperationsVesselSideWatcher),
        takeEvery(loginActions.deleteOpOfVessel.type, deleteOpTrackingWatcher),
        takeEvery(loginActions.updateOpsOfVessel.type, updateOpsTrackingWatcher)
    ])
}

