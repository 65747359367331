import React, {useState} from 'react';
import styles from './style.module.scss'
import {ACTION_TYPE, propsModal} from "../../../../util/varibles/constants";
import {Modal} from "antd";
import FormComponent from "./FormComponent";
import Header from "../../../../components/Popup/Component/Header";

interface IProps {
    widget: any
    editMode: ACTION_TYPE

    onClose?(): void
}


const PopupEditWidget: React.FC<IProps> = (props) => {
    const {editMode = ACTION_TYPE.CREATE, widget = {name: ''}, onClose} = props;
    const [visible, setVisible] = useState(true);
    const handleClose = () => {
        setVisible(false);
        if (onClose)
            onClose()
    }
    const title = editMode === ACTION_TYPE.CREATE ? 'Add new widget' : ('Update widget ' + widget.name);
    return <Modal {...{
        ...propsModal,
        className: styles['modal'],
        width: '600px',
        open: visible,
        title: <Header title={title} size='normal' onClose={handleClose}/>,
        onCancel: handleClose,
    }}>
        <FormComponent widget={widget} editMode={editMode} onClose={handleClose}/>
    </Modal>;
};

export default PopupEditWidget;
