import React from 'react';
import styles from './style.module.scss'
import {Form} from "antd";
import {IPropsElement} from "./constants";

interface IProps extends IPropsElement {

}

const ContainerElement: React.FC<IProps> = (props) => {
    const {label = "", name, properties: {required, subLabel}} = props;
    const rules = [{required: required, message: `${label.length > 0 ? `"${label}"` : "This field "} is required`}]
    const attr: any = {
        id: 1 + '_element',
        tabIndex: 0,
        name,
        rules,
        extra: subLabel,
    }
    return <div className={styles.element}>
        <div className={styles.label}>{label}</div>
        <Form.Item {...attr} >
            {props.children}
        </Form.Item>
    </div>
};

export default ContainerElement;
