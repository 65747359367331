import createFastContext from "../createFastContext";
import React, {ReactNode} from "react";
import {OPERATION_MODE} from "../../util/varibles/constants";
import {TargetTypes} from "../../pages/PlanOperationPage/Plan/Calendar/Vessels/constants";
import {useSelector} from "react-redux";
import {selectOpMode} from "../../util/store/selectors";

interface IState {
    isDrag: string
    menu: any[],

    userTenantId: string
    opMode: OPERATION_MODE,

    startPoint: number,
    calendarRef?: () => HTMLElement

    onDragOp(props: any): void
}

const {Provider, useStore: usePlan, setStore: setPlan, getStore: getPlan, callEvent: callEventPlan} = createFastContext<IState, {
    setTarget: (target: TargetTypes, e: MouseEvent) => void
}>({
    state: {
        isDrag: '',
        menu: [],

        userTenantId: '',
        opMode: OPERATION_MODE.PLAN,

        startPoint: 0,
        onDragOp: () => null
    }
});

const PlanProvider = ({children, generateMenu, ...args}: {
    children: ReactNode
    generateMenu: (target: TargetTypes) => any
} & Partial<IState>) => {
    const opMode = useSelector(selectOpMode);

    return <Provider props={{...args, opMode}} event={{
        setTarget: (target, e, {set}) => {
            e.stopPropagation();
            if (e.button !== 2) {
                set({menu: []})
                return;
            }
            set({menu: generateMenu(target)});
        }
    }}>
        {children}
    </Provider>
}


export {usePlan, setPlan, getPlan, callEventPlan, PlanProvider}
