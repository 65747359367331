import React, {useCallback} from 'react';
import {ACTION_TYPE, OP_STATUS, OPERATION_TYPE} from "../../../../../../../util/varibles/constants";
import WrapperOperation from "../Component/WrapperOperation";
import stylesContainer from "../style.module.scss";
import TransportView from "../../../../../_Component/ContentOperation/TransportView";
import {IPropsOp} from "../index";
import {store} from "../../../../../../../util/store/store";
import {cloneObj} from "../../../../../../../util/varibles/global";
import {selectPlan} from "../../../../../../../util/store/selectors";

interface IProps extends IPropsOp {
    operationType: OPERATION_TYPE

    isOwn: boolean
    isVesselOwn: boolean
    permission: any
    status: OP_STATUS
}

const Transport: React.FC<IProps> = (props) => {
    const {isOwn, data,} = props;

    const edit = useCallback(() => {
        const sites = selectPlan.sites(store.getState());
        const {operation} = data;
        const {sub_operations, source_id, source_name} = operation;
        const {units = []} = sites.find((item: any) => item.id === source_id) || {};

        return {
            isShow: true,
            operationType: OPERATION_TYPE.TRANSPORT,
            editMode: ACTION_TYPE.EDIT,
            values: {
                ...cloneObj(data),
                store: sub_operations.reduce((list: any, {id}: any) => {
                    const key = id + '|-';
                    list[key] = {
                        ...units.find((unit: any) => unit.id === id),
                        site_id: source_id,
                        site_name: source_name,
                    }
                    return list;
                }, {}),
            }
        }
    }, [data,]);

    return <WrapperOperation {...{...props, edit}}>
        <div className={[stylesContainer['content-wrapper'], stylesContainer.layout].join(' ')}>
            <TransportView
                status={props.status}
                data={data}
                isOwn={isOwn}
            />
        </div>
    </WrapperOperation>;
};

export default Transport;
