import React, {Suspense} from 'react';
import {IPropsTask, taskView} from "./index";
import styles from "./style.module.scss";
import {ISorting} from "../../../../../../util/varibles/interface";
import {operatorObject} from "../../../../../../pages/WeatherPage/util";
import {formatNumber} from "../../../../../../util/varibles/global";

const Sorting: React.FC<IPropsTask> = ({data}) => {
    const {sub_tasks, conditions} = data as ISorting;

    return <>
        <div className={styles['container-task']} key='name'>
            <div data-lev='name'>Sorting</div>
        </div>
        <table className={styles.sorting} key='sorting'>
            <thead data-lev='title'>
            <tr>
                <th>Sort condition</th>
                <th>Destination</th>
                <th>Amount / Total weight</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(conditions).map(key => {
                const tasks = sub_tasks[key] || [];
                const [first, last] = conditions[key].value;
                const firstValue = first ? (operatorObject[first[0]]?.labelHtml + ' ' + formatNumber(first[1]) + 'g') : '';
                const lastValue = last ? (operatorObject[last[0]]?.labelHtml + ' ' + formatNumber(last[1]) + 'g') : '';
                return <tr key={key} data-lev='task'>
                    <td>{firstValue} {(first && last) && <>and</>} {lastValue}</td>
                    <td>
                        <Suspense>
                            {tasks.map((item, i) => {
                                const Node = taskView[item.type || ''];
                                return <div key={item.group_id} className={styles.sortingTask}>
                                    {i > 0 && <div className='mr-1'>,</div>}
                                    {Node && <Node data={item}/>}
                                </div>
                            })}
                        </Suspense>
                    </td>
                    <td/>
                </tr>
            })}
            </tbody>
        </table>
    </>
};

export default Sorting;
