import {initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";
import {getElById} from "../../util/varibles/global";
import {VIEW_SMOOTH} from "../../util/varibles/constants";
import {IWeather} from "../../util/varibles/interface";

const weatherSlice = createSlice({
    name: 'weather',
    initialState,
    reducers: {
        getListRequest: (state) => {
            state.weathers = [];
            state.loadingList = true;
        },
        getListSuccess: (state, action) => {
            state.weathers = action.payload;
            state.loadingList = false
        },
        getLocationsSuccess: (state, action) => {
            state.locations = action.payload.reduce((rs: any, item: any) => {
                rs[item.id] = item
                return rs;
            }, {});
            state.loadingLocations = false
        },
        getListFailure: (state) => {
            state.loadingList = false
            state.loadingLocations = false
        },
        add: (state, action) => {
            const {weathers} = state;
            const {id} = action.payload[0];

            state.weathers = action.payload.reduce((rs: any, item: any) => {
                const {is_enable} = item;
                if (is_enable) {
                    const {group_id, type} = item;
                    return [
                        ...rs.map((sub: any) => {
                            if (sub.group_id === group_id && sub.type === type)
                                return {...sub, is_enable: false}
                            return sub
                        }),
                        item
                    ]
                }
                return [...rs, item];
            }, weathers)
            setTimeout(() => {
                const el = getElById(id);
                if (el) {
                    el.scrollIntoView(VIEW_SMOOTH);
                }
            }, 300)
        },
        update: (state, action) => {
            const list = action.payload;
            const {weathers} = state;
            const groupIds: string[] = [];
            const ids = list.map((item: IWeather) => {
                if (item.is_enable)
                    groupIds.push(item.group_id)
                return item.id
            });
            const enableIds = new Set(groupIds)
            state.weathers = weathers.map(item => {
                const {id, group_id} = item;
                const index = ids.indexOf(id);
                if (index !== -1)
                    return list[index];
                else if (enableIds.has(group_id))
                    return {...item, is_enable: false}

                return item;
            })
        },
        delete: (state, action) => {
            const [deleted, updateId] = action.payload;
            const {weathers} = state;
            const {id: deleteId, group_id} = deleted;
            const remaining = weathers.filter((item: IWeather) => item.id !== deleteId);
            if (updateId) {
                state.weathers = remaining.map(item => item.group_id === group_id
                    ? {...item, is_enable: item.id === updateId}
                    : item);
            } else
                state.weathers = [...remaining];
        }
    },
})

export const weatherActions = weatherSlice.actions

const weatherReducer = weatherSlice.reducer;

export default weatherReducer;
