import {all, call, put, takeEvery} from "redux-saga/effects";
import {regionActions} from "./reducer";
import {IRegion} from "../../varibles/interface";
import {RegionService} from "../../services/region";
import {showErrorResponse} from "../../varibles/global";

function* getRegionsWatcher(): Generator<any, any, IRegion[]> {
    try {
        const regions = yield call(RegionService.gets)
        yield put(regionActions.getRegionsSuccess(regions));
    } catch (error) {
        yield put(regionActions.getRegionsFailure())
        showErrorResponse('Get locations failure', error);
    }
}

export default function* regionSaga() {
    yield all([
        takeEvery(regionActions.getRegions.type, getRegionsWatcher),
    ])
}
