import React from 'react';
import styles from "./style.module.scss";
import {diseasedZone} from "../../../varibles/constants";

interface IProps {
    data: any

    onClick(data: any): void
}

const DiseaseZone: React.FC<IProps> = ({data, onClick}) => {
    const {zones} = data;

    return <div className={styles['container-info']}>
        <div className={[styles.header, 'text-center'].join(' ')}>Disease zones</div>
        <div className={styles['disease-zones']}>
            {zones.map((item: any) => {
                const {id, diseasedType} = item;
                const {name = '', color = ''} = diseasedZone[diseasedType] || {};
                const style: any = {'--diseased-color': color}
                return <div
                    key={id}
                    className={styles['disease-info']}
                    onMouseDown={() => onClick(item)}
                >
                    <div className="diseased-zone" data-code={diseasedType} style={style}/>
                    <span>{name}</span>
                </div>
            })}
        </div>
    </div>;
};

export default DiseaseZone;