import React from 'react';
import styles from "./style.module.scss";
import Pointer from "./Pointer";
import CurrentTime from "./CurrentTime";
import RulerComponent from "./RulerComponent";
import ButtonLive from "./ButtonLive";
import {Spin, Tooltip} from "antd";
import ControlComponent from "./ControlComponent";
import ButtonPlay from "./ButtonPlay";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import PopupEditWidget from "../../_Component/PopupEditWidget";
import {initWidget} from "../../constants";
import {ACTION_TYPE} from "../../../../util/varibles/constants";

interface IProps {
    loading: boolean

    loadData(time?: { start_time: number, end_time: number }): void
}

const Timeline: React.FC<IProps> = React.memo(({loading, loadData}) => {
    const handleAddWidget = () => {
        const el = openPopup(<PopupEditWidget
          widget={initWidget}
          editMode={ACTION_TYPE.CREATE}
          onClose={() => el.remove()}
        />)
    }

    return <div className={styles['container-timeline']}>
        <div className={styles.timeline}>
            <div id='container-ruler' className={styles['container-ruler']}>
                <Pointer loading={loading}/>
                <CurrentTime/>
                <RulerComponent/>
                {loading && <Spin wrapperClassName={styles['container-loading']} spinning={loading}>
                    <div className={styles.loading}/>
                </Spin>}
            </div>
        </div>
        <div className='flex align-center justify-between gap-[10px] py-[6px] px-[12px]'>
            <div className='flex align-center gap-[10px]'>
                <ButtonPlay loading={loading}/>
                <ButtonLive loadData={loadData}/>
                <ControlComponent loading={loading} loadData={loadData}/>
            </div>
            <Tooltip title='Add widget'>
                <div className={styles['button-plus']} onClick={handleAddWidget}/>
            </Tooltip>
        </div>
    </div>;
}, (prevProps, nextProps) => prevProps.loading === nextProps.loading);

export default Timeline;

