import {handleApproveOp, handleCancelOp, handleStartOp} from "./actions";
import {ICON_NAME} from "../../../components/Icon";
import {OP_STATUS, OPERATION_TYPE, PATHNAME} from "../../../util/varibles/constants";
import {showPopupDeleteOperation} from "../../PlanOperationPage/Plan/Calendar/Operations/actions";
import {IActivityLog, IOperations} from "../../../util/varibles/interface";
import {IDataMenuOperation, IMenuOperation} from "../../PlanOperationPage/Plan/Calendar/Operations/constants";
import {Dispatch} from "@reduxjs/toolkit";
import {overviewActions} from "../reducer";
import {loginActions} from "../../LoginPage/reducer";
import {openPage} from "../../../util/library/Router";

export enum MENU_OP {
    START = 'start',
    DETAIL = 'detail',
    APPROVE = 'approve',
    CANCEL = 'cancel',
    EDIT = 'edit',
    DELETE = 'delete',
}

interface IPropsMenu {
    dispatch: Dispatch
    operations: IOperations

    edit?(): void
}

export const menuOp: any = {
    [MENU_OP.START]: (data: IDataMenuOperation, props: IPropsMenu): IMenuOperation => {
        const {status} = data.operation;
        return {
            name: 'Start',
            icon: ICON_NAME.START,
            onClickHandler: () => handleStartOp(data, props.dispatch),
            isActive: status === OP_STATUS.APPROVED
        }
    },
    [MENU_OP.DETAIL]: (data: IDataMenuOperation): IMenuOperation => {
        const {tenant_id, id} = data.operation;
        return {
            name: 'Operation detail',
            icon: ICON_NAME.OPERATION_DETAIL,
            onClickHandler: () => openPage(`${PATHNAME.OPERATION}/${tenant_id}/${id}`),
            isActive: true
        }
    },
    [MENU_OP.APPROVE]: (data: IDataMenuOperation, props: IPropsMenu): IMenuOperation => {
        const {status} = data.operation;
        return {
            name: 'Approve',
            icon: ICON_NAME.APPROVE,
            onClickHandler: () => handleApproveOp(data, props.dispatch),
            isActive: status === OP_STATUS.WAITING
        }
    },
    [MENU_OP.CANCEL]: (data: IDataMenuOperation, props: IPropsMenu): IMenuOperation => {
        const {status} = data.operation;
        return {
            name: 'Cancel',
            icon: ICON_NAME.CANCEL,
            danger: true,
            onClickHandler: () => handleCancelOp(data, props.dispatch),
            isActive: status === OP_STATUS.SAVED || status === OP_STATUS.WAITING || status === OP_STATUS.APPROVED
        }
    },
    [MENU_OP.EDIT]: (data: IDataMenuOperation, props: IPropsMenu): IMenuOperation => {
        return {
            name: 'View/Edit',
            icon: ICON_NAME.EDIT,
            onClickHandler: props.edit,
            isActive: data.isActive,
        }
    },
    [MENU_OP.DELETE]: (data: IDataMenuOperation, props: IPropsMenu): IMenuOperation => {
        const {status} = data.operation;
        return {
            name: 'Delete',
            icon: ICON_NAME.DELETE,
            danger: true,
            onClickHandler: () => showPopupDeleteOperation(data, props, (ids: string[]) => {
                props.dispatch(overviewActions.deleteOps(ids));
                props.dispatch(loginActions.deleteOpOfVessel(ids));
            }),
            isActive: status !== OP_STATUS.PROCESSING
        }
    },
}

export const menuOptions: any = {
    [OPERATION_TYPE.HARVEST]: [
        MENU_OP.DETAIL,
        MENU_OP.START,
        MENU_OP.APPROVE,
        MENU_OP.CANCEL,
    ],
    [OPERATION_TYPE.TREATMENT]: [
        MENU_OP.DETAIL,
        MENU_OP.START,
        MENU_OP.APPROVE,
        MENU_OP.CANCEL,
    ],
    [OPERATION_TYPE.TRANSPORT]: [
        MENU_OP.DETAIL,
        MENU_OP.START,
        MENU_OP.APPROVE,
        MENU_OP.CANCEL,
    ],
    [OPERATION_TYPE.EVENT]: [
        MENU_OP.DETAIL,
        MENU_OP.START,
        MENU_OP.APPROVE,
        MENU_OP.EDIT,
        MENU_OP.CANCEL,
        MENU_OP.DELETE,
    ],
    [OPERATION_TYPE.SERVICE]: [
        MENU_OP.DETAIL,
        MENU_OP.START,
        MENU_OP.APPROVE,
        MENU_OP.CANCEL,
    ],
}

export enum WEATHER_STATUS {
    BAD,
    MODERATE,
    GOOD
}

export const weatherStatus: any = {
    [WEATHER_STATUS.BAD]: {id: WEATHER_STATUS.BAD, name: 'Bad weather'},
    [WEATHER_STATUS.MODERATE]: {id: WEATHER_STATUS.MODERATE, name: 'Moderate weather'},
    [WEATHER_STATUS.GOOD]: {id: WEATHER_STATUS.GOOD, name: 'Good weather'}
};

export const updateTime = (activityLog: IActivityLog[], duration: number, startTime = 0) => {
    const space = startTime ? startTime - activityLog[0].est_start_time : duration;
    return activityLog.map((item: IActivityLog) => ({...item, est_start_time: item.est_start_time + space}));
}




