import React, {useState} from 'react';
import {Button, Modal} from "antd";
import Header from "../../Popup/Component/Header";
import Checklist from "../../Checklist";
import styles from './style.module.scss';
import {updateStateOpOfVesselSideFetch} from "../../../util/services/vessel-side";
import {getStepNameOfChecklist} from "../../../util/varibles/global";

interface IProps {
    visible: boolean
    data: any
    keyStep: string
    checklist: any

    onClose(): void

    onSubmit(args: any): void
}

const PopupChecklist: React.FC<IProps> = ({visible, data, keyStep, checklist, onSubmit, onClose}) => {
    const [open, setOpen] = useState(visible);
    const [loading, setLoading] = useState(false);

    function handleClose() {
        setOpen(false);
        setTimeout(onClose, 300);
    }

    function handleSubmit(checklist: any) {
        const {id, tenant_id, vessel_id, checklists} = data.operation;
        const body = {
            operation_id: id,
            tenant_id,
            vessel_id,
            time: Date.now(),
            checklists: {...checklists, [keyStep]: checklist}
        };
        setLoading(true);

        new Promise(resolve => resolve(updateStateOpOfVesselSideFetch(body)))
            .then((rs: any) => {
                onSubmit(rs);
                setLoading(false);
                handleClose();
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const step = data.current_process + 1;
    const {step_name = ''} = data.activity_log[step] || {}
    const name = getStepNameOfChecklist({
        operation_type: data.operation.operation_type,
        step: keyStep,
        step_name
    });

    return <Modal
        width={612}
        className={styles.modal}
        open={open}
        keyboard
        centered
        title={<Header title={<div className='font-s2'>{name}</div>} onClose={handleClose}/>} footer={null}
        closable={false}
        onCancel={handleClose}
    >
        <Checklist
            checklist={checklist}
            title={name}
            loading={loading}
            cancel={<Button className='bt-default' onClick={handleClose}>Cancel</Button>}
            onSubmit={handleSubmit}
        />
    </Modal>;
};

export default PopupChecklist;
