import React, {Component} from 'react';
import {connect} from 'react-redux';
import {AppState} from "../../util/store/store";
import {ACTION} from "../../util/varibles/permission";
import {nextPage} from "../../util/library/Router";
import {PATHNAME} from "../../util/varibles/constants";
import {locationActions} from "../../util/store/location/reducer";
import {regionActions} from "../../util/store/region/reducer";
import {Tabs} from "antd";
import styles from "./style.module.scss";
import Locations from "./Locations";
import Factories from "./Factories";

const mapStateToProps = (state: AppState) => ({
    permission: state.login.user.permission
});

interface IProps {
    permission: any

    getLocations(): void

    getRegions(): void
}

class LocationPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        if (!this.props.permission[ACTION.LOCATION.VIEW]) {
            nextPage(PATHNAME.ACCESS_DENIED);
        } else {
            this.props.getLocations()
            this.props.getRegions()
        }
    }

    render() {
        return <div className='m-header bgPage'>
            <Tabs
                defaultActiveKey='users'
                centered
                tabBarExtraContent={{
                    left: <div className={[styles.header, 'header-page'].join(' ')}>
                        <div className='title-page'>
                            <b>Location Management</b>
                        </div>
                    </div>
                }}
                renderTabBar={(props, DefaultTabBar) => <DefaultTabBar className='h-56' {...props}/>}
                tabBarGutter={10}
                items={[
                    {
                        label: `Factory`,
                        key: 'factory',
                        children: <Factories permission={this.props.permission}/>
                    },
                    {
                        label: `POI Location`,
                        key: 'poi',
                        children: <Locations permission={this.props.permission}/>
                    },

                ]}
            />
        </div>;
    }
}

export default connect(mapStateToProps, {
    getLocations: locationActions.getPois,
    getRegions: regionActions.getRegions
})(LocationPage);