import {all, call, put, takeEvery} from 'redux-saga/effects'
import {
    getAutoRoutePlanFetch,
    getLocationsFetch,
    getRoutesByLocation
} from "../../util/services/route";
import {showErrorResponse} from "../../util/varibles/global";
import {routeActions} from "./reducer";
import {ILocation, IRoute} from "../../util/varibles/interface";
import {PayloadAction} from "@reduxjs/toolkit";

function* initialWatcher(args: PayloadAction<{ source_id: string, destination_id: string }>) {
    try {
        const {source_id, destination_id} = args.payload;
        const routes: IRoute[] = yield call(getRoutesByLocation, source_id, destination_id);
        yield put(routeActions.initialDataSuccess(routes));
    } catch (error) {
        yield put(routeActions.initialDataFailure());
        console.log(error);
    }
}

function* getLocationsWatcher(): Generator<any, any, ILocation[]> {
    try {
        const locations = yield call(getLocationsFetch);
        yield put(routeActions.getLocationsSuccess(locations))
    } catch (error) {
        yield put(routeActions.getLocationsFailure());
    }
}

function* getAutoRouteWatcher({...args}) {
    try {
        const body = args.payload;
        yield call(getAutoRoutePlanFetch, body);
    } catch (error) {
        yield put(routeActions.autoRouteFailure())
        showErrorResponse('Auto route failure', error);
    }
}

export default function* routeSaga() {
    yield all([
        takeEvery(routeActions.initialDataRequest.type, initialWatcher),
        takeEvery(routeActions.getLocationsRequest.type, getLocationsWatcher),
        takeEvery(routeActions.autoRouteRequest.type, getAutoRouteWatcher)
    ])
}
