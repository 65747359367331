import React, {useEffect, useState} from 'react';
import {aWeekMillisecond, SHORT_TIME_FORMAT} from "../../../../util/varibles/constants";
import styles from './style.module.scss';
import {datetime} from "../../../../util/library/datetime";

let interval: any;

interface IProps {
    weeks: { key: string, time: number }[]
}

const CurrentDay: React.FC<IProps> = ({weeks}) => {
    const [, updateState] = useState({});
    useEffect(() => {
        if (interval)
            clearInterval(interval)

        interval = setInterval(() => updateState({}), 1500)
        return () => {
            if (interval)
                clearInterval(interval)
        };
    }, []);

    if (weeks.length === 0)
        return null

    const currentTime = Date.now();
    const startDate = datetime().startOf('week').time;
    const start = weeks[0].time;
    const more = (currentTime - startDate) / aWeekMillisecond
    const count = Math.floor((currentTime - start) / aWeekMillisecond)
    const style = {
        marginLeft: `calc(((100vw - 175px) / var(--week-per-screen) * ${count + more}))`
    }

    return <div className={styles.currentTime} style={style}>
        <div className={styles.timeView}>{datetime(currentTime).format(SHORT_TIME_FORMAT)}</div>
    </div>;
};

export default CurrentDay;
