import React, {ComponentType, lazy, Suspense} from 'react';
import styles from './style.module.scss'
import {TASK_TYPE, TREATMENT_TYPE} from "../../../../../../util/varibles/constants";
import Sorting from "./Sorting";
import {TTaskTreatment} from "../../../../../../util/varibles/interface";

export interface IPropsTask {
    data: TTaskTreatment
}

export const taskView: { [id: string]: ComponentType<IPropsTask> } = {
    [TASK_TYPE.TREATMENT]: lazy(() => import('./Treatment')),
    [TASK_TYPE.MOVE]: lazy(() => import('./MoveToUnit')),
    [TASK_TYPE.COMBINE]: lazy(() => import('./Combine')),
}

const Task: React.FC<IPropsTask> = ({data}) => {
    const {type, treatment_type, support_vessels = []} = data;
    const Node = taskView[type || ''];

    if (treatment_type === TREATMENT_TYPE.SORTING) {
        return <Sorting data={data}/>
    }

    return <div className={styles['container-task']}>
        <Suspense>
            {Node && <Node data={data}/>}
        </Suspense>
        {support_vessels.length > 0 && <div className={styles['vessel-support']}>
            <div data-lev='label'>Vessel support:</div>
            <div data-lev='vessel-name'> {support_vessels.map((item: any) => item.name).join(', ')}</div>
        </div>}
    </div>
};

export default Task;