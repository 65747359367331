import IconHeader from '../../../assets/icon/element/element_header.svg'
import IconShortText from '../../../assets/icon/element/element_short_text.svg'
import IconLongText from '../../../assets/icon/element/element_long_text.svg'
import IconNumber from '../../../assets/icon/element/element_number.svg'
import IconDropdown from '../../../assets/icon/element/element_dropdown.svg'
import IconRadio from '../../../assets/icon/element/element_radio.svg'
import IconCheckbox from '../../../assets/icon/element/element_checkbox.svg'
import IconDate from '../../../assets/icon/element/element_calendar.svg'
import IconTime from '../../../assets/icon/element/element_clock.svg'
import IconDivide from '../../../assets/icon/element/element_divide.svg'
import IconSign from '../../../assets/icon/element/element_sign.svg'
import {ElementType} from "../constants";
import {SEPARATOR_VALUE} from "../PopupEdit/RightSide/constants";

export enum ELEMENT_BASIC {
    HEADER = 'header',
    SHORT_TEXT = 'short-text',
    LONG_TEXT = 'long-text',
    NUMBER = 'number',
    DROPDOWN = 'dropdown',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    DATE = 'date',
    TIME = 'time'
}

export const elementBasics = [
    {id: ELEMENT_BASIC.HEADER, icon: IconHeader, name: 'Header'},
    {id: ELEMENT_BASIC.SHORT_TEXT, icon: IconShortText, name: 'Short text field'},
    {id: ELEMENT_BASIC.LONG_TEXT, icon: IconLongText, name: 'Long text field'},
    {id: ELEMENT_BASIC.NUMBER, icon: IconNumber, name: 'Number field'},
    {id: ELEMENT_BASIC.DROPDOWN, icon: IconDropdown, name: 'Dropdown'},
    {id: ELEMENT_BASIC.RADIO, icon: IconRadio, name: 'Single choice'},
    {id: ELEMENT_BASIC.CHECKBOX, icon: IconCheckbox, name: 'Multiple choice'},
    {id: ELEMENT_BASIC.DATE, icon: IconDate, name: 'Date picker'},
    {id: ELEMENT_BASIC.TIME, icon: IconTime, name: 'Time picker'},
]

export enum ELEMENT_PAGE {
    DIVIDE = 'divide'
}

export const elementPages = [
    {id: ELEMENT_PAGE.DIVIDE, icon: IconDivide, name: 'Divide'},
]

export enum ELEMENT_DEFINED {
    SIGN = 'sign'
}

export const elementDefined = [
    {id: ELEMENT_DEFINED.SIGN, icon: IconSign, name: 'Sign'},
]

export interface IPropsElement {
    isAllowSelect: boolean
    index: number
    type: ElementType
    label: string
    properties: {
        required: boolean
        multipleSelections: boolean
        placeHolder: string
        subLabel: string
        options: string
        optionDefault: string
        numberOfColumns: string
        numberOfRows: number
        separator: SEPARATOR_VALUE
        isShowTime: boolean
        isCalendarPopup: boolean
        isTimeRange: boolean
        fontSize: 'default' | 'large' | 'small'
        textAlign: 'left' | 'center' | 'right'
    }

    selected: any

    onSelect(value?: any): void

    onDelete(index: number): void
}
