import React, {useCallback} from 'react';
import {ACTION_TYPE, OP_STATUS, OPERATION_TYPE, SITE_TYPE} from "../../../../../../../util/varibles/constants";
import WrapperOperation from "../Component/WrapperOperation";
import stylesContainer from "../style.module.scss";
import styles from "../style.module.scss";
import HarvestView from "../../../../../_Component/ContentOperation/HarvestView";
import {ISite} from "../../../../../../../util/varibles/interface";
import {IPropsOp} from "../index";
import {store} from "../../../../../../../util/store/store";
import {selectPlan} from "../../../../../../../util/store/selectors";
import {getOperation} from "../../../../../../../contexts/OperationContext";
import {addClassName, removeClassName} from "../../../../../../../util/varibles/global";
import {getPlan} from "../../../../../../../contexts/PlanOperationContext/Plan";

interface IProps extends IPropsOp {
    operationType: OPERATION_TYPE
    isOwn: boolean
    isVesselOwn: boolean
    permission: any
    status: OP_STATUS
}

const Harvest: React.FC<IProps> = (props) => {
    const {isOwn, data} = props;
    const get = getOperation();
    const getBoxOfOp = getPlan()

    const edit = useCallback(() => {
        const {sites, harvests} = selectPlan.root(store.getState());
        const {sub_operations, site_id, site_name} = data.operation;
        const sub = [...sub_operations];

        return {
            isShow: true,
            operationType: OPERATION_TYPE.HARVEST,
            editMode: ACTION_TYPE.EDIT,
            values: {
                operation: {...data.operation, sub_operations: sub},
                activity_log: data.activity_log,
                store: sub_operations.reduce((list: any, item: any, index: number) => {
                    const {id, harvest_id = '-'} = item;
                    let siteId: any;
                    if (!item.site_id) {
                        const site = sites.find((sub: ISite) => sub.units.some((unit) => unit.id === id))
                        if (site) {
                            siteId = site.id || site_id;
                            sub[index].site_id = site.id
                            sub[index].site_name = site.name
                        } else
                            siteId = site_id
                    } else
                        siteId = item.site_id;
                    const {
                        units = [],
                        type = SITE_TYPE.INTERNAL
                    } = sites.find((item: any) => item.id === siteId) || {};
                    const key = id + '|' + harvest_id;
                    list[key] = {
                        ...units.find((unit: any) => unit.id === id),
                        site_id,
                        site_name,
                        site_type: type,
                        harvest_id
                    }
                    if (harvest_id !== '-')
                        list[key] = {...list[key], ...harvests.find((harvest: any) => harvest.harvest_id === harvest_id)};

                    return list;
                }, {}),
                vessel: data.vessel
            }
        }
    }, [data.operation, data.activity_log, data.vessel]);

    const handleMouseOver = () => {
        const {calendarRef} = getBoxOfOp();
        if (calendarRef) {
            const {modeDrag} = calendarRef().dataset || {};
            if (modeDrag !== `${OPERATION_TYPE.HARVEST}` || !isOwn)
                return;

            const {rootRef} = get();
            if (rootRef) {
                const el = rootRef();
                const {modeDrag = ''} = el.dataset || {};
                if (modeDrag.length === 0)
                    addClassName(el, styles['merge-hover']);
            }
        }
    }

    const handleMouseLeave = () => {
        const {calendarRef} = getBoxOfOp();
        if (calendarRef) {
            const {modeDrag} = calendarRef().dataset || {};
            if (modeDrag !== `${OPERATION_TYPE.HARVEST}` || !isOwn)
                return;

            const {rootRef} = get();
            if (rootRef)
                removeClassName(rootRef(), styles['merge-hover']);
        }
    }

    return <WrapperOperation
        {...props}
        edit={edit}
        event={{
            onMouseOver: handleMouseOver,
            onMouseLeave: handleMouseLeave
        }}
    >
        <div className={[stylesContainer['content-wrapper'], stylesContainer.layout].join(' ')}>
            <HarvestView
                status={props.status}
                data={data}
                isOwn={isOwn}
            />
        </div>
    </WrapperOperation>;
};

export default Harvest;
