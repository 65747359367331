import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {CERTAIN_TYPE, OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import styles from './style.module.scss';
import {IFactory, IOperation, ISite, IUnit, TTaskTreatment} from "../../../../../../util/varibles/interface";
import {selectPlan, selectTenantId} from "../../../../../../util/store/selectors";
import {UnitModel} from "../../../../BiologyBrowser/constants";
import {checkSorting, checkTreatment, initTask} from "./constants";
import ChangeOperation from "./ChangeOperation";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import {callEventEditOp, getEditOp, IUnitWhenEdit, setEditOp} from "../../../../../../contexts/EditOperationContext";

export interface IProps {
    popupData: any

    onClose(): void
}

export interface IState {
    startTime: number
    support_vessels: any
    activity_log: any
    operation: any
    loading: boolean
    units: any
    factory: IFactory
    step: number
    supportTasks: any
    listOfTask: any
    isCalculate: boolean
    site: ISite
}

let currentUnitId: string | undefined;

const Treatment: React.FC<IProps> = (props) => {
    const {values} = props.popupData || {};
    const sites = useSelector(selectPlan.sites);
    const operations = useSelector(selectPlan.ops);
    const userTenantId = useSelector(selectTenantId);
    const set = setEditOp();
    const get = getEditOp();
    const {getVesselTasks} = callEventEditOp();

    const handleSubmit = (finish: Function) => {
        const {operation, units, files, supportTasks, isCalculate} = get()
        const id = operation?.id || '';
        if (!isCalculate) {
            const {onSave} = get();
            const data = {
                ...operations[id],
                operation: {
                    ...operation,
                    files: files.map((item: any) => ({key: item.key, name: item.name})),
                    sub_operations: Object.keys(units).reduce((rs: IUnit[], key: string) => {
                        const {tasks = [], ...argsUnit} = units[key];
                        if (tasks.length > 0)
                            return [...rs, argsUnit]
                        return rs;
                    }, [])
                }
            } as IOperation;
            const listOfFile = files.length > 0 ? {[id]: files} : {};
            onSave({update: {[id]: data}, isCheck: false, files: listOfFile});
            return;
        } else {
            let newSubOperations: IUnit[] = [], error = false, newTasks: TTaskTreatment[] = [];
            const newUnits = Object.keys(units).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                const {tasks = [], ...args} = units[key];
                if (tasks.length > 0) {
                    if (!args.fish_amount || !args.total_weight) {
                        notify.error(NotifyCode.E40)([args.unit_id]);
                        error = true;
                        return rs;
                    }

                    newSubOperations.push(args)
                    const {isError, tasks: tasksAfterCheck} = checkTreatment(tasks as TTaskTreatment[]);
                    if (isError) {
                        error = true;
                        rs[key] = {...units[key], tasks: tasksAfterCheck}
                    } else
                        newTasks = [...newTasks, ...tasks] as TTaskTreatment[]
                }
                return rs
            }, {});

            if (error) {
                set(({units: prev}) => ({units: ({...prev, ...newUnits})}))
                return;
            }
            const {site_id, site_name} = newSubOperations[0];
            const data = {
                operation: {
                    ...operation,
                    site_id,
                    site_name,
                    operation_type: OPERATION_TYPE.TREATMENT,
                    support_vessel: supportTasks.length > 0,
                    tasks: newTasks,
                    sub_operations: newSubOperations,
                    files: files.map((item: any) => ({key: item.key, name: item.name})),
                },
                vessel: values.vessel,
                activity_log: []
            };
            finish(data);
        }
    }

    useEffect(() => {
        const el = document.getElementById('container-treatment');
        if (el)
            setTimeout(() => el.scrollTo(0, 0), 100);
        const [{site_id: newSiteId, site_name: newSiteName}] = values.operation?.sub_operations || [{}];
        const site = sites.find(site => site.id === newSiteId);
        if (!site)
            return;

        const {store} = values;
        const {
            site_id = newSiteId,
            tenant_id = userTenantId,
            site_name = newSiteName,
            operation_type = OPERATION_TYPE.TREATMENT,
            certain_mode = CERTAIN_TYPE.NONE,
            is_cleaning = false,
            files = [],
            tasks = Object.keys(store || {}).reduce((rs: TTaskTreatment[], key) => {
                const {id, site_id: subSiteId} = store[key];
                if (subSiteId === id)
                    return rs;
                rs.push(initTask(store[key]))
                return rs
            }, []),
            sub_operations,
        } = values.operation || {};

        let isLock = !!currentUnitId;
        const indexes: { [id: string]: number } = {};
        const group = tasks.reduce((rs: any, item: any, index: number) => {
            const {id} = item.sub_operation || item.sub_operations[0];
            const data = {...item, index};
            if (!rs[id]) {
                indexes[id] = index;
                rs[id] = {
                    isLock,
                    tasks: [data]
                }
            } else
                rs[id].tasks.push(data)
            if (id === currentUnitId)
                isLock = false;

            return rs;
        }, {});

        const units = site.units.reduce((rs: { [id: string]: IUnitWhenEdit }, item) => {
            const {id} = item;
            const old = sub_operations.find((item: IUnit) => item.id === id) || {};
            const isSorting = checkSorting(group[id]?.tasks || []);
            rs[id] = {...UnitModel(item, site), ...group[id] || {}, ...old, isSorting, index: indexes[id]};
            return rs;
        }, {})

        const op = {
            ...values.operation,
            tenant_id,
            site_id,
            site_name,
            operation_type,
            certain_mode,
            is_cleaning
        }
        const [{est_start_time = Date.now()}] = values.activity_log || []
        set({
            site,
            files,
            startTime: est_start_time,
            supportTasks: [],
            operation: op,
            isCalculate: !op.id,
            units,
        })
        if (values.vessel)
            getVesselTasks(values.vessel)
    }, [values, set, sites, userTenantId, getVesselTasks]);

    const common = {
        onSubmit: (finish: Function) => handleSubmit(finish),
        onClose: props.onClose
    }

    return <div id='container-treatment' className={styles['container-operation']}>
        <ChangeOperation {...common} />
    </div>
};

export default Treatment