import React, {Component} from 'react';
import {RouterProvider} from "react-router-dom";
import {CHAT_TYPE, KEY_STORAGE} from "../util/varibles/constants";
import WSService from "../web-socket/web-socket-service";
import {Auth} from "aws-amplify";
import {showErrorResponse} from "../util/varibles/global";
import {connect} from "react-redux";
import {IConversation, IOperation} from "../util/varibles/interface";
import {loginActions} from "./LoginPage/reducer";
import {SOCKET_FUNC, SOCKET_LEV} from "../web-socket/constants";
import {messageActions} from "./MessagePage/reducer";
import {router} from "../util/library/Router";
import {MESSAGE_TYPE} from "./MessagePage/constants";
import {messageNotify} from "../components/Chat/constants";
import {AppState} from "../util/store/store";

const timeToRefresh = 1800000;

const mapStateToProps = (state: AppState) => {
    return {
        username: state.login.user.username,
        user_id: state.login.user.user_id
    }
};

interface IProps {
    username: string
    user_id: string

    logout(): void

    getUser(payload?: void): void

    updateOpsOfVessel(payload: IOperation[]): void

    seenMessage(payload: IConversation): void

    readMessage(payload: IConversation): void

    addMessage(payload: IConversation): void

    updateMessage(payload: IConversation): void

    addUser(payload: IConversation): void

    kickUser(payload: IConversation): void

    leaveChat(payload: { chatType: CHAT_TYPE, chatId: string }): void

    updateTenant(payload: any): void
}

class App extends Component<IProps> {
    interval: any;
    isSocket = false;
    socket: WSService | undefined;
    actions: any = {};

    constructor(props: IProps) {
        super(props);
        if (localStorage.getItem(KEY_STORAGE.AUTHENTICATION)) {
            this.handleGetNewToken();
            this.interval = setInterval(() => this.handleGetNewToken(), 900000);
            this.props.getUser();
        }

        // navigator.mediaDevices
        //     .getUserMedia({ audio: true })
        //     .catch(reason => console.error(`Audio permissions denied: ${reason}`));
    }

    componentDidMount() {
        this.openWs(this.props);
        setTimeout(() => {
            const audio: any = document.getElementById("au");
            let bt = document.getElementById("bt");
            if (audio && bt) {
                console.log(audio);
                bt.addEventListener("click", () => {
                    audio.play();
                });
                const startPlaying = () => {
                    audio.removeEventListener('playing', startPlaying);
                    audio.src = 'notification.mp3';
                    audio.play();
                }
                audio.addEventListener('playing', startPlaying);
                audio.addEventListener('error', () => {
                    console.log("error");
                });
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.closeWs();
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        if (localStorage.getItem(KEY_STORAGE.AUTHENTICATION)) {
            if (!this.isSocket)
                this.openWs(nextProps);
            if (!this.interval)
                this.interval = setInterval(() => this.handleGetNewToken(), timeToRefresh)
        } else {
            this.closeWs();
        }

        return this.state !== nextState;
    }

    processMessage = (value: any) => {
        switch (value.function) {
            case SOCKET_FUNC.SEEN_MESSAGE: {
                this.props.seenMessage(value.message);
                break;
            }
            case SOCKET_FUNC.CHAT: {
                const {is_edited, message_type} = value.message.message;
                this.tingTing(value.message);
                const {username: currentUser, readMessage} = this.props;
                switch (message_type) {
                    case MESSAGE_TYPE.ADD: {
                        this.props.addUser(value.message);
                        messageNotify(value.message, () => readMessage(value.message), currentUser);
                        break;
                    }
                    case MESSAGE_TYPE.DELETE: {
                        this.props.kickUser(value.message);
                        messageNotify(value.message, () => readMessage(value.message), currentUser);
                        break;
                    }
                    case MESSAGE_TYPE.LEAVE: {
                        const {username} = value.message.message;
                        if (username === currentUser) {
                            const {type} = value.message;
                            const {id} = value.message.group_chat;
                            this.props.leaveChat({chatType: type, chatId: id});
                        } else {
                            this.props.kickUser(value.message);
                            messageNotify(value.message, () => readMessage(value.message), currentUser);
                        }
                        break;
                    }
                    default: {
                        if (is_edited) {
                            const {id} = value.message.group_chat;
                            this.props.updateMessage({...value.message, group_id: id});
                            messageNotify(value.message, () => readMessage(value.message), currentUser, true);
                        } else {
                            this.props.addMessage(value.message);
                            messageNotify(value.message, () => readMessage(value.message), currentUser);
                        }
                    }
                }
                break;
            }
            case SOCKET_FUNC.UPDATE_TENANT: {
                this.props.updateTenant(value.message);
                break;
            }
            case SOCKET_FUNC.OPERATION_CONFIRMED:
            case SOCKET_FUNC.OPERATION_STATUS_CHANGE:
            case SOCKET_FUNC.OPERATION_STATE_CHANGE: {
                const operations = value.message || [];
                if (Array.isArray(operations))
                    this.props.updateOpsOfVessel(operations);
                break;
            }
        }
    }

    tingTing = (value: any = {}) => {
        const {user_id} = this.props;
        const {id = null} = value.message || {};
        let bt = document.getElementById("bt");
        if (bt) {
            bt.focus();
            bt.click();
        }

        // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        // const source = audioContext.createBufferSource();
        // source.addEventListener('ended', () => {
        //     source.stop();
        //     audioContext.close();
        // });
        //
        // const request = new XMLHttpRequest();
        // request.open('GET', '/notification.mp3', true);
        // request.responseType = 'arraybuffer';
        // request.onload = () => {
        //     audioContext.decodeAudioData(
        //         request.response,
        //         (buffer: any) => {
        //             source.buffer = buffer;
        //             source.connect(audioContext.destination);
        //             source.start();
        //         },
        //         (e: any) => {
        //             console.log('Error with decoding audio data' + e.message);
        //         });
        // }
        // request.send();

        if (user_id !== id) {
            // const audio = new Audio('notification.mp3');
            // audio.play()
            //     .then()
            //     .catch(() => {
            //         const alert_elem: any = document.querySelector('.alert');
            //         if (alert_elem)
            //             alert_elem.addEventListener('click', ({target}: any) => {
            //                 if (target.matches('button')) {
            //                     const allowed = target.value === "1";
            //                     if (allowed) {
            //                         audio.play().then(() => {
            //                         });
            //                     }
            //                     alert_elem.remove();
            //                 }
            //             });
            //     });
        }
    }

    handleGetNewToken = () => {
        let exp = 0;

        try {
            const authentication: any = localStorage.getItem(KEY_STORAGE.AUTHENTICATION);
            exp = JSON.parse(authentication).payload.exp;
        } catch (error) {
            console.log(error)
            this.props.logout();
        }

        const duration = (exp * 1000) - Date.now()

        if (duration < timeToRefresh) {
            const handleFailure = (e: any) => {
                this.props.logout();
                showErrorResponse('Unable to refresh Token', e);
            }

            Auth.currentAuthenticatedUser().then(cognitoUser => {
                const {refreshToken} = cognitoUser.signInUserSession;
                try {
                    cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
                        const {idToken}: any = session || {};
                        localStorage.setItem(KEY_STORAGE.AUTHENTICATION, JSON.stringify(idToken));
                    })
                } catch (e) {
                    handleFailure(e);
                }
            }).catch(handleFailure);
        }
    }

    openWs = (props: IProps) => {
        const {user_id = null} = props || {};
        if (user_id) {
            this.isSocket = true;
            this.socket = new WSService({
                groups: ['global'],
                user: {user_id},
                listener: {
                    lev: SOCKET_LEV.GLOBAL,
                    func: (value: any) => this.processMessage(value)
                },
                renew: true
            });
        }
    }

    closeWs = () => {
        if (this.isSocket && this.socket) {
            this.isSocket = false
            this.socket.onClose()
        }
    }

    render() {
        return <RouterProvider router={router}/>;
    }
}

export default connect(mapStateToProps, {
    logout: loginActions.logout,
    getUser: loginActions.getUserRequest,
    updateOpsOfVessel: loginActions.updateOpsOfVessel,
    seenMessage: messageActions.seenMessage,
    readMessage: messageActions.readMessageRequest,
    addMessage: messageActions.addMessage,
    updateMessage: messageActions.updateMessage,
    addUser: messageActions.addUser,
    kickUser: messageActions.kickUser,
    leaveChat: messageActions.leaveChat,
    updateTenant: loginActions.updateTenant
})(App);
