import React, {ReactNode} from 'react';
import styles from './style.module.scss';

interface IProps {
    visible?: boolean
    className?: string
    separate?: string | ReactNode
    locations: { name: string }[]
}

const Locations: React.FC<IProps> = (props) => {
    const {visible = true, className, separate = <span className='arrow-right'>&#8594;</span>, locations} = props;
    if (!visible || locations.length === 0)
        return null;

    const list = [];
    for (let i = 1; i < locations.length; i++) {
        list.push(<span key={i}>{separate}</span>);
        list.push(<span key={`location-${i}`}>{locations[i].name}</span>);
    }

    return <div className={[styles.locations, className].join(' ')}>
        <span key={0}>{locations[0].name}</span>
        {list}
    </div>;
};

export default Locations;
