import React, {ReactElement} from 'react';
import {propContainer} from "./constants";
import LoadingBox from "../../components/LoadingBox";

export const Symbol = {
    m3: <>&#13221;</>
}

export enum TemplateCode {
    T1 = 'convert-string-to-html',
    T2 = 'response-send-operation-to-vessel',
    T3 = 'response-send-request-to-vessel'
}

export const template = {
    [TemplateCode.T1]: (data: string | ReactElement): ReactElement => typeof data !== 'string'
        ? data
        : <div dangerouslySetInnerHTML={{__html: data}}/>,

    [TemplateCode.T2]: (content: string, newOp: any = [], oldOp: any = []) => <div>
        <div>{content}</div>
        <div>{newOp.length > 0 ? `New: ${newOp.join(', ')}.\n` : ''}</div>
        <div>{oldOp.length > 0 ? `Update: ${oldOp.join(', ')}.` : ''}</div>
    </div>,

    [TemplateCode.T3]: (newRequest: string[] = [], existRequest: string[] = []) => <>
        {newRequest.length > 0 &&
            <p className='mb-1'>Request has been sent to {newRequest.join(', ')} successfully</p>}
        {existRequest.length > 0 && <p className='mb-1'>
            {`${existRequest.join(', ')} ${existRequest.length === 1 ? 'has' : 'have'} been added to Plan operation page already`}
        </p>}
    </>
}

export const propsSelect = {
    ...propContainer,
    notFoundContent: <LoadingBox/>
}