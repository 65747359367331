import React, {useCallback} from 'react';
import {Button, message, Upload} from "antd";
import {PaperClipOutlined} from "@ant-design/icons";
import styles from './style.module.scss';
import Files from "./Files";
import {datetime} from "../../../../../../util/library/datetime";
import {useEditOp} from "../../../../../../contexts/EditOperationContext";

interface IProps {
    isShowList: boolean
}

const AttachFile: React.FC<IProps> = (props) => {
    const [files, set] = useEditOp(state => state.files);
    const {isShowList = true} = props;

    const handleAdd = useCallback((file: any) => {
        const time = datetime().format('YYYYMMDD_HHmmss_');
        const {name} = file;
        const data = {key: time + name, name, file};
        set(({files: prev}) => {
            const value = Array.from(prev)
            const index = prev.indexOf((item: any) => item.name === name);
            if (index > -1) {
                value.splice(index, 1, data);
            } else {
                value.push(data);
            }
            return {files: value};
        })
    }, [set]);

    const list = files.map((item: any) => {
        const {isNew, key, name, file} = item;
        return isNew ? file : {uid: key, name}
    })

    return <div className={styles.wrapper} data-is-show-list={isShowList}>
        {isShowList && <Files/>}
        <Upload {...{
            fileList: list,
            multiple: true,
            showUploadList: false,
            className: styles['attach-file'],
            beforeUpload: (file: any) => {
                const isPDF = file.type === 'application/pdf';
                const isLt50M = file.size / 1024 / 1024 < 50;
                if (!isPDF) {
                    message.error(`${file.name} is not a PDF file`);
                    return false;
                }
                if (!isLt50M) {
                    message.error('File must smaller than 50MB!');
                    return false;
                }
                handleAdd(file)
                return false;
            },
        }} >
            <Button className='bt-primary pl-10 pr-10' data-icon={true}>
                <PaperClipOutlined/>
                <span>Attach PDF file</span>
            </Button>
        </Upload>
    </div>;
};

export default AttachFile;
