import React, {useEffect, useRef} from 'react';
import {checkLimit} from "../../../../../../../util/varibles/global";
import styles from "./style.module.scss";
import {useEditOp} from "../../../../../../../contexts/EditOperationContext";

interface IProps {
}

const DragEl: React.FC<IProps> = () => {
    const rootRef = useRef<HTMLDivElement>(null);
    const [dragData, set] = useEditOp(state => state.dragData)

    useEffect(() => {
        const onMouseMove = (e: React.MouseEvent | any) => {
            const el = rootRef.current;
            if (el) {
                const {x = 0, y = 0} = dragData || {}
                el.style.display = 'none';
                const {pageX, pageY} = e;
                let left = checkLimit(0, window.innerWidth, pageX);
                let top = checkLimit(0, window.innerHeight, pageY);
                el.style.left = (left - x) + 'px';
                el.style.top = (top - y) + 'px';
                el.style.display = 'block';
            }
        };
        const onMouseUp = (e: React.MouseEvent | any) => {
            const el = rootRef.current;
            if (el && dragData) {
                let {pageX, pageY} = e;
                el.style.display ='none';
                pageX = checkLimit(110, window.innerWidth, pageX);
                pageY = checkLimit(0, window.innerHeight, pageY);
                let element: HTMLElement | any = document.elementFromPoint(pageX, pageY);
                let harvestElement = element.closest('.' + styles.operation);
                const {cancel, merge} = dragData;
                if (harvestElement) {
                    merge(Number(harvestElement.id));
                }
                cancel()
                el.style.display ='block';
                set({dragData: undefined})
            }
        };
        if (dragData) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        } else {
            document.body.style.cursor = 'unset';
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }
        return () => {
            document.body.style.cursor = 'unset';
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }
    }, [dragData]);

    const {width = 0, height = 0} = dragData || {};

    return <div ref={rootRef} className='fixed' style={{width: `${width}px`, height: `${height}px`, zIndex: '1032'}}>
        {dragData?.View}
    </div>;
};

export default DragEl;
