import {all, call, put, select, takeEvery} from 'redux-saga/effects'
import {CALENDAR_ID} from "./constants";
import {getScheduleOfWeeksFetch} from "../../util/services/operation";
import {jumpToToday} from "../../util/varibles/global";
import {aWeekMillisecond} from "../../util/varibles/constants";
import {OverviewSetting} from "../../util/varibles/userSetting";
import {selectOneSetting, selectOpMode, selectOverview, selectTenantId} from "../../util/store/selectors";
import {IOperation, IVessel} from "../../util/varibles/interface";
import {overviewActions} from "./reducer";
import {PayloadAction} from "@reduxjs/toolkit";

function* initialWatcher(args: PayloadAction<{ action?(vessels: IVessel[]): void }>): Generator<any, any, any> {
    try {
        const {action} = args.payload;
        const tenantId = yield select(selectTenantId);
        const weeks = yield select(selectOverview.weeks);
        const {time: start} = weeks[0];
        const {time: finish} = weeks[weeks.length - 1];
        const result = yield call(getScheduleOfWeeksFetch, start, finish + aWeekMillisecond - 1) || {};
        const vesselIds = yield select(selectOneSetting(OverviewSetting.VESSEL_FILTER));
        const opMode = yield select(selectOpMode);
        yield put(overviewActions.initialDataSuccess({result, tenantId, vesselIds, opMode}));
        if (action)
            action(result.vessels)

        setTimeout(() => jumpToToday(CALENDAR_ID), 300);
    } catch (error) {
        yield put(overviewActions.initialDataFailure())
        console.log(error);
    }
}

function* lazyLoadingWatcher({...args}) {
    try {
        const {isLoad, key, startTime, finishTime, weeks} = args.payload;
        if (isLoad) {
            const result: IOperation[] = yield call(getScheduleOfWeeksFetch, startTime, finishTime);
            yield put(overviewActions.lazyLoadingSuccess({result, key, weeks}))
        }
    } catch (error) {
        console.log(error);
        yield put(overviewActions.lazyLoadingFailure())
    }
}

export default function* overviewSaga() {
    yield all([
        takeEvery(overviewActions.initialDataRequest.type, initialWatcher),
        takeEvery(overviewActions.lazyLoadingRequest.type, lazyLoadingWatcher),
    ])
}
