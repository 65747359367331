import React, {Component} from 'react';
import styles from './style.module.scss';
import {CheckOutlined} from "@ant-design/icons";

interface IProps {
    id: string
    label: string
    icon: any
    isCheck: boolean

    handleClick(id: string): void
}

class OptionItem extends Component<IProps> {
    render() {
        const {id, label, icon, isCheck} = this.props;
        return <div {...{
            className: styles['container-option-item'],
            'data-is-check': isCheck,
            onClick: () => this.props.handleClick(id)
        }}>
            <div className={styles['option-item']}>
                <div className={styles['checkbox']}>
                    <CheckOutlined/>
                </div>
                <span className='no-select'>{label}</span>
                {icon}
            </div>
        </div>;
    }
}

export default OptionItem;
