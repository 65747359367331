import React from 'react';
import {IOperation} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import {connect} from "react-redux";
import {aWeekMillisecond, CERTAIN_TYPE, certainType, OPERATION_MODE} from "../../../../util/varibles/constants";
import OpInfo from "../../../PlanOperationPage/Popup/OpInfo";
import {IPropsOp} from "./index";
import {getStartFinishByOpMode} from "../../../../util/varibles/global";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import {HEIGHT_PER_OP} from "../Vessels";
import {AppState} from "../../../../util/store/store";


const mapStateToProps = (state: AppState) => {
    const {operation_mode = OPERATION_MODE.PLAN} = state.login.user.tenant;
    return {
        first: state.overview.weeks[0].time,
        space: state.overview.weeks.length * aWeekMillisecond,
        operation_mode
    }
};

interface IProps extends IPropsOp {
    data: IOperation
    first: number
    space: number
    operation_mode: OPERATION_MODE
}

const WrapperOperation: React.FC<IProps> = (props) => {
    const {data, first, level, space, moreHeight, vesselHeight, operation_mode} = props;
    const open = () => {
        const div = openPopup(<OpInfo {...{
            isEdit: false,
            isShow: true,
            data: data,
            onClose: () => div.remove()
        }}/>)
    }

    if (!vesselHeight[data.vessel.id])
        return null;
    const {start, finish} = getStartFinishByOpMode[operation_mode](data.activity_log, data.operation.status);

    const {height, index: vesselIndex, position} = vesselHeight[data.vessel.id];
    let top = (vesselIndex + (position * moreHeight)) + ((height + moreHeight) * .08) + 60;

    if (!level.main) {
        top += (level.level * HEIGHT_PER_OP)
    }

    const style = {
        top: `${top}px`,
        left: `calc((100% - 160px) * ${(start - first)} / ${space} + 160px)`,
        width: `calc((100% - 160px) * ${(finish - start)} / ${space})`
    }
    const {color} = certainType[data.operation.certain_mode] || certainType[CERTAIN_TYPE.NONE];
    return <div className={styles.wrapper} style={style} onClick={open}>
        <div className={styles.info}>{props.children}</div>
        <div className={styles.line} style={{background: color}}/>
    </div>;
};

export default connect(mapStateToProps, {})(WrapperOperation);
