import React from 'react';
import {Button, Result} from "antd";
import styles from './style.module.scss';
import {PATHNAME} from "../../util/varibles/constants";
import {nextPage} from "../../util/library/Router";

class NotFoundPage extends React.Component {
    render() {
        return <Result
            status="500"
            title={<div className={styles.title}>Error page</div>}
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button className='bt-primary m-auto' type="primary" onClick={() => nextPage(PATHNAME.HOME)}>
                Back Dashboard
            </Button>}
        />;
    }
}

export default NotFoundPage;
