import React, {useEffect, useRef, useState} from 'react';
import Search from "antd/es/input/Search";
import styles from './style.module.scss';
import {InputRef} from "antd";
import {notify, NotifyCode} from "../../../../util/varibles/message";

interface IProps {
    open: boolean
    loading: boolean

    onClose(change?: boolean): void

    onLoad(code?: string): void
}

const SearchBox: React.FC<IProps> = ({open, onClose, onLoad}) => {
    const [search, setSearch] = useState('');
    const [change, setChange] = useState(false);
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (open) {
            inputRef.current!.focus({cursor: 'start'});
            setChange(false);
        }
    }, [open]);
    const onSearch = () => {
        if (search.length !== 6) {
            notify.error(NotifyCode.E38)()
            return;
        }
        setChange(true);
        onLoad(search.toUpperCase());
    }

    return <Search
        className={styles.search}
        ref={inputRef}
        onKeyDown={(e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                if (search.length === 0) {
                    onClose(change)
                } else {
                    setSearch('');
                    onLoad();
                }

                return false;
            }
        }}
        value={search}
        onChange={({target}) => setSearch(target.value)}
        data-open={open}
        placeholder="Search by operation code"
        allowClear
        enterButton="Search"
        onSearch={onSearch}
    />;
};

export default SearchBox;
