import React, {Component} from 'react';
import styles from './style.module.scss';
import {formatNumber} from "../../util/varibles/global";
import {IVessel, TOperation} from "../../util/varibles/interface";

interface IProps {
    isLabel: boolean
    visible: boolean
    className: any
    operation: TOperation
    vessel: IVessel
}

class SumWeight extends Component<IProps> {
    static defaultProps = {
        isLabel: true,
        visible: true,
        className: [styles['margin-left'], styles['border-right']].join(' ')
    }

    render() {
        const {visible} = this.props;
        if (!visible || !this.props.vessel) return null;

        const {sub_operations} = this.props.operation;
        const {capacity} = this.props.vessel;
        const totalWeight = sub_operations.reduce((total: number, item: any) => total + item.total_weight, 0);

        return <div className={this.props.className}>
            {this.props.isLabel && <span>Sum weight: </span>}
            {formatNumber(Math.round(totalWeight / 1000) / 1000)} t
            ({Math.round(totalWeight / capacity * 100)}%)
        </div>;
    }
}

export default SumWeight;
