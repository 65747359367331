import React, {useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {IDisease} from "../../../util/varibles/interface";
import styles from "../style.module.scss";
import InputSearch from "../../../components/Basic/InputSearch";
import {Button, Divider, Table, Tooltip} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import PopupEdit from "../PopupEdit";
import {DiseaseService} from "../../../util/services/disease";
import Menu from "../../../components/Menu";
import Icon, {ICON_NAME} from "../../../components/Icon";
import Delete from "../Delete";
import {ACTION} from "../../../util/varibles/permission";
import {diseaseActions} from "../reducer";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    diseases: state.disease.diseases,
    loading: state.disease.loading,
    actions: state.login.user.permission
});

interface IProps {
    diseases: IDisease[]
    loading: boolean
    actions: any
}

const Diseases: React.FC<IProps> = (props) => {
    const {actions, diseases, loading} = props;
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            width: 60,
            render: (data: any) => {
                const list = [];

                if (actions[ACTION.DISEASE.UPDATE]) {
                    list.push({
                        key: 'edit',
                        label: <div className='menu-line' onClick={() => edit(data)}>
                            <Icon icon={ICON_NAME.EDIT}/>
                            <div>Edit disease</div>
                        </div>
                    })
                }

                if (actions[ACTION.DISEASE.DELETE]) {
                    list.push({
                        key: 'delete',
                        label: <Delete data={data} onSuccess={() => dispatch(diseaseActions.delete(data))}/>
                    })
                }

                return <Menu items={list} placement='bottomRight'>
                    <div className='three-dot'/>
                </Menu>
            }
        }
    ]

    const edit = (data?: any) => {
        let action: any, title, okText, request;
        if (data) {
            title = 'Update disease ' + data.name;
            action = diseaseActions.update;
            request = DiseaseService.update
        } else {
            title = 'Add disease';
            okText = 'Add'
            action = diseaseActions.add
            request = DiseaseService.create
        }
        const el = openPopup(<PopupEdit
            open={true}
            data={data}
            title={title}
            okText={okText}
            request={request}
            onOk={(disease) => dispatch(action(disease))}
            onClose={() => el.remove()}
        />)
    }

    return <div className={styles.category}>
        <div className='d-flex justify-content-end mb-10'>
            <InputSearch placeHolder='Search by name' value={search}
                         onChange={(search) => setSearch(search)}/>
            <Divider className='divider-vertical' type='vertical'/>
            {actions[ACTION.DISEASE.CREATE] && <Tooltip title="Add disease">
                <Button className='bt-primary bt-plus' type="primary" shape="circle"
                        icon={<PlusOutlined style={{fontSize: '18px'}}/>}
                        onClick={() => edit()}
                />
            </Tooltip>}
        </div>
        <Table columns={columns} dataSource={diseases} rowKey="id" pagination={false} loading={loading}/>
    </div>;
};

export default connect(mapStateToProps, {})(Diseases);