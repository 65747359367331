import React, {Component} from 'react';
import styles from "./style.module.scss";
import {IOperation} from '../../../../../../util/varibles/interface';

class Transport extends Component<IOperation> {
    render() {
        const {name = ''} = this.props.vessel || {};
        const {operation} = this.props;
        const {source, destination} = operation;
        const voyage = name + source.name + ' - ' + destination.name;

        return <>
            {voyage.length <= 38 ? <div className={styles['line-info']}>
                <div className='h-24 d-flex align-items-center text-truncate'>
                    <div>{name}</div>
                    <div className={styles['divider-custom']}/>
                    {source.name}
                    <span className='arrow-right'>&#8594;</span>
                    {destination.name}
                </div>
            </div> : <div className={styles['line-info']}>
                <div>
                    <div className='d-flex align-items-center text-truncate'>{name}</div>
                    <div className='h-24 d-flex align-items-center text-truncate'>
                        {source.name}
                        <span className='arrow-right'>&#8594;</span>
                        {destination.name}
                    </div>
                </div>
            </div>}
        </>;
    }
}

export default Transport;
