import React, {Component} from 'react';
import styles from './style.module.scss';

interface IProps {
    status: any
    statusText: string
}

class Status extends Component<IProps> {
    render() {
        return <div className={styles['operation-status']} data-status={this.props.status}>
            {this.props.statusText}
        </div>;
    }
}

export default Status;
