import React, {useState} from 'react';
import {Badge, Button, Checkbox, Dropdown} from "antd";
import {propContainer, treatmentType} from "../../../../util/varibles/constants";
import styles from "./style.module.scss";
import Icon, {ICON_NAME} from "../../../../components/Icon";

interface IProps {
    value: Set<any>
    opts: string[]
    onChange: (value: any) => void
}

const FilterOpType: React.FC<IProps> = ({value,opts, onChange}) => {
    const [visible, setVisible] = useState(false);

    const handleClear = () => onChange(new Set([]))

    const handleChange = (key: string, checked: boolean) => {
        if (checked)
            onChange(new Set([...Array.from(value), +key]))
        else {
            const temp = new Set(value);
            temp.delete(+key);
            onChange(temp)
        }
    }

    const items = [
        {
            className: styles.filter,
            key: 'filter',
            disabled: true,
            label: <>
                <div className='flex justify-between items-center'>
                    <div className='font-[600] text-[15px]'>Filter treatment type</div>
                    <div className='link' onClick={handleClear}>Clear</div>
                </div>
                <div className='flex flex-col mt-2 gap-1 pb-2'>
                    {opts.map(key => {
                        const {name} = treatmentType[key] ||{}
                        return <Checkbox
                            key={key}
                            checked={value.has(+key)}
                            onChange={({target}) => handleChange(key, target.checked)}
                        >
                            {name}
                        </Checkbox>
                    })}
                </div>
            </>
        }
    ]

    return <Dropdown
        {...propContainer}
        open={visible}
        trigger={['click']}
        onOpenChange={(changed) => setVisible(changed)}
        menu={{items}}
        overlayClassName={styles.dropdown}
        placement="bottomLeft"
        arrow
    >
        <Badge count={value.size}>
            <Button className='bt-ghost h-32' data-icon={true} data-active={visible}>
                <Icon icon={ICON_NAME.FILTER}/>
            </Button>
        </Badge>
    </Dropdown>;
};

export default FilterOpType;
