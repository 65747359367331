import React, {ReactNode, useEffect, useRef, useState} from 'react';
import styles from "./style.module.scss";
import {showErrorResponse} from "../../../../../util/varibles/global";
import {SiteService} from "../../../../../util/services/site";
import {Button, Checkbox} from "antd";
import {openPage} from "../../../../../util/library/Router";
import {PATHNAME} from "../../../../../util/varibles/constants";
import {DiseaseService} from "../../../../../util/services/disease";
import {IDisease} from "../../../../../util/varibles/interface";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import LoadingBox from "../../../../../components/LoadingBox";

const initValue = (types: IDisease[]) => {
    const ids = types.map(item => item.id);
    return {value: ids, old: new Set(ids)}
}

interface IProps {
    site_id: string
    unit_diseases: any

    onUpdate?(payload: IDisease[]): void
}

const Diseases: React.FC<IProps> = ({site_id, unit_diseases, onUpdate}) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const {types = []} = unit_diseases || {};
    const [{loading, diseases = []}, setDiseases] = useState<{
        loading: boolean,
        diseases?: IDisease[]
    }>({loading: false});
    const [loadingSave, setLoadingSave] = useState(false)
    const [{value, old}, setValue] = useState<{ value: string[], old: Set<string> }>(initValue(types));
    const [changes, setChanges] = useState<{ [id: string]: boolean }>({})

    useEffect(() => {
        setDiseases({loading: true})
        DiseaseService.gets()
            .then(rs => {
                setDiseases({loading: false, diseases: rs})
            })
            .catch(() => {
                setDiseases({loading: false})
            })
    }, []);

    const handleSave = () => {
        const params = {site_id, unit_diseases: {types: value}}
        setLoadingSave(true)
        SiteService.updateByPlanner(params)
            .then((rs) => {
                notify.success(NotifyCode.S2)(['Disease']);
                setValue(prev => ({...prev, old: new Set(value)}));
                setChanges({});
                setLoadingSave(false);
                const {types = []} = rs[0] || {}
                if (onUpdate)
                    onUpdate(types);
            })
            .catch((error) => {
                setLoadingSave(false);
                showErrorResponse(notify[NotifyCode.E35](['Disease']), error);
            })
    }

    const handleCheck = (e: CheckboxChangeEvent, id: string) => {
        const {checked} = e.target;
        setChanges(prev => {
            const {[id]: _, ...args} = prev;
            if ((old.has(id) && checked) || (!old.has(id) && !checked))
                return args

            return {...prev, [id]: checked}
        })
    }

    return <div className={styles.section} ref={rootRef}>
        <div className={[styles.line, 'mt-10'].join(' ')}>
            {diseases.length === 0 && <LoadingBox
                className={styles.empty}
                loading={loading}
                message={<div>
                    <span>The disease has not been defined yet.</span>
                    <br/>
                    <span>Please</span>
                    <span className='link' onClick={() => openPage(PATHNAME.DISEASE)}>define disease</span>
                    <span>first then add into site later.</span>
                </div>}
            />}
            <Checkbox.Group
                className={[styles.options, 'checkbox-fill'].join(' ')}
                value={value}
                onChange={(checked) => setValue(prev => ({...prev, value: checked as string[]}))}
            >
                {diseases.reduce((list: ReactNode[], {id, name, is_active}) => {
                    if (old.has(id) || is_active)
                        return [...list, <Checkbox
                            key={id}
                            value={id.toString()}
                            onChange={(e) => handleCheck(e, id)}
                        >
                            {name}
                        </Checkbox>]
                    return list;
                }, [])}
            </Checkbox.Group>
            {Object.keys(changes).length > 0 &&
                <Button
                    className={['bt-default', styles.openAdd].join(' ')}
                    loading={loadingSave}
                    onClick={handleSave}
                >
                    Save
                </Button>}
        </div>
    </div>;
};

export default Diseases;