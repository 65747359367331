import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import {aDayMillisecond} from "../../../../../util/varibles/constants";
import {getDatesBetween2Dates} from "../../../../../util/varibles/global";
import {hours, minus, minWidthHours, widthMinus} from "../constants";
import {datetime} from "../../../../../util/library/datetime";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    history: state.vesselDetail.history,
    isLive: state.vesselDetail.isLive,
});

interface IProps {
    history: any
    isLive: boolean
}

class RulerComponent extends Component<IProps> {

    componentDidMount() {
        const {isLive} = this.props;
        if (isLive) {
            const rulerEl: any = document.getElementById('ruler');
            const {offsetWidth} = rulerEl;
            rulerEl.scrollTo({left: -offsetWidth, behavior: 'smooth',});
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        const {history: dataOld} = this.props;
        const {history: dataNew} = nextProps;
        const {startTime, finishTime} = dataNew;
        return dataOld.startTime !== startTime || dataOld.finishTime !== finishTime;
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>) {
        const {history: dataOld} = prevProps;
        const {history: dataNew} = this.props;
        const {startTime, finishTime} = dataNew;
        if (dataOld.startTime !== startTime || dataOld.finishTime !== finishTime) {
            const rulerEl: any = document.getElementById('ruler');
            const {scrollWidth} = rulerEl;
            rulerEl.scrollTo({left: -scrollWidth, behavior: 'smooth',});
        }
    }

    renderDays = () => {
        const {startTime, finishTime} = this.props.history;
        const start = datetime(startTime);
        const finish = datetime(finishTime);
        const startHistory = start.startOf('day').time;
        const finishHistory = finish.startOf('day').time;
        if (startHistory === finishHistory) {
            const width = (finish.time - start.time) / 3600000 * widthMinus * 3;
            return <div key={start.time} {...{
                className: styles.day,
                style: {minWidth: width + 'px', width: width + 'px', right: 0,}
            }}>
                <div className={styles['container-hour']} style={{
                    right: ((finishHistory + aDayMillisecond - finish.time) / 3600000 * widthMinus * -3) + 'px'
                }}>
                    <div className={styles.hour}>
                        <div className={styles.time}>{datetime(start).format('ddd')}</div>
                        {minus}
                    </div>
                    {hours}
                </div>
            </div>
        } else {
            const nextStartDate = startHistory + aDayMillisecond;
            const days = getDatesBetween2Dates(nextStartDate, finishHistory, false);
            const widthStart = (nextStartDate - start.time) / 3600000 * widthMinus * 3;
            const widthFinish = (finish.time - finishHistory) / 3600000 * widthMinus * 3;
            const content = [];
            for (let i = days.length - 1; i >= 0; i--) {
                content.push(<div key={days[i]} {...{
                    className: styles.day,
                    style: {minWidth: minWidthHours + 'px', width: minWidthHours + 'px',}
                }}>
                    <div className={styles.hour}>
                        <div className={styles.time}>{datetime(days[i]).format('ddd')}</div>
                        {minus}
                    </div>
                    {hours}
                </div>)
            }
            return <>
                <div
                    key={finish.time}
                    className={styles.day}
                    style={{minWidth: widthFinish + 'px', width: widthFinish + 'px'}}
                >
                    <div className={styles.hour}>
                        <div className={styles.time}>{start.format('ddd')}</div>
                        {minus}
                    </div>
                    {hours}
                </div>
                {content}
                <div key={start.time} {...{
                    className: styles.day, 'data-is-start': true,
                    style: {minWidth: widthStart + 'px', width: widthStart + 'px',}
                }}>
                    <div className={styles.hour}>
                        <div className={styles.time}>{start.format('ddd')}</div>
                        {minus}
                    </div>
                    {hours}
                </div>
            </>
        }
    }

    render() {
        return <div id='ruler' className={[styles.ruler, 'no-select'].join(' ')}>
            {this.renderDays()}
        </div>;
    }
}

export default connect(mapStateToProps, {})(RulerComponent);
