import React, {Component} from 'react';
import {Button, Form, Input} from 'antd';
import {connect} from "react-redux";
import {FORM_TYPES, ITarget} from "./constants";
import {FormInstance} from 'antd/lib/form';
import styles from "./style.module.scss";
import {configurationActions} from "./reducer";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

interface IProps {
    target: ITarget

    addServer(payload: any): void

    updateServer(payload: any): void
}

interface IState {
}

class FormObject extends Component<IProps, IState> {
    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        const {target: {data}} = this.props;
        if (data && this.formRef.current)
            this.formRef.current.setFieldsValue(data)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {target: {data}} = this.props;
        if (this.props !== prevProps && data && this.formRef.current)
            this.formRef.current.setFieldsValue(data)
    }

    onFinish = (values: any) => {
        const {target: {data, index}} = this.props;
        if (data) {
            this.props.updateServer({values, index, formType: FORM_TYPES.OBJECT});
        } else {
            this.props.addServer({values, formType: FORM_TYPES.OBJECT});
        }
    };

    render() {
        const {target: {data}} = this.props;
        return <Form {...layout} ref={this.formRef} name="object" onFinish={this.onFinish}>
            <h5 className='mb-12'><b>{data ? `Update object: ${data.name}` : 'Create object'}</b></h5>
            <Form.Item className='mb-0' label="Id" name="id">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Type" name="type">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Name" name="name">
                <Input/>
            </Form.Item>
            <Form.Item className='mt-10' {...tailLayout}>
                <Button className={[styles['btn'], styles['effect01']].join(' ')} htmlType='submit'>Save</Button>
            </Form.Item>
        </Form>;
    }
}

export default connect(null, {
    addServer: configurationActions.addServer,
    updateServer: configurationActions.updateServer,
})(FormObject);
