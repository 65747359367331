import {initialState, NAME_SLICE} from "./constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IRegion} from "../../varibles/interface";

const regionSlice = createSlice({
    name: NAME_SLICE,
    initialState,
    reducers: {
        getRegions: (state) => {
            state.loading = true;
        },
        getRegionsSuccess: (state, action: PayloadAction<IRegion[]>) => {
            state.loading = false;
            state.data = action.payload;
        },
        getRegionsFailure: (state) => {
            state.loading = false;
        },
    },
})

export const regionActions = regionSlice.actions

const regionReducer = regionSlice.reducer;

export default regionReducer;
