import React, {Component} from 'react';
import styles from "./style.module.scss";
import {DatePicker} from "antd";
import {SHORT_TIME_FORMAT} from "../../../../../util/varibles/constants";
import {datetime} from "../../../../../util/library/datetime";
import {getElById} from "../../../../../util/varibles/global";

const timeColors = ['#2F8ED5', '#1B6399', '#56A513', '#818e98'];

interface IProps {
    item: any
    format: any
    isEdit: boolean
    disabledDate: any
    disabledTime: any
    handleChangeTime: any
}

class Time extends Component<IProps> {
    state = {
        isOpen: false,
        value: null
    }

    componentWillUnmount() {
        document.removeEventListener('click', () => this.handleClickLayout);
        this.setState({isOpen: false})
    }

    togglePicker = (isOpen: any) => {
        const {time} = this.props.item;
        this.setState({isOpen, value: datetime(time).value}, () => {
            if (isOpen) {
                document.addEventListener('click', this.handleClickLayout)
            } else {
                document.removeEventListener('click', this.handleClickLayout)
            }
        })
    }

    handleClickLayout = (e: any) => {
        const {target} = e;
        const {index} = this.props.item;
        const el = target.closest(`.${styles['info-time']}, .ant-picker-dropdown , .${styles['dropdown-picker']}`);
        if (el) {
            if (el.id !== 'info-time-' + index && el.className.includes(`${styles['info-time']}`)) {
                this.togglePicker(false);
            }
        } else {
            this.togglePicker(false);
        }
    }

    render() {
        const {item, format, isEdit, disabledDate, disabledTime, handleChangeTime} = this.props;
        const {isOpen, value} = this.state;

        return <div key={item.time} {...{
            className: styles['wrapper-time'],
            tabIndex: 1,
            title: item.placeHolder
        }}>
            <div className={styles['icon']} style={{background: timeColors[item.type]}}/>
            <div className={styles['container-time']}>
                <span {...{className: styles['name-location'], title: item.title}}>{item.title}</span>
                <div {...{
                    className: styles['time'],
                    "data-is-edit": isEdit,
                }}>
                    <div {...{
                        id: 'info-time-' + item.index,
                        className: styles['info-time'],
                        onClick: () => this.togglePicker(true)
                    }}>
                        <div {...{className: styles['name']}}>
                            {datetime(item.time).format(format)}
                        </div>
                    </div>
                    {isEdit && <DatePicker {...{
                        getPopupContainer: () => getElById('popup-operation-info'),
                        className: styles['date-picker'],
                        popupClassName: styles['dropdown-picker'],
                        value,
                        showTime: {
                            minuteStep: 15,
                            format: SHORT_TIME_FORMAT
                        },
                        open: isOpen,
                        onOk: () => this.togglePicker(false),
                        onChange: (e: any) => {
                            this.togglePicker(false);
                            handleChangeTime(e);
                        },
                        disabledDate,
                        disabledTime,
                    }}/>}
                </div>
            </div>

        </div>;
    }
}

export default Time;
