import React, {Component} from 'react';
import styles from './style.module.scss';

interface IProps {
    value: string
    placeHolder: string
    isChangePerKey: boolean
    className?: string

    onChange(value: string): void
}

interface IState {
    value: string
}

class InputSearch extends Component<IProps, IState> {
    static defaultProps = {
        value: '',
        placeHolder: '',
        isChangePerKey: true
    }

    state = {
        value: ''
    };

    constructor(props: IProps) {
        super(props);
        this.state.value = this.props.value || ''
    }


    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        this.setState({value});
        if (this.props.isChangePerKey)
            this.props.onChange(value);
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape')
            this.clearInput();
        if (e.key === 'Enter' && !this.props.isChangePerKey)
            this.props.onChange(this.state.value);
    };

    clearInput = () => {
        this.props.onChange('');
        this.setState({value: ''});
    };

    render() {
        const {placeHolder, className} = this.props;
        const {value} = this.state;
        return <div className={[styles.search, className].join(' ')}>
            <input placeholder={placeHolder} value={value} onChange={this.handleChange} onKeyDown={this.handleKeyDown}/>
            {value.length > 0 ? <span className={styles.clear} onClick={this.clearInput}>&times;</span> :
                <span/>}
        </div>;
    }
}

export default InputSearch;
