import React, {Component} from 'react';
import styles from './style.module.scss';
import {jumpToToday} from "../../../util/varibles/global";

interface IProps {
    calenderId: string,

    getHeightWeek(): number
}

class ButtonJumpCalendar extends Component<IProps> {
    handlePrev = () => {
        const {calenderId, getHeightWeek} = this.props;
        const heightWeek = getHeightWeek();
        const parentEl = document.getElementById(calenderId);
        if (parentEl) {
            const {scrollTop} = parentEl
            const position = Math.floor((scrollTop + 5) / heightWeek);
            parentEl.scrollTo({top: (position - 1) * heightWeek, behavior: 'smooth'})
        }
    }

    handleNext = () => {
        const {calenderId, getHeightWeek} = this.props;
        const heightWeek = getHeightWeek();
        const parentEl = document.getElementById(calenderId);
        if (parentEl) {
            const {scrollTop} = parentEl
            const position = Math.floor(scrollTop / heightWeek);
            parentEl.scrollTo({top: (position + 1) * heightWeek, behavior: 'smooth'})
        }
    }

    handleToday = () => {
        const {calenderId} = this.props;
        jumpToToday(calenderId);
    }

    render() {
        return <div className={styles.container}>
            <div className={styles['bt-arrow']} onClick={this.handlePrev}>
                &#8593;
            </div>
            <div className='bt-default h-32' onClick={this.handleToday}>Today</div>
            <div className={styles['bt-arrow']} onClick={this.handleNext}>
                &#8595;
            </div>
        </div>;
    }
}

export default ButtonJumpCalendar;
