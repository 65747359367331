import React, {useRef, useState} from 'react';
import {Input, Tag, Tooltip} from 'antd';
import styles from './style.module.scss';
import {PlusOutlined} from "@ant-design/icons/lib";
import {connect} from "react-redux";
import {IChecklist} from "../../../util/varibles/interface";
import {updateTagsFetch} from "../../../util/services/checklist";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    checklists: state.checklist.checklists
});


interface IProps {
    checklist: IChecklist
    checklists: IChecklist[]

    isSend?: boolean

    onSave(checklist: IChecklist | any): void
}

const Tags: React.FC<IProps> = (props) => {
    const {checklist, isSend, checklists} = props;
    const {tags = []} = checklist || {};
    const inputRef = useRef<any>();
    const editRef = useRef<any>();
    const [inputValue, setInputValue] = useState('');
    const [inputVisible, setInputVisible] = useState(false);
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const handleRemove = (removedTag: string) => {
        const {id, tags = []} = checklist || {};
        const data = tags.filter(item => item !== removedTag);
        new Promise(resolve => resolve(updateTagsFetch({id, tags: data})))
            .then((rs: any) => {
                notify.success(NotifyCode.S2)(['The checklist']);
                props.onSave(rs);
            })
            .catch(error => {
                showErrorResponse('Update failed', error);
            })
    };

    const showInput = () => {
        setInputVisible(true);
        setTimeout(() => inputRef.current?.focus(), 10)
    };

    const handleInputChange = (e: any) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        const {id, tags = []} = checklist || {};
        const data = [...tags, inputValue]
        if (isSend) {
            new Promise(resolve => resolve(updateTagsFetch({id, tags: data})))
                .then((rs: any) => {
                    notify.success(NotifyCode.S2)(['The checklist']);
                    props.onSave(rs);
                    setInputVisible(false);
                    setInputValue('');
                })
                .catch(error => {
                    showErrorResponse('Update failed', error);
                })
        } else {
            props.onSave({...checklist || {}, tags: data});
            setInputVisible(false);
            setInputValue('');
        }
    };

    const handleEditInputChange = (e: any) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        let {id, tags = []} = checklist || {};
        tags[editInputIndex] = editInputValue;

        if (id) {
            new Promise(resolve => resolve(updateTagsFetch({id, tags})))
                .then((rs: any) => {
                    notify.success(NotifyCode.S2)(['The checklist']);
                    props.onSave(rs);
                    setEditInputIndex(-1);
                    setEditInputValue('');
                })
                .catch(error => {
                    showErrorResponse('Update failed', error);
                })
        } else {
            props.onSave({...checklist || {}, tags});
            setEditInputIndex(-1);
            setEditInputValue('');
        }
    };

    return <div className={styles['tags']}>
        <div>
            {tags.map((tag, index) => {
                if (editInputIndex === index) {
                    return <Input
                        ref={editRef}
                        key={index}
                        size="small"
                        className={styles['tag-input']}
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                    />;
                }

                const isLongTag = tag.length > 20;

                const tagElem = (
                    <Tag
                        className={styles['edit-tag']}
                        key={index}
                        closable
                        onClose={() => handleRemove(tag)}
                    >
			              <span onClick={e => {
                              setEditInputIndex(index);
                              setEditInputValue(tag);
                              setTimeout(() => editRef.current?.focus(), 10);
                              e.preventDefault();
                          }}
                          >
			                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
			              </span>
                    </Tag>
                );
                return isLongTag ? <Tooltip title={tag} key={index}>{tagElem}</Tooltip> : tagElem;
            })}
            {inputVisible && <Input
                key='input'
                ref={inputRef}
                type="text"
                size="small"
                className={styles['tag-input']}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
            />}
            {!inputVisible && <Tag key='new-tag' className={styles['site-tag-plus']} onClick={showInput}>
                <PlusOutlined/> New Tag
            </Tag>}
        </div>
    </div>;
};

export default connect(mapStateToProps, {})(Tags);
