import React, {useEffect, useState} from 'react';

import styles from './style.module.scss';
import {OPERATION_TYPE, propsModal} from "../../../util/varibles/constants";
import {Modal} from "antd";
import {getOperationWeatherFetch, updateOperationStatusFetch} from "../../../util/services/operation";
import {paramsWeatherOfOp} from "../../../pages/PlanOperationPage/util";
import Info from "./Info";
import {IOperation} from "../../../util/varibles/interface";
import {showErrorResponse} from "../../../util/varibles/global";

const widthPopup = 880;

const getWeather = (data: IOperation, success: Function, failure: Function) => {
    const {activity_log, operation: {operation_type, tenant_id}} = data;
    if (operation_type === OPERATION_TYPE.UNAVAILABLE) {
        failure({message: "Invalid operation"});
        return;
    }
    new Promise((resolve) => {
        const params = paramsWeatherOfOp[operation_type](data.operation, {operation_type, tenant_id, activity_log});

        resolve(getOperationWeatherFetch(params));
    })
        .then((rs: any) => {
            success(rs);
        })
        .catch((error) => {
            failure(error);
        })
}

interface IProps {
    isEdit: boolean
    isOwn: boolean
    isApprove: boolean
    data: IOperation

    updateOperation(payload: { data: IOperation, isCheck?: boolean }): void

    onClose?(): void
}

const PopupOpInfo: React.FC<IProps> = (props) => {
    const {isEdit, isOwn, isApprove, data, updateOperation, onClose} = props;
    const [visible, setVisible] = useState(true);
    const [loadingWeather, setLoadingWeather] = useState(false);
    const [weather, setWeather] = useState({});
    const [activity_log, setActivity] = useState(data.activity_log);

    useEffect(() => {
        setLoadingWeather(true)
        getWeather(data, (rs: any) => {
            setWeather(rs);
            setLoadingWeather(false);
        }, (error: any) => {
            setLoadingWeather(false);
            showErrorResponse('Get weather failed', error);
        })
    }, [data]);

    const handleUpdateStatus = (params: any) => {
        new Promise(resolve => resolve(updateOperationStatusFetch(params)))
            .then((rs: any) => {
                const {activity_log, operation} = rs;
                updateOperation({data: {...data, operation: {...data.operation, ...operation}, activity_log}});
            })
            .catch((error) => {
                showErrorResponse('Update status failed', error);
            })
        handleClose();
    }


    const handleUpdateActivityLog = (activityLog: any) => {
        setActivity(activityLog)
        updateOperation({data: {...data, activity_log: activityLog}});
    }

    const handleUpdateNote = (note: string) => {
        updateOperation({data: {...data, operation: {...data.operation, note}}, isCheck: false});
    }

    const handleClose = () => {
        setVisible(false);
        if (onClose) {
            onClose();
        }
    }

    return <Modal {...{
        ...propsModal,
        open: visible,
        header: null,
        mask: false,
        wrapClassName: styles['wrap-modal'],
        width: widthPopup,
        styles: {body: {padding: 0}},
        onCancel: handleClose,
    }}>
        <div id='popup-operation-info' style={{padding: '10px 25px 15px 25px'}}>
            <Info
                loading={{weather: loadingWeather}}
                data={{...data, activity_log}}
                isOwn={isOwn}
                isEdit={isEdit && isOwn}
                isApprove={isApprove}
                weather={weather}
                event={{
                    handleUpdateActivityLog,
                    handleUpdateNote,
                    handleChangeState: handleUpdateStatus,
                    onClose: handleClose
                }}
            />
        </div>
    </Modal>;
};

export default PopupOpInfo;
