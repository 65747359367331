import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Checkbox} from "antd";
import stylesContainer from '../../style.module.scss';
import {selectOpDetail} from "../../../../util/store/selectors";
import {WorldMapProvider} from "../../../../contexts/WorldMapContext";
import GoogleMap from "../../../../contexts/WorldMapContext/GoogleMap";
import {SeaWayPolyline} from "../../../../contexts/WorldMapContext/Polyline";
import {FactoryMarker, SiteMarker, VesselMarker} from "../../../../contexts/WorldMapContext/Marker";
import {useMap} from "@vis.gl/react-google-maps";

const Route: React.FC = () => {
    const [isTracking, setIsTracking] = useState(false);
    const data = useSelector(selectOpDetail.data)
    const {route, vessel, operation: {site, factory, source, destination}} = data || {};
    const startPlace = site || source;
    const map = useMap();

    const handleClick = (checked: boolean) => {
        if (checked) {
            const {gps, position} = vessel;
            const {Latitude = position?.latitude || 0, Longitude = position?.longitude || 0} = gps || {};
            map?.setZoom(7)
            map?.setCenter({lat: Latitude, lng: Longitude});
        }
        setIsTracking(checked);
    }

    if (!route)
        return null

    const points = route.points.map(item => {
        return {
            lat: item.latitude,
            lng: item.longitude,
        }
    });

    return <WorldMapProvider>
        <div className={stylesContainer.section}>
            <div className={stylesContainer['header-box']}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="mr-3">Route</span>
                        <div className="font-s16 d-flex align-items-end">
                            {`${route.name}  |  ${route.distance} nm`}
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <Checkbox {...{
                            className: 'font-s2',
                            checked: isTracking,
                            onChange: ({target}) => handleClick(target.checked),
                        }}>
                            Track vessel
                        </Checkbox>
                    </div>
                </div>
            </div>
            <div className='w-full h-[660px]'>
                <GoogleMap
                    center={points}
                    onDragstart={() => setIsTracking(false)}
                >
                    <VesselMarker
                        data={vessel}
                        showPopup={isTracking}
                    />
                    <SeaWayPolyline data={{points}} width={3}/>
                    <SiteMarker data={{...startPlace, position: route.points[0]}}/>
                    {factory && <FactoryMarker data={{...factory, position: route.points[points.length - 1]}}/>}
                    {destination && <SiteMarker data={{...destination, position: route.points[points.length - 1]}}/>}
                </GoogleMap>
            </div>
        </div>
    </WorldMapProvider>;
};

export default Route