import {all, call, put, select, takeEvery} from 'redux-saga/effects'
import {CALENDAR_ID} from "./constants";
import {cloneObj, getKeyWeek, getMondayByKey, jumpToToday, showErrorResponse} from "../../util/varibles/global";
import {
    createOperationsFetch,
    getScheduleOfWeeksFetch2,
    OpService,
    saveOperationsFetch
} from "../../util/services/operation";
import {aDayMillisecond, aWeekMillisecond, VIEW_SMOOTH} from "../../util/varibles/constants";
import {downLevel, generate, IProperty, paramsActivityLog, removeFishChanges, upLevel} from "./util";
import {Storage} from "aws-amplify";
import {updateTime} from "./Plan/Calendar/Operations/constants";
import {selectOneSetting, selectOpMode, selectPlan, selectSetting, selectTenantId,} from "../../util/store/selectors";
import {
    IFactory,
    ILevelByVessel,
    IOperation,
    IOperations,
    IRegion,
    IServiceTask,
    IVessel,
    TOperation
} from "../../util/varibles/interface";
import {CapabilityService} from "../../util/services/capability";
import {notify, NotifyCode} from "../../util/varibles/message";
import {planOpActions} from "./reducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {datetime} from "../../util/library/datetime";
import {PlanSetting, PreferenceSetting} from "../../util/varibles/userSetting";
import {FactoryService} from "../../util/services/factory";
import {SiteService} from "../../util/services/site";
import {generateError} from "./_Component/ErrorBox";

function* initialWatcher(): Generator<any, any, [IFactory[], IRegion[], { vessels: IVessel[] }, IServiceTask[]]> {
    try {
        const [factories, serviceTasks] = yield all([
            call(FactoryService.gets),
            call(CapabilityService.serviceTasks)
        ])

        yield put(planOpActions.initialSuccess({
            factories,
            serviceTasks
        }));

    } catch (error) {
        console.log(error)
    }
}

const initConfirm = {
    true: {
        title: 'Saved operation',
        fetch: saveOperationsFetch,
        content: NotifyCode.S11,
    },
    false: {
        title: 'Sent operation',
        fetch: createOperationsFetch,
        content: NotifyCode.S12
    }
}
const Symbol1 = '_'
const addOp = (payload: any, currentOp: IOperation) => {
    const operation = generate[currentOp.operation.operation_type](currentOp)
    const {vessel} = currentOp;
    const currentVesselIndex = payload.findIndex((item: any) => item.vessel_id === vessel.id);
    let index;
    if (currentVesselIndex !== -1) {
        payload[currentVesselIndex].operations.push(operation);
        index = [currentVesselIndex, payload[currentVesselIndex].operations - 1].join(Symbol1);
    } else {
        payload.push({vessel_id: vessel.id, vessel_owner_id: vessel.tenant_id, operations: [operation]})
        index = [payload.length - 1, 0].join(Symbol1);
    }
    return {rs: payload, opIndex: index};
}

function* confirmOperationsWatcher(args: PayloadAction<{
    operations: IOperation[],
    isSave: boolean
}>): Generator<any, any, any> {
    const {operations, isSave = false} = args.payload;

    const {title, fetch, content} = initConfirm[`${isSave}`];
    let newOps: IOperations = yield select(selectPlan.ops), confirmVessels: any;
    let opIndexes: { [id: string]: IOperation } = {}
    try {
        const opMode = yield select(selectPlan.opMode);
        let files = yield select(selectPlan.files);
        let level: ILevelByVessel = yield select(selectPlan.level);
        let opsDelete = yield select(selectPlan.opsDelete);
        let fishChanges = yield select(selectPlan.fishChanges);
        let delete_operations: any = [];

        confirmVessels = operations.reduce((payload: any, currentOp: IOperation) => {
            const {id, related_id} = currentOp.operation;
            const {[id]: item, ...args} = newOps;
            if (!item)
                return payload;

            newOps = args;
            level = downLevel(level, item, newOps, opMode);

            const {[id]: fileOfOp = [], ...subFiles} = files;
            if (fileOfOp.length > 0) {
                files = {...subFiles};
                fileOfOp.forEach((item: any) => Storage.put(`operations/${item.key}`, item.file, {contentType: item.file.type}).then())
            }

            const {[id]: deleteByOp = [], ...operationsDeleteNew} = opsDelete;
            opsDelete = operationsDeleteNew;
            delete_operations = [...delete_operations, ...deleteByOp.map((item: IOperation) => item.operation.id)]

            if (related_id) {
                Object.keys(args).forEach((key: string) => {
                    if (args[key].operation.related_id === related_id && args[key].action_type) {
                        const {[key]: secondaryOp, ...subArgs} = args;
                        newOps = subArgs
                        level = downLevel(level, secondaryOp, newOps, opMode);
                        const {rs, opIndex} = addOp(payload, secondaryOp);
                        payload = rs;
                        opIndexes[opIndex] = secondaryOp;
                    }
                });
            }
            fishChanges = removeFishChanges(fishChanges, currentOp)
            const {rs, opIndex} = addOp(payload, currentOp)
            opIndexes[opIndex] = currentOp;
            return rs
        }, []);

        const {new_operations, old_operations} = yield call(fetch, {vessels: confirmVessels, delete_operations});

        const vessels: IVessel[] = yield select(selectPlan.vessels);
        yield put(planOpActions.confirmOpSuccess({
            operations: [...new_operations, ...old_operations].reduce((list: IOperations, data: any) => {
                const {id, vessel_id} = data.operation;
                list[id] = {...data, vessel: vessels.find(item => item.id === vessel_id)};
                level = upLevel(level, list[id], list, opMode);
                return list
            }, newOps),
            level,
            operationsDelete: opsDelete,
            files,
            fishChanges
        }));
        const newCode = new_operations.map((item: any) => `#${item.operation.operation_code}`);
        const oldCode = old_operations.map((item: any) => `#${item.operation.operation_code}`);
        notify.success(content)([newCode, oldCode]);
    } catch (error: any) {
        switch (error.response && error.response.status) {
            case 448: {
                error.data.then((rs: any) => {
                    const {overlap_ids = []} = rs || {};
                    const id: string[] = [];
                    const codes = overlap_ids.reduce((list: string [], key: string) => {
                        const {operation_code} = (newOps[key] || {operation: {}}).operation;
                        if (operation_code) {
                            id.push(key);
                            return [...list, `#${operation_code}`]
                        }
                        return list;
                    }, [])
                    if (codes.length > 0)
                        notify.error(NotifyCode.E28, title + ' failure')([codes.join(', ')]);
                    else
                        notify.error(NotifyCode.E27, title + ' failure')();

                    if (notify.length === 0) {
                        const el = document.getElementById(id[0]);
                        if (el)
                            el.scrollIntoView(VIEW_SMOOTH);
                    }
                })
                break;
            }
            default: {
                if (error.data)
                    error.data
                        .then((rs: any) => {
                            const {errors} = rs || {}, message: any[] = [];
                            if (errors) {
                                Object.keys(errors).forEach(key => {
                                    const keys = key.split('.');
                                    if (keys.some((sub: string) => sub === 'total_weight')) {
                                        const key = [keys[1], [keys[3]]].join(Symbol1)
                                        const op = opIndexes[key];
                                        if (op) {
                                            message.push(generateError(op, +keys[5]))
                                        }
                                    }
                                })
                            }
                            if (message.length > 0) {
                                notify.errorRaw(message, title + ` failure (${error.response.status})`)
                            } else
                                notify.error(NotifyCode.E15, title + ` failure (${error.response.status})`)()
                        })
                        .catch(() => {
                            notify.error(NotifyCode.E15, title + ` failure (${error.response.status})`)()
                        })
                else
                    console.log(error)
            }
        }
        yield put(planOpActions.confirmOpFailure());
    }
}

function* lazyLoadingWatcher({...args}): Generator<any, any, any> {
    const {key, action} = args.payload;
    try {
        const oldWeeks = yield select(selectPlan.weeks);
        const beforeCount = yield select(selectPlan.beforeCount);
        const afterCount = yield select(selectPlan.afterCount);

        let isLoad,
            startTime,
            finishTime,
            newWeeks,
            result;
        if (key === 'beforeCount') {
            isLoad = beforeCount;
            const [year, weekNo]: any = oldWeeks[0].split(/\|/);
            finishTime = datetime().set({day: 1, year, week: weekNo}).startOf('day').time;
            startTime = finishTime - (3 * aWeekMillisecond);
            newWeeks = [...oldWeeks];
            for (let i = 1; i <= 3; i++) {
                const keyWeek = getKeyWeek(finishTime - (i * aWeekMillisecond));
                newWeeks = [keyWeek, ...newWeeks]
            }
            result = ({operations, vessels, before_count}: any) => ({operations, vessels, beforeCount: before_count});
        } else {
            isLoad = afterCount;
            const [year, weekNo]: any = oldWeeks[oldWeeks.length - 1].split(/\|/);
            startTime = datetime().set({
                day: 1,
                year,
                week: weekNo
            }).startOf('day').time + aWeekMillisecond;
            finishTime = startTime + (3 * aWeekMillisecond) - 1;
            newWeeks = [...oldWeeks];
            for (let i = 1; i <= 3; i++) {
                const keyWeek = getKeyWeek(startTime + (i * aWeekMillisecond));
                newWeeks = [...newWeeks, keyWeek]
            }
            result = ({operations, vessels, after_count}: any) => ({operations, vessels, afterCount: after_count});
        }

        if (isLoad) {
            const opMode = yield select(selectPlan.opMode);
            yield put(planOpActions.lazyLoadingSuccess({
                result: result(yield call(getScheduleOfWeeksFetch2, startTime, finishTime)),
                startTime,
                finishTime,
                key,
                weeks: newWeeks,
                opMode
            }))
        } else {
            yield put(planOpActions.updateWeeks(newWeeks))
        }
        action();
    } catch (error) {
        yield put(planOpActions.lazyLoadingFailure());
        action();
        console.log(error);
    }
}

function* getSitesWatcher({...args}): Generator<any, any, any> {
    const {abort} = args.payload;
    try {
        const {sites = [], harvests = []} = yield call(SiteService.getForPlan, abort);
        yield put(planOpActions.getSitesSuccess({sites, harvests}));
    } catch (error) {
        console.log(error);
        yield put(planOpActions.getSitesFailure())
    }
}

export interface IGetActivityLog {
    source: {
        operation: TOperation
        vessel: IVessel
    }[]
    properties: {
        [indexOp: string]: IProperty
    }
    signal?: AbortController
    showError?: boolean

    success?(ops: IOperations, options?: any): void

    failure?(error?: any): void
}

function* getActivityLogWatcher(args: PayloadAction<IGetActivityLog>): Generator<any, any, any> {
    try {
        const keySetting = [PreferenceSetting.SITES_TIME, PreferenceSetting.FACTORIES_TIME, PreferenceSetting.AUTO_PLAN];
        const setting = yield select(selectSetting(keySetting));
        const factories = yield select(selectPlan.factories);
        const operations = yield select(selectPlan.ops);
        const level = yield select(selectPlan.level);
        const opMode = yield select(selectOpMode);
        const opsNew: any = {};

        const {source, properties, signal} = args.payload;
        let {start: startTime = Date.now()} = properties[source[0].operation.id || 0] || {};
        let limitTime = Date.now();
        let extended: any = {}
        for (let index = 0, max = source.length; index < max; index++) {
            const item = source[index]
            const {id = index} = item.operation;
            const {operation_type} = item.operation;
            const property = {...properties[id], start: startTime} || {start: startTime};
            const opts = {startTime, setting, property, factories, operations, opMode, level: level[item.vessel.id]}
            const {data, param} = paramsActivityLog[operation_type](item, opts);

            let {
                activity_log,
                router_id,
                prev_op_router_id,
                support_vessels,
                round_map,
                tasks,
                duration,
                sub_operations,
            } = yield call(OpService.activityLog, param, signal);

            extended[id] = {support_vessels, tasks, round_map, duration, sub_operations}
            opsNew[id] = cloneObj(data);
            if (activity_log[0].est_start_time < limitTime) {
                const countDays = Math.ceil((limitTime - activity_log[0].est_start_time) / aDayMillisecond)
                activity_log = updateTime(activity_log, countDays * aDayMillisecond);
                startTime = datetime(startTime).add(countDays, 'day').time;
            } else {
                startTime = datetime(activity_log[activity_log.length - 1].est_start_time)
                    .add(1, 'day').startOf('day').time;
            }

            opsNew[id].activity_log = activity_log;
            opsNew[id].operation.router_id = router_id;
            opsNew[id].operation.prev_op_router_id = prev_op_router_id;

            if (round_map) {
                opsNew[id].operation.round_map = round_map;
            }
            if (sub_operations) {
                opsNew[id].operation.sub_operations = sub_operations;
            }
            if (tasks) {
                opsNew[id].operation.tasks = tasks;
            }

            limitTime = activity_log[activity_log.length - 1].est_start_time;
        }
        args.payload.success && args.payload.success(opsNew, extended);

    } catch (error: any) {
        const {showError = true} = args.payload;
        if (error.name !== 'AbortError' && showError) {
            showErrorResponse('Error', error);
        }
        console.log(error);
        args.payload.failure && args.payload.failure(error);
    }
}

function* getVesselsWatcher(args: PayloadAction<{ abort: AbortController, callback: any }>): Generator<any, any, any> {
    try {
        const {abort, callback} = args.payload;
        const vesselIds = yield select(selectOneSetting(PlanSetting.VESSEL_FILTER));

        const tenantId: string = yield select(selectTenantId);
        const weeks = yield select(selectPlan.weeks);
        const startTime = getMondayByKey(weeks[0]).time;
        const finishTime = getMondayByKey(weeks[weeks.length - 1]).time + aWeekMillisecond - 1;
        setTimeout(() => jumpToToday(CALENDAR_ID), 200);

        const plan = yield  call(getScheduleOfWeeksFetch2, startTime, finishTime, abort);
        const opMode = yield select(selectOpMode);
        const autoConfig = yield select(selectOneSetting(PreferenceSetting.AUTO_PLAN));
        yield put(planOpActions.getVesselsSuccess({plan, tenantId, vesselIds, opMode, autoConfig}));

        if (callback) {
            const {vessels} = plan;
            callback(vessels);
        }

        const canceled: IOperation[] = yield select(selectPlan.opsCanceled);
        if (canceled.length > 0) {
            yield put(planOpActions.getCancelOps(canceled));
        }
    } catch (error) {
        console.log(error);
        yield put(planOpActions.getVesselsFailure());
    }
}

export default function* planOperationSaga() {
    yield all([
        takeEvery(planOpActions.initialRequest.type, initialWatcher),
        takeEvery(planOpActions.confirmOpRequest.type, confirmOperationsWatcher),
        takeEvery(planOpActions.lazyLoadingRequest.type, lazyLoadingWatcher),
        takeEvery(planOpActions.getSitesRequest.type, getSitesWatcher),
        takeEvery(planOpActions.getActivityLog.type, getActivityLogWatcher),
        takeEvery(planOpActions.getVesselsRequest.type, getVesselsWatcher),
    ])
}
