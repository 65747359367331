import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {
    createMethod,
    createSpanEl,
    handleNext,
    options,
    updatePositionCaret,
    updatePositionMenu,
    WEATHER_EL
} from "../../util";
import {getElById, getWidthOfText} from "../../../../util/varibles/global";
import Menu from "./Menu";
import Method from "./Method";
import {renderToString} from "react-dom/server";
import ReactDOM from "react-dom/client";
import {getCondition, getElementByRole} from "../../constants";

let root: any;

export type TAddEl = (role: WEATHER_EL, el: any, unit?: string) => Element | null;

export const toggleMenu = (props?: { addEl: TAddEl, openEditMode?: Function }) => {
    const menuEl = document.getElementById('weather-suggestions');
    if (!menuEl)
        return;

    if (props) {
        const {addEl, openEditMode} = props;
        const el = getElementByRole()
        const role = el?.dataset.role;
        if (role) {
            if (options[role]) {
                updatePositionMenu(el);
                menuEl.dataset.isShow = 'true';
                if (root)
                    root.unmount();
                const {value} = el.dataset;
                const selected = options[role].findIndex((item: any) => item.value === value);
                root = ReactDOM.createRoot(menuEl as HTMLElement);
                root.render(<Menu role={role} selected={selected !== -1 ? selected : 0} addEl={addEl}
                                  openEditMode={openEditMode}/>);
            } else {
                menuEl.dataset.isShow = 'false';
            }
        }
    } else {
        menuEl.dataset.isShow = 'false';
    }
}

const renderMethods = (methods: any) => methods.map((item: any, index: number) => {
    const regexOperator = /[><=]/g;
    switch (item.type) {
        case WEATHER_EL.GROUP: {
            return <Method
                key={WEATHER_EL.GROUP + index}
                role={WEATHER_EL.GROUP}
                label={item.value}
            />
        }
        case WEATHER_EL.PARAM: {
            let match;
            const indexes: any = [];
            while ((match = regexOperator.exec(item.value)) !== null) {
                if (indexes.length > 0 && indexes[indexes.length - 1].index === (match.index - 1))
                    indexes[indexes.length - 1].length = 2;
                else
                    indexes.push({index: match.index, length: regexOperator.lastIndex - match.index})
            }
            if (indexes.length === 0) {
                return <Method
                    key={WEATHER_EL.PARAM + index}
                    role={WEATHER_EL.PARAM}
                    label={item.value}
                    isInvalid={true}
                />
            }

            const start = indexes[0].index;
            const end = indexes[indexes.length - 1].index + indexes[indexes.length - 1].length;
            const paramStr = item.value.substring(0, start).trim();
            const operatorStr = item.value.substring(start, end).trim();
            const valueStr = item.value.substring(end).trim();
            const param = options.param.find(obj => obj.value === paramStr);
            const operator = options.operator.find(obj => obj.value === operatorStr)
            let styleValue = {}

            if (param)
                styleValue = {paddingRight: getWidthOfText(param.unit) + 'px'}
            return [
                <Method
                    key={WEATHER_EL.PARAM + index}
                    role={WEATHER_EL.PARAM}
                    value={param ? param.value : '-'}
                    label={param ? param.label : paramStr}
                />,
                <Method
                    key={WEATHER_EL.OPERATOR + index}
                    role={WEATHER_EL.OPERATOR}
                    value={operator ? operator.value : '-'}
                    label={operator ? operator.label : operatorStr}
                />,
                <Method
                    key={WEATHER_EL.VALUE + index}
                    role={WEATHER_EL.VALUE}
                    value={valueStr}
                    label={valueStr}
                    style={styleValue}
                    unit={param ? param.unit : ''}
                />
            ]
        }
        case WEATHER_EL.RELATION: {
            return <Method
                key={WEATHER_EL.RELATION + index}
                role={WEATHER_EL.RELATION}
                label={item.value}
                value={item.value}
            />
        }
        default:
            return null;
    }
})

interface IProps {
    id: string
    value: string
    editing?: boolean
    placeHolder: string

    openEditMode?(): void
}

const InputCondition: React.FC<IProps> = (props) => {
    const {id, value, editing, openEditMode} = props;
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const el = getElById(id)
        if (el && ((edit && !editing) || (!edit && !editing))) {
            el.innerHTML = renderToString(renderMethods(createMethod(value)))
        }
        setEdit(!!editing)
    }, [id, editing, value, edit]);

    const addEl: TAddEl = (role, el, unit) => {
        const inputEl = getElById(id);
        if (!inputEl)
            return null;
        let newEl
        if (role === WEATHER_EL.VALUE && !unit) {
            const {childNodes} = inputEl as any;
            const index = Array.prototype.indexOf.call(childNodes, el);
            let paramId = '';
            for (let i = index; i >= 0; i--) {
                const {role, value} = childNodes[i]?.dataset;
                if (role === WEATHER_EL.PARAM) {
                    paramId = value;
                    break;
                }
            }
            const param = options.param.find(item => item.value === paramId);
            newEl = createSpanEl(role, param?.unit);
        } else
            newEl = createSpanEl(role, unit);

        newEl.textContent = ' ';
        inputEl?.insertBefore(newEl, el.nextSibling);
        return newEl;
    }

    const handleMouseDown = () => {
        const el = getElementByRole();
        const role = el?.dataset.role;
        if (role === WEATHER_EL.CONTAINER) {
            const newEl = addEl(WEATHER_EL.PARAM, el);
            updatePositionCaret(newEl, 1)
        } else if (role === WEATHER_EL.VALUE) {
            if (!el.nextSibling) {
                const newEl = addEl(WEATHER_EL.RELATION, el);
                updatePositionCaret(newEl, 1)
            }
        }
        setTimeout(() => toggleMenu({addEl, openEditMode}), 10)
    }

    const handleInput = () => {
        const inputEl = getElById(id);
        if (!inputEl)
            return;
        const {textContent, childNodes} = inputEl || {};
        if ((textContent || '').length === 0) {
            inputEl.innerHTML = '';
        } else {
            (childNodes || []).forEach((item: any) => {
                if (item.textContent.length === 0)
                    item.remove()
            })
        }
        if (!editing) {
            const {condition} = getCondition(childNodes);
            setTimeout(() => {
                inputEl.innerHTML = renderToString(renderMethods(createMethod(condition)))
            }, 1)
        }
        if (openEditMode)
            openEditMode();
    }

    const handleKeyDown = (e: any) => {
        let keyCaught = false;
        switch (e.key) {
            case ' ': {
                const el = getElementByRole();
                const role = el?.dataset.role;
                if (role === WEATHER_EL.VALUE) {
                    keyCaught = true;
                    handleNext[role](el, {addEl})
                    toggleMenu({addEl})
                }
                break;
            }
            case 'ArrowLeft':
            case 'ArrowRight': {
                toggleMenu({addEl})
                break;
            }
            case 'Escape':
                keyCaught = true;
                toggleMenu();
                break;
        }

        if (keyCaught) {
            e.stopPropagation()
            e.preventDefault();
        }
    }

    const handleKeyUp = (e: any) => {
        switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowRight': {
                toggleMenu({addEl, openEditMode});
                break;
            }
        }
    }

    const handleFocus = () => {
        const inputEl = getElById(id);
        if (inputEl?.textContent?.length === 0)
            updatePositionCaret(inputEl, 0)
    }


    const handleBlur = () => {
        const inputEl = getElById(id);
        if (!inputEl)
            return;
        const {childNodes} = inputEl;
        const newChild: Node[] = [];
        childNodes.forEach((item: any) => {
            const {textContent} = item;
            if (textContent.trim().length > 0)
                newChild.push(item);
        })
        inputEl.replaceChildren(...newChild);
        toggleMenu();
    }

    return <div className={styles.container}>
        <div
            {...{
                id,
                className: styles.input,
                contentEditable: true,
                suppressContentEditableWarning: true,
                onInput: handleInput,
                onKeyDown: handleKeyDown,
                onKeyUp: handleKeyUp,
                onClick: handleMouseDown,
                onFocus: handleFocus,
                onBlur: handleBlur,
            }}
            data-role={WEATHER_EL.CONTAINER}
        >
        </div>
    </div>;
};

export default InputCondition;

