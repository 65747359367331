import React, {Component} from 'react';
import styles from "./style.module.scss";
import {descriptionDeliveryType, formatNumber} from "../../../../../../util/varibles/global";
import {IUnit, TOperation} from "../../../../../../util/varibles/interface";
import UnitLice from "../../../../../Operation/UnitLice";

interface IProps {
    operation: TOperation
    isDeliveryType: boolean
}

class HarvestTable extends Component<IProps> {
    static defaultProps = {
        isDeliveryType: true
    }

    render() {
        const {isDeliveryType} = this.props;
        const {sub_operations, site_name, sites = [], site_id, diseases = []} = this.props.operation;
        const list = sites.length === 0 ? [{id: site_id, name: site_name}] : sites;

        return <div className={styles['info-harvests']} data-is-delivery-type={isDeliveryType}>
            <div className={styles.harvest} data-row-type={'header'} key='header'>
                <div>Site, Unit</div>
                <div>Harvest ID</div>
                <div>AVG weight</div>
                <div>Amount</div>
                <div>Total weight</div>
                <div>Mortality risk</div>
                <div>Lice</div>
                {isDeliveryType && <div>Delivery type</div>}
            </div>
            {list.map((site: any, i: number) => {
                const {lice: siteLice} = diseases.find(item => item.site_id === site.id) || {};
                return [
                    <div className={styles.harvest} data-row-type='site' key={i}>
                        {site.name}
                    </div>,
                    ...sub_operations.reduce((list: any, item: IUnit) => item.site_id === site.id ? [
                        ...list, <div className={styles.harvest} key={item.id}>
                            <div>{item.unit_id}</div>
                            <div>{item.harvest_id || '-'}</div>
                            <div>
                                {item.avg_weight > 0 ? `${formatNumber(Math.round(item.avg_weight) / 1000)} kg` : '-'}
                            </div>
                            <div>
                                {item.fish_amount > 0 ? `${formatNumber(item.fish_amount)}` : '-'}
                            </div>
                            <div>{formatNumber(Math.round(item.total_weight / 1000) / 1000)} t</div>
                            <div>{item.mortality_risk ? 'High' : 'Low'}</div>
                            {(item.lice || siteLice) ? <UnitLice lice={item.lice || siteLice}/> : <div/>}
                            {isDeliveryType && <div className={styles.delivery_types}>
                                {(item.delivery_types || []).map((subItem: any) =>
                                    <div key={subItem.id} className={styles.delivery_type}>
                                        {descriptionDeliveryType(subItem)}
                                    </div>)}
                            </div>}
                        </div>
                    ] : list, [])
                ]
            })}
        </div>;
    }
}

export default HarvestTable;
