import React from 'react';
import {Empty} from "antd";

interface IProps {
}

const NoData: React.FC<IProps> = () => {
    return <div className='mt-20'>
        <Empty/>
    </div>;
};

export default NoData;