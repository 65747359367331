import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty, onChangeRequired} from "./constants";
import styles from "./style.module.scss";

interface IProps extends IPropsPropertyElement {

}

class LongTextElement extends Component<IProps> {
    render() {
        const {selected} = this.props;
        if (!selected) return null;

        const {label, properties: {required, placeHolder, subLabel, numberOfRows}} = selected;
        return <>
            <div className={styles['title']}>Long text field Properties</div>
            <div className={styles['property-element']}>
                <label>Label</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
                <span>Type a text field label.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Required</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('required')}>
                        <input id='required' type="checkbox" checked={required}
                               onChange={() => onChangeProperty({required: !required}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Size</label>
                <div className={styles.rows}>
                    <input type="number" value={numberOfRows} min='2'
                           onChange={(e) => onChangeProperty({numberOfRows: e.currentTarget.value}, this.props)}
                    />
                    <span>Rows</span>
                </div>
                <span>Textarea height in rows.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Placeholder</label>
                <input type="text" value={placeHolder}
                       onChange={(e) => onChangeProperty({placeHolder: e.currentTarget.value}, this.props)}
                />
                <span>Add an example hint inside the field.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Sub label</label>
                <input type="text" value={subLabel}
                       onChange={(e) => onChangeProperty({subLabel: e.currentTarget.value}, this.props)}
                />
                <span>Add a small description below the input field.</span>
            </div>
        </>;
    }
}

export default LongTextElement;
