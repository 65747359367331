import React from 'react';
import styles from "./style.module.scss";
import {IOperation} from "../../../../../../util/varibles/interface";

const Service: React.FC<IOperation> = (props) => {
    const {name = ''} = props.vessel || {};
    const {operation} = props;

    const {site, sub_operations} = operation;

    const units = sub_operations.map((item: any) => item.unit_id).join(', ');
    const location = [site.name, units].join(' - ');
    const voyage = name + location;

    return <>
        {voyage.length <= 38 ? <div className={[styles['line-info'], 'justify-content-start'].join(' ')}>
            <div>{name}</div>
            <div className={styles['divider-custom']}/>
            {location}
        </div> : <div className={styles['line-info']}>
            <div>
                <div className='d-flex align-items-center text-truncate'>{name}</div>
                <div className='d-flex align-items-center text-truncate'>{location}</div>
            </div>
        </div>}
    </>;
};

export default Service;
