import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectRegion} from "../../../../util/store/region/constants";
import styles from "../../Control/LeftControl/style.module.scss";

interface IProps {
    regionId: string
}

const Region: React.FC<IProps> = ({regionId}) => {
    const regions = useSelector(selectRegion.regions);
    const [name, setName] = useState('-');
    useEffect(() => {
        const {name = '-'} = regions.find(item => item.id === regionId) || {};
        setName(name);
    }, [regions, regionId]);


    return <div className={styles['line-info']}>
        <span>Region:</span>
        <span>{name}</span>
    </div>;
};

export default Region;
