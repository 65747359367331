import React, {useCallback, useEffect} from 'react';
import GoogleMap from '../../../../util/library/googlemap/googlemap';
import {ILatLng, IRoute} from "../../../../util/varibles/interface";
import {connect, useDispatch} from "react-redux";
import {RouteMode} from "../../constatns";
import {clearElements, MAP_ELEMENT, markerIcon} from "../../../../util/library/googlemap/constants";
import {createPolyline} from "../../../../util/varibles/global";
import {routeActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    map: state.route.map,
    mode: state.route.mode,
    showId: state.route.showId,
    routes: state.route.routes,
    route: state.route.route,
    params: state.route.params
})

interface IProps {
    map?: GoogleMap
    mode: RouteMode
    routes: IRoute[]
    route: any
    showId: string
    params: {
        source: any
        destination: any
    }
}

const Map: React.FC<IProps> = (props) => {
    const {map, mode, routes, route, showId, params} = props;
    const dispatch = useDispatch()

    const handleAddMarker = useCallback((marker: ILatLng, position?: number) => {
        const points = [...route.points || []];
        points.splice(position || points.length - 1, position ? 1 : 0, marker);
        dispatch(routeActions.editRoute({...route, points}))
    }, [route, dispatch])

    useEffect(() => {
        if (map) {
            map.addListener('rightclick', (e: any) => {
                if (mode !== RouteMode.EDIT) return;
                const marker = {lat: e.latLng.lat(), lng: e.latLng.lng()};
                handleAddMarker(marker);
            })
        }
    }, [map, mode, handleAddMarker]);


    useEffect(() => {
        if (mode === RouteMode.EDIT) {
            map?.clearSeaways();
            const list = map?.getElement(MAP_ELEMENT.POINT) || {};
            Object.keys(list).forEach(key => {
                if (key !== route.id)
                    clearElements(list[key] || []);
            });

            map?.generate({
                type: MAP_ELEMENT.POLYLINE,
                seaWays: [route],
                event: {
                    'rightclick': (data: any, e: any) => {
                        if (mode !== RouteMode.EDIT) return;
                        const {seaWay} = map?.getElement(MAP_ELEMENT.POLYLINE, route.id) || {};
                        if (!seaWay)
                            return;

                        const {latLng} = e;
                        const path = seaWay.getPath().getArray();
                        const position = {minDistance: 9999999999, index: -1};
                        for (let i = 0, len = path.length - 1; i < len; i++) {
                            const dist = google.maps.geometry.spherical.computeDistanceBetween(path[i], latLng);
                            const dist1 = google.maps.geometry.spherical.computeDistanceBetween(path[i + 1], latLng);
                            const dist2 = google.maps.geometry.spherical.computeDistanceBetween(path[i], path[i + 1]);
                            const minDistance = dist + dist1 - dist2;
                            if (minDistance < position.minDistance) {
                                position.minDistance = minDistance;
                                position.index = i + 1;
                            }
                        }
                        const marker = {lat: latLng.lat(), lng: latLng.lng()};
                        handleAddMarker(marker, position.index);
                    }
                }
            });
            let points = [...route.points];
            points = points.slice(1);
            points.pop();
            map?.generate({
                type: MAP_ELEMENT.POINT,
                ...route,
                markers: points,
                isEdit: true,
                event: {
                    dragstart: (args) => {
                        const {index} = args;
                        const markers = map?.getElement(MAP_ELEMENT.POINT, route.id);
                        const {getIcon} = markerIcon[MAP_ELEMENT.POINT];
                        markers[index].setIcon(getIcon({color: '#' + route.color, size: 9}))
                    },
                    dragend: (args, e) => {
                        const {index} = args;
                        const {seaWay} = map?.getElement(MAP_ELEMENT.POLYLINE, route.id) || {};
                        if (!seaWay)
                            return;
                        const markers = map?.getElement(MAP_ELEMENT.POINT, route.id);
                        const {getIcon} = markerIcon[MAP_ELEMENT.POINT];
                        markers[index].setIcon(getIcon({color: '#' + route.color, size: 8}))
                        let lat = e.latLng.lat();
                        let lng = e.latLng.lng();
                        dispatch(routeActions.editRoute({
                            ...route,
                            points: route.points.map((item: any, i: number) => {
                                if (i === index + 1)
                                    item = {name: route.points[index + 1].name, lat, lng}
                                return item;
                            })
                        }));
                        let path = seaWay.getPath().getArray();
                        path[index + 1] = new google.maps.LatLng(lat, lng);
                        seaWay.setPath(path);
                    }
                }
            })
        }
    }, [map, mode, route, dispatch, handleAddMarker]);

    useEffect(() => {
        const index = routes.findIndex(item => item.id === showId);
        if (index !== -1) {
            const seaWay = createPolyline(routes[index], index, 3);
            const {id, color} = seaWay;
            const list = map?.getElement(MAP_ELEMENT.POINT) || {};
            Object.keys(list).forEach(key => {
                clearElements(list[key] || []);
            });
            map?.generate({type: MAP_ELEMENT.POLYLINE, seaWays: [seaWay]});
            map?.generate({type: MAP_ELEMENT.POINT, markers: seaWay.points, id, color})
        }
    }, [map, routes, showId]);

    useEffect(() => {
        const {source, destination} = params;
        const center: number[][] = [];
        const sites: any[] = [];
        const factories: any[] = [];
        map?.clearSeaways();
        const list = map?.getElement(MAP_ELEMENT.POINT) || {};
        Object.keys(list).map(key => clearElements(list[key] || []));

        [source, destination].forEach(item => {
            if (item) {
                const {position: {latitude: lat, longitude: lng}, field} = item;
                center.push([lng, lat]);
                const marker = {...item, lat, lng}
                if (field === 'factory') {
                    factories.push(marker);
                } else {
                    sites.push(marker);
                }
            }
        });
        map?.generate({type: MAP_ELEMENT.FACTORY, markers: factories});
        map?.generate({type: MAP_ELEMENT.SITE, markers: sites});
        if (center.length > 0)
            map?.setCenter(center, 8);

    }, [map, params]);

    return <div className='map'>
        <div id='map'/>
    </div>
};

export default connect(mapStateToProps, {
    editRoute: routeActions.editRoute
})(Map);
