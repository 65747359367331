import React from 'react';
import {OPERATION_TYPE} from "../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {Table} from "antd";
import {connect} from "react-redux";
import stylesParent from "../style.module.scss";
import Operation from "./Operations/Operation";
import Position from "../../Component/Position";
import LoadingBox from "../../../../components/LoadingBox";
import {AppState} from "../../../../util/store/store";
import Weather from "../../Component/Weather";
import {convertNumberTimeToStringTime} from "../../../../util/varibles/global";
import Phone from "../../Component/Phone";

const mapStateToProps = (state: AppState) => ({
    operations: state.dashboard.operations,
});

interface IProps {
    data: any
    operations: any
}

const Factory: React.FC<IProps> = ({data, operations}) => {
    const {name, contact, position, deliver_type, work_time, id} = data;

    const columnDeliverType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Speed',
            dataIndex: 'speed',
            key: 'speed',
            render: (speed: number = 0) =>
                <span>{speed === 0 ? '-' : `${Math.round(speed / 1000) / 1000} tons/h`}</span>
        }
    ]

    const listDeliverType = Object.keys(deliver_type)
        .reduce((list: any, key) => [...list, deliver_type[key]], [])
        .sort((a: any, b: any) => a.name.localeCompare(b.name))

    const listOfOperation = operations.reduce((list: any, item: any) => {
        const {operation_type} = item.operation;
        switch (operation_type) {
            case OPERATION_TYPE.HARVEST: {
                const {id: factory_id = ''} = item.operation.factory || {};
                if (factory_id === id)
                    return [...list, item]
                return list;
            }
            default:
                return list
        }
    }, [])

    return <>
        <div className={stylesParent.header}>
            <div>{name}</div>
        </div>
        <div className={stylesParent.body}>
            <div className={styles['container-info']}>
                <Phone phone={contact}/>
                <div className={styles['line-info']}>
                    <span>Work time:</span>
                    <span>{convertNumberTimeToStringTime(work_time * 60000)}</span>
                </div>
                <Position {...{lat: position.latitude, lng: position.longitude}}/>
            </div>
            <div className={styles['container-weather']}>
                <div className={styles.title}>
                    <div>Deliver info.</div>
                </div>
                <div className={styles.body}>
                    <Table
                        rowKey='id'
                        bordered
                        pagination={false}
                        className={styles['table-deliver-type']}
                        columns={columnDeliverType}
                        dataSource={listDeliverType}
                    />
                </div>
            </div>
            <div className={styles['container-weather']}>
                <Weather name={name}/>
            </div>
            <div className={[styles['container-weather'], 'pb-0'].join(' ')}>
                <div className={styles.title}>
                    <div>Incoming operation. ({listOfOperation.length})</div>
                </div>
                {listOfOperation.length === 0 && <LoadingBox message="There is no ongoing operation today."/>}
                {listOfOperation.map((data: any, index: any) => <Operation key={index} {...{data}}/>)}
            </div>
        </div>
    </>;
};

export default connect(mapStateToProps, {})(Factory);
