import {useDispatch, useSelector} from 'react-redux';
import React, {useCallback, useEffect, useState} from "react";
import styles from './style.module.scss';
import {CONVERSATION_MODE} from "../../../../../pages/MessagePage/constants";
import {useResizeDetector} from "react-resize-detector";
import Conversation from "./Conversation";
import ConversationAdd from "./ConversationAdd";
import {createChatFetch, sendMessageFetch} from "../../../../../util/services/chat";
import {showErrorResponse} from "../../../../../util/varibles/global";
import {CHAT_TYPE} from "../../../../../util/varibles/constants";
import {selectMessage, selectUserId, selectUsername} from "../../../../../util/store/selectors";
import {messageActions} from "../../../../../pages/MessagePage/reducer";

interface IProps {
    callAdd: any
}

let addRef: any = React.createRef();
const Conversations: React.FC<IProps> = (props) => {
    const userId = useSelector(selectUserId);
    const username = useSelector(selectUsername);
    const conversations = useSelector(selectMessage.conversations);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        props.callAdd(newConversation);
        return () => {
            props.callAdd(undefined)
        }
    }, []);

    const newConversation = (chatType: CHAT_TYPE) => {
        setOpen(true)
        setTimeout(() => {
            if (addRef)
                addRef.open(chatType);
        }, 10)
    }

    const handleAddConversation = (params: any) => {
        const {chatType, values, ...args} = params;
        if (chatType === CHAT_TYPE.USER) {
            const [name] = username.split('@');
            setLoading(true)
            new Promise((resolve) => {
                resolve(createChatFetch({
                    ...params,
                    name: [name, ...values.map((item: any) => item.name.split('@')[0])].join(', '),
                    users: [...values.map((item: any) => item.id), userId],
                    members: [...values.map((item: any) => ({id: item.id, username: item.name})), {
                        id: userId,
                        username
                    }],
                    type: CHAT_TYPE.USER
                }))
            })
                .then((chat: any) => {
                    const {id} = chat.group_chat;
                    dispatch(messageActions.addChat(chat));
                    handleAddSuccess(chat, id);
                })
                .catch(handleAddFailure)
        } else {
            const {id} = values[0];
            new Promise((resolve) => {
                resolve(sendMessageFetch({
                    ...args,
                    type: CHAT_TYPE.OPERATION,
                    group_id: id
                }))
            })
                .then((chat: any) => {
                    dispatch(messageActions.addChatOfOp(chat));
                    handleAddSuccess(chat, id);
                })
                .catch(handleAddFailure)
        }
    }


    const handleAddSuccess = (chat: any, id: string) => {
        dispatch(messageActions.updateConversations({conversation: chat, mode: CONVERSATION_MODE.OPEN, isTop: true}));
        setOpen(false)
        setTimeout(() => {
            const el = document.getElementById('input-' + id);
            if (el)
                el.focus()
        }, 100)
    }

    const handleAddFailure = async (error: any) => {
        setLoading(false)
        showErrorResponse('Create chat failed', error);
    }

    const handleResize = useCallback(() => {
    }, [])

    const {ref} = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 1000,
        onResize: () => handleResize
    });

    return <div ref={ref} className={styles.wrapper}>
        {open && <ConversationAdd
            childRef={(ref: any) => addRef = ref}
            onClose={() => setOpen(false)}
            onAdd={handleAddConversation}
        />}
        {conversations.map((item: any) => <Conversation key={item.id} {...item} />)}
    </div>
};

export default Conversations;
