import React from 'react';
import {IIdName} from "../../../../../../util/varibles/interface";
import {Checkbox} from "antd";
import styles from './style.module.scss';

interface IProps {
    factories: IIdName[]
    factoryFilter: string[]
    search: string

    updateSetting(ids: string[]): void
}


const Factories: React.FC<IProps> = ({factories, factoryFilter, search, updateSetting}) => {
    const ids = new Set(factoryFilter);
    const handleChange = (e: any, id: string) => {
        const {checked} = e.target;
        if (checked) {
            updateSetting(Array.from(new Set([...factoryFilter, id])))
        } else {
            ids.delete(id);
            updateSetting(Array.from(ids))
        }
    }

    return <div className={styles.wrapper}>
        <div className={styles.name}>
            <label>Factories</label>
        </div>
        <div className={styles.list}>
            {factories.map(sub => {
                const {id: subId, name: subName} = sub;

                if (search.length && subName.toLowerCase().indexOf(search) === -1)
                    return null

                return <Checkbox
                    key={subId}
                    className={styles.item}
                    checked={ids.has(subId)}
                    onChange={(e) => handleChange(e, subId)}
                >
                    {subName}
                </Checkbox>
            })}
        </div>
    </div>
};

export default Factories;

