import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import UnitView from "./UnitView";
import PointChange from "./PointChange";
import {checkLimit, compareInCircle, addClassName, removeClassName} from "../../../../util/varibles/global";

const condition = (el: any, pageX: number, pageY: number) => {
    const {x, y, width} = el.getBoundingClientRect();
    return compareInCircle({
        o: {x: x + (width / 2), y: y + 8},
        point: {x: pageX, y: pageY},
        radius: 20
    })
}

interface IProps {
    id: string
    name: string
    index: number
    isLock?: boolean
    isOngoing?: boolean
    isSelect?: boolean

    onSelect(): void

    changeStep(step: number): void

    onOrderUnits(id: string, index: number): void
}

let tooltip: any, dragging = false;

const Unit: React.FC<IProps> = (props) => {
        const {id, name, isLock, isOngoing, isSelect, onSelect, onOrderUnits} = props;
        const root: any = useRef(null);
        const [isDrag, setIsDrag] = useState(false);

        const handleMouseMove = useCallback((e: any) => {
            if (!dragging) {
                dragging = true;
                addClassName(document.body);
                root.current.parentElement.dataset.dragging = 'true';
                setIsDrag(true);
            }

            const pageX = checkLimit(40, window.innerWidth - 40, e.pageX);
            const pageY = checkLimit(0, window.innerHeight - 40, e.pageY - 12);
            tooltip.style.left = pageX + 'px';
            tooltip.style.top = pageY + 'px';

            let flag = false;
            const children = root.current.parentElement.getElementsByClassName(styles.pointChange);
            Array.from(children).forEach((item: any) => {
                const value = condition(item, pageX, pageY);
                if (!flag && value) {
                    flag = true;
                    item.dataset.actived = 'true'
                    const {x, y} = item.getBoundingClientRect();
                    tooltip.style.left = (x + 11) + 'px';
                    tooltip.style.top = (y - 5) + 'px';
                } else
                    delete item.dataset.actived;
            });

        }, []);

        const handleMouseUp = useCallback((e: any) => {

            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            removeClassName(document.body);
            if (tooltip)
                tooltip.remove();

            const pageX = checkLimit(40, window.innerWidth - 40, e.pageX);
            const pageY = checkLimit(0, window.innerHeight - 40, e.pageY - 10);
            const children = root.current.parentElement.getElementsByClassName(styles.pointChange);
            const el: any = Array.from(children).find((item: any) => condition(item, pageX, pageY));

            root.current.parentElement.dataset.dragging = 'false';
            setIsDrag(false);
            if (el) {
                const {index} = el.dataset;
                onOrderUnits(id, Number(index));
            }
        }, [id, onOrderUnits, handleMouseMove]);

        const handleMouseDown = useCallback((e: any) => {
            onSelect();
            dragging = false;

            if (isLock)
                return;

            tooltip = document.createElement('div');
            tooltip.className = styles.unitDragging;
            tooltip.innerHTML = name;
            tooltip.style.top = (e.pageY - 12) + 'px';
            tooltip.style.left = e.pageX + 'px';
            document.body.appendChild(tooltip);
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }, [name, isLock, onSelect, handleMouseMove, handleMouseUp]);

        useEffect(() => {
            return () => {
                setIsDrag(false);
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
                if (tooltip)
                    tooltip.remove();
            };
        }, [handleMouseMove, handleMouseUp]);

        return <div
            id={id}
            ref={root}
            className={styles.unit}
            data-ongoing={isOngoing}
            data-dragging={isDrag}
            onMouseDown={handleMouseDown}
        >
            <UnitView
                name={name}
                isLock={isLock}
                isOngoing={isOngoing}
                isSelect={isSelect}
            />
            {!isDrag && <PointChange index={props.index}/>}
        </div>;
    }
;

export default Unit;
