import React, {Component} from 'react';
import {connect} from 'react-redux';
import {MessageState, tabMessage} from "../../constants";
import stylesContainer from '../../style.module.scss';
import styles from './style.module.scss';
import {Modal, notification, Spin} from "antd";
import MessageBox from "../MessageBox";
import {
    addUsersToChatFetch,
    closeConversationFetch,
    createChatFetch,
    removeUsersFromChatFetch
} from "../../../../util/services/chat";
import {ExportOutlined, LogoutOutlined, TeamOutlined, UserDeleteOutlined} from "@ant-design/icons";
import PopupAddUser from "../PopupAddUser";
import {getTitleChatUser, userColors} from "../../../../components/Chat/constants";
import Message from "../../../../components/Chat/MessageMini/Component/Message";
import {checkLoadMore, showErrorResponse} from "../../../../util/varibles/global";
import WSService from "../../../../web-socket/web-socket-service";
import Menu from "../../../../components/Menu";
import {messageActions} from "../../reducer";
import {CHAT_TYPE} from "../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    user_id: state.login.user.user_id,
    username: state.login.user.username,
    tenant_name: state.login.user.tenant_name,
    message: state.message
})

interface IProps {
    user_id: string
    username: string
    tenant_name: string
    message: MessageState
    chatId: string

    clearId(): void

    addChat(payload: any): void

    sendMessage(payload: any): void

    getMessages(payload: any): void

    getChat(payload: any): void

    leaveChat(payload: { chatType: CHAT_TYPE, chatId: string }): void

    addUser(payload: any): void

    kickUser(payload: any): void

    deleteConversation(chatId: string): void
}

class Chat extends Component<IProps> {
    state = {
        isNew: false,
        isShowNew: false,
        selected: null,
        values: [],
        loading: false
    }

    rootRef: any = React.createRef();
    popupAddRef: any = React.createRef();
    popupKickRef: any = React.createRef();

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        const {
            chat: dataNew,
            chatMore,
            loadingChat
        } = this.props.message;
        const {chat: dataOld} = prevProps.message;

        if (chatMore && !loadingChat && JSON.stringify(dataNew) !== JSON.stringify(dataOld)) {
            setTimeout(() => {
                checkLoadMore(this.rootRef.current, () => {
                    const {chatIndex} = this.props.message;
                    this.props.getChat({index: chatIndex + 1})
                })
            }, 100)
        }
    }

    showMessages = (isSelected: boolean, data: any) => {
        if (isSelected)
            return;

        if (this.props.chatId.length > 0)
            this.props.clearId();

        const {messages} = this.props.message;
        const {id} = data.group_chat;
        if (messages[id]) {
            this.setState({isNew: false, selected: id})
        } else {
            this.setState({isNew: false, selected: id})
            this.props.getMessages({id, index: 1});
        }
    }

    newChat = () => {
        if (!this.state.isNew)
            this.setState({isNew: true, values: [], isShowNew: true})
    }

    handleSend = (params: any, target: any) => {
        const {isNew} = this.state;
        if (isNew) {
            const {values} = this.state;
            if (values.length === 0) {
                notification.error({message: 'Send failed', description: ''})
            } else {
                const {user_id, username} = this.props;
                const [currentName] = username.split('@');
                this.setState({loading: true});
                const {name, users, members} = values.reduce((rs: any, item: any) => {
                    rs.name.push(item.name.split('@')[0]);
                    rs.users.push(item.id);
                    rs.members.push({id: item.id, username: item.name})
                    return rs;
                }, {name: [currentName], users: [user_id], members: [{id: user_id, username}]})
                new Promise((resolve) => {
                    resolve(createChatFetch({
                        ...params,
                        name: name.join(', '),
                        users,
                        members,
                        type: CHAT_TYPE.USER
                    }))
                })
                    .then((chat: any) => {
                        this.props.addChat(chat);
                        this.setState({selected: chat.group_chat.id, isNew: false, isShowNew: false, loading: false});
                    })
                    .catch(async error => {
                        this.setState({loading: false})
                        showErrorResponse('Create chat failed', error);
                    })
            }
        } else {
            const {group_chat = {id: ''}} = target || {}
            const {id = ''} = group_chat || {};
            this.props.sendMessage({...params, type: CHAT_TYPE.USER, group_id: id})
        }
    }

    handleWheel = (e: any) => {
        const {chatMore, loadingChat, chatIndex} = this.props.message;
        if (!chatMore || loadingChat)
            return;

        const {deltaY} = e;
        const {scrollTop, scrollHeight, offsetHeight}: any = this.rootRef.current;
        const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;

        if (chatMore && isScrolledToBottom && deltaY > 0) {
            this.props.getChat({index: chatIndex + 1});
        }
    }

    menu = (target: any): any => {
        const {user_id} = this.props;
        const {host_id} = target.group_chat;
        const isAdmin = host_id === user_id;
        const members = [...target.group_chat.members || []];
        members.sort((a: any, b: any) => a.username.localeCompare(b.username));

        const colors = members.reduce((rs: any, item: any, index: number) => {
            rs[item.username] = userColors[index % 10];
            return rs;
        }, {})

        const items = [
            ...members.map((item: any) => {
                const {username = ''} = item;
                const {background} = colors[username] || userColors[0];
                return {
                    key: item.id,
                    label: <div className='menu-line' data-view='user'>
                        <div className={styles['menu-avatar']} style={{background}}>{username[0]}</div>
                        <div className={styles['menu-member']}>
                            <div>
                                {username.split('@')[0]}
                            </div>
                            <div data-lev='action'>
                                {(isAdmin && item.id !== user_id) && <div
                                    title='Remove member'
                                    className={styles['icon-remove-member']}
                                    onClick={() => this.handleKickUsers([item])}
                                >
                                    <UserDeleteOutlined/>
                                </div>}
                            </div>
                        </div>
                    </div>
                }
            }),
            {
                type: 'divider'
            }
        ]

        if (isAdmin) {
            items.push({
                key: 'add-user',
                label: <div
                    className='menu-line'
                    onClick={() => {
                        if (this.popupAddRef)
                            this.popupAddRef.open()
                    }}
                >
                    <Icon className='ml-5' icon={ICON_NAME.ADD_USER}/>
                    <div className='ml-10'>Add people</div>
                </div>
            })
            items.push({
                key: 'leave',
                label: <div className='menu-line' data-danger={true} onClick={this.handleCloseConversation}>
                    <div className={styles.icon}>
                        <ExportOutlined/>
                    </div>
                    <div className='ml-10'>Close Conversation</div>
                </div>
            })
        } else {
            items.push({
                key: 'leave',
                label: <div className='menu-line' data-danger={true} onClick={() => this.handleKickUsers([])}>
                    <div className={styles['icon-leave']}>
                        <LogoutOutlined/>
                    </div>
                    <div className='ml-10'>Leave</div>
                </div>
            })
        }
        return items
    }

    handleAddUsers = (list: any) => {
        const {selected}: any = this.state;
        if (!selected)
            return;

        const {users, members} = list.reduce((rs: any, item: any) => {
            rs.users.push(item.id);
            rs.members.push(item)
            return rs;
        }, {users: [], members: []});
        this.popupAddRef.setState({loadingAdd: true});

        const {username, tenant_name} = this.props;

        new Promise(resolve => {
            resolve(addUsersToChatFetch({
                id: selected,
                user_tenant_name: tenant_name,
                message: "",
                timestamp: Date.now(),
                username,
                users,
                members,
                connection_id: WSService.getConnectId()
            }))
        }).then((rs) => {
            this.popupAddRef.setState({loadingAdd: false, visible: false});
            this.props.addUser(rs);
        }).catch(() => {
            this.popupAddRef.setState({loadingAdd: false});
            notification.error({
                message: 'Add people failed',
                description: 'Please try again later'
            })
        })
    }

    handleCloseConversation = () => {
        const {selected}: any = this.state;
        if (!selected)
            return;

        const modal = Modal.confirm({
            title: <div className='font-s16 font-w4'>Are you sure you want to close this
                conversation?</div>,
            content: <div className='text-comment font-s5'>People in this conversation won't receive messages or see
                them.</div>,
            okButtonProps: {
                className: 'bt-danger font-s4'
            },
            cancelButtonProps: {
                className: 'bt-default font-s4'
            },
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                modal.update({
                    okButtonProps: {
                        className: 'bt-danger font-s4',
                        loading: true
                    }
                })
                new Promise(resolve => {
                    resolve(closeConversationFetch(selected, {
                        connection_id: WSService.getConnectId()
                    }))
                }).then(() => {
                    this.props.deleteConversation(selected);
                    this.setState({selected: null})
                    modal.destroy();
                }).catch(() => {
                    modal.update({
                        okButtonProps: {
                            className: 'bt-danger font-s4',
                            loading: false
                        }
                    })
                    notification.error({
                        message: 'Close conversation failed',
                        description: 'Please try again later'
                    })
                })
                return true;
            },
            onCancel: () => modal.destroy(),
        })
    }

    handleKickUsers = (list: any) => {
        const {selected}: any = this.state;
        if (!selected)
            return;

        const {username, tenant_name} = this.props;

        const {users, members} = list.reduce((rs: any, item: any) => {
            rs.users.push(item.id);
            rs.members.push(item)
            return rs;
        }, {users: [], members: []});

        const params = {
            id: selected,
            user_tenant_name: tenant_name,
            message: "",
            timestamp: Date.now(),
            username,
            users,
            members,
            connection_id: WSService.getConnectId()
        }

        if (list.length === 0) {
            const modal = Modal.confirm({
                title: <div className='font-s16 font-w4'>Are you sure you want to leave this
                    conversation?</div>,
                content: <div className='text-comment font-s5'>You will stop receiving messages from this conversation
                    and people will see that you left.</div>,
                okButtonProps: {
                    className: 'bt-danger font-s4'
                },
                cancelButtonProps: {
                    className: 'bt-default font-s4'
                },
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    modal.update({
                        okButtonProps: {
                            className: 'bt-danger font-s4',
                            loading: true
                        }
                    })
                    new Promise(resolve => {
                        resolve(removeUsersFromChatFetch(params))
                    }).then(() => {
                        this.props.leaveChat({chatType: CHAT_TYPE.USER, chatId: selected});
                        this.setState({selected: null})
                        modal.destroy();
                    }).catch(() => {
                        modal.update({
                            okButtonProps: {
                                className: 'bt-danger font-s4',
                                loading: false
                            }
                        })
                        notification.error({
                            message: 'Leave chat failed',
                            description: 'Please try again later'
                        })
                    })
                    return true;
                },
                onCancel: () => modal.destroy(),
            })
        } else {
            const modal = Modal.confirm({
                title: <div className='font-s16 font-w4'>Are you sure you want to remove this person from
                    the conversation?</div>,
                content: <div className='text-comment font-s5'>They will no longer be able to send or receive new
                    messages.</div>,
                okButtonProps: {
                    className: 'bt-danger font-s4'
                },
                cancelButtonProps: {
                    className: 'bt-default font-s4'
                },
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    modal.update({
                        okButtonProps: {
                            className: 'bt-danger font-s4',
                            loading: true
                        }
                    })
                    new Promise(resolve => {
                        resolve(removeUsersFromChatFetch(params))
                    }).then((rs: any) => {
                        this.props.kickUser(rs);
                        modal.destroy();
                    }).catch(() => {
                        modal.update({
                            okButtonProps: {
                                className: 'bt-danger font-s4',
                                loading: false
                            }
                        })
                        notification.error({
                            message: 'Remove from chat failed',
                            description: 'Please try again later'
                        })
                    })
                    return true;
                },
                onCancel: () => modal.destroy(),
            })
        }
    }

    render() {
        const {chat, loadingChat} = this.props.message;
        const {chatId} = this.props;
        const {isNew, isShowNew, values, loading, selected} = this.state;

        let name = '', id: any = null, users = [], target: any;

        if (!isNew) {
            id = chatId || selected;
            if (id) {
                target = chat.find((item: any) => item.group_chat.id === id);
            }

            if (target) {
                name = getTitleChatUser(target, this.props.user_id);
                users = target.group_chat.members;
            }
        }

        return <>
            <div className={stylesContainer.leftControl}>
                <div className={stylesContainer.title}>
                    <div data-lev='name'>
                        {tabMessage[CHAT_TYPE.USER].name}
                    </div>
                    <div data-lev='action'>
                        <div className='bt-ghost' data-icon={true} onClick={this.newChat}>
                            <Icon icon={ICON_NAME.ADD_2}/>
                        </div>
                    </div>
                </div>
                <div ref={this.rootRef} className={stylesContainer['container-content']} onWheel={this.handleWheel}>
                    {isShowNew && <div className={styles['new-chat']} data-visible={this.state.isNew}>
                        <div/>
                        <span>New chat</span>
                    </div>}

                    <div className={stylesContainer['content']}>
                        {chat.map((item: any) => {
                            const {group_chat = {}} = item || {}
                            const {id, users = []} = group_chat
                            const title = getTitleChatUser(item, this.props.user_id);
                            const isSelected = !isNew && target && id === target.group_chat.id;

                            return <Message
                                title={title}
                                avatar={users.length > 2
                                    ? <div className={styles['avatar-group']}>GR</div>
                                    : <div className={styles['avatar-single']}>{title[0]}</div>}
                                key={id}
                                data={item}
                                isSelected={isSelected}
                                onClick={() => this.showMessages(isSelected, item)}
                            />
                        })}
                    </div>
                    {loadingChat && <div className={stylesContainer.loading} data-empty={chat.length === 0}>
                        <Spin/>
                    </div>}
                </div>
            </div>
            <MessageBox
                isNew={isNew}
                values={values}
                name={name}
                loading={loading}
                chatType={CHAT_TYPE.USER}
                conversation={target}
                onSend={(params: any) => this.handleSend(params, target)}
                onChangeSelect={values => this.setState({values})}
                extra={<>
                    {target && <Menu items={this.menu(target)}>
                        <div className={stylesContainer.icon}>
                            <TeamOutlined style={{fontSize: '18px'}}/>
                            <span className='ml-1'>({target.group_chat.members.length})</span>
                        </div>
                    </Menu>}
                </>}
            />

            <PopupAddUser
                id={id} users={users}
                childRef={(ref: any) => this.popupAddRef = ref}
                onAdd={this.handleAddUsers}
            />
        </>;
    }
}

export default connect(mapStateToProps, {
    addChat: messageActions.addChat,
    getChat: messageActions.getChatRequest,
    sendMessage: messageActions.sendMessageRequest,
    getMessages: messageActions.getMessagesRequest,
    leaveChat: messageActions.leaveChat,
    addUser: messageActions.addUser,
    kickUser: messageActions.kickUser,
    deleteConversation: messageActions.deleteConversation
})(Chat);