import React, {Component} from 'react';
import {listOfStep, OPERATION_TYPE} from "../../../../util/varibles/constants";
import {convertStepOfLocation, durationTxtByStartAndFinish, formatNumber} from "../../../../util/varibles/global";
import Operation from "../../_Component/Operation";
import Vessel from "../../_Component/Vessel";
import stylesOp from '../../_Component/Operation/style.module.scss';
import Route from "../../_Component/Route";
import HarvestTable from "../../../../components/Popup/PopupOpInfo/Info/Harvest/HarvestTable";
import FishDisease from "../../../../components/Location/FishDisease";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import SectionRight from "../../_Component/SectionRight";
import WrapperActivityLog from "../../_Component/WrapperActivityLog";
import TankDensities from "../../../../components/Operation/TankDensities";
import {IOperation} from "../../../../util/varibles/interface";
import {getTotalAmountAndTotalWeight} from "../../../PlanOperationPage/util";
import ViewNote from "../../_Component/ViewNote";
import PlannedTime from "../../_Component/PlannedTime";
import LiceList from "../../../../components/Operation/LiceList";

interface IProps {
    data: IOperation
    isOwn: boolean
    viewMode: VIEW_MODE
}

class Harvest extends Component<IProps> {
    render() {
        const {operation, activity_log} = this.props.data;
        const {sub_operations, note = '',} = operation;
        const {total_amount, total_weight} = getTotalAmountAndTotalWeight(sub_operations);
        const est_start_time = activity_log[0].est_start_time;
        const est_finish_time = activity_log[activity_log.length - 1].est_start_time;

        const {capacity = 0} = this.props.data.vessel || {};
        const isNote = note.length === 0;

        const activityLog = activity_log.reduce((list: any, item: any, index: number) => {
            const {key = ''} = item;
            const steps = key.length > 0 ? key.split(',').map((step: string) => {
                if (step.indexOf('site') !== -1) {
                    return convertStepOfLocation.site({
                        step: index,
                        key: step,
                        operation,
                        activity_log
                    });
                } else if (step.indexOf('factory') !== -1) {
                    return convertStepOfLocation.factory({
                        step: index,
                        key: step,
                        operation,
                        activity_log
                    });
                }
                return listOfStep[step]
            }) : [];
            const value = {...item, steps, index};
            return [...list, {id: index, list: [value]}];
        }, []);

        return <>
            <div>
                <Operation {...{isNote}}>
                    <div className={stylesOp['info-line']}>
                        <span>Operation type:</span>
                        <span>Harvest</span>
                    </div>
                    <PlannedTime activity_log={activity_log}/>
                    <div className={stylesOp['info-line']}>
                        <span>Planned duration:</span>
                        <span>{durationTxtByStartAndFinish(est_start_time, est_finish_time)}</span>
                    </div>
                    <div className={stylesOp['info-line']}>
                        <span>Est. sum fish amount:</span>
                        <span>{formatNumber(total_amount)} </span>
                    </div>
                    <div className={stylesOp['info-line']}>
                        <span>Est. sum weight:</span>
                        <span>{`${formatNumber(Math.round(total_weight / 1000) / 1000)} t (${Math.round(total_weight / capacity * 100)}%)`}</span>
                    </div>
                    <FishDisease diseases={operation.diseases || []} units={operation.sub_operations}
                                 wrapperClass='mb-5'/>
                    <LiceList diseases={operation.diseases || []}/>
                    <div className={stylesOp['info-line']}>
                        <TankDensities type={OPERATION_TYPE.HARVEST} storages={operation.sub_operations[0]?.storages}/>
                    </div>
                    <HarvestTable {...{operation}}/>
                    <ViewNote data={this.props.data} isOwn={this.props.isOwn} note={note}/>
                </Operation>
                <Vessel/>
                <Route/>
            </div>
            <SectionRight>
                <WrapperActivityLog
                    isOwn={this.props.isOwn}
                    data={this.props.data}
                    activity_log={activityLog}
                    activity_log_root={activity_log}
                />
            </SectionRight>
        </>
    }
}

export default Harvest;
