import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {Storage} from "aws-amplify";
import {downloadBlob} from "../../../../../../util/varibles/global";
import {useEditOp} from "../../../../../../contexts/EditOperationContext";

const Files: React.FC = () => {
    const [files, set] = useEditOp(state => state.files);

    const handleOpenFile = useCallback((file: any) => {
        const {isNew, name} = file;
        if (isNew) {
            const element = document.createElement("a");
            element.href = URL.createObjectURL(file.file);
            element.download = name;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            element.remove();
        } else {
            const {key} = file;
            Storage.get(`operations/${key}`, {download: true})
                .then((blob: any) => downloadBlob(blob.Body, name))
        }
    }, [])

    const handleDelete = useCallback((file: any) => {
        const {name} = file;
        set(({files: prev}) => ({files: prev.filter((item: any) => item.name !== name)}))
    }, [set])

    return <div className={styles.wrapperFiles}>
        <div className={styles.files}>
            {files.map((item: any) => <div className={styles.file} key={item.key}>
                <span onClick={() => handleOpenFile(item)}>{item.name}</span>
                <div className={styles['file-remove']} onClick={() => handleDelete(item)}>
                    <div className={styles['icon-close']}/>
                </div>
            </div>)}
        </div>
    </div>;
};

export default Files;
