import React, {useMemo} from 'react';
import styles from "./style.module.scss";
import {FISH_TYPE, fishType, OPERATION_TYPE, siteType} from "../../../../util/varibles/constants";
import stylesParent from "../style.module.scss";
import {useSelector} from "react-redux";
import Operation from "./Operations/Operation";
import Position from "../../Component/Position";
import LoadingBox from "../../../../components/LoadingBox";
import FishDisease from "../../Component/FishDisease";
import {getProdAreaName} from "../../../../util/varibles/global";
import Weather from "../../Component/Weather";
import Phone from "../../Component/Phone";
import {selectDashboard} from "../../../../util/store/selectors";

interface IProps {
    data: any
    operations: any
    productionAreas: any
    loading: boolean
    weather: any
}

const Site: React.FC<IProps> = ({data}) => {

    const {
        address,
        name,
        position,
        type,
        site_type = FISH_TYPE.PRODUCTION,
        id,
        diseases = [],
        prod_area,
        unit_diseases
    } = data;

    const {production_area = {}} = diseases[0] || {};
    const productAreaId = prod_area || production_area.id;
    const productionAreas = useSelector(selectDashboard.prodAreas)
    const operations = useSelector(selectDashboard.operations)
    const productionArea = useMemo(() => {
        return productionAreas.find((item: any) => `${item.id}` === `${productAreaId}`)
    }, [productionAreas, productAreaId]);

    const listOfOperation = operations.reduce((list: any, item: any) => {
        const {operation_type} = item.operation;
        switch (operation_type) {
            case OPERATION_TYPE.HARVEST: {
                const {site = {id: ''}, sites = []} = item.operation;
                const listOfSite = sites.length > 0 ? sites : [site]
                if (listOfSite.some((item: any) => item.id === id))
                    return [...list, item]
                return list;
            }
            case OPERATION_TYPE.TREATMENT: {
                const {id: site_id = ''} = item.operation.site || {};
                if (site_id === id)
                    return [...list, item]
                return list;
            }
            case OPERATION_TYPE.TRANSPORT: {
                const {
                    source: {id: source_id} = {id: ''},
                    destination: {id: destination_id} = {id: ''}
                } = item.operation;
                if (source_id === id || destination_id === id)
                    return [...list, item]
                return list;
            }
            default:
                return list
        }
    }, []);

    return <>
        <div className={stylesParent.header}>
            <div>{name}</div>
        </div>
        <div className={stylesParent.body}>
            <div className={styles['container-info']}>
                <Phone phone={address}/>
                <div className={styles['line-info']}>
                    <span>Type:</span>
                    <span>{siteType[type].name}</span>
                </div>
                <div className={styles['line-info']}>
                    <span>Fish type:</span>
                    <span>{fishType[site_type] ? fishType[site_type].name : ''} </span>
                </div>
                <Position {...{lat: position.latitude, lng: position.longitude}}/>
                <div className={styles['line-info']}>
                    <span>Production area:</span>
                    <span>{productionArea ? getProdAreaName(productionArea) : ''}</span>
                </div>
            </div>
            <div className={styles['container-weather']}>
                <Weather name={name}/>
            </div>
            <div className={[styles['container-weather'], 'pb-0'].join(' ')}>
                <div className={styles.title}>
                    <div>Lice, Disease</div>
                </div>
                <div className='text-comment ml-20 font-s4'>
                    (The info. is for current week and will be updated each week)
                </div>
                <FishDisease data={diseases} unit_diseases={unit_diseases}/>
            </div>
            <div className={[styles['container-weather'], 'pb-0'].join(' ')}>
                <div className={styles.title}>
                    <div>Incoming operation. ({listOfOperation.length})</div>
                </div>
                <div className={stylesParent.operations}>
                    {listOfOperation.length === 0 && <LoadingBox message="There is no ongoing operation today."/>}
                    {listOfOperation.map((data: any, index: any) => <Operation key={index} data={data}/>)}
                </div>
            </div>
        </div>
    </>;
};

export default Site;