import React, {useCallback, useState} from 'react';
import {ACTION_TYPE, propsEmpty} from "../../../util/varibles/constants";
import {Drawer} from "antd";
import styles from './style.module.scss';
import {IVessel} from "../../../util/varibles/interface";
import Header from '../../../components/Popup/Component/Header';
import {EditVesselProvider} from "../../../contexts/EditVesselContext";
import VesselForm from "./Component/VesselForm";

interface IProps {
    editMode: ACTION_TYPE
    vessel?: IVessel
    title: string

    onSave(vessel: IVessel): void

    onClose?(): void
}

const PopupEditVessel: React.FC<IProps> = (props) => {
    const {editMode, vessel, title, onSave, onClose} = props;
    const [visible, setVisible] = useState(true);


    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            onClose()
    }, [onClose])

    return <Drawer
        {...propsEmpty}
        closable={false}
        className={styles['popup-edit-vessel']}
        open={visible}
        width='100%'
        height='100%'
        placement='bottom'
        onClose={handleClose}
        title={<Header
            title={<span className='font-w5 font-s1'>{title ? title : ''}</span>}
            onClose={handleClose}
        />}
    >
        <EditVesselProvider>
            <VesselForm vessel={vessel} editMode={editMode} onSave={onSave} onClose={handleClose}/>
        </EditVesselProvider>
    </Drawer>;
};

export default PopupEditVessel;
