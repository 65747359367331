import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import HiredVessels from "./HiredVessels";
import OwnVessel from "./OwnVessel";
import {
    CONTRACT_PERMISSION_TYPE,
    CONTRACT_STATUS,
    CONTRACT_TYPE,
    contractPermissionType,
    PATHNAME
} from "../../util/varibles/constants";
import {formatDate, uppercaseFirstLetter} from "../../util/varibles/global";
import {vesselManagementActions} from "./reducer";
import {AppState} from "../../util/store/store";
import {ACTION} from "../../util/varibles/permission";
import {nextPage} from "../../util/library/Router";

const listOfColumn = {
    code: {
        title: <span className='pl-3'>Code</span>,
        dataIndex: 'contract_code',
        key: 'contract_code',
        width: '100px',
        render: (text: string) => <span className='pl-3'>{text}</span>
    },
    permission: {
        title: 'Type',
        key: 'permission',
        width: '300px',
        render: (record: any) => {
            const {type, permission} = record;
            if (type === CONTRACT_TYPE.FIXED) {
                return <span>-</span>
            } else {
                const {name} = contractPermissionType[permission] || contractPermissionType[CONTRACT_PERMISSION_TYPE.BASIC];
                return <span>{name}</span>
            }
        },
    },
    tenant_name: {
        title: 'Customer',
        dataIndex: 'tenant_name',
        key: 'tenant_name',
        width: '180px',
        render: (text: number) => <span>{text}</span>,
    },
    start_time: {
        title: 'Start time',
        dataIndex: 'start_time',
        key: 'start_time',
        width: '130px',
        render: (text: number) => <span>{formatDate(text)}</span>,
    },
    finish_time: {
        title: 'Finish time',
        dataIndex: 'finish_time',
        key: 'finish_time',
        width: '130px',
        render: (text: number) => <span>{formatDate(text)}</span>,
    },
    status: {
        title: 'Status',
        dataIndex: 'spot_status',
        key: 'status',
        width: '130px',
        render: (status: any) => {
            let statusText, statusCode;
            switch (status) {
                case CONTRACT_STATUS.INACTIVE:
                    statusText = 'Canceled';
                    statusCode = -1;
                    break;
                case CONTRACT_STATUS.DECLINE:
                    statusText = 'Decline'
                    statusCode = -1;
                    break;
                case CONTRACT_STATUS.COMPLETE:
                    statusText = 'Active'
                    statusCode = 1;
                    break;
                default:
                    statusText = uppercaseFirstLetter(status)
                    statusCode = 0;
            }
            return <div
                className={styles['contract-status']}
                data-status-code={statusCode}
            >
                {statusText}
            </div>
        },
    },
}

interface IProps {
    permission: any

    getData(): void
}

class VesselManagementPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        if (props.permission[ACTION.VESSEL.VIEW]) {
            this.props.getData();
        } else {
            nextPage(PATHNAME.ACCESS_DENIED)
        }
    }

    render() {
        const columnsOwnVessel = [
            listOfColumn.code,
            listOfColumn.permission,
            listOfColumn.tenant_name,
            listOfColumn.start_time,
            listOfColumn.status
        ];
        const columnsHiredVessel = [
            listOfColumn.code,
            listOfColumn.permission,
            listOfColumn.start_time,
            listOfColumn.status
        ]

        return <div className='m-header bgPage'>
            <div className='header-page'>
                <div className='title-page'>
                    <b>Vessel Management</b>
                </div>
            </div>
            <div className={['body-page', styles.body].join(' ')} data-box='true'>
                <OwnVessel columns={columnsOwnVessel}/>
                <HiredVessels columns={columnsHiredVessel}/>
            </div>
        </div>;
    }
}

const mapStateToProps = (state: AppState) => {
    const permission = state.login.user.permission;

    return {
        permission
    }
}


export default connect(mapStateToProps, {
    getData: vesselManagementActions.getListRequest
})(VesselManagementPage);
