import React, {useCallback, useState} from 'react';
import {Badge, Button, Checkbox, Dropdown, Input, Tooltip} from "antd";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {propContainer} from "../../../../util/varibles/constants";
import styles from "./style.module.scss";

interface IProps {
    options: { label: string, value: string }[]
    value: string[]

    onChange(value: any): void
}

const Filter: React.FC<IProps> = ({options, value, onChange}) => {
    const [visible, setVisible] = useState(false);
    const [search, setSearch] = useState('');

    const handleVisibleChange = (visible: boolean) => setVisible(visible);

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Escape' && search.length > 0) {
            setSearch('');
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }, [search]);

    const items = [
        {
            className: styles.wrapper,
            key: 'filter',
            disabled: true,
            label: <div className={styles.filter} onClick={(e) => e.stopPropagation()}>
                {options.length > 10 && <div className={styles.search}>
                    <Input
                        className='h-36'
                        placeholder='Search by name'
                        value={search}
                        onChange={({target}) => setSearch(target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>}
                <Checkbox.Group
                    className='checkbox-fill'
                    value={value}
                    onChange={onChange}
                    options={options.filter(item => item.label.toLowerCase().includes(search.toLowerCase().trim()))}
                />
                {value.length > 0 && <div className='link mt-10' onClick={() => onChange([])}>
                    Clear
                </div>}
            </div>
        }
    ]

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setVisible(!visible);
    }

    return <Dropdown
        {...propContainer}
        open={visible}
        trigger={['click']}
        onOpenChange={handleVisibleChange}
        menu={{items}}
        placement="bottomRight"
        arrow
        dropdownRender={(menus) => <>
            <div className={styles.layout} onClick={handleClick}></div>
            {menus}
        </>}
    >
        <Badge count={value.length}>
            <Tooltip title='Filter'>
                <Button className='bt-ghost' data-icon={true} onClick={handleClick}>
                    <Icon icon={ICON_NAME.FILTER}/>
                </Button>
            </Tooltip>
        </Badge>
    </Dropdown>;
};

export default Filter;
