import React, {Component} from 'react';
import Density from "./Density";
import styles from './style.module.scss';
import OperationMode from "./OperationMode";

interface IProps {
}

class OperationSettingPage extends Component<IProps> {
    render() {
        return <div className="m-header bgPage">
            <div className='header-page'>
                <div className='title-page'>
                    <b>Operation</b>
                </div>
            </div>
            <div className='body-page pt-10' data-box='true'>
                <div className={styles.content}>
                    <Density/>
                    <OperationMode/>
                </div>
            </div>
        </div>;
    }
}

export default OperationSettingPage;