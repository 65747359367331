import React from 'react';
import {SisternodeOutlined} from "@ant-design/icons";
import {ITenant} from "../../../util/varibles/interface";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupGenerate from "../PopupGenerate";

interface IProps {
    data: ITenant

    disabled: boolean
}

const GenerateSite: React.FC<IProps> = ({data, disabled}) => {
    const handleClick = () => {
        if (!disabled) {
            const el = openPopup(<PopupGenerate
                visible={true}
                data={data}
                onClose={() => el.remove()}
            />)
        }
    }

    return <div className='menu-line' aria-disabled={disabled} onClick={handleClick}>
        <SisternodeOutlined style={{fontSize: '20px'}}/>
        <div>Generate site, unit</div>
    </div>;
};

export default GenerateSite;