import React, {useMemo} from 'react';
import InputForm from "../../../../components/Basic/InputForm";
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import {vesselForm} from "../constants";
import {funcValidation} from "../../../../util/varibles/validation";
import {IErrorForm} from "../../../../util/varibles/interface";

interface IProps {
    id: string
    className?: string
    options?: { label: string, value: any }[]
}

const VesselInput: React.FC<IProps> = ({id, className, options}) => {
    const [value, setStore] = useEditVessel(state => {
        const selector: any = state.vessel;
        return selector[id]
    })
    const [error = {}] = useEditVessel(state => {
        const {[id]: selector} = state.error || {} as { [key: string]: IErrorForm };
        return selector as IErrorForm
    })
    const {label, type, rules, suffix} = vesselForm[id] || {};
    const isError = useMemo(() => Object.keys(error).length > 0, [error]);

    return <InputForm
        label={label}
        className={className}
        error={error}
        require={rules.require || rules.requireZero}
        suffix={suffix}
        type={type}
        value={value}
        options={options}
        onChange={newValue => setStore(({vessel: vesselOld, error: errorOld = {}}) => {
            const state: { vessel: any, error?: any } = {
                vessel: {
                    ...vesselOld,
                    [id]: newValue
                }
            }
            if (isError) {
                state.error = {
                    ...errorOld,
                    [id]: Object.keys(rules).reduce((rs: any, key) => {
                        const check = funcValidation[key];
                        if (check) {
                            const result = check(newValue);
                            if (result)
                                rs[key] = result
                        }
                        return rs;
                    }, {})
                }
            }
            return state;
        })}
    />;
};

export default VesselInput;