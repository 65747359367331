import React from 'react';
import {connect} from "react-redux";
import {ILevel, ILevelByVessel, IOperation, IOperations, IPropsChildren,} from "../../../../util/varibles/interface";
import {OPERATION_TYPE} from "../../../../util/varibles/constants";
import Harvest from "./Operation/Harvest";
import Treatment from "./Operation/Treatment";
import Transport from "./Operation/Transport";
import Event from "./Operation/Event";
import Service from "./Operation/Service";
import {IVesselHeight} from "../Vessels";
import {AppState} from "../../../../util/store/store";

export interface IPropsOp extends ICommon {
    level: ILevel
}

export interface ICommon extends IPropsChildren {
    moreHeight: number
    vesselHeight: IVesselHeight
}

const render = {
    [OPERATION_TYPE.UNAVAILABLE]: () => {
        return null;
    },
    [OPERATION_TYPE.HARVEST]: (op: IOperation, key: string, level: ILevelByVessel, common: ICommon) => {
        return <Harvest key={key} data={op} level={level[op.vessel.id][op.operation.id]} {...common}/>
    },
    [OPERATION_TYPE.TREATMENT]: (op: IOperation, key: string, level: ILevelByVessel, common: ICommon) => {
        return <Treatment key={key} data={op} level={level[op.vessel.id][op.operation.id]} {...common}/>
    },
    [OPERATION_TYPE.TRANSPORT]: (op: IOperation, key: string, level: ILevelByVessel, common: ICommon) => {
        return <Transport key={key} data={op} level={level[op.vessel.id][op.operation.id]} {...common}/>
    },
    [OPERATION_TYPE.EVENT]: (op: IOperation, key: string, level: ILevelByVessel, common: ICommon) => {
        return <Event key={key} data={op} level={level[op.vessel.id][op.operation.id]} {...common}/>
    },
    [OPERATION_TYPE.SERVICE]: (op: IOperation, key: string, level: ILevelByVessel, common: ICommon) => {
        return <Service key={key} data={op} level={level[op.vessel.id][op.operation.id]} {...common}/>
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        operations: state.overview.operations,
        level: state.overview.level
    }
};

interface IProps {
    operations: IOperations
    level: ILevelByVessel
    moreHeight: number
    vesselHeight: IVesselHeight
}

const Operations: React.FC<IProps> = ({operations, level, moreHeight, vesselHeight}) => {
    const common = {
        moreHeight,
        vesselHeight
    }

    return <>
        {Object.keys(operations).map(key => {
            const {operation_type} = operations[key].operation;
            return render[operation_type](operations[key], key, level, common)
        })}
    </>
};

export default connect(mapStateToProps, {})(Operations);
