import React, {ReactNode, useEffect} from 'react';
import {useWorldMap} from "./";
import {Map, MapMouseEvent, useMap} from '@vis.gl/react-google-maps';
import {CENTER_DEFAULT, MAP_STYLE, mapType, ZOOM_DEFAULT} from "../../util/library/googlemap/constants";
import {useSelector} from "react-redux";
import {selectOneSetting} from "../../util/store/selectors";
import {GlobalSetting} from "../../util/varibles/userSetting";
import {MapEvent} from "@vis.gl/react-google-maps/dist/components/map/use-map-events";

interface IProps {
    id?: string
    center?: { lat: number, lng: number } | { lat: number, lng: number }[]
    children?: ReactNode

    onDragstart?: (event?: MapEvent) => void
    onClick?: (event?: MapMouseEvent) => void
    onContextmenu?: (event?: MapMouseEvent) => void
}

const GoogleMap: React.FC<IProps> = ({id = 'map', center, children, onDragstart, onClick, onContextmenu}) => {
    const map = useMap();
    const mapStyle = useSelector(selectOneSetting(GlobalSetting.MAP_STYLE)) as MAP_STYLE;
    const [, setStore] = useWorldMap(store => store.zoom)

    useEffect(() => {
        if (map) {
            if (center) {
                if (Array.isArray(center)) {
                    const bounds = new google.maps.LatLngBounds();
                    center.forEach(point => {
                        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
                    });
                    map?.fitBounds(bounds);
                } else {
                    map.setCenter(center);
                }
            }

            Object.keys(mapType).forEach(key => {
                const {style, name} = mapType[key];
                if (style) {
                    const type = new google.maps.StyledMapType(style, {name});
                    map.mapTypes.set(key, type);
                }
            })
        }
    }, [map]);

    return <Map
        defaultCenter={CENTER_DEFAULT}
        defaultZoom={ZOOM_DEFAULT}
        onZoomChanged={event => setStore({zoom: event.detail.zoom})}
        mapTypeId={mapStyle}
        zoomControl={false}
        clickableIcons={false}
        streetViewControl={false}
        mapTypeControl={false}
        mapId={id}
        gestureHandling={'greedy'}
        disableDefaultUI
        onDragstart={onDragstart}
        onContextmenu={onContextmenu}
        onClick={e => {
            if (onClick)
                onClick(e)
            setStore({zonesLock: false, location: undefined})
        }}
    >
        {children}
    </Map>
}
export default GoogleMap;
