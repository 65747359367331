import React, {useCallback, useMemo, useRef, useState} from 'react';
import Menu from "../../../../../../../components/Menu";
import {Checkbox} from "antd";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import styles from './style.module.scss';
import PopupDuplicate from "../../../_Component/Duplicate/PopupDuplicate";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";
import {TTaskTreatment} from "../../../../../../../util/varibles/interface";
import {callEventEditOp, IUnitWhenEdit, useEditOp} from "../../../../../../../contexts/EditOperationContext";
import {uuid} from "../../../../../../../util/varibles/global";
import {CheckboxChangeEvent} from "antd/es/checkbox";

let isClick = false;
let time = 0;
const initMenu = {open: false, placement: 'bottom'}

interface IProps {
    data: TTaskTreatment

    onDelete?(): void

    onChange(change: Partial<TTaskTreatment>): void
}

const Setting: React.FC<IProps> = (props) => {
    const {data, onChange, onDelete} = props;
    const buttonRef = useRef<HTMLDivElement>(null);
    const [menu, setMenu] = useState(initMenu);
    const [site, set] = useEditOp(state => state.site);
    const {turnOnCalculate} = callEventEditOp()


    const items = useMemo(() => {
        // const {is_support_vessel, type, treatment_type = null} = data;
        const {is_support_vessel} = data;
        // let isAllowSupport = type === TASK_TYPE.HARVEST || TASK_TYPE.COMBINE
        //     || (type === TASK_TYPE.TREATMENT && treatment_type !== null && treatment_type !== TREATMENT_TYPE.SORTING);
        const isAllowSupport = false;

        const handleCheck = (value: CheckboxChangeEvent) => {
            const {checked} = value.target;
            onChange({is_support_vessel: checked})
            const {sub_operation, group_id} = data;
            set(({supportTasks}) => {
                return {
                    supportTasks: checked
                        ? [...supportTasks, {...data, is_support_vessel: checked}]
                        : supportTasks.filter(item => item.group_id !== group_id && item.sub_operation.id !== sub_operation.id)
                }
            })
        };

        return [
            ...(isAllowSupport ? [{
                key: 'support',
                disabled: true,
                label: <Checkbox checked={is_support_vessel} onChange={handleCheck}>
                    <span className='ml-5'>Need support vessel</span>
                </Checkbox>
            }] : []),
            {
                key: 'duplicate',
                label: <div className='menu-line' onClick={() => {
                    setMenu(prev => ({...prev, open: false}));
                    const {sub_operation} = data;
                    const modal = openPopup(<PopupDuplicate
                        isShow={true}
                        sites={site ? [site] : []}
                        unit={sub_operation}
                        onAdd={units => {
                            set(({units: prev}) => ({
                                units: units.reduce((rs: { [id: string]: IUnitWhenEdit }, item) => ({
                                    ...rs,
                                    [item.id]: {
                                        ...rs[item.id],
                                        tasks: [...rs[item.id].tasks || [], {
                                            ...data,
                                            group_id: uuid(),
                                            sub_operation: item
                                        }]
                                    }
                                }), prev)
                            }))
                            turnOnCalculate();
                        }}
                        onClose={() => modal.remove()}
                    />);
                }}>
                    <Icon icon={ICON_NAME.DUPLICATE}/>
                    <span>Replicate to other unit</span>
                </div>
            },
            {
                key: 'delete',
                label: <div className='menu-line' data-danger='true' onClick={onDelete}>
                    <Icon icon={ICON_NAME.DELETE} danger/>
                    <span>Delete task</span>
                </div>
            }
        ]
    }, [data, site, set, turnOnCalculate, onChange, onDelete]);

    const handleMouseDown = useCallback((e: any) => {
        setMenu({...menu, placement: 'bottomRight'});
        if (buttonRef.current) {
            isClick = true;
            const {x, y} = e.target.getBoundingClientRect();
            const event = new MouseEvent("contextmenu", {
                bubbles: true,
                cancelable: false,
                view: window,
                button: 2,
                buttons: 0,
                clientX: x + 28,
                clientY: y + 28
            });
            buttonRef.current.dispatchEvent(event);
        }
    }, [menu]);

    const onOpenChange = useCallback((value: boolean) => {
        const state = {open: value, placement: ''};
        if (isClick) {
            state.placement = 'bottomRight'
            isClick = false;
        } else if ((Date.now() - time) < 5) {
            return;
        }
        setMenu(state);
    }, [])

    return <Menu
        visible={menu.open}
        placement={menu.placement as any}
        onOpenChange={onOpenChange}
        items={items}
        trigger={['contextMenu']}
    >
        <div ref={buttonRef} className={styles.button}>
            <div className='three-dot' onMouseDown={handleMouseDown} onMouseUp={() => time = Date.now()}/>
        </div>
    </Menu>;
};

export default Setting;
