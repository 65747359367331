import React from 'react';
import Header from "../../Header";
import stylesContainer from "../../style.module.scss";
import {
    eventTypes,
    getOperationStatus,
    OP_STATUS,
    OPERATION_MODE,
    STATE_EVENT
} from "../../../../../util/varibles/constants";
import Status from "../../Component/Status";
import ActivityLog from "../../Component/AcitivtyLog";
import ApproveControl from "../../Component/ApproveControl";
import Locations from "../../../../Operation/Locations";
import {IActivityLog, IOperation} from "../../../../../util/varibles/interface";
import {getTimeByOpMode} from "../../../../../pages/PlanOperationPage/util/function_operation/constants";
import Approver from "../../Component/Approver";
import BoxOpInfo from "../../Component/BoxOpInfo";

interface IProps {
    isEdit: boolean
    isOwn: boolean
    isApprove: boolean
    data: IOperation
    loading: any
    weather: any
    opMode: OPERATION_MODE

    handleUpdateActivityLog(activityLog: IActivityLog[]): void

    onClose(): void

    handleUpdateNote(note: string): void

    handleChangeState(params: any): void
}

const Event: React.FC<IProps> = (props) => {
    const {
        data,
        loading,
        weather,
        isEdit,
        opMode,
        isOwn, isApprove,
        handleChangeState,
        onClose,
    } = props;
    const {operation, activity_log, vessel} = data;
    const startTime = getTimeByOpMode[opMode](activity_log[0]);
    const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);

    const {current_process, event_type, status, pois} = operation;
    const statusText = status === OP_STATUS.PROCESSING ? STATE_EVENT[current_process] : getOperationStatus(status);
    const event = eventTypes.find(item => item.id === event_type) || {name: ''};

    return <>
        <Header {...{
            onClose,
            isOwn,
            operation,
            loading,
            weather,
            keyWeather: `${startTime}|${finishTime}`
        }}>
            <div className={stylesContainer.title}>
                <div className='ml-3 d-flex align-items-center'>
                    <Status {...{status, statusText}}/>
                    <Locations className='ml-5' locations={pois || []} separate={', '}/>
                </div>
            </div>
        </Header>

        <div className={stylesContainer.body}>
            <ActivityLog {...{
                isEdit,
                activity_log,
                vessel,
                operation,
                opMode,
                handleUpdate: props.handleUpdateActivityLog
            }} />
        </div>
        <BoxOpInfo data={data} isEdit={isEdit} handleUpdateNote={props.handleUpdateNote}>
            <ApproveControl {...{isApprove, operation, handleChangeState}}/>
            <Approver isOwn={isOwn} operation={operation}/>
            <div className={[stylesContainer['container-box'], 'mb-10'].join(' ')}>
                <div>Event type:</div>
                <div className={stylesContainer['box-custom']}>{event.name}</div>
            </div>
        </BoxOpInfo>
    </>;
};

export default Event;