import React from 'react';
import {Checkbox} from "antd";
import {useDispatch, useSelector} from "react-redux";
import styles from "../style.module.scss";
import LoadingBox from "../../../../components/LoadingBox";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {selectDashboard, selectOneSetting} from "../../../../util/store/selectors";

interface IProps {
}

const Vessel: React.FC<IProps> = () => {
    const vessels = useSelector(selectDashboard.vessels) || [];
    const loading = useSelector(selectDashboard.loadingVessels);
    const filter = useSelector(selectOneSetting(DashboardSetting.VESSELS));
    const dispatch = useDispatch();

    const onChange = (data: any) => {
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.VESSELS]: data}]));
    }

    const renderContent = () => {
        if (loading || vessels.length === 0)
            return <LoadingBox loading={loading} message="No data"/>

        return <Checkbox.Group className={[styles.checkbox, 'checkbox-fill'].join(' ')} value={filter}
                               onChange={onChange}>
            {vessels.map(({id, name}) => <Checkbox key={id} value={id}>{name}</Checkbox>)}
        </Checkbox.Group>
    }

    return <div className='pb-12'>
        {renderContent()}
        {filter.length > 0 && <div className='link mt-10 ml-5' onClick={() => onChange([])}>Clear</div>}
    </div>;
};

export default Vessel;
