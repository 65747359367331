import React, {Component} from 'react';
import stylesContainer from "../style.module.scss";
import styles from './style.module.scss';
import {CONVERSATION_MODE} from "../../../../../../pages/MessagePage/constants";
import ChatBox from "../../../../ChatBox";
import {Select} from "antd";
import {searchOpFetch, searchUserFetch} from "../../../../../../util/services/chat";
import {CHAT_TYPE, KEY_STORAGE, OP_STATUS} from "../../../../../../util/varibles/constants";
import {getSubInfoChatOp} from "../../../../constants";
import Logo from "../../../../../Operation/Logo";
import Id from "../../../../../Operation/Id";
import LoadingBox from "../../../../../LoadingBox";

interface IProps {
    childRef: any

    onClose(): void

    onAdd(params: any): void
}

class ConversationAdd extends Component<IProps> {
    state = {
        chatType: CHAT_TYPE.USER,
        mode: CONVERSATION_MODE.CLOSE,
        values: [],
        options: [],
        loading: false,
        key: ''
    }
    timeout: any;
    selectRef: any = React.createRef();

    constructor(props: IProps) {
        super(props);
        const chatType = localStorage.getItem(KEY_STORAGE.CHAT_TYPE) === CHAT_TYPE.OPERATION ? CHAT_TYPE.OPERATION : CHAT_TYPE.USER;
        this.state = {...this.state, chatType}
    }


    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    open(chatType: CHAT_TYPE) {
        this.setState({
            chatType,
            mode: CONVERSATION_MODE.OPEN,
            values: [],
            options: [],
            loading: false,
            key: '',
        }, () => {
            if (this.selectRef.current)
                this.selectRef.current.focus();
        })
    }

    changeMode(e: any, mode: CONVERSATION_MODE) {
        const selectEl = document.querySelector(`.${styles['select-custom']}`);
        const dropdownEl: any = document.querySelector(`.${styles['dropdown-user']}`);
        if ((selectEl && selectEl.contains(e.target)) || (dropdownEl && dropdownEl.contains(e.target)))
            return;

        if (mode === CONVERSATION_MODE.OPEN) {
            setTimeout(() => {
                if (this.selectRef.current)
                    this.selectRef.current.focus()
            }, 100);
        }
        e.preventDefault();
        e.stopPropagation();

        this.setState({mode})
        return false
    }

    close = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({mode: CONVERSATION_MODE.CLOSE});
        setTimeout(() => this.props.onClose(), 300);
        return false
    }

    handleSelectUser = (isSelect: boolean, value: any, event: any) => {
        const {values} = this.state;
        const {children} = event;
        this.setState({
            values: isSelect
                ? [...values, {id: value, name: children}]
                : values.filter((item: any) => item.id !== value)
        })
    }

    handleSelectOp = (isSelect: boolean, value: any, event: any) => {
        const {values} = this.state;
        const {op} = event;
        this.setState({
            values: isSelect
                ? [...values, {id: value, op}]
                : values.filter((item: any) => item.id !== value)
        })

        if (isSelect) {
            setTimeout(() => {
                const el = document.getElementById('input-' + value);
                if (el)
                    el.focus()
            }, 100)
        }
    }

    handleSearchUser = (key: string) => {
        const value = key.trim();
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        if (value.length < 2) {
            this.setState({loading: false, options: []});
            return;
        }

        this.setState({key: value});
        this.timeout = setTimeout(() => {
            this.setState({loading: true, isMore: false});
            new Promise((resolve) => {
                resolve(searchUserFetch({key: value, index: 1}));
            }).then((rs: any) => {
                const {users, next} = rs;
                this.setState({
                    loading: false,
                    options: users.map((item: any) => <Select.Option key={item.id} value={item.id}>
                        {item.username}
                    </Select.Option>),
                    isMore: next
                });
            }).catch(() => {
                this.setState({loading: false});
            })
        }, 250)
    }

    handleSearchOp = (key: string) => {
        const value = key.trim();
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        if (value.length < 2) {
            this.setState({loading: false, options: []});
            return;
        }

        this.setState({key: value});
        this.timeout = setTimeout(() => {
            this.setState({loading: true, isMore: false});
            new Promise((resolve) => {
                resolve(searchOpFetch({key: value, index: 1}));
            }).then((rs: any) => {
                const {operations, next} = rs;
                this.setState({
                    loading: false,
                    options: operations.map((item: any) => {
                        const {operation_type, name, id} = item;
                        return <Select.Option key={id} value={id} op={item}>
                            <div className={styles['option-op']}>
                                <Logo type={operation_type} status={OP_STATUS.NEW}/>
                                <Id operation={{operation_code: name}} status={OP_STATUS.NEW}/>
                                <div>{getSubInfoChatOp(item)}</div>
                            </div>
                        </Select.Option>
                    }),
                    isMore: next
                });
            }).catch(() => {
                this.setState({loading: false});
            })
        }, 250)
    }

    renderTitle = () => {
        const {values, options, loading, chatType} = this.state;
        const props: any = {}
        if (chatType === CHAT_TYPE.USER) {
            props.mode = 'multiple';
            props.className = [styles['select-custom'], styles['select-user']].join(' ');
            props.onSearch = this.handleSearchUser;
            props.placeholder = 'Choose people to join the chat';
            props.onSelect = (value: any, event: any) => this.handleSelectUser(true, value, event);
            props.onDeselect = (value: any, event: any) => this.handleSelectUser(false, value, event)
        } else {
            props.className = styles['select-custom']
            props.onSearch = this.handleSearchOp;
            props.placeholder = 'Choose operation';
            props.onSelect = (value: any, event: any) => this.handleSelectOp(true, value, event);
            props.onDeselect = (value: any, event: any) => this.handleSelectOp(false, value, event);
        }

        return <Select
            {...props}
            value={values.map((item: any) => item.id)}
            ref={this.selectRef}
            showSearch
            filterOption={false}
            popupMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            notFoundContent={<LoadingBox loading={loading}/>}
        >
            {options}
        </Select>
    }

    handleAdd = (params: any) => {
        const {chatType, values} = this.state;
        this.props.onAdd({...params, chatType, values})
    }

    render() {
        const {mode, chatType, values = []}: any = this.state;

        return <div
            className={[stylesContainer.conversation, stylesContainer['conversation-add']].join(' ')}
            data-mode={mode}
        >
            <div className={stylesContainer['title']}
                 onClick={(e) => this.changeMode(e, mode === CONVERSATION_MODE.MINIMIZE ? CONVERSATION_MODE.OPEN : CONVERSATION_MODE.MINIMIZE)}>
                <div className={stylesContainer['title-name']}>
                    {mode === CONVERSATION_MODE.MINIMIZE ? 'New chat' : <div className={styles['wrapper']}>
                        {this.renderTitle()}
                    </div>}
                </div>
                <div className='bt-close' onMouseDown={this.close}/>
            </div>
            <div className={stylesContainer['content']}>
                <ChatBox
                    data={{
                        type: chatType,
                        group_chat: {
                            id: values[0] ? values[0].id : null,
                            members: [],
                        }
                    }}
                    isActiveInput={values.length > 0}
                    onSend={this.handleAdd}
                />
            </div>
        </div>;
    }
}

export default ConversationAdd;