import {Button} from 'antd';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import {VesselService} from "../../../../util/services/vessel";
import {cloneObj, showErrorResponse, uppercaseFirstLetter} from "../../../../util/varibles/global";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import styles from "../style.module.scss";
import {IStorage} from "../../../../util/varibles/interface";
import InputForm from "../../../../components/Basic/InputForm";
import {storageForm} from "../constants";
import {debounce} from "debounce";
import LoadingBox from "../../../../components/LoadingBox";

const keys = ['name', 'value', 'speed'];

const TankInput: React.FC<{
    id: number,
    data: IStorage | any,
    onChange: (value: IStorage) => void,
    onRemove?: () => void
}> = ({id, data, onChange, onRemove}) => {

    const handleChange = (value: Partial<IStorage>) => onChange({...data, ...value})
    const [error = {}] = useEditVessel(state => {
        const {[id]: selector} = state.error?.storages || {};
        return selector
    })

    return <div className={styles.tank}>
        {keys.map(item => {
            const props = storageForm[item];
            return <InputForm
                {...props}
                key={item}
                error={error[item]}
                value={data[item]}
                onChange={value => handleChange({[item]: value})}
            />
        })}
        {onRemove && <Button className='bt-ghost' data-icon='true' data-danger={true}
                             onClick={onRemove}>
            <Icon icon={ICON_NAME.DELETE}/>
        </Button>}
    </div>
}

const Storages: React.FC = () => {
    const [value = [], setStore] = useEditVessel(state => state.vessel?.storages);
    const [{storageTypes, loading}, setStorageTypes] = useState<{ loading: boolean, storageTypes: any[] }>({
        loading: false,
        storageTypes: []
    });
    const storageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setStorageTypes(prev => ({...prev, loadingStorages: true}));
        VesselService.storages()
            .then(rs => setStorageTypes({loading: false, storageTypes: rs}))
            .catch(error => {
                showErrorResponse('Get tanks failed', error);
                setStorageTypes({loading: false, storageTypes: []});
            })
    }, []);

    const handleAdd = useCallback(() => {
        if (!loading && storageTypes[0]) {
            const {id, name, type, group_type} = storageTypes[0];
            setStore(({vessel: old}) => ({
                vessel: {
                    ...old,
                    storages: [
                        ...old?.storages || [],
                        {
                            name: '',
                            speed: 0,
                            value: 0,
                            group_id: id,
                            group_name: uppercaseFirstLetter(name),
                            group_type,
                            type
                        }
                    ]
                }
            }))

            debounce(() => {
                const el = storageRef.current;
                if (el) {
                    const tankEl = el.lastChild;
                    if (tankEl) {
                        const target = tankEl.firstChild as HTMLDivElement;
                        if (target) {
                            const inputEl = target.getElementsByTagName('input')[0];
                            inputEl?.focus();
                        }
                    }
                }
            }, 300)()
        }
        // const el = openPopup(<PopupAddStorages
        //     onAdd={callback}
        //     onClose={() => setTimeout(() => el.remove(), 300)}
        // />)
    }, [loading, storageTypes, setStore]);

    const handleChange = useCallback((index: number, value: IStorage) => {
        setStore(({vessel: old}) => {
            const currentData = cloneObj(old?.storages || []);
            currentData.splice(index, 1, value);
            return {vessel: {...old, storages: currentData}}
        })
    }, [setStore])

    const handleRemove = useCallback((index: number) => {
        setStore(({vessel: old}) => {
            const currentData = cloneObj(old?.storages || []);
            currentData.splice(index, 1);
            return {vessel: {...old, storages: currentData}}
        })
    }, [setStore])

    return <div>
        <div className='flex items-center gap-2 mb-1'>
            <span className='text-[15px] mt-1'>Add tank equipment</span>
            <Button className="bt-ghost" loading={loading} data-icon={true}
                    size='small'
                    onClick={() => handleAdd()}>
                <Icon icon={ICON_NAME.ADD_2} size='small'/>
            </Button>
        </div>
        {value.length === 0 && <LoadingBox className='mt-3 max-w-[300px]' loading={false}/>}
        <div ref={storageRef} className='mb-4'>
            {value.map((item, i) => {
                return <TankInput
                    key={i}
                    id={i}
                    data={item}
                    onChange={sub => handleChange(i, sub)}
                    onRemove={!!i ? () => handleRemove(i) : undefined}
                />
            })}
        </div>
    </div>
};

export default Storages;
