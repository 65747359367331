import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {certainTypes} from "../../../../util/varibles/constants";
import stylesContainer from '../../style.module.scss';
import {Divider, Switch} from "antd";
import styles from './style.module.scss';
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import defaultSetting from "../../../../util/varibles/defaultSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {AppState} from "../../../../util/store/store";


const mapStateToProps = (state: AppState) => {
    const {[PreferenceSetting.SHOW_CERTAINTY]: isCertainty = defaultSetting[PreferenceSetting.SHOW_CERTAINTY]} = state.login.user.setting || {};
    return {isCertainty}
}

interface IProps {
    isCertainty: boolean
}

const CertaintyOperation: React.FC<IProps> = ({isCertainty}) => {
    const dispatch = useDispatch();
    const handleChange = (isCertainty: boolean) => {
        dispatch(loginActions.updateUserSetting([{isCertainty}]));
    }

    const {options, descriptions} = certainTypes.reduce((data, item) => {
        const style = {background: item.color, '--color': item.fontColor}
        data.options.push(<div key={item.id} className={styles.certain} data-icon={item.icon} style={style}>
            {item.name}
        </div>);
        data.descriptions.push(<div key={item.id} className={styles.description}>
            <div>{item.name}</div>
            <div>- {item.description}</div>
        </div>)
        return data;
    }, {options: [], descriptions: []})

    return <>
        <div className={stylesContainer.header}>
            <div className={stylesContainer.title}>
                Set operation's certainty
            </div>
        </div>
        <div className={styles['container-certainty']}>
            <div>Operation’s certainty:</div>
            <Switch checked={isCertainty} onChange={handleChange}/>
        </div>
        <div className={styles.divider}>
            <Divider/>
        </div>
        <div className={styles.note}>
            <div data-lev='description'>
                When Operation's certainty is ON, you can mark the operation with 3 levels of certainty - None,
                Medium, High. This helps other planners to know which operation is certainty, which is not, so that
                the collaboration among planners happen easily
            </div>
            <div data-lev='options'>
                {options}
            </div>
            <div data-lev='option-detail'>
                {descriptions}
            </div>
        </div>
    </>;
};


export default connect(mapStateToProps, {})(CertaintyOperation);
