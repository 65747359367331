import React, {Component} from 'react';
import {connect} from 'react-redux';
import BiologyBrowser from './BiologyBrowser';
import Plan from './Plan';
import styles from './style.module.scss';
import WSService, {IResultSocket} from "../../web-socket/web-socket-service";
import RowResize from "./_Component/RowResize";
import {SOCKET_FUNC, SOCKET_LEV} from "../../web-socket/constants";
import {ACTION} from "../../util/varibles/permission";
import {PATHNAME} from "../../util/varibles/constants";
import {nextPage} from "../../util/library/Router";
import {planOpActions} from "./reducer";
import PopupOperation from "./Popup/EditOp";
import {AppState} from "../../util/store/store";
import {RouteService} from "../../util/services/route";
import {IRoute} from "../../util/varibles/interface";

const mapStateToProps = (state: AppState) => ({
    permission: state.login.user.permission,
});

interface IProps {
    permission: any

    initialData(): void

    getVessels(payload: any): void

    getSites(payload: any): void

    saveRoutes(rs: IRoute[]): void

    updateOpsByWs(payload: { data: any, mode: 'update' | 'remove' | 'none' }): void

    updateAvailableTime(payload: any): void

    deleteAvailableTime(payload: any): void

    updateUnitsAndHarvests(payload: { units: any, harvests: any }): void

    closePopup(): void
}

class PlanOperationPage extends Component<IProps> {
    socket: WSService | undefined;
    groupIds = [];
    abort: AbortController = new AbortController();

    constructor(props: IProps) {
        super(props);
        const {permission} = this.props;
        if (!permission[ACTION.PLAN_OP.VIEW])
            nextPage(PATHNAME.ACCESS_DENIED);
        else {
            document.body.style.overflow = 'hidden';
            this.abort = new AbortController();
            this.props.getVessels({
                abort: this.abort,
                callback: (vessels: any) => {
                    if (vessels.length > 0) {
                        this.groupIds = vessels.map((item: any) => item.vessel.id)
                        this.socket = new WSService({
                            groups: this.groupIds,
                            listener: {
                                lev: SOCKET_LEV.PAGE,
                                func: (value: IResultSocket) => this.processMessage(value)
                            }
                        });
                    }
                }
            });
            this.props.initialData();
            this.props.getSites({abort: this.abort});
            RouteService.gets()
                .then(rs => this.props.saveRoutes(rs))
                .catch(error => console.log('Get routes failed:', error))
        }
    }

    componentWillUnmount(): void {
        document.body.style.overflow = '';
        this.props.closePopup();
        if (this.socket) {
            this.socket.removeListener(SOCKET_LEV.PAGE);
            this.socket.unregisterGroups(this.groupIds)
        }
        if (this.abort)
            this.abort.abort()
    }

    processMessage = (value: IResultSocket) => {
        const data = value.message

        switch (value.function) {
            case SOCKET_FUNC.OPERATION_CONFIRMED: {
                this.props.updateOpsByWs({data, mode: 'update'})
                break;
            }
            case SOCKET_FUNC.OPERATION_DELETED:
            case SOCKET_FUNC.OPERATION_RESET: {
                this.props.updateOpsByWs({data, mode: 'remove'})
                break;
            }
            case SOCKET_FUNC.AVAILABLE_TIME_CHANGE: {
                this.props.updateAvailableTime(data)
                break;
            }
            case SOCKET_FUNC.AVAILABLE_TIME_DELETED: {
                this.props.deleteAvailableTime(data)
                break;
            }
            case SOCKET_FUNC.OPERATION_STATE_CHANGE:
            case SOCKET_FUNC.OPERATION_STATUS_CHANGE: {
                this.props.updateOpsByWs({data, mode: 'none'})
                break;
            }
            case SOCKET_FUNC.HARVEST_CHANGE: {
                this.props.getSites({abort: this.abort});
                break;
            }
            case SOCKET_FUNC.ONGOING_UPDATE: {
                const {operations = []} = value.message;
                this.props.updateOpsByWs({data: operations, mode: 'none'})
                break;
            }
            case SOCKET_FUNC.UPDATE_UNIT_HARVEST: {
                const {units = [], harvests = []} = value.message;
                this.props.updateUnitsAndHarvests({units, harvests});
                break;
            }
            default:
                return;
        }
    }

    render() {
        return <div className='m-header bgPage'>
            <div id='container-plan' className={styles['plan-operation']}>
                <BiologyBrowser/>
                <RowResize/>
                <Plan/>
            </div>
            <PopupOperation/>
        </div>;
    }
}

export default connect(mapStateToProps, {
    initialData: planOpActions.initialRequest,
    getVessels: planOpActions.getVesselsRequest,
    getSites: planOpActions.getSitesRequest,
    saveRoutes: planOpActions.saveRoutes,
    updateOpsByWs: planOpActions.updateOpsByWs,
    updateAvailableTime: planOpActions.updateAvailableTime,
    deleteAvailableTime: planOpActions.deleteAvailableTime,
    updateUnitsAndHarvests: planOpActions.updateUnitsAndHarvests,
    closePopup: planOpActions.closePopup,
})(PlanOperationPage)

