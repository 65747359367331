import React, {ReactNode} from 'react';
import Icon, {ICON_NAME} from "../Icon";
import styles from './style.module.scss';
import {IDiseaseOfSite} from "../../util/varibles/interface";

interface IProps {
    diseases?: IDiseaseOfSite[]
    count?: number
    short?: string | boolean
    wrapperClass?: string
}

const LiceList: React.FC<IProps> = ({diseases, count = 1, short, wrapperClass}) => {
    if (!Array.isArray(diseases) || diseases[0] === null)
        return null;

    const listOfLice = diseases.reduce((rs: IDiseaseOfSite[], item) => {
        if (item.lice) {
            return [...rs, item]
        }
        return rs
    }, [])

    if (listOfLice.length === 0)
        return null;

    const views = listOfLice.reduce((rs: ReactNode[], item) => {
        const {lice} = item;
        const stationary = Math.round((lice?.stationary_lice?.value || 0) * 100) / 100;
        const mobile = Math.round((lice?.mobile_lice?.value || 0) * 100) / 100;
        const female = Math.round((lice?.female_lice?.value || 0) * 100) / 100;

        return [...rs, <div key={item.site_id} className={styles.licePerLocation} data-limit={count === 1}>
            <span>{item.name}:</span>
            <span>{`${Math.round((stationary + mobile + female) * 100) / 100} (${stationary}, ${mobile}, ${female})`}</span>
        </div>]
    }, [])

    const content = <div className={styles.lice} data-short={short}>
        <Icon icon={ICON_NAME.LICE} size='large'/>
        <div className={styles.liceLabel}>
            Lice per fish (Stationary, Mobile, Adult female lice):
        </div>
        <div className={styles.liceName}>
            {views}
        </div>
    </div>

    return wrapperClass ? <div className={wrapperClass}>{content}</div> : content
};

export default LiceList;
