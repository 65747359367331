import React, {Component} from 'react';
import {Button, Result} from "antd";
import styles from "./style.module.scss";
import {PATHNAME} from "../../util/varibles/constants";
import {nextPage} from "../../util/library/Router";

class NotAuthorizedPage extends Component {
    render() {
        return <div className={[styles.wrapper, 'bgPage'].join(' ')}>
            <Result
                status="403"
                title={<div className='font-s16 font-w5'>Unauthorized</div>}
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button className='bt-primary m-auto' onClick={() => nextPage(PATHNAME.HOME)}>Back Dashboard</Button>}
            />
        </div>;
    }
}

export default NotAuthorizedPage;