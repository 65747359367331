import React, {Component} from 'react';
import {degToRadians} from "../../../../util/varibles/global";

class PieChartComponent extends Component<any, any> {
	static defaultProps = {
		colors: ['#FFAA00', '#2F8ED5'],
		size: 100,
		lineWidth: 50
	};
	private myPieChart: any = React.createRef();

	componentDidMount() {
		this.draw(this.props.data)
	};

	draw = (data: number[]) => {
		const canvas: any = this.myPieChart.current;
		const c = canvas.getContext('2d');
		const center = this.props.size / 2;
		const lineWidth = this.props.lineWidth;
		const radius = center - (lineWidth / 2);
		c.lineWidth = lineWidth;
		let startAngle = degToRadians(-90);
		const total = data.reduce((sum: number, item) => sum + item, 0);
		data.forEach((item: any, index: number) => {
			const section = item / total * 360;
			const endAngle = startAngle + degToRadians(section);
			c.strokeStyle = this.props.colors[index];
			c.beginPath();
			c.arc(center, center, radius, startAngle, endAngle);
			c.stroke();
			startAngle = endAngle;
		});
	};

	render() {
		return <canvas id="canvas" ref={ this.myPieChart } height={ this.props.size } width={ this.props.size }/>;
	}
}

export default PieChartComponent;
