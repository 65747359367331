import React from 'react';
import {IPropsChildren} from "../../util/varibles/interface";
import {OP_STATUS, OPERATION_TYPE, operationType} from "../../util/varibles/constants";
import styles from './style.module.scss';
import Icon from '../Icon';

interface IProps extends IPropsChildren {
    status: OP_STATUS
    type?: OPERATION_TYPE
    dataAttribute?: any
}

const Logo: React.FC<IProps> = ({children, status, type = '', dataAttribute}) => {
    const {icon} = operationType[type] || {};

    return <div className={styles.logo} data-status={status}>
        {icon ? <Icon icon={icon} dataAttribute={dataAttribute}/> : children}
    </div>;
};

export default Logo;