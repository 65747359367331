import React from 'react';
import modal from "../../../components/Popup/ModalCustom";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {Button} from "antd";

interface IProps {
}

const TitleRight: React.FC<IProps> = (props) => {
    const handleDelete = () => {
        modal.confirm({
            content: 'Are you sure you want to delete this operation?',
            subContent: 'Note: Delete this operation may cause duration changing on next operation (if have any) due to vessel’s start position.',
            ok: {
                click: () => {
                    // this.props.deletePlannedOperationAction(this.operation.id)
                }
            }
        });
    };

    return <Button className='mr-10' type="text" danger shape="circle" onClick={handleDelete}>
        <Icon icon={ICON_NAME.DELETE} danger/>
    </Button>
};

export default TitleRight;
