import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import ContainerElement from "./ContainerElement";
import styles from "./style.module.scss";
import {CalendarOutlined} from "@ant-design/icons/lib";

interface IProps extends IPropsElement {

}

class DateElement extends Component<IProps> {
    render() {
        const placeHolderTime = 'HH : MM'
        const {isAllowSelect, label, properties: {subLabel, separator, isShowTime, isCalendarPopup}} = this.props;
        const placeHolderDate = ['YYYY', 'MM', 'DD'].join(` ${separator} `)
        return <ContainerElement {...this.props}>
            <label data-is-empty={label.length === 0}>
                {label || 'Label'}
            </label>
            <div className={styles['control']}>
                <div className={styles['placeholder']}>
                    <div className='d-flex justify-content-between'>
                        <span>{isShowTime ? [placeHolderDate, placeHolderTime].join(', ') : placeHolderDate}</span>
                        {isCalendarPopup && <CalendarOutlined className='pt-1 pr-1'/>}
                    </div>
                </div>
                <div className={styles['sub-label']}>{subLabel || (isAllowSelect ? 'Sub label' : '')}</div>
            </div>
        </ContainerElement>;
    }
}

export default DateElement;
