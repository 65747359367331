import {all, call, put, takeEvery} from "redux-saga/effects";
import {getWeathersFetch} from "../../util/services/weather";
import {weatherActions} from "./reducer";
import {showErrorResponse} from "../../util/varibles/global";
import {getLocationsFetch} from "../../util/services/route";

function* getWeathersWatcher(): Generator {
    try {
        const weathers = yield call(getWeathersFetch);
        yield put(weatherActions.getListSuccess(weathers))
    } catch (error) {
        showErrorResponse('Get data failure', error);
        yield put(weatherActions.getListFailure());
    }
}

function* getSitesWatcher(): Generator {
    try {
        const locations: any = yield call(getLocationsFetch) || [];
        yield put(weatherActions.getLocationsSuccess(locations))

    } catch (error) {
        showErrorResponse('Get data failure', error);
        yield put(weatherActions.getListFailure());
    }
}

export default function* weatherSaga() {
    yield all([
        takeEvery(weatherActions.getListRequest.type, getWeathersWatcher),
        takeEvery(weatherActions.getListRequest.type, getSitesWatcher),
    ])
}
