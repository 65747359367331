import {colors, ICreateSeaWays, IEventElement, IPropsMap} from "./constants";


/*
* Date: 22/09/2022
* Author: Vinh.Pham
* Description: Create line
*/
export function createLine(route: any, map: google.maps.Map | null, event: IEventElement) {
    let {width, color = colors[0], points} = route;
    const path = route.points.map((point: any) => new google.maps.LatLng(point.lat, point.lng));
    const seaWay: google.maps.Polyline = new google.maps.Polyline({
        path,
        geodesic: true,
        strokeColor: "#" + color,
        strokeOpacity: 1.0,
        strokeWeight: width,
        zIndex: width === 3 ? 2 : 1
    });
    seaWay.setMap(map);
    points = points.slice(1);
    points.pop();
    Object.keys(event).forEach((key) => {
        const {[key]: action}: any = event;
        seaWay.addListener(key, action);
    })
    return {seaWay, info: route};
}

/*
* Date: 22/09/2022
* Author: Vinh.Pham
* Description: Create lines
*/
export function createLines(args: ICreateSeaWays & IPropsMap) {
    const {seaWays, map, elements, event} = args;
    clearLines(elements.polyLines);
    return seaWays.reduce((list: any, route: any) => {
        list[route.id] = {id: route.id, width: route.width, ...createLine(route, map, event)}
        return list;
    }, {});
}

/*
* Date: 22/09/2022
* Author: Vinh.Pham
* Description: Clear all line
*/
export function clearLines(polyLines: any, exceptIds: string[] = []) {
    const ids = new Set(exceptIds);
    Object.keys(polyLines).forEach(key => {
        if (!ids.has(key)) {
            const {seaWay} = polyLines[key];
            seaWay.setMap(null);
        }
    });
}
