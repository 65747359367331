import React from 'react';
import styles from "./style.module.scss";

interface IProps {
    title: any
    size?: 'small' | 'normal' | 'large' // Size of name,
    isDrag?: boolean
    className?: string

    onClose(): void
}

const Header: React.FC<IProps> = ({title, size = 'normal', isDrag = false, className, onClose}) => {
    return <div className={[styles.title, className].join(' ')} data-drag={isDrag}>
        <div className={styles.name} data-lev={size}>{title}</div>
        <div className='bt-close' onMouseDown={onClose}/>
    </div>;
};

export default Header;
