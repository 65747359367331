import React from 'react';
import {SHORT_MONTH_DATETIME_FORMAT} from "../../../util/varibles/constants";
import styles from "./style.module.scss";
import EditTime from "../EditTIme";
import {IOperation} from "../../../util/varibles/interface";
import {getStepNameOfOp} from "../../../util/varibles/global";
import {datetime} from "../../../util/library/datetime";

interface IProps {
    data: IOperation
    current_process: number
    step: number

    onUpdate?(args: any): void
}

const Time: React.FC<IProps> = ({data, current_process, step, onUpdate}) => {
    const {activity_log, operation} = data;
    const {real_start_time = 0, est_start_time = 0} = activity_log[step] || {};
    const getName = getStepNameOfOp[operation.operation_type] || getStepNameOfOp.default;
    const name = getName({current_process: step, operation, activity_log});

    return <>
        <div className={styles['operation-info-left']}>
            <div className={styles['step-name']} data-ongoing={current_process === step}>{name}</div>
            <div className={styles['planned-time']}>
                Planned: {datetime(est_start_time).format(SHORT_MONTH_DATETIME_FORMAT)}
            </div>
            {!!real_start_time && <div className={styles['actual-time']}>
                <span>Actual: {datetime(real_start_time).format(SHORT_MONTH_DATETIME_FORMAT)}</span>
                <EditTime time={real_start_time} step={step} data={data} onUpdate={onUpdate}/>
            </div>}
        </div>
    </>;
};

export default Time;