import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {convertNumberTimeToStringTime} from "../../../../util/varibles/global";
import {Tooltip} from "antd";

interface IProps {
    visible: boolean
    time: number
}

const Delay: React.FC<IProps> = ({visible, time}) => {
    if (!visible)
        return null;

    return <Tooltip title={`+${convertNumberTimeToStringTime(time)}`}>
        <div className={styles['delay']}>
            <Icon icon={ICON_NAME.ADD_DELAY}/>
        </div>
    </Tooltip>;
};

export default Delay;
