import {ReactNode} from "react";
import createFastContext from "../createFastContext";
import {IDeliveryTypeUnit, IUnit, TOperation} from "../../util/varibles/interface";

interface IState {
    op?: TOperation
    unit: IUnit
    delivery_types: {
        [key: string]: IDeliveryTypeUnit
    }
}

const {
    Provider,
    useStore: useEditSubOp,
    setStore: setEditSubOp,
    getStore: getEditSubOp,
    callEvent: callEventEditSubOp
} = createFastContext<IState>({
    state: {
        unit: {
            id: '',
            unit_id: '',
            fish_amount: 0,
            avg_weight: 0,
            total_weight: 0,
            is_waiting_unit: false,
            mortality_risk: false
        },
        delivery_types: {}
    }
});

const EditSubOpProvider = ({children, ...args}: { children: ReactNode } & Partial<IState>) => {
    return <Provider props={args}>
        {children}
    </Provider>
}


export {useEditSubOp, setEditSubOp, getEditSubOp, callEventEditSubOp, EditSubOpProvider}
