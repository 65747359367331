import React from 'react';
import {IOperation} from "../../../../util/varibles/interface";
import {OPERATION_TYPE, timeLate} from "../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {statusUnAllow} from "../../../PlanOperationPage/util";
import stylesContainer from "../../style.module.scss";
import ActivityLogs2 from "../../../../components/TrackingOp/ActivitiesLog/ActivityLogs2";

interface IProps {
    data: IOperation
    isOwn: boolean
    activity_log: any
    activity_log_root: any
}

const WrapperActivityLog: React.FC<IProps> = (props) => {
    const openDuration = () => {

    }

    const {activity_log, activity_log_root} = props;
    const {current_process, status, operation_type} = props.data.operation;

    let timeStatus: any = '', sum = 0;
    for (let i = 0; i <= current_process; i++) {
        const {est_start_time, real_start_time} = activity_log_root[i];
        sum += (est_start_time - real_start_time);
    }

    if (sum > 0 && Math.abs(sum) > timeLate) {
        timeStatus = <p className={styles['time-status']} data-status={sum > 0}>
            {Math.round(sum / 60000)} mins {sum > 0 ? 'before schedule' : 'behind schedule'}
        </p>
    }

    const startOperation = activity_log_root[0].est_start_time;
    const isFlagEdit = !statusUnAllow.has(status) && startOperation > Date.now()
        && operation_type === OPERATION_TYPE.HARVEST
        && props.isOwn;

    return <div className={stylesContainer.section}>
        <div className={stylesContainer['header-box']}>
            <div className="d-flex justify-content-between align-items-center">
                <div>Activity log</div>
                {isFlagEdit && <div className={stylesContainer['bt-edit']} onClick={openDuration}>Edit</div>}
            </div>
        </div>
        <div className={stylesContainer['body-box']}>
            {timeStatus}
            <ActivityLogs2
                current_process={current_process}
                activity_log={activity_log}
                activity_log_root={activity_log_root}/>
        </div>
    </div>;
};

export default WrapperActivityLog;

