import React from 'react';
import stylesContainer from "../../style.module.scss";
import {durationTxt, getStartFinishByOpMode} from "../../../../../util/varibles/global";
import {IActivityLog} from "../../../../../util/varibles/interface";
import {useSelector} from "react-redux";
import {selectOpMode} from "../../../../../util/store/selectors";
import Icon, {ICON_NAME} from "../../../../Icon";

interface IProps {
    activity_log: IActivityLog[]
}

const Duration: React.FC<IProps> = (props) => {
    const {activity_log} = props;
    const opMode = useSelector(selectOpMode);
    const {start, finish} = getStartFinishByOpMode[opMode](activity_log)

    return <div className={[stylesContainer['container-box'], 'mb-5'].join(' ')} data-icon={true}>
        <Icon icon={ICON_NAME.CLOCK} style={{marginTop: '4px', marginLeft: '2px'}}/>
        <div data-lev='name'>Duration:</div>
        <div className='font-w4'>{durationTxt(finish - start)}</div>
    </div>;
};

export default Duration;