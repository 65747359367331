import React, {useMemo} from 'react';
import {IVessel} from "../../../../util/varibles/interface";
import {useSelector} from "react-redux";
import {selectPermission} from "../../../../util/store/selectors";
import {ACTION} from "../../../../util/varibles/permission";
import Density from "../Density";

interface IProps {
    vessel: IVessel
}

const CombineDensity: React.FC<IProps> = ({vessel}) => {
    const permissions = useSelector(selectPermission);

    const {density_map = {}} = vessel;
    const value = useMemo(() => Object.keys(density_map || {}).reduce((rs: string[], key) => {
        const value = key.split(',');
        return value.length > 1 ? [...rs, key] : rs;
    }, []), [density_map]);

    if (!permissions[ACTION.VESSEL.UPDATE])
        return null;

    return <div className='mt-4 flex flex-col gap-2'>
        <Density
            vessel={vessel}
            title={<div className='link font-[600] ml-[-10px]'>Define combined treatments’ density</div>}
            value={density_map}
        />
        <div className='flex flex-col gap-8px mt-2'>
            {value.map(item => <Density key={item} id={item} vessel={vessel} value={density_map}/>)}
        </div>
    </div>;
};

export default CombineDensity;
