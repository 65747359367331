import React, {Component} from 'react';
import {Tooltip} from "antd";
import styles from "../../Control/LeftControl/style.module.scss";

interface IProps {
    lat: any
    lng: any
}

class Position extends Component<IProps> {
    render() {
        const {lat, lng} = this.props;
        return <div className={styles['line-info']}>
            <span>Position:</span>
            <span>
                <Tooltip title='Latitude'>{Number(lat).toFixed(6)}</Tooltip>
                <span className='mr-1'>,</span>
                <Tooltip title='Longitude'>{Number(lng).toFixed(6)}</Tooltip>
            </span>
        </div>;
    }
}

export default Position;
