import React from 'react';
import {OPERATION_TYPE} from "../../../util/varibles/constants";
import Harvest from "./Harvest";
import Transport from "./Transport";
import Treatment from "./Treatment";
import Event from "./Event";
import Service from "./Service";
import {VIEW_MODE} from "../../../util/varibles/permission";
import {IOperation, IStateGlobal} from "../../../util/varibles/interface";
import {connect} from "react-redux";
import {opDetailActions} from "../reducer";
import {messageActions} from "../../MessagePage/reducer";
import {PreferenceSetting} from "../../../util/varibles/userSetting";
import styles from './style.module.scss';
import LoadingBox from "../../../components/LoadingBox";

const mapStateToProps = (state: IStateGlobal) => {
    const {[PreferenceSetting.VIEW_MODE]: viewMode} = state.login.user.setting || {};

    return {
        data: state.operationDetail.data,
        loading: state.operationDetail.loadingOp,
        userTenantId: state.login.user.tenant_id,
        viewMode,
    }
};

interface IProps {
    data: IOperation
    loading: boolean
    viewMode: VIEW_MODE
    userTenantId: string
}

const Detail: React.FC<IProps> = (props) => {
    const { viewMode, data, loading} = props;
    const {operation_type, tenant_id} = data.operation || {};

    const common = {
        viewMode,
        data,
        isOwn: tenant_id === props.userTenantId,
    }

    let op;
    switch (operation_type) {
        case OPERATION_TYPE.HARVEST: {
            const {site, source, factory, destination} = data.operation;
            op = <Harvest {...{
                ...common,
                data: {
                    ...data,
                    operation: {...data.operation, source: site || source, destination: factory || destination}
                }
            }}/>
            break;
        }
        case OPERATION_TYPE.TRANSPORT: {
            op = <Transport {...{...common}} />
            break;
        }
        case OPERATION_TYPE.TREATMENT: {
            op = <Treatment {...{...common}}/>
            break;
        }
        case OPERATION_TYPE.EVENT: {
            op = <Event {...{...common}}/>
            break;
        }
        case OPERATION_TYPE.SERVICE: {
            op = <Service{...{...common}}/>
            break;
        }
        default:
            op = <LoadingBox loading={loading} level='page'/>
            break;
    }

    return <div className={styles.detail}>
        <div className={styles.content} data-loading={loading} data-empty={!op || !data.operation}>
            {op}
        </div>
    </div>
};

export default connect(mapStateToProps, {
    updatePosition: opDetailActions.updatePositionVessel,
    updateOp: opDetailActions.updateOp,
    getMessages: messageActions.getMessagesRequest
})(Detail)

