import React, {useCallback, useMemo, useRef, useState} from 'react';
import styles from "./style.module.scss";
import {Input, InputRef, Tag, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {patternFloat} from "../../../../../../../../util/varibles/constants";
import {formatNumber} from "../../../../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../../../../util/varibles/message";
import {IConditionOfSorting, ISorting} from "../../../../../../../../util/varibles/interface";
import {initTask} from "../../constants";
import {unloadDirectly} from "./Condition";

const indexOfOperator: { [id: string]: number } = {
    '>': 0,
    '>=': 0,
    '<': 1,
    '<=': 0
}

const getKeyCondition = (value: IConditionOfSorting['value']) => {
    const [first, last] = value;
    return [(first || []).join(''), (last || []).join('')].join('/');
}

const initCondition = (value: IConditionOfSorting['value']) => {
    return {value, middle_tank: unloadDirectly.id}
}

const initConditions = (value: [number | null, number, number | null], operator: number = 1) => {
    const newFirst: IConditionOfSorting['value'] = [value[0] ? [operator ? '>=' : '>', value[0]] : null, [operator ? '<' : '<=', value[1]]];
    const newLast: IConditionOfSorting['value'] = [[operator ? '>=' : '>', value[1]], value[2] ? [operator ? '<' : '<=', value[2]] : null];
    return {
        [getKeyCondition(newFirst)]: initCondition(newFirst),
        [getKeyCondition(newLast)]: initCondition(newLast)
    }
}

interface IProps {
    data: ISorting

    onUpdate(change: any): void
}

const ValueOfSorting: React.FC<IProps> = ({data, onUpdate}) => {
    const {conditions = {}, sub_operation, sub_tasks = {}, error} = data;
    const [{
        inputValue,
        editInputIndex,
        editInputValue
    }, setForm] = useState<{ inputValue: string, editInputIndex: number, editInputValue: string }>({
        inputValue: '',
        editInputIndex: -1,
        editInputValue: '',
    })
    const inputRef = useRef<InputRef>(null)
    const editInputRef = useRef<InputRef>(null)
    const valueOfConditions = useMemo(() => {
        const value = new Set(Object.keys(conditions).reduce((rs: number[], key: string) => {
            conditions[key].value.forEach(item => {
                const sub = (item || [])[1];
                if (sub)
                    rs.push(sub);
            })
            return rs
        }, []))
        return Array.from(value);
    }, [conditions]);

    const handleInputChange = (e: any) => {
        const valueNew = e.target.value.trim();
        const reg = new RegExp(patternFloat);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }
        setForm(prev => ({...prev, inputValue: valueNew}))
    };

    const handleEditInputChange = (e: any) => {
        const valueNew = e.target.value.trim();
        const reg = new RegExp(patternFloat);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }
        setForm(prev => ({...prev, editInputValue: valueNew}))
    };

    const update = useCallback((newConditions: { [id: string]: IConditionOfSorting }) => {
        const {conditions: errorOfConditions, ...args} = error;
        onUpdate({
            sub_tasks: Object.keys(newConditions).reduce((rs: ISorting['sub_tasks'], key) => {
                rs[key] = sub_tasks[key] || [initTask(sub_operation)];
                return rs;
            }, {}),
            conditions: newConditions,
            error: args
        })
    }, [error, sub_tasks, sub_operation, onUpdate]);

    const onDelete = (data: { [id: string]: IConditionOfSorting }, value: number): {
        [id: string]: IConditionOfSorting
    } => {
        if (Object.keys(data).length > 2) {
            const [firstKey, lastKey] = Object.keys(data).filter(key => {
                const [fist, last] = data[key].value;
                return (fist || [])[1] === value || (last || [])[1] === value;
            })
            if (firstKey && lastKey) {
                return Object.keys(data).reduce((rs: { [id: string]: IConditionOfSorting }, key) => {
                    if (key === firstKey)
                        return rs;
                    if (key === lastKey) {
                        const [first] = data[firstKey].value
                        const [, last] = data[key].value;
                        rs[getKeyCondition([first, last])] = initCondition([first, last])
                    } else
                        rs[key] = data[key];
                    return rs
                }, {})
            }
        }
        return {}
    }

    const onAdd = (data: { [id: string]: IConditionOfSorting }, newValue: number) => {
        let flag = false;
        const add = (rs: {
            [id: string]: IConditionOfSorting
        }, first: number | null, last: number | null, operator: number) => {
            flag = true;
            return {...rs, ...initConditions([first, newValue, last], operator)}
        }
        return Object.keys(data).reduce((rs: {
            [id: string]: IConditionOfSorting
        }, key) => {
            if (!flag) {
                const first = data[key].value[0] || [];
                const last = data[key].value[1] || [];
                const {[first[0] || '']: firstOperator = 1} = indexOfOperator;
                if (first[1] && first[1] > newValue) {
                    return add(rs, first[1], last[1] || null, firstOperator)
                } else if (!last[1] || last[1] > newValue) {
                    const {[last[0] || '']: operator = 1 - firstOperator} = indexOfOperator;
                    return add(rs, first[1] || null, last[1] || null, operator)
                }
            }
            rs[key] = data[key]
            return rs;
        }, {})
    }

    const handleAdd = () => {
        const text = inputValue.trim();
        if (text.length === 0 || text === '0')
            return;

        const reg = new RegExp(patternFloat);
        if (!reg.test(text)) {
            notify.error(NotifyCode.E9)();
            return;
        }

        const newValue = +text;
        if (valueOfConditions.length === 0) {
            update(initConditions([null, newValue, null]));
        } else {
            const checkValues = new Set(valueOfConditions);
            if (!checkValues.has(newValue)) {
                update(onAdd(conditions, newValue));
            } else
                notify.warn(NotifyCode.W7)()
        }

        setForm(prev => ({...prev, inputValue: ''}));
        setTimeout(() => inputRef.current?.focus(), 300);
    };


    const handleEdit = () => {
        const text = editInputValue.trim();
        if (text.length === 0 || text === '0')
            return;

        const reg = new RegExp(patternFloat);
        if (!reg.test(text)) {
            notify.error(NotifyCode.E9)();
            return;
        }
        const newValue = +text;
        const checkValues = new Set(valueOfConditions);
        if (!checkValues.has(newValue)) {
            const conditionAfterDelete = onDelete(conditions, valueOfConditions[editInputIndex]);
            update(onAdd(conditionAfterDelete, newValue));
        } else
            notify.warn(NotifyCode.W7)()


        setForm(prev => ({...prev, editInputIndex: -1, editInputValue: ''}))
    };


    return <div className={styles.tags}>
        {valueOfConditions.map((value, index: number) => {
            if (editInputIndex === index) {
                return <Input
                    ref={editInputRef}
                    key={value}
                    size="small"
                    className={styles['tag-input']}
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEdit}
                    onPressEnter={handleEdit}
                    suffix={inputValue ? 'g' :
                        <Tooltip title="Press enter or click outside the input area to save.">
                            <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                        </Tooltip>}
                />;
            }

            const isLongTag = `${value}`.length > 20;

            const tagElem = <Tag
                className={styles['edit-tag']}
                key={value}
                closable
                onClose={() => update(onDelete(conditions, value))}
            >
                <span
                    onClick={e => {
                        setForm(prev => ({...prev, editInputIndex: index, editInputValue: `${value}`}));
                        setTimeout(() => editInputRef.current?.focus(), 150)
                        e.preventDefault();
                    }}
                >
                {isLongTag ? `${`${value}`.slice(0, 20)}...` : (formatNumber(value) + 'g')}
                </span>
            </Tag>;

            return isLongTag ? <Tooltip title={value} key={index}>{tagElem}</Tooltip> : tagElem;
        })}
        <Input
            ref={inputRef}
            type="text"
            size="small"
            className={inputValue ? [styles['tag-input'], styles['tag-input-focus']].join(' ') : styles['tag-input']}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleAdd}
            onPressEnter={handleAdd}
            placeholder={inputValue ? '' : 'Set a condition '}
            suffix={inputValue ? 'g' : <Tooltip title="Press enter or click outside the input area to add.">
                <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
            </Tooltip>}
        />
    </div>;
};

export default ValueOfSorting;
