import React, {Component} from 'react';
import {durationTxt} from '../../../../util/varibles/global';
import {SHORT_DATETIME_FORMAT, stepsFull, timeLate} from '../../../../util/varibles/constants';
import styles from './style.module.scss';
import {Empty} from "antd";
import {datetime} from "../../../../util/library/datetime";

interface IProps {
    data: any
}

// Activity Log for service, harvest, event
class ActivityLogs extends Component<IProps> {
    render() {
        const {activity_log = [], operation: {operation_type, current_process}} = this.props.data;
        if (activity_log.length === 0)
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>

        const lastPoint = activity_log.length - 1;

        const activityLog = stepsFull[operation_type].map((item: any, index: number) => {

            const {est_start_time: startTime, real_start_time = 0, eta = 0} = activity_log[index];

            let state, nextPoint = '';

            if (index <= current_process) {
                state = index === current_process && index < lastPoint;
                nextPoint = `Actual: ${datetime(real_start_time).format(SHORT_DATETIME_FORMAT)}`;
            } else if (eta)
                nextPoint = `Est: ${datetime(eta).format(SHORT_DATETIME_FORMAT)}`;

            const finishTime = (index + 1) < lastPoint ? activity_log[index + 1].est_start_time : activity_log[lastPoint].est_start_time;

            const durationEst = new Date(finishTime).setSeconds(0, 0) - new Date(startTime).setSeconds(0, 0);

            const durationAct = (index + 1) < lastPoint
                ? new Date(activity_log[index + 1].real_start_time).setSeconds(0, 0) - new Date(real_start_time).setSeconds(0, 0)
                : 0

            const delayTime = real_start_time - startTime;

            return <div className={styles.step} key={index} data-is-current-step={state}>
                <div className={styles.duration}>
                    {index < current_process && <p className='font-w4'>
                        {durationTxt(durationAct)}
                    </p>}
                    <p>{durationTxt(durationEst)}</p>
                </div>
                <div className={styles.info}>
                    <p>{item.name}</p>
                    <p data-status={delayTime <= timeLate}>
                        {nextPoint}
                        {index <= current_process && Math.abs(delayTime) > timeLate ?
                            <i className='ml-1'>(diff. {Math.round(Math.abs(delayTime) / 60000)}m)</i> : ''}
                    </p>
                    <p>Planned: {datetime(startTime).format(SHORT_DATETIME_FORMAT)}</p>
                </div>
            </div>
        })
        return <div className={styles.wrapper}>{activityLog}</div>;
    }
}

export default ActivityLogs;

