import React, {useState} from 'react';
import {Button, Divider, Input, notification, Select, Space} from "antd";
import {SSO_MODE, ssoModes} from "../../../util/varibles/constants";
import {PlusOutlined} from "@ant-design/icons";
import {getElById} from "../../../util/varibles/global";
import styles from './style.module.scss';
import {IDomain} from "../../../util/varibles/interface";


const initDomain = {
    domain: '',
    provider_name: '',
    sso_mode: SSO_MODE.COGNITO
}

interface IProps {
    menu: any
    domains: IDomain[]

    onAdd(domain: IDomain): void
}

const AddDomain: React.FC<IProps> = (props) => {
    const [data, setData] = useState(initDomain);
    const {domains, menu, onAdd} = props;

    const addDomain = () => {
        const {domain, provider_name, sso_mode} = data;
        const isExist = domains.some((item: any) => item.domain === data);
        if (isExist) {
            notification['error']({
                message: 'Add failed',
                description: `${domain} already exists in the system`
            })
            return;
        }

        const error = [];

        if (domain.trim().length === 0)
            error.push(<div>Domain can't be empty</div>)

        if (provider_name.trim().length === 0)
            error.push(<div>Provider name can't be empty</div>)

        if (!sso_mode)
            error.push(<div>SSO mode can't be empty.</div>)

        if (error.length > 0) {
            notification['error']({
                message: 'Add failed',
                description: error
            })
            return;
        }

        onAdd({
            domain: domain.trim(),
            provider_name: provider_name.trim(),
            sso_mode
        });
        setData(initDomain);
    };

    return <div id='select-domain'>
        {menu}
        <Divider style={{margin: '4px 0'}}/>
        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
            <Space.Compact className={styles['new-domain']}>
                <Input
                    className='h-32'
                    placeholder='Domain'
                    value={data.domain}
                    onChange={(e: any) => setData({...data, domain: e.target.value})}
                />
                <Input
                    className='h-32'
                    placeholder='Provider name'
                    value={data.provider_name}
                    onChange={(e: any) => setData({...data, provider_name: e.target.value})}
                />
                <Select
                    placeholder='SSO mode'
                    getPopupContainer={() => getElById('select-domain')}
                    value={data.sso_mode}
                    onChange={(value) => setData({...data, sso_mode: value})}
                    options={ssoModes.map(item => ({value: item.id, label: item.name}))}
                />
                <Button className={styles['bt-add-domain']} icon={<PlusOutlined/>} onClick={addDomain}/>
            </Space.Compact>
        </div>
    </div>;
};

export default AddDomain;