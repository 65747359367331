import React, {Component} from 'react';
import {connect} from 'react-redux';
import {OPERATION_TYPE, PATHNAME, treatmentType, VESSEL_TYPE, vesselType} from "../../util/varibles/constants";
import styles from "./style.module.scss";
import Users from "./Users";
import Customers from "./Customers";
import {Modal, Table, Tabs} from "antd";
import {notify, NotifyCode} from "../../util/varibles/message";
import {ACTION} from "../../util/varibles/permission";
import {userActions} from "./reducer";
import {loginActions} from "../LoginPage/reducer";
import {nextPage} from "../../util/library/Router";
import {AppState} from "../../util/store/store";

const columnVessels = [
    {
        title: 'Name',
        key: 'name',
        render: ({id, name}: any) => <a className={styles['vessel-name']} href={`/vessel/${id}`}>{name}</a>
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 120,
        render: (type: VESSEL_TYPE) => (vesselType[type] || {}).name
    },
    {
        title: 'Operation type',
        key: 'operation_type',
        render: (data: any) => {
            let {operation_type} = data;
            let operationType = (operation_type || []).slice().sort((a: any, b: any) => a - b)
                .reduce((list: any, opType: any, key: any) => {
                    switch (opType) {
                        case OPERATION_TYPE.HARVEST: {
                            return [
                                ...list,
                                <span key={key} className={styles['operation-type']}>Harvest</span>
                            ]
                        }
                        case OPERATION_TYPE.TREATMENT: {
                            const {operation_sub_types} = data
                            return [...list, ...operation_sub_types.map((opSub: any) =>
                                <span key={key} className={styles['operation-type']}>
                            {(treatmentType[opSub] || {name: ''}).name}
                        </span>)]
                        }
                        default:
                            return list
                    }
                }, [])
            return <div className={styles['container-operation-type']}>
                {operationType}
            </div>
        }
    }
]

const columnSites = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        className: 'text-capitalize'
    },
    {
        title: 'Fish type',
        dataIndex: 'site_type',
        key: 'site_type',
        className: 'text-capitalize'
    },
]

const columnFactories = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Work time',
        dataIndex: 'work_time',
        key: 'work_time',
    },
    {
        title: 'Deliver type',
        dataIndex: 'deliver_type',
        key: 'deliver_type',
        render: (data: any) => {
            const listDeliverType = Object.keys(data)
                .reduce((list: any, key) => [...list, data[key]], [])
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
            return <div className={styles['container-operation-type']}>
                {listDeliverType.map(({id, name, speed}: any) => <span key={id} className={styles['operation-type']}>
                    {name}: <span>{speed === 0 ? '-' : `${Math.round(speed / 1000) / 1000} tons/h`}</span>
                </span>)}
            </div>
        }
    }
]

const openInfo = (key: string, list: any) => {
    let params: any = [];
    switch (key) {
        case 'vessel':
            params = [`Vessels (${list.length})`, columnVessels]
            break;
        case 'site':
            params = [`Sites (${list.length})`, columnSites]
            break;
        case 'factory' :
            params = [`Factories (${list.length})`, columnFactories]
            break;
        default:
            return null;
    }

    Modal.info({
        className: styles['modal-vessels'],
        icon: null,
        title: <div className='font-w5 font-s2'>{params[0]}</div>,
        content: <div className={styles['table-vessels']}>
            <Table
                rowKey='id'
                columns={params[1]}
                pagination={false}
                dataSource={list}
            />
        </div>,
        maskClosable: true,
        centered: true,
        okText: 'Close',
        okButtonProps: {
            className: 'bt-default'
        }
    });
}


const mapStateToProps = (state: AppState) => {
    return {
        permission: state.login.user.permission
    }
};

interface IProps {
    permission: any

    getUsers(): void

    getTenants(): void

    getDomains(): void

    getSites(): void

    getFactories(): void
}

class UsersPage extends Component<IProps> {
    tabRef: HTMLDivElement | any = React.createRef<HTMLDivElement>();

    constructor(props: IProps) {
        super(props);
        this.props.getUsers();
        this.props.getTenants();
        this.props.getDomains();
        if (!props.permission[ACTION.USER.VIEW]) {
            notify.warn(NotifyCode.W1, 'Access denied')
            nextPage(PATHNAME.HOME);
        } else {
            this.props.getSites();
            this.props.getFactories();
        }
    }

    chooseTab = (tab: string) => {
        if (this.tabRef.current) {
            this.tabRef.current.dataset.tab = tab;
        }
    }

    render() {
        const common = {openInfo};
        return <div className='m-header bgPage'>
            <Tabs
                defaultActiveKey='users'
                centered
                tabBarExtraContent={{
                    left: <div className={[styles.header, 'header-page'].join(' ')}>
                        <div className='title-page'>
                            <b>User Management</b>
                        </div>
                    </div>
                }}
                renderTabBar={(props, DefaultTabBar) => <DefaultTabBar className='h-56' {...props}/>}
                tabBarGutter={10}
                items={[
                    {
                        label: `User`,
                        key: 'users',
                        children: <Users {...common}/>
                    },
                    {
                        label: `Customer`,
                        key: 'User',
                        children: <Customers {...common}/>
                    },

                ]}
            />
        </div>;
    }
}

export default connect(mapStateToProps, {
    getUsers: userActions.getUsersRequest,
    getTenants: userActions.getTenantsRequest,
    getDomains: loginActions.getDomainsRequest,
    getSites: userActions.getSitesRequest,
    getFactories: userActions.getFactoriesRequest
})(UsersPage);
