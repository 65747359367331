import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import {IUnit} from "../../../../../../util/varibles/interface";

export enum KeyOfTask {
    ID = 'id',
    CAPABILITIES = 'capabilities',
    DURATION = 'duration',
    ERROR = 'error',
}

export interface IKeyUpdate {
    key: KeyOfTask
    value?: any
}

export interface IGenerateOp {
    store: any
    keyStore: string[]
    site: any
    properties: any
    error_detail?: any
}

export function convertUnit(data: any) {
    const {id, unit_id, site_id, site_name} = data
    return {
        id,
        unit_id,
        site_id,
        site_name,
    }
}

export function checkOp(tasks: any) {
    let isErrorGlobal = false;
    const tasksNew = tasks.map((item: any) => {
        const {id = null, error} = item;
        if (Object.keys(error).length > 0) {
            isErrorGlobal = true;
            return item;
        }

        if (id === null) {
            isErrorGlobal = true;
            return {...item, error: {empty: notify[NotifyCode.E11]()}};
        }
        const {isError, task: taskNew} = checkTask(item);
        if (isError)
            isErrorGlobal = true;
        return taskNew;
    })
    return {
        isError: isErrorGlobal,
        tasks: [...tasksNew]
    }
}

function checkTask(task: any) {

    return {isError: false, task}
}

export function addTask(tasks: any, unit: IUnit) {
    const {id: target_id} = unit;
    let index = 0;
    for (let i = 0; i < tasks.length; i++) {
        const {id} = tasks[i].sub_operation;

        if (id !== target_id)
            continue;
        index = i + 1;
    }

    const task = {
        sub_operation: convertUnit(unit),
    };
    tasks.splice(index, 0, task);
    return {tasks};
}
