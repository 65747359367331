import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../Icon";

interface IProps {
    data: any
}

const Position: React.FC<IProps> = ({data}) => {
    const {position} = data;

    return <div className={styles.wrapperInfoIcon}>
        <Icon icon={ICON_NAME.LOCATION} size='normal'/>
        {position && <div className={styles.info}>
            <div>Position:</div>
            <div>{Number(position.latitude).toFixed(6)}, {Number(position.longitude).toFixed(6)}</div>
        </div>}
    </div>;
};

export default Position;