import React, {Component} from 'react';
import styles from "./style.module.scss";
import {InputNumber, Modal, Slider, Space} from "antd";
import HarvestTable from "../../../../components/Popup/PopupOpInfo/Info/Harvest/HarvestTable";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {connect} from "react-redux";
import {IOperation, IOperations} from "../../../../util/varibles/interface";
import {planOpActions} from "../../reducer";
import {IGetActivityLog} from "../../saga";
import {createKeyActivityLog, getStandardTime, updateTime} from "../../Plan/Calendar/Operations/constants";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {IUpdateOp} from "../../constants";
import {AppState} from "../../../../util/store/store";
import Footer from "../../../../components/Popup/Component/Footer";
import {propsModal} from "../../../../util/varibles/constants";

const mapStateToProps = (state: AppState) => {
    const autoPlanConfig = getSetting(state.login.user.setting, PreferenceSetting.AUTO_PLAN);
    return {
        autoPlanConfig,
        operations: state.planOperation.operations,
    }
};

interface IProps {
    visible?: boolean
    autoPlanConfig: any
    operations: IOperations
    idTarget: string
    opDrag: IOperation

    updateOps(payload: IUpdateOp): void

    mergeOp(payload: { data: IOperation, deletedId: string }): void

    getActivityLog(payload: IGetActivityLog): void

    onOk(): void

    onClose(): void
}

class PopupMerge extends Component<IProps> {
    controllerMove = new AbortController();

    state = {
        visible: true,
        loading: false,
        sub_operations: [],
        sites: [],
        subDrag: []
    }

    constructor(props: IProps) {
        super(props);

        const {opDrag} = this.props;

        const {sub_operations: subDrag}: any = opDrag.operation;

        const {sub_operations, sites} = this.getSubOperationsAndSites(subDrag);

        this.state = {
            ...this.state,
            sub_operations,
            sites,
            subDrag: subDrag.map((item: any) => ({
                ...item,
                max_fish_amount: item.fish_amount,
                max_total_weight: Math.round(item.total_weight / 1000) / 1000
            }))
        }
    }


    componentWillUnmount() {
        this.controllerMove.abort();
    }

    handleConfirm = () => {
        const {idTarget, opDrag} = this.props;
        const opTarget: any = this.props.operations[idTarget];
        const {subDrag} = this.state;
        const isUpdate = subDrag.some((item: any) => item.fish_amount !== item.max_fish_amount);
        const operations = [];
        let success: any;
        if (isUpdate) {
            const {sub_operations: subDragRoot} = opDrag.operation;
            const {subSend, subReturn} = subDrag.reduce((rs: any, item: any, index: number) => {
                const {fish_amount, avg_weight} = item;
                delete item.max_fish_amount;
                delete item.max_total_weight;

                const delivery_types = this.getDeliveryTypes(fish_amount, index);
                rs.subSend.push({...item, delivery_types});

                const fishAmountReturn = subDragRoot[index].fish_amount - fish_amount;
                const totalWeightReturn = fishAmountReturn * avg_weight;
                const deliveryTypesReturn = this.getDeliveryTypes(fishAmountReturn, index)

                rs.subReturn.push({
                    ...item,
                    fish_amount: fishAmountReturn,
                    total_weight: totalWeightReturn,
                    delivery_types: deliveryTypesReturn
                });

                return rs;
            }, {subSend: [], subReturn: []});
            const {sub_operations: convertSubTarget, sites: sitesTarget} = this.getSubOperationsAndSites(subSend, true);
            const sitsReturn = this.getSites(subReturn);
            operations.push({
                ...opDrag,
                operation: {...opDrag.operation, sub_operations: subReturn, sites: sitsReturn},
            })
            operations.push({
                ...opTarget,
                operation: {...opTarget.operation, sub_operations: convertSubTarget, sites: sitesTarget},
            })
            success = (rs: IOperations) => {
                this.props.updateOps({update: rs});
            }
        } else {
            const {sub_operations, sites}: any = this.state;
            operations.push({...opTarget, operation: {...opTarget.operation, sub_operations, sites}})
            success = (rs: any) => {
                this.props.mergeOp({data: rs[opTarget.operation.id], deletedId: opDrag.operation.id})
            }
        }
        const properties: any = {[operations[0].operation.id]: {start: Date.now()}}
        this.props.getActivityLog({
            source: operations,
            properties,
            success: (ops: IOperations) => {
                this.setState({loading: false, visible: false});
                const list = Object.keys(ops).reduce((rs: any, id) => {
                    const key = createKeyActivityLog(ops[id].operation, ops[id].vessel);
                    const {autoPlanConfig} = this.props;
                    const newStartTime = getStandardTime({
                        autoPlanConfig,
                        vessel: opTarget.vessel,
                        activityLogNew: ops[id].activity_log,
                        activityLogOld: opTarget.activity_log
                    });
                    const activityLogNew = updateTime(ops[id].activity_log, 0, newStartTime);
                    rs[id] = {
                        ...ops[id],
                        activity_log: activityLogNew,
                        activityLogRoot: {key, activityLogNew},
                    };
                    return rs;
                }, {})

                success(list);
                this.handleClose();
            },
            failure: () => {
                this.setState({loading: false, visible: false});
            }
        })
    }

    handleClose = () => {
        this.controllerMove.abort();
        this.setState({visible: false})
        this.props.onClose();
    }

    getSubOperationsAndSites = (subDrag: any, isDeliveryType = false) => {
        const {operation} = this.props.operations[this.props.idTarget];
        const {sub_operations: subTarget} = operation;

        const subNew = subDrag.reduce((list: any, item: any) => {
            const {id, fish_amount = 0, total_weight = 0, delivery_types} = item;
            const index = list.findIndex((sub: any) => sub.id === id);
            if (index !== -1) {
                list[index].fish_amount += fish_amount;
                list[index].total_weight += total_weight;
                if (isDeliveryType) {
                    list[index].delivery_types = delivery_types.reduce((rs: any, delivery_type: any) => {
                        const {
                            id: deliveryTypeId,
                            fish_amount: subFishAmount,
                            total_weight: subTotalWeight
                        } = delivery_type;
                        const indexDeliveryType = rs.findIndex((sub: any) => sub.id === deliveryTypeId);
                        if (indexDeliveryType !== -1) {
                            rs[indexDeliveryType].fish_amount += subFishAmount;
                            rs[indexDeliveryType].total_weight += subTotalWeight;
                        } else {
                            rs.push(delivery_type)
                        }
                        return rs;
                    }, list[index].delivery_types);
                }
            } else
                list.push(item);

            return list;
        }, JSON.parse(JSON.stringify(subTarget)));

        const sites = this.getSites(subNew, operation.sites || []);

        return {sub_operations: subNew, sites}
    }

    getSites = (subNew: any, sitesOld: any = []) => {
        const {id: lastSiteId = null} = sitesOld[sitesOld.length - 1] || {};
        const {[lastSiteId]: lastSite, ...siteIds} = subNew.reduce((rs: any, item: any) => {
            const {site_id, site_name, fish_amount, total_weight} = item;
            if (rs[site_id]) {
                rs[site_id].total_amount += fish_amount;
                rs[site_id].total_weight += total_weight;
            } else
                rs[site_id] = {name: site_name, total_amount: fish_amount, total_weight}
            return rs;
        }, {});
        const sites = Object.keys(siteIds).reduce((list: any, key) => [...list, {id: key, ...siteIds[key]}], [])
        if (lastSiteId)
            sites.push({id: lastSiteId, ...lastSite})
        return sites;
    }

    handleFishAmount = (index: any, value: any) => {
        const {subDrag} = this.state;

        const {avg_weight} = subDrag[index];
        const total_weight = value * avg_weight;
        const fish_amount = value;


        const subNew = subDrag.map((item: any, i) => {
            if (i !== index)
                return item;

            return {...item, fish_amount, total_weight};
        });
        const {sub_operations, sites} = this.getSubOperationsAndSites(subNew);
        this.setState({subDrag: subNew, sub_operations, sites})
    }

    handleChangeTotalWeight = (index: any, value: any) => {
        const {subDrag} = this.state;
        const {avg_weight} = subDrag[index];
        const total_weight = value * 1000000;
        const fish_amount = Math.floor(total_weight / avg_weight);

        const subNew = subDrag.map((item: any, i) => {
            if (i !== index)
                return item;

            return {...item, fish_amount, total_weight};
        });
        const {sub_operations, sites} = this.getSubOperationsAndSites(subNew);
        this.setState({subDrag: subNew, sub_operations, sites})
    }

    getDeliveryTypes = (fish_amount: number, index: number) => {
        const {subDrag} = this.state;
        const {avg_weight} = subDrag[index];
        const {delivery_types: deliveryTypesRoot} = this.props.opDrag.operation.sub_operations[index];
        const delivery_types: any = [];
        let count = fish_amount;
        for (let i = 0; i < subDrag.length; i++) {
            const {fish_amount: subFishAmount} = deliveryTypesRoot[i];
            if (subFishAmount <= count) {
                delivery_types.push(deliveryTypesRoot[i]);
                count -= subFishAmount;
            } else {
                delivery_types.push({
                    ...deliveryTypesRoot[i],
                    fish_amount: count,
                    total_weight: count * avg_weight
                });
            }

            if (count === 0)
                break;
        }
        return delivery_types;
    }

    convertTotalWeight = (index: number) => {
        const {subDrag} = this.state;
        const {fish_amount, avg_weight} = subDrag[index];
        const total_weight = fish_amount * avg_weight;

        const subNew = subDrag.map((item: any, i) => {
            if (i !== index)
                return item;

            return {...item, total_weight};
        });
        const {sub_operations, sites} = this.getSubOperationsAndSites(subNew);
        this.setState({subDrag: subNew, sub_operations, sites})
    }

    render() {
        const {sub_operations, sites, subDrag} = this.state;
        const {visible, loading} = this.state;
        const {idTarget, opDrag} = this.props;
        const opTarget = this.props.operations[idTarget];
        const {operation_code: codeA} = opDrag.operation;
        const {operation_code: codeB} = opTarget.operation;

        const {capacity} = opTarget.vessel;
        const total_weight = sub_operations.reduce((rs: any, item: any) => rs + item.total_weight, 0);
        const sub_total = subDrag.reduce((rs: any, item: any) => rs + item.total_weight, 0);
        const isOver = total_weight > capacity;

        let message = '';
        if (isOver)
            message = notify[NotifyCode.E1]()
        if (sub_total === 0)
            message = 'No fish were transferred'

        const percent = Math.round(total_weight / capacity * 100);

        return <Modal
            {...propsModal}
            title={<div className='font-w5 font-s2'>Merge Operation</div>}
            className={styles['modal-merge']}
            maskClosable
            centered
            open={visible}
        >
            <div className={styles['container-adjust']}>
                <div className='font-w4'>Adjust fish amount transferred from #{codeA} to #{codeB}</div>
                <div className={styles['table-adjust']}>
                    <div className={styles['line-adjust']} data-row-type={'header'} key='header'>
                        <div>Site, unit</div>
                        <div className='pl-2'>Fish amount</div>
                        <div className='pl-2'>Total weight</div>
                    </div>
                    {subDrag.map((item: any, index: any) => <div className={styles['line-adjust']} key={item.id}>
                        <div>
                            {[item.site_name, item.unit_id].join(' - ')}
                        </div>
                        <div className='d-flex'>
                            <Slider
                                className='w-full'
                                min={0}
                                max={item.max_fish_amount}
                                onChange={(value: any) => this.handleFishAmount(index, value)}
                                value={item.fish_amount}
                            />
                            <InputNumber
                                min={0}
                                max={item.max_fish_amount}
                                value={item.fish_amount}
                                onChange={(value: any) => this.handleFishAmount(index, value)}
                            />
                        </div>
                        <div className='d-flex'>
                            <Slider
                                className='w-full'
                                min={0}
                                step={0.001}
                                max={item.max_total_weight}
                                value={Math.round(item.total_weight / 1000) / 1000}
                                onChange={(value: any) => this.handleChangeTotalWeight(index, value)}
                            />
                            <Space.Compact>
                                <InputNumber
                                    min={0}
                                    max={item.max_total_weight}
                                    value={Math.round(item.total_weight / 1000) / 1000}
                                    onBlur={() => this.convertTotalWeight(index)}
                                    onChange={(value: any) => this.handleChangeTotalWeight(index, value)}
                                />
                                <div className={styles.suffix}>t</div>
                            </Space.Compact>
                        </div>
                    </div>)}
                </div>
            </div>

            <div className='font-w4 mb-10'>Operation information after merge</div>
            <div className={styles['table-sub-operations']}>
                <div>
                    Capacity: {Math.round(total_weight / 1000) / 1000}t / {Math.round(capacity / 1000) / 1000}t
                    ({isOver ? <>{percent}%)</>
                    : <span className={styles['error']}>{notify[NotifyCode.E1]()} {percent}%</span>})
                </div>
                <HarvestTable {...{operation: {...opTarget.operation, sub_operations, sites}, isDeliveryType: false}}/>
            </div>
            <Footer
                ok={{text: 'Confirm', disabled: sub_total === 0 || isOver, loading, title: message, click: this.handleConfirm}}
                cancel={{click: this.handleClose}}
            />
        </Modal>;
    }
}

export default connect(mapStateToProps, {
    updateOps: planOpActions.updateOps,
    mergeOp: planOpActions.mergeOp,
    getActivityLog: planOpActions.getActivityLog
})(PopupMerge);
