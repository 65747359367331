import React from 'react';
import styles from './style.module.scss';
import {FULL_DATE_FORMAT, MONTH_DATE_FORMAT} from "../../../util/varibles/constants";
import Activity from "../Activity";
import {datetime} from "../../../util/library/datetime";

interface IProps {
    weekNo: string
    finish?: number
}

const Empty: React.FC<IProps> = ({weekNo, finish}) => {
    const monday = datetime().set({day: 1, week: +weekNo});
    return <div className={styles.day}>
        <div className={styles.time}>
            <div className={styles.dayName}>
                <span>{monday.format(MONTH_DATE_FORMAT)}</span>
                <span className='arrow-right'>&#8594;</span>
                <span>{!!finish
                    ? datetime(finish).format(FULL_DATE_FORMAT)
                    : datetime().set({day: 0, week: +weekNo}).format(MONTH_DATE_FORMAT)}</span>
            </div>
            <span className={styles.weekNo}>WEEK {weekNo}</span>
        </div>
        <div>
            <Activity/>
        </div>
    </div>;
};

export default Empty;
