import React from 'react';
import styles from "./style.module.scss";
import {AVAILABLE_TIME_TYPE, vesselTypes} from "../../../../../util/varibles/constants";
import {Tooltip} from "antd";
import {IVessel} from "../../../../../util/varibles/interface";
import Icon, {ICON_NAME} from "../../../../../components/Icon";

interface IProps {
    vessel: IVessel
}

const VesselName: React.FC<IProps> = (props) => {
    const {id, name, type, isOwn, permission, available_time_type} = props.vessel
    const vesselType = vesselTypes[type - 1] ? vesselTypes[type - 1].shortName : '';
    const title = `${name} - ${vesselType}`;

    return <div className={styles.vesselInfo} data-vessel-id={id}>
        <div className={styles.vesselName}>
            <span className={styles.name}>{title}</span>
            {available_time_type === AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME &&
                <Tooltip title='Use available time only is on'>
                    <div className='mb-[-3px]'>
                        <Icon icon={ICON_NAME.LOCK}/>
                    </div>
                </Tooltip>}
            {(!isOwn && !permission) && <span className={styles.spot}>Spot</span>}
        </div>
    </div>;
};

export default VesselName;