import React, {Component} from 'react';
import styles from "../style.module.scss";
import {SHORT_DATETIME_FORMAT, STATE_TRANSPORT} from "../../../../../util/varibles/constants";
import {Divider} from "antd";
import {IPropsSearchItem} from "../constants";
import {SwapRightOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import {datetime} from "../../../../../util/library/datetime";

interface IProps extends IPropsSearchItem {
}

class Transport extends Component<IProps> {
    render() {
        const {
            operation_code,
            source_name,
            destination_name,
            vessel_name,
            current_process,
            status,
            tenant_id,
            est_start_time,
            est_finish_time,
            id
        } = this.props.data;

        const statusTxt = status === "processing" ? STATE_TRANSPORT[current_process] : status;
        return <div className={styles['item-result']}>
            <NavLink to={`/operation/${tenant_id}/${id}`}>
                <div className={styles['line-info-item']}>
                    <div className={styles['icon-transport']}/>
                    <span className='ml-2 mr-3'>{'#' + operation_code}</span>
                    <span>{source_name + ' - ' + destination_name}</span>
                </div>
                <div className={styles['line-info-item']}>
                    <span>{vessel_name}</span>
                    <Divider className={styles['divider-line']} type="vertical"/>
                    <div className={styles['operation-type']}>Transport</div>
                    <div className={[styles['operation-status'], 'ml-2'].join(' ')}>{statusTxt}</div>
                </div>
                <div className={styles['line-info-item']}>
                    {datetime(est_start_time).format(SHORT_DATETIME_FORMAT)}
                    <SwapRightOutlined className='ml-2 mr-2 font-s3'/>
                    {datetime(est_finish_time).format(SHORT_DATETIME_FORMAT)}
                </div>
            </NavLink>
        </div>
    }
}

export default Transport;
