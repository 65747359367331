import React from 'react';
import {IChecklist} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import stylesRight from '../RightSide/style.module.scss';
import HeaderElement from "../../Element/HeaderElement";
import ShortTextElement from "../../Element/ShortTextElement";
import {ELEMENT_BASIC, ELEMENT_DEFINED, ELEMENT_PAGE} from "../../Element/constants";
import LongTextElement from "../../Element/LongTextElement";
import NumberElement from "../../Element/NumberElement";
import DropdownElement from "../../Element/DropdownElement";
import RadioElement from "../../Element/RadioElement";
import CheckboxElement from "../../Element/CheckboxElement";
import DateElement from "../../Element/DateElement";
import TimeElement from "../../Element/TimeElement";
import FormEmpty from "./FormEmpty";
import SignElement from "../../Element/SignElement";
import Divide from "../../Element/Divide";
import {IElement} from "../../constants";
import Footer from "../../Element/Footer";
import Tags from "../../Element/Tags";


interface IProps {
    checklist: IChecklist
    selected: any

    onDelete(index: number): void

    onSelect(value?: any): void

    setChecklist(checklist: IChecklist): void
}

const MiddleSide: React.FC<IProps> = (props) => {
    const {checklist, selected, onDelete, onSelect, setChecklist} = props;
    const renderElement = (content: string) => {
        if (content.length > 0) {
            const data = JSON.parse(content || '')
            return data.map((element: IElement, index: number) => {
                const {type} = element;
                let props = {
                    index,
                    isAllowSelect: true,
                    ...element,
                    selected,
                    onDelete,
                    onSelect
                }
                switch (type) {
                    case ELEMENT_BASIC.HEADER: {
                        return <HeaderElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.SHORT_TEXT: {
                        return <ShortTextElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.LONG_TEXT: {
                        return <LongTextElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.NUMBER: {
                        return <NumberElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.DROPDOWN: {
                        return <DropdownElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.RADIO: {
                        return <RadioElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.CHECKBOX: {
                        return <CheckboxElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.DATE: {
                        return <DateElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.TIME: {
                        return <TimeElement {...props} key={index}/>
                    }
                    case ELEMENT_PAGE.DIVIDE: {
                        return <Divide {...props} key={index}/>
                    }
                    case ELEMENT_DEFINED.SIGN: {
                        return <SignElement {...props} key={index}/>
                    }
                    default:
                        return null
                }
            })
        } else
            return <FormEmpty/>
    }

    const handleClick = (e: React.MouseEvent) => {
        const {target}: any = e;
        if (!target.closest(`.${styles['form']}`)) {
            const elementFocus = document.activeElement;
            if (!elementFocus || !elementFocus.closest(`.${stylesRight['right-side']}`))
                onSelect()
        }
    }

    const {content = ''} = checklist || {}
    return <div className={styles['middle-side']} onMouseDown={handleClick}>
        <div className={styles['form']}>
            <div className={styles['header']}>
                <Tags
                    onSave={setChecklist}
                    checklist={checklist}
                />
            </div>
            {renderElement(content)}
            <Footer onSave={setChecklist} checklist={checklist}/>
        </div>
    </div>;
};

export default MiddleSide;
