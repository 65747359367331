import React from 'react';
import {Tooltip} from "antd";
import styles from "./style.module.scss";
import {openNote} from "../../../../../../components/Popup/PopupOpNote";
import Icon, {ICON_NAME} from "../../../../../../components/Icon";

const propsNote = {
    align: {offset: [0, 6]},
    mouseEnterDelay: 0.03
}

interface IProps {
    value: string
    title?: string
    isTooltip?: boolean
    isBubble?: boolean
    isLabel?: boolean

    onChange(value: string): void
}

const AddNote: React.FC<IProps> = ({title = '', value, isLabel = true, isBubble = true, isTooltip = false, onChange}) => {
    const handleOpenNote = (note: string = '') => {
        openNote({title, note, onSave: onChange})
    }

    const isNote = (value || '').trim().length > 0;
    const content = <div
        className='bt-ghost'
        data-icon='true'
        data-is-note={isNote}
        title={isNote ? "" : "Click to add notes for " + title}
        onClick={() => handleOpenNote(value)}
    >
        <div className='flex items-center px-[8px]'>
            {isLabel && <div>Add note</div>}
            <Icon className={styles.note} icon={ICON_NAME.NOTE}/>
            {isBubble && <div className={styles.noted} data-is-note={isNote}/>}
        </div>
    </div>;

    if (!isTooltip)
        return content

    return <Tooltip {...propsNote} title={isNote ? `Note: ${value}` : ''}>
        {content}
    </Tooltip>;
};

export default AddNote;
