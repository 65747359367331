import React, {useState} from 'react';
import {INotification} from "../../../util/varibles/interface";
import styles from './style.module.scss'
import Icon, {ICON_NAME} from "../../Icon";
import {NotificationType, operationType, SHORT_MONTH_DATETIME_FORMAT} from "../../../util/varibles/constants";
import {datetime} from "../../../util/library/datetime";

const getIcon = (type: string = '') => {
    const [targetType, subType] = type.split('#');
    switch (targetType) {
        case NotificationType.Operation: {
            const {icon} = operationType[subType] || {};
            return {icon, type: NotificationType.Operation}
        }
        case NotificationType.Contract: {
            return {icon: ICON_NAME.VESSEL, type: NotificationType.Contract}
        }
        default:
            return {icon: null, type: undefined}
    }
}

interface IProps {
    data: INotification

    onClick(data: INotification): void
}

const ViewNotification: React.FC<IProps> = ({data, onClick}) => {
    const [{icon, type}] = useState(getIcon(data.type));

    return <div className={styles.notification} onClick={() => onClick({...data, notificationType: type})}>
        <div className={styles.icon}>
            <Icon icon={icon}/>
        </div>
        <div className={styles.content}>
            <div className={styles.message}>
                {data.message}
            </div>
            <div className={styles.time}>
                {datetime(data.timestamp).format(SHORT_MONTH_DATETIME_FORMAT)}
            </div>
        </div>
        {!data.read && <div className={styles.read}/>}
    </div>;
};

export default ViewNotification;
