import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty, onChangeRequired} from "./constants";
import styles from "./style.module.scss";
import {Select} from "antd";

interface IProps extends IPropsPropertyElement {

}

class TimeElement extends Component<IProps> {
    render() {
        const {selected} = this.props;
        if (!selected) return null;
        const {Option} = Select;
        const {label, properties: {required, subLabel, isTimeRange, minuteStep = 1}} = selected;
        return <>
            <div className={styles['title']}>Time picker Properties</div>
            <div className={styles['property-element']}>
                <label>Label</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
                <span>Type a text field label.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Required</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('required')}>
                        <input id='required' type="checkbox" checked={required}
                               onChange={() => onChangeProperty({required: !required}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Minute stepping</label>
                <div className="d-flex">
                    <Select defaultValue={minuteStep} style={{width: 120}}
                            onChange={(value: any) => onChangeProperty({minuteStep: value}, this.props)}
                    >
                        <Option value={1}>1</Option>
                        <Option value={5}>5</Option>
                        <Option value={10}>10</Option>
                        <Option value={15}>15</Option>
                        <Option value={30}>30</Option>
                    </Select>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Time range</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('time-range')}>
                        <input id='time-range' type="checkbox" checked={isTimeRange}
                               onChange={() => onChangeProperty({isTimeRange: !isTimeRange}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Sub label</label>
                <input type="text" value={subLabel}
                       onChange={(e) => onChangeProperty({subLabel: e.currentTarget.value}, this.props)}
                />
                <span>Add a small description below the input field.</span>
            </div>
        </>;
    }
}

export default TimeElement;
