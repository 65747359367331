import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './style.module.scss';
import {VESSEL_TYPE, vesselType} from "../../../../util/varibles/constants";
import {updateUserSetting} from "../../../LoginPage/reducer";
import LoadingBox from "../../../../components/LoadingBox";
import {selectDefaultVessel} from "../../../../util/store/selectors";
import {VesselService} from "../../../../util/services/vessel";
import {showErrorResponse} from "../../../../util/varibles/global";
import {IVessel} from "../../../../util/varibles/interface";

let abort: AbortController;

const Vessels: React.FC = () => {
    const dispatch = useDispatch();
    const [{vessels, loading}, setVessels] = useState<{ vessels: IVessel[], loading: boolean }>({
        vessels: [],
        loading: false
    })
    const default_vessel = useSelector(selectDefaultVessel);

    useEffect(() => {
        abort = new AbortController();
        setVessels(prev => ({...prev, loading: true}))
        VesselService.vesselByTenantId(abort)
            .then(vessels => setVessels({vessels, loading: false}))
            .catch(err => {
                setVessels(prev => ({...prev, loading: false}))
                showErrorResponse('Get data failed', err)
            })
        return () => abort.abort()
    }, [])

    const handleClick = useCallback((vesselId: string) => {
        const value = {default_vessel: vesselId};
        dispatch(updateUserSetting([{}, value]));
    }, [dispatch])

    return <div className={styles.vessels}>
        {vessels.length === 0 ? <div className='w-[180px] flex items-center justify-center'>
            <LoadingBox loading={loading}/>
        </div> : vessels.map((item: any) => {
            const {name: type = ''} = vesselType[item.type] || {};
            return <div
                className={[styles.vessel, styles.checkboxFill].join(' ')}
                key={item.id}
                data-actived={item.id === default_vessel}
                onClick={() => handleClick(item.id)}
            >
                <div>
                    <b>{item.name}</b>
                    <span>{item.type !== VESSEL_TYPE.SERVICE ? ` (${Math.round(item.capacity / 1000000)}t)` : ''}</span>
                </div>
                <div>{type}</div>
            </div>
        })}
    </div>;
};

export default Vessels;

