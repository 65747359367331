import React, {useCallback, useState} from 'react';
import styles from "./style.module.scss";
import {Checkbox, Modal, Tooltip} from "antd";
import {propsModal} from "../../../../../../util/varibles/constants";
import Header from "../../../../../../components/Popup/Component/Header";
import {convertUnit} from "../../Body/Treatment/constants";
import {ISite, TTaskTreatment} from '../../../../../../util/varibles/interface';
import Footer from "../../../../../../components/Popup/Component/Footer";

interface IProps {
    sites: ISite[]
    unit: TTaskTreatment['sub_operation']
    isShow: boolean
    isEmpty?: boolean

    onAdd(units: {
        id: string
        unit_id: string
        site_id: string
        site_name: string
    }[]): void

    onClose?(): void
}

const PopupDuplicate: React.FC<IProps> = (props) => {
    const {sites, unit, isShow, isEmpty, onAdd, onClose} = props;
    const [visible, setVisible] = useState(isShow);
    const [selected, setSelected] = useState([]);

    const handleAdd = () => {
        const unitIds: any = new Set(selected);
        const data = sites.reduce((list: any, site) => {
            site.units.forEach(unit => {
                if (unitIds.has(unit.id))
                    list.push(convertUnit(unit))
            })
            return list;
        }, [])
        onAdd(data);
        handleClose();
    }

    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            setTimeout(onClose, 300);
    }, [onClose]);

    const handleChange = (value: any) => {
        setSelected(value);
    }

    return <Modal
        {...propsModal}
        className={styles.modal}
        title={<Header title='Replicate tasks to other units' onClose={handleClose}/>}
        open={visible}
        mask={false}
        okText='Replicate'
        onCancel={handleClose}
    >
        <div className={styles.line}>
            <span>Source:</span>
            <b>{unit.unit_id}</b>
        </div>
        <div className={styles.line}>
            <span>Destination:</span>
        </div>
        <Checkbox.Group
            className={[styles.checkbox, 'checkbox-fill'].join(' ')}
            value={selected}
            onChange={handleChange}
        >
            {sites.reduce((list: any, item) => {
                const {units} = item;
                return [...list, ...units.reduce((rs: any, sub) => {
                    if (sub.id !== unit.id) {
                        const disabled = sub.avg_weight > 0 && sub.fish_amount === 0 && !isEmpty;
                        return [...rs, <Checkbox key={sub.id} disabled={disabled} value={sub.id.toString()}>
                            {disabled ? <Tooltip title='No fish'>{sub.unit_id}</Tooltip> : sub.unit_id}
                        </Checkbox>]
                    }
                    return rs
                }, [])]
            }, [])}
        </Checkbox.Group>
        <Footer
            ok={{
                disabled: selected.length === 0,
                click: handleAdd
            }}
            cancel={{
                click: handleClose
            }}
        />
    </Modal>;
};

export default PopupDuplicate;
