import React, {Component} from 'react';
import {connect} from "react-redux";
import styles from './style.module.scss'
import {TAB_OPERATION_DETAIL} from "./constants";
import WSService from "../../web-socket/web-socket-service";
import {SOCKET_FUNC, SOCKET_LEV} from "../../web-socket/constants";
import {MessageState} from "../MessagePage/constants";
import {PreferenceSetting} from "../../util/varibles/userSetting";
import {addRouterListener, getParamsFromUrl, getSearchFromUrl} from "../../util/library/Router";
import {opDetailActions} from "./reducer";
import {messageActions} from "../MessagePage/reducer";
import Detail from "./Detail";
import Chat from "./Chat";
import {Tabs} from "antd";
import Title from "./Title";
import TitleRight from "./Title/TitleRight";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {[PreferenceSetting.VIEW_MODE]: viewMode} = state.login.user.setting || {};

    return {
        keyTab: state.operationDetail.keyTab,
        message: state.message,
        viewMode,
        roles: state.login.user.roles,
        permission: state.login.user.permission,
    }
};

interface IProps {
    keyTab: TAB_OPERATION_DETAIL
    message: MessageState
    permission: any

    getOperation(payload: any): void

    updatePosition(payload: any): any

    updateOp(payload: any): void

    getMessages(payload: any): void
}

class OperationDetailPage extends Component<IProps> {
    rootRef: HTMLDivElement | any = React.createRef<HTMLDivElement>();
    operation = {
        tenant_id: '',
        id: '',
    };
    isOpenSocket = false;
    socket: WSService | undefined;
    groups: any = [];
    listen: any;

    constructor(props: IProps) {
        super(props);
        this.getOperation();

        this.listen = addRouterListener((state) => {
            const {pathname} = state.location;
            const index = pathname.indexOf('/operation');
            if (index === 0) {
                if (this.socket) {
                    this.socket.removeListener(SOCKET_LEV.PAGE);
                    this.socket.unregisterGroups(this.groups)
                }
                this.getOperation();
            }
        })
    }

    getOperation = () => {
        const {tenant_id = '', id = ''} = getParamsFromUrl();
        this.operation = {tenant_id, id};
        const [tab] = getSearchFromUrl();
        this.isOpenSocket = false;

        this.props.getOperation({
            ...this.operation,
            tab: tab === TAB_OPERATION_DETAIL.MESSAGE ? TAB_OPERATION_DETAIL.MESSAGE : TAB_OPERATION_DETAIL.INFO
        });

        const {messages} = this.props.message;
        if (!messages[id]) {
            this.props.getMessages({id, index: 1});
        }
    }

    processMessage = (value: any) => {

        switch (value.function) {
            case SOCKET_FUNC.VESSEL_GPS:
                this.props.updatePosition(value)
                break;
            case SOCKET_FUNC.OPERATION_CONFIRMED:
            case SOCKET_FUNC.OPERATION_STATE_CHANGE:
            case SOCKET_FUNC.OPERATION_STATUS_CHANGE:
                this.props.updateOp(value.message);
                break;
            default:
                return;
        }
    }

    registerWebSocket = () => {
        if (!this.isOpenSocket) {
            let {id} = this.operation;
            this.groups = [id];
            this.socket = new WSService({
                groups: this.groups,
                listener: {
                    lev: SOCKET_LEV.PAGE,
                    func: (value: any) => this.processMessage(value)
                }
            });
            this.isOpenSocket = true;
        }
    }

    componentDidMount(): void {
        if (!this.socket || this.socket.getGroups().length === 0) {
            setTimeout(() => {
                this.registerWebSocket()
            }, 2000)
        }
    }

    componentWillUnmount(): void {
        this.handleClose();
        if (this.listen)
            this.listen();
    }

    handleClose = () => {
        if (this.socket) {
            this.socket.removeListener(SOCKET_LEV.PAGE);
            this.socket.unregisterGroups(this.groups)
        }
    }


    render() {
        const {keyTab} = this.props;

        return <div ref={this.rootRef} className={[styles.page, 'bgPage'].join(' ')} data-tab={keyTab}>
            <Tabs
                defaultActiveKey={keyTab}
                centered
                tabBarExtraContent={{
                    left: <Title/>,
                    right: <TitleRight/>
                }}
                tabBarGutter={10}
                renderTabBar={(props, DefaultTabBar) => <DefaultTabBar className='h-56 mb-0' {...props}/>}
                items={[
                    {
                        label: `Operation info`,
                        key: 'info',
                        children: <Detail/>
                    },
                    {
                        label: `Message`,
                        key: 'message',
                        children: <Chat id={this.operation.id}/>
                    }
                ]}
            />
        </div>
    }
}

export default connect(mapStateToProps, {
    getOperation: opDetailActions.getOpRequest,
    updatePosition: opDetailActions.updatePositionVessel,
    updateOp: opDetailActions.updateOp,
    getMessages: messageActions.getMessagesRequest
})(OperationDetailPage)
