import React, {Component} from 'react';
import WrapperMessage from "./WrapperMessage";
import styles from './style.module.scss';
import TrackingOp from "../../components/TrackingOp";

class MessagePage extends Component {
    render() {
        return <div className={[styles.wrapper, 'm-header bgPage'].join(' ')}>
            <WrapperMessage/>
            <TrackingOp/>
        </div>;
    }
}


export default MessagePage;
