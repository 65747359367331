import React from 'react';
import {Input} from "antd";
import styles from "./style.module.scss";
import {SearchOutlined} from "@ant-design/icons";

interface IProps {
    search(args: any): void
}

const Search: React.FC<IProps> = ({search}) => {
    const handleSearch = (e: any) => {
        const {value} = e.target;
        if (search)
            search({searchValue: value})
    }

    return <Input
        className={[styles.search].join(' ')}
        placeholder="Search harvest ID, site, packing station"
        allowClear
        onChange={handleSearch}
        suffix={<SearchOutlined className='text-comment'/>}
    />;
};

export default Search;
