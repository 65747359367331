import {api} from "../api/callApi";
import {urlUser} from "../api/v1";
import {ITenant} from "../varibles/interface";

interface IUpdateUserSettingFetch {
    id: string
    setting?: any
    default_vessel?: string
}

export const AccountService = {
    tenant: () => api.get<ITenant[]>(urlUser + '/tenant'),
    update: (user: any) => api.put(urlUser, user),
    info: () => api.get(urlUser),
    domain: () => api.get(urlUser + '/domain', {isAuthentication: false}),
    updateSetting: (setting: IUpdateUserSettingFetch) => api.put(urlUser + '/setting', setting)
}

// export function getWidgetByUserAndVesselFetch(userId: string, vesselId: string) {
//     return callApi(`${urlUserWidgets}/${userId}/${vesselId}`, {
//         method: 'GET'
//     })
// }
//
// export function updateWidgetByUserAndVesselFetch(body: { user_id: string, vessel_id: string, dashboard: string }) {
//     return callApi(urlUserWidgets, {
//         method: 'POST',
//         body: JSON.stringify(body)
//     })
// }