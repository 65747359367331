import React from 'react';
import styles from "./style.module.scss";
import {IUnit} from "../../../util/varibles/interface";

interface IProps {
    unit?: IUnit,
}

const UnitNote: React.FC<IProps> = ({unit}) => {
    if (!unit)
        return null;
    const {note = ''} = unit;

    if (note.length === 0)
        return null;

    return <div className={styles['unit-note']}>
        <div className={styles['unit-note-icon']}/>
        <div className={styles['operation-info-title']}>
            Unit's note: <span className={styles['operation-info-content']}>{note}</span>
        </div>
    </div>

}
export default UnitNote;