import React, {useEffect, useState} from 'react';
import {KEY_TAB, OPERATION_TYPE, SITE_TYPE} from "../../../../util/varibles/constants";
import HeaderUnits from "./HeaderUnits";
import {UnitModel} from "../constants";
import stylesContainer from '../style.module.scss';
import {ISite, IUnit} from "../../../../util/varibles/interface";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import Site from "../../Popup/Site";
import {useSelector} from "react-redux";
import {selectSetting} from "../../../../util/store/selectors";
import {setTabBiology} from "../../../../contexts/PlanOperationContext/TabBiologyContext";
import {setBiology} from "../../../../contexts/PlanOperationContext/Biology";
import Cards from '../_Component/Cards1';
import Card from "../_Component/Cards1/Card";
import FishAndWeight from "../_Component/FishAndWeight";
import UnitLice from "../../../../components/Operation/UnitLice";
import Location from "../_Component/Location";
import FishDisease from '../../../../components/Location/FishDisease';
import UnitDiseases from '../_Component/UnitDiseases';

interface IState {
    isGroup: boolean
    siteType: string
    searchValue: string
    weightFilter: [number | null, number | null],
    isShowAll: boolean
}

const initState: IState = {
    isGroup: false,
    siteType: '',
    searchValue: '',
    weightFilter: [null, null],
    isShowAll: false,
}

interface IProps {
    loading: boolean
    sites: ISite[]
}

const Units: React.FC<IProps> = (props) => {

    const {
        [PlanSetting.BIOLOGY_SITE]: biologySiteFilter = [],
        [PlanSetting.BIOLOGY_PROD_AREA]: biologyProdAreaFilter = [],
        [PlanSetting.BIOLOGY_LICE_SORT]: liceSort = null,
    } = useSelector(selectSetting([PlanSetting.BIOLOGY_SITE, PlanSetting.BIOLOGY_PROD_AREA, PlanSetting.BIOLOGY_LICE_SORT]), (oldValue, newValue) => oldValue === newValue)
    const [{siteType, searchValue, weightFilter}, setForm] = useState(initState);
    const {sites, loading} = props;
    const set = setBiology();
    const setTab = setTabBiology();

    useEffect(() => {
        set(({clear}) => ({
            clear: {...clear, unit: () => setTab({selected: {}})}
        }))
    }, [set, setTab]);

    const [minWeight, maxWeight] = weightFilter;
    const listOfSiteFilter = new Set(biologySiteFilter);
    const listOfProdAreaFilter = new Set(biologyProdAreaFilter);
    const countSite = biologySiteFilter.length;
    const countProdArea = biologyProdAreaFilter.length;
    const units = sites
        .reduce((list: IUnit[], site) => {
            const isSite = (countSite === 0 || listOfSiteFilter.has(site.id))
                && (countProdArea === 0 || listOfProdAreaFilter.has(site.prod_area));
            const isSearch = site.name.toLowerCase().indexOf((searchValue || '').toLowerCase()) !== -1;
            const isType = site.type?.indexOf(siteType) !== -1;
            if (!isSite || !isSearch || !isType)
                return list;

            return [
                ...list,
                ...site.units.reduce((items: any, unit: IUnit) => {
                    const {avg_weight, fish_amount} = unit;
                    const isMin = minWeight ? (minWeight <= avg_weight && fish_amount) : true;
                    const isMax = maxWeight ? (maxWeight >= avg_weight && fish_amount) : true;

                    if (!isMin || !isMax)
                        return items;
                    return [...items, UnitModel(unit, site)]
                }, [])]
        }, [])
    if (liceSort) {
        units.sort((a, b) => {
            const {lice: liceA = {}}: any = a;
            const {lice: liceB = {}}: any = b;
            const {value: valueA = 0} = liceA[liceSort] || {}
            const {value: valueB = 0} = liceB[liceSort] || {}
            return valueB - valueA
        })
    }

    const handleOpenSite = (unit: IUnit) => {
        const el = openPopup(<Site
            site={{id: unit.site_id || '', name: unit.site_name || ''}}
            unitId={unit.id}
            onClose={() => el.remove()}
        />)
    }

    return <div id={KEY_TAB.PRODUCTION} className={stylesContainer['wrapper-tab']}>
        <HeaderUnits {...{
            sites,
            siteType,
            searchValue,
            weightFilter,
            liceSort,
            onSearch: value => setForm(prev => ({...prev, ...value})),
        }}/>
        <div className={stylesContainer['tab-body']}>
            <Cards<IUnit>
                data={units}
                loading={loading}
                child={{
                    height: 80,
                    View: ({data}) => {
                        const {unit_id, site_name = '', fish_amount, type, diseases = [], lice} = data;
                        const isInternal = type === SITE_TYPE.INTERNAL;
                        const isActive = !(isInternal && fish_amount <= 0);

                        return <Card
                            data={data}
                            isActive={isActive}
                            key={data.id}
                            style={{height: '80px', maxHeight: '80px'}}
                            types={[OPERATION_TYPE.HARVEST, OPERATION_TYPE.TREATMENT, OPERATION_TYPE.SERVICE]}
                            onContext={() => handleOpenSite(data)}
                        >
                            <div className={stylesContainer.line}>
                                <div className={stylesContainer.infoLine}>
                                    <Location source={[site_name, `, ${unit_id}`]}/>
                                    {isInternal && <FishAndWeight data={data}/>}
                                </div>
                                <div className={stylesContainer.infoLine} data-right={true}>
                                    <UnitLice lice={lice}/>
                                    <FishDisease diseases={diseases} units={[data]} short={true}/>
                                    <UnitDiseases data={data.unit_diseases}/>
                                </div>
                            </div>
                        </Card>
                    }
                }}
            />
        </div>
    </div>
};

export default Units;
