import PopupAddDelay from "../../../Popup/AddDelay";
import React from "react";
import {notification} from "antd";
import {SupportType} from "../../../constants";
import {OP_STATUS, OPERATION_CONFIRM_STATUS} from "../../../../../util/varibles/constants";
import {deleteOperationFetch, updateOperationStatusFetch} from "../../../../../util/services/operation";
import {IOperation, IOperations} from "../../../../../util/varibles/interface";
import {openPopup} from "../../../../../components/Popup/Component/WrapperPopup";
import {VALIDATION_STATUS} from "../../../util/validation";
import modal from "../../../../../components/Popup/ModalCustom";
import {Dispatch} from "@reduxjs/toolkit";
import {planOpActions} from "../../../reducer";
import {changeLoadingOp, downloadBlob, getElById, showErrorResponse} from "../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import Icon from "../../../../../components/Icon";
import PopupDuplicate from "../../../Popup/Duplicate";
import PopupEditDuration from "../../../../../components/Popup/PopupEditDuration";
import PopupSendMail from "../../../Popup/SendMail";
import {GlobalService} from "../../../../../util/services/global";
import {Storage} from "aws-amplify";

export const handleAddDelay = (data: any, dispatch: Dispatch) => {
    const opInfoEl = openPopup(<PopupAddDelay
        visible={true}
        data={data}
        onClose={() => setTimeout(() => opInfoEl.remove(), 200)}
        onSave={(payload: any) => dispatch(planOpActions.updateOpsByWs(payload))}
    />);
}

export const handleSendToVessel = (data: any, operations: IOperations, dispatch: Dispatch) => {
    let sendList = [];
    if (data.status === VALIDATION_STATUS.ERROR) {
        notification['error']({
            message: 'Error',
            description: `#${data.operation.operation_code}: ${data.message}`
        })
    } else {
        const {support: targetSupport, id: targetId} = data.operation;
        if (targetSupport && targetSupport.type === SupportType.MAIN) {
            const {related_id} = targetSupport;
            sendList = Object.keys(operations).reduce((list: any, key) => {
                const item: any = operations[key];
                const {support} = item.operation;
                if (support && `${support.related_id}` === `${related_id}`)
                    return [...list, item]
                const {pusher = {}} = data;
                if (pusher[targetId])
                    return [...list, item];
                return list;
            }, []);
        } else {
            sendList = Object.keys(operations).reduce((list: any, key) => {
                const item: any = operations[key];
                const {pusher = {}, action_type} = item;
                if (pusher[targetId] && action_type)
                    return [...list, item];
                return list;
            }, [data]);
        }
        dispatch(planOpActions.confirmOpRequest({operations: sendList, isSave: false}));
    }
}

export const handleSaveOperation = (data: any, operations: IOperations, dispatch: Dispatch) => {
    let sendList = [];
    if (data.status === 2) {
        notification.error({
            message: 'Error',
            description: `#${data.operation.operation_code}: ${data.message}`
        })
    } else {
        const {support: targetSupport, id: targetId} = data.operation;
        if (targetSupport && targetSupport.type === SupportType.MAIN) {
            const {related_id} = targetSupport;
            sendList = Object.keys(operations).reduce((list: any, key) => {
                const item: any = operations[key];
                const {support} = item.operation;
                if (support && `${support.related_id}` === `${related_id}`)
                    return [...list, item]
                const {pusher = {}} = data;
                if (pusher[targetId])
                    return [...list, item];
                return list;
            }, []);
        } else {
            sendList = Object.keys(operations).reduce((list: any, key) => {
                const item: any = operations[key];
                const {pusher = {}, action_type} = item;
                if (pusher[targetId] && action_type)
                    return [...list, item];
                return list;
            }, [data]);
        }
        dispatch(planOpActions.confirmOpRequest({operations: sendList, isSave: true}));
    }
}

export const handleCancelOperation = (data: IOperation, dispatch: Dispatch) => {
    const {id, tenant_id = ''} = data.operation;

    const params = {
        operation_id: id,
        tenant_id,
        status: OP_STATUS.CANCELED
    }

    modal.confirm({
        content: 'Are you sure you want to cancel this operation?',
        ok: {
            click: () => {
                new Promise((resolve) => resolve(updateOperationStatusFetch(params)))
                    .then(() => {
                        notification.success({message: 'Success', description: 'Cancel successfully'})
                        afterChangeState(data, {...data, operation: params}, dispatch);
                    })
                    .catch((error) => {
                        showErrorResponse('Cancel failed', error);
                    })
            }
        }
    })
}


export const handleDeclineOperation = (data: IOperation, dispatch: Dispatch) => {
    const {id, tenant_id = ''} = data.operation;

    const params = {
        operation_id: id,
        tenant_id,
        status: OP_STATUS.CANCELED
    }

    modal.confirm({
        content: 'Are you sure you want to decline this operation?',
        ok: {
            click: () => {
                new Promise((resolve) => resolve(updateOperationStatusFetch(params)))
                    .then(() => {
                        notification.success({message: 'Success', description: 'Decline successfully'})
                        afterChangeState(data, {...data, operation: params}, dispatch);
                    })
                    .catch((error) => {
                        showErrorResponse('Decline failed', error);
                    })
            }
        }
    })
}

export const showPopupDeleteOperation = (data: IOperation, props: any, action: (ids: string[]) => void) => {

    const {id, operation_code, status} = data.operation;
    if (status === OP_STATUS.NEW && data.action_type === OPERATION_CONFIRM_STATUS.UPDATE) {
        props.dispatch(planOpActions.deleteCancelOp(id));
    } else if (data.action_type === OPERATION_CONFIRM_STATUS.NEW) {
        deleteSuccess([id], [operation_code], action);
    } else {
        modal.confirm({
            content: 'Are you sure you want to delete this operation?',
            subContent: 'Note: Delete this operation may cause duration changing on next operation (if have any) due to vessel’s start position.',
            ok: {
                click: () => {
                    const loadingEl = document.getElementById(id + '-loading');
                    changeLoadingOp(loadingEl, true);
                    new Promise((resolve) => resolve(deleteOperationFetch(id)))
                        .then(() => {
                            changeLoadingOp(loadingEl, false);
                            deleteSuccess([id], [operation_code], action);
                        })
                        .catch((error) => {
                            changeLoadingOp(loadingEl, false);

                            if (error.response && error.response.status.toString() === '445') {
                                deleteSuccess([id], [operation_code], action);
                            } else
                                showErrorResponse('Delete failed', error);
                        })
                }
            }
        })
    }
};

export const deleteSuccess = (ids: any, code: any, action: (ids: string[]) => void) => {
    notify.success(code.length > 1 ? NotifyCode.S5 : NotifyCode.S4, "Delete successful")([code.join(', ')])
    action(ids);
}

export const openPopupDuration = (data: IOperation, dispatch: Dispatch) => {
    const opInfoEl = openPopup(<PopupEditDuration
        data={data}
        onSave={(op) => {
            dispatch(planOpActions.updateOps({update: {[op.operation.id]: op}}))
        }}
        onClose={() => opInfoEl.remove()}
    />);
}

export const handleApproveOp = (data: IOperation, dispatch: Dispatch) => {
    const {id, tenant_id = '', operation_code} = data.operation;
    const params = {
        operation_id: id,
        tenant_id,
        status: OP_STATUS.WAITING
    }
    const el = getElById(id + '-loading');
    changeLoadingOp(el, true);
    new Promise((resolve) => resolve(updateOperationStatusFetch(params)))
        .then((rs) => {
            notify.success(NotifyCode.S7)([`#${operation_code}`])
            changeLoadingOp(el, false);
            afterChangeState(data, rs, dispatch);
        })
        .catch(error => {
            showErrorResponse(`#${operation_code} approved failed`, error)
            changeLoadingOp(el, false);
        })
}


export const afterChangeState = (data: IOperation, rs: any, dispatch: Dispatch) => {
    const {activity_log} = rs;
    const {status, current_process} = rs.operation;
    dispatch(planOpActions.updateOpsByWs({
        data: [{
            ...data,
            operation: {...data.operation, status, current_process},
            activity_log
        }],
    }))

}

export const handleDuplicate = (data: IOperation) => {
    const el = openPopup(<PopupDuplicate data={data} onClose={() => el.remove()}/>)
}

export const handleSendMail = (data: IOperation) => {
    const el = openPopup(<PopupSendMail data={data} onClose={() => el.remove()}/>)
}


export const handleDownloadPdf = (data: IOperation) => {
    const body = {
        vessel_id: data.vessel.id,
        id: data.operation.id || '',
        tenant_id: data.operation.tenant_id || ''
    }
    GlobalService.opToPdf(body)
        .then((filePath: any) => {
            const fileName = filePath.slice(filePath.lastIndexOf('/') + 1);
            Storage.get(filePath, {download: true})
                .then((rs: any) => {
                    downloadBlob(rs.Body, fileName)
                    notify.success(NotifyCode.S14)();
                })
        })
        .catch((error) => {
            showErrorResponse('Download failed', error)
        })
}

export const renderMenu = (menuOptions: any = []) => {
    return menuOptions.reduce((list: any[], item: any) => {
        if (item.isActive === true) {
            list = [...list, {
                key: item.name,
                label: <div className='menu-line' data-danger={item.danger} onClick={item.onClickHandler}>
                    <Icon
                        icon={item.icon}
                        style={{width: '15px', height: '15px'}}
                        danger={item.danger}
                    />
                    <span>{item.name}</span>
                </div>
            }]
        }
        return list
    }, [])
};