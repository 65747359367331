import styles from "./RulerComponent/style.module.scss";
import {checkZero} from "../../../../util/varibles/global";
import React from "react";
import {datetime} from "../../../../util/library/datetime";

export const minus: any = [];
export const widthMinus = 8;
const maxMinus = 5, centerMinus = Math.floor((maxMinus + 1) / 2);
const styleMinus = {minWidth: widthMinus + 'px'}
for (let i = 0; i <= maxMinus; i++) {
    minus.push(<div key={'minus' + i} {...{
        className: styles['minus'], style: styleMinus, "data-is-center": i === centerMinus,
    }}/>)
}

export const hours: any = []
const divisor = 2;
export const minWidthHours = widthMinus * (maxMinus + 1) * 24 / divisor;
for (let i = divisor; i < 24; i += divisor) {
    hours.push(<div className={styles['hour']} key={'hour' + i}>
        <div className={styles['time']}>{checkZero(i)}</div>
        {minus}
    </div>)
}

export const speedList = [
    {key: 1, label: 'Standard (1s)'},
    {key: 10, label: '10s'},
    {key: 30, label: '30s'},
    {key: 60, label: '1min'},
    {key: 300, label: '5min'},
]

export const getFinishReplay = (finishTime: any) => finishTime.valueOf() > datetime().time ? datetime().time : finishTime;
