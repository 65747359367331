import React from 'react';
import {useSelector} from 'react-redux';
import styles from './style.module.scss';
import PopupEditRoute from "../Component/PopupEditRoute";
import RouteView from "../RouteView";
import {selectRoute} from "../../../util/store/selectors";
import {RouteMode} from "../constatns";
import WrapperRoute from "./WrapperRoute";

interface IProps {
    getConnectId(): string
}

const Content: React.FC<IProps> = (props) => {
    const mode = useSelector(selectRoute.mode);
    const isShow = mode === RouteMode.EDIT
    const style = isShow ? {transform: `translate(-50%)`} : {}

    return <WrapperRoute width='425px'>
        <div className={styles['wrapper-controller']}>
            <div className={styles['controller-content']} style={style}>
                <div className={styles.view} data-hide={isShow}>
                    <RouteView/>
                </div>
                <div className={styles.edit} data-hide={!isShow}>
                    <PopupEditRoute getConnectId={props.getConnectId}/>
                </div>
            </div>
        </div>
    </WrapperRoute>;
};

export default Content;

