import React, {useEffect, useRef} from 'react';
import {connect, useSelector} from 'react-redux';
import styles from './style.module.scss';
import AddChat from "./AddChat";
import {CHAT_TYPE} from "../../../../util/varibles/constants";
import {selectMessage} from "../../../../util/store/selectors";
import ChatBox from "../../../../components/Chat/ChatBox";
import {IConversation} from "../../../../util/varibles/interface";

const mapStateToProps = () => ({})

interface IProps {
    conversation: IConversation
    isNew: boolean
    values: any[]
    name: string
    loading: boolean
    extra?: any
    chatType: CHAT_TYPE

    onSend(params: any): void

    onChangeSelect(values: any): void
}

const MessageBox: React.FC<IProps> = (props) => {
    const {isNew = false, values = [], loading: loadingGlobal = false, chatType, onSend, onChangeSelect} = props;
    const isActiveInput = isNew ? values.length > 0 : !!props.conversation;
    const {group_id} = props.conversation || {};
    const list = useSelector(selectMessage.messages);
    const {messages = [], loading = false} = list[group_id] || {};

    const selectRef = useRef<HTMLBRElement>(null);
    useEffect(() => {
        if (isNew)
            setTimeout(() => {
                selectRef.current?.focus()
            }, 100)
    }, [isNew]);


    return <div className={styles.wrapper}>
        <div className={styles.title}>
            {isNew
                ?
                <AddChat chatType={chatType} values={values} selectRef={selectRef} onChange={onChangeSelect}/>
                : <>
                    <div data-lev='name'>
                        <div data-lev='logo'/>
                        <span>{props.name}</span>
                    </div>
                    <div data-lev='action'>
                        {props.extra}
                    </div>
                </>}
        </div>
        <div className={styles.content}>
            <ChatBox
                data={props.conversation}
                messages={isNew ? [] : messages}
                loading={loadingGlobal || loading}
                isActiveInput={isActiveInput}
                onSend={onSend}
            />
        </div>
    </div>;
};


export default connect(mapStateToProps, {})(MessageBox);