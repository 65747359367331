import {IElement} from "../../constants";
import React from "react";

export const columns = [
    {label: 1, value: 1},
    {label: 2, value: 2},
    {label: 3, value: 3},
    {label: 4, value: 4},
];

export interface IPropsPropertyElement {
    selected: any

    onUpdate(element: IElement, index: number): any
}

export function onChangeLabel(e: React.FormEvent<HTMLInputElement>, props: IPropsPropertyElement) {
    const {selected} = props;
    if (!selected) return;

    const {index, ...args} = selected
    props.onUpdate({...args, label: e.currentTarget.value}, index)
}

export function onChangeProperty(properties: any, props: IPropsPropertyElement) {
    const {selected} = props;
    if (!selected) return;
    const {index, ...args} = selected
    props.onUpdate({...args, properties: {...args.properties, ...properties}}, index)
}

export function onChangeRequired(id: string) {
    const element: any = document.getElementById(id);
    element.click()
}

const colorFocus = "#1B6399";
const colorHover = "#267BB9";
const color = "#CECECE";
const heightChild = '32px';
const heightParent = '35px';

export const selectStyle = {
    container: (provided: any) => ({...provided, height: heightParent}),
    control: (provided: any, state: any) => ({
        ...provided,
        borderRadius: "50px",
        minHeight: heightParent,
        height: heightParent,
        paddingLeft: "5px",
        boxShadow: "unset",
        transition: 'all .3s',
        border: "1px solid " + (state.selectProps.menuIsOpen ? colorFocus : color),
        "&:hover": {
            borderColor: colorHover
        },
        "&:focus": {
            borderColor: colorFocus
        },

    }),
    valueContainer: (provided: any) => ({...provided, height: heightChild, padding: "0 8px",}),
    indicatorsContainer: (provided: any) => ({...provided, height: heightChild,}),
    indicatorSeparator: (provided: any) => ({...provided, display: "none"}),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: state.selectProps.menuIsOpen ? colorFocus : color,
        height: heightChild,
        padding: 0,
        width: '32px',
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        justifyContent: 'center',
        alignItems: 'center',

        "&:hover": {
            color: colorHover,
        }
    }),
    input: (provided: any) => ({...provided, margin: 0, height: heightChild, padding: "0 0 0 3px"}),
    placeholder: (provided: any) => ({
        ...provided,
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
    }),
    multiValue: (provided: any) => ({...provided, height: "26px"}),
    menu: (provided: any) => ({...provided, marginTop: "1px"}),
    option: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        height: heightChild,
        lineHeight: heightChild
    })
};

export enum SEPARATOR_VALUE {
    DASH = '-',
    SLASH = '/',
    DOT = '.'
}

export const optionSeparator = [
    {label: SEPARATOR_VALUE.DASH, value: SEPARATOR_VALUE.DASH},
    {label: SEPARATOR_VALUE.SLASH, value: SEPARATOR_VALUE.SLASH},
    {label: SEPARATOR_VALUE.DOT, value: SEPARATOR_VALUE.DOT},
];
