import React, {Component} from 'react';
import styles from "./style.module.scss";
import {formatNumber} from "../../../../../util/varibles/global";
import {IUnit} from "../../../../../util/varibles/interface";
import UnitLice from "../../../../Operation/UnitLice";

interface IProps {
    data: any
    total: any
}

class TransportInfo extends Component<IProps> {
    render() {
        const {data, total} = this.props;
        return data.map((item: { detail: IUnit[] } & IUnit) => {
            item.lice = {
                female_lice: {
                    value: 0.3
                },
                stationary_lice: {
                    value: 0.3
                },
                total_lice: {
                    value: 0.3
                },
                mobile_lice: {
                    value: 0.3
                },
            }
            return <div className={styles.table} key={item.id}>
                <div className={styles['table-title']}>
                    <span className='mr-1'>Load from </span>
                    <b className='font-[600]'>{item.unit_id} - {formatNumber(item.fish_amount)} fishes</b>
                    <div className='ml-[5px] flex gap-1 items-center'>
                        <span>(avg. weight:</span>
                        <b className='font-[600]'>{formatNumber(item.avg_weight)} gr)</b>
                    </div>
                    {item.lice && <div className='flex gap-1 items-center'>
                        <span>, Lice:</span>
                        <b className='font-[600]'><UnitLice lice={item.lice}/></b>
                    </div>}
                    <span>, and deliver to</span>
                </div>
                <div className={styles['table-header']}>
                    <div>Deliv. unit</div>
                    <div>Amount</div>
                    <div>Lice</div>
                </div>
                {item.detail.map((detail: any) =>
                    <div className={styles['table-row']} key={detail.delivery_id}>
                        <div>{detail.delivery_name}</div>
                        <div>
                            <span>{formatNumber(detail.fish_amount)}</span>
                            {detail.fish_amount !== total[detail.delivery_id] &&
                                <span className='ml-1'>(of {formatNumber(total[detail.delivery_id])})</span>}
                        </div>
                        <div>
                            <UnitLice lice={detail.lice}/>
                        </div>
                    </div>)}
            </div>
        });
    }
}

export default TransportInfo;
