import React from 'react';
import {ACTION_TYPE, CONTRACT_STATUS, CONTRACT_TYPE, vesselTypes,} from "../../util/varibles/constants";
import styles from './style.module.scss';
import {Button, Collapse, Table, Tooltip} from "antd";
import PopupApprove from "./PopupApprove";
import {PlusOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import Menu from "../../components/Menu";
import Icon, {ICON_NAME} from "../../components/Icon";
import {IStateGlobal, IVessel} from "../../util/varibles/interface";
import {openPopup} from "../../components/Popup/Component/WrapperPopup";
import PopupEditVessel from "./PopupEditVessel";
import {vesselManagementActions} from "./reducer";
import {ACTION} from "../../util/varibles/permission";
import ChangeSpot from "./Component/ChangeSpot";

const mapStateToProps = (state: IStateGlobal) => ({
    ownVessels: state.vesselManagement.ownVessels,
    permission: state.login.user.permission
});

interface IProps {
    ownVessels: IVessel[]
    columns: any
    permission: any

    addVessel(payload: IVessel): void
}


const OwnVessel: React.FC<IProps> = (props) => {
    const {columns, ownVessels, permission} = props;

    const openApprove = (values: any) => {
        const el = openPopup(<PopupApprove values={values} onClose={() => el.remove()}/>)
    }

    const action = {
        width: '200px',
        key: 'action',
        render: (record: any) => {
            if (!permission[ACTION.VESSEL.UPDATE])
                return null;
            const {type, spot_status} = record;
            let content;
            switch (spot_status) {
                case CONTRACT_STATUS.REQUESTING:
                    content = <>
                        <Button
                            className='bt-danger  mr-10'
                            onClick={() => openApprove({...record, statusConfirm: CONTRACT_STATUS.DECLINE})}
                        >
                            Decline
                        </Button>
                        <Button
                            className='bt-primary'
                            onClick={() => openApprove({
                                ...record,
                                statusConfirm: type === CONTRACT_TYPE.FIXED ? CONTRACT_STATUS.APPROVED : CONTRACT_STATUS.COMPLETE
                            })}
                        >
                            Approve
                        </Button>
                    </>
                    break;
                case CONTRACT_STATUS.COMPLETE:
                    content = <Menu {...{items: renderMenu(record), placement: "bottomRight",}}>
                        <div className='three-dot'/>
                    </Menu>
                    break;
                default:
                    return null
            }
            return <div className='d-flex justify-content-end'>
                {content}
            </div>
        },
    }

    const renderMenu = (record: any) => {
        const menu: any = [];
        const {type} = record;
        if (type === CONTRACT_TYPE.SINGLE) {
            menu.push({
                key: 'update',
                label: <div
                    className='menu-line'
                    onClick={() => openApprove({...record, statusConfirm: null})}
                >
                    <Icon icon={ICON_NAME.EDIT}/>
                    <div>Update contract</div>
                </div>
            })
        }

        menu.push({
            key: 'cancel',
            label: <div
                className='menu-line'
                data-danger='true'
                onClick={() => openApprove({...record, statusConfirm: CONTRACT_STATUS.INACTIVE})}
            >
                <Icon icon={ICON_NAME.CANCEL} danger/>
                <div>Cancel contract</div>
            </div>
        })

        return menu;
    }

    const renderHeaderComponent = (vessel: any) => {
        return <div className={styles['header-vessel']}>
            <div className={styles['info-vessel']}>
                <div className={styles['vessel-name']}>
                    <NavLink to={'/vessel/' + vessel.id}>{vessel.name}</NavLink>
                </div>
                <div>{vesselTypes[vessel.type - 1].name}</div>
                <div>{vessel.treatment_type}</div>
                <div>{Math.round(vessel.capacity / 1000) / 1000 + ' tons'}</div>
                <div>{vessel.speed + ' knots'}</div>
            </div>
        </div>
    }

    const openAddVessel = () => {
        const el = openPopup(<PopupEditVessel
            title={'Add new vessel'}
            editMode={ACTION_TYPE.CREATE}
            onSave={props.addVessel}
            onClose={() => el.remove()}
        />)
    }

    return <div className={styles['category']}>
        <div className='d-flex justify-content-between align-items-center'>
            <span className='font-s1 font-w4 pt-2 pb-2'>Own vessels</span>
            {permission[ACTION.VESSEL.CREATE] && <Tooltip title="Add vessel">
                <Button
                    className='bt-primary bt-plus' type="primary" shape="circle"
                    icon={<PlusOutlined style={{fontSize: '18px'}}/>}
                    onClick={openAddVessel}
                />
            </Tooltip>}
        </div>
        <div className={styles['title-vessel']}>
            <div>Vessel</div>
            <div>Vessel type</div>
            <div>Treatment type</div>
            <div>Capacity</div>
            <div>Speed</div>
            <div className='text-right'>Spot mode</div>
        </div>
        <Collapse items={ownVessels.map(item => {
            return {
                title: item.name,
                key: item.id,
                className: styles.vessel,
                extra: <div className={styles['bt-switch-spot']} onClick={(e: any) => e.stopPropagation()}>
                    <ChangeSpot vessel={item}/>
                </div>,
                label: renderHeaderComponent(item),
                children: (!item.contracts || item.contracts.length === 0 )?
                    <div className={styles['data-empty']}>
                        There is no fixed contract, single operation nor request yet
                    </div> :
                    <Table {...{
                        className: styles.contracts,
                        rowKey: 'id',
                        dataSource: item.contracts.map((item: any) => ({
                            ...item,
                            vesselId: item.id,
                            vesselName: item.name
                        })),
                        columns: [...columns, action],
                        pagination: false,
                        title: () => 'Contracts'
                    }} />

            }
        })}/>
    </div>;
};

export default connect(mapStateToProps, {
    addVessel: vesselManagementActions.addVessel
})(OwnVessel);
