import React from 'react';
import stylesComponent from "./style.module.scss";
import {HARVEST_OPTION_KEY} from "../../pages/PreferencePage/utils";

interface IProps {
    keys?: any
    visible?: boolean
    operation: any
}

const NoteDetail: React.FC<IProps> = ({keys = {}, visible = false, operation}) => {
    const isShow = keys[HARVEST_OPTION_KEY.NOTE] || visible;

    if (!isShow)
        return null;

    const {note = ''} = operation;

    if (!note || note.length === 0)
        return null;

    return <div className={stylesComponent.section}>
        <span className='text-comment'>{note}</span>
    </div>;
};

export default NoteDetail;
