import React, {Component} from 'react';
import {connect} from "react-redux";
import {IStateGlobal} from "../../../util/varibles/interface";
import {LoginState} from "../../../pages/LoginPage/constants";
import styles from "./style.module.scss";

function mapStateToProps(state: IStateGlobal) {
    return {
        login: state.login
    };
}

interface IProps {
    login: LoginState
    data: any
}

class MessageRemoveUser extends Component<IProps> {
    render() {
        const [currentUser] = this.props.login.user.username.split('@');
        const [username] = this.props.data.username.split('@');
        const {members = []} = this.props.data;

        const userA = username === currentUser ? 'You' : username;
        const userB = members.map((item: any) => {
            const [name] = (item.username || '').split('@');
            return name === currentUser ? 'you' : name
        })
        return <div className={styles['change-user']}>
            {userA} removed {userB.join(', ')} from the group
        </div>;
    }
}

export default connect(mapStateToProps, {})(MessageRemoveUser);
