import React, {Component} from 'react';
import styles from './style.module.scss';
import {IPropsChildren} from "../../../util/varibles/interface";
import {IPropsElement} from "./constants";

interface IProps extends IPropsElement, IPropsChildren {
    selected: any
    properties: any

    onSelect(value?: any): void

    onDelete(index: number): void
}

class ContainerElement extends Component<IProps> {
    componentDidMount() {
        document.addEventListener('keydown', this.handleDeleteByKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleDeleteByKeyDown)
    }

    handleDeleteByKeyDown = (e: KeyboardEvent) => {
        const {selected} = this.props;
        if (selected && e.key === 'Delete') {
            const elementFocus = document.activeElement;
            const {index} = selected
            if (elementFocus && elementFocus.id === `${index}_element`)
                this.props.onDelete(selected.index)
        }
    }

    handleClick = () => {
        const {index, label, type, properties, selected, isAllowSelect} = this.props;
        if (isAllowSelect) {
            const element = {
                index,
                type,
                label,
                properties
            }
            this.props.onSelect(element);
        }
    }

    render() {
        const {index, type, selected, isAllowSelect} = this.props;
        return <div
            id={index + '_element'} className={styles['container-element']}
            data-is-select={selected && index === selected.index && isAllowSelect}
            data-element-type={type}
            onClick={this.handleClick}
            tabIndex={0}
        >
            {this.props.children}
            <div className={styles['bt-setting']} onClick={this.handleClick}/>
            <div className={styles['bt-delete']} onClick={() => this.props.onDelete(index)}/>
        </div>;
    }
}

export default ContainerElement;
