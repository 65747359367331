import {CHAT_TYPE, OPERATION_TYPE} from "../../util/varibles/constants";
import React from "react";
import {MESSAGE_TYPE} from "../../pages/MessagePage/constants";
import {NavLink} from "react-router-dom";
import {onOpenPage} from "../../util/varibles/global";
import styles from "./style.module.scss";
import {notification} from "antd";
import {nextPage} from "../../util/library/Router";

export const userColors = [
    {background: '#D5DAFA', color: '#002C4D', nameColor: '#A8A8FA'},
    {background: '#CDDFFA', color: '#002C4D', nameColor: '#9CBDF1'},
    {background: '#C9ECF0', color: '#002C4D', nameColor: '#7FD3DE'},
    {background: '#CDF1F5', color: '#002C4D', nameColor: '#7BD3EE'},
    {background: '#D1EBC5', color: '#002C4D', nameColor: '#B2E174'},
    {background: '#E1F5E2', color: '#002C4D', nameColor: '#A1DCAE'},
    {background: '#F7F1DA', color: '#002C4D', nameColor: '#F7E399'},
    {background: '#F7E6CB', color: '#002C4D', nameColor: '#F7CDA6'},
    {background: '#FADCD2', color: '#002C4D', nameColor: '#F8D0BB'},
    {background: '#FFE5E5', color: '#002C4D', nameColor: '#EBB0C9'},
]

export const getTitleChatUser = (data: any, user_id: string) => {
    const {members} = data.group_chat;
    if (members.length > 2) {
        return data.group_chat.name
    } else {
        const index = 1 - members.findIndex((item: any) => item.id === user_id);
        const {username = data.group_chat.name} = members[index] || {}
        const [name] = username.split('@');
        return name;
    }
}

export const getSubInfoChatOp = (item: any) => {
    const {operation_type, site_name, factory_name} = item;

    switch (operation_type) {
        case OPERATION_TYPE.HARVEST: {
            return <div className={styles['sub-info']}>
                <span data-lev='dot'>,</span>
                <span>{site_name}</span>
                <span className='arrow-right'>&#8594;</span>
                <span>{factory_name}</span>
            </div>
        }
        case OPERATION_TYPE.TREATMENT: {
            return <div className={styles['sub-info']}>
                <span data-lev='dot'>,</span>
                <span>{site_name}</span>
            </div>
        }
    }
}

export const messageNotify = (data: any, read: any, currentUser: any, isUpdate?: boolean) => {
    const {type} = data;
    const [title] = data.message.username.split('@');
    let subTitle;
    if (type === CHAT_TYPE.USER) {
        const {members} = data.group_chat;
        if (members.length > 2) {
            subTitle = data.group_chat.name;
        }
    } else {
        const {name, id, tenant_id} = data.group_chat;
        const link = `/operation/${tenant_id}/${id}`;
        subTitle = <NavLink className={styles['message-op-code']} onMouseDown={(e: any) => onOpenPage(e, link)} to={link}>
            #{name}
        </NavLink>
    }
    const {message_type} = data.message
    let description;
    switch (message_type) {
        case MESSAGE_TYPE.ADD: {
            const {members} = data.message;
            const userB = members.map((item: any) => {
                const [name] = (item.username || '').split('@');
                return name === currentUser ? 'you' : name
            })
            description = title + ' added ' + userB.join(', ') + ' the group';
            break;
        }
        case MESSAGE_TYPE.DELETE: {
            const {members} = data.message;
            const userB = members.map((item: any) => {
                const [name] = (item.username || '').split('@');
                return name === currentUser ? 'you' : name
            })
            description = title + ' removed ' + userB.join(', ') + ' the group';
            break;
        }
        case MESSAGE_TYPE.LEAVE: {
            description = title + ' left the group'
            break;
        }
        default: {
            description = (isUpdate ? 'Edited: ' : '') + data.message.message;
        }
    }

    notification.info({
        className: styles['notification-message'],
        icon: <div className={styles['message-logo']}>{title[0]}</div>,
        message: <div className={styles['message-sender']}>
            <div className={styles['message-name']}>{title}</div>
            {subTitle && <div className={styles['message-chat-name']}>{subTitle}</div>}
        </div>,
        description: <div className={styles['message-description']}>
            {description}
        </div>,
        onClick: () => {
            read();
            showPage(data);
        },
        closeIcon: <div className='bt-close'/>,
    })
}

const showPage = (data: any) => {
    const {type} = data;
    const {id} = data.group_chat;
    const link = `/message?chatType=${type}&id=${id}`;
    nextPage(link);
}