import React, {ReactNode, useCallback, useMemo} from 'react';
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import {OPERATION_TYPE, TREATMENT_TYPE, treatmentInTanks} from "../../../../util/varibles/constants";
import Menu from "../../../../components/Menu";
import {Checkbox} from 'antd';
import {optionOpTypes} from "../constants";
import styles from '../style.module.scss';
import ValueAndClear from "../../../../components/Basic/ValueAndClear";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import Density from "./Density";

const opts = [
    `${OPERATION_TYPE.HARVEST}`,
    `${OPERATION_TYPE.SERVICE}`,
    `${OPERATION_TYPE.TRANSPORT}`,
    ...treatmentInTanks.map(item => [OPERATION_TYPE.TREATMENT, item].join(','))
]

interface IProps {
}

const TankTreatments: React.FC<IProps> = () => {
    const [sub_types = [], setStore] = useEditVessel(state => state.vessel?.tank_sub_types);
    const [op_types = []] = useEditVessel(state => state.vessel?.operation_type);
    const {value, valueSet} = useMemo(() => {
        const result = [...op_types.reduce((rs: string[], item) => {
            if (item === OPERATION_TYPE.TREATMENT) {
                return rs
            }
            return [...rs, `${item}`]
        }, []), ...sub_types.map(sub => [OPERATION_TYPE.TREATMENT, sub].join(','))];
        return {value: result, valueSet: new Set(result)}
    }, [sub_types, op_types])

    const handleClear = useCallback((id: string) => {
        setStore(({vessel}) => {
            const opTypes = new Set(vessel?.operation_type || []);
            const subTypes = new Set(vessel?.tank_sub_types || []);
            const [type, sub_type] = id.split(',');
            if (+type === OPERATION_TYPE.TREATMENT) {
                subTypes.delete(+sub_type);
            } else {
                opTypes.delete(+type);
            }
            return {
                vessel: {...vessel, operation_type: Array.from(opTypes), tank_sub_types: Array.from(subTypes)}
            }
        })
    }, [setStore]);

    return <div>
        <Menu items={[{
            key: 'option',
            className: 'no-hover w-[200px]',
            disabled: true,
            label: <Checkbox.Group
                className={styles.tankSubTypes}
                value={value}
                options={opts.map(item => {
                    const {name} = optionOpTypes[item]
                    return {
                        label: name,
                        value: item,
                    }
                }).sort((a, b) => a.label.localeCompare(b.label))}
                onChange={selected => {
                    const result = selected.reduce((rs: {
                        operation_type: OPERATION_TYPE[],
                        tank_sub_types: TREATMENT_TYPE[]
                    }, item) => {
                        const [type, sub_type] = item.split(',');
                        if (+type === OPERATION_TYPE.TREATMENT) {
                            rs.operation_type = Array.from(new Set([...rs.operation_type, +type]));
                            rs.tank_sub_types.push(+sub_type);
                        } else {
                            rs.operation_type.push(+type);
                        }
                        return rs
                    }, {operation_type: [], tank_sub_types: []})
                    setStore(({vessel}) => ({
                        vessel: {...vessel, ...result}
                    }))
                }}
            />
        }]}>
            <div className='flex gap-2 items-center text-[15px] mb-2 w-[180px] cursor-pointer leading-[28px]'>
                <span>Select tank's capabilites</span>
                <Icon icon={ICON_NAME.ARROW_DOWN} size={13} bg='colorMain'/>
            </div>
        </Menu>
        <div className='flex flex-col gap-[8px]'>
            {opts.reduce((rs: ReactNode[], item) => {
                const flag = valueSet.has(item);
                if (!flag)
                    return rs;
                const {name} = optionOpTypes[item];
                const [, treatment_type] = item.split(',');
                return [...rs, <div key={item} className='flex gap-5'>
                    <ValueAndClear name={name} onClear={() => handleClear(item)}/>
                    <Density id={treatment_type}/>
                </div>]
            }, [])}
        </div>
    </div>;
};

export default TankTreatments;
