import React, {useCallback, useEffect, useState} from 'react';
import TrackingOp from "../../../components/TrackingOp";
import {useDispatch, useSelector} from "react-redux";
import {IOperation} from "../../../util/varibles/interface";
import {VIEW_MODE} from "../../../util/varibles/permission";
import {MAP_ELEMENT} from "../../../util/library/googlemap/constants";
import {generateMarkers} from "../Control/constants";
import {BarentsWatchService} from "../../../util/barentswatch/api";
import {notification} from "antd";
import {convertDisease} from "../../../util/varibles/global";
import {dashboardActions} from "../reducer";
import {IVesselOfUser} from "../../LoginPage/constants";
import {selectDashboard, selectViewMode} from "../../../util/store/selectors";
import styles from "../style.module.scss";
import {DiseaseZonePolygon} from "../../../contexts/WorldMapContext/Polygon";

let abort = new AbortController();
let loading = false;

interface IProps {
}

const WrapperTrackingOp: React.FC<IProps> = () => {
    const vessels = useSelector(selectDashboard.vessels);
    const viewMode = useSelector(selectViewMode);
    const dispatch = useDispatch();
    const [zones, setZones] = useState<any[]>([])

    useEffect(() => {
        return () => {
            if (abort)
                abort.abort();
            dispatch(dashboardActions.showInfo({}))
            loading = false;
        }
    }, [dispatch]);

    const onTracking = useCallback((data: IOperation | { operation: undefined, vessel: IVesselOfUser }) => {
        const {id: vesselId, name} = data.vessel;

        if (!data.operation) {
            dispatch(dashboardActions.showInfo({
                targetType: MAP_ELEMENT.POLYLINE,
                data: {markers: [{type: MAP_ELEMENT.VESSEL, id: vesselId}]},
            }))
            return;
        }

        const vessel = vessels.find((item: any) => item.id === vesselId);

        if (vessels.length > 0 && !vessel) {
            notification.error({message: `${name} is not assigned IOT name`})
        }

        const {operation_type = '', diseases = []} = data.operation || {};
        const getMarkers = generateMarkers[operation_type];
        const markers = getMarkers ? getMarkers(data) : [];
        dispatch(dashboardActions.showInfo({
            targetType: MAP_ELEMENT.POLYLINE,
            data: {...data, markers: [...markers, {type: MAP_ELEMENT.VESSEL, id: vesselId}]},
        }));
        console.log(diseases)
        const diseaseIds = diseases.reduce((list: any, item: any) => {
            if (item.pk) {
                const week = item.pk.replace('#', '/');
                (item.diseases || []).forEach((sub: any) => {
                    const {type, forskNr} = sub;
                    const key = [type, forskNr].join('/');
                    list[key] = {...sub, week}
                })
            }
            return list;
        }, {});

        if (Object.keys(diseaseIds).length === 0)
            return;

        if (loading)
            abort.abort();

        abort = new AbortController();
        loading = true;
        Promise.all(Object.keys(diseaseIds).map((key: any) => {
            const {week, type, forskNr} = diseaseIds[key];
            return BarentsWatchService.getIlaControlAreaV1(type, forskNr, week, {signal: abort.signal})
                .then((rs: any) => ({...convertDisease(rs), diseaseType: type}));
        }))
            .then(rs => {
                setZones(rs);
                loading = false;
            })
            .catch((err) => {
                console.log(err);
                loading = false
            })
    }, [vessels, dispatch]);

    useEffect(() => {
        if (viewMode !== VIEW_MODE.VESSEL) {
            if (loading)
                abort.abort();
            dispatch(dashboardActions.showInfo(({})));
            loading = false;
        }
    }, [viewMode, dispatch]);

    return <div className={styles.vesselView} data-show={viewMode === VIEW_MODE.VESSEL}>
        <TrackingOp onTracking={onTracking} full/>
        {zones.map(item => <DiseaseZonePolygon
            key={item.id}
            data={item}
            diseaseType={item.diseaseType}
        />)}
    </div>
};

export default WrapperTrackingOp
