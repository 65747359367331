import React, {ReactNode, useMemo, useState} from 'react';
import Icon, {ICON_NAME} from "../../../../components/Icon";
import styles from '../style.module.scss';
import {treatmentName} from "../constants";
import PopupDensity from "../PopupDensity";
import {IDensityMap} from "../../../../util/varibles/interface";
import LoadingBox from "../../../../components/LoadingBox";

interface IProps {
    id?: string
    value?: { [value: string]: [number, number] }
    loading?: boolean
    title?: {
        view: ReactNode,
        source: IDensityMap
    }
    onSave: (value: IDensityMap) => void
}

const TreatmentDensity: React.FC<IProps> = ({id = '', value = {}, title, loading, onSave}) => {
    const [visible, setVisible] = useState(false);
    const isValue = useMemo(() => Object.keys(value).length > 0, [value]);

    return <>
        <div className={styles.density} data-loading={loading} onClick={() => !loading ? setVisible(true) : null}>
            {loading && <LoadingBox spinnerSize={18} loading={loading}/>}
            {title
                ? <div>{title.view}</div>
                : <div className={styles.densityBt}>
                    {isValue && <Icon icon={ICON_NAME.TANK_2}/>}
                    <div>{(isValue && id.length > 0) ? treatmentName(id) : 'Define'} density</div>
                </div>}
        </div>
        {visible && <PopupDensity
            id={id}
            value={value}
            combine={title?.source}
            onSave={onSave}
            onClose={() => setTimeout(() => setVisible(false), 300)}
        />}
    </>;
};

export default TreatmentDensity;
