import React, { Component } from 'react';
import { IPropsElement } from "./constants";
import styles from './style.module.scss';

interface IProps extends IPropsElement {

}

class HeaderElement extends Component <IProps> {
	render() {
		const { label, properties: { subLabel, fontSize, textAlign } } = this.props;
		const style = { textAlign }
		return <div className={styles.header} style={ style }>
			<p data-font-size={ fontSize }>{ label }</p>
			<p>{ subLabel }</p>
		</div>;
	}
}

export default HeaderElement;
