import React, {Component} from 'react';
import styles from "./style.module.scss";

interface IProps {
}

class Empty extends Component<IProps> {
    render() {
        return <div className={styles.empty}>
            <span>New message <br/>Type your first message below</span>
        </div>;
    }
}

export default Empty;