import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {IOperation} from "../../../../util/varibles/interface";
import {Checkbox} from "antd";
import {TRouteExtend} from "../index";
import GoogleMap from "../../../../util/library/googlemap/googlemap";
import {colors} from "../../../../util/varibles/constants";
import LoadingBox from "../../../LoadingBox";
import {createPolyline} from "../../../../util/varibles/global";
import {MAP_ELEMENT} from "../../../../util/library/googlemap/constants";

interface IProps {
    data: IOperation
    routeId: string
    routes: { [id: string]: TRouteExtend }
    sites: any
    map: GoogleMap
    loading: boolean

    setRoute(id: string): void
}

const Route: React.FC<IProps> = (props) => {
    const {routes, data, routeId, loading, map, setRoute} = props;
    const {site_name, factory_name} = data.operation;
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        if (Object.keys(routes).length === 0)
            return;

        if (checkAll) {
            map.generate({
                type: MAP_ELEMENT.POLYLINE,
                seaWays: Object.keys(routes).map(id => createPolyline(routes[id], routes[id]?.index, id === routeId ? 3 : 2))
            });
        } else {
            map.generate({
                type: MAP_ELEMENT.POLYLINE,
                seaWays: [createPolyline(routes[routeId], routes[routeId]?.index, 3)]
            });
        }
    }, [routes, routeId, checkAll]);


    const showAll = (e: any) => {
        const {checked} = e.target;
        setCheckAll(checked)
    }

    const handleSelect = (key: string) => {
        if (key === routeId)
            return;
        setRoute(key);
    }

    return <div className={styles.wrapper}>
        <div className={styles.map} id='map'/>
        <div className={styles.box}>
            <div className={styles.header}>Select the route from {site_name} to {factory_name}</div>
            <Checkbox className={styles.checkAll} checked={checkAll} onChange={showAll}>Show all routes</Checkbox>
            <div className={styles.routes}>
                {Object.keys(routes).map((key, i) => <div
                    key={key}
                    className={styles.route}
                    onClick={() => handleSelect(key)}
                    data-checked={key === routeId}
                >
                    <div className={styles.color} style={{background: "#" + colors[i % 10]}}/>
                    <div className={styles.name}>{routes[key].name}</div>
                    <div>{routes[key].distance} nm</div>
                </div>)}
                {Object.keys(routes).length === 0 && <LoadingBox loading={loading}/>}
            </div>
        </div>
    </div>
};

export default Route;

