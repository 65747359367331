import React from 'react';
import {IOperation} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import Task from "../../../Popup/PopupOpInfo/Info/Treatment/Task";
import {formatNumber} from "../../../../util/varibles/global";
import Icon, {ICON_NAME} from "../../../Icon";

interface IProps {
    data: IOperation
    unitId: string
}

const UnitInfo: React.FC<IProps> = (props) => {

    const {unitId} = props;
    const {tasks, sub_operations} = props.data.operation;

    const tasksOfUnit = tasks.reduce((rs: any, item) => {
        const unit = item.sub_operation || (item.sub_operations ? item.sub_operations[0] : {});
        if (unit.unit_id === unitId)
            return [...rs, item];
        return rs;
    }, []);

    const {
        avg_weight = 0,
        fish_amount = 0,
        total_weight = 0,
        note = ''
    } = sub_operations.find(item => item.unit_id === unitId) || {};

    return <div className={styles.info}>
        <div className={styles.tasks}>
            <div data-lev='title'>Task:</div>
            <div data-lev='list'>
                {tasksOfUnit.map((item: any) => <Task
                    key={item.group_id}
                    data={item}
                />)}
            </div>
        </div>
        <div className={styles.fishInfo}>
            <div className={styles.subInfo}>
                <div data-lev='title'>Avg. weight:</div>
                <div>{formatNumber(avg_weight)} gr</div>
            </div>
            <div className={styles.subInfo}>
                <div data-lev='title'>Amount:</div>
                <div>{formatNumber(fish_amount)}</div>
            </div>
            <div className={styles.subInfo}>
                <div data-lev='title'>Total weight:</div>
                <div>{formatNumber(Math.round(total_weight / 1000) / 1000)} t</div>
            </div>
        </div>
        {note.length > 0 && <div className={styles.note}>
            <Icon className={styles.iconNote} icon={ICON_NAME.NOTE} size='large'/>
            <div className={styles.subInfo}>
                <div data-lev='title'>Unit's note:</div>
                <div>{note}</div>
            </div>
        </div>}
    </div>;
};

export default UnitInfo;
