import {initialState, NAME_SLICE} from "./constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ILocation} from "../../varibles/interface";

const locationSlice = createSlice({
    name: NAME_SLICE,
    initialState,
    reducers: {
        getPois: (state) => {
            state.loading = true;
        },
        getPoisSuccess: (state, action: PayloadAction<ILocation[]>) => {
            state.loading = false;
            state.data = action.payload;
        },
        getPoisFailure: (state) => {
            state.loading = false;
        },
        add: (state, action) => {
            const location = action.payload;
            const {data} = state;
            state.data = [...data, location];
        },
        update: (state, action) => {
            const location = action.payload;
            const {data} = state;
            state.data = data.map(item => item.id === location.id ? location : item)
        },
        delete: (state, action) => {
            const id = action.payload;
            const {data} = state;
            state.data = data.filter(item => item.id !== id);
        },
    },
})

export const locationActions = locationSlice.actions

const locationReducer = locationSlice.reducer;

export default locationReducer;
