import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {OPERATION_TYPE, propsModal} from "../../../../util/varibles/constants";
import {Modal} from "antd";
import Header from "./Header";
import Harvest from "./Body/Harvest";
import styles from './style.module.scss';
import Transport from "./Body/Transport";
import Event from "./Body/Event";
import Treatment from "./Body/Treatment";
import Service from "./Body/Service";
import {planOpActions} from "../../reducer";
import {IUpdateOp} from "../../constants";
import {selectPlan} from "../../../../util/store/selectors";
import {EditOperationProvider} from "../../../../contexts/EditOperationContext";

const views: { [id: string]: { width: number, Node: Function } } = {
    [OPERATION_TYPE.HARVEST]: {
        width: 1200,
        Node: Harvest
    },
    [OPERATION_TYPE.TREATMENT]: {
        width: 1200,
        Node: Treatment
    },
    [OPERATION_TYPE.EVENT]: {
        width: 500,
        Node: Event
    },
    [OPERATION_TYPE.TRANSPORT]: {
        width: 1200,
        Node: Transport
    },
    [OPERATION_TYPE.SERVICE]: {
        width: 1200,
        Node: Service
    }
}

const PopupOperation: React.FC = () => {
    const popupData = useSelector(selectPlan.popupEdit);
    const {action, isShow, operationType} = popupData;
    const dispatch = useDispatch();
    const [opType, setOpType] = useState<OPERATION_TYPE | undefined>(operationType);

    const handleClose = useCallback(() => dispatch(planOpActions.togglePopupOperation({isShow: false})), [dispatch]);

    const handleSave = useCallback((params: IUpdateOp) => {
        dispatch(planOpActions.updateOps(params))
        if (action) {
            action();
        }
        setTimeout(handleClose, 100);
    }, [dispatch, action, handleClose]);

    useEffect(() => {
        if (isShow) {
            setOpType(operationType);
        }
    }, [isShow, operationType]);

    const {width, Node} = useMemo(() => views[`${opType}`] || {}, [opType]);

    return <Modal
        {...propsModal}
        open={isShow}
        title={<Header
            opType={opType}
            vessel={popupData?.values?.vessel}
            changeOpType={setOpType}
            onClose={handleClose}
        />}
        width={width}
        wrapClassName={styles.popup}
        styles={{body: {padding: 0, height: '100%'}}}
        modalRender={modal => <EditOperationProvider
            operation={popupData?.values?.operation}
            vessel={popupData?.values?.vessel}
            activity_log={popupData?.values?.activity_log}
            editMode={popupData.editMode}
            onSave={handleSave}
        >
            {modal}
        </EditOperationProvider>}
        onCancel={handleClose}
    >
        {Node && <Node popupData={popupData} onClose={handleClose}/>}
    </Modal>;
};

export default PopupOperation