import React, {createRef, useCallback, useEffect} from 'react';
import styles from "./style.module.scss";
import {Button, Tooltip} from "antd";
import {checkLimit, getElById} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import {PlusCircleOutlined} from "@ant-design/icons";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import Menu from "../../../../../../../components/Menu";
import AddNote from "../../../_Component/AddNote";
import DragEl from "../../../_Component/DragEl";
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";
import {IUnit} from "../../../../../../../util/varibles/interface";
import {callEventEditOp, getEditOp, IUnitWhenEdit, setEditOp} from "../../../../../../../contexts/EditOperationContext";
import {initTask} from "../../Treatment/constants";

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps {
    unit: IUnit
}

let dragEl: any, parentEl: any, root: any, isClose: boolean;

const Title: React.FC<IProps> = ({unit}) => {
    const rootRef = createRef<HTMLDivElement>()
    const {id: unitId, unit_id} = unit;
    const get = getEditOp();
    const set = setEditOp();
    const {turnOnCalculate} = callEventEditOp();
    const {name: siteName = ''} = get().site || {};

    const handleMouseMove = (e: any) => {
        parentEl.dataset.isDrag = 'group';
        root.dataset.isDrag = true;

        const {pageX, pageY} = e;
        dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = dragEl;
        dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        dragEl.style.left = left - 10 + 'px';
        dragEl.style.top = top - 10 + 'px';
        dragEl.style.display = 'block';
        document.body.style.cursor = 'grabbing';
    }

    useEffect(() => {
        const el = rootRef.current


        if (el) {
            const handleMouseDown = () => {
                window.addEventListener('selectstart', disableSelect);
                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);

                dragEl = openPopup(<DragEl {...{
                    data: [
                        {label: 'Site', value: siteName},
                        {label: 'Unit', value: unit_id},
                    ]
                }}/>)
                dragEl.style.position = 'absolute';
                dragEl.style.zIndex = '2000';
                dragEl.style.pointerEvents = 'none';
                dragEl.style.display = 'none';
            }
            setTimeout(() => {
                if (!isClose) {
                    parentEl = document.getElementById('service-operation');
                    if (el && parentEl) {
                        el.addEventListener("mousedown", handleMouseDown);
                        root = el.parentElement;
                    }
                }
            }, 100)
        }
        return () => {
            isClose = true;
            document.body.style.cursor = 'unset';
            window.removeEventListener('selectstart', disableSelect);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [rootRef, unit_id, siteName]);

    const handleMouseUp = (e: any) => {
        if (dragEl) {
            dragEl.remove();
            dragEl = undefined;
        }
        document.body.style.cursor = 'unset';
        delete parentEl.dataset.isDrag;
        delete root.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        if (!e.target)
            return;
        const {unitId: newPosition} = e.target.dataset;

        set(({units}) => {
            const {[unitId]: current, ...args} = units;
            if (newPosition && Object.keys(args).length > 0) {
                return {
                    units: Object.keys(args).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                        rs[key] = args[key];
                        if (key === newPosition)
                            rs[unitId] = current
                        return rs;
                    }, {})
                }
            } else
                return {units: {[unitId]: current, ...(args || {})}}
        })
        turnOnCalculate()
    }

    const handleAddTask = useCallback(() => {
        set(({units}) => {
            const value = initTask(units[unitId])

            return {units: {...units, [unitId]: {...units[unitId], tasks: [...units[unitId].tasks || [], value]}}}
        })
    }, [set, unitId])


    const handleDeleteUnit = useCallback(() => {
        set(({units}) => ({
            units: {...units, [unitId]: {...units[unitId], tasks: []}}
        }))
    }, [set, unitId])

    const handleSaveNote = useCallback((note: string) => {
        set(({units}) => ({units: {...units, [unitId]: {...units[unitId], note}}}))
    }, [unitId, set]);

    const {note = ''} = unit;

    return <div ref={rootRef} className={[styles['title'], stylesContainer['title']].join(' ')}>
        <div className={styles['left-section']}>
            <label>{[siteName, unit.unit_id].join(', ')}</label>
        </div>
        <div className={styles['right-section']}>
            <Tooltip title={note}>
                <div className={styles.note}>{note}</div>
            </Tooltip>
            <AddNote title={unit.unit_id} value={note} onChange={handleSaveNote}/>
            <Menu items={[
                {
                    key: 'delete',

                    label: <div
                        className='menu-line' data-danger='true'
                        onClick={handleDeleteUnit}
                    >
                        <Icon icon={ICON_NAME.DELETE} danger/>
                        <span>Delete unit</span>
                    </div>
                }
            ]} getPopupContainer={() => getElById('treatment-operation')}>
                <div className='three-dot'/>
            </Menu>
            <Button {...{
                shape: "circle",
                type: 'text',
                size: 'small',
                className: 'bt-add',
                icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                onClick: handleAddTask
            }}/>
        </div>
    </div>;
};

export default Title;