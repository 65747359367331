import React, {useState} from 'react';
import VesselFilter from "./VesselFilter";
import {Tooltip} from "antd";
import styles from "./style.module.scss";
import {CALENDAR_ID} from "../../constants";
import SendPlans from "./SendPlans";
import ButtonJumpCalendar from "../../../../components/Basic/ButtonJumpCalendar";
import {FilePdfOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import ExportPlan from "../../Popup/ExportPlan";
import RemoveOps from "./RemoveOps";
import PopupVessels from "../../Popup/Vessels";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import PopupChangesLog from "../../Popup/ChangeLog";

const HeaderComponent: React.FC = () => {
    const [changeLog, setChangeLog] = useState(false);

    const openExportPopup = () => {
        const el = openPopup(<ExportPlan onCancel={() => el.remove()}/>)
    }

    const handleClickSpotVessel = () => {
        const el = openPopup(<PopupVessels
            onClose={() => el.remove()}
        />)
    }

    const openChangeLog = () => {
        if (changeLog)
            return;

        setChangeLog(true)
        const el = openPopup(<PopupChangesLog
            onClose={() => {
                setChangeLog(false)
                el.remove()
            }}
        />)
    }

    return <div className={styles['view-header']}>
        <div className={styles['header-left']}>
            <span className='font-s1 font-w4'>Plan</span>
        </div>
        <div className={[styles['header-right'], 'pr-4'].join(' ')}>
            <ButtonJumpCalendar calenderId={CALENDAR_ID} getHeightWeek={() => 840}/>
            <VesselFilter/>
            <div className='bt-ghost h-32' onClick={openExportPopup}>
                <FilePdfOutlined className={styles['export-pdf']}/>
                <span>Pdf/Excel file</span>
            </div>
            <div className='bt-ghost h-32' onClick={openChangeLog}>
                <Icon icon={ICON_NAME.CHANGES_LOG}/>
                <span>Changes log</span>
            </div>
            <SendPlans>
                <RemoveOps/>
                <Tooltip title="Spot vessel">
                    <div className={styles['bt-add']} onClick={handleClickSpotVessel}>
                        <PlusCircleOutlined style={{fontSize: '18px'}}/>
                    </div>
                </Tooltip>
            </SendPlans>
        </div>
    </div>;
};

export default HeaderComponent;
