import React, {useCallback, useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {Modal, notification} from "antd";
import {ACTION_TYPE, OPERATION_CONFIRM_STATUS, OPERATION_TYPE, propsModal} from "../../../../util/varibles/constants";
import {generate} from "../../../PlanOperationPage/util";
import {crewSendFetch} from "../../../../util/services/vessel-side";
import {showErrorResponse} from "../../../../util/varibles/global";
import {IActivityLog, IOperation, IOperations, IVessel} from "../../../../util/varibles/interface";
import {template, TemplateCode} from "../../../../util/varibles/template";
import {overviewActions} from "../../reducer";
import Header from "../../../PlanOperationPage/Popup/EditOp/Header";
import styles from "../../../PlanOperationPage/Popup/EditOp/style.module.scss";
import Event from "../../../PlanOperationPage/Popup/EditOp/Body/Event";
import {IUpdateOp} from "../../../PlanOperationPage/constants";
import {loginActions} from "../../../LoginPage/reducer";
import {ICON_NAME} from "../../../../components/Icon";
import {EditOperationProvider} from "../../../../contexts/EditOperationContext";

const mapStateToProps = () => ({});

interface ITitle {
    title: string
    isAllowChange?: boolean
    icon: ICON_NAME
}

interface IPopup {
    propsTitle: ITitle
    content: any
    opType: OPERATION_TYPE
    width: number
    className?: string
}

const initPopup: IPopup = {
    propsTitle: {
        title: '',
        isAllowChange: false,
        icon: ICON_NAME.EVENT
    },
    className: '',
    content: null,
    opType: OPERATION_TYPE.EVENT,
    width: 1080
}

interface IProps {
    visible: boolean
    editMode: ACTION_TYPE
    data: {
        vessel: IVessel
        operation: {
            operation_type: OPERATION_TYPE
        } & any
        activity_log: IActivityLog[]
    }

    onClose?(): void

    addOps(operations: IOperations): void

    updateOps(operations: IOperations): void
}

const PopupEdit: React.FC<IProps> = (props) => {
    const {visible, data, editMode = ACTION_TYPE.EDIT, addOps, updateOps, onClose} = props;

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(visible);
    const [popup, setPopup] = useState(initPopup);
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        setOpen(false);
        if (onClose)
            setTimeout(onClose, 300);
    }, [onClose])

    const handleSendOp = useCallback((operations: any) => {
        const vessels = operations.reduce((rs: any, item: IOperation) => {
            const operation = generate[item.operation.operation_type](item)

            const indexVessel = rs.findIndex((sub: any) => sub.vessel_id === item.vessel.id);
            if (indexVessel === -1) {
                rs.push({
                    vessel_id: item.vessel.id,
                    vessel_owner_id: item.vessel.tenant_id,
                    operations: [operation]
                })
            } else {
                rs[indexVessel].operations.push(operation)
            }

            return rs;
        }, []);
        setLoading(true);
        new Promise(resolve => resolve(crewSendFetch({vessels})))
            .then((rs: any) => {
                const {new_operations, old_operations} = rs;
                const {vessel} = data;

                const {newCode, newOps} = new_operations.reduce((rs: any, item: any) => {
                    rs.newCode = [...rs.newCode, `#${item.operation.operation_code}`];
                    rs.newOps = [...rs.newOps, {...item, vessel}];
                    return rs;
                }, {newCode: [], newOps: []});

                const {oldCode, oldOps} = old_operations.reduce((rs: any, item: any) => {
                    rs.oldCode = [...rs.oldCode || [], `#${item.operation.operation_code}`];
                    rs.oldOps = [...rs.oldOps || [], {...item, vessel}];
                    return rs;
                }, {oldCode: [], oldOps: []});

                notification.success({
                    message: (editMode === ACTION_TYPE.CREATE ? 'Create' : 'Update') + ' successfully',
                    description: template[TemplateCode.T2]('', newCode, oldCode)
                });
                setLoading(false);
                addOps(newOps);
                updateOps(oldOps);
                dispatch(loginActions.updateOpsOfVessel([...newOps, ...oldOps]));

                handleClose();
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
                showErrorResponse('Error', error);
            })
    }, [editMode, addOps, handleClose, dispatch, data, updateOps])

    const renderContent = useCallback((opType: OPERATION_TYPE) => {
        const propsTitle = initPopup.propsTitle;

        const common = {
            loading,
            editMode,
            onSave: (params: IUpdateOp) => {
                const {add = {}, update = {}} = params;
                const list = [
                    ...Object.keys(add).reduce((rs: any, key) => [...rs, {
                        ...add[key],
                        action_type: OPERATION_CONFIRM_STATUS.NEW
                    }], []),
                    ...Object.keys(update).reduce((rs: any, key) => [...rs, {
                        ...update[key],
                        action_type: update[key].action_type || OPERATION_CONFIRM_STATUS.UPDATE
                    }], [])
                ]
                handleSendOp(list);
            }
        }
        let state;
        switch (opType) {
            case OPERATION_TYPE.EVENT: {
                const content = <Event {...{...common, values: data, onClose: handleClose}} />

                state = {
                    propsTitle,
                    content,
                    width: 480,
                    opType
                }
                break;
            }
            default:
                state = initPopup
        }
        setPopup(state);

    }, [data, loading, editMode, handleClose, handleSendOp])

    useEffect(() => {
        if (visible)
            renderContent(data.operation.operation_type);
    }, [visible, data, loading, editMode, renderContent]);

    if (!popup.content)
        return null;

    const handleChangeOpType = (type: OPERATION_TYPE) => renderContent(type);

    return <Modal
        {...propsModal}
        open={open}
        title={<Header
            vessel={data.vessel}
            opType={popup.opType}
            changeOpType={handleChangeOpType}
            onClose={handleClose}
        />}
        width={popup.width}
        wrapClassName={[styles.popup, popup.className].join(' ')}
        styles={{body: {padding: 0, height: '100%'}}}
        modalRender={modal => <EditOperationProvider
            operation={data.operation}
            vessel={data.vessel}
            activity_log={data.activity_log}
        >
            {modal}
        </EditOperationProvider>}
        onCancel={handleClose}
    >
        {popup.content}
    </Modal>;
};

export default connect(mapStateToProps, {
    addOps: overviewActions.addOps,
    updateOps: overviewActions.updateOps
})(PopupEdit);