import styles from "./style.module.scss";
import {ICreateDialog, IDialog, IPropsMap, MAP_ELEMENT} from "../constants";
import React, {ReactNode} from "react";
import DiseaseZone from "./DiseaseZone";
import Site from "./Site";
import Factory from "./Factory";
import Vessel from "./Vessel";
import ProductionArea from "./ProductionArea";
import ReactDOM, {Root} from "react-dom/client";
import Poi from "./Poi";
import {cloneObj} from "../../../varibles/global";

declare const google: any;

export const createDialog = (args: ICreateDialog & IPropsMap | any) => {
    const {map = null, elements, position, contentType, currentTime = Date.now(), event, lock = false} = args;
    const data = cloneObj(args.data);

    const content = popupView({
        type: contentType,
        currentTime,
        data,
        onClick: (data: any) => {
            const {click} = event;
            if (click)
                click(data, contentType);
        }
    })

    const {id} = data;

    if (elements.dialog[id]) {
        elements.dialog[id].element.setPosition(position);
        elements.dialog[id].element.setContent(content);
        elements.dialog[id].lock = lock;
    } else {
        const render = (el: any) => {
            const root = ReactDOM.createRoot(el as HTMLElement);
            root.render(content);
            return root;
        };
        elements.dialog[id] = {
            element: generateDialog(position, render),
            info: data,
            lock,
            type: contentType,
        };
    }

    elements.dialog[id].element.setMap(map);
    return elements.dialog
}

interface IPopupView {
    type: MAP_ELEMENT
    data: any
    currentTime: number

    onClick(data: any): void
}

export const popupView = (args: IPopupView) => {
    const {type, data} = args;
    switch (type) {
        case MAP_ELEMENT.SITE: {
            return <Site data={data}/>
        }
        case MAP_ELEMENT.FACTORY: {
            return <Factory data={data}/>
        }
        case MAP_ELEMENT.POI: {
            return <Poi data={data}/>
        }
        case MAP_ELEMENT.VESSEL: {
            const {currentTime} = args;
            return <Vessel data={data} currentTime={currentTime}/>
        }
        case MAP_ELEMENT.DISEASED_ZONE: {
            const {onClick} = args;
            return <DiseaseZone data={data} onClick={onClick}/>
        }
        case MAP_ELEMENT.PRODUCTION_AREA: {
            return <ProductionArea data={data}/>
        }
        default:
            return <div/>
    }
}

export function generateDialog(position: google.maps.LatLng, render: Function) {
    class Dialog extends google.maps.OverlayView {
        position: google.maps.LatLng;
        containerDiv: HTMLDivElement;
        root: Root;

        constructor(position: google.maps.LatLng, render: Function) {
            super();
            this.position = position;
            this.containerDiv = document.createElement("div");
            this.containerDiv.classList.add(styles['popup-container']);
            const bubbleAnchor = document.createElement("div");
            bubbleAnchor.classList.add(styles['popup-bubble-anchor']);
            const body = document.createElement("div");
            body.classList.add(styles['popup-bubble']);
            bubbleAnchor.appendChild(body);
            this.root = render(body);
            this.containerDiv.appendChild(bubbleAnchor);
        }

        setContent(content: ReactNode) {
            this.root.render(content);
        }

        setPosition(position: any) {
            this.position = position;
        }

        onAdd() {
            this.getPanes().floatPane.appendChild(this.containerDiv);
            this.containerDiv.onclick = function (event) {
                event.stopPropagation();
            }
        }

        onRemove() {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv);
            }
        }

        draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(
                this.position
            );
            const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";
            if (display === "block") {
                this.containerDiv.style.opacity = '1';
                this.containerDiv.style.visibility = 'visible';
                this.containerDiv.style.left = divPosition.x + "px";
                this.containerDiv.style.top = divPosition.y + "px";
            } else {
                this.containerDiv.style.opacity = '0';
                this.containerDiv.style.visibility = 'hidden';
            }
        }
    }


    return new Dialog(position, render);
}


export function closeAllInfoWindow(dialog: IDialog, except: string[] = []) {
    const ids = new Set(except)
    Object.keys(dialog || {}).forEach(key => {
        if (!ids.has(key)) {
            dialog[key].element.setMap(null);
            dialog[key].lock = false;
        }
    })
}