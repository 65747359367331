import {LEVEL_DENSITY, levelDensity} from "../../../OperationSettingPage/Density/constants";
import {IActivityLog} from "../../../../util/varibles/interface";
import {OPERATION_MODE} from "../../../../util/varibles/constants";

export const FuncOp = {
    BeginMerge: 'begin-merge',
    BeginCreate: 'begin-create',

    UncheckBiology: 'uncheck-biology',
    UpdateActivityLog: 'update-activity-log'
}

export function getStatusDensity(density: number[], value: number) {
    let i = 0, length = density.length;
    for (i; i < length; i++) {
        if (value > density[i])
            continue;

        break;
    }

    if (i === 0) {
        return levelDensity[LEVEL_DENSITY.LOW]
    } else if (i === length) {
        return levelDensity[LEVEL_DENSITY.HIGH]
    } else {
        return levelDensity[LEVEL_DENSITY.MEDIUM][i - 1] || {}
    }
}

export function getStatusDensity2(maxDensity: number, density: number ) {
    if (maxDensity >= density) {
        return {color: '#7CB6B1'}
    } else {
        return {color: '#E95D5D', colorText: '#E95D5D'}
    }
}

export const getTimeByOpMode = {
    [OPERATION_MODE.PLAN]: (step: IActivityLog) => step.est_start_time,
    [OPERATION_MODE.ETA]: (step: IActivityLog) => step.real_start_time || step.eta || step.est_start_time,
}