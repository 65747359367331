import React from 'react';
import {ISite, IUnit} from "../../../../util/varibles/interface";
import Location from "../../BiologyBrowser/_Component/Location";
import FishAndWeight from "../../BiologyBrowser/_Component/FishAndWeight";
import FishDisease from "../../../../components/Location/FishDisease";
import UnitDiseases from "../../BiologyBrowser/_Component/UnitDiseases";
import {SITE_TYPE} from "../../../../util/varibles/constants";
import styles from './style.module.scss';
import UnitLice from "../../../../components/Operation/UnitLice";

interface IProps {
    site?: ISite
    selected?: IUnit

    onChange(selected?: IUnit): void;
}

const Units: React.FC<IProps> = ({site, selected, onChange}) => {
    if (!site)
        return null;

    const {type, units, diseases = []} = site;
    const isInternal = type === SITE_TYPE.INTERNAL;

    return <div>
        <div className={styles.lineName}>
            All units
        </div>
        <div className={styles.units}>
            {units.map(item => {
                const isActive = item.id === selected?.id
                const {lice} = item;
                return <div
                    className={styles.unit}
                    key={item.id}
                    data-empty={item.fish_amount === 0 && isInternal}
                    data-active={item.id === selected?.id}
                    onClick={() => onChange(isActive ? undefined : item)}
                >
                    <div className={styles.unitLine}>
                        <Location source={[item.unit_id]}/>
                        {isInternal && <FishAndWeight data={item}/>}
                    </div>
                    <div className={styles.unitLine} data-right={true}>
                        <UnitLice lice={lice}/>
                        <FishDisease diseases={diseases} units={[item]} short={true}/>
                        <UnitDiseases data={item.unit_diseases}/>
                    </div>
                </div>
            })}
        </div>
    </div>;
};

export default Units;
