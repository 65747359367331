import React, {useState} from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {filterCapabilities, vesselOpType} from "../";

interface IProps {
    onChange(value: any): void
}

const Capabilities: React.FC<IProps> = ({onChange}) => {
    const [value, setValue] = useState(new Set<string>([]));
    const [open, setOpen] = useState(false);
    const root: any = React.useRef(null);

    const handleClick = () => {
        if (!open && root.current)
            root.current.focus();

        setOpen(!open);
    }

    const handleSelect = (checked: boolean, id: string) => {
        const selected = new Set(value);
        if (checked)
            selected.add(id);
        else
            selected.delete(id);
        setValue(selected);
        onChange(Array.from(selected))
    }

    const handleClear = () => {
        setValue(new Set<string>([]));
        onChange([])
    }

    const list = Array.from(value);
    const result = list.map((key: any) => {
        const {name = null} = vesselOpType[key] || {};
        return name
    }).join(', ');

    return <div className={styles.wrapper} ref={root} tabIndex={0} onBlur={() => setOpen(false)}>
        <div className={styles.view} onClick={handleClick}>
            {list.length === 0 ?
                <div className={styles.placeholder}>Filter by capabilities</div>
                : <>
                    <div className={styles.selected} title={result}>{result}</div>
                    <div className={styles.close} onClick={handleClear}>
                        <Icon icon={ICON_NAME.CLOSE} size='small'/>
                    </div>
                </>
            }
        </div>
        <div className={styles.dropdown}>
            <div className={styles.options}>
                {filterCapabilities.map((item: any, index: number) => <div className={styles.section} key={index}>
                    {item.map(({id, name}: any) => <div
                        key={id}
                        className={styles.option}
                        data-selected={value.has(id)}
                        onClick={() => handleSelect(!value.has(id), id)}
                    >
                        {name}
                    </div>)}
                </div>)}
            </div>
        </div>
    </div>;
};

export default Capabilities;
