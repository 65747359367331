import React, {Component} from 'react';
import styles from "./style.module.scss";
import {Button, Input, Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {updateOperationNoteFetch} from "../../../../../util/services/operation";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {TOperation} from "../../../../../util/varibles/interface";
import {showErrorResponse} from "../../../../../util/varibles/global";
import Icon, {ICON_NAME} from "../../../../Icon";

interface IProps {
    isEdit: boolean
    note: string
    operation?: TOperation

    handleUpdate(value: string): void
}

class Note extends Component<IProps> {
    state = {
        isOpen: false,
        note: '',
        loading: false
    }

    inputRef: any = React.createRef();

    constructor(props: IProps) {
        super(props);
        this.state.note = this.props.note;
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        const {note} = nextProps
        if (this.props.note !== note)
            this.setState({note})
        return this.state !== nextState
    }

    handleChange = (e: any) => {
        const {value} = e.target;
        if (value.length <= 500)
            this.setState({note: value})
    }

    handleEdit = () => {
        const {isEdit} = this.props;
        if (isEdit)
            this.setState({isOpen: true}, () => this.inputRef.current!.focus({cursor: 'end'}));
    }

    handleDelete = () => {
        const {isOpen} = this.state;
        if (isOpen)
            this.setState({note: ''}, () => this.inputRef.current!.focus({cursor: 'end'}));
        else {
            Modal.confirm({
                title: 'Do you want to delete the note?',
                icon: <ExclamationCircleOutlined/>,
                onOk: () => {
                    this.props.handleUpdate('');
                    this.setState({note: ''});
                },
            });
        }
    }

    handleClose = () => {
        this.setState({isOpen: false, note: this.props.note});
    }

    handleUpdate = () => {
        const note = this.state.note.trim();
        const {operation} = this.props;
        if (operation) {
            const {id, operation_code} = operation;
            this.setState({loading: true});
            new Promise(resolve => resolve(updateOperationNoteFetch({note}, id)))
                .then(() => {
                    notify.success(NotifyCode.S2)([`Note of #${operation_code}`]);
                    this.setState({loading: false, isOpen: false});
                    this.props.handleUpdate(note);
                })
                .catch(error => {
                    this.setState({loading: false});
                    showErrorResponse('Update failed', error)
                })
        } else
            this.setState({isOpen: false, note}, () => this.props.handleUpdate(note.trim()));
    }

    render() {
        const {isEdit} = this.props;
        const {isOpen, note, loading} = this.state;
        return <div className={styles['info-note']} data-is-allow={isEdit} data-is-edit={isOpen}>
            <div className={styles.header}>
                <div className={styles.title}>Note</div>
                {isEdit && <div className={styles.action}>
                    {isOpen ? <>
                        <Button className={styles.cancel} onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button className={styles.save} loading={loading} onClick={this.handleUpdate}>
                            Save
                        </Button>
                    </> : <div className={styles.edit} onClick={this.handleEdit}>
                        <Icon icon={ICON_NAME.EDIT}/>
                    </div>}
                    <div className={styles.delete} onClick={this.handleDelete}>
                        <Icon danger icon={ICON_NAME.DELETE}/>
                    </div>
                </div>}
            </div>
            <div className={styles.body}>
                {isEdit && isOpen ? <Input.TextArea  {...{
                    className: 'scroll-small',
                    ref: this.inputRef,
                    value: note,
                    maxLength: 150,
                    showCount: true,
                    onChange: this.handleChange,
                }}/> : <div onClick={this.handleEdit}>
                    {note}
                </div>}
            </div>
        </div>;
    }
}

export default Note;
