import {all} from "@redux-saga/core/effects";
import loginSaga from "../../pages/LoginPage/saga";
import messageSaga from "../../pages/MessagePage/saga";
import dashboardSaga from "../../pages/DashboardPage/saga";
import planOperationSaga from "../../pages/PlanOperationPage/saga";
import overviewSaga from "../../pages/OverviewPage/saga";
import operationDetailSaga from "../../pages/OperationDetailPage/saga";
import diseaseSaga from "../../pages/DiseasePage/saga";
import combineTreatmentSaga from "../../pages/CombineTreatmentPage/saga";
import routeSaga from "../../pages/RoutePage/saga";
import checklistSaga from "../../pages/ChecklistPage/saga";
import configurationSaga from "../../pages/ConfigurationPage/saga";
import taskTypeSaga from "../../pages/TaskTypeManagementPage/saga";
import vesselManagementSaga from "../../pages/VesselManagementPage/saga";
import vesselDetailSaga from "../../pages/VesselDetailPage/saga";
import userSaga from "../../pages/UserPage/saga";
import weatherSaga from "../../pages/WeatherPage/saga";
import locationSaga from "./location/saga";
import regionSaga from "./region/saga";
import siteMonitoringSaga from "../../pages/SiteMonitoringPage/saga";

export default function* rootSaga() {
    yield all([
        loginSaga(),
        dashboardSaga(),
        messageSaga(),
        planOperationSaga(),
        overviewSaga(),
        operationDetailSaga(),
        diseaseSaga(),
        combineTreatmentSaga(),
        routeSaga(),
        checklistSaga(),
        configurationSaga(),
        locationSaga(),
        regionSaga(),
        taskTypeSaga(),
        vesselDetailSaga(),
        vesselManagementSaga(),
        userSaga(),
        weatherSaga(),
        siteMonitoringSaga()
    ])
}
