import React, {ReactNode, useEffect} from 'react';
import Treatment from "./Treatment";
import styles from "./style.module.scss";
import {Select} from "antd";
import WrapperTask from "../WrapperTask";
import {filterOption, uuid} from "../../../../../../../util/varibles/global";
import {aMinutesMillisecond, TASK_TYPE, TREATMENT_TYPE} from "../../../../../../../util/varibles/constants";
import {ICombineTreatment, TTaskTreatment} from "../../../../../../../util/varibles/interface";
import MoveToUnit from "./MoveToUnit";
import Combine, {IPropsTask} from "./Combine";
import {propsSelect} from "../../../../../../../util/varibles/template";
import {initTask} from "../constants";
import {IUnitWhenEdit, setEditOp, SPLIT_KEY, useEditOp} from "../../../../../../../contexts/EditOperationContext";

interface IPropsSelectTask {
    unit: IUnitWhenEdit
    value: string | null,

    onChange(value: string, opt?: any): void
}

const SelectTask: React.FC<IPropsSelectTask> = ({unit, value, onChange}) => {
    const {isLock, isSorting} = unit;
    const [vesselTasks] = useEditOp(state => state.vesselTasks);

    return <Select
        {...propsSelect}
        loading={vesselTasks.loading}
        disabled={isLock}
        className='w-full max-w-[180px]'
        placeholder="Select a task"
        variant={"borderless"}
        showSearch
        value={vesselTasks.loading ? null : value}
        popupClassName={styles['dropdown-custom']}
        onChange={onChange}
        filterOption={filterOption}
        popupMatchSelectWidth={false}
        options={vesselTasks.data.map(item => ({
            value: `${item.id}`,
            data: item,
            ...(isSorting && `${item.id}`.includes([TASK_TYPE.TREATMENT, TREATMENT_TYPE.SORTING].join(SPLIT_KEY))
                ? {
                    disabled: true,
                    label: item.name,
                    "data-inactive": true
                }
                : {
                    label: item.name,
                    disabled: false
                }),
        }))}
    />
}

const view: { [id: string]: (props: IPropsTask) => ReactNode } = {
    [TASK_TYPE.TREATMENT]: (props: IPropsTask) => <Treatment {...props}/>,
    [TASK_TYPE.MOVE]: (props: IPropsTask) => <MoveToUnit {...props}/>,
    [TASK_TYPE.COMBINE]: (props: IPropsTask) => <Combine {...props} data={props.data as ICombineTreatment}/>,
}

interface IProps {
    unit: IUnitWhenEdit
    data: TTaskTreatment
    condition?: string
    taskParentId?: string
    isAllowDrag?: boolean

    onUpdate(task: TTaskTreatment, args?: { sorting?: boolean }): void

    onDelete(): void
}

const Task: React.FC<IProps> = ({unit, data, condition, taskParentId, isAllowDrag = true, onUpdate, onDelete}) => {
    const {type = '', treatment_type = null, type_id = null, error = {}, sub_operation, group_id} = data;
    const set = setEditOp();

    useEffect(() => {
        return () => {
            set(({supportTasks}) => ({
                supportTasks: supportTasks.filter(item => item.group_id !== group_id && item.sub_operation.id !== sub_operation.id)
            }))
        }
    }, [sub_operation, group_id, set])

    const handleUpdateTask = (change: Partial<TTaskTreatment>) => {
        if (unit.isLock)
            return;
        onUpdate({...data, ...change});
    }

    const handleChangeTaskType = (value: string, opt: any) => {
        const {group_id, sub_operation} = data;
        const [newType, newSubType = null] = value.toString().split(SPLIT_KEY);

        if (+newType === TASK_TYPE.COMBINE) {
            if (type_id !== newSubType) {
                const {data: combineTask} = opt;
                onUpdate({
                    ...initTask(sub_operation),
                    group_id,
                    type: TASK_TYPE.COMBINE,
                    type_id: newSubType,
                    type_name: combineTask.name,
                    sub_tasks: combineTask.sub_types.map((item: any) => {
                        const {treatment_type = null, duration} = item;
                        let sub = {
                            type: item.type,
                            sub_operation,
                            treatment_type,
                            sub_tasks: {},
                            group_id: uuid(),
                            error: {}
                        };

                        return duration ? {...sub, duration: duration * aMinutesMillisecond} : sub;
                    })
                } as TTaskTreatment);
            }
        } else if (type !== +newType || `${treatment_type}` !== `${newSubType}`)
            onUpdate({
                ...initTask(sub_operation),
                group_id,
                type: +newType || type,
                treatment_type: newSubType === null ? newSubType : +newSubType
            } as TTaskTreatment, {sorting: true});
    }


    const styleError = {height: '0'}
    let isError = false;
    if (Object.keys(error).length > 0) {
        styleError.height = (Object.keys(error).length * 20 + 3) + 'px';
        isError = true;
    }

    let taskType = null;
    if (type) {
        taskType = (type_id || treatment_type) !== null ? [type, type_id || treatment_type].join(SPLIT_KEY) : type.toString();
    }

    const {[type || '']: content = () => null} = view;
    return <>
        <WrapperTask key={data.group_id + 'task'} {...{
            unit,
            data: {elementId: 1, ...data},
            condition,
            taskParentId,
            isError,
            isAllowDrag,
            index: data.index,
            onChange: handleUpdateTask,
            onDelete,
        }}>
            <SelectTask value={taskType} unit={unit} onChange={handleChangeTaskType}/>
            {content({data, unit, onUpdate: handleUpdateTask})}
        </WrapperTask>
        <div className={styles['task-error']} style={styleError} key={data.group_id + 'error'}>
            {Object.keys(error).map(key =>
                <div className={styles['error-line']} key={key} dangerouslySetInnerHTML={{__html: error[key]}}/>)}
        </div>
    </>
};

export default Task;
