import {FORM_TYPES, initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";

const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        addServer: (state, action) => {
            const {values, formType} = action.payload;
            const {device} = state;
            const {configuration} = device;
            if (!configuration) return state;
            const {servers, objects} = configuration
            switch (formType) {
                case FORM_TYPES.SERVER: {
                    const id = servers.length > 0 ? servers[servers.length - 1].id : 0
                    state.device = {
                        ...device,
                        configuration: {
                            ...configuration, servers: [...servers, {...values, id: id + 1}]
                        }
                    };
                    state.isChange = true;
                    break;
                }
                case FORM_TYPES.OBJECT: {
                    const id = objects.length > 0 ? objects[objects.length - 1].id : 0
                    state.device = {
                        ...device,
                        configuration: {
                            ...configuration, objects: [...objects, {...values, id: id + 1, tags: []}]
                        }
                    };
                    state.isChange = true;
                    break;
                }
                case FORM_TYPES.TAG: {
                    const indexObject = objects.findIndex((item: any) => item.id === values.id);
                    objects.splice(indexObject, 1, values);
                    state.device = {...device, configuration: {...configuration, objects}};
                    state.isChange = true;
                    break;
                }
                default:
                    return state;
            }
        },
        updateServer: (state, action) => {
            const {values, index, formType} = action.payload;
            const {device} = state;
            const {configuration} = device;
            if (configuration) {
                const {servers, objects} = configuration
                switch (formType) {
                    case FORM_TYPES.SERVER: {
                        servers.splice(index, 1, values);
                        state.device = {...device, configuration: {...configuration, servers}};
                        state.isChange = true;
                        break;
                    }
                    case FORM_TYPES.OBJECT: {
                        objects.splice(index, 1, values);
                        state.device = {...device, configuration: {...configuration, objects}};
                        state.isChange = true;
                        break;
                    }
                    case FORM_TYPES.TAG: {
                        state.device = {...device, configuration: {...configuration, objects}};
                        state.isChange = true
                        break;
                    }
                }
            }
        },
        deleteServer: (state, action) => {
            const {index, formType, object}: any = action.payload;
            const {device} = state;
            const {configuration} = device;
            if (configuration) {
                const {servers, objects} = configuration
                switch (formType) {
                    case FORM_TYPES.SERVER: {
                        servers.splice(index, 1);
                        state.device = {...device, configuration: {...configuration, servers}};
                        state.isChange = true
                        break;
                    }
                    case FORM_TYPES.OBJECT: {
                        objects.splice(index, 1);
                        state.device = {...device, configuration: {...configuration, objects}};
                        state.isChange = true
                        break;
                    }
                    case FORM_TYPES.TAG: {
                        const indexObject = objects.findIndex((item: any) => item.id === object.id);
                        objects[indexObject].tags.splice(index, 1);
                        state.device = {...device, configuration: {...configuration, objects}};
                        state.isChange = true
                        break;
                    }
                }
            }
        },
        selectConfiguration: (state, action) => {
            let target = action.payload;
            const {selectedKeys, info} = target;
            if (selectedKeys.length > 0) {
                const {data} = info.node;
                target = data;
            } else {
                target = undefined;
            }
            state.target = target;
        },
        getDevicesRequest: (state, action) => {
            state.iotName = action.payload.iotName;
        },
        getDevicesSuccess: (state, action) => {
            const devices = action.payload || [];
            state.device = devices[0] || {};
            state.devices = devices;
            state.isChange = false;
        },
        changeDevice: (state, action) => {
            state.device = action.payload || {};
        },
        sendDeviceRequest: (state, action) => {
            state.isLoading = true;
        },
        sendDeviceSuccess: (state) => {
            state.isLoading = false;
            state.isChange = false;
        },
        sendDeviceFailure: (state) => {
            state.isLoading = false;
        }
    },
})

export const configurationActions = configurationSlice.actions

const configurationReducer = configurationSlice.reducer;

export default configurationReducer;