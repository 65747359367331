import React, {useCallback} from 'react';
import {IVessel} from "../../../../../util/varibles/interface";
import {openPopup} from "../../../../../components/Popup/Component/WrapperPopup";
import PopupClear from "../../../Popup/Clear";
import {Tooltip} from "antd";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import {connect} from 'react-redux';
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    vessels: state.planOperation.vessels
});

interface IProps {
    vessels: IVessel[]
}

const RemoveOps: React.FC<IProps> = ({vessels}) => {
    const handleClick = useCallback(() => {
        const ids = vessels.reduce((list: any, {id, isShow}: any) => {
            if (isShow)
                list.push(id);
            return list;
        }, [])
        const div = openPopup(<PopupClear vesselIds={ids} onClose={() => div.remove()}/>);
    }, [vessels]);


    return <Tooltip title="Remove operations">
        <div
            data-danger='true'
            className='bt-ghost'
            data-icon={true}
            onClick={handleClick}
        >
            <Icon icon={ICON_NAME.DELETE} size='large' danger/>
        </div>
    </Tooltip>;
};

export default connect(mapStateToProps, {})(RemoveOps);