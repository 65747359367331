import React, {Component} from 'react';
import {Modal, Spin} from "antd";
import styles from './style.module.scss';
import WeatherChart from "../../../../components/WeatherChart";

interface IProps {
    isShow: boolean
    loading: boolean
    weather: any
    name: string

    childRef(ref: any): void

    onClose(): void
}

class PopupWeather extends Component<IProps> {
    state = {
        isShow: true,
    }

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    handleClose = () => {
        this.setState({isShow: false});
        setTimeout(() => this.props.onClose(), 300);
    }

    render() {
        const {isShow} = this.state;
        const {name, loading, weather} = this.props;

        return <Modal {...{
            title: <span className='font-w5'>{isShow ? `Weather for ${name}` : ''}</span>,
            open: isShow,
            centered: true,
            width: 800,
            footer: null,
            onCancel: this.handleClose,
            mask: false,
            wrapClassName: styles['modal-custom']
        }} >
            <Spin spinning={loading}>
                {isShow && <WeatherChart {...{loading, weather,}}/>}
            </Spin>
        </Modal>;
    }
}

export default PopupWeather;
