import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty} from "./constants";
import styles from "./style.module.scss";

interface IProps extends IPropsPropertyElement {

}

class HeaderElement extends Component<IProps> {
    render() {
        const {selected} = this.props;
        if (!selected) return null;

        const {label, properties: {subLabel, fontSize, textAlign}} = selected;
        return <>
            <div className={styles['title']}>Header Properties</div>
            <div className={styles['property-element']}>
                <label>Heading Text</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
            </div>
            <div className={styles['property-element']}>
                <label>Sub-Heading Text</label>
                <input type="text" value={subLabel}
                       onChange={(e) => onChangeProperty({subLabel: e.currentTarget.value}, this.props)}
                />
                <span>Small text below the heading.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Heading Size</label>
                <div className={styles['options-text']}>
                    <div data-is-active={fontSize === 'default'}
                         onClick={() => onChangeProperty({fontSize: 'default'}, this.props)}
                    >
                        Default
                    </div>
                    <div data-is-active={fontSize === 'large'}
                         onClick={() => onChangeProperty({fontSize: 'large'}, this.props)}
                    >
                        Large
                    </div>
                    <div data-is-active={fontSize === 'small'}
                         onClick={() => onChangeProperty({fontSize: 'small'}, this.props)}
                    >
                        Small
                    </div>
                </div>
                <span>Small text below the heading.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Text Alignment</label>
                <div className={styles['options-text']}>
                    <div data-is-active={textAlign === 'left'}
                         onClick={() => onChangeProperty({textAlign: 'left'}, this.props)}
                    >
                        Left
                    </div>
                    <div data-is-active={textAlign === 'center'}
                         onClick={() => onChangeProperty({textAlign: 'center'}, this.props)}
                    >
                        Center
                    </div>
                    <div data-is-active={textAlign === 'right'}
                         onClick={() => onChangeProperty({textAlign: 'right'}, this.props)}
                    >
                        Right
                    </div>
                </div>
                <span>Small text below the heading.</span>
            </div>
        </>;
    }
}

export default HeaderElement;
