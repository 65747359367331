import React, {Component} from 'react';

class TankComponent extends Component {
    render() {
        return <>

        </>;
    }
}

export default TankComponent;
