import React, {useEffect, useState} from 'react';
import styles from './style.module.scss'
import {connect} from "react-redux";
import {KEY_STORAGE, PATHNAME, patternEmail} from "../../util/varibles/constants";
import {Button, Form, Input} from "antd";
import {FormInstance} from "antd/lib/form";
import {Auth} from 'aws-amplify';
import {WindowsOutlined} from "@ant-design/icons/lib";
import {loginActions} from "./reducer";
import {Navigate} from "react-router-dom";
import {AppState} from "../../util/store/store";
import {showErrorResponse} from "../../util/varibles/global";

const support_email = 'support@ocean-planner.no';
const support_phone = '+47 455 00 924'

enum SSO_MOD {
    AZURE = 'azure',
    COGNITO = 'cognito'
}

const mapStateToProps = (state: AppState) => ({
    user_id: state.login.user.user_id,
    domains: state.login.domains,
});

interface IProps {
    user_id: string
    domains: any

    getDomains(): void

    login(payload: any): void
}

const LoginPage: React.FC<IProps> = (props) => {
    const {domains = [], getDomains} = props;
    const [loading, setLoading] = useState(false);
    const formRef = React.createRef<FormInstance>();
    const [form, setForm] = useState({
        domain: '',
        username: false,
        password: false
    });

    useEffect(() => {
        getDomains();
        setTimeout(() => {
            const container = document.getElementById('login-screen');
            const elements = Array.from(container?.getElementsByTagName('input') || []);
            if (elements[0])
                elements[0].focus();
        }, 1000)
    }, [getDomains]);

    const handleSubmit = (values: any) => {
        const {domain} = form;
        const loginType = domains.find((item: any) => item.domain === domain) || {}
        const {sso_mode, provider_name} = loginType;
        const {username, password} = values;
        if (sso_mode && sso_mode === SSO_MOD.AZURE) {
            const options: any = {customProvider: provider_name};
            const response: any = {};
            const user: any = {username, email: username};
            Auth.federatedSignIn(options, response, user).then();
        } else {
            setLoading(true);
            new Promise(resolve => resolve(Auth.signIn(username.trim(), password.trim())))
                .then((rs: any) => {
                    setLoading(false);
                    props.login(rs);
                })
                .catch(error => {
                    setLoading(false);
                    showErrorResponse('Login failure', error);
                })
        }
    }

    const handleChangeUsername = (e: any) => {
        const {value} = e.target;
        setForm(prev => ({...prev, domain: value.match(patternEmail) ? value.split('@')[1] : ''}))
    }

    const toggleFocus = (key: any, value: boolean) => setForm(prev => ({...prev, [key]: value}));

    if (localStorage.getItem(KEY_STORAGE.AUTHENTICATION))
        return <Navigate to={PATHNAME.HOME} replace/>;

    const loginType = domains.find((item: any) => item.domain === form.domain) || {}
    const {sso_mode} = loginType;

    return <div className={styles['background-login']}>
        <div className={styles['container-logo']}>
            <div className={styles['section-logo']}>
                <div className={styles['space-logo']}/>
                <div className={styles['logo']}/>
            </div>
            <div className={styles['section-contract']}>
                <div className={styles['space-logo']}/>
                <div className={styles['container-contact']}>
                    <div className={styles['contact']} title={`Support contact: ${support_email} ${support_phone}`}>
                        <div>Support contact:</div>
                        <div title={support_email} className='mr-1'>{support_email}</div>
                        <div title={support_phone}>{support_phone}</div>
                    </div>
                </div>
                <div className={styles['space-logo']}/>
            </div>
        </div>
        <div className={styles['container-popup']} id='login-screen'>
            <div className={styles['space-popup']}/>
            <div className={styles.popup}>
                <p className={styles['title']}>Sign in</p>
                <p className='text-[15px] mb-5'>Login with your Ocean Planner account or your corporate credentials</p>
                <Form
                    autoComplete='off'
                    ref={formRef}
                    layout="vertical"
                    onFinish={handleSubmit}
                    requiredMark='optional'
                >
                    <Form.Item noStyle
                               shouldUpdate={(prev: any, current: any) => prev.username !== current.username}>
                        {({getFieldValue}: any) => {
                            const value = getFieldValue('username') || '';
                            return <Form.Item
                                className={`mt-10 container-input${(value.length > 0) ? '' : ' label-float'}`}
                                name="username"
                                label='Username'
                                rules={[{required: true, message: `Username is required`}]}
                            >
                                <Input
                                    className='input-login'
                                    onChange={handleChangeUsername}
                                    onFocus={() => toggleFocus('username', true)}
                                    onBlur={() => toggleFocus('username', false)}
                                />
                            </Form.Item>
                        }}
                    </Form.Item>

                    {(!sso_mode || sso_mode === SSO_MOD.COGNITO) && <>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prev: any, current: any) => prev.password !== current.password}
                        >
                            {({getFieldValue}: any) => {
                                const value = getFieldValue('password') || '';
                                return <Form.Item
                                    className={`mt-10 container-input${(value.length > 0) ? '' : ' label-float'}`}
                                    name="password"
                                    label="Password"
                                    rules={[{required: true, message: `Password is required`}]}
                                >
                                    <Input.Password
                                        className='input-login'
                                        onFocus={() => toggleFocus('password', true)}
                                        onBlur={() => toggleFocus('password', false)}
                                    />
                                </Form.Item>
                            }}
                        </Form.Item>

                        <p className={styles['link-custom']}>Forgot your password</p>
                    </>}
                    <Button {...{
                        className: [styles['bt-submit'], 'bt-primary'].join(' '),
                        htmlType: 'submit',
                        type: 'primary',
                        loading
                    }}>
                        {(!sso_mode || sso_mode === SSO_MOD.COGNITO) ? 'Login' :
                            <WindowsOutlined style={{fontSize: '20px'}}/>}
                    </Button>
                </Form>
            </div>
            <div className={styles['space-popup']}/>
        </div>
    </div>;
};

export default connect(mapStateToProps, {
    login: loginActions.loginRequest,
    getDomains: loginActions.getDomainsRequest
})(LoginPage);


