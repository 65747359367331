import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import LoadingBox from "../../../../../components/LoadingBox";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    loading: state.planOperation.loadingVessels
})

interface IProps {
    loading: boolean
}

class EmptyVessels extends Component<IProps> {
    render() {
        const {loading} = this.props;
        return <div className={styles.empty} key='layout-empty'>
            <LoadingBox loading={loading}/>
        </div>;
    }
}

export default connect(mapStateToProps, {})(EmptyVessels);