import React from 'react';
import AvailableTime from "./AvailableTime";
import {IAvailableTime, IVessel} from "../../../../../util/varibles/interface";

interface IProps {
    available_times: IAvailableTime[]
    finishTime: number
    vesselIndex: number
    width: number
    vessel: IVessel
}

const AvailableTimes: React.FC<IProps> = ({
                                              vesselIndex,
                                              width,
                                              vessel,
                                              finishTime,
                                              available_times,
                                          }) => {

    return <>
        {available_times.map((sub: any, index) => <AvailableTime key={sub.id} {...{
            index,
            data: sub,
            vessel,
            width,
            finishTime,
            vesselIndex,
        }}/>)}
    </>
};

export default AvailableTimes;