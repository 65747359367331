import stylesTimeLine from './Operation/Component/TimeLine/style.module.scss';
import {convertPxToTimeByStep} from "../../../util";
import {
    aDayMillisecond,
    aWeekMillisecond,
    initVesselConfig,
    KeyOfStep,
    OP_STATUS,
    OPERATION_CONFIRM_STATUS,
    OPERATION_TYPE,
    PATHNAME,
    PriorityTime,
    TIME_PER_STEP
} from "../../../../../util/varibles/constants";
import {getElById} from "../../../../../util/varibles/global";
import {ICON_NAME} from "../../../../../components/Icon";
import {
    handleAddDelay,
    handleApproveOp,
    handleCancelOperation,
    handleDeclineOperation,
    handleDownloadPdf,
    handleDuplicate,
    handleSaveOperation,
    handleSendMail,
    handleSendToVessel,
    openPopupDuration,
    showPopupDeleteOperation
} from "./actions";
import {IActivityLog, IOperation, IOperations, IVessel, TOperation} from "../../../../../util/varibles/interface";
import {openNote} from "../../../../../components/Popup/PopupOpNote";
import {notification} from "antd";
import {getTimeByOpMode} from "../../../util/function_operation/constants";
import {planOpActions} from "../../../reducer";
import {Dispatch} from "@reduxjs/toolkit";
import {openPage} from "../../../../../util/library/Router";
import {CALENDAR_ID} from "../../../constants";
import {selectOpMode} from "../../../../../util/store/selectors";
import {store} from '../../../../../util/store/store';

export enum MENU_OP {
    DETAIL = 'detail',
    EDIT = 'edit',
    SEND_TO_VESSEL = 'send-to-vessel',
    SAVE_OPERATION = 'save-operation',
    DELETE = 'delete',
    APPROVE = 'approve',
    CANCEL = 'cancel',
    DECLINE = 'decline',
    ROUTE_DURATION = 'route-duration',
    DELAY = 'delay',
    ADD_NOTE = 'add-note',
    PUSH_CLOSER = 'push-close',
    DUPLICATE = 'duplicate',
    SEND_MAIL = 'send-mail',
    DOWNLOAD_PDF = 'download-pdf'
}

export interface IMenuOperation {
    name: string,
    icon: ICON_NAME,
    onClickHandler: any
    isActive: boolean
    danger?: boolean
}

export interface IDataMenuOperation extends IOperation {
    isActive: boolean
}

export interface IPropsMenu {
    dispatch: Dispatch
    operations: IOperations

    edit?(): any
}

export interface IMenuOp {
    [menuId: string]: (data: IDataMenuOperation, props?: IPropsMenu | any) => IMenuOperation
}

export const menuOp: IMenuOp = {
    [MENU_OP.DETAIL]: (data: IDataMenuOperation) => {
        const {tenant_id, id} = data.operation;
        return {
            name: 'Operation detail',
            icon: ICON_NAME.OPERATION_DETAIL,
            onClickHandler: () => openPage(`${PATHNAME.OPERATION}/${tenant_id}/${id}`),
            isActive: data.action_type !== OPERATION_CONFIRM_STATUS.NEW
        }
    },
    [MENU_OP.EDIT]: (data: IDataMenuOperation, props: IPropsMenu) => {
        return {
            name: 'View/Edit',
            icon: ICON_NAME.EDIT,
            onClickHandler: () => {
                const {edit, dispatch} = props;
                if (edit){
                    dispatch(planOpActions.togglePopupOperation(edit()));
                }
            },
            isActive: data.isActive,
        }
    },
    [MENU_OP.SEND_TO_VESSEL]: (data: IDataMenuOperation, props: IPropsMenu) => {
        const startTime = data.activity_log[0].est_start_time;
        const {is_active_vessel} = data.operation;
        return {
            name: 'Send to vessel',
            icon: ICON_NAME.SEND_TO_VESSEL,
            onClickHandler: () => {
                const {operations, dispatch} = props;
                handleSendToVessel(data, operations, dispatch);
            },
            isActive: (!!data.action_type || !is_active_vessel) && (startTime > Date.now() || data.operation.status === OP_STATUS.PROCESSING)
        }
    },
    [MENU_OP.SAVE_OPERATION]: (data: IDataMenuOperation, props: IPropsMenu) => {
        const startTime = data.activity_log[0].est_start_time;
        const {is_active_vessel} = data.operation;
        return {
            name: 'Save operation',
            icon: ICON_NAME.SAVE_OPERATION,
            onClickHandler: () => {
                const {operations, dispatch} = props;
                handleSaveOperation(data, operations, dispatch);
            },
            isActive: (!!data.action_type || !is_active_vessel) && startTime > Date.now()
        }
    },
    [MENU_OP.ADD_NOTE]: (data: IDataMenuOperation, props: IPropsMenu) => {
        const {note, status} = data.operation;
        return {
            name: (note || '').length === 0 ? 'Add note' : 'Edit note',
            icon: ICON_NAME.NOTE,
            onClickHandler: () => {
                const moreProps = status !== OP_STATUS.NEW ? {operation: data.operation} : {}
                openNote({
                    ...moreProps,
                    title: `#${data.operation.operation_code}`,
                    note,
                    onSave: (note: string) => {
                        const {id} = data.operation;
                        if (Object.keys(moreProps).length > 0)
                            props.dispatch(planOpActions.updateOpsByWs({
                                data: [{...data, operation: {...data.operation, note}}]
                            }))
                        else
                            props.dispatch(planOpActions.updateOps({
                                update: {[id]: {...data, operation: {...data.operation, note}}},
                                isCheck: false
                            }))
                    }
                })
            },
            isActive: true
        }
    },
    [MENU_OP.DELETE]: (data: IDataMenuOperation, props: IPropsMenu) => {
        const {status} = data.operation;
        return {
            name: 'Delete',
            icon: ICON_NAME.DELETE,
            danger: true,
            onClickHandler: () => showPopupDeleteOperation(data, props, (ids: string[]) => props.dispatch(planOpActions.deleteOps(ids))),
            isActive: status !== OP_STATUS.PROCESSING
        }
    },
    [MENU_OP.APPROVE]: (data: IDataMenuOperation, props: IPropsMenu) => {
        const {status} = data.operation;
        return {
            name: 'Approve',
            icon: ICON_NAME.APPROVE,
            onClickHandler: () => handleApproveOp(data, props.dispatch),
            isActive: status === OP_STATUS.PENDING || status === OP_STATUS.SAVED
        }
    },
    [MENU_OP.DECLINE]: (data: IDataMenuOperation, props: IPropsMenu) => {
        const {status} = data.operation;
        return {
            name: 'Decline',
            icon: ICON_NAME.CANCEL,
            danger: true,
            onClickHandler: () => handleDeclineOperation(data, props.dispatch),
            isActive: status === OP_STATUS.PENDING || status === OP_STATUS.SAVED
        }
    },
    [MENU_OP.CANCEL]: (data: IDataMenuOperation, props: IPropsMenu) => {
        const {status} = data.operation;
        return {
            name: 'Cancel',
            icon: ICON_NAME.CANCEL,
            danger: true,
            onClickHandler: () => handleCancelOperation(data, props.dispatch),
            isActive: status === OP_STATUS.WAITING || status === OP_STATUS.APPROVED

        }
    },
    [MENU_OP.ROUTE_DURATION]: (data: IDataMenuOperation, props: IPropsMenu) => {
        return {
            name: 'Route & Duration',
            icon: ICON_NAME.ROUTE,
            onClickHandler: () => openPopupDuration(data, props.dispatch),
            isActive: data.isActive,
        }
    },
    [MENU_OP.DELAY]: (data: IDataMenuOperation, props: IPropsMenu) => {
        return {
            name: 'Add delay time',
            icon: ICON_NAME.ADD_DELAY,
            onClickHandler: () => handleAddDelay(data, props.dispatch),
            isActive: data.operation.status === OP_STATUS.PROCESSING
        }
    },
    [MENU_OP.PUSH_CLOSER]: (data: IDataMenuOperation, props: IPropsMenu) => {
        return {
            name: 'Move next to previous operation',
            icon: ICON_NAME.MOVE_OP,
            onClickHandler: () => {
                const opMode = selectOpMode(store.getState());
                const {operations} = props;
                const {activity_log} = data;
                const {id: vesselId} = data.vessel;

                const {[opMode]: getTime}: any = getTimeByOpMode;
                const {id} = data.operation;
                const startA = getTime(activity_log[0]) - aWeekMillisecond;
                const finishA = getTime(activity_log[activity_log.length - 1]);

                const newTime = Object.keys(operations).reduce((time: number, key) => {
                    if (operations[key].vessel.id !== vesselId || key === id)
                        return time
                    const startB = getTime(operations[key].activity_log[0]);
                    const max = operations[key].activity_log.length;
                    const finishB = getTime(operations[key].activity_log[max - 1]);

                    if (startB <= finishA && finishB >= startA) {
                        if (time < finishB)
                            return finishB;
                    }

                    return time
                }, startA);

                if (newTime === getTime(activity_log[0])) {
                    notification.error({
                        message: 'Move failed',
                        description: 'There is no operation within previous 7 days to move next to.\n' +
                            '(System only supports to move operation within 7 days)'
                    })
                } else
                    props.dispatch(planOpActions.updateOps({
                        update: {
                            [data.operation.id]: {
                                ...data,
                                activity_log: updateTime(data.activity_log, 0, newTime + TIME_PER_STEP)
                            }
                        }
                    }))
            },
            isActive: data.operation.status !== OP_STATUS.PROCESSING && data.operation.status !== OP_STATUS.CANCELED && data.operation.status !== OP_STATUS.FINISHED
        }
    },
    [MENU_OP.DUPLICATE]: (data: IDataMenuOperation) => {
        return {
            icon: ICON_NAME.DUPLICATE,
            name: 'Duplicate operation',
            onClickHandler: () => handleDuplicate(data),
            isActive: true
        }
    },
    [MENU_OP.SEND_MAIL]: (data: IDataMenuOperation) => {
        return {
            icon: ICON_NAME.SEND_MAIL,
            name: 'Send email PDF file',
            onClickHandler: () => handleSendMail(data),
            isActive: data.operation.status !== OP_STATUS.NEW
        }
    },
    [MENU_OP.DOWNLOAD_PDF]: (data: IDataMenuOperation) => {
        return {
            icon: ICON_NAME.PDF,
            name: 'Download PDF file',
            onClickHandler: () => handleDownloadPdf(data),
            isActive: data.operation.status !== OP_STATUS.NEW
        }
    }
}

export const menuOptions: any = {
    [OPERATION_TYPE.HARVEST]: [
        MENU_OP.SAVE_OPERATION,
        MENU_OP.SEND_TO_VESSEL,
        MENU_OP.DETAIL,
        MENU_OP.PUSH_CLOSER,
        MENU_OP.DOWNLOAD_PDF,
        MENU_OP.SEND_MAIL,
        MENU_OP.ADD_NOTE,
        MENU_OP.DELAY,
        MENU_OP.ROUTE_DURATION,
        MENU_OP.EDIT,
        MENU_OP.CANCEL,
        MENU_OP.DELETE,
    ],
    [OPERATION_TYPE.TREATMENT]: [
        MENU_OP.SAVE_OPERATION,
        MENU_OP.SEND_TO_VESSEL,
        MENU_OP.DETAIL,
        MENU_OP.PUSH_CLOSER,
        MENU_OP.DOWNLOAD_PDF,
        MENU_OP.SEND_MAIL,
        MENU_OP.ADD_NOTE,
        MENU_OP.DELAY,
        MENU_OP.DUPLICATE,
        MENU_OP.EDIT,
        MENU_OP.CANCEL,
        MENU_OP.DELETE
    ],
    [OPERATION_TYPE.TRANSPORT]: [
        MENU_OP.SAVE_OPERATION,
        MENU_OP.SEND_TO_VESSEL,
        MENU_OP.DETAIL,
        MENU_OP.PUSH_CLOSER,
        MENU_OP.DOWNLOAD_PDF,
        MENU_OP.SEND_MAIL,
        MENU_OP.ADD_NOTE,
        MENU_OP.DELAY,
        MENU_OP.EDIT,
        MENU_OP.CANCEL,
        MENU_OP.DELETE
    ],
    [OPERATION_TYPE.EVENT]: [
        MENU_OP.SAVE_OPERATION,
        MENU_OP.SEND_TO_VESSEL,
        MENU_OP.DETAIL,
        MENU_OP.PUSH_CLOSER,
        MENU_OP.DOWNLOAD_PDF,
        MENU_OP.SEND_MAIL,
        MENU_OP.ADD_NOTE,
        MENU_OP.DELAY,
        MENU_OP.EDIT,
        MENU_OP.CANCEL,
        MENU_OP.DELETE
    ],
    [OPERATION_TYPE.SERVICE]: [
        MENU_OP.SAVE_OPERATION,
        MENU_OP.SEND_TO_VESSEL,
        MENU_OP.DETAIL,
        MENU_OP.PUSH_CLOSER,
        MENU_OP.DOWNLOAD_PDF,
        MENU_OP.SEND_MAIL,
        MENU_OP.ADD_NOTE,
        MENU_OP.DELAY,
        MENU_OP.EDIT,
        MENU_OP.CANCEL,
        MENU_OP.DELETE
    ],
}

export const HEIGHT_PER_DAY = 120;

export enum WEATHER_STATUS {
    BAD,
    MODERATE,
    GOOD
}

export const weatherStatus: any = {
    [WEATHER_STATUS.BAD]: {id: WEATHER_STATUS.BAD, name: 'Bad weather'},
    [WEATHER_STATUS.MODERATE]: {id: WEATHER_STATUS.MODERATE, name: 'Moderate weather'},
    [WEATHER_STATUS.GOOD]: {id: WEATHER_STATUS.GOOD, name: 'Good weather'}
};

const spaceScroll = 50;
const spaceScrollBar = spaceScroll + 8;

/*
* Date: 24/07/2020
* Author: Vinh.Pham
* Description: Loads more data when scrolling
 */
export const handleScroll = ({...args}) => {
    const {pageX, pageY, maxWidth, target, id, key, activityLogs, action, clearInterval} = args;
    const element: any = getElById(CALENDAR_ID);
    clearInterval()
    if (!element)
        return;

    const elementTimeLine: any = document.getElementsByClassName(stylesTimeLine.timeline)
    const filter = [...elementTimeLine].filter((item: any) => item.dataset.operationId === id)
    const {top, right, bottom} = element.getBoundingClientRect();
    const {scrollWidth, clientWidth, scrollHeight, clientHeight} = element;
    const scrollLeftMax = scrollWidth - clientWidth;
    const scrollTopMax = scrollHeight - clientHeight;
    let activityLog = activityLogs[key];

    let internal: any;
    if (pageX > right - spaceScrollBar) {
        const space = spaceScrollBar - (right - pageX);

        internal = window.setInterval(() => {
            element.scrollLeft = element.scrollLeft + space;
            const positionTargetChange = target.offsetLeft + space;
            target.style.left = (positionTargetChange > maxWidth ? maxWidth : positionTargetChange) + 'px';
            if (filter.length > 0 && element.scrollLeft !== scrollLeftMax) {
                const positionTimelineChange = filter[0].offsetLeft + space
                const spaceChange = element.scrollLeft >= scrollLeftMax ? scrollLeftMax : positionTimelineChange
                filter.forEach(item => {
                    item.style.left = spaceChange + 'px';
                    item.style.width = (target.offsetLeft - spaceChange + 120) + 'px';
                })
            }
            if (element.scrollLeft === scrollLeftMax)
                clearInterval()
        }, 50)
    }

    if (pageX < (120 + spaceScroll)) {
        const space = (120 + spaceScroll) - pageX;
        internal = window.setInterval(() => {
            element.scrollLeft = element.scrollLeft - space;
            const positionTargetChange = target.offsetLeft - space;
            target.style.left = (positionTargetChange < 110 ? 110 : positionTargetChange) + 'px';
            if (filter.length > 0) {
                const positionTimelineChange = filter[0].offsetLeft - space;
                const spaceChange = element.scrollLeft === 0 ? 110 : positionTimelineChange
                filter.forEach(item => {
                    item.style.left = spaceChange + 'px'
                })
            }
            if (element.scrollLeft === 0)
                clearInterval()
        }, 50)
    }
    if (pageY > bottom - spaceScrollBar) {
        const space = spaceScrollBar - (bottom - pageY);
        internal = window.setInterval(() => {
            const duration = convertPxToTimeByStep(space);
            const durationPx = duration * HEIGHT_PER_DAY / aDayMillisecond;
            element.scrollTop = element.scrollTop + durationPx;
            activityLog = updateTime(activityLog, duration);
            Object.assign(activityLogs[key], activityLog);
            action(activityLogs);
            if (element.scrollTop === scrollTopMax)
                clearInterval()
        }, 50)
    }
    if (pageY < top + spaceScroll) {
        const space = spaceScroll - (pageY - top);
        internal = window.setInterval(() => {
            const duration = convertPxToTimeByStep(space);
            const durationPx = duration * HEIGHT_PER_DAY / aDayMillisecond
            element.scrollTop = element.scrollTop - durationPx;
            activityLog = updateTime(activityLog, -duration);
            Object.assign(activityLogs[key], activityLog);
            action(activityLogs);
            if (element.scrollTop === 0)
                clearInterval()
        }, 50)
    }
    return internal;
}

interface IGetStartTime {
    vessel: IVessel
    activityLogOld: any
    activityLogNew: any
    autoPlanConfig: any
}

export const getStandardTime = (args: IGetStartTime): number => {
    const {vessel, activityLogOld, activityLogNew, autoPlanConfig} = args;
    const {type: vesselType} = vessel;
    const {vessel: vesselConfig = initVesselConfig} = autoPlanConfig || {}
    const {priority} = vesselConfig[vesselType] || {priority: PriorityTime.FACTORY};

    const condition = priority === PriorityTime.FACTORY ? (key: any) => key === KeyOfStep.ARRIVE_FACTORY : (key: any) => key === KeyOfStep.ARRIVE_SITE;
    const {est_start_time: oldTime} = activityLogOld.find((item: any) => condition(item.key)) || {est_start_time: 0};
    const {est_start_time: newTime} = activityLogNew.find((item: any) => condition(item.key)) || {est_start_time: 0};
    const duration = newTime - oldTime;

    if (duration)
        return activityLogNew[0].est_start_time - duration;

    return activityLogOld[0].est_start_time
}

export const updateTime = (activityLog: IActivityLog[], duration: number, startTime = 0) => {
    const space = startTime ? startTime - activityLog[0].est_start_time : duration;
    return activityLog.map((item: IActivityLog) => ({...item, est_start_time: item.est_start_time + space}));
}

export const createKeyActivityLog = (operation: TOperation, vessel: IVessel) => {
    const {start_place_id = ''} = operation;
    return [vessel.id, start_place_id].join("|");
}
