import React from 'react';
import {Checkbox} from "antd";

interface IProps {
    is_cleaning: boolean

    onChange(value: boolean): void
}

const Cleaning: React.FC<IProps> = ({is_cleaning, onChange}) => {

    return <div>
        <Checkbox
            checked={is_cleaning}
            onChange={({target}) => onChange(target.checked)}
        >
            Cleaning
        </Checkbox>
    </div>;
};

export default Cleaning;
