import React, {Component} from 'react';
import styles from "../style.module.scss";
import {durationTxt, getStepNameOfOp, uppercaseFirstLetter} from "../../../../../../../util/varibles/global";
import {
    getOperationStatus,
    OP_STATUS,
    SHORT_MONTH_DATETIME_FORMAT,
    timeLate
} from "../../../../../../../util/varibles/constants";
import {datetime} from "../../../../../../../util/library/datetime";
import {TOperation} from "../../../../../../../util/varibles/interface";

interface IProps {
    operation: TOperation
    activityLog: any
    isOld: boolean
}

class Status extends Component<IProps> {
    static defaultProps = {
        isOld: false
    }
    renderContent = () => {
        const {status, operation_type, current_process = 0} = this.props.operation;
        const { activityLog, isOld} = this.props;
        switch (status) {
            case OP_STATUS.APPROVED: {
                const {eta = 0, est_start_time} = activityLog[0];
                const time = datetime(eta || est_start_time).format(SHORT_MONTH_DATETIME_FORMAT);
                const getName = getStepNameOfOp[operation_type] || getStepNameOfOp.default;
                const stateTxt = getName({
                    current_process: 0,
                    operation: this.props.operation,
                    activity_log: activityLog, isOld
                });
                let delayTime = eta ? est_start_time - eta : 0;

                return <>
                    <div className={styles['status-info']}>
                        Will be
                        <span className={styles.stateTxt} data-status={status}>{stateTxt}</span>
                        on <span className='font-w4'>{time}</span>
                    </div>
                    <div className={styles['status-info']}>
                        <span>
                            Planned:
                            <span className='font-w4 ml-1'>
                                {datetime(est_start_time).format(SHORT_MONTH_DATETIME_FORMAT)}
                            </span>
                        </span>
                        {Math.abs(delayTime) > timeLate && <span className='font-w4' data-late-state={delayTime > 0}>
                            (diff {durationTxt(delayTime)})
                        </span>}
                    </div>
                </>;
            }
            case OP_STATUS.PROCESSING: {
                const {eta = 0, est_start_time} = activityLog[current_process + 1] || {};
                const time = datetime(eta || est_start_time).format(SHORT_MONTH_DATETIME_FORMAT);
                const getName = getStepNameOfOp[operation_type] || getStepNameOfOp.default;
                const stateTxt = getName({
                    current_process: current_process + 1,
                    operation: this.props.operation,
                    activity_log: activityLog,
                    isOld
                });
                let delayTime = eta ? est_start_time - eta : 0;
                return <>
                    <div className={styles['status-info']}>
                        Will be
                        <span className={styles.stateTxt} data-status={status}>{stateTxt}</span>
                        on <span className='font-w4'>{time}</span>
                    </div>
                    <div className={styles['status-info']}>
                        <span>
                            Planned:
                            <span className='font-w4 ml-1'>
                                {datetime(est_start_time).format(SHORT_MONTH_DATETIME_FORMAT)}
                            </span>
                        </span>
                        {Math.abs(delayTime) > timeLate && <span className='font-w4' data-late-state={delayTime > 0}>
                            (diff {durationTxt(delayTime)})
                        </span>}
                    </div>
                </>;
            }
            case OP_STATUS.FINISHED: {
                const {real_start_time, est_start_time} = activityLog[activityLog.length - 1];
                const time = datetime(real_start_time).format(SHORT_MONTH_DATETIME_FORMAT);
                const delayTime = est_start_time - real_start_time;
                const finalStatus = `${durationTxt(delayTime)} ${delayTime > 0 ? 'before schedule' : 'behind schedule'}`
                return <>
                    <div className={styles['status-info']}>
                        Will be
                        <span className={styles.stateTxt} data-status={status}>Completed</span>
                        on <span className='font-w4'>{time}</span>
                    </div>
                    <div className={styles['status-info']}>
                        {Math.abs(delayTime) > timeLate ? <span className='font-w4' data-late-state={delayTime > 0}>
                            {finalStatus}
                        </span> : <span className='font-w4' data-late-state={true}>On time</span>}
                    </div>
                </>
            }
            default:
                return <div className={styles['status-info']}>
                    <span className={styles.stateTxt} data-status={status}>
                        {uppercaseFirstLetter(getOperationStatus(status))}
                    </span>
                </div>;
        }
    }

    render() {
        return <div className={styles['operation-status']}>
            {this.renderContent()}
        </div>;
    }
}

export default Status;
