import {api} from "../api/callApi";
import {urlServer} from "../api/v1";
import {ICapability, IVessel} from "../varibles/interface";

const urlBase = urlServer + '/capability';

export const CapabilityService = {
    serviceTasks: () => {
        return api.get<IVessel[]>(urlBase + '/tasks')
    },
    gets: () => {
        return api.get<ICapability[]>(urlBase + '/s')
    }
}