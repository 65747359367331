import React, {Component} from 'react';

import styles from './style.module.scss';
import {OP_STATUS} from "../../util/varibles/constants";

interface IProps {
    visible: boolean
    className: any
    operation: {
        operation_code: string
    }
    status: OP_STATUS
}

class Id extends Component<IProps> {
    static defaultProps = {
        visible: true,
        className: ''
    }

    render() {
        const {visible} = this.props;
        if (!visible) return null;
        const {status} = this.props;
        const {operation_code} = this.props.operation;

        return <div className={[styles.id, this.props.className].join(' ')} data-status={status}>
            #{operation_code}
        </div>;
    }
}

export default Id;
