import React from 'react';
import {TREATMENT_TYPE} from "../../../../../../../../util/varibles/constants";
import InputDuration from "../../../../../../../../components/Basic/InputDuration";
import styles from "./style.module.scss";
import Sorting from "./Sorting";
import {IPropsTask} from "../Combine";
import {notify, NotifyCode} from "../../../../../../../../util/varibles/message";
import {ISorting} from "../../../../../../../../util/varibles/interface";

interface IProps extends IPropsTask {

}

const Treatment: React.FC<IProps> = ({data, unit, onUpdate}) => {
    const {treatment_type, error} = data;

    const handleChangeDuration = (value: number) => {
        const {duration, ...args} = error;
        onUpdate({duration: value, error: args})
    }

    const handleErrorDuration = (empty: boolean) => {
        onUpdate({
            error: {
                ...error,
                duration: empty ? notify[NotifyCode.E16]() : notify[NotifyCode.E19]()
            }
        })
    }

    switch (treatment_type) {
        case TREATMENT_TYPE.HYDROGENPEROXIDE:
        case TREATMENT_TYPE.SALTWATER:
        case TREATMENT_TYPE.FRESHWATER: {
            return <InputDuration
                value={+(data.duration || 0)}
                className={[styles['input-custom'], 'w-full max-w-160'].join(' ')}
                disabled={unit.isLock}
                onChange={handleChangeDuration}
                onError={handleErrorDuration}
            />
        }
        case TREATMENT_TYPE.SORTING: {
            return <Sorting
                data={data as ISorting}
                unit={unit}
                onUpdate={onUpdate}
            />
        }
        default:
            return null
    }
};

export default Treatment;
