import React, {createRef, useCallback, useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {IActivityLog} from "../../../../util/varibles/interface";
import {checkLimit, convertNumberTimeToStringTime} from "../../../../util/varibles/global";
import {datetime} from "../../../../util/library/datetime";
import {listOfStep, SHORT_MONTH_DATETIME_FORMAT, TIME_PER_STEP} from "../../../../util/varibles/constants";

let pageX = 0, el: HTMLDivElement | null, limit = [0, 0];

interface IProps {
    data: IActivityLog
    prev?: IActivityLog
    isTop: boolean
    more: number

    setMore(value: number): void

    updateActivityLog(timeChange: number): void
}

const ActivityLog: React.FC<IProps> = (props) => {
    const rootRef = createRef<HTMLDivElement>();
    const {isTop, more, setMore, updateActivityLog} = props;
    const [isDrag, setIsDrag] = useState(false);
    const {key, est_start_time} = props.data;
    const {name} = listOfStep[key] || {};
    const {est_start_time: prev_time = est_start_time} = props.prev || {};
    const duration = est_start_time - prev_time;

    const handleMouseMove = useCallback((e: any) => {
        const value = checkLimit(limit[0], limit[1], e.pageX - pageX);
        const timeChange = Math.floor(value / 5) * TIME_PER_STEP;
        if (timeChange <= -duration) {
            const min = -duration + TIME_PER_STEP;
            setMore(min);
            if (el)
                el.style.left = (min / TIME_PER_STEP * 5) + 'px'
        } else {
            if (el)
                el.style.left = value + 'px'
            setMore(timeChange);
        }
    }, [duration]);

    const handleMouseUp = useCallback((e: any) => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        const value = checkLimit(limit[0], limit[1], e.pageX - pageX);
        let timeChange = Math.floor(value / 5) * TIME_PER_STEP;
        if (timeChange <= -duration)
            timeChange = -duration + TIME_PER_STEP;

        updateActivityLog(timeChange);
        setIsDrag(false);
        if (el) {
            el.style.left = 'unset';
        }
    }, [updateActivityLog]);

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, []);

    const handleMouseDown = useCallback((e: any) => {
        pageX = e.pageX;
        el = rootRef.current;
        if (el) {
            const {offsetWidth = 0} = el;
            const {scrollWidth = 0} = el?.parentElement || {};
            const space = Math.floor((scrollWidth - offsetWidth) / 2)
            limit = [-space, space];
        }
        setIsDrag(true);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove, handleMouseUp]);

    return <div className={styles.activityLog}>
        {duration !== 0 && <div className={styles.duration}>
            <div>{convertNumberTimeToStringTime(duration)}</div>
            {(more !== 0 && isDrag) && <div className={styles.more} data-is-expand={more > 0}>
                {more < 0 ? '-' : '+'} {convertNumberTimeToStringTime(more)}
            </div>}
        </div>}
        <div
            ref={rootRef}
            className={styles.time}
            data-dragging={isDrag}
            onMouseDown={isTop ? () => null : handleMouseDown}
        >
            <div>{name}</div>
            <div>{datetime(est_start_time + more).format(SHORT_MONTH_DATETIME_FORMAT)}</div>
        </div>
    </div>;
};

export default ActivityLog;
