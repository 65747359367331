import React, {Component} from 'react';
import styles from "./style.module.scss";
import {Button, Divider, Input, List, Modal} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {GlobalService} from "../../../util/services/global";
import SearchItem from "./SearchItem";
import {propsModal} from "../../../util/varibles/constants";

const initState = {visible: false, searchValue: '', loading: false, list: []}

interface IProps {

}

interface IState {
    visible: boolean
    searchValue: string
    loading: boolean
    list: any
}

class SearchComponent extends Component<IProps, IState> {
    state = initState

    constructor(props: IProps) {
        super(props);
        this.state = initState
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {visible} = this.state;
        if (prevState.visible !== visible && visible)
            setTimeout(() => {
                const element = document.getElementById('input-search-global');
                if (element)
                    element.focus();
            }, 300)
    }

    openPopup = () => this.setState({...initState, visible: true})

    closePopup = () => this.setState({visible: false})

    handleSearch = () => {
        const {searchValue} = this.state;
        if (searchValue.length === 0) return;
        const data = searchValue.replace(/^#+/g, '');
        this.setState({loading: true}, () =>
            new Promise((resolve) => resolve(GlobalService.search(data)))
                .then(rs => {
                    this.setState({loading: false, list: rs})
                })
                .catch(() => {
                    this.setState({loading: false})
                }))
    }

    handleKeyDown = () => {
        if (this.state.searchValue.length > 0)
            this.handleSearch()
    }

    render() {
        const {visible, searchValue, loading, list} = this.state;

        return <div className={styles['container-search']}>
            <Button className={styles['toggle-search']} onClick={this.openPopup}>
                <span className={styles.title}>Search in Ocean Planner</span>
                <SearchOutlined className='ml-0'/>
            </Button>
            <Modal {...{
                ...propsModal,
                wrapClassName: styles.popupSearch,
                styles: {mask: {background: 'unset'}},
                open: visible,
                centered: false,
                closable: false,
                footer: null,
                onCancel: this.closePopup
            }}>
                <div className={styles['header-search']}>
                    <Input {...{
                        id: 'input-search-global',
                        className: styles['input-search'],
                        placeholder: "Type your search here",
                        value: searchValue,
                        onPressEnter: this.handleKeyDown,
                        onChange: ({target}: any) => this.setState({searchValue: target.value}),
                        variant: "borderless",
                        prefix: <SearchOutlined style={{fontSize: '20px'}}/>,
                        suffix: searchValue.length > 0 && <div className='d-flex align-items-center'>
                            <Button {...{
                                className: 'bt-default',
                                disabled: loading,
                                onClick: () => this.setState({searchValue: ''})
                            }}>
                                Clear
                            </Button>
                            <Button  {...{
                                loading,
                                className: 'bt-primary ml-16',
                                onClick: this.handleSearch,
                            }}>
                                Search
                            </Button>
                        </div>
                    }} />
                    <Divider type="vertical"/>
                    <div className='bt-close' onClick={this.closePopup}/>
                </div>
                <div className={styles['result-search']}>
                    <div className={styles['count-search']}>{list.length + ' search results'}</div>
                    <List {...{
                        loading,
                        grid: {gutter: 16, column: 2},
                        dataSource: list,
                        renderItem: (data: any) => <SearchItem {...{data, closePopup: this.closePopup}}/>
                    }}/>
                </div>
            </Modal>
        </div>;
    }
}

export default SearchComponent;
