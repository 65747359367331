import React from "react";
import {createBrowserRouter} from "react-router-dom";
import {PATHNAME} from "../varibles/constants";
import LoginPage from "../../pages/LoginPage";
import {requireAuth} from "../../components/RequireAuth";
import DashboardPage from "../../pages/DashboardPage";
import AccessTokenPage from "../../pages/AccessTokenPage";
import VesselDetailPage from "../../pages/VesselDetailPage";
import OverviewPage from "../../pages/OverviewPage";
import UsersPage from "../../pages/UserPage";
import MessagePage from "../../pages/MessagePage";
import DiseasePage from "../../pages/DiseasePage";
import NotAuthorizedPage from "../../pages/NotAuthorizedPage";
import NotFoundPage from "../../pages/NotFoundPage";
import OperationDetailPage from "../../pages/OperationDetailPage";
import TaskTypeManagementPage from "../../pages/TaskTypeManagementPage";
import ChecklistPage from "../../pages/ChecklistPage";
import CombineTreatmentPage from "../../pages/CombineTreatmentPage";
import PreferencePage from "../../pages/PreferencePage";
import OperationSettingPage from "../../pages/OperationSettingPage";
import WeatherPage from "../../pages/WeatherPage";
import SiteMonitoringPage from "../../pages/SiteMonitoringPage";
import PlanOperationPage from "../../pages/PlanOperationPage";
import VesselManagementPage from "../../pages/VesselManagementPage";
import RoutePage from "../../pages/RoutePage";
import LocationPage from "../../pages/LocationPage";
import EquipmentsPage from "../../pages/EquipmentsPage";

export const router = createBrowserRouter([
    {path: PATHNAME.LOCATION, element: requireAuth(<LocationPage/>)},
    {path: PATHNAME.LOGIN, element: <LoginPage/>},
    {path: PATHNAME.HOME, element: requireAuth(<DashboardPage/>)},
    {path: PATHNAME.DASHBOARD, element: requireAuth(<DashboardPage/>)},
    {path: PATHNAME.ACCESS_TOKEN, element: <AccessTokenPage/>},
    {path: PATHNAME.PLAN, element: requireAuth(<PlanOperationPage/>)},
    {path: `${PATHNAME.VESSEL}/:id`, element: requireAuth(<VesselDetailPage/>)},
    {path: PATHNAME.OVERVIEW, element: requireAuth(<OverviewPage/>)},
    {path: `${PATHNAME.OPERATION}/:tenant_id/:id`, element: requireAuth(<OperationDetailPage/>)},
    {path: PATHNAME.ROUTE, element: requireAuth(<RoutePage/>)},
    {path: PATHNAME.CHECKLIST, element: requireAuth(<ChecklistPage/>)},
    {path: PATHNAME.VESSEL, element: requireAuth(<VesselManagementPage/>)},
    {path: PATHNAME.WEATHER, element: requireAuth(<WeatherPage/>)},
    {path: PATHNAME.USER, element: requireAuth(<UsersPage/>)},
    {path: PATHNAME.SITE_MONITORING, element: requireAuth(<SiteMonitoringPage/>)},
    {path: PATHNAME.PREFERENCE, element: requireAuth(<PreferencePage/>)},
    {path: PATHNAME.TASK_TYPE, element: requireAuth(<TaskTypeManagementPage/>)},
    {path: PATHNAME.OPERATION_SETTING, element: requireAuth(<OperationSettingPage/>)},
    {path: PATHNAME.COMBINED_TREATMENT, element: requireAuth(<CombineTreatmentPage/>)},
    {path: PATHNAME.MESSAGE, element: requireAuth(<MessagePage/>)},
    {path: PATHNAME.DISEASE, element: requireAuth(<DiseasePage/>)},
    {path: PATHNAME.EQUIPMENT, element: requireAuth(<EquipmentsPage/>)},
    {path: PATHNAME.ACCESS_DENIED, element: <NotAuthorizedPage/>},
    {path: '*', element: <NotFoundPage/>}
]);


export const nextPage = (path: PATHNAME | string) => {
    router.navigate(path, {replace: true}).then();
}

export const prevPage = () => {
    const {navigation} = router.state || {};
    const {state = 'idle'} = navigation || {}
    if (state === 'idle')
        router.navigate(PATHNAME.HOME, {replace: true}).then()
    else
        router.navigate(-1).then();
}

export const openPage = (url: string) => {
    window.open(`${window.location.protocol}//${window.location.host}${url}`, "_blank");
}

export const getParamsFromUrl = (state = router.state) => {
    return state.matches[0].params || {};
}

export const getSearchFromUrl = (state = router.state) => {
    const {search} = state.location;
    return search.replace('?', '').split('&') || '';
}

export const addRouterListener = router.subscribe
