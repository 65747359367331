import React, {Component} from 'react';
import styles from './style.module.scss';
import {MESSAGE_STATUS} from "../../../../util/varibles/constants";

interface IProps {
    status: any
    isOwn: boolean
}

class MessageStatus extends Component<IProps> {
    render() {
        const {status} = this.props;
        if (!this.props.isOwn || !status)
            return null
        return <div className={styles['message-status']} data-status={status}>
            <svg className={styles['checkmark']} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className={styles['checkmark__circle']} cx="26" cy="26" r="25" fill="none"/>
                {status === MESSAGE_STATUS.ERROR ? <>
                    <line
                        className={styles['checkmark__check']}
                        xmlns="http://www.w3.org/2000/svg"
                        x1="38"
                        y1="14"
                        x2="14"
                        y2="38"
                    />
                    <line
                        className={styles['checkmark__check']}
                        xmlns="http://www.w3.org/2000/svg"
                        x1="14"
                        y1="14"
                        x2="38"
                        y2="38"
                    />
                </> : <path className={styles['checkmark__check']} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>}

            </svg>
        </div>
        // return ;
    }
}

export default MessageStatus;