import React, {useState} from 'react';
import {Button, notification} from "antd";
import {connect} from "react-redux";
import {IFactory, IOperation, IOperations, IStateGlobal, IVessel} from "../../../../../util/varibles/interface";
import {PreferenceSetting} from "../../../../../util/varibles/userSetting";
import defaultSetting from "../../../../../util/varibles/defaultSetting";
import {OpService} from "../../../../../util/services/operation";
import {getHourMinute, showErrorResponse} from "../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {datetime} from "../../../../../util/library/datetime";
import {planOpActions} from "../../../reducer";
import {getTabBiology} from "../../../../../contexts/PlanOperationContext/TabBiologyContext";

const mapStateToProps = (state: IStateGlobal) => {
    const {
        priorityTime = 1,
        planVessels = [],
        sitesTime = {},
        factoriesTime = {},
        [PreferenceSetting.AUTO_PLAN]: autoPlanConfig = defaultSetting[PreferenceSetting.AUTO_PLAN]
    } = state.login.user.setting || {};

    return {
        setting: {
            priorityTime,
            planVessels,
            sitesTime,
            factoriesTime,
            autoPlanConfig
        },
        factories: state.planOperation.factories,
        vessels: state.planOperation.vessels,
        operations: state.planOperation.operations,
    };
}

interface IProps {
    setting: any
    factories: IFactory[]
    vessels: IVessel[]
    operations: IOperations

    autoPlan(payload: { operations: IOperation[], harvests: any }): void

    clear(): void
}

const AutoPlan: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const get = getTabBiology();

    const handleAutoPlan = () => {
        const {selected} = get();

        if (Object.keys(selected).length === 0) {
            notification.error({message: 'Error', description: 'There is no harvest to plan'});
            return;
        }
        const {vessels, factories, operations} = props;
        const {planVessels = [], priorityTime = 1} = props.setting || {};
        const realVessels = vessels.filter(({id}) => planVessels.length === 0 || planVessels.indexOf(id) !== -1);
        const harvests = Object.keys(selected).reduce((list: any, key) => {
            const {
                harvest_id,
                site_id = '',
                factory_id = '',
                id,
                unit_id,
                avg_weight,
                fish_amount,
                harvest_date,
                packing_date = '',
            } = selected[key];
            const factory = factories.find(item => item.id === factory_id);
            if (!factory) {
                const {factory_name} = selected[key];
                notification.error({message: 'Error', description: `Don't have ${factory_name} factory in System`});
                return list;
            }
            const {sitesTime = {}, factoriesTime = {}} = props.setting || {};
            const factoryTime = factoriesTime[factory_id] ? factoriesTime[factory_id].arrival_time : factory.work_time;
            const hourMinute = getHourMinute(factoryTime);
            const time = datetime(packing_date.replace(' ', 'T')).set(hourMinute).time;
            let site_arrival_time = -1;
            const site_time = sitesTime.all || sitesTime[site_id];
            if (site_time && site_time.arrival_time) {
                const siteTime = site_time.arrival_time
                const hourMinute = getHourMinute(factoryTime);

                if (harvest_date === packing_date && siteTime <= factoryTime) {
                    site_arrival_time = datetime(packing_date.replace(' ', 'T')).set(hourMinute).time;
                } else {
                    site_arrival_time = datetime(packing_date.replace(' ', 'T')).set(hourMinute).subtract(1, 'day').time;
                }
            }
            return [...list, {
                harvest_id,
                id,
                unit_id,
                site_id,
                factory_id,
                avg_weight,
                fish_amount,
                site_arrival_time,
                packing_date: time,
                packing_time: factoryTime,
                priority: priorityTime
            }]
        }, []);

        const {autoPlanConfig} = props.setting;
        const params = {
            harvests,
            vessels: realVessels.map(item => item.id),
            operations: Object.keys(operations).reduce((list: any, key) => {
                if (!!operations[key].action_type) {
                    const {id} = operations[key].vessel;
                    list[id] = [...list[id] || [], operations[key]]
                }
                return list
            }, {}),
            config: autoPlanConfig,
        }

        if (harvests.length > 0) {
            setLoading(true);
            OpService.autoPlan(params)
                .then((rs: any) => {
                    setLoading(false);
                    const {operations} = rs;
                    props.autoPlan(rs);
                    props.clear();

                    if (operations.length === 0) {
                        notify.warn(NotifyCode.W3)();
                    } else
                        notify.success(NotifyCode.S3)([operations.length, harvests.length])
                })
                .catch(error => {
                    setLoading(false);
                    showErrorResponse('Auto plan failed', error)
                })
        }
    }


    return <Button className='bt-primary h-32' loading={loading} onClick={handleAutoPlan}>
        Auto plan
    </Button>;
};

export default connect(mapStateToProps, {
    autoPlan: planOpActions.autoPlan,
})(AutoPlan);
