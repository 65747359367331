import React from 'react';
import styles from "./style.module.scss";
import {checkZero, getHourMinute} from "../../../varibles/global";

interface IProps {
    data: any
}

const Factory: React.FC<IProps> = ({data}) => {
    const {phone, work_time, capacity_discharge, name} = data;
    const {hour, minute} = getHourMinute(work_time);

    return <div className={styles['container-info']}>
        <div className={[styles.header, styles['location-name']].join(' ')}>
            <div className={styles['factory-icon']}/>
            <span>{name}</span>
        </div>
        {phone && <div className={styles['contact']}>
            <div className={styles.phone}>
                <div className={styles.icon}/>
                <span>{phone}</span>
            </div>
        </div>}
        {(work_time || capacity_discharge) && <div className={styles.detail}>
            {work_time && <div className={styles.info}>
                <span>Opening time: <b>{checkZero(hour)}:{checkZero(minute)}</b></span>
            </div>}
            {capacity_discharge && <div className={styles.info}>
                <span>Capacity/hours: <b>{capacity_discharge} tons</b></span>
            </div>}
        </div>}
    </div>;
};

export default Factory;