import React, {Component} from 'react';
import styles from './style.module.scss';

interface IProps {
    data: any
}

class DragEl extends Component<IProps> {
    render() {
        const { data } = this.props;
        return <div className={ styles['container-drag-el'] }>
            { data.map((item: any) =>
                <div className={ styles['info'] } key={ item.label }>
                    <span>{ item.label }:</span>
                    <span>{ item.value }</span>
                </div>) }
        </div>;
    }
}

export default DragEl;