import React from 'react';
import {WEATHER_TYPE} from "../util";
import Section from "../Component/Section";
import {renderWeathers} from "../";

interface IProps {
    weathers: any
}

const Global: React.FC<IProps> = ({weathers}) => {
    return <Section title='Global' type={WEATHER_TYPE.GLOBAL} count={weathers.length}>
        {renderWeathers(weathers)}
    </Section>;
};

export default Global;
