import React from 'react';
import styles from "./style.module.scss";
import {Table} from "antd";
import {formatNumber} from "../../../util/varibles/global";

interface IProps {
    sub_operations: any
}

const HarvestingInfo: React.FC<IProps> = ({sub_operations}) => {
    if (!sub_operations)
        return null;

    const columns = [
        {
            title: 'Site, Unit',
            dataIndex: 'site_unit',
            key: 'site_unit',
        },
        {
            title: 'AVG weight',
            dataIndex: 'avg_weight',
            key: 'avg_weight',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Total weight',
            dataIndex: 'total_weight',
            key: 'total_weight',
        },
        {
            title: 'Mortality risk',
            dataIndex: 'mortality_risk',
            key: 'mortality_risk',
        },
    ];

    const dataSource: any = sub_operations.map((sub_operation: any, index: any) => ({
        key: index,
        site_unit: sub_operation.site_name + ', ' + sub_operation.unit_id,
        avg_weight: formatNumber(sub_operation.avg_weight) + ' gr',
        amount: formatNumber(sub_operation.fish_amount),
        total_weight: (sub_operation.total_weight / 1000000) + 't',
        mortality_risk: sub_operation.mortality_risk ? 'High' : 'Low',
    }));

    return <Table
        dataSource={dataSource}
        columns={columns}
        bordered={true}
        className={styles['sorting-table']}
        pagination={false}
    />;
};

export default HarvestingInfo;