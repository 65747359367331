import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {propContainer, SHORT_DATETIME_FORMAT} from "../../../../../util/varibles/constants";
import styles from './style.module.scss';
import {DatePicker, Form, Select} from "antd";
import {speedList} from "../constants";
import {datetime} from "../../../../../util/library/datetime";
import {selectVessel} from "../../../../../util/store/selectors";
import {vesselDetailActions} from "../../../reducer";
import {FormInstance} from "antd/lib/form";
import {ArrowRightOutlined} from "@ant-design/icons";

const {RangePicker} = DatePicker;
const {Option} = Select;

interface IProps {
    loading: boolean

    loadData(time?: { start_time: number, end_time: number }): void
}

const Control: React.FC<IProps> = ({loading, loadData}) => {
    const formRef: any = React.createRef<FormInstance>();
    const isLive = useSelector(selectVessel.isLive);
    const history = useSelector(selectVessel.history);
    const replay = useSelector(selectVessel.replay);
    const dispatch = useDispatch();

    useEffect(() => {
        if (formRef.current) {
            const start = datetime(history.startTime).value;
            const finish = datetime(history.finishTime).value;
            formRef.current.setFieldsValue({time: [start, finish], speed: replay.speed})
        }
    }, [history, formRef, replay.speed]);

    const handleChangeTime = (time: any) => {
        if (!time || time.length === 0) return;
        const [startNew, finishNew] = time;
        const {startTime, finishTime} = history;
        if (startNew.valueOf() !== startTime || finishNew.valueOf() !== finishTime) {
            update({
                replay: {...replay, time, isPlay: false},
                history: {
                    startTime: startNew.valueOf(),
                    finishTime: finishNew.valueOf(),
                },
                currentTime: startNew.valueOf(),
                isLive: false
            });
            loadData({start_time: startNew.valueOf(), end_time: finishNew.valueOf()})
        }
    }

    const handleChangeSpeed = (speed: number) => update({replay: {...replay, speed}})

    const update = (args: any) => dispatch(vesselDetailActions.update(args));

    const presets: any = [
        {
            label: 'Today',
            value: [datetime().startOf('day').value, datetime().value]
        },
        {
            label: 'This Week',
            value: [datetime().startOf('week').value, datetime().value]
        },
        {
            label: 'This Month',
            value: [datetime().startOf('month').value, datetime().value]
        },
    ]
    return <Form {...{
        layout: 'inline',
        ref: formRef,
        initialValues: {speed: speedList[0].key}
    }}>
        <Form.Item className={styles['picker-date']} name="time" label='Select time period'>
            <RangePicker {...{
                ...propContainer,
                allowClear: false,
                showNow: true,
                showTime: true,
                disabled: loading,
                format: SHORT_DATETIME_FORMAT,
                placeholder: ['Start time', 'Finish time'],
                presets,
                separator: <ArrowRightOutlined
                    style={{marginTop: '-2px !important', marginLeft: '-3px', display: 'block'}}/>,
                onChange: handleChangeTime
            }} />
        </Form.Item>
        <Form.Item className={styles['select-speed']} name="speed" label='Speed'>
            <Select disabled={isLive} placeholder='Speed' onChange={handleChangeSpeed}>
                {speedList.map(({key, label}) => <Option key={key} value={key}>{label}</Option>)}
            </Select>
        </Form.Item>
    </Form>;
};

export default Control;
