import React from 'react';
import {IActivityLog, IPropsChildren} from "../../../../../../../util/varibles/interface";
import styles from "../style.module.scss";
import {datetime} from "../../../../../../../util/library/datetime";
import {SHORT_MONTH_DATETIME_FORMAT} from "../../../../../../../util/varibles/constants";

interface IProps extends IPropsChildren {
    activity_log: IActivityLog[]
}

const Duration: React.FC<IProps> = (props) => {
    const {activity_log} = props;
    const est_start_time = activity_log[0].est_start_time;
    const est_finish_time = activity_log[activity_log.length - 1].est_start_time;

    return <div className={[styles['line-info'], 'justify-content-between'].join(' ')}>
        <div className='h-24 d-flex align-items-center'>
            <span>{datetime(est_start_time).format(SHORT_MONTH_DATETIME_FORMAT)}</span>
            <span className='arrow-right'>&#8594;</span>
            <span>{datetime(est_finish_time).format(SHORT_MONTH_DATETIME_FORMAT)}</span>
        </div>
        {props.children}
    </div>;
};

export default Duration;