import {createKeyOfNote, initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";
import {CONTRACT_PERMISSION_TYPE, CONTRACT_TYPE, OP_STATUS, OPERATION_TYPE} from "../../util/varibles/constants";
import {calculateTotalWeightAndTotalFish, cloneObj} from "../../util/varibles/global";
import {downLevel, upLevel} from "../PlanOperationPage/util";

const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        initialDataRequest: (state, action) => {
            state.loadingData = true;
        },
        initialDataSuccess: (state, action) => {
            const {vesselIds = [], tenantId, result, opMode} = action.payload;
            let {operations, vessels, notes, before_count: beforeCount, after_count: afterCount} = result;

            state.vessels = vessels.reduce((items: any, item: any, index: number) => {
                const {vessel} = item
                const isOwn = `${tenantId}` === `${vessel.tenant_id}`;

                let permission = null;
                if (!isOwn) {
                    const {contracts} = vessel;
                    const contract = contracts.find((sub: any) => sub.tenant_id === tenantId
                        && sub.type === CONTRACT_TYPE.SINGLE
                        && sub.permission === CONTRACT_PERMISSION_TYPE.FULL)
                    permission = contract ? contract.id : permission
                }

                return [...items, {
                    ...vessel,
                    isOwn,
                    permission,
                    row: 1,
                    isShow: vesselIds.length === 0 || vesselIds.indexOf(vessel.id) !== -1,
                    index,
                }];
            }, []);

            state.operations = operations.reduce((list: any, item: any) => {
                const {id, operation_type} = item.operation;
                if (operation_type === OPERATION_TYPE.HARVEST)
                    item = calculateTotalWeightAndTotalFish(item);
                const {vessel_id} = item.operation;
                const vessel = state.vessels.find((sub: any) => sub.id === vessel_id);

                if (vessel) {
                    list[id] = {...item, vessel};
                    state.level = upLevel(state.level, list[id], list, opMode);
                    const {row = 1} = state.vessels[vessel.index] || {};
                    state.vessels[vessel.index].row = Math.max(row, state.level[vessel_id][id].sub);
                }

                return list;
            }, {});

            state.notes = notes.reduce((rs: any, item: any) => {
                const {timestamp, level} = item;
                const key = createKeyOfNote(timestamp, level);
                rs[key] = [...rs[key] || [], item];
                return rs;
            }, {});
            state.opMode = opMode;
            state.beforeCount = beforeCount;
            state.afterCount = afterCount;
            state.loadingData = false;
        },
        initialDataFailure: (state) => {
            state.loadingData = false;
        },
        lazyLoadingRequest: (state, action) => {
            const {weeks, isLoad} = action.payload;
            if (isLoad)
                state.loadingLazy = true;
            else
                state.weeks = weeks;
        },
        lazyLoadingSuccess: (state: any, action) => {
            const {vessels, operations: operationsOld, opMode} = state;
            const {result, key, weeks} = action.payload;
            const {operations, vessels: vesselsRs} = result;
            const keyOfState = key === 'beforeCount'
                ? ['beforeCount', 'before_count']
                : ['afterCount', 'after_count'];

            const vesselsNew = vesselsRs.reduce((list: any, item: any) => {
                const isExist = list.some((sub: any) => sub.id === item.vessel.id)

                if (!isExist)
                    list.push({...item.vessel, index: list.length})

                return list;
            }, vessels);

            state.weeks = weeks;
            state.operations = operations.reduce((list: any, item: any) => {
                const {id, operation_type} = item.operation;
                state.level = downLevel(state.level, list[id], list, opMode);
                if (operation_type === OPERATION_TYPE.HARVEST)
                    item = calculateTotalWeightAndTotalFish(item);
                const {vessel_id} = item.operation;
                const vessel = vesselsNew.find((sub: any) => sub.id === vessel_id);
                list[id] = {...item, vessel};
                state.level = upLevel(state.level, list[id], list, opMode);
                return list;
            }, {...operationsOld});
            state.loadingLazy = false;
            state[keyOfState[0]] = result[keyOfState[1]]
        },
        lazyLoadingFailure: (state) => {
            state.loadingLazy = false;
        },
        updateOps: (state, action) => {
            const list = action.payload;
            const {operations, opMode} = state;
            state.operations = list.reduce((rs: any, item: any) => {
                const {id, status} = item.operation;
                state.level = downLevel(state.level, rs[id], rs, opMode);
                if (status === OP_STATUS.CANCELED) {
                    delete rs[id];
                    return rs;
                }
                if (!rs[id]) {
                    rs[id] = item;
                } else {
                    rs[id].operation = item.operation;
                    rs[id].activity_log = item.activity_log;
                }
                state.level = upLevel(state.level, rs[id], rs, opMode);
                return rs;
            }, cloneObj(operations))
        },
        deleteOps: (state, action) => {
            const {operations, opMode} = state;
            const ids = new Set(action.payload);
            state.operations = Object.keys(operations).reduce((list: any, key: any) => {
                if (!ids.has(key))
                    return {...list, [key]: operations[key]};
                else {
                    state.level = downLevel(state.level, operations[key], operations, opMode);
                }
                return list;
            }, {});
        },
        addOps: (state, action) => {
            const operations = action.payload;
            const {vessels, opMode} = state;
            state.operations = operations.reduce((rs: any, item: any) => {
                const {id, operation_type} = item.operation;
                if (operation_type === OPERATION_TYPE.HARVEST)
                    item = calculateTotalWeightAndTotalFish(item);
                const {vessel_id} = item.operation;
                const vessel = vessels.find((sub: any) => sub.id === vessel_id);
                rs[id] = {...item, vessel};
                state.level = upLevel(state.level, rs[id], rs, opMode);
                return rs;
            }, cloneObj(state.operations));
        },
        updateNotesCalendar: (state, action) => {
            const notes = action.payload;
            const {timestamp, level} = notes[0];
            const key = createKeyOfNote(timestamp, level);
            state.notes = {...state.notes, [key]: notes}
        },
        deleteNotesCalendar: (state, action) => {
            const notes = action.payload;
            const {timestamp, level} = notes[0];
            const key = createKeyOfNote(timestamp, level);
            const {[key]: old, ...args} = state.notes;
            state.notes = args
        },
        updateVessels: (state, action) => {
            state.vessels = action.payload
        }
    },
})

export const overviewActions = overviewSlice.actions

const overviewReducer = overviewSlice.reducer;

export default overviewReducer;

