import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import {Button, Dropdown} from "antd";
import {liceInfo, liceOpts, propContainer} from "../../../../../util/varibles/constants";


interface IProps {
    lice: null | string

    onChange(value: null | string): void
}

const LiceSort: React.FC<IProps> = ({lice, onChange}) => {
    const {[lice || '']: target}: any = liceInfo
    return <div className={styles.wrapper}>
        <Dropdown
            {...propContainer}
            trigger={['click']}
            menu={{
                items: liceOpts.map(item => {
                    return {
                        label: item.name,
                        key: item.id,
                        onClick: () => onChange(item.id)
                    }
                })
            }}
            placement="bottom"
        >
            {target ? <Button className='bt-primary'>
                <Icon icon={ICON_NAME.DECREASE}/>
                <span className='ml-1 mr-10'>{target.name}</span>
            </Button> : <Button className='bt-ghost'>
                <Icon icon={ICON_NAME.DECREASE}/>
                <span>Decreased sorting by lice type</span>
            </Button>}
        </Dropdown>
        {target && <div className={styles.close} onClick={() => onChange(null)}>
            <Icon icon={ICON_NAME.CLOSE} size='small'/>
        </div>}
    </div>;
};

export default LiceSort;
