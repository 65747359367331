import {useEditVessel} from "../../../../contexts/EditVesselContext";
import TreatmentDensity from "./TreatmentDensity";
import React from "react";


const Density = ({id = ''}: { id?: string }) => {
    const [density = {}, setStore] = useEditVessel(state => {
        const {[id]: data} = state.vessel?.density_map || {};
        return data;
    });

    return <TreatmentDensity
        id={id}
        value={density}
        onSave={value => setStore(({vessel}) => {
            const {density_map = {}} = vessel || {}
            return {vessel: {...vessel, density_map: {...density_map, ...value}}}
        })}
    />
}

export default Density;