import React from 'react';
import styles from "./style.module.scss";
import {IPropsElement} from "./constants";

interface IProps extends IPropsElement {

}

const Divide: React.FC<IProps> = () => {
    return <div className={styles.element}>
        <div className={styles.divide}/>
    </div>;
};

export default Divide;
