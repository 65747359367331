import React from 'react';
import styles from "./style.module.scss";

interface IProps {
}

const Underline: React.FC<IProps> = () => {
    return <div className={styles.underline}/>;
};

export default Underline;
