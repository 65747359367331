export enum HARVEST_OPTION_KEY {
    ID = 'id',
    OPERATION_STATUS = 'operation-status',
    SITE_UNIT = 'site-unit',
    FACTORY = 'factory',
    NOTE = 'note',
    REQUIRE_CLOSED_TRANSPORT = 'require-closed-transport',
    MORTALITY_RISK = 'mortality-risk',
    EXTERNAL_SITE = 'external-site',
    CERTAIN = 'certain',
    WEATHER = 'weather',
    SUM_WEIGHT = 'sum-weight',
    FISH_AMOUNT = 'fish-amount',
    AVG_WEIGHT = 'avg-weight',
    START_TIME = 'start-time',
    ARRIVAL_TIME_AT_SITE = 'arrival-time-at-site',
    ARRIVAL_TIME_AT_FACTORY = 'arrival-time-at-factory',
    COMPLETED_TIME = 'completed-time',
    DURATION = 'duration',
    DELIVERY_TYPE = 'delivery-type',
    NAME_SPOT = 'name-spot',
    ADD_DELAY = 'add-delay'
}

export const harvestOptions = [
    {label: 'ID', id: HARVEST_OPTION_KEY.ID},
    {label: 'Operation status', id: HARVEST_OPTION_KEY.OPERATION_STATUS},
    {label: 'Site, unit', id: HARVEST_OPTION_KEY.SITE_UNIT},
    {label: 'Factory', id: HARVEST_OPTION_KEY.FACTORY},
    {label: 'Note', id: HARVEST_OPTION_KEY.NOTE},
    {label: 'Require closed transport', id: HARVEST_OPTION_KEY.REQUIRE_CLOSED_TRANSPORT},
    {label: 'Mortality risk', id: HARVEST_OPTION_KEY.MORTALITY_RISK},
    {label: 'External site', id: HARVEST_OPTION_KEY.EXTERNAL_SITE},
    {label: 'Weather', id: HARVEST_OPTION_KEY.WEATHER},
    {label: 'Sum weight', id: HARVEST_OPTION_KEY.SUM_WEIGHT},
    {label: 'Fish amount', id: HARVEST_OPTION_KEY.FISH_AMOUNT},
    {label: 'Avg. weight', id: HARVEST_OPTION_KEY.AVG_WEIGHT},
    {label: 'Start time', id: HARVEST_OPTION_KEY.START_TIME},
    {label: 'Arrival time at site', id: HARVEST_OPTION_KEY.ARRIVAL_TIME_AT_SITE},
    {label: 'Arrival time at factory', id: HARVEST_OPTION_KEY.ARRIVAL_TIME_AT_FACTORY},
    {label: 'Completed time', id: HARVEST_OPTION_KEY.COMPLETED_TIME},
    {label: 'Duration', id: HARVEST_OPTION_KEY.DURATION},
    {label: 'Delivery type', id: HARVEST_OPTION_KEY.DELIVERY_TYPE},
    {label: 'Certainty', id: HARVEST_OPTION_KEY.CERTAIN},
]
