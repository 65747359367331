import React, {useState} from 'react';
import {durationTxtByStartAndFinish} from "../../../../util/varibles/global";
import styles from './style.module.scss';
import {IActivityLog, IOperation} from "../../../../util/varibles/interface";
import {TRouteExtend} from "../index";
import LoadingBox from "../../../LoadingBox";
import ActivityLog from "./ActivityLog";
import {datetime} from "../../../../util/library/datetime";
import {KeyOfStep, SHORT_MONTH_DATETIME_FORMAT} from "../../../../util/varibles/constants";

const initMore = {index: 0, time: 0}

interface IProps {
    route: TRouteExtend
    data: IOperation
    sites: any
    loading: boolean

    updateActivityLog(activityLog: IActivityLog[]): void
}

const Duration: React.FC<IProps> = (props) => {
    const [more, setMore] = useState<{ index: number, time: number }>(initMore);
    const {loading, updateActivityLog} = props;
    const {activity_log = []} = props.route || {};
    const {est_start_time: start} = activity_log[0] || {}
    const {est_start_time: finish = 0} = activity_log[activity_log.length - 1] || {}
    const {est_start_time: siteTime = 0} = activity_log[1] || {}
    const factoryIndex = activity_log.findIndex(item => item.key === KeyOfStep.ARRIVE_FACTORY);
    const {est_start_time: factoryTime = 0} = activity_log[factoryIndex] || {};
    const duration = durationTxtByStartAndFinish(start, finish);

    return <div className={styles.wrapper}>
        <div className={styles.header}>
            <div className={styles.title}>Duration: {duration}</div>
            <div className={styles.sub}>
                <div>Start: {datetime(start).format(SHORT_MONTH_DATETIME_FORMAT)}</div>
                <div>Site: {siteTime ? datetime(siteTime + (1 >= more.index ? more.time : 0)).format(SHORT_MONTH_DATETIME_FORMAT) : '-'}</div>
                <div>Factory: {factoryTime ? datetime(factoryTime + (factoryIndex >= more.index ? more.time : 0)).format(SHORT_MONTH_DATETIME_FORMAT) : '-'}</div>
                <div>Finish: {finish ? datetime(finish + more.time).format(SHORT_MONTH_DATETIME_FORMAT) : '-'}</div>
            </div>
        </div>
        {activity_log.length === 0
            ? <LoadingBox loading={loading}/>
            : <div className={styles.activityLogs}>
                {activity_log.map((item, i) => <ActivityLog
                    key={i}
                    data={item}
                    prev={activity_log[i - 1]}
                    isTop={i === 0}
                    more={i >= more.index ? more.time : 0}
                    setMore={(time) => setMore({index: i, time})}
                    updateActivityLog={(time) => {
                        const newValue = activity_log.map((item, index) => index >= i
                            ? {...item, est_start_time: item.est_start_time + time}
                            : item);
                        updateActivityLog(newValue)
                        setMore(initMore);
                    }}
                />)}
            </div>}
    </div>
};

export default Duration;

