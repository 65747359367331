import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectDashboard, selectOneSetting} from "../../../util/store/selectors";
import {FactoryMarker} from "../../../contexts/WorldMapContext/Marker";
import {DashboardSetting} from "../../../util/varibles/userSetting";
import {MAP_ELEMENT} from "../../../util/library/googlemap/constants";
import {dashboardActions} from "../reducer";
import {IFactory} from "../../../util/varibles/interface";
import {LOCATION_TYPE} from "../../../util/varibles/constants";

interface IProps {
    ids: Set<string>
}

const Factories: React.FC<IProps> = ({ids}) => {
    const isFactory = useSelector(selectOneSetting(DashboardSetting.FACTORIES));
    const source = useSelector(selectDashboard.factories) || [];
    const dispatch = useDispatch();

    const onClick = useCallback((data: IFactory) => {
        dispatch(dashboardActions.showInfo({data, targetType: MAP_ELEMENT.FACTORY}));
        dispatch(dashboardActions.getWeatherRequest({type: LOCATION_TYPE.FACTORY, id: data.id}))
    }, [dispatch])

    return <>
        {source.map(item => <FactoryMarker
            key={item.id}
            data={item}
            show={isFactory && (ids.size === 0 || ids.has(item.id))}
            onClick={() => onClick(item)}
        />)}
    </>;
};

export default Factories;
