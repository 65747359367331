import React from 'react';
import styles from './style.module.scss';
import DiseaseStatus from "../../../../../components/Location/FishDisease/DiseaseStatus";
import {diseasedZone, diseaseType} from "../../../../../util/varibles/constants";
import {Tooltip} from "antd";

interface IProps {
    data: any
}

const FishDisease: React.FC<IProps> = ({data}) => {
    const {diseases = [], cases = []} = data || {};
    const {disease = null, status = ''} = cases[0] || {};
    if (diseases.length === 0 && !disease)
        return null

    const {flag, names} = diseases.reduce((rs: any, item: any, index: number) => {
        const {color = '', shortName = ''} = diseasedZone[item.type] || {};
        const style: any = {'--diseased-color': color};
        rs.flag.push(<div key={index} className={styles.disease} style={style}/>);
        rs.names.push(shortName);
        return rs;
    }, {flag: [], names: []});

    if (disease) {
        const {name: typeName} = diseaseType[disease];
        names.push(`${typeName} ${status}`);
    }

    return <div className={styles.wrapper}>
        <Tooltip className={styles.tooltip} title={names.join(', ')} placement='topRight'>
            <div>
                {disease && <DiseaseStatus status={disease}/>}
                <div className={styles.diseases}>
                    {flag}
                </div>
            </div>
        </Tooltip>
    </div>;
};

export default FishDisease;