import React from 'react';
import styles from './style.module.scss';
import Vessels from "../Vessels";
import Loading from "./Loading";

interface IProps {
}

const Schedule: React.FC<IProps> = () => {
    return <div className={styles.schedule}>
        <Vessels/>
        <Loading/>
    </div>;
};

export default Schedule;
