import React, {Component} from 'react';
import styles from './style.module.scss';
import ActivityLog from "./ActivityLog";

interface IProps {
    name: string
    list: any
    current_process: number
    lastPoint: number
    activity_log_root: any
}

class Group extends Component<IProps> {
    state = {
        isOpen: false
    }

    render() {
        const {name, list, current_process, lastPoint, activity_log_root} = this.props;
        const {isOpen} = this.state;
        const height = {height: (list.length * 95 + 50) + 'px'}
        return <div className={styles.group} style={height} data-is-expand={isOpen}>
            <div className={styles['container-title-group']}>
                <div
                    className={styles['bt-toggle-group']}
                    data-is-expand={isOpen}
                    onClick={() => this.setState({isOpen: !isOpen})}
                >
                    <svg className={styles['bt-toggle-line-horizontal']} width="10px" height="2px" viewBox="0 0 5.5 1"
                         xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="0.5" x2="5" y2="0.5" strokeLinecap="round"/>
                    </svg>
                    <svg className={styles['bt-toggle-line-vertical']} width="10px" height="2px" viewBox="0 0 5.5 1"
                         xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="0.5" x2="5" y2="0.5" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className={styles['title-group']}>
                    <span>{name}</span>
                </div>
            </div>
            <div className={styles['content-group']} data-is-expand={isOpen}>
                {list.map((item: any) =>
                    <ActivityLog
                        key={item.index}
                        data={item}
                        current_process={current_process}
                        lastPoint={lastPoint}
                        activity_log={activity_log_root}
                    />)}
            </div>
        </div>;
    }
}

export default Group;