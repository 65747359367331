import React, {Component} from 'react';
import styles from './style.module.scss';
import {checkLimit} from "../../../../../../util/varibles/global";
import {IOperation} from '../../../../../../util/varibles/interface';

class Harvest extends Component<IOperation> {
    render() {
        const {name = ''} = this.props.vessel || {};
        const {operation} = this.props;
        const {
            sub_operations,
            site,
            factory_name,
            sites = []
        } = operation;

        const startPlace = sites.length === 0
            ? [site.name, sub_operations.map((item: any) => item.unit_id).join(', ')].join(' - ')
            : sites.map((item: any) => {
                return [item.name, sub_operations.reduce((list: any, sub: any) =>
                        sub.site_id === item.id ? [...list, sub.unit_id] : list
                    , []).join(', ')].join(' - ')
            }).join(' | ')

        const voyage = name + startPlace + factory_name;

        const style: any = {'--width-indent': -(checkLimit(0, undefined, voyage.length - 58) * 7) + 'px'}

        return <>
            {voyage.length <= 40 ? <div className={styles['line-info']}>
                <div className='h-24 d-flex align-items-center text-truncate'>
                    <span>{name}</span>
                    <div className={styles['divider-custom']}/>
                    <span>{startPlace}</span>
                    <span className='arrow-right'>&#8594;</span>
                    <span>{factory_name}</span>
                </div>
            </div> : <div className={styles['line-info']}>
                <div>
                    <div className='d-flex align-items-center text-truncate'>{name}</div>
                    <div className={styles['line-info']}>
                        <div className='animation-ellipsis'>
                            <div
                                className='animation-ellipsis-text h-24 d-flex align-items-center'
                                style={style}
                                title={[startPlace, factory_name].join(' -> ')}
                            >
                                <span>{startPlace}</span>
                                <span className='arrow-right'>&#8594;</span>
                                <span>{factory_name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>;
    }
}

export default Harvest;
