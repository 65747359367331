import callApi from "../api/callApi";
import {urlChat} from "../api/v1";
import {CHAT_TYPE} from "../varibles/constants";
import WSService from "../../web-socket/web-socket-service";

interface IGetChats {
    index: number
}

export function getChatsFetch(body: IGetChats) {
    return callApi(urlChat + 's', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

interface IGetChatsOperation {
    index: number
}

export function getChatsOperationFetch(body: IGetChatsOperation) {
    return callApi(urlChat + 's/operation', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

interface ICreateChat {
    name: string
    users: string[]
    members: any[]
    user_tenant_name: string
    timestamp: number
    username: string
    type: CHAT_TYPE
    roles: string[]
    connection_id: string,
}

export function createChatFetch(body: ICreateChat) {
    return callApi(urlChat, {
        method: "POST",
        body: JSON.stringify(body)
    })
}


interface IUpdateChat {
    id: string
    name: string
}

export function updateChatFetch(body: IUpdateChat) {
    return callApi(urlChat, {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

interface ISendMessage {
    group_id: string,
    user_tenant_name: string,
    message: string,
    timestamp: number,
    username: string,
    connection_id: string,
    roles: string[]
}

export function sendMessageFetch(body: ISendMessage) {
    return callApi(urlChat + '/message', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

interface IUpdateMessage {
    group_id: string,
    chat_id: string,
    message: string,
}

export function updateMessageFetch(body: IUpdateMessage) {
    return callApi(urlChat + '/message', {
        method: "PUT",
        body: JSON.stringify({
            ...body,
            connection_id: WSService.getConnectId()
        })
    })
}

interface IGetMessages {
    id: string
    index: number
}

export function getMessagesFetch(body: IGetMessages) {
    return callApi(urlChat + '/messages', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

interface ISearchUser {
    key: string
    index: number
}

export function searchUserFetch(body: ISearchUser) {
    return callApi(urlChat + '/user', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

interface ISearchOp {
    key: string
    index: number
}

export function searchOpFetch(body: ISearchOp) {
    return callApi(urlChat + '/operation', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

export function readAllMessageFetch() {
    return callApi(urlChat + '/reads', {
        method: "PUT",
    })
}

interface ISeenMessage {
    id: string
    chat_id: string
    username: string
    timestamp: number
    connection_id: string,
}

export function seenMessageFetch(body: ISeenMessage) {
    return callApi(urlChat + '/seen', {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

interface IAddUsersToChat {
    id: string
    users: string[]
    members: any[]
    user_tenant_name: string,
    message: string,
    timestamp: number,
    username: string,
    connection_id: string,
}

export function addUsersToChatFetch(body: IAddUsersToChat) {
    return callApi(urlChat + '/user', {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

interface IRemoveUsersFromChat {
    id: string
    users: string[]
    members: any[]
    user_tenant_name: string,
    message: string,
    timestamp: number,
    username: string,
    connection_id: string,
}

export function removeUsersFromChatFetch(body: IRemoveUsersFromChat) {
    return callApi(urlChat + '/user', {
        method: "DELETE",
        body: JSON.stringify(body)
    })
}

interface ICloseConversation {
    connection_id: string
}

export function closeConversationFetch(chatId: string, body: ICloseConversation) {
    return callApi(urlChat + '/' + chatId, {
        method: "DELETE",
        body: JSON.stringify(body)
    })
}
