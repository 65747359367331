import React, {useEffect, useMemo} from 'react';
import {connect, useSelector} from 'react-redux';
import {CERTAIN_TYPE, OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import stylesContainer from "./style.module.scss";
import {Divider} from "antd";
import AddUnit from "../../_Component/AddUnit";
import AddNote from "../../_Component/AddNote";
import Certain from "../../_Component/Certain";
import Footer from "../../Footer";
import {checkOp, convertUnit} from "./constants";
import {IOperations, IServiceTask, ISite, IUnit} from "../../../../../../util/varibles/interface";
import SelectTime from "../../_Component/SelectTime";
import Unit from "./Unit";
import {getServiceTasksByVessel} from "../../../../../../util/varibles/global";
import {AppState} from "../../../../../../util/store/store";
import {callEventEditOp, getEditOp, IUnitWhenEdit, useEditOp} from "../../../../../../contexts/EditOperationContext";
import {selectTenantId} from "../../../../../../util/store/selectors";
import {UnitModel} from "../../../../BiologyBrowser/constants";
import LineMiddle2 from "../../_Component/LineMiddle2";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";

const mapStateToProps = (state: AppState) => ({
    tenant_id: state.login.user.tenant_id,
    sites: state.planOperation.sites,
    serviceTasks: state.planOperation.serviceTasks,
    operations: state.planOperation.operations,
})

interface IProps {
    tenant_id: any
    popupData: any
    sites: ISite[]
    serviceTasks: IServiceTask[]
    operations: IOperations
    loading?: boolean


    onClose(): void
}

const Service: React.FC<IProps> = (props) => {
    const [loadingSubmit, set] = useEditOp(state => state.loadingSubmit);
    const [operation] = useEditOp(state => state.operation);
    const [units] = useEditOp(state => state.units);
    const userTenantId = useSelector(selectTenantId);
    const get = getEditOp();
    const {operations} = props;
    const {getActivityLog, handeUpdateOp} = callEventEditOp();

    useEffect(() => {
        const generateState = (props: IProps) => {
            const {sites, serviceTasks} = props;
            const {operation, vessel, activity_log = [], store} = props.popupData.values;
            const startTime = activity_log.length > 0 ? activity_log[0].est_start_time : Date.now();


            const {site_id: siteId} = store[Object.keys(store)[0]] || {}
            const {
                tenant_id = userTenantId,
                operation_type = OPERATION_TYPE.SERVICE,
                site_id = siteId,
                tasks = Object.keys(store).reduce((list: any, key: string) => {
                    return [...list, {
                        sub_operation: convertUnit(store[key]),
                        error: {},
                    }]
                }, []),
                certain_mode = CERTAIN_TYPE.NONE,
                sub_operations
            } = operation;

            const indexes: { [id: string]: number } = {};
            const group = tasks.reduce((rs: any, item: any, index: number) => {
                const {id} = item.sub_operation
                const data = {...item, index};
                if (!rs[id]) {
                    indexes[id] = index;
                    rs[id] = {
                        tasks: [data]
                    }
                } else
                    rs[id].tasks.push(data)

                return rs;
            }, {});

            const site = sites.find((item: any) => item.id === site_id);
            const op = {
                ...operation,
                tenant_id,
                operation_type,
                site_id,
                site_name: site?.name,
                tasks,
                certain_mode,
            }
            if (!site)
                return;

            const units = (site?.units || []).reduce((rs: { [id: string]: IUnitWhenEdit }, item) => {
                const {id} = item;
                const old = sub_operations.find((item: IUnit) => item.id === id) || {};
                rs[id] = {...UnitModel(item, site), ...group[id] || {}, ...old, index: indexes[id]};
                return rs;
            }, {});

            set({
                site,
                startTime,
                serviceTasks: getServiceTasksByVessel({serviceTasks, vessel}),
                operation: op,
                isCalculate: !op.id,
                units,
            })
        }
        const el = document.getElementById('container-service');
        if (el)
            setTimeout(() => el.scrollTo(0, 0), 100);
        generateState(props)
    }, [set, userTenantId, props]);

    const handleSubmit = () => {
        const {operation, isCalculate, startTime, units, vessel} = get();
        const {id: opId = 0} = operation || {};
        if (!isCalculate) {
            const {onSave} = get();
            onSave({
                update: {
                    [opId]: {
                        ...operations[opId],
                        operation: {
                            ...operations[opId].operation,
                            sub_operations: Object.keys(units).reduce((rs: IUnit[], key: string) => {
                                const {tasks = [], ...argsUnit} = units[key];
                                if (tasks.length > 0)
                                    return [...rs, argsUnit]
                                return rs;
                            }, [])
                        }
                    }
                }, isCheck: false
            });
        } else {
            let newSubOperations: IUnit[] = [], error = false, newTasks: IServiceTask[] = [];
            const newUnits = Object.keys(units).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                const {tasks = [], ...args} = units[key];
                if (tasks.length > 0) {
                    if (!args.fish_amount || !args.total_weight) {
                        notify.error(NotifyCode.E40)([args.unit_id]);
                        error = true;
                        return rs;
                    }

                    newSubOperations.push(args)
                    const {isError, tasks: tasksAfterCheck} = checkOp(tasks);
                    if (isError) {
                        error = true;
                        rs[key] = {...units[key], tasks: tasksAfterCheck}
                    } else
                        newTasks = [...newTasks, ...tasks] as IServiceTask[]
                }
                return rs
            }, {});

            if (error) {
                set(({units: prev}) => ({units: ({...prev, ...newUnits})}))
                return;
            }
            const {site_id, site_name} = newSubOperations[0];

            const data: any = {
                activity_log: [],
                operation: {
                    ...operation,
                    site_id,
                    site_name,
                    operation_type: OPERATION_TYPE.SERVICE,
                    tasks: newTasks,
                    sub_operations: Object.keys(units).reduce((rs: IUnit[], key: string) => {
                        const {tasks = [], ...argsUnit} = units[key];
                        if (tasks.length > 0)
                            return [...rs, argsUnit]
                        return rs;
                    }, [])
                },
                vessel,
            };

            getActivityLog({source: [data], properties: {[opId]: {start: startTime}}})
        }
    }

    const {note = '', certain_mode = CERTAIN_TYPE.NONE} = operation || {};

    const taskByUnit = useMemo(() => {
        return Object.keys(units).reduce((rs: IUnitWhenEdit[], key) => {
            const {tasks = []} = units[key];
            if (tasks.length > 0) {
                rs.push(units[key])
            }
            return rs
        }, [])
    }, [units]);

    return <>
        <div id='container-service' className={styles.wrapper}>
            <div className={styles['sub-plan']}>
                <SelectTime/>
                <div className={styles['sub-section-right']}>
                    <AddUnit/>
                    <AddNote
                        title='Service'
                        value={note}
                        onChange={(note: string) => handeUpdateOp({note})}
                    />
                    <Divider type='vertical'/>
                    <Certain
                        value={certain_mode}
                        onChange={certain_mode => handeUpdateOp({certain_mode})}
                    />
                </div>
            </div>
            <div className={styles['wrapper-operation']}>
                {taskByUnit.length === 0 && <div className={styles.line} data-lev='empty'>No task</div>}
                <div id='service-operation' className={styles.operation}>
                    <div className={stylesContainer['line-middle']} data-level='top'>
                        <LineMiddle2 containerId={'service-operation'}/>
                    </div>
                    {taskByUnit.map((item) => <Unit
                        key={item.id}
                        unit={item}
                    />)}
                </div>
            </div>
        </div>
        <Footer {...{
            loading: loadingSubmit || props.loading,
            handleSubmit,
            onClose: () => {
                props.onClose();
            },
        }}/>
    </>;
};

export default connect(mapStateToProps, {})(Service);
