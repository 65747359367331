import React from 'react';
import styles from '../style.module.scss'
import {vesselTypes} from "../../../../util/varibles/constants";
import {setEditVessel} from "../../../../contexts/EditVesselContext";

interface IProps {
}

const VesselType: React.FC<IProps> = () => {
    const setStore = setEditVessel();

    return <div className={styles['select-vessel-type']}>
        <div className={styles.header}>
            <p>Add new vessel</p>
            <p>Select vessel type you would like to add</p>
        </div>
        <div className={styles['container-vessel-type']}>
            {vesselTypes.map(({id, name}) => <div key={id} {...{
                className: styles['vessel-type'],
                onClick: () => setStore(({vessel}) => ({vessel: {...vessel, type: id}}))
            }}>
                <span>{name}</span>
            </div>)}
        </div>
    </div>;
};

export default VesselType;
