import React from 'react';
import {Tooltip} from "antd";
import styles from "./style.module.scss";
import InputCondition from "../../InputCondition";

interface IProps {
    data: {
        id: string
        bad_condition: string
        moderate_condition: string
        good_condition: string
    }

    editing?: boolean

    openEditMode?(): void
}

const Content: React.FC<IProps> = ({data, editing, openEditMode}) => {
    const {id, bad_condition = '', moderate_condition = '', good_condition = ''} = data || {};
    return <div className={styles.body}>
        <div className='d-flex'>
            <Tooltip title='Bad weather'>
                <label className={styles.bad}/>
            </Tooltip>
            <InputCondition {...{
                id: id + '-bad',
                value: bad_condition,
                editing,
                placeHolder: 'Bad weather condition',
                openEditMode
            }}/>
        </div>
        <div className='d-flex'>
            <Tooltip title='Moderate weather'>
                <label className={styles.moderate}/>
            </Tooltip>
            <InputCondition {...{
                id: id + '-moderate',
                value: moderate_condition,
                editing,
                placeHolder: 'Moderate weather condition',
                openEditMode
            }}/>
        </div>
        <div className='d-flex'>
            <Tooltip title='Good weather'>
                <label className={styles.good}/>
            </Tooltip>
            <InputCondition {...{
                id: id + '-good',
                value: good_condition,
                editing,
                placeHolder: 'Good weather condition',
                openEditMode
            }}/>
        </div>
    </div>;
};

export default Content;

