import React, {createRef, useEffect} from 'react';
import styles from './style.module.scss';

interface IProps {
    attr?: {
        unitId?: string
        taskParentId?: string
        condition?: string
        taskId?: string
    }
    containerId: string
}

let parentEl: HTMLElement | null;
const LineMiddle: React.FC<IProps> = ({attr = {}, containerId}) => {
    const rootRef = createRef<HTMLDivElement>()

    useEffect(() => {
        const el = rootRef.current
        if (el) {
            parentEl = document.getElementById(containerId);
            el.addEventListener("mouseover", () => handleMouseEnter(el));
            el.addEventListener("mouseleave", () => handleMouseLeave(el));
        }
        return () => {
            if (el) {
                el.removeEventListener("mouseover", () => handleMouseEnter(el));
                el.removeEventListener("mouseleave", () => handleMouseLeave(el));
            }
        }
    }, [rootRef, containerId]);

    const handleMouseEnter = (el: HTMLDivElement) => {
        if (parentEl?.dataset.isDrag) {
            el.dataset.hover = "true";
        }
    }

    const handleMouseLeave = (el: HTMLDivElement) => {
        el.dataset.hover = "false";
    }

    return <div
        ref={rootRef}
        className={styles['container-line']}
        data-mode='change'
        {...Object.keys(attr).reduce((rs: { [id: string]: any }, key) => {
            const {[key]: value}: any = attr;
            if (value)
                rs[`data-${key.toLowerCase()}`] = value;
            return rs;
        }, {})}
    >
        <div className={styles['icon-plus']}/>
    </div>;
};

export default LineMiddle;
