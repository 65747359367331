import React from 'react';
import {IDiffItem} from "../../../../../util/varibles/interface";
import {LOG_STATUS, treatmentType} from "../../../../../util/varibles/constants";
import {status} from "./Operation";

interface IProps {
    data: IDiffItem[]
}

const Tasks: React.FC<IProps> = (props) => {
    const {data} = props;
    return <>
        {data.map(item => {
            const {state, new: value, old} = item || {};
            if (state === LOG_STATUS.UPDATE) {
                return null;
            } else {
                const treatment_type = value?.treatment_type || old?.treatment_type;
                const {name} = treatmentType[treatment_type] || {};
                return name ? [name, status[state]].join(' ') : null
            }
        })}
    </>;
};

export default Tasks;
