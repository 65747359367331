import React from 'react';
import styles from "./style.module.scss";
import Icon, {ICON_NAME} from "../Icon";
import {Symbol} from "../../util/varibles/template";
import {getStatusDensity2} from "../../pages/PlanOperationPage/util/function_operation/constants";

interface IProps {
    density: number
    max_density?: number
}

const Density: React.FC<IProps> = React.memo(({density, max_density = 0}) => {
    const {color = '', colorText} = getStatusDensity2(max_density, density);
    const max = Math.round(max_density * 100000) / 100;

    return <div className={styles.density}>
        <Icon icon={ICON_NAME.TANK_2} style={{background: color, marginBottom: '-1px'}}/>
        <div>
            <span style={{color: colorText}}>{Math.round(density * 100000) / 100} kg/{Symbol.m3} </span>
            {max > 0 && <span className='text-comment ml-[5px]'>({max}kg/{Symbol.m3})</span>}
        </div>
    </div>;
}, (prev, next) =>
    prev.density === next.density
    && prev.max_density === next.max_density);

export default Density;
