import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import styles from "../style.module.scss";
import ViewChecklist from "../ViewChecklist";
import InputSearch from "../../../components/Basic/InputSearch";
import {Button, Divider} from "antd";
import {IChecklist} from "../../../util/varibles/interface";
import {useResizeDetector} from "react-resize-detector";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEdit from "../PopupEdit";
import {ACTION_TYPE} from "../../../util/varibles/constants";
import {checklistActions} from "../reducer";
import {AppState} from "../../../util/store/store";

const sortChecklists = () => {
    let countColumn = Math.floor((window.innerWidth - 50) / 610);
    countColumn = countColumn <= 0 ? 1 : countColumn;
    const columns = Array(countColumn).fill(0);
    const container: any = document.getElementById('container-checklists')
    Array.from(container.children).forEach((child: any) => {
        const index = columns.indexOf(Math.min(...columns));
        child.style.order = index;
        columns[index] += parseFloat(child.clientHeight);
    })
    container.style.height = Math.max(...columns) + 'px';
}

const mapStateToProps = (state: AppState) => ({
    checklists: state.checklist.checklists,
});

interface IProps {
    checklists: IChecklist[]

    add(payload: IChecklist): void
}

const Checklists: React.FC<IProps> = ({checklists, add}) => {
    useEffect(() => {
        sortChecklists()
    }, [checklists]);

    const openEdit = () => {
        const el = openPopup(<PopupEdit
            editMode={ACTION_TYPE.CREATE}
            onSave={add}
            onClose={() => el.remove()}
        />)
    }

    const [keyword, setKeyword] = useState('');
    const data = checklists.filter(checklist =>
        (keyword.length === 0 || checklist.tags.some(tag => tag.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())))
        && checklist.is_active
    );

    const {ref} = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 1000,
        onResize: () => sortChecklists()
    });

    return <>
        <div className='m-header bgPage'>
            <div className={styles.header}>
                <div className='title-page'>
                    <b>Checklist</b>
                </div>
                <div className={styles.action}>
                    <InputSearch placeHolder='Search by tag' onChange={(value) => setKeyword(value)}/>
                    <Divider className='divider-vertical' type='vertical'/>
                    <Button className='bt-primary bt-plus' shape='circle' onClick={openEdit}/>
                </div>
            </div>
            <div id='container-checklists' ref={ref} className={styles.body}>
                {data.map((checklist) => <ViewChecklist key={checklist.id} {...checklist} />)}
            </div>
        </div>
    </>;
};
export default connect(mapStateToProps, {
    add: checklistActions.add
})(Checklists);
