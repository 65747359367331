import React from 'react';
import styles from "./style.module.scss";
import Icon, {ICON_NAME} from "../Icon";
import {productionAreas} from "../../util/varibles/constants";
import {getProdAreaName} from "../../util/varibles/global";

interface IProps {
    data: any
}

const ProductionArea: React.FC<IProps> = ({data}) => {
    const productionArea = productionAreas.find(item => item.id = data.prod_area);

    return <div className={styles.wrapperInfoIcon}>
        <Icon icon={ICON_NAME.PRODUCTION_AREA} size='large'/>
        <div className={styles.info}>
            <div>Production area:</div>
            <div>{productionArea ? getProdAreaName(productionArea) : '-'}</div>
        </div>
    </div>;
};

export default ProductionArea;