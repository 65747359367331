import React from 'react';
import {useSelector} from 'react-redux';
import {CERTAIN_TYPE, certainType, certainTypes} from "../../../../../../util/varibles/constants";
import styles from './style.module.scss';
import {PreferenceSetting} from "../../../../../../util/varibles/userSetting";
import {selectOneSetting} from "../../../../../../util/store/selectors";

interface IProps {
    value: any
    isLabel?: boolean
    className?: string

    onChange(value: any): void
}

const Certain: React.FC<IProps> = (props) => {
    const handleClick = (id: CERTAIN_TYPE) => {
        props.onChange(id);
    }
    const isCertainty = useSelector(selectOneSetting(PreferenceSetting.SHOW_CERTAINTY))
    const {value = CERTAIN_TYPE.NONE, isLabel = true} = props;
    if (!isCertainty)
        return <div/>;

    const certain = value.length > 0 ? value : CERTAIN_TYPE.NONE;

    const {color} = certainType[certain];
    let position = 2;
    const list = certainTypes.map((item, index) => {
        const style: any = {'--color': item.fontColor};
        if (item.id === certain)
            position = 2 - index;
        return <div
            key={item.id}
            tabIndex={1}
            className={[styles.certain, 'no-select'].join(' ')}
            data-icon={item.icon}
            data-active={item.id === certain}
            style={style}
            onMouseDown={() => handleClick(item.id)}
        >
            {item.name}
        </div>
    });
    const styleSwitch: any = {transform: `translateX(-${position * 100}%)`, background: color};

    return <div className={[styles.wrapper, props.className].join(' ')} data-is-label={isLabel}>
        {isLabel && <div className='mr-10'>Certainty:</div>}
        <div className={styles.certainties}>
            {list}
            <div className={styles.switch} style={styleSwitch}/>
        </div>
    </div>;
};

export default Certain;