import React, {Component} from 'react';
import {SHORT_DATETIME_FORMAT, STATE_HARVEST} from "../../../../../util/varibles/constants";
import styles from "../style.module.scss";
import {SwapRightOutlined} from "@ant-design/icons";
import {Divider} from "antd";
import {IPropsSearchItem} from "../constants";
import {NavLink} from "react-router-dom";
import {datetime} from "../../../../../util/library/datetime";

interface IProps extends IPropsSearchItem {
}

class Harvest extends Component<IProps> {

    render() {
        const {
            operation_code,
            site_name,
            factory_name,
            sub_operations,
            vessel_name,
            current_process,
            status,
            est_start_time,
            est_finish_time,
            tenant_id,
            id
        } = this.props.data;
        const statusTxt = status === "processing" ? STATE_HARVEST[current_process] : status;
        const unitIds = sub_operations.map((item: any) => item.unit_id).join(', ');
        return <div className={styles['item-result']}>
            <NavLink to={`/operation/${tenant_id}/${id}`}>
                <div className={styles['line-info-item']}>
                    <div className={styles['icon-harvest']}/>
                    <span className='ml-2 mr-3'>{'#' + operation_code}</span>
                    <span>{site_name + ' - ' + unitIds}</span>
                    <SwapRightOutlined className='ml-2 mr-2 font-s3'/>
                    {factory_name}
                </div>
                <div className={styles['line-info-item']}>
                    <span>{vessel_name}</span>
                    <Divider className={styles['divider-line']} type="vertical"/>
                    <div className={styles['operation-type']}>Harvest</div>
                    <div className={[styles['operation-status'], 'ml-2'].join(' ')}>{statusTxt}</div>
                </div>
                <div className={styles['line-info-item']}>
                    {datetime(est_start_time).format(SHORT_DATETIME_FORMAT)}
                    <SwapRightOutlined className='ml-2 mr-2 font-s3'/>
                    {datetime(est_finish_time).format(SHORT_DATETIME_FORMAT)}
                </div>
            </NavLink>
        </div>;
    }
}

export default Harvest;
