import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import ContainerElement from "./ContainerElement";
import styles from "./style.module.scss";

interface IProps extends IPropsElement {

}

class LongTextElement extends Component<IProps> {
    render() {
        const {
            isAllowSelect, label, properties: {
                placeHolder = '', subLabel = '',
                // numberOfRows = 2
            }
        } = this.props;
        return <ContainerElement {...this.props}>
            <label data-is-empty={label.length === 0}>{label || (isAllowSelect ? 'Label' : '')}</label>
            <div className={styles['control']}>
                <div className={styles['placeholder']} data-is-empty={placeHolder.length === 0}>
                    {placeHolder || (isAllowSelect ? 'Place holder' : '')}
                </div>
                <div className={styles['sub-label']}>
                    {subLabel || (isAllowSelect ? 'Sub label' : '')}
                </div>
            </div>
        </ContainerElement>;
    }
}

export default LongTextElement;
