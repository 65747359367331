import React, {useCallback, useEffect, useState} from 'react';
import {EL_RULE, getBgVessel, VESSEL_COLOR} from "../constants";
import {AVAILABLE_TIME_TYPE} from "../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {addClassName, clearSelect, removeClassName} from "../../../../../util/varibles/global";
import {IPropsChildren, IVessel} from '../../../../../util/varibles/interface';
import {connect} from "react-redux";
import Days from "./Days";
import {planOpActions} from "../../../reducer";
import {convertPxToTime} from "../../../util";
import {AppState} from "../../../../../util/store/store";
import {callEventPlan, getPlan} from "../../../../../contexts/PlanOperationContext/Plan";

let pageX = 0, scrollLeft = 0;

const mapStateToProps = (state: AppState) => ({
    opFocusId: state.planOperation.opFocusId,
});

interface IProps extends IPropsChildren {
    vessel: IVessel
    width: number
    index: number
    opFocusId: any
    countDay: number
    calendarId: string

    clearFocus(): void
}

const Vessel: React.FC<IProps> = (props) => {
    const {vessel, index, countDay, opFocusId, clearFocus} = props;
    const [dragging, setDragging] = useState(false);
    const {isOwn, permission, available_time_type} = vessel;
    const {id, isShow} = vessel;
    const get = getPlan();
    const {setTarget} = callEventPlan();

    const handleMouseMove = useCallback((e: any) => {
        const {calendarRef} = get();
        if (calendarRef)
            calendarRef().scrollLeft = scrollLeft - e.pageX + pageX;
    }, [get]);

    const handleMouseUp = useCallback(() => {
        setDragging(false);
        removeClassName(document.body);
        clearSelect();
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove])

    useEffect(() => {
        return handleMouseUp;
    }, [handleMouseUp]);

    const isAccessFull = !!(isOwn || permission || available_time_type === AVAILABLE_TIME_TYPE.ALL);

    const handleMouseDown = useCallback((e: any) => {
        if (opFocusId)
            clearFocus();
        const {startPoint} = get();
        const time = startPoint + convertPxToTime(e.nativeEvent.layerY);
        setTarget({type: EL_RULE.VESSEL, vessel, time}, e)

        if (e.button !== 0)
            return;

        pageX = e.pageX;
        const {calendarRef} = get();
        if (calendarRef)
            scrollLeft = calendarRef().scrollLeft;
        setDragging(true);
        addClassName(document.body);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove, handleMouseUp, opFocusId, clearFocus, get, vessel]);

    const color = VESSEL_COLOR[(index + 1) % VESSEL_COLOR.length];
    const style: any = isAccessFull ? {background: color} : {
        backgroundAttachment: 'fixed',
        backgroundImage: getBgVessel(color),
    }

    style.display = isShow ? 'grid' : 'none';
    style.minWidth = props.width + '%';

    return <div
        id={`${EL_RULE.VESSEL}-${id}`}
        className={styles.vessel}
        style={style}
        data-dragging={dragging}
        data-rule={EL_RULE.VESSEL}
        data-vessel-id={id}
        data-access-full={isAccessFull}
        onMouseDown={handleMouseDown}
    >
        {props.children}
        <Days
            count={countDay}
            vesselId={vessel.id}
            isAccessFull={isAccessFull}
            onMouseDown={(time, e) => setTarget({type: EL_RULE.VESSEL, vessel, time}, e)}
        />
    </div>;
};

export default connect(mapStateToProps, {
    clearFocus: planOpActions.clearFocus,
})(Vessel);
