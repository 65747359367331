export enum LEVEL_DENSITY {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high'
}


export const levelDensity = {
    [LEVEL_DENSITY.LOW]: {
        level: LEVEL_DENSITY.LOW,
        name: 'Low density',
        short: 'Low',
        color: '#56A513'
    },
    [LEVEL_DENSITY.MEDIUM]: [
        {
            level: LEVEL_DENSITY.MEDIUM,
            name: 'Medium density',
            short: 'Medium',
            color: '#EBCB49'
        }
    ],
    [LEVEL_DENSITY.HIGH]: {
        level: LEVEL_DENSITY.HIGH,
        name: 'High density',
        short: 'High',
        color: '#F15656'
    }
}