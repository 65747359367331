 import React from 'react';
import {NavLink} from "react-router-dom";
import {vesselType} from "../../../../util/varibles/constants";
import styles from './style.module.scss';
import {IVessel} from "../../../../util/varibles/interface";
import {IVesselHeight} from "./index";

interface IProps {
    data: IVessel & { isPermission: boolean }
    moreHeight: number
    vesselHeight: IVesselHeight
}

const Vessel: React.FC<IProps> = ({data, moreHeight, vesselHeight}) => {
    const {name, type, id, isPermission} = data;
    const {name: nameVesselType = ''} = vesselType[type] || {};
    const {height} = vesselHeight[id];

    const style = {
        height: `${height + moreHeight}px`
    }

    return <div className={styles.vessel} style={style} data-is-permission={isPermission}>
        <div/>
        <NavLink to={"/vessel/" + id}>{name}</NavLink>
        <p>{nameVesselType}</p>
    </div>;
};

export default Vessel;

