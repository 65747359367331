import React from 'react';
import styles from './style.module.scss';
import {Checkbox} from "antd";
import {vesselOpType} from "./";

interface IProps {
    data: any
}

const Vessel: React.FC<IProps> = ({data}) => {
    const {id, name, opType} = data;

    return <div id={['filter', id].join('-')} className={styles.vessel}>
        <Checkbox className={styles.checkbox} value={id}>
            <div className={styles.vesselName}>{name}</div>
            <div className={styles.opType}>
                {opType.reduce((list: any, item: any) => {
                    const {name = null} = vesselOpType[item] || {};
                    return name ? [...list, name] : list;
                }, []).join(', ')}
            </div>
        </Checkbox>
    </div>;
};

export default Vessel;