import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import {IPropsChildren} from "../../../../util/varibles/interface";
import {Collapse} from "antd";
import {LeftOutlined, PlusOutlined} from "@ant-design/icons";
import styles from './style.module.scss';
import {WEATHER_TYPE} from "../../util";
import LoadingBox from "../../../../components/LoadingBox";
import {AppState} from "../../../../util/store/store";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import PopupEdit from "../PopupAdd";

const mapStateToProps = (state: AppState) => ({
    loading: state.weather.loadingList
});

interface IProps extends IPropsChildren {
    title: string
    loading: boolean
    children: ReactNode
    extra?: ReactNode
    type: WEATHER_TYPE
    count: number
}

const Section: React.FC<IProps> = (props) => {
    const {type, count, loading, extra, children} = props;
    const handleAdd = (e: any) => {
        e.stopPropagation();
        const el = openPopup(<PopupEdit type={type} onClose={() => el.remove()}/>)
    }

    return <div className={styles.section}>
        <Collapse {...{
            defaultActiveKey: ['1'],
            expandIconPosition: 'end',
            expandIcon: ({isActive}) => <LeftOutlined
                className={[styles.btCollapse, 'mt-[8px]'].join(' ')}
                rotate={isActive ? -90 : 0}
            />,
            items: [{
                key: '1',
                label: `${props.title} ${count > 0 ? `(${count})` : ''}`,
                children: count === 0
                    ? <LoadingBox loading={loading}/>
                    : <div className={styles.content}>{children}</div>,
                extra: <div className={styles.extra}>
                    {extra}
                    <div className={styles.btAdd} onClick={handleAdd}><PlusOutlined/></div>
                </div>
            }]
        }}/>
    </div>;
};

export default connect(mapStateToProps, {})(Section);
