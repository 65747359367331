/* eslint-disable complexity */
import type {Ref} from 'react';
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef} from 'react';

import {useMap} from '@vis.gl/react-google-maps';
import {DISEASED_ZONE, diseasedZone} from "../../util/varibles/constants";
import {useWorldMap} from "./index";

type PolygonEventProps = {
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onDrag?: (e: google.maps.MapMouseEvent) => void;
    onDragStart?: (e: google.maps.MapMouseEvent) => void;
    onDragEnd?: (e: google.maps.MapMouseEvent) => void;
    onMouseOver?: (e: google.maps.MapMouseEvent) => void;
    onMouseMove?: (e: google.maps.MapMouseEvent) => void;
    onMouseOut?: (e: google.maps.MapMouseEvent) => void;
};

type PolygonCustomProps = {
    /**
     * this is an encoded string for the path, will be decoded and used as a path
     */
    paths?: string[];
    show?: boolean;
};

export type PolygonProps = google.maps.PolygonOptions &
    PolygonEventProps &
    PolygonCustomProps;

export type PolygonRef = Ref<google.maps.Polygon | null>;

function usePolygon(props: PolygonProps) {
    const {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseMove,
        onMouseOut,
        paths,
        show = true,
        ...polygonOptions
    } = props;

    const callbacks = useRef<Record<string, (e: unknown) => void>>({});
    Object.assign(callbacks.current, {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseMove,
        onMouseOut
    });

    const polygon = useRef(new google.maps.Polygon()).current;
    // update PolygonOptions (note the dependencies aren't properly checked
    // here, we just assume that setOptions is smart enough to not waste a
    // lot of time updating values that didn't change)
    useEffect(() => {
        polygon.setOptions(polygonOptions);
    }, [polygon, polygonOptions]);

    const map = useMap();

    // update the path with the encodedPath
    useMemo(() => {
        if (!paths) return;
        polygon.setPaths(paths);
    }, [polygon, paths]);

    // create polygon instance and add to the map once the map is available
    useEffect(() => {
        if (!map) {
            if (map === undefined)
                console.error('<Polygon> has to be inside a Map component.');

            return;
        }

        polygon.setMap(show ? map : null);

        return () => {
            polygon.setMap(null);
        };
    }, [map, show]);

    // attach and re-attach event-handlers when any of the properties change
    useEffect(() => {
        if (!polygon) return;

        // Add event listeners
        const gme = google.maps.event;
        [
            ['click', 'onClick'],
            ['drag', 'onDrag'],
            ['dragstart', 'onDragStart'],
            ['dragend', 'onDragEnd'],
            ['mouseover', 'onMouseOver'],
            ['mousemove', 'onMouseMove'],
            ['mouseout', 'onMouseOut']
        ].forEach(([eventName, eventCallback]) => {
            gme.addListener(polygon, eventName, (e: google.maps.MapMouseEvent) => {
                const callback = callbacks.current[eventCallback];
                if (callback) callback(e);
            });
        });

        return () => {
            gme.clearInstanceListeners(polygon);
        };
    }, [polygon]);

    return polygon;
}

/**
 * Component to render a polygon on a map
 */
const Polygon = forwardRef((props: PolygonProps, ref: PolygonRef) => {
    const polygon = usePolygon(props);
    useImperativeHandle(ref, () => polygon, []);

    return null;
});

interface IDiseaseZonePolygon {
    data: any
    show?: boolean
    diseaseType: DISEASED_ZONE
}

const DiseaseZonePolygon: React.FC<IDiseaseZonePolygon> = ({data, show = true, diseaseType}) => {
    const {coordinates} = data;
    const {color = '', zIndex = 0} = diseasedZone[diseaseType] || {};
    const [zonesLock, setStore] = useWorldMap(state => state.zonesLock);

    return <Polygon
        geodesic={true}
        show={show}
        paths={coordinates}
        strokeColor={color}
        strokeOpacity={0.8}
        strokeWeight={3}
        fillColor={color}
        fillOpacity={0.35}
        zIndex={zIndex}
        onClick={e => setStore({zonesLock: true, location: e.latLng ? [e.latLng.lat(), e.latLng.lng()] : undefined})}
        onMouseMove={e => {
            if (!zonesLock)
                setStore({location: e.latLng ? [e.latLng.lat(), e.latLng.lng()] : undefined})
        }}
        onMouseOut={() => {
            if (!zonesLock)
                setStore({location: undefined})
        }}
    />
};

export {DiseaseZonePolygon}