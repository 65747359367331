import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty} from "./constants";
import styles from "./style.module.scss";
import {Select} from "antd";
import {signers} from "../../../../util/varibles/constants";

interface IProps extends IPropsPropertyElement {
}

const {Option} = Select;

class SignElement extends Component<IProps> {
    handleChange = (value: any) => {
        const data = signers.filter(item => value.indexOf(item.value) !== -1);
        onChangeProperty({options: JSON.stringify(data)}, this.props);
    }

    render() {
        const {selected} = this.props;
        if (!selected) return null;
        const {label, properties: {options}} = selected;
        const value = JSON.parse(options).map((item: any) => item.value);
        return <>
            <div className={styles.title}>Sign Properties</div>
            <div className={styles['property-element']}>
                <label>Label</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
                <span>Type a text field label.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Who need to sign</label>
                <Select {...{
                    className: 'select-mode-tag', mode: "multiple", suffixIcon: null, value,
                    onChange: (value: any) => this.handleChange(value)
                }}>
                    {signers.map(({label, value}) => <Option key={value} value={value}>{label}</Option>)}
                </Select>
                <span>Select people who need to sign to checklist.</span>
            </div>
        </>;
    }
}

export default SignElement;
