import dayjs, {Dayjs} from "dayjs";

const timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone);
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc);
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear);
const isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek);
const dayOfYear = require('dayjs/plugin/dayOfYear')
dayjs.extend(dayOfYear)
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
const weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)
const updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

// @ts-ignore
dayjs.updateLocale('en', {
    weekStart: 1
});

export function datetime(data: any = Date.now()): Datetime {
    return new Datetime(data);
}

class Datetime {
    private readonly data: any = dayjs();

    constructor(data?: any) {
        this.data = dayjs(data);
        return this;
    }

    format(type: string) {
        return this.data.format(type);
    }

    add(time: number, unit: any) {
        return new Datetime(this.data.add(time, unit));
    }

    subtract(time: number, unit: any) {
        return new Datetime(this.data.subtract(time, unit));
    }

    diff(time: Date | number, unit = 'millisecond') {
        const data = datetime(time);
        return this.data.diff(data.value, unit);
    }

    startOf(unit: any) {
        return new Datetime(this.data.startOf(unit));
    }

    endOf(unit: any) {
        return new Datetime(this.data.endOf(unit));
    }

    toISOString() {
        return this.data.toISOString();
    }

    set(data: any) {
        let time = this.data;
        Object.keys(data).forEach((key: any) => {
            switch (key) {
                case 'day':
                    time = time.weekday(data[key]);
                    break;
                case 'week':
                    time = time.isoWeek(data[key]);
                    break;
                default:
                    time = time.set(key, data[key]);
            }
        })
        return new Datetime(time);
    }

    get isValid() {
        return this.data.isValid();
    }

    get day() {
        return this.data.weekday();
    }

    get date() {
        return this.data.date();
    }

    get month() {
        return this.data.month();
    }

    get week() {
        const value: any = this.data;
        return value.isoWeek();
    }

    get year() {
        return this.data.year();
    }

    get weekYear() {
        return this.data.isoWeekYear();
    }

    get hour() {
        return this.data.hour();
    }

    get minute() {
        return this.data.minute();
    }

    get second() {
        return this.data.second();
    }

    get time() {
        return this.data.valueOf();
    }

    get utcOffset() {
        return this.data.utcOffset();
    }

    get UTCText() {
        return this.data.utc().format();
    }

    get value(): Dayjs {
        return this.data;
    }
}

export default Datetime
