import React from 'react';
import {Modal} from "antd";

interface IPropsConfirm {
    content: string
    subContent?: string
    ok?: {
        text?: string
        danger?: boolean
        click?(): void
    }
    cancel?: {
        text?: string
        danger?: boolean
        click?(): void
    }
}

const modal = {
    confirm: (props: IPropsConfirm) => {
        const modal = Modal.confirm({
            icon: null,
            content: <div className=' font-w4 font-s16'>{props.content}</div>,
            maskClosable: true,
            centered: true,
            okText: 'Confirm',
            width: 450,
            onOk: () => {
                modal.destroy();
                const {click} = props.ok || {}
                if (click)
                    click()
            },
            okButtonProps: {
                danger: true,
                className: ['font-w4', 'bt-danger'].join(' ')
            },
            cancelButtonProps: {
                className: ['font-w4', 'bt-default'].join(' ')
            }
        })
    }
}

export default modal
