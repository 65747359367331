import callApi, {api} from "../api/callApi";
import {urlNotification, urlUser} from "../api/v1";
import {NOTE_TYPE} from "../varibles/constants";

interface IGetNotificationOfMessageFetch {
    id: string
    index: number
}

export function getNotificationOfMessageFetch(body: IGetNotificationOfMessageFetch) {
    return callApi(urlNotification + '/message', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

export function getNotificationGlobalFetch(body: IGetNotificationOfMessageFetch) {
    return callApi(urlNotification + '/operation', {
        method: "POST",
        body: JSON.stringify(body)
    })
}

interface IUpdateNotificationStatusOfMessageFetch {
    id: string
    operation_idv: string
    status: string
}

export function updateNotification(body: IUpdateNotificationStatusOfMessageFetch) {
    return callApi(urlNotification, {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export function clearAllNotificationFetch() {
    return callApi(urlNotification + '/s', {
        method: "PUT",
    })
}

interface IChangePasswordByAdmin {
    username: string
    password: string
}

interface ICreateNoteByUser {
    note: string
    timestamp: number
    level: NOTE_TYPE
}

interface IUpdateNoteByUser {
    id: string
    note: string
    timestamp: number
}

interface IDeleteNoteByUser {
    id: string
    timestamp: number
}

interface IGetUserLog {
    timestamp: number
}

interface IGetNotifications {
    id?: string
}

interface ISeenNotification {
    id: string
}

export const UserService = {
    gets: () => api.get(urlUser + 's'),
    create: (user: any) => api.post(urlUser + '/admin', user),
    update: (user: any) => api.put(urlUser, user),
    delete: (user: any) => api.delete(urlUser, user),
    getUserLog: (body: IGetUserLog = {timestamp: Date.now()}) => api.post(urlUser + '/logs', body),
    getOpLog: (body: {operation_code: string}) => api.put(urlUser + '/logs', body),
    getNotifications: (body: IGetNotifications) => api.post(urlNotification + 's', body),
    changePassword: (body: IChangePasswordByAdmin) => api.put(urlUser + '/password', body),
    seen: (body: ISeenNotification) => api.put(urlNotification + '/seen', body),
    createNote: (body: ICreateNoteByUser) => api.post(urlUser + '/note', body),
    updateNote: (body: IUpdateNoteByUser) => api.put(urlUser + '/note', body),
    deleteNote: (body: IDeleteNoteByUser) => api.delete(urlUser + '/note', body),
}