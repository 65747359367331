import callApi, {api} from "../api/callApi";
import {urlDisease} from "../api/v1";
import {IDisease} from "../varibles/interface";


interface ICreateDisease {
    name: string
    description: string
}

interface IUpdateDisease {
    id: string
    name: string
    description: string
}

interface IDeleteDisease {
    id: string
}

export function deleteDiseaseFetch(params: IDeleteDisease) {
    return callApi(urlDisease, {
        method: 'DELETE',
        body: JSON.stringify(params)
    })
}

export const DiseaseService = {
    gets: () => api.get<IDisease[]>(urlDisease + '/s'),
    create: (body: ICreateDisease) => api.post<IDisease>(urlDisease, body),
    update: (body: IUpdateDisease) => api.put<IDisease>(urlDisease, body),
    delete: (id: string) => api.delete(urlDisease + "/" + id)
}