import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IOperation, IOperations, IPropsChildren} from "../../../../../util/varibles/interface";
import {TYPE_SUBMIT} from "../../../constants";
import {Button, notification} from "antd";
import {getKeyWeek} from "../../../../../util/varibles/global";
import styles from "../style.module.scss";
import {statusUnAllow} from "../../../util";
import Menu from "../../../../../components/Menu";
import {VALIDATION_STATUS} from "../../../util/validation";
import {planOpActions} from "../../../reducer";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import {AppState} from "../../../../../util/store/store";

const message = {
    invalid: (error: any) => `Can not send new / update operation to vessel. 
                            There ${error.length > 1 ? 'are' : 'is'} invalid operation on planning calendar, 
                            please correct first and try to send to vessel later.`,
    emptyByCurrentWeek: () => `There is no new / updated operation in current week to send to vessel.`,
    empty: () => `There is no new / updated operation to send to vessel.`
}

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id,
    loading: state.planOperation.isFetching.confirm,
    operations: state.planOperation.operations
});

interface IProps extends IPropsChildren {
    userTenantId: string
    operations: IOperations
    loading: boolean

    confirmOperation(payload: { operations: IOperation[], isSave?: boolean }): any
}

class SendPlans extends Component<IProps> {

    handleSubmit = (typeSubmit: TYPE_SUBMIT, operations: any) => {
        if (typeSubmit === TYPE_SUBMIT.CURRENT_WEEK) {
            const currentWeek = getKeyWeek(new Date());
            operations = operations.filter((item: any) => !item.operation.is_active_vessel
                && currentWeek === getKeyWeek(item.activity_log[0].est_start_time));
            if (operations.length === 0) {
                notification.warning({message: 'Warn', description: message.emptyByCurrentWeek(),});
                return;
            }
        }
        if (operations.length === 0)
            notification.warning({message: 'Warn', description: message.empty(),});
        else
            this.checkOperation(operations, () => this.props.confirmOperation({operations}))
    };

    handleSave = (operations: any) => {
        this.checkOperation(operations, () => this.props.confirmOperation({operations, isSave: true}))
    }

    checkOperation = (operations: IOperation[], action: any) => {
        const operationError = operations.filter(item => item.status === VALIDATION_STATUS.ERROR)
        if (operationError.length > 0) {
            notification.error({message: 'Error', description: message.invalid(operationError)});
        } else
            action();
    }

    render() {
        const {operations, loading, userTenantId} = this.props;
        const {change, save} = Object.keys(operations).reduce((list: any, key: string) => {
            if (operations[key].operation.tenant_id !== userTenantId)
                return list;

            if (statusUnAllow.has(operations[key].operation.status))
                return list;

            if (!!operations[key].action_type)
                list.change = [...list.change, operations[key]]
            else if (!operations[key].operation.is_active_vessel)
                list.save = [...list.save, operations[key]];
            return list
        }, {change: [], save: []});

        const data = [...change, ...save];

        const menu = [
            {
                key: 'week',
                label: <div
                    className='menu-line'
                    data-full={true}
                    onClick={() => this.handleSubmit(TYPE_SUBMIT.ALL, data)}
                >
                    Send all plans
                </div>
            },
            {
                key: 'all',
                label: <div
                    className='menu-line'
                    data-full={true}
                    onClick={() => this.handleSubmit(TYPE_SUBMIT.CURRENT_WEEK, data)}
                >
                    Send plans by current week
                </div>
            }
        ]

        return <>
            <Button
                className='bt-ghost h-32'
                ghost={true}
                onClick={() => this.handleSave(change)}
                disabled={change.length === 0}
                loading={loading}
            >
                <Icon icon={ICON_NAME.APPROVE}/>
                <span>
                    Save plan
                </span>
            </Button>
            {this.props.children}
            <div className={styles['divide']}/>
            <Menu items={menu} disabled={data.length === 0}>
                <Button className='bt-primary h-32' loading={loading}>
                    Send plan to vessels
                </Button>
            </Menu>
        </>;
    }
}

export default connect(mapStateToProps, {
    confirmOperation: planOpActions.confirmOpRequest,
})(SendPlans);
