import React, {useEffect, useState} from 'react';
import {KEY_STORAGE, THEME_COLOR} from "../../util/varibles/constants";
import {IPropsChildren} from "../../util/varibles/interface";
import {ThemeContext} from "./ThemeContext";
import './theme.scss';

interface IProps extends IPropsChildren {
}

const Theme: React.FC<IProps> = ({children}) => {
    const getDefaultTheme = (): string => localStorage.getItem(KEY_STORAGE.THEME) || THEME_COLOR.LIGHT;
    const [theme, setTheme] = useState(getDefaultTheme());

    useEffect(() => {
        document.body.className = `theme-${theme}`
    }, [theme]);

    return <ThemeContext.Provider value={{theme, setTheme}}>
        <div className='view'>
            {children}
        </div>
    </ThemeContext.Provider>;
};

export default Theme;
