import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE} from "../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {Button, Modal, Radio} from "antd";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Menu from "../../../../../components/Menu";
import {IWeather} from "../../../../../util/varibles/interface";
import {weatherActions} from "../../../reducer";
import {deleteWeatherFetch, updateWeatherFetch} from "../../../../../util/services/weather";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {showErrorResponse} from "../../../../../util/varibles/global";
import {getCondition} from "../../../constants";

interface IProps {
    data: IWeather;
    loading: boolean
    children: ReactNode

    setLoading(loading: boolean): void

    update(weather: IWeather): void

    remove(ids: string[]): void

    cancel(): void
}

const Title: React.FC<IProps> = (props) => {
    const {data, loading, children, setLoading, update, remove} = props;
    const {id, name = '', is_enable, actionType} = data || {};

    const handleDelete = () => {
        if (loading)
            return;
        Modal.confirm({
            title: 'Delete weather setting',
            icon: <ExclamationCircleOutlined/>,
            content: 'Are you sure delete this weather setting?',
            keyboard: true,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                setLoading(true);
                new Promise(resolve => resolve(deleteWeatherFetch(id)))
                    .then((rs: any) => {
                        setLoading(false);
                        remove([data, rs]);
                        notify.success(NotifyCode.S4)([name]);
                    })
                    .catch(error => {
                        setLoading(false);
                        showErrorResponse('Delete failed', error);
                    })
            },
        });
    }

    const menu = [
        {
            key: 'delete',
            label: <div className='menu-line' data-danger='true' onClick={handleDelete}>
                <Icon icon={ICON_NAME.DELETE} danger/>
                <span>Delete</span>
            </div>
        }
    ];

    const handleSave = () => {
        const weatherName = name.trim();
        if (weatherName.length === 0) {
            notify.error(NotifyCode.E32)()
            return;
        }
        const badEl: any = document.getElementById(id + '-bad');
        const moderateEl: any = document.getElementById(id + '-moderate');
        const goodEl: any = document.getElementById(id + '-good');
        if (badEl && moderateEl && goodEl) {
            const el = [badEl, moderateEl, goodEl];
            let conditions = [], isInvalid = false;
            for (let i = 0; i < 3; i++) {
                const {condition, error} = getCondition(el[i].childNodes)
                if (error) {
                    isInvalid = true;
                    break;
                } else
                    conditions.push(condition);
            }
            if (isInvalid) return;
            const [bad_condition, moderate_condition, good_condition] = conditions
            const weather = {
                ...data,
                bad_condition,
                moderate_condition,
                good_condition,
            }
            handleSend(weather)
        }
    }

    const handleSend = (weather: any) => {
        setLoading(true);
        new Promise(resolve => resolve(updateWeatherFetch(weather)))
            .then((rs: any) => {
                notify.success(NotifyCode.S2)([name]);
                update(rs)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Update  failed', error);
            })
    }

    const isEdit = actionType === ACTION_TYPE.CREATE || actionType === ACTION_TYPE.EDIT;

    return <div className={styles.wrapper}>
        <div className={styles.title}>
            {children}
        </div>
        <div className={styles.extra} data-is-edit={isEdit}>
            <Radio onClick={() => handleSend({...props.data, is_enable: true})} checked={is_enable}>Enable</Radio>
            {!isEdit && <Menu items={menu} placement="bottomRight">
                <div className={['three-dot', styles.btMenu].join(' ')}/>
            </Menu>}
            <div className={styles['action-save']}>
                <Button className='bt-default h-32' onClick={props.cancel}>Cancel</Button>
                <Button className='bt-primary h-32' loading={loading} onClick={handleSave}>
                    Save
                </Button>
            </div>
        </div>
    </div>;
};

export default connect(null, {
    update: weatherActions.update,
    remove: weatherActions.delete,
})(Title);
