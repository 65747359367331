import {ILocation} from "../../varibles/interface";
import {AppState} from "../store";

export const NAME_SLICE = 'location'

export interface LocationState {
    data: ILocation[]

    loading: boolean
}

export const initialState: LocationState = {
    data: [],

    loading: false,
}

// Location
export const selectLocation = {
    locations: (state: AppState) => state[NAME_SLICE].data,
    loading: (state: AppState) => state[NAME_SLICE].loading
}