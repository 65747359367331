import React from 'react';
import {IElement} from "../../constants";
import styles from './style.module.scss';
import {ELEMENT_BASIC, ELEMENT_DEFINED} from "../../Element/constants";
import HeaderElement from "./HeaderElement";
import ShortTextElement from "./ShortTextElement";
import LongTextElement from "./LongTextElement";
import NumberElement from "./NumberElement";
import DropdownElement from "./DropdownElement";
import RadioElement from "./RadioElement";
import CheckboxElement from "./CheckboxElement";
import DateElement from "./DateElement";
import TimeElement from "./TimeElement";
import SignElement from "./SignElement";
import Footer from "../../../../components/Popup/Component/Footer";

interface IProps {
    selected: any

    rightOpen: boolean

    setRightOpen(open: boolean): void

    onAdd(element: IElement, index: number | null): void

    onUpdate(element: IElement, index: number): void

    onDelete(index: number): void
}

const RightSide: React.FC<IProps> = (props) => {
    const {selected, rightOpen, setRightOpen, onUpdate} = props;
    const renderPropertyElement = () => {
        if (selected) {
            let props = {
                selected,
                onUpdate
            }
            switch (selected.type) {
                case ELEMENT_BASIC.HEADER: {
                    return <HeaderElement {...props}/>
                }
                case ELEMENT_BASIC.SHORT_TEXT: {
                    return <ShortTextElement {...props}/>
                }
                case ELEMENT_BASIC.LONG_TEXT: {
                    return <LongTextElement {...props}/>
                }
                case ELEMENT_BASIC.NUMBER: {
                    return <NumberElement {...props}/>
                }
                case ELEMENT_BASIC.DROPDOWN: {
                    return <DropdownElement {...props}/>
                }
                case ELEMENT_BASIC.RADIO: {
                    return <RadioElement {...props}/>
                }
                case ELEMENT_BASIC.CHECKBOX: {
                    return <CheckboxElement {...props}/>
                }
                case ELEMENT_BASIC.DATE: {
                    return <DateElement {...props}/>
                }
                case ELEMENT_BASIC.TIME: {
                    return <TimeElement {...props}/>
                }
                case ELEMENT_DEFINED.SIGN: {
                    return <SignElement {...props} />
                }
                default:
                    return null
            }
        } else
            return null
    }

    const duplicateElement = () => {
        if (selected) {
            const {index, ...args} = selected;
            props.onAdd(args, index);
        }
    }

    return <div className={styles['right-side']} data-is-show={rightOpen}>
        <div className={styles['bt-toggle']} onClick={() => setRightOpen(!rightOpen)}/>
        <div className={styles['container']}>
            {renderPropertyElement()}
            {selected && <Footer className='justify-content-start'>
                <div className='bt-danger' onClick={() => props.onDelete(selected.index)}>
                    Delete field
                </div>
                {!Object.values(ELEMENT_DEFINED).includes(selected.type) &&
                    <div className='bt-primary' onClick={duplicateElement}>
                        Duplicate field
                    </div>}
            </Footer>}
        </div>
    </div>;
};

export default RightSide;
