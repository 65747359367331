import React from 'react';
import {IUnit} from "../../util/varibles/interface";
import Icon, {ICON_NAME} from "../Icon";
import styles from './style.module.scss';

interface IProps {
    className?: string
    unit: IUnit
}

const Round: React.FC<IProps> = ({className, unit}) => {
    return <div className={[styles.lineInfo, className].join(' ')}>
        <Icon className='ml-[1px]' icon={ICON_NAME.ROUND}/>
        <div>Round: {unit.round_number}</div>
    </div>;
};

export default Round;
