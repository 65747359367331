import React from 'react';
import {listOfStep, SHORT_MONTH_DATETIME_FORMAT} from "../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {datetime} from "../../../../../util/library/datetime";

interface IProps {
    data: any
    isDetail: boolean
}

const Schedule: React.FC<IProps> = ({data, isDetail}) => {
    return <div className={styles['info']} data-show={isDetail}>
        {data.map((item: any, index: number) => {
            const {time, state, key} = item;
            const {name = ''} = listOfStep[key] || {};

            return <div key={index} className={styles['time-line']} data-state={state}>
                <div>{name}:</div>
                <div>{datetime(time).format(SHORT_MONTH_DATETIME_FORMAT)}</div>
            </div>
        })}
    </div>
};

export default Schedule;