import React from 'react';
import TankDensities from "../../../../../../../components/Operation/TankDensities";
import {OPERATION_TYPE} from "../../../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {TOperation} from "../../../../../../../util/varibles/interface";
import Certain from "../../../_Component/Certain";
import AddNote from "../../../_Component/AddNote";

interface IProps {
    indexHarvest: number
    harvest: TOperation

    onUpdate(value: Partial<TOperation>): void
}

const OpName: React.FC<IProps> = ({indexHarvest, harvest, onUpdate}) => {
    return <div className={styles.opName}>
        <div data-lev='title'>
            <div>Trip {indexHarvest + 1}</div>
            <TankDensities
                isEmpty
                className={styles.tanks}
                type={OPERATION_TYPE.HARVEST}
                storages={harvest.sub_operations[0].storages}
            />
        </div>
        <div data-lev='action'>
            <AddNote
                title={harvest.operation_code.length > 0 ? `#${harvest.operation_code}` : `Trip ${indexHarvest + 1}`}
                value={harvest.note}
                onChange={note => onUpdate({note})}
            />
            <Certain
                value={harvest.certain_mode}
                onChange={certain_mode => onUpdate({certain_mode})}
            />
        </div>
    </div>;
};

export default OpName;
