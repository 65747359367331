import React, {useState} from 'react';
import {DatePicker, Form, Modal, notification, Radio} from "antd";
import {layout} from "./constants";
import styles from './style.module.scss';
import {
    CONTRACT_PERMISSION_TYPE,
    CONTRACT_TYPE,
    contractPermissionType,
    FULL_DATE_FORMAT,
    propContainer,
    propsModal
} from "../../../../util/varibles/constants";
import Header from "../../../../components/Popup/Component/Header";
import {FormInstance} from "antd/lib/form";
import {datetime} from "../../../../util/library/datetime";
import Footer from "../../../../components/Popup/Component/Footer";
import {showErrorResponse} from "../../../../util/varibles/global";
import {sendRequestSingleOperationFetch} from "../../../../util/services/spot";
import {template, TemplateCode} from "../../../../util/varibles/template";
import {IVessel} from "../../../../util/varibles/interface";

interface IProps {
    selectedRowKeys: string[],
    spotVessels: IVessel[],
    contractType: CONTRACT_TYPE

    onOk(): void

    onClose?(): void
}

const PopupRequest: React.FC<IProps> = (props) => {
    const formRef = React.createRef<FormInstance>();
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const {onOk, onClose, selectedRowKeys, spotVessels} = props;
    const vessels = selectedRowKeys.map(item => spotVessels.find(vessel => vessel.id === item)?.name);

    const handleClose = () => {
        setVisible(false);
        if (onClose)
            onClose();
    }

    const handleSubmit = (values: any) => {
        const {dueTime, permission} = values;
        const dataTime: any = {
            due_time: datetime(dueTime).startOf('day').time,
        }

        const data = selectedRowKeys.map(item => ({...dataTime, vessel_id: item, permission}));
        handleSend(sendRequestSingleOperationFetch, data)
    }

    const handleSend = (fetch: any, data: any) => {
        setLoading(true);
        new Promise(resolve => resolve(fetch(data)))
            .then((rs: any) => {
                const {new_contract_vessels, exist_contract_vessels} = rs;
                onOk();
                handleClose();
                setLoading(false);
                notification.success({
                    message: 'Sent successful',
                    description: template[TemplateCode.T3](new_contract_vessels, exist_contract_vessels),
                });
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Send request failed', error)
            })
    }

    return <Modal {...{
        ...propsModal,
        title: <Header
            title={'Request contract'}
            onClose={handleClose}
        />,
        open: visible,
        centered: true,
        width: '450px',
        className: styles['popup-fixed-contract'],
        onCancel: handleClose
    }}>
        <div className='mb-12'>From <span className='font-w5'>{vessels.join(', ')}</span></div>
        <Form {...{
            ...layout,
            ref: formRef,
            name: 'single-contract',
            layout: 'vertical',
            initialValues: {
                dueTime: datetime().set({month: datetime().month + 1}).value,
                permission: CONTRACT_PERMISSION_TYPE.BASIC
            },
            onFinish: handleSubmit
        }}>
            <Form.Item
                className='h-36 mb-10 ml-10'
                name="permission"
            >
                <Radio.Group
                    options={Object.keys(contractPermissionType).map(key => ({
                        value: key,
                        label: contractPermissionType[key].name
                    }))}
                />
            </Form.Item>
            <Form.Item
                label="Request’s Due time"
                name="dueTime"
                className='mb-10'
            >
                <DatePicker
                    {...propContainer}
                    className='h-36'
                    allowClear={false}
                    format={FULL_DATE_FORMAT}
                    placeholder='Request’s Due time'
                />
            </Form.Item>
            <span className='text-comment'>
                If vessel owner did not respond before due date, the request will be expired
            </span>

            <Footer
                ok={{loading, text: 'Send request'}}
                cancel={{click: handleClose}}
            />
        </Form>
    </Modal>;
};

export default PopupRequest;
