import React from 'react';
import {diseasedZones} from "../../../../../util/varibles/constants";
import DiseasedZone from "./DiseasedZone";

interface IProps {
}

const DiseasedZones: React.FC<IProps> = () => {

    return <div className='d-flex flex-column'>
        {diseasedZones.map((item) => <DiseasedZone key={item.id} data={item}/>)}
    </div>;
};

export default DiseasedZones;
