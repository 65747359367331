import React from 'react';
import {IWidget, WIDGET_TYPE} from "../../constants";
import LineChart from "./Types/LineChart";
import Gauge from "./Types/Gauge";
import Tank from "./Types/Tank";
import MenuWidget from "./Menu";
import styles from './style.module.scss';
import {chartUtil} from "../../../../util/library/chart-util";

interface IProps {
    sensorsData: any;
    currentData: any;
    width: number
    index: any
    widget: IWidget
}

const Widget: React.FC<IProps> = React.memo((props) => {
    const {width, widget, index, sensorsData, currentData} = props;

    const renderChart = () => {
        const {widgetType} = widget;
        const common = {...widget, width};
        switch (widgetType) {
            case WIDGET_TYPE.LINE: {
                const {keys = [], yAxis = []}: any = widget;
                const data = keys.reduce((rs: any, {device_id, device_name, sensor, yAxis: yAxisData}: any) => {
                    const key = device_id + '/' + sensor;
                    if (!sensorsData[key]) return rs;
                    const yAxisIndex = yAxisData >= 0 ? yAxisData : 0
                    const valueSuffix = yAxis[yAxisIndex] ? ' ' + yAxis[yAxisIndex].format : '';
                    return [...rs, {
                        name: device_name + ', ' + sensor,
                        data: sensorsData[key],
                        showSymbol: false,
                        type: 'line',
                        smooth: widget.smooth,
                        yAxisIndex,
                        ...chartUtil.addUnit(valueSuffix),
                    }]
                }, [])
                return <LineChart {...{...common, data, currentData}}/>;
            }
            case WIDGET_TYPE.GAUGE: {
                const {devices = [], sensors = []} = widget;
                const {unit} = sensors[0];
                const keyData = devices[0].device_id + '/' + sensors[0].name;
                const data = currentData[keyData] ? [{data: [Math.round(currentData[keyData].value * 10) / 10]}] : []
                return <Gauge {...{...common, data, unit}}/>
            }
            case WIDGET_TYPE.TANK: {
                return <Tank {...common}/>
            }
        }
    };

    const {widgetType} = widget;
    const style = widgetType === WIDGET_TYPE.LINE ? {width: '50%'} : {width};
    return <div className={styles['chart-item']} style={style}>
        <div className={styles['chart-title']}>
            <span>{widget.name}</span>
            <MenuWidget widget={widget} index={index}/>
        </div>
        <div className={styles['chart-body']}>
            {renderChart()}
        </div>
    </div>
}, (prev, next) =>
    JSON.stringify(prev.currentData) === JSON.stringify(next.currentData)
    && JSON.stringify(prev.widget) === JSON.stringify(next.widget)
    && JSON.stringify(prev.width) === JSON.stringify(next.width)
    && JSON.stringify(prev.sensorsData) === JSON.stringify(next.sensorsData));

export default Widget;


