import React, {useEffect, useState} from 'react';
import styles from "./style.module.scss";
import Icon, {ICON_NAME} from "../../Icon";
import {Badge} from "antd";
import PopupNotification from "./PopupNotification";
import {INotification} from "../../../util/varibles/interface";
import {NotificationType, PATHNAME, VIEW_SMOOTH} from "../../../util/varibles/constants";
import {getElById, showErrorResponse} from "../../../util/varibles/global";
import {UserService} from "../../../util/services/user";
import {openPage} from "../../../util/library/Router";
import {openPopup} from "../../Popup/Component/WrapperPopup";
import PopupVessels from "../../../pages/PlanOperationPage/Popup/Vessels";
import WSService from "../../../web-socket/web-socket-service";
import {SOCKET_FUNC, SOCKET_LEV} from "../../../web-socket/constants";
import {useSelector} from "react-redux";
import {selectTenantId} from "../../../util/store/selectors";

let socket: WSService | undefined;

interface IProps {
}

const Notification: React.FC<IProps> = () => {
    const [open, setOpen] = useState(false);
    const [{
        source,
        more,
        count_unread
    }, setSource] = useState<{ source: INotification[], more: boolean, count_unread: number }>({
        source: [],
        more: false,
        count_unread: 0
    });
    const [loading, setLoading] = useState(false);
    const userTenantId = useSelector(selectTenantId);

    useEffect(() => {
        loadData(undefined, () => {
            socket = new WSService({
                listener: {
                    lev: SOCKET_LEV.POPUP,
                    func: (value) => {
                        const {message} = value;
                        if (value.function !== SOCKET_FUNC.NOTIFICATIONS || !Array.isArray(message))
                            return;
                        const ids = new Set(message.map(item => item.id));
                        setSource(prev => {
                            let count = prev.count_unread;
                            const renew = prev.source.filter(item => {
                                if (ids.has(item.id)) {
                                    if (item.read)
                                        count++;
                                    return false
                                } else
                                    return true
                            });
                            return {
                                ...prev,
                                source: [...value.message, ...renew,],
                                count_unread: count
                            }
                        })
                    }
                }
            });
        });
        return () => {
            if (socket)
                socket.removeListener(SOCKET_LEV.POPUP)
        };
    }, []);

    const handleClick = (data: INotification) => {
        const {id, read} = data;
        if (!read)
            UserService.seen({id})
                .then(() => {
                    setSource(prev => ({
                        ...prev,
                        count_unread: prev.count_unread - 1,
                        source: prev.source.map(item => item.id === id ? {...item, read: true} : item)
                    }))
                })
                .catch(error => {
                    console.log(error)
                    showErrorResponse('Update status notification failed', error);
                })
        switch (data.notificationType) {
            case NotificationType.Operation: {
                const {id: operationId, tenant_id} = data.detail || {};
                const el = getElById(operationId);
                if (el) {
                    el.scrollIntoView(VIEW_SMOOTH);
                } else {
                    openPage(`${PATHNAME.OPERATION}/${tenant_id}/${operationId}`);
                }
                break
            }
            case NotificationType.Contract: {
                const {id: contractId, tenant_id} = data.detail || {};
                if (userTenantId === tenant_id) {
                    const popupEl = openPopup(<PopupVessels contractId={contractId} onClose={() => popupEl.remove()}/>)
                } else {
                    openPage(`${PATHNAME.VESSEL}?contract_id=${contractId}`);
                }
                break;
            }
            default:
                return null
        }
    }

    const loadData = (id?: string, callback?: Function) => {
        const params = id ? {id} : {};
        setLoading(true);
        UserService.getNotifications(params)
            .then((rs: any) => {
                const {is_prev = false, notifications = []} = rs || {};
                setSource(prev => ({
                    count_unread: rs?.count_unread || 0,
                    more: is_prev,
                    source: [...prev.source, ...notifications]
                }));
                if (callback)
                    callback();
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
                // showErrorResponse('Get notifications failed', error);
                setLoading(false)
            })
    }

    return <>
        <div className={styles.wrapper}>
            <Badge count={count_unread} title='Notification'>
                <Icon icon={ICON_NAME.BELL} size='large'/>
            </Badge>
            <div className={styles.button} onClick={() => setOpen(!open)}/>
        </div>
        <PopupNotification
            source={source}
            more={more}
            loading={loading}
            open={open}
            loadData={loadData}
            onClose={() => setOpen(false)}
            onClick={handleClick}
        />
    </>;
};

export default Notification;
