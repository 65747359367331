import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "antd";
import styles from '../style.module.scss';
import LoadingBox from "../../../../components/LoadingBox";
import {getProdAreaName} from "../../../../util/varibles/global";
import {DashboardSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {selectDashboard, selectOneSetting} from "../../../../util/store/selectors";


const Content: React.FC<{ filter: string[], onChange(data: string[]): void }> = ({filter, onChange}) => {
    const prodAreas = useSelector(selectDashboard.prodAreas) || [];
    const loading = useSelector(selectDashboard.loadingProdAreas);
    const sites = useSelector(selectDashboard.sites);

    const productionIds = useMemo(() => {
        const ids = new Set(sites.reduce((list: string[], item: any) => {
            const {diseases = []} = item || {}
            const {production_area = {}} = diseases[0] || {};
            const id = item.prod_area || production_area.id;
            return [...list, `${id}`];
        }, []));
        ids.delete('undefined');
        return ids;
    }, [sites]);

    if (loading || prodAreas.length === 0)
        return <LoadingBox loading={loading} message="No data"/>

    return <Checkbox.Group
        className={[styles.checkbox, 'checkbox-fill'].join(' ')}
        value={filter}
        onChange={onChange}
    >
        {prodAreas.reduce((list: any, item) => productionIds.has(`${item.id}`)
            ? [...list, <Checkbox key={item.id} value={item.id.toString()}>{getProdAreaName(item)}</Checkbox>]
            : list, [])}
    </Checkbox.Group>
}

const ProductionAreas: React.FC = () => {
    const filter = useSelector(selectOneSetting(DashboardSetting.PRODUCT_AREAS));
    const dispatch = useDispatch();

    const onChange = useCallback((data: string[]) => {
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.PRODUCT_AREAS]: data}]));
    }, [dispatch]);

    return <div className='pb-12'>
        <Content filter={filter} onChange={onChange}/>
        {filter.length > 0 && <div className='link mt-10 ml-5' onClick={() => onChange([])}>Clear</div>}
    </div>;
};

export default ProductionAreas;
