import React, {Component} from 'react';
import styles from './style.module.scss';
import {TOperation} from "../../util/varibles/interface";

interface IProps {
    visible?: boolean
    operation: TOperation
}

class Note extends Component<IProps> {
    static defaultProps = {
        visible: true
    }

    render() {
        const {visible} = this.props;
        const {note = ''} = this.props.operation;

        if (!visible || note.length === 0) return null;

        return <div className={styles.note}/>
    }
}

export default Note;
