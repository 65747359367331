import React from 'react';
import styles from "./style.module.scss";
import {Table} from "antd";
import {formatNumber} from "../../../util/varibles/global";
import {IUnit} from "../../../util/varibles/interface";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEditUnit from "./PopupEditUnit";

interface IProps {
    siteId: string
    units: IUnit[]

    onUpdate(data: { id: string, units: IUnit[] }): void
}

const Units: React.FC<IProps> = (props) => {
    const {siteId, units} = props;
    const columnsUnit = [
        {
            title: 'No',
            key: 'no',
            className: 'text-center',
            width: 40,
            render: (value: any, item: any, index: number) => index + 1,
        },
        {
            title: 'Unit',
            dataIndex: 'unit_id',
            key: 'id'
        },
        {
            title: 'Mortality risk',
            dataIndex: 'mortality_risk',
            key: 'mortality_risk',
            render: (mortality_risk: boolean) => mortality_risk ? 'High' : 'Low'
        },
        {
            title: 'Count',
            dataIndex: 'fish_amount',
            key: 'fish_amount',
            render: (fish_amount: number) => fish_amount ? formatNumber(fish_amount) : '-'
        },
        {
            title: 'Avg. weight',
            dataIndex: 'avg_weight',
            key: 'avg_weight',
            render: (avg_weight: number) => avg_weight ? formatNumber(avg_weight / 1000) + ' kg' : '-'
        },
        {
            width: 60,
            render: (unit: any) =>
                <div className={styles['action-table-child']}>
                    <div
                        className={styles['container-action']}
                        onClick={() => openEdit(unit)}
                    >
                        <div className={styles['icon-edit']}/>
                    </div>
                    <div
                        className={styles['container-action']}
                        data-is-delete={'true'}
                        onClick={() => handleDelete(unit.id)}
                    >
                        <div className={styles['icon-delete']}/>
                    </div>
                </div>,
        }
    ]

    const openEdit = (unit: IUnit) => {
        const div = openPopup(<PopupEditUnit
            visible={true}
            data={unit}
            onSave={handleUpdate}
            onClose={() => div.remove()}
        />)
    }

    const handleUpdate = (unit: IUnit) => {
        const {id: unitId} = unit;
        props.onUpdate({id: siteId, units: units.map(item => item.id === unitId ? {...item, ...unit} : item)});
    }

    const handleDelete = (unitId: string) => {
        props.onUpdate({id: siteId, units: units.filter(item => item.id !== unitId)});
    }

    return <div className={styles['container-units']}>
        <p>Units</p>
        <Table
            bordered
            pagination={false}
            columns={columnsUnit}
            dataSource={units}
        />
    </div>;
};

export default Units;