import React, {useEffect} from 'react';
import {Col, Row} from "antd";
import {ACTION_TYPE, VESSEL_TYPE, vesselType, vesselTypes} from "../../../../util/varibles/constants";
import styles from '../style.module.scss';
import VesselInput from "./VesselInput";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import VesselType from "./VesselType";
import {IVessel} from "../../../../util/varibles/interface";
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import VesselImage from "./VesselImage";
import VesselCapacity from "./VesselCapacity";
import {renderOpts} from "../../../../util/varibles/global";
import Storages from "./Storages";
import CleaningTime from "./CleaningTime";
import AvailableTimeType from "./AvailableTimeType";
import Equipments from './Equipments';
import TankTreatments from "./TankTreatments";
import VesselSubmit from "./VesselSubmit";
import ServiceCapabilities from "./ServiceCapabilities";
import CombineDensity from "./CombineDensity";

interface IProps {
    vessel?: IVessel
    editMode: ACTION_TYPE

    onSave(vessel: IVessel): void

    onClose(): void
}

const VesselForm: React.FC<IProps> = ({editMode, vessel, onSave, onClose}) => {
    const [type, setStore] = useEditVessel(state => state.vessel?.type);

    useEffect(() => {
        setStore({vessel});
    }, [vessel, setStore]);

    return <div className={styles['select-vessel']}>
        {type === undefined ? <VesselType/> : <div>
            <Row gutter={[32, 16]}>
                <Col span={8}>
                    {editMode === ACTION_TYPE.CREATE && <div
                        className='flex gap-2 items-center cursor-pointer mb-3'
                        onClick={() => setStore(({vessel: old}) => ({vessel: {...old, type: undefined}}))}
                    >
                        <Icon icon={ICON_NAME.ARROW_LEFT} style={{height: '26px', width: '28px'}}/>
                        <span className='text-[15px]'>Back</span>
                    </div>}
                    <VesselImage/>
                </Col>
                <Col span={16}>
                    <div className="text-[18px] font-[600] mt-1  mb-3">{vesselType[type || '']?.name}</div>
                    <div className="text-[15px] font-[600] mb-2">Vessel info.</div>
                    <VesselInput id='name'/>
                    {editMode === ACTION_TYPE.EDIT &&
                        <VesselInput id='type' options={renderOpts(vesselTypes)}/>}
                    <VesselInput id='contact'/>
                    <div className='grid grid-cols-2 gap-5'>
                        <VesselInput id='length'/>
                        <VesselInput id='width'/>
                    </div>
                    <VesselInput id='speed'/>
                    {type !== VESSEL_TYPE.SERVICE &&
                        <VesselCapacity/>}
                    {type !== VESSEL_TYPE.SERVICE && <div className='grid grid-cols-2 gap-x-5'>
                        {type === VESSEL_TYPE.WELL_BOAT ? <>
                            <VesselInput id='harvest_capacity' className='col-span-2'/>
                            <VesselInput id='factory_discharge'/>
                            <VesselInput id='waiting_unit_capacity'/>
                        </> : <>
                            <VesselInput id='harvest_capacity'/>
                            <VesselInput id='factory_discharge'/>
                        </>}
                    </div>}
                    <CleaningTime/>
                    <VesselInput id='mmsi'/>
                    <AvailableTimeType/>
                    {type === VESSEL_TYPE.WELL_BOAT && <div className='text-[15px] font-[600] my-3'>
                        Add tank equipment and select the corresponding capabilities
                    </div>}
                    {type !== VESSEL_TYPE.SERVICE && <Storages/>}
                    {type === VESSEL_TYPE.WELL_BOAT && <TankTreatments/>}
                    {type === VESSEL_TYPE.SERVICE && <ServiceCapabilities/>}
                    {type !== VESSEL_TYPE.PROCESS_VESSEL && <Equipments vesselType={type}/>}
                    {type === VESSEL_TYPE.WELL_BOAT && <CombineDensity/>}
                </Col>
            </Row>
            <VesselSubmit editMode={editMode} onSave={onSave} onClose={onClose}/>
        </div>}
    </div>;
};

export default VesselForm;
