import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    FULL_DATE_FORMAT,
    OP_STATUS,
    OPERATION_CONFIRM_STATUS,
    propContainer,
    propsConfirm,
    propsModal,
    SHORT_DATETIME_FORMAT
} from "../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {Button, DatePicker, Modal, notification, Popconfirm, Select, Space, Table} from "antd";
import {OpService} from "../../../../util/services/operation";
import stylesOp from "../../Plan/Calendar/Operations/Operation/style.module.scss";
import {filterOption, renderOpts, showErrorResponse, uppercaseFirstLetter} from "../../../../util/varibles/global";
import {IOperation} from "../../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import Header from "../../../../components/Popup/Component/Header";
import {datetime} from "../../../../util/library/datetime";
import {planOpActions} from "../../reducer";
import Id from "../../../../components/Operation/Id";
import Logo from "../../../../components/Operation/Logo";
import {selectPlan, selectTenantId} from "../../../../util/store/selectors";

export enum DELETE_TYPE {
    DELETE = 'delete',
    ROLLBACK = 'rollback',
    DELETE_NOT_SAVE = 'deleteNotSave'
}

const column = [
    {
        title: 'OPERATION CODE',
        key: 'code',
        width: '140px',
        render: (data: any) => {
            const {operation} = data;
            const {operation_type, status, event_type} = operation
            return <div
                className={[styles['operation-code'], stylesOp['layout']].join(' ')}
                data-status={status}
                data-operation-type={operation_type}
            >
                <Logo status={status} type={operation_type} dataAttribute={{'data-event-type': event_type}}/>
                <Id {...{visible: true, operation, status}}/>
            </div>
        }
    },
    {
        title: 'TIME',
        key: 'time',
        render: (data: any) => {
            const {activity_log = []} = data
            const {est_start_time: start = null} = activity_log[0] || {};
            const {est_start_time: finish = null} = activity_log[activity_log.length - 1] || {};
            return (start && finish)
                ? (datetime(start).format(SHORT_DATETIME_FORMAT)) + ' - ' + (datetime(finish).format(SHORT_DATETIME_FORMAT))
                : '-'
        }
    },
    {
        title: 'VESSEL',
        key: 'vessel',
        render: (data: any) => {
            const {name = ''} = data.vessel || {};
            return name
        }
    },
    {
        title: 'STATUS',
        width: '125px',
        key: 'status',
        render: (data: any) => {
            const {action_type} = data;
            let type, text;
            if (action_type) {
                type = action_type === OPERATION_CONFIRM_STATUS.NEW ? 'new' : 'edited'
                text = uppercaseFirstLetter(type)
            } else {
                const {status} = data.operation;
                if (status === OP_STATUS.SAVED) {
                    type = 'saved'
                    text = 'Saved'
                } else {
                    type = 'sent'
                    text = 'Sent to vessel'
                }
            }
            return <div className={styles['status']} data-status={type}>
                {text}
            </div>
        }
    },
    {
        title: 'ACTION',
        key: 'action',
        render: (data: any) => {
            const {action} = data;
            return (action === DELETE_TYPE.DELETE) ? 'Delete' : 'Rollback'
        }
    },
]

interface IProps {
    visible?: boolean
    vesselIds: string[]

    onClose(): void
}

let controllerRequest = new AbortController();

const PopupClear: React.FC<IProps> = (props) => {
    const {visible = true} = props;
    const currentOps = useSelector(selectPlan.ops);
    const vesselsRoot = useSelector(selectPlan.vessels);
    const userTenantId = useSelector(selectTenantId);
    const [open, setOpen] = useState(visible);
    const [loading, setLoading] = useState(false);
    const [loadingOps, setLoadingOps] = useState(false);
    const [operations, setOperations] = useState<IOperation[]>([]);
    const [vessels, setVessels] = useState([]);
    const [start, setStart] = useState(datetime().value);
    const [finish, setFinish] = useState(datetime().add(1, 'day').value);
    const dispatch = useDispatch();

    const handleDelete = () => {
        if (vessels.length === 0) {
            notification.error({message: 'Please choose a vessel'});
            return;
        }

        if (!start || !finish)
            return;

        const startTime = datetime(start).startOf('day').time;
        const finishTime = datetime(finish).endOf('day').time;
        if (finishTime < startTime) {
            notify.error(NotifyCode.E8)();
            return;
        }

        const listOfVessel: any = new Set(vessels);

        const {listOfNew, listOfOld} = Object.keys(currentOps).reduce((list: any, key) => {
            const {action_type, activity_log} = currentOps[key];
            const {tenant_id: opTenantId, status} = currentOps[key].operation;
            if (userTenantId !== opTenantId)
                return list;

            const {id: vessel_id = ''} = currentOps[key].vessel;
            if (!listOfVessel.has(vessel_id))
                return list

            if (status === OP_STATUS.PROCESSING)
                return list

            const opStart = activity_log[0].est_start_time;
            const opFinish = activity_log[activity_log.length - 1].est_start_time;
            if (!(startTime <= opStart && opFinish <= finishTime))
                return list

            if (action_type === OPERATION_CONFIRM_STATUS.NEW) {
                list.listOfNew.push(key)
            } else if (action_type === OPERATION_CONFIRM_STATUS.UPDATE) {
                list.listOfOld.push(key)
            }
            return list;
        }, {listOfNew: [], listOfOld: []});

        const params = {
            operation_ids: listOfOld,
            vessels: vessels.map((id: string) => {
                const {tenant_id = ''} = vesselsRoot.find(item => item.id === id) || {}
                return {
                    vessel_id: id,
                    vessel_owner_id: tenant_id
                }
            }),
            start_time: startTime,
            finish_time: finishTime,
        }

        setLoading(true);
        OpService.getOpsForDelete(params)
            .then((rs: any = []) => {
                setLoading(false);
                notify.success(NotifyCode.S5)();
                const {editing_operations = [], deleted_operations} = rs;
                const listOfUpdate: any = [];
                const listOfDelete = [
                    ...listOfNew,
                    ...deleted_operations,
                    ...listOfOld.reduce((list: any, key: any) => {
                        const operation = editing_operations.find((item: any) => item.operation.id === key)
                        if (operation) {
                            listOfUpdate.push(operation);
                            return list;
                        }

                        return [...list, key]
                    }, [])
                ]
                dispatch(planOpActions.clearOps({listOfDelete, listOfUpdate}))
            })
            .catch((error) => {
                console.log(error)
                showErrorResponse('Delete failed', error);
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!start || !finish || !vessels) {
            return;
        }

        const startTime = datetime(start).startOf('day').time;
        const finishTime = datetime(finish).endOf('day').time;
        const params = {
            vessel_ids: vessels,
            start_time: startTime,
            finish_time: finishTime
        }

        controllerRequest.abort();
        controllerRequest = new AbortController();
        setLoadingOps(true);
        OpService.clearOps(params, controllerRequest)
            .then((rs: any) => {
                const listOfDeleteRoot = new Set(rs);
                const listOfVessel: any = new Set(vessels);
                setLoadingOps(false);
                const listOfOperation = Object.keys(currentOps).reduce((list: IOperation[], key) => {
                    const {action_type, activity_log} = currentOps[key];
                    const {tenant_id: opTenantId, status} = currentOps[key].operation;
                    if (userTenantId !== opTenantId)
                        return list;

                    const {id: vessel_id = ''} = currentOps[key].vessel;
                    if (!listOfVessel.has(vessel_id))
                        return list

                    if (status === OP_STATUS.PROCESSING)
                        return list

                    const opStart = activity_log[0].est_start_time;
                    const opFinish = activity_log[activity_log.length - 1].est_start_time;
                    if (!(startTime <= opStart && opFinish <= finishTime))
                        return list

                    if (action_type === OPERATION_CONFIRM_STATUS.NEW) {
                        list.push({...currentOps[key], action: DELETE_TYPE.DELETE})
                    } else {
                        const isDelete = listOfDeleteRoot.has(key);
                        if (isDelete) {
                            listOfDeleteRoot.delete(key);
                            list.push({...currentOps[key], action: DELETE_TYPE.DELETE})
                        } else {
                            list.push({...currentOps[key], action: DELETE_TYPE.ROLLBACK})
                        }
                    }
                    return list;
                }, []);

                if (listOfDeleteRoot.size > 0) {
                    listOfDeleteRoot.forEach((key: any) => {
                        if (currentOps[key])
                            listOfOperation.push({...currentOps[key], action: DELETE_TYPE.DELETE})
                    })
                }
                setOperations(listOfOperation);
            })
            .catch(() => {
                setLoadingOps(false);
            })
        return () => {
            controllerRequest.abort();
        };
    }, [start, finish, vessels, currentOps, userTenantId]);

    const handleChangeStart = (value: any) => {
        setStart(value);
        const duration = datetime(value).diff(datetime(finish).time);
        if (duration > 0) {
            setFinish(datetime(value).add(1, 'day').endOf('day').value);
        }
    }

    const handleChangeFinish = (value: any) => {
        setFinish(value);
        const duration = datetime(start).diff(datetime(value).time);
        if (duration > 0) {
            setStart(datetime(value).subtract(1, 'day').startOf('day').value);
        }
    }

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    return <Modal {...{
        ...propsModal,
        open: open,
        title: <Header title='Remove operations' onClose={handleClose}/>,
        width: 720,
        wrapClassName: styles.popup,
        onCancel: handleClose,
    }}>
        <span className='text-comment'>All new, saved, waiting operation will be removed.</span>
        <div className={styles['container-import']}>
            <div className={styles.label}>
                <div>From time</div>
                <div>To time</div>
            </div>
            <Space.Compact className={styles['container-time']}>
                <DatePicker {...{
                    ...propContainer,
                    allowClear: false,
                    format: FULL_DATE_FORMAT,
                    placeholder: 'From time',
                    value: start,
                    onChange: handleChangeStart,
                }} />
                <DatePicker {...{
                    ...propContainer,
                    allowClear: false,
                    format: FULL_DATE_FORMAT,
                    placeholder: 'To time',
                    value: finish,
                    onChange: handleChangeFinish
                }} />
            </Space.Compact>
        </div>
        <div className={styles['container-import']}>
            <div className={styles.label}>
                <div>From vessels</div>
            </div>
            <Select
                mode='multiple'
                value={vessels}
                className='select-mode-tag w-full'
                onChange={(value: any) => setVessels(value)}
                filterOption={filterOption}
                options={renderOpts(vesselsRoot)}
            />
        </div>
        <div className={styles['container-operations']}>
            <div className={styles.operations}>
                <div>Operations ({operations.length})</div>
            </div>
            <div className={styles.operations}>
                <Table
                    rowKey={(obj: IOperation) => obj.operation.id}
                    bordered
                    loading={loadingOps}
                    dataSource={operations}
                    columns={column}
                    pagination={false}
                />
                {operations.length > 0 &&
                    <span className='font-italic text-comment'>
                        Note: There will be cases where the operation that was changed but has not been saved yet. It will be roll back to previous state.
                    </span>}
            </div>
        </div>
        <div className={styles.footer}>
            <Button className='bt-default' onClick={handleClose}> Cancel </Button>
            <Popconfirm
                {...propsConfirm}
                disabled={!start || !finish || vessels.length === 0 || loadingOps || operations.length === 0}
                title={
                    <div>
                        <div>
                            <span>Are you sure you want to delete all operations from </span>
                            <span className='font-w4'>{datetime(start).format(FULL_DATE_FORMAT)}</span>
                            <span> to </span>
                            <span className='font-w4'>{datetime(finish).format(FULL_DATE_FORMAT)}</span>?
                        </div>
                        <div className='font-note font-italic'>
                            Warning: <span className='font-w5'>Once deleted, they cannot be recovered</span>
                        </div>
                    </div>
                }
                onConfirm={handleDelete}
            >
                <Button
                    danger
                    className={'bt-danger'}
                    type='primary'
                    disabled={!start || !finish}
                    loading={loading}
                >
                    Delete
                </Button>
            </Popconfirm>
        </div>
    </Modal>;
};

export default PopupClear;