import React, {useState} from 'react';
import {Button} from "antd";
import {IContract, IVessel} from "../../../../util/varibles/interface";
import {planOpActions} from "../../reducer";
import {connect} from "react-redux";
import {showErrorResponse} from "../../../../util/varibles/global";
import {updateSingleContractFetch} from "../../../../util/services/spot";
import {notify, NotifyCode} from "../../../../util/varibles/message";

interface IProps {
    id: string
    contract: IContract

    cancelContract(payload: string[]): void

    setSpotVessels(vessels: IVessel[]): void
}

const Cancel: React.FC<IProps> = (props) => {
    const {id, contract} = props;
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setLoading(true);
        new Promise(resolve => resolve(updateSingleContractFetch(contract.id)))
            .then(() => {
                setLoading(false);
                notify.success(NotifyCode.S8)(['The contract'])
                props.cancelContract([id, contract.id]);
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Cancel contract failed', error);
            })
    }

    if (id.length === 0)
        return null;

    return <Button {...{
        className: 'bt-danger',
        loading,
        onClick: handleCancel
    }}>Cancel</Button>;
};

export default connect(null, {
    cancelContract: planOpActions.cancelContract
})(Cancel);
