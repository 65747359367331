import React from 'react';
import {IPropsChild} from "./index";

const Cleaning: React.FC<IPropsChild> = (props) => {
    return <div>
        
    </div>;
};

export default Cleaning;
