import React from 'react';
import styles from '../style.module.scss';
import Icon, {ICON_NAME} from "../../../../../../../../components/Icon";
import {ROUND_JOB, roundJob} from "../../../../../../../../util/varibles/constants";
import {durationTxt} from "../../../../../../../../util/varibles/global";
import {MenuProps} from "antd";
import Menu from "../../../../../../../../components/Menu";
import {IAfterProcesses} from "../../../../../../../../util/varibles/interface";
import {openPopup} from "../../../../../../../../components/Popup/Component/WrapperPopup";
import PopupAddBufferTime from "./PopupAddBufferTime";
import {useRoundMap} from "../../../../../../../../contexts/RoundMapContext";

interface IProps {
    data?: IAfterProcesses

    onSave(prev: (arr: IAfterProcesses[]) => IAfterProcesses[]): void
}

const BufferTime: React.FC<IProps> = ({data, onSave}) => {
    const {isEdit} = useRoundMap()

    const handleDeleteBufferTime = () => {
        onSave(arr => arr.filter((item: IAfterProcesses) => item.type !== ROUND_JOB.BufferTime))
    }

    const items: MenuProps['items'] = isEdit ? [
        {
            label: <div className='menu-line'>
                <Icon icon={ICON_NAME.EDIT}/>
                <span>Update buffer time</span>
            </div>,
            key: '1',
            onClick: () => {
                const el = openPopup(<PopupAddBufferTime
                    duration={data?.duration}
                    onSave={onSave}
                    onClose={() => el.remove()}
                />)
            }
        },
        {
            label: <div className='menu-line' data-danger='true'>
                <Icon icon={ICON_NAME.DELETE}/>
                <span className='color-danger'>Delete buffer time</span>
            </div>,
            key: '2',
            onClick: handleDeleteBufferTime
        },
    ] : [];

    if (!data)
        return null;

    return <Menu items={items} trigger={['contextMenu']} placement='bottomRight'>
        <div className={styles.bufferTime}>
            <Icon icon={ICON_NAME.CLOCK_2}/>
            <div>
                {roundJob[ROUND_JOB.BufferTime].name}: {durationTxt(data.duration || 0)}
            </div>
        </div>
    </Menu>
};

export default BufferTime;