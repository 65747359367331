import React from 'react';
import {Select} from "antd";
import styles from "./Treatment/style.module.scss";
import {IUnit, TTaskTreatment} from "../../../../../../../util/varibles/interface";
import {IUnitWhenEdit, useEditOp} from "../../../../../../../contexts/EditOperationContext";


interface IProps {
    data: TTaskTreatment
    unit: IUnitWhenEdit

    onUpdate(change: any): void
}

const MoveToUnit: React.FC<IProps> = ({data, onUpdate}) => {
    const [site] = useEditOp(state => state.site);
    const {units = []} = site || {};
    const handleChangeUnit = (value: IUnit) => {
        const {unit: errorOfUnit, ...args} = data.error;

        onUpdate({
            destination_id: value.id,
            destination_field: 'unit',
            destination_name: value.unit_id,
            error: args || {}
        })
    }

    const {destination_id} = data;
    return <Select
        className={[styles['select-custom'], 'w-full max-w-160'].join(' ')}
        placeholder="Select a unit"
        variant={"borderless"}
        value={destination_id}
        popupClassName={styles['dropdown-custom']}
        onSelect={(value: any, option: any) => handleChangeUnit(option.data)}
        popupMatchSelectWidth={false}
        options={units.map(item => ({value: item.id, label: item.unit_id, data: item}))}
    />;
};

export default MoveToUnit;