import React from 'react';
import {Col} from "antd";
import {IPropsChildren} from "../../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
}

const Section: React.FC<IProps> = ({children}) => {
    return <Col className="gutter-row" span={24} sm={12}>
        <div className='flex flex-col gap-[2px]'>
            {children}
        </div>
    </Col>;
};

export default Section;