import React from 'react';
import {SizeOperation} from "./Component/WrapperOperation";
import styles from "./style.module.scss";
import {OP_STATUS, OPERATION_TYPE} from "../../../../../../util/varibles/constants";
import {IPropsOp} from "./index";

interface IProps extends IPropsOp {
    operationType: OPERATION_TYPE
    status: OP_STATUS
}

const Unavailable: React.FC<IProps> = (props) => {
    return <SizeOperation {...{...props, isOwn: false, minHeight: 0, contentDisable: true}}>
        <div {...{className: styles['content-wrapper']}}>
            <div className='text-truncate'>Unavailable</div>
        </div>
    </SizeOperation>;
};

export default Unavailable;
