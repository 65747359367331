import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {Button} from "antd";
import {IOperation} from "../../../../util/varibles/interface";
import {orderUnitsFetch} from "../../../../util/services/vessel-side";
import {showErrorResponse} from "../../../../util/varibles/global";
import {useDispatch} from "react-redux";
import {loginActions} from "../../../../pages/LoginPage/reducer";

interface IProps {
    data: IOperation
    isChange: boolean
    orderUnit: string[]

    onCancel(): void

    onUpdate?(args: any): void
}

const OrderControl: React.FC<IProps> = (props) => {
    const {
        data,
        isChange,
        orderUnit,
        onCancel,
        onUpdate,
    } = props;

    const dispatch = useDispatch();

    const handleSave = useCallback(() => {
        const {id, tenant_id = '', sub_operations} = data.operation;
        const params = {
            operation_id: id,
            tenant_id,
            unit_order: orderUnit.map((unit_id: string) => {
                const unit = sub_operations.find(item => item.unit_id === unit_id) || {id: ''};
                return {id: unit.id, unit_id}
            })
        };

        dispatch(loginActions.updateLoadingVesselSide(true));
        new Promise(resolve => resolve(orderUnitsFetch(params)))
            .then((rs: any) => {
                const {activity_log, operation} = rs;
                dispatch(loginActions.updateLoadingVesselSide(false));
                const value = {
                    ...data,
                    activity_log,
                    operation: {...data.operation, ...operation}
                };
                dispatch(loginActions.updateOpOfVessel(value));
                if (onUpdate)
                    onUpdate(value);
            })
            .catch(error => {
                dispatch(loginActions.updateLoadingVesselSide(false));
                showErrorResponse('Unit order change failed', error)
            })
    }, [data, orderUnit, onUpdate, dispatch])

    if (!isChange)
        return null;

    return <>
        <div className={styles.orderControl}>
            <Button className={styles.btCancel} onClick={onCancel}>
                Cancel
            </Button>
            <Button className={styles.btAdd} onClick={handleSave}>
                Save
            </Button>
        </div>
    </>;
};

export default OrderControl;

