import React from 'react';
import {connect} from "react-redux";
import {aWeekMillisecond} from "../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {IVesselHeight} from "../Vessels";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    return {
        first: state.overview.weeks[0].time,
        space: state.overview.weeks.length * aWeekMillisecond,
    }
};

interface IProps {
    source: any[]
    vesselHeight: IVesselHeight
    moreHeight: number
    first: number
    space: number
}

const Contracts: React.FC<IProps> = ({source, moreHeight, vesselHeight, first, space}) => {
    return <>
        {source.map((item: any) => {
            const {start_time, finish_time, contract_code, isPermission, isOwn, vesselId} = item;

            if (isOwn === isPermission)
                return null;

            let style;
            if (vesselHeight[vesselId]) {
                const {height, index, position} = vesselHeight[vesselId];
                const top = index + (position * moreHeight) + 60;
                style = {
                    display: 'flex',
                    height: `${height + moreHeight}px`,
                    top: `${top}px`,
                    left: `calc((100% - 160px) * ${(start_time - first)} / ${space} + 160px)`,
                    width: `calc((100% - 160px) * ${(finish_time - start_time)} / ${space})`
                }
            } else {
                style = {
                    display: 'none'
                }
            }

            return <div key={contract_code} {...{
                className: styles.contract,
                style,
                'data-is-own': isOwn,
            }}/>
        })}
    </>;
};

export default connect(mapStateToProps, {})(Contracts);
