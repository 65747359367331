import React, {Component} from 'react';
import styles from './style.module.scss';
import {formatNumber} from "../../util/varibles/global";
import {TOperation} from "../../util/varibles/interface";

interface IProps {
    visible?: boolean
    operation: TOperation
}

class FishAmount extends Component<IProps> {
    static defaultProps = {
        visible: true,
    }

    render() {
        const {visible} = this.props;
        if (!visible) return null;

        const {sub_operations} = this.props.operation;

        let flag = false;
        const fishAmount = sub_operations.reduce((total: number, item) => {
            if (item.avg_weight <= 0)
                flag = true;
            return total + item.fish_amount
        }, 0);

        if (flag && fishAmount === 0)
            return null

        return <div className={[styles['margin-left'], styles['border-right']].join(' ')}>
            Fish amount: {formatNumber(fishAmount)}
        </div>;
    }
}

export default FishAmount;
