import callApi from "../api/callApi";
import {urlChecklist} from "../api/v1";

export function getChecklistsByUserFetch(userId: string) {
    return callApi(urlChecklist + '/user/' + userId, {
        method: "GET"
    })
}

export function getChecklistsByVesselFetch(vesselId: string) {
    return callApi(urlChecklist + '/vessel/' + vesselId, {
        method: "GET"
    })
}

export function createChecklistFetch(body: any) {
    return callApi(urlChecklist, {
        method: "POST",
        body: JSON.stringify(body)
    })
}

export function updateChecklistFetch(body: any) {
    return callApi(urlChecklist, {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

interface IUpdateTagsFetch {
    id: any
    tags: string[]
}

export function updateTagsFetch(body: IUpdateTagsFetch) {
    return callApi(urlChecklist + '/tags', {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export function deleteChecklistFetch(id: string) {
    return callApi(urlChecklist, {
        method: "DELETE",
        body: JSON.stringify({id})
    })
}
