import React from 'react';
import Icon, {ICON_NAME} from "../../../../../../components/Icon";
import {useEditOp} from "../../../../../../contexts/EditOperationContext";
import {convertNumberTimeToStringTime} from "../../../../../../util/varibles/global";

interface IProps {
}

const Duration: React.FC<IProps> = () => {
    const [duration = 0] = useEditOp(state => state.operation?.duration);

    return <div className='flex items-center gap-1'>
        <Icon icon={ICON_NAME.CLOCK_2}/>
        {convertNumberTimeToStringTime(duration) || '__'}
    </div>;
};

export default Duration;