import React, {useEffect, useRef, useState} from 'react';
import {IActivityLog} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import {Tooltip} from "antd";
import {listOfStep} from "../../../../util/varibles/constants";
import {uppercaseFirstLetter} from "../../../../util/varibles/global";
import RoundName from "./RoundName";


interface IProps {
    current_process: number
    step: number
    schedule: { [round: string]: IActivityLog[] }

    changeStep(step: number): void
}

const Steps: React.FC<IProps> = ({current_process, step, schedule, changeStep}) => {
    const root: any = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (root.current)
            setWidth(root.current.offsetWidth)
    }, [root])

    if (Object.keys(schedule).length === 0)
        return <div ref={root} className={styles.steps}/>

    const max = schedule[Object.keys(schedule)[0]].length;
    const column = Math.floor((width + 70) / (max * 70 + 70));
    const style = {gridTemplateColumns: `repeat(${column}, min-content)`}

    return <div ref={root} className={styles.steps} style={style}>
        {Object.keys(schedule).map((key, i) => <div key={key} className={styles.round}>
            <RoundName column={column} index={i} name={key}/>
            {schedule[key].map((item) => {
                const {key = ''} = item;
                const list = key.split(',');
                const name = list.map((step: string) => {
                    const {name = ''} = listOfStep[step];
                    return uppercaseFirstLetter(name);
                }).join(', ');

                return <div
                    key={item.index}
                    className={styles.step}
                    data-selected={item.index === step}
                    data-is-active={item.index === current_process}
                >
                    <Tooltip title={name}>
                        <div
                            className={styles.stepName}
                            data-disable={(item.index || 0) > current_process}
                            onClick={() => changeStep(item.index || 0)}
                        />
                    </Tooltip>
                </div>
            })}
        </div>)}
    </div>;
};

export default Steps;
