import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPE} from "../../../../../util/varibles/constants";
import {IWidget} from "../../../constants";
import {openPopup} from "../../../../../components/Popup/Component/WrapperPopup";
import PopupEditWidget from "../../../_Component/PopupEditWidget";
import {showErrorResponse} from "../../../../../util/varibles/global";
import {updateDashboardFetch} from "../../../../../util/services/dashboard";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {vesselDetailActions} from "../../../reducer";
import {selectVessel} from "../../../../../util/store/selectors";
import Icon, {ICON_NAME} from "../../../../../components/Icon";


interface IProps {
    widget: IWidget
    index: number
}

const Menu: React.FC<IProps> = React.memo((props) => {
    const {index, widget} = props;
    const dashboard = useSelector(selectVessel.dashboard);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            document.removeEventListener('click', handleClose)
        };
    }, []);

    useEffect(() => {
        if (visible) {
            document.addEventListener('click', handleClose)
        } else {
            document.removeEventListener('click', handleClose)
        }
    }, [visible]);

    const handleClose = (e: MouseEvent | any) => {
        if (!e || !e.target.closest('.' + styles['container-menu'])) {
            setVisible(false);
        }
    };

    const handleEdit = () => {
        const el = openPopup(<PopupEditWidget
          widget={{...widget, index}}
          editMode={ACTION_TYPE.EDIT}
          onClose={() => el.remove()}
        />)
        setVisible(false)
    };

    const handleDelete = () => {
        if (loading)
            return;
        const content = JSON.parse(dashboard.content);
        let params = {name: ''};
        if (content.length > 1) {
            content.splice(index, 1)
            params = {...dashboard, content: JSON.stringify([...content])};
        } else
            params = {...dashboard, content: JSON.stringify([])};

        setLoading(true);
        new Promise(resolve => resolve(updateDashboardFetch(params)))
          .then((rs: any) => {
              notify.success(NotifyCode.S5)([widget.name]);
              dispatch(vesselDetailActions.updateDashboard({dashboard: rs}))
              setLoading(false);
          })
          .catch(error => {
              setLoading(false);
              showErrorResponse('Delete failed', error)
          })
        setVisible(false)
    }

    return <div className={styles.menu}>
        <div className='three-dot' onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setVisible(true)
            return false;
        }}/>
        <div className={styles['container-menu']} data-is-show={visible}>
            <div className={styles['item-menu']} onClick={handleEdit}>
                <Icon icon={ICON_NAME.EDIT} size='large'/>
                <span>Edit</span>
            </div>
            <div className={[styles['item-menu']].join(' ')}
                 onClick={handleDelete}
                 data-danger={true}
            >
                <Icon icon={ICON_NAME.DELETE} danger size='large'/>
                <span className='color-danger'>Delete</span>
            </div>
        </div>
    </div>;
}, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));

export default Menu
