import React, {ReactNode, useMemo} from 'react';
import styles from './style.module.scss';
import {IPropsChildren} from "../../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    colPerRow: number
    columns: any[]
    titleClassName: string
}

const ListHeader: React.FC<IProps> = ({colPerRow, titleClassName, columns, children}) => {
    const style = {gridColumn: `1 / span ${colPerRow}`, gridTemplateColumns: `repeat(${colPerRow},1fr)`}
    const title: ReactNode = useMemo(() => columns.map((item: any, i: number) => <div key={i}
                                                                                      className={item.className}>
        {item.title}
    </div>), [columns]);
    const list: number[] = [];

    for (let i = 0; i < colPerRow; i++) {
        list.push(i)
    }

    return <div className={styles['header-stores']} style={style}>
        {list.map((i) => <div key={i} className={[styles['header-title'], titleClassName].join(' ')}>
            {i === 0 ? children : <div/>}
            {title}
        </div>)}
    </div>;
};

export default ListHeader;