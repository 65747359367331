import React, {useContext} from 'react';
import {useDispatch} from "react-redux";
import styles from './style.module.scss';
import {PreferenceSetting} from "../../../util/varibles/userSetting";
import {loginActions} from "../../../pages/LoginPage/reducer";
import {KEY_STORAGE, THEME_COLOR} from "../../../util/varibles/constants";
import {ThemeContext} from "../../Theme/ThemeContext";

interface IProps {
}

const ThemeColor: React.FC<IProps> = () => {

    const {theme, setTheme} = useContext(ThemeContext);
    const dispatch = useDispatch();

    const changeColor = () => {
        const value = theme === THEME_COLOR.LIGHT ? THEME_COLOR.DARK : THEME_COLOR.LIGHT;
        dispatch(loginActions.updateUserSetting([
            {[PreferenceSetting.THEME_COLOR]: value}
        ]))
        localStorage.setItem(KEY_STORAGE.THEME, value);
        setTheme(value)
    }

    return <div className={[styles.wrapper, 'change-theme'].join(' ')} onClick={changeColor}>
        <div/>
    </div>;
};

export default ThemeColor;
