import React, {ReactNode} from 'react';
import Icon, {ICON_NAME} from "../../Icon";
import styles from '../style.module.scss';

interface IProps {
    name: string | ReactNode

    onClear(): void
}

const ValueAndClear: React.FC<IProps> = ({name, onClear}) => {
    return <div className={styles.valueAndClear}>
        <div>{name}</div>
        <div data-lev='close' onClick={onClear}>
            <Icon icon={ICON_NAME.CLOSE}/>
        </div>
    </div>;
};

export default ValueAndClear;