import {FuncOp} from "./constants";
import {ACTION_TYPE, KEY_STORAGE, OPERATION_TYPE, SITE_TYPE, VIEW_SMOOTH} from "../../../../util/varibles/constants";
import {checkOperationType} from "../../BiologyBrowser/constants";
import {IVessel} from "../../../../util/varibles/interface";
import {getElById} from "../../../../util/varibles/global";

interface IUncheckBiology {
    uncheck?(): void
}

interface IBeginCreate {
    vessel: IVessel
    availableTimeId: string
    contractId: string
    startTime: number
    defaultOpType: any
}

export const funcGlobal = {
    [FuncOp.UncheckBiology]: (args: any | IUncheckBiology) => {
        localStorage.setItem(KEY_STORAGE.CLEAR_CHECK_BIOLOGY, Date.now().toString());
        if (args.uncheck)
            args.uncheck();
    },

    [FuncOp.BeginCreate]: (args: any | IBeginCreate) => {
        const {vessel, availableTimeId, contractId, startTime, defaultOpType, userTenantId} = args;

        const unitsNew = JSON.parse(localStorage.getItem(KEY_STORAGE.UNITS_SELECTED) || '');
        const requestType = JSON.parse(localStorage.getItem(KEY_STORAGE.REQUIRE_OPERATION_TYPE) || '');
        const limitOperationTypes = checkOperationType(vessel, requestType);

        if (limitOperationTypes.length === 0)
            return;

        let operation: any = {}, isInternal = false;

        const {store, total_weight, sub_operations} = Object.keys(unitsNew).reduce((data: any, key: string) => {
            const {total_weight, harvest_id = '-', site_type} = unitsNew[key];
            if (!isInternal) {
                isInternal = site_type === SITE_TYPE.INTERNAL
            }
            data.total_weight += total_weight;
            data.store[key] = {...unitsNew[key], harvest_id}
            data.sub_operations.push(unitsNew[key]);
            return data;
        }, {store: {}, total_weight: 0, sub_operations: []});

        const {[vessel.id]: veselOpType = {}, global = null} = defaultOpType || {};
        const defaultType = veselOpType.type || global;

        const operationType = limitOperationTypes.find((item: any) => item === defaultType) || limitOperationTypes[0]

        return {
            isShow: true,
            operationType,
            limitOperationTypes,
            isAllowChange: limitOperationTypes.length > 1,
            editMode: ACTION_TYPE.CREATE,
            isFull: limitOperationTypes[0] === OPERATION_TYPE.HARVEST,
            isInternal,
            values: {
                store,
                activity_log: [{est_start_time: startTime}],
                operation: {
                    ...operation,
                    total_weight,
                    tenant_id: userTenantId,
                    sub_operations,
                    available_time_id: availableTimeId,
                    contract_id: contractId
                },
                vessel,
            },
        }
    },
}

export function focusOp(id: string) {
    setTimeout(() => {
        const el = getElById(id);
        if (el)
            el.scrollIntoView(VIEW_SMOOTH)
    }, 300)
}