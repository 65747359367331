import {SupportType} from "../../../../constants";
import {
    KeyOfTask,
    OPERATION_TYPE,
    TASK_TYPE,
    TREATMENT_TYPE,
    VESSEL_TYPE
} from "../../../../../../util/varibles/constants";
import {ICombineTreatment, ISorting, IUnit, TTaskTreatment} from "../../../../../../util/varibles/interface";
import {uuid} from "../../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import {notification} from "antd";

export interface IKeyUpdate {
    key: KeyOfTask
    value?: any
}

export function convertUnit(data: Partial<IUnit> & { site_id?: string, site_name?: string }) {
    const {
        id = '',
        unit_id = '',
        site_id = '',
        site_name = '',
        process_type,
        density_percent
    } = data;

    return {id, unit_id, site_id, site_name, process_type, density_percent}
}

export function checkSupportTasks(tasks: any, supportTasks: any) {
    return {
        tasks: tasks.map((item: any) => {
            const {treatment_type} = item;
            if (treatment_type === TREATMENT_TYPE.SORTING) {
                const {sub_tasks} = item;
                return {
                    ...item,
                    sub_tasks: Object.keys(sub_tasks).reduce((rs: any, key) => {
                        rs[key] = sub_tasks[key].map((sub: any) => {
                            delete sub.support_vessels;
                            return sub
                        })
                        return rs;
                    }, {})
                }
            }
            delete item.support_vessels;
            return item;
        }),
        tasksSupport: supportTasks.map((item: any) => ({...item, support_vessels: []}))
    }
}

export function checkTreatment(tasks: TTaskTreatment[]) {
    let isErrorGlobal = false;
    const tasksNew = tasks.map((item) => {
        const {type = null, error = {}} = item;
        if (Object.keys(error || {}).length > 0) {
            isErrorGlobal = true;
            return item;
        }

        if (type === null) {
            isErrorGlobal = true;
            return {...item, error: {empty: notify[NotifyCode.E11]()}};
        }
        const {isError, task} = checkTaskOfTreatment(item);
        if (isError)
            isErrorGlobal = true;
        return task;
    })
    return {
        isError: isErrorGlobal,
        tasks: [...tasksNew]
    }
}

export function checkTaskOfTreatment(task: TTaskTreatment): { isError: boolean, task: TTaskTreatment } {
    const {type} = task;
    switch (type) {
        case TASK_TYPE.TREATMENT:
            const {treatment_type = null} = task;
            if (treatment_type === null)
                return {isError: true, task: {...task, error: {treatment_type: notify[NotifyCode.E17]()}}}
            switch (treatment_type) {
                case TREATMENT_TYPE.FRESHWATER:
                case TREATMENT_TYPE.SALTWATER:
                case TREATMENT_TYPE.HYDROGENPEROXIDE: {
                    const {duration} = task;
                    if (duration === undefined)
                        return {isError: true, task: {...task, error: {duration: notify[NotifyCode.E16]()}}};
                    break;
                }
                case TREATMENT_TYPE.SORTING: {
                    const {conditions = [], sub_tasks} = task as ISorting;
                    if (Object.keys(conditions).length === 0)
                        return {isError: true, task: {...task, error: {conditions: notify[NotifyCode.E10]()}}};

                    let isErrorGlobal = false;
                    const subTasksNew = Object.keys(sub_tasks || {}).reduce((rs: ISorting['sub_tasks'], key) => {
                        rs[key] = sub_tasks[key].map(sub => {
                            const {isError, task: subTaskNew} = checkTaskOfTreatment(sub);
                            if (isError)
                                isErrorGlobal = true;
                            return subTaskNew
                        })
                        return rs;
                    }, {});
                    return {isError: isErrorGlobal, task: {...task, sub_tasks: subTasksNew}}
                }
            }
            break;
        case TASK_TYPE.HARVEST: {
            const {destination_id} = task;
            if (!destination_id)
                return {isError: true, task: {...task, error: {factory: notify[NotifyCode.E14]()}}}
            break;
        }
        case TASK_TYPE.MOVE: {
            const {destination_id} = task;
            if (!destination_id)
                return {isError: true, task: {...task, error: {unit: notify[NotifyCode.E13]()}}}
            break;
        }
        case TASK_TYPE.COMBINE: {
            const {sub_tasks} = task as ICombineTreatment;
            let isErrorGlobal = false;
            const subTasksNew = sub_tasks.map((sub) => {
                const {isError, task: subTaskNew} = checkTaskOfTreatment(sub);
                if (isError)
                    isErrorGlobal = true;
                return subTaskNew
            })
            return {isError: isErrorGlobal, task: {...task, sub_tasks: subTasksNew}}
        }
        default:
            break;
    }
    return {isError: false, task}
}

export function addTask(tasks: any = [], unit: any) {
    const {id: target_id} = unit;
    let index = 0, isIn = false;
    for (let i = 0; i < tasks.length; i++) {
        const {treatment_type} = tasks[i];
        const {id} = tasks[i].sub_operation || tasks[i].sub_operations[0];
        index = i + 1;

        if (id !== target_id) {
            if (isIn)
                break;
        } else {
            isIn = true;
            if (treatment_type === TREATMENT_TYPE.SORTING) {
                index = i;
                break;
            }
        }
    }

    const result = Array.from(tasks);
    result.splice(index, 0, {
        type: null,
        sub_operation: convertUnit(unit),
        group_id: uuid(),
    });
    return result;
}

export function getTaskSupport(tasks: any) {
    return tasks.reduce((list: any, item: any) => {
        const {treatment_type} = item;
        if (treatment_type === TREATMENT_TYPE.SORTING) {
            const {sub_tasks} = item;
            Object.keys(sub_tasks).forEach((key) => {
                sub_tasks[key].forEach((sub: any) => {
                    const {is_support_vessel} = sub;
                    if (is_support_vessel)
                        list.push(sub);
                })
            })
        } else {
            const {is_support_vessel} = item;
            if (is_support_vessel)
                list.push(item);
        }
        return list
    }, []);
}

interface IGenerateOpSupport {
    support_vessels: any
    vessels: any
    tasks: any
    operation: any
}

export function generateOpSupport(args: IGenerateOpSupport) {
    const {support_vessels, vessels, tasks, operation} = args;
    const subTasks = tasks.reduce((list: any, item: any) => {
        if (item.treatment_type === TREATMENT_TYPE.SORTING) {
            const {sub_tasks} = item;
            Object.keys(sub_tasks).forEach(subKey => {
                sub_tasks[subKey].forEach((subTask: any) => {
                    subTask.support_vessels?.forEach((sub: any) => {
                        list[sub.id] = [...list[sub.id] || [], {...subTask, sorting: false}]
                    })
                })
            })
        } else {
            item.support_vessels?.forEach((sub: any) => {
                list[sub.id] = [...list[sub.id] || [], {...item, sorting: false}]
            })
        }
        return list
    }, {});

    return Object.keys(support_vessels).reduce((list: any, key, index) => {
        const {
            start_place_id = '-',
            start_place_field = '-',
            activity_log: currentActivityLog
        } = support_vessels[key];
        const vesselSp: any = vessels.find((item: any) => item.id === key) || {};
        const listOfTask = subTasks[key];
        const id = Date.now() + index;

        if (listOfTask.length === 1 && listOfTask[0].type === TASK_TYPE.HARVEST) {
            const {site_id, site_name, isInternal} = args.operation;
            const {destination_id, destination_name, delivery_types} = listOfTask[0];

            const {
                currentSubOp,
                fish_amount,
                total_weight,
                prev_op_router_id,
                router_id
            } = listOfTask.reduce((list: any, item: any) => {
                const {fish_amount, total_weight} = item.sub_operations[0]
                list.fish_amount += fish_amount;
                list.total_weight += total_weight;
                list.currentSubOp.push(item.sub_operations[0]);
                list.prev_op_router_id = item.prev_op_router_id;
                list.router_id = item.router_id;
                return list;
            }, {
                currentSubOp: [],
                fish_amount: 0,
                total_weight: 0,
                prev_op_router_id: '-',
                router_id: '-'
            });

            list[id] = {
                operation: {
                    sorting: true,
                    site_id,
                    site_name,
                    factory_id: destination_id,
                    factory_name: destination_name,
                    isInternal,
                    delivery_types,
                    id,
                    start_place_id,
                    start_place_field,
                    prev_op_router_id,
                    router_id,
                    is_waiting_unit: vesselSp.type === VESSEL_TYPE.WELL_BOAT,
                    operation_code: id,
                    tenant_id: args.operation.tenant_id,
                    operation_type: OPERATION_TYPE.HARVEST,
                    sub_operations: currentSubOp,
                    tasks: listOfTask,
                    note: '',
                    related_id: args.operation.id,
                    support: SupportType.SUB,
                    fish_amount,
                    total_weight
                },
                activity_log: currentActivityLog,
                vessel: vesselSp
            }
        } else {
            list[id] = {
                operation: {
                    ...operation,
                    id,
                    operation_code: id,
                    tenant_id: args.operation.tenant_id,
                    start_place_id,
                    start_place_field,
                    operation_type: OPERATION_TYPE.TREATMENT,
                    tasks: listOfTask,
                    related_id: args.operation.id,
                    support: SupportType.SUB
                },
                activity_log: currentActivityLog,
                vessel: vesselSp
            }
        }
        return list
    }, {})
}

export function updateUnitToTask(unitAdded: IUnit[], units: any, tasks: any) {
    const unitsFail = unitAdded.reduce((list: any, item: any) => {
        const {fish_amount = null, total_weight = null} = units[item.id] || {}
        if (!fish_amount || !total_weight)
            return [...list, item.unit_id]
        return list
    }, []);

    if (unitsFail.length > 0) {
        notification.error({
            message: 'Error',
            description: `Please fill fish amount and total weight for ${unitsFail.join(', ')}`
        })
        return;
    }

    return tasks.map((item: any) => {
        const {type, treatment_type, sub_operation} = item;
        const {id: unitId} = sub_operation;
        const {fish_amount, total_weight, fish_amount_original = fish_amount} = units[unitId];
        const props = {fish_amount, total_weight, fish_amount_original}
        if (treatment_type === TREATMENT_TYPE.SORTING) {
            const {sub_tasks} = item;
            return {
                ...item,
                sub_operation: type === TASK_TYPE.MOVE ? sub_operation : {...sub_operation, ...props},
                sub_tasks: Object.keys(sub_tasks).reduce((rs: any, key) => {
                    rs[key] = sub_tasks[key].map((sub: any) => {
                        const {type} = sub;
                        if (type === TASK_TYPE.HARVEST) {
                            const {sub_operations} = sub;
                            const {delivery_types} = sub_operations[0];
                            return {
                                ...sub,
                                sub_operations: [{
                                    ...sub_operations[0],
                                    ...props,
                                    delivery_types: [{...delivery_types[0], ...props}]
                                }]
                            }
                        }
                        return {...sub, sub_operation: {...sub.sub_operation, ...props}}
                    })
                    return rs;
                }, [])
            }
        }
        if (item.sub_operations) {
            item.sub_operations = [{...item.sub_operations[0], ...props,}]
            return item;
        }
        return {...item, sub_operation: {...sub_operation, ...props}}
    })
}

export const initTask = (unit: { id: string, unit_id: string, site_id?: string, site_name?: string }) => ({
    type: null,
    group_id: uuid(),
    sub_operation: convertUnit(unit),
    error: {},
})

export const checkSorting = (tasks: TTaskTreatment[]): boolean => {
    return tasks.some(item => {
        const {type, treatment_type} = item;

        return type === TASK_TYPE.TREATMENT && treatment_type === TREATMENT_TYPE.SORTING
    })

}