import React, {createRef, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import stylesContainer from "../style.module.scss";
import {Input, Select, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import AddExternalSite from "../_Component/AddExternalSite";
import Filter from "./Filter";
import AvgWeight from "../_Component/AvgWeight";
import LiceSort from "../_Component/LiceSort";
import {loginActions} from "../../../LoginPage/reducer";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import {SITE_TYPE} from "../../../../util/varibles/constants";
import CountSelected from "../_Component/CountSelected";

interface IProps {
    siteType: any
    searchValue: any
    weightFilter: [number | null, number | null],
    sites: any
    liceSort: null | string

    onSearch(value: { siteType?: SITE_TYPE, searchValue?: string, weightFilter?: [number | null, number | null] }): void
}

const {Option} = Select;

const HeaderUnits: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const rootRef = createRef<HTMLDivElement>()

    const handleChangeLice = (value: any) => {
        dispatch(loginActions.updateUserSetting([{[PlanSetting.BIOLOGY_LICE_SORT]: value}]))
    }

    useEffect(() => {
        if (rootRef.current) {
            const element = document.getElementById('units');
            if (element) {
                const {offsetWidth, clientWidth} = element
                rootRef.current.style.width = `calc(100% - ${offsetWidth - clientWidth}px)`
            }
        }
    }, [rootRef.current]);

    const {sites, weightFilter, liceSort, onSearch} = props;

    return <div ref={rootRef} className={stylesContainer['tab-header']}>
        <div className={stylesContainer['header-left']} data-operaion-type='units'>
            <LiceSort lice={liceSort} onChange={handleChangeLice}/>
            <CountSelected/>
            <Space.Compact className={[stylesContainer['search-group']].join(' ')}>
                <Select allowClear placeholder='Site type'
                        onChange={(value: any = '') => onSearch({siteType: value})}
                        style={{width: '100px'}}
                >
                    <Option value="external">External</Option>
                    <Option value="internal">Internal</Option>
                </Select>
                <Input
                    style={{width: 'calc(100% - 100px)'}}
                    placeholder="Search site"
                    allowClear
                    onChange={({target: {value}}: any) => onSearch({searchValue: value})}
                    suffix={<SearchOutlined className='text-comment'/>}
                />
            </Space.Compact>
            <AvgWeight weight={weightFilter} onChange={value => onSearch({weightFilter: value})}/>
        </div>
        <div className={stylesContainer['header-right']}>
            <Filter {...{sites}}/>
            <AddExternalSite/>
        </div>
    </div>;
};

export default HeaderUnits;