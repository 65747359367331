import React, {useState} from 'react';
import {CaretLeftOutlined} from "@ant-design/icons";
import styles from '../style.module.scss';

interface IProps {
}

const Toggle: React.FC<IProps> = (props) => {
    const elementRef = React.useRef(null);
    const [open, setOpen] = useState(true);

    function onClick(e: any) {
        setOpen(!open)
        const {parentElement}: any = elementRef.current || {};
        if (parentElement) {
            parentElement.dataset.open = !open;
        }
    }

    return <div className={styles.toggleLeft} data-open={open} ref={elementRef} onClick={onClick}>
        <div>
            <CaretLeftOutlined/>
        </div>
    </div>;
};

export default Toggle;
