import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {ACTION_TYPE, DELIVERY_TYPE, SITE_TYPE, VESSEL_TYPE} from "../../../../../../util/varibles/constants";
import styles from './style.module.scss';
import {checkRoute2, getFactory} from "../../../../util";
import Units from "../../_Component/Units";
import Operations from "./Operations";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import Footer from "../../Footer";
import {createHarvest, createHarvestExternal, createHarvests, opsAfterSubmit} from "./constants";
import {IFactory, IOperations, IUnit, IVesselConfig} from "../../../../../../util/varibles/interface";
import {planOpActions} from "../../../../reducer";
import {AppState} from "../../../../../../util/store/store";
import {cloneObj} from "../../../../../../util/varibles/global";
import {getSetting} from "../../../../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../../../../util/varibles/userSetting";
import {callEventEditOp, getEditOp, useEditOp} from "../../../../../../contexts/EditOperationContext";
import {selectTenantId} from "../../../../../../util/store/selectors";

const mapStateToProps = (state: AppState) => {
    const {vessel: config = {}} = getSetting(state.login.user.setting, PreferenceSetting.AUTO_PLAN);
    return {
        operations: state.planOperation.operations,
        routeIds: state.planOperation.routeIds,
        factories: state.planOperation.factories,
        config
    }
};

const initState = {
    ops: [],
    units: {},
}

interface IProps {
    operations: IOperations
    routeIds: string[]
    factories: IFactory[]
    isInternal: boolean
    loading?: boolean
    popupData: any
    config: { [id: string]: IVesselConfig }

    onClose(): void
}

const Harvest: React.FC<IProps> = (props) => {
    const [loadingSubmit, set] = useEditOp(state => state.loadingSubmit);
    const {factories, routeIds, popupData, config} = props;
    const userTenantId = useSelector(selectTenantId)
    const get = getEditOp();
    const {getActivityLog} = callEventEditOp();

    useEffect(() => {
        const generateState = () => {
            const {isInternal = true, editMode} = popupData
            if (!popupData.values)
                return initState

            const {operation, vessel, store} = popupData.values;
            const {sub_operations} = operation;

            const factoriesFilter = sub_operations.reduce((list: IFactory[], item: any) => {
                const {factory_id, factory_name} = item;
                if (factory_id)
                    return list.map(sub => sub.id === factory_id ? {...sub, name: factory_name} : sub)
                return list;
            }, factories)

            if (editMode === ACTION_TYPE.CREATE) {
                const {is_journey_to_site = false, cleaning_types = []} = config[vessel.type] || {};

                if (factories.length === 0) {
                    notify.error(NotifyCode.E18, 'Create operation failed')();
                    return initState;
                }

                const {operations} = createHarvests({
                    data: sub_operations,
                    store: cloneObj(store),
                    factories,
                    routeIds,
                    vessel,
                    isFull: popupData.isFull,
                    isInternal,
                    properties: {
                        tenant_id: userTenantId,
                        available_time_id: operation.available_time_id,
                        contract_id: operation.contract_id,
                        is_cleaning: new Set(cleaning_types).has(DELIVERY_TYPE.DIRECT),
                        is_journey_to_site
                    }
                }) || [];
                return {
                    isCalculate: true,
                    ops: operations,
                    units: cloneObj(store),
                    count: operations.length,
                    factories: factoriesFilter
                };
            } else {
                let error_detail: any = {};
                if (operation.total_weight > vessel.capacity)
                    error_detail.total_weight = notify[NotifyCode.E1]();
                const {factory_id, sites = [], site_id, site_name, sub_operations} = operation;
                const factory: any = factories.find((item: any) => item.id === factory_id);
                const operations = cloneObj([{
                    ...operation,
                    sub_operations: sub_operations.map((sub: any) => {
                        const delivery_types = cloneObj(sub.delivery_types || []);
                        const isDefault = delivery_types.some((deliveryType: any) => deliveryType.default);
                        if (isDefault) {
                            return sub;
                        } else {
                            delivery_types[0].default = true;
                            return {...sub, delivery_types}
                        }
                    }),
                    error_detail,
                    delivery_types: factory.deliver_type || {},
                    sites: sites.length === 0 ? {id: site_id, name: site_name, isRoute: true} : sites
                }]);
                return {
                    ...initState,
                    ops: operations,
                    units: cloneObj(store),
                    count: operations.length,
                    factories: factoriesFilter
                };
            }
        }
        set(generateState())
    }, [popupData, factories, routeIds, config, userTenantId, set]);

    const addOperation = (unit: IUnit) => {
        const {ops: old, count, vessel, operation: opOld} = get();
        const ops = cloneObj(old);
        const {
            site_id,
            site_name,
            site_type,
            primary_factory = '',
            factory_id = ''
        } = unit;
        const site = {id: site_id, name: site_name, type: site_type, isRoute: true}
        const factory = getFactory(factory_id, primary_factory, factories);
        const waitingUnit = vessel?.type === VESSEL_TYPE.WELL_BOAT;
        const {error, message} = checkRoute2(site, factory, routeIds);
        let error_detail = {};
        if (error === 2) {
            error_detail = {route: [{source_id: site.id, destination_id: factory_id, message}]};
            site.isRoute = false;
        }
        const {deliver_type = {}} = factory;
        const keyDeliveryType: any = Object.keys(deliver_type).find((key: any) => deliver_type[key].default);
        const deliveryType = deliver_type[keyDeliveryType];
        const {isInternal} = props;
        const properties = {
            tenant_id: userTenantId,
            available_time_id: opOld?.available_time_id,
            contract_id: opOld?.contract_id
        }
        const common = {
            unit,
            count,
            site,
            factory,
            waitingUnit,
            deliver_type,
            deliveryType,
            error_detail,
            properties
        }
        const {operation} = !isInternal ? createHarvestExternal(common) : createHarvest({...common, vessel});

        ops.push(operation);
        set({ops, count: count + 1})
    }

    const handleSubmit = () => {
        const {isCalculate, ops} = get();

        if (isCalculate) {
            const {vessel, activity_log} = get();
            const {id: opId} = ops[0] || {};
            const {isError, operations: dataNew} = checkError();
            if (isError || !vessel) return;
            const {operations, properties} = opsAfterSubmit(dataNew, vessel, {
                [opId]: {
                    start: activity_log[0].est_start_time,
                    old_activity_log: activity_log
                }
            })

            getActivityLog({source: operations, properties})

        } else {
            const {onSave} = get();
            const operation = ops[0];
            const {id = ''} = ops[0];
            onSave({update: {[id]: {...props.operations[id], operation}}, isCheck: false});
        }
    };

    const checkError = () => {
        const {ops} = get();
        const {routeIds} = props;
        let isError = false;
        const operationsAfterCheck = ops.reduce((list: any, item: any) => {
            const {factory_id, factory_name, error_detail = {}, sub_operations, sites = []} = item;

            const indexSiteHasRoute = sites.findIndex((sub: any) => checkRoute2(sub, {
                id: factory_id,
                name: factory_name
            }, routeIds).error !== 2);
            if (indexSiteHasRoute === -1) {
                isError = true;
                error_detail.route = sites.map((item: any) => ({
                    source_id: item.id,
                    destination_id: factory_id,
                    message: notify[NotifyCode.E20]([item.name, factory_name])
                }));
            } else {
                item.sites = sites.map((sub: any, i: any) => i === indexSiteHasRoute ? {...sub, isRoute: true} : sub)
            }

            const site = sites[0];
            if (site.type === SITE_TYPE.INTERNAL) {
                const isEmpty = sub_operations.some((sub: any) => sub.fish_amount === 0 || sub.total_weight === 0)
                if (isEmpty) {
                    isError = true;
                    error_detail.fish_amount = notify[NotifyCode.E37](['Fish amount, total weight']);
                }
            } else {
                const isEmpty = sub_operations.some((sub: any) => sub.total_weight === 0)
                if (isEmpty) {
                    isError = true;
                    error_detail.fish_amount = notify[NotifyCode.E37](['Total weight']);
                }
            }
            list = [...list, {...item, error_detail}];
            return list;
        }, []);

        if (isError) {
            set({ops: operationsAfterCheck})
        }
        return {isError, operations: ops};
    }

    return <div className={styles['container-body']}>
        <Units {...{
            addOperation: addOperation,
            gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 50px',
            nameRow: (data: any) => <>
                <span>{data.harvest_id}</span>
                <span>{data.site_name}, {data.unit_id}</span>
            </>,
        }} />
        <Operations/>
        <div className='mt-auto'>
            <Footer {...{
                loading: loadingSubmit || props.loading,
                handleSubmit: handleSubmit,
                onClose: props.onClose,
            }}/>
        </div>
    </div>
};

export default connect(mapStateToProps, {
    getActivityLog: planOpActions.getActivityLog
})(Harvest);
