import {IOperation} from "../../util/varibles/interface";
import {propsEmpty} from "../../util/varibles/constants";

export enum TAB_OPERATION_DETAIL {
    INFO = 'info',
    MESSAGE = 'message'
}

export interface OperationDetailState {
    data: IOperation
    keyTab: TAB_OPERATION_DETAIL

    loadingOp: boolean
    loadingMessages: boolean
    loading: any

    loadingRoute: boolean
}

export const initialState: OperationDetailState = {
    data: propsEmpty,
    keyTab: TAB_OPERATION_DETAIL.INFO,

    loadingOp: false,
    loadingMessages: false,
    loading: {note: false},

    loadingRoute: false,
};
