import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {getElById} from "../../util/varibles/global";
import {SCHEDULE_ID} from "./index";
import Icon, {ICON_NAME} from "../Icon";

interface IProps {
    visible: boolean

    onClick(): void
}

const BackToTop: React.FC<IProps> = ({visible, onClick}) => {
    const handleClick = useCallback(() => {
        const el = getElById(SCHEDULE_ID);
        if (el) {
            el.scrollTo({top: 0, behavior: 'smooth'});
            onClick();
        }
    }, [onClick]);


    return <div className={styles.backTop} data-visible={visible} onClick={handleClick}>
        <Icon icon={ICON_NAME.ARROW}/>
    </div>;
};

export default BackToTop;