import React, {useState} from 'react';
import {notification, Popconfirm, Spin} from "antd";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {propsConfirm} from "../../../util/varibles/constants";
import {LoadingOutlined} from "@ant-design/icons";
import {DiseaseService} from "../../../util/services/disease";
import {showErrorResponse} from "../../../util/varibles/global";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {IDisease} from "../../../util/varibles/interface";

const antIcon = <LoadingOutlined style={{fontSize: 16, margin: '-8px'}} spin/>

interface IProps {
    data: IDisease

    onSuccess(): void
}

const Delete: React.FC<IProps> = ({data, onSuccess}) => {
    const [loading, setLoading] = useState(false);
    const handleDelete = () => {
        setLoading(true);
        DiseaseService.delete(data.id)
            .then(() => {
                onSuccess();
                notify.success(NotifyCode.S4)([data.name])
                notification.success({message: 'Delete successful'});
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                showErrorResponse('Delete failed', error);
            })
    }

    return <Spin
        indicator={antIcon}
        spinning={loading}
    >
        <Popconfirm
            {...propsConfirm}
            title={`Are you sure delete disease "${data.name}"?`}
            onConfirm={handleDelete}
        >
            <div className='menu-line' data-danger='true'>
                <Icon icon={ICON_NAME.DELETE} danger/>
                <div>Delete</div>
            </div>
        </Popconfirm>
    </Spin>;
};

export default Delete;