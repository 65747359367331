import React, {ReactElement} from 'react';
import {INoteCalender} from "../../../../util/varibles/interface";
import {Tooltip} from "antd";
import styles from './style.module.scss';
import {getRoleNames} from "../../../../util/varibles/constants";

interface IProps {
    notes: INoteCalender[]
    children: ReactElement
}

const Note: React.FC<IProps> = ({notes, children}) => {

    return notes.length > 0
        ? <Tooltip overlayClassName={styles.notes} color='#002C4D' title={<div>
            {notes.map(({id, note, user = {username: '', roles: []}}) => <div
                key={id}
                className={styles.note}
            >
                <div className={styles.message}>{note}</div>
                <div className={styles.user}>{user.username} ({getRoleNames(user.roles)})</div>
            </div>)}
        </div>} placement='bottom'>
            {children}
        </Tooltip>
        : children;
};

export default Note;
