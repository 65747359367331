import React from 'react';
import styles from "./style.module.scss";
import Time from "./Time";
import Checkbox from "../../../../../../components/Basic/Checkbox";

interface IProps {
    isDisable: boolean
    checked: boolean
    location: any
    value: any

    onCheck(item: any, e: any): void

    onChange(value: any): void

    onDelete(id: any): void
}

const ArrivalTime: React.FC<IProps> = (props) => {
    const {isDisable, checked, location, onCheck} = props;
    const {id} = props.location;
    const {arrival_time} = props.value;
    const handleDelete = () => {
        props.onDelete([id])
    }

    const handleChange = (arrival_time: number) => {
        props.onChange({[id]: {arrival_time}})
    }

    return <div {...{
        id: 'time-setting-' + location.id,
        className: styles['arrival-time'],
        'data-disabled': isDisable
    }}>
        <Checkbox label={location.name} disabled={isDisable} checked={checked} onChange={(e) => onCheck(location, e)}/>
        <Time {...{value: arrival_time, isDisable, onChange: handleChange, onDelete: handleDelete}}/>
    </div>;
};

export default ArrivalTime;
