import React, {Component} from 'react';
import styles from './style.module.scss';
import {convertNumberTimeToStringTime, convertStringTimeToNumberTime} from "../../../util/varibles/global";
import {notify, NotifyCode} from "../../../util/varibles/message";

interface IProps {
    value: any
    className?: string
    style?: any
    placeholder?: string

    onChange(value: any): void
}

class InputTime extends Component<IProps> {
    static defaultProps = {
        placeholder: 'In duration (4d 6h 45m)'
    }

    state = {
        durationTxt: '',
        error: ''
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            ...this.state,
            durationTxt: convertNumberTimeToStringTime(this.props.value)
        }
    }

    onChange = (e: any) => {
        const {value} = e.target;
        const durationTxt = value.replace(/ {2,}/g, ' ').toLowerCase();
        const duration = convertStringTimeToNumberTime(durationTxt.trim());
        const state: any = {durationTxt}
        if (duration !== undefined) {
            state.error = '';
        }
        this.setState(state);
        this.props.onChange(duration);
    }

    onBlur = () => {
        const {durationTxt = ''} = this.state;
        let error = ''
        if (durationTxt.length === 0)
            error = notify[NotifyCode.E16]();
        else {
            const duration = convertStringTimeToNumberTime(durationTxt.trim());
            if (duration !== undefined) {
                this.props.onChange(duration);
            } else
                error = notify[NotifyCode.E19]();
        }
        this.setState({error})
    }

    render() {
        const {durationTxt, error} = this.state;
        const {className, placeholder} = this.props;

        return <div className={styles['container']}>
            <input
                className={[styles['input'], className].join(' ')}
                placeholder={placeholder}
                value={durationTxt || ''}
                onChange={this.onChange}
                onBlur={this.onBlur}
                data-error={error.length > 0}
            />
            <div className={styles['error']} dangerouslySetInnerHTML={{__html: error}}/>
        </div>
    }
}

export default InputTime;