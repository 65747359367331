import React from 'react';
import styles from "./style.module.scss";
import {status} from "./Operation";
import {KeyChangeLog, LOG_STATUS} from "../../../../../util/varibles/constants";
import {formatNumber} from "../../../../../util/varibles/global";
import {IDiffUnits} from "../../../../../util/varibles/interface";
import {view} from "./Differences";
import Tasks from "./Tasks";

interface IProps {
    tasks: any
    sub_ops?: IDiffUnits
}

const Units: React.FC<IProps> = (props) => {
    const units = props.sub_ops || {};
    (props.tasks || []).forEach((item: any) => {
        const {new: dataNew, old: dataOld} = item || {};
        const {id: idNew, unit_id: unitIdNew} = dataNew?.sub_operation || dataNew?.sub_operations[0] || {};
        const {id: idOld, unit_id: unitOld} = dataOld?.sub_operation || dataOld?.sub_operations[0] || {};
        if (idNew && units[idNew]?.state !== LOG_STATUS.ADDED && units[idNew]?.state !== LOG_STATUS.DELETE) {
            units[idNew] = {
                ...units[idNew],
                unit_id: unitIdNew,
                state: units[idNew]?.state || LOG_STATUS.UPDATE,
                tasks: [...units[idNew]?.tasks || [], item]
            }
        }
        if (idOld && idNew !== idOld && units[idOld]?.state !== LOG_STATUS.ADDED && units[idOld]?.state !== LOG_STATUS.DELETE) {
            units[idOld] = {
                ...units[idOld],
                unit_id: unitOld,
                state: units[idOld]?.state || LOG_STATUS.UPDATE,
                tasks: [...units[idOld]?.tasks || [], item]
            }
        }
    })

    return <>
        {Object.keys(units).map(id => {
            const {unit_id, state, ...args} = units[id];
            return <div className={styles.difference} key={id}>
                <div className={styles.unit}>
                    <div className={styles.unitId}>Unit {unit_id} {status[state]}</div>
                    {Object.keys(args).map(subKey => {
                        switch (subKey) {
                            case KeyChangeLog.TOTAL_WEIGHT: {
                                const {new: value = 0, old = 0} = args[subKey] || {};
                                const viewOld = Math.round(old / 1000) / 1000 + 't';
                                const viewNew = Math.round(value / 1000) / 1000 + 't';
                                return <div key={subKey}>
                                    {view.simple(subKey, viewOld, viewNew)}
                                </div>
                            }
                            case KeyChangeLog.FISH_AMOUNT: {
                                const {new: value = 0, old = 0} = args[subKey] || {};
                                const viewOld = formatNumber(old);
                                const viewNew = formatNumber(value);
                                return <div key={subKey}>
                                    {view.simple(subKey, viewOld, viewNew)}
                                </div>
                            }
                            case KeyChangeLog.DELIVERY_TYPES: {
                                const {new: value = [], old = []} = args[subKey] || {};
                                const viewOld = old.map(item => `${item.name} (${Math.round(item.total_weight / 1000) / 1000}t)`).join(', ');
                                const viewNew = value.map(item => `${item.name} (${Math.round(item.total_weight / 1000) / 1000}t)`).join(', ');
                                return <div key={subKey}>
                                    {view.simple(subKey, viewOld, viewNew)}
                                </div>
                            }
                            case KeyChangeLog.TASKS: {
                                return <Tasks data={args[subKey] || []}/>
                            }
                            default:
                                return null
                        }
                    })}
                </div>
            </div>
        })}
    </>
};

export default Units;
