import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {loginActions} from "../../../../LoginPage/reducer";
import {Checkbox, Empty, Input, Select, Space, Spin, Tooltip} from "antd";
import styles from './style.module.scss';
import ArrivalTimeForAll from "./ArrivalTimeForAll";
import {SearchOutlined} from "@ant-design/icons";
import ArrivalTime from "./ArrivalTime";
import {AppState} from "../../../../../util/store/store";


const message = {
    disabled: (name: string) => `Remove Arrival time for all ${name} to enable individual setting.`
}

const nameByType = {
    'sitesTime': ['site', 'sites'],
    'factoriesTime': ['factory', 'factories']
}

enum KEY_CONFIG_TYPE {
    ALL,
    NON_CONFIG,
    CONFIG
}

const listOfConfigType = [
    {id: KEY_CONFIG_TYPE.ALL, name: 'All'},
    {id: KEY_CONFIG_TYPE.NON_CONFIG, name: 'Not configure'},
    {id: KEY_CONFIG_TYPE.CONFIG, name: 'Configured'},
]

const createListOfData = (data: any, arrivalTimes: any) => data.reduce((rs: any, item: any) => {
    rs[KEY_CONFIG_TYPE.ALL].push(item);
    if (arrivalTimes[item.id]) {
        rs[KEY_CONFIG_TYPE.CONFIG].push(item);
    } else {
        rs[KEY_CONFIG_TYPE.NON_CONFIG].push(item);
    }
    return rs;
}, {
    [KEY_CONFIG_TYPE.ALL]: [],
    [KEY_CONFIG_TYPE.NON_CONFIG]: [],
    [KEY_CONFIG_TYPE.CONFIG]: []
})

const mapStateToProps = (state: AppState, {type}: IProps) => {
    const {[type]: arrivalTimes = {}} = state.login.user.setting || {};
    return {
        arrivalTimes,
    }
};

interface IProps {
    data: any
    type: 'sitesTime' | 'factoriesTime'
}

interface IPropsGlobal extends IProps {
    arrivalTimes: any
    loading: boolean

    updateUserSetting(payload: any): void
}

const ArrivalTimes: React.FC<IPropsGlobal> = (props) => {
    const {type, arrivalTimes, loading} = props;
    const [data, setData] = useState(createListOfData(props.data, arrivalTimes));
    const [checked, setChecked]: any = useState(new Set());
    const [searchValue, setSearchValue] = useState('');
    const [configType, setConfigType] = useState(KEY_CONFIG_TYPE.ALL);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setData(createListOfData(props.data, arrivalTimes))
    }, [props.data, arrivalTimes]);

    useEffect(() => {
        setOptions((data[configType] || []).filter((item: any) => item.name.toLowerCase().indexOf(searchValue) !== -1))
    }, [data, configType, searchValue])

    const isDisable = !!arrivalTimes.all
    const [shortName, name] = nameByType[type];
    let isDelete = false;
    for (let item of checked) {
        isDelete = !!arrivalTimes[item]
        if (isDelete)
            break;
    }

    const handleChangeAll = (e: any) => {
        const {checked: value} = e.target;
        if (value) {
            setChecked(new Set(props.data.map((item: any) => item.id)));
            setCheckAll(true);
            setIndeterminate(false);
        } else {
            setChecked(new Set());
            setCheckAll(false);
            setIndeterminate(false);
        }
    }

    const handleCheck = (item: any, e: any) => {
        const {checked: value} = e.target;
        const list = new Set(Array.from(checked));
        if (value)
            list.add(item.id);
        else
            list.delete(item.id);
        const checkAll = list.size === data[configType].length;
        setChecked(list);
        setCheckAll(checkAll);
        setIndeterminate(!checkAll && list.size > 0);
    }

    const handleSearch = (e: any) => {
        const {value} = e.target;
        setSearchValue(value.toLowerCase())
    }

    const handleChange = (value: any) => {
        props.updateUserSetting([{[type]: {...arrivalTimes, ...value}}])
    }

    const handleDelete = (values: any) => {
        const data = JSON.parse(JSON.stringify(arrivalTimes || {}));
        values.forEach((id: any) => delete data[id])
        props.updateUserSetting([{[type]: data}])
    }

    return <>
        <Spin spinning={loading}>
            <ArrivalTimeForAll  {...{
                name,
                checked,
                isDelete,
                value: arrivalTimes.all || {},
                onChange: handleChange,
                onDelete: handleDelete,
            }}/>

            <Space.Compact className={styles['container-search']}>
                <Select {...{
                    className: styles['config-type'],
                    value: configType,
                    popupMatchSelectWidth: false,
                    onChange: setConfigType,
                    options: listOfConfigType.map(({id, name}) => ({
                        value: id,
                        label: `${name} (${data[id].length})`
                    }))
                }}/>
                <Input {...{
                    allowClear: true,
                    className: styles.search,
                    placeholder: `Search for ${shortName} name`,
                    suffix: <SearchOutlined style={{fontSize: '18px'}}/>,
                    onChange: handleSearch
                }} />
            </Space.Compact>

            <div className={styles['count']}>
                {checked.size > 0 && <span>Number of selected: {checked.size} </span>}
            </div>

            <div className={styles['control']}>
                <Checkbox {...{
                    disabled: arrivalTimes.all,
                    checked: checkAll,
                    indeterminate,
                    onChange: handleChangeAll
                }}>
                    Name {shortName}
                </Checkbox>
                <div className={styles['right-control']}>Arrival time at {shortName}</div>
            </div>

            <Tooltip title={isDisable ? message.disabled(name) : ''}>
                <div className={[styles['arrival-time-list'], 'scroll-small'].join(' ')}>
                    {options.map((location: any) => <ArrivalTime key={location.id} {...{
                        location,
                        value: arrivalTimes[location.id] || {},
                        isDisable,
                        checked: checked.has(location.id),
                        onCheck: handleCheck,
                        onChange: handleChange,
                        onDelete: handleDelete,
                    }}/>)}
                    {options.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </div>
            </Tooltip>
        </Spin>
    </>;
};

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting,
})(ArrivalTimes);
