import React, {Component} from 'react';
import styles from "./style.module.scss";
import {eventType} from "../../../../../../util/varibles/constants";
import {checkLimit} from "../../../../../../util/varibles/global";
import {IOperation} from "../../../../../../util/varibles/interface";

class Event extends Component<IOperation> {
    render() {
        const {operation} = this.props;
        const {name = ''} = this.props.vessel || {};
        const {event_type, note = ''} = operation;
        const {name: eventName = ''} = eventType[event_type] || {};
        const voyage = [name, `${eventName}${note.length > 0 ? (': ' + note) : ''} `].join(' | ');
        const style: any = {'--width-indent': -(checkLimit(0, undefined, voyage.length - 58) * 7) + 'px'};

        return <>
            <div className={styles['line-info']}>
                <div className='animation-ellipsis'>
                    <div className='animation-ellipsis-text d-flex text-nowrap' style={style}>
                        {voyage}
                    </div>
                </div>
            </div>
        </>;
    }
}

export default Event;
