import React from 'react';
import styles from './style.module.scss';
import ContainerElement from "./ContainerElement";
import {IPropsElement} from "./constants";

interface IProps extends IPropsElement {

}

const Divide: React.FC<IProps> = (props) => {
    return <ContainerElement {...props}>
        <div className={styles.divide}/>
    </ContainerElement>;
};

export default Divide;
