import React, {Component} from 'react';
import styles from "./style.module.scss";
import {listOfTimeType} from "../../constants";
import {aDayMillisecond, aHourMillisecond} from "../../../../../util/varibles/constants";
import {checkLimit} from "../../../../../util/varibles/global";

interface IProps {
    timestamp: any
}

class MessageTime extends Component<IProps> {
    state = {
        timeText: ''
    }
    step: any = null;
    interval: any = null;

    constructor(props: IProps) {
        super(props);
        const timeType = this.getTimeType();
        if (timeType !== -1) {
            const {timestamp} = this.props;
            this.step = listOfTimeType[timeType].getCount(timestamp);
            this.interval = setInterval(this.handleUpdateTime, this.step);
            this.state = {timeText: this.getTimeText(timeType)}
        }
    }

    componentWillUnmount() {
        if (this.interval)
            clearInterval(this.interval)
    }

    handleUpdateTime = () => {
        const timeType: any = this.getTimeType();
        const {timestamp} = this.props;
        const step = listOfTimeType[timeType].getCount(timestamp);

        if (this.step !== step) {
            clearInterval(this.interval);
            this.step = step;
            this.interval = setInterval(this.handleUpdateTime, this.step);
        }

        this.setState({timeText: this.getTimeText(timeType)})
    }

    getTimeType = () => {
        const {timestamp} = this.props;
        if (timestamp === '-')
            return -1;
        const duration = Date.now() - timestamp;
        if (duration < aHourMillisecond)
            return 0;
        else if (duration < aDayMillisecond)
            return 1;
        else
            return 2;
    }

    getTimeText = (timeType: any) => {
        const {textForOne, suffix, step} = listOfTimeType[timeType];
        const {timestamp} = this.props;
        const duration = Date.now() - timestamp;
        const value = checkLimit(1, undefined, Math.round(duration / step))
        return value === 1 ? textForOne : (value + suffix);
    }

    render() {
        const {timeText = ''} = this.state;
        if (timeText.length === 0)
            return '';
        return <span className={styles['time']}>&#8226; {timeText}</span>
    }
}

export default MessageTime;