import React, {useEffect, useState} from 'react';
import {aDayMillisecond, aHourMillisecond} from "../../../../../util/varibles/constants";
import styles from "./style.module.scss";
import {Checkbox, Radio} from "antd";
import callApi from "../../../../../util/api/callApi";
import {urlDevicesService} from "../../../../../util/api/v1";
import LoadingBox from "../../../../../components/LoadingBox";
import {cloneObj} from "../../../../../util/varibles/global";
import {SeaWayPolyline} from "../../../../../contexts/WorldMapContext/Polyline";

const sixHours = 6 * aHourMillisecond;

const options = [
    {label: '6 hrs', value: sixHours},
    {label: '12 hrs', value: 12 * aHourMillisecond},
    {label: '24 hrs', value: aDayMillisecond},
];

const SeaWay: React.FC<{ data: { id: string, name: string, points: any[] } }> = (props) => {
    return <SeaWayPolyline data={props.data} width={3}/>
}

interface IProps {
    vessel_id: string
    position: any
    iotName: string
}

let abortControl: any, flag = false;
const HistoryVessel: React.FC<IProps> = (props) => {
    const {position, vessel_id} = props;
    const [{loading, history}, setHistory] = useState<{ loading: boolean, history: any[] }>({
        loading: false,
        history: []
    })
    const [time, setTime] = useState(0);
    const [seaWay, setSeaWay] = useState<{
        id: string,
        points: any[],
        name: string,
    } | undefined>();

    useEffect(() => {
        return () => {
            if (abortControl)
                abortControl.abort();
        }
    }, []);

    useEffect(() => {
        if (abortControl)
            abortControl.abort();
        flag = false;
        setTime(0);
        setHistory({loading: false, history: []})
    }, [vessel_id])

    useEffect(() => {
        setHistory(prev => {
            const history = cloneObj(prev.history);
            let startPoint = history[0];
            const start_time = Date.now() - aDayMillisecond;
            while (history.length > 0 && startPoint.time < start_time) {
                history.shift();
                startPoint = history[0]
            }
            return {...prev, history}
        })
    }, [position])

    const handleCheckboxChange = (e: any) => {
        const {checked} = e.target;
        if (checked) {
            setTime(options[0].value)
            if (!flag)
                getHistory();
        } else {
            setTime(0)
        }
    }

    const handleRadioChange = (e: any) => {
        const {value} = e.target;
        setTime(value)
        getData(value);
    }

    const getHistory = () => {
        abortControl = new AbortController();
        const {iotName} = props;
        const start_time = Date.now() - aDayMillisecond;
        const end_time = Date.now();
        setHistory(prev => ({...prev, loading: true}))
        callApi(urlDevicesService + '/gps', {
            method: 'POST',
            body: JSON.stringify({start_time, end_time, iot_name: iotName}),
            signal: abortControl.signal
        })
            .then((rs: any) => {
                flag = true;
                setHistory({
                    loading: false,
                    history: rs.map((item: any) => {
                        const [gpsTime] = item.time.split('.');
                        const time = gpsTime[gpsTime.length - 1] === 'Z'
                            ? new Date(gpsTime.replace(' ', 'T')).getTime()
                            : new Date(gpsTime.replace(' ', 'T') + 'Z').getTime();
                        return {...item, time}
                    })
                })
                getData(time)
            })
            .catch(() => {
                setHistory(prev => ({...prev, loading: false}))
            })
    }

    const getData = (value: number) => {
        const {iotName} = props;
        const start_time = Date.now() - value;
        const end_time = Date.now();
        const points = history.reduce((list: {lat: number, lng: number}[], item: any) => {
            const {time, Latitude, Longitude} = item;
            if (time >= start_time && time <= end_time)
                return [...list, {lat: Number(Latitude), lng: Number(Longitude)}]
            return list;
        }, [])
        const {Latitude, Longitude} = props.position;
        points.push({lat: Number(Latitude), lng: Number(Longitude)})
        setSeaWay({id: iotName, points, name: iotName})
    }

    return <div className={styles['history-vessel']} data-is-show={time !== 0}>
        <Checkbox className={styles.checkbox} checked={time !== 0} onChange={handleCheckboxChange}>
            Show vessel track
        </Checkbox>
        <Radio.Group {...{
            value: time,
            disabled: loading,
            options,
            optionType: "button",
            buttonStyle: "solid",
            onChange: handleRadioChange
        }}/>
        {seaWay && <SeaWay data={seaWay}/>}
        {loading && <LoadingBox level='bg' className={styles.loading} loading={loading}/>}
    </div>;
};

export default HistoryVessel;
