import {ACTION_TYPE, aWeekMillisecond, OPERATION_MODE} from "../../util/varibles/constants";
import {
    IFactory,
    ILevelByVessel,
    IOperation,
    IOperations,
    IServiceTask,
    ISite,
    IVessel
} from "../../util/varibles/interface";
import {getKeyWeek, getMondayByKey} from "../../util/varibles/global";
import {datetime} from "../../util/library/datetime";

export const createCurrentTime = (): { weeks: string[] } => {
    const weekNo = datetime().week;
    const year = datetime().weekYear;
    let startTime = getMondayByKey([year, weekNo].join('|')).time,
        finishTime = startTime + 604799999,
        weeks: string[] = [getKeyWeek(startTime)];
    for (let i = 0; i < 6; i++) {
        startTime -= aWeekMillisecond;
        weeks = [getKeyWeek(startTime), ...weeks];
    }
    for (let i = 0; i < 7; i++) {
        finishTime += aWeekMillisecond;
        weeks = [...weeks, getKeyWeek(finishTime)];
    }
    return {weeks};
};

export const CALENDAR_ID = 'calendar';
export const LAYOUT_CALENDER = 'layout-calendar';

export enum SupportType {
    MAIN = 'main',
    SUB = 'sub'
}

export enum TYPE_SUBMIT {
    ALL,
    CURRENT_WEEK,
}

export interface IFishChanges {
    [id: string]: {
        [opId: string]: number
    }
}

export interface IFetching {
    sites: boolean
    confirm: boolean
}

export interface PlanOperationState {
    operations: IOperations
    operationsDelete: { [index: string]: IOperation[] }
    level: ILevelByVessel
    fishChanges: IFishChanges

    beforeCount: boolean
    afterCount: boolean
    indexId: number
    weeks: string[]
    popupClose: boolean

    opMode: OPERATION_MODE

    canceled: IOperation[]
    sites: ISite[]
    factories: IFactory[]
    routeIds: string[]
    vessels: IVessel[]
    harvests: any[]

    serviceTasks: IServiceTask[]

    files: any;
    opFocusId: any

    isFetching: IFetching
    loadingVessels: boolean

    popupOperation: {
        isShow: boolean
        isAllowChange?: boolean
        operationType?: any
        limitOperationTypes?: any
        editMode?: ACTION_TYPE
        values?: {
            vessel?: IVessel
            [id: string]: any
        }
        action?: any
    }
}

export const initialState: PlanOperationState = {
    operations: {},
    operationsDelete: {},
    level: {},
    fishChanges: {},

    beforeCount: false,
    afterCount: false,
    indexId: 1,
    popupClose: false,

    opMode: OPERATION_MODE.PLAN,

    canceled: [],
    sites: [],
    factories: [],
    routeIds: [],
    vessels: [],
    harvests: [],
    serviceTasks: [],

    files: {},
    opFocusId: null,

    isFetching: {
        sites: false,
        confirm: false,
    },
    loadingVessels: false,

    popupOperation: {isShow: false, operationType: null, values: {}},

    ...createCurrentTime()
};

export interface IUpdateOp {
    update?: IOperations
    add?: IOperations
    files?: any
    isCheck?: boolean
    isFocus?: string | number
}