import React, {Component} from 'react';
import {Button, Modal, Select} from "antd";
import styles from './style.module.scss';
import {searchUserFetch} from "../../../../util/services/chat";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import LoadingBox from "../../../../components/LoadingBox";

interface IProps {
    childRef: any
    id: string
    users: any

    onAdd(users: any): void
}

class PopupAddUser extends Component<IProps> {
    state = {
        key: '',
        visible: false,
        options: [],
        users: [],
        loading: false,
        loadingAdd: false
    }

    timeout: any;

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    open = () => {
        this.setState({key: '', visible: true, users: [], options: []})
    }

    close = () => {
        this.setState({visible: false})
    }

    handleSearch = (key: string) => {
        const value = key.trim();
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        if (value.length < 2) {
            this.setState({loading: false, options: []});
            return;
        }

        this.setState({key: value});
        this.timeout = setTimeout(() => {
            this.setState({loading: true, isMore: false});
            new Promise((resolve) => {
                resolve(searchUserFetch({key: value, index: 1}));
            }).then((rs: any) => {
                const {users, next} = rs;
                this.setState({
                    loading: false,
                    options: users.map((item: any) => <Select.Option key={item.id} value={item.id}>
                        {item.username}
                    </Select.Option>),
                    isMore: next
                });
            }).catch(() => {
                this.setState({loading: false});
            })
        }, 250)
    }

    handleSelect = (isSelect: boolean, value: any, e: any) => {
        const {users} = this.state;
        const {children} = e;
        this.setState({
            users: isSelect
                ? [...users, {id: value, name: children}]
                : users.filter((item: any) => item.id !== value)
        })
    }

    handleAdd = () => {
        const {users} = this.state;
        if (users.length === 0) {
            notify.error(NotifyCode.E12, 'Add people failed')();
            return;
        }

        this.props.onAdd(users.map((item: any) => ({id: item.id, username: item.name})));
    }

    render() {
        const {visible, users, options, loading, loadingAdd} = this.state;
        return <Modal
            width={500}
            className={styles['modal']}
            open={visible}
            title={null}
            footer={null}
            centered
            styles={{body: {padding: 0}}}
            closable={false}
            maskClosable={true}
            onCancel={this.close}
        >
            <div className={styles.title}>
                <div data-lev='name'>Add people</div>
                <div className='bt-close' onClick={this.close}/>
            </div>
            <Select
                className='select-mode-tag w-full mb-12'
                placeholder='Select people to join the group'
                showSearch
                mode={'multiple'}
                value={users.map((item: any) => item.id)}
                filterOption={false}
                popupMatchSelectWidth={false}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                notFoundContent={<LoadingBox loading={loading}/>}
                onSearch={this.handleSearch}
                onSelect={(value: any, e: any) => this.handleSelect(true, value, e)}
                onDeselect={(value: any, e: any) => this.handleSelect(false, value, e)}
            >
                {options}
            </Select>
            <div className={styles['footer']}>
                <Button className='bt-default' onClick={this.close}>Cancel</Button>
                <Button className='bt-primary' loading={loadingAdd} onClick={this.handleAdd}>Add</Button>
            </div>
        </Modal>;
    }
}

export default PopupAddUser;