import {ReactNode} from "react";
import createFastContext from "../createFastContext";
import {IUnit} from "../../util/varibles/interface";

interface IState {
    selected: { [id: string]: IUnit }
}

const {
    Provider,
    useStore: useTabBiology,
    setStore: setTabBiology,
    getStore: getTabBiology,
    callEvent: callEventTabBiology
} = createFastContext<IState>({
    state: {
        selected: {}
    }
});

const TabBiologyProvider = ({children, ...args}: { children: ReactNode } & Partial<IState>) => {
    return <Provider props={args}>
        {children}
    </Provider>
}


export {useTabBiology, setTabBiology, getTabBiology, callEventTabBiology, TabBiologyProvider}
