import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {colors} from "../../../../../util/varibles/constants";
import {RouteMode} from "../../../constatns";
import styles from './style.module.scss';
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {notification, Spin} from "antd";
import {IRoute} from "../../../../../util/varibles/interface";
import modal from "../../../../../components/Popup/ModalCustom";
import {routeActions} from "../../../reducer";
import {createPolyline, showErrorResponse} from "../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import {deleteRouteFetch, setRouteDefaultFetch, updateRouteFetch} from "../../../../../util/services/route";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    showId: state.route.showId
});

interface IProps {
    showId: string
    data: any
    index: number

    show(payload: string): void

    changeMode(payload: { mode: RouteMode, route: any }): void

    deleteRoute(payload: string): void

    updateDefault(payload: string): void

    updateRoute(payload: IRoute): void
}

const RouteItem: React.FC<IProps> = (props) => {
    const {showId, data, index} = props
    const [isFocus, setIsFocus] = useState(false);
    const [name, setName] = useState(data.name);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClose);
        return () => {
            document.removeEventListener('click', handleClose);
        };
    }, [isFocus]);


    const handleEditRoute = (route: any) => {
        props.changeMode({mode: RouteMode.EDIT, route: createPolyline(route, props.index, 5)});
    };

    const handleSetDefault = () => {
        if (loading)
            return;
        setLoading(true);
        new Promise(resolve => resolve(setRouteDefaultFetch({router_id: data.id})))
            .then(() => {
                props.updateDefault(data.id)
                notify.success(NotifyCode.S2)([data.name]);
                setLoading(false);
            })
            .catch(error => {
                showErrorResponse('Update failed', error)
                setLoading(false);
            })
    }

    const handleDeleteRoute = () => {
        if (loading)
            return;
        modal.confirm({
            content: `Are you sure you want to delete this route "${data.name}"?`,
            ok: {
                click: () => {
                    setLoading(true);
                    new Promise(resolve => resolve(deleteRouteFetch({router_id: data.id})))
                        .then(({default_id = ''}: any) => {
                            props.deleteRoute(data.id)
                            if(data.defalt) {
                                props.updateDefault(default_id);
                            }
                            notify.success(NotifyCode.S4)([data.name]);
                            setLoading(false);
                        })
                        .catch(error => {
                            showErrorResponse('Delete failed', error)
                            setLoading(false);
                        })
                }
            }
        })
    };

    const handleFocus = (e: any) => {
        if (data.id === showId && !isFocus) {
            setIsFocus(true);
        }
        e.preventDefault();
        return false;
    }

    const handleClose = (e: any) => {
        const {target} = e;
        const el = target.closest(`.${styles['route-container']}`);
        if (el && el.id === 'route-row-' + props.data.id)
            return false;
        closeEdit();
        document.removeEventListener('click', handleClose);
        e.preventDefault();
    }

    const closeEdit = () => {
        setIsFocus(false);
        setName(data.name)
    };

    const handleChange = (e: any) => {
        const {value} = e.target;
        setName(value.trim());
        e.preventDefault();
        return false;
    }

    const handleSave = (e: any) => {
        if (name.length <= 0) {
            notification.error({message: 'Error', description: 'Name is required'});
            setTimeout(() => {
                const el = document.getElementById(data.id + '-input');
                if (el)
                    el.focus();
            }, 300)
            return false;
        }
        const {id: router_id, source_id, destination_id, default: isDefault, type, points} = data;
        const params = {router_id, source_id, destination_id, default: isDefault, type, name, points};
        setLoading(true);
        new Promise(resolve => resolve(updateRouteFetch(params)))
            .then((rs: any) => {
                const data = {...params, ...rs};
                props.updateRoute(data)
                notify.success(NotifyCode.S2)([data.name]);
                setLoading(false);
                setIsFocus(false);
            })
            .catch(error => {
                showErrorResponse('Update failed', error)
                setLoading(false);
            })
        e.preventDefault();
        return false;
    }

    return <Spin spinning={loading}>
        <div
            id={'route-row-' + data.id}
            className={styles['route-container']}
            onClick={() => props.show(data.id)}
            data-selected={data.id === showId}
            data-is-focus={isFocus}
        >
            <div className={styles['route-info']}>
                <div className={styles['route-color']} style={{background: "#" + colors[index % 10]}}/>
                <div className={styles['route-name']}
                     data-distance={data.distance ? `${data.distance} nm` : ''}
                     onClick={handleFocus}
                >
                    {isFocus ? <input id={data.id + '-input'} className={styles['route-input']}
                                      value={name} onChange={handleChange}/> :
                        <span>{data.name}</span>}
                </div>
            </div>
            <div className={styles['route-action']}>
                {data.default ? <div className={styles['route-default']}>Default</div>
                    : <div className={styles['route-set-default']}
                           onMouseDown={handleSetDefault}>
                        Set default
                    </div>}
                <div className={styles['route-icon-edit']} title="Edit router" data-toggle="tooltip"
                     data-placement="top" onMouseDown={() => handleEditRoute(data)}/>
                <div className={[styles['route-icon-delete'], 'mr-2'].join(' ')}
                     onMouseDown={handleDeleteRoute}/>

                <div className={styles['action-edit-name']}>
                    <div className={styles['bt-cancel']} onMouseDown={closeEdit}><CloseOutlined/></div>
                    <div className={styles['bt-save']} onMouseDown={handleSave}><SaveOutlined/></div>
                </div>
            </div>
        </div>
    </Spin>;
};

export default connect(mapStateToProps, {
    changeMode: routeActions.changeMode,
    show: routeActions.show,
    deleteRoute: routeActions.deleteRoute,
    updateDefault: routeActions.updateDefault,
    updateRoute: routeActions.updateRoute
})(RouteItem);
