import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IStateGlobal} from "../../../../../../util/varibles/interface";
import {MessageState, tabMessage} from "../../../../../../pages/MessagePage/constants";
import stylesContainer from "../style.module.scss";
import Message from "../../Message";
import {Spin} from "antd";
import styles from "../../../../../../pages/MessagePage/Component/Operation/style.module.scss";
import {LoginState} from "../../../../../../pages/LoginPage/constants";
import {getSubInfoChatOp} from "../../../../constants";
import {checkLoadMore} from "../../../../../../util/varibles/global";
import {messageActions} from "../../../../../../pages/MessagePage/reducer";
import {CHAT_TYPE, operationType} from "../../../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../../Icon";

const mapStateToProps = (state: IStateGlobal) => ({
    login: state.login,
    message: state.message
})

interface IProps {
    login: LoginState
    message: MessageState

    onOpenConversation(conversation: any): void

    onAddConversation(): void

    getChat(payload: any): void
}

class Operation extends Component<IProps> {
    rootRef: any = React.createRef();

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        const {
            chat: dataNew,
            operationsMore,
            loadingOperation
        } = this.props.message;
        const {chat: dataOld} = prevProps.message;

        if (operationsMore && !loadingOperation && JSON.stringify(dataNew) !== JSON.stringify(dataOld) && this.rootRef.current) {
            checkLoadMore(this.rootRef.current, () => {
                const {operationsIndex} = this.props.message;
                this.props.getChat({index: operationsIndex + 1})
            })
        }
    }

    handleWheel = (e: any) => {
        const {operationsMore, loadingOperation, operationsIndex} = this.props.message;
        if (!operationsMore || loadingOperation)
            return;

        const {deltaY} = e;
        const {scrollTop, scrollHeight, offsetHeight}: any = this.rootRef.current;
        const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;

        if (isScrolledToBottom && deltaY > 0) {
            this.props.getChat({index: operationsIndex + 1})
        }
    }

    render() {
        const {operations, loadingOperation} = this.props.message;

        return <>
            <div className={stylesContainer['title']}>
                <div data-lev='name'>
                    {tabMessage[CHAT_TYPE.OPERATION].name}
                </div>
                <div data-lev='action'>
                    <div className='bt-ghost' data-icon={true} onClick={this.props.onAddConversation}>
                        <Icon icon={ICON_NAME.ADD_2}/>
                    </div>
                </div>
            </div>

            <div ref={this.rootRef} className={stylesContainer['container-content']} onWheel={this.handleWheel}>
                <div className={stylesContainer['content']}>
                    {operations.map((item: any) => {
                        const {group_chat = {}} = item || {};
                        const {id, name, operation_type} = group_chat;
                        const {icon} = operationType[operation_type] || {};
                        return <Message
                            title={<div className={styles['message-title']}>
                                <div>#{name}</div>
                                {getSubInfoChatOp(group_chat)}
                            </div>}
                            avatar={<div className={styles.avatar}>
                                <Icon icon={icon} />
                            </div>}
                            key={id}
                            data={item}
                            onClick={() => this.props.onOpenConversation(item)}
                        />
                    })}
                </div>
                {loadingOperation && <div className={stylesContainer['loading']} data-empty={operations.length === 0}>
                    <Spin/>
                </div>}
            </div>
        </>;
    }
}

export default connect(mapStateToProps, {
    getChat: messageActions.getChatOfOpRequest
})(Operation);