import React, {Component} from 'react';
import {initSensors} from "../../constants";
import {Checkbox, Col, Form, Input, Row, Select} from "antd";

interface IProps {
    formRef: any
    devices: any
}

class LineChartComponent extends Component<IProps> {
    handleChangeDevice = (devices: any) => {
        const sensors: any = [];
        const sensorsValue = this.props.formRef.current.getFieldValue('sensors') || []
        devices.reduce((list: any, item: any) => {
            const device = JSON.parse(item)
            device.sensors.forEach((sensor: any) => {
                if (!list[sensor.name])
                    list[sensor.name] = 1;
                else
                    list[sensor.name]++;
                if (list[sensor.name] === devices.length)
                    sensors.push(sensor)
            })
            return list;
        }, {})
        if (sensors.length === 0)
            this.props.formRef.current.setFieldsValue(initSensors)
        else if (sensorsValue.length > 0) {
            const isExist = sensorsValue.some((item: any) => {
                const {name} = JSON.parse(item);
                return !sensors.find((sensorData: any) => sensorData.name === name)
            })
            if (isExist)
                this.props.formRef.current.setFieldsValue(initSensors)
        }
    }
    handleChangeSensor = () => {
        const {sensors} = this.props.formRef.current.getFieldsValue();
        let yAxis: any[] = [];
        sensors.reduce((list: any, item: any) => {
            const {unit} = JSON.parse(item);
            if (!list[unit]) {
                yAxis.push({min: 0, max: null, format: unit})
                list[unit] = 1;
            }
            return list;
        }, {})
        if (yAxis.length > 0)
            this.props.formRef.current.setFieldsValue({yAxis})
    }

    render() {
        const {devices, formRef} = this.props;
        const {Option} = Select;
        return <>
            <Form.Item label="Device" name="devices" required>
                <Select
                    placeholder='Select device'
                    mode="multiple"
                    onChange={this.handleChangeDevice}
                    filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {devices.map((item: any) =>
                        <Option key={item.device_id} value={JSON.stringify(item)}>{item.device_name}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, current) => prev.devices !== current.devices}>
                {({getFieldValue}) => {
                    const devices = getFieldValue('devices') || []
                    const sensors: any[] = [];
                    devices.reduce((list: any, item: any) => {
                        const device = JSON.parse(item)
                        device.sensors.forEach((sensor: any) => {
                            if (!list[sensor.name])
                                list[sensor.name] = 1;
                            else
                                list[sensor.name]++;
                            if (list[sensor.name] === devices.length)
                                sensors.push(sensor)
                        })
                        return list;
                    }, {})
                    return <Form.Item label="Sensors" name="sensors" required>
                        <Select
                            placeholder='Select sensors'
                            onChange={this.handleChangeSensor}
                            mode="multiple"
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {sensors.map((item: any) =>
                                <Option key={item.name} {...{value: JSON.stringify(item)}}>{item.name}</Option>)}
                        </Select>
                    </Form.Item>
                }}
            </Form.Item>
            {!!formRef.current && <Row gutter={24}>
                <Form.List name="yAxis">
                    {(fields) => fields.map(({key, ...field}, index) => {
                        const {yAxis = []} = formRef.current.getFieldsValue();
                        const {format} = yAxis[index] || {};
                        const labelMin = `Min value ${format.length > 0 ? `(${format})` : ''}`;
                        const labelMax = `Max value ${format.length > 0 ? `(${format})` : ''}`;
                        return [
                            <Col span={12} key={key + '-min'}>
                                <Form.Item key={key + '-min'} {...{
                                    ...field,
                                    required: true,
                                    label: labelMin,
                                    name: [field.name, 'min'],
                                    fieldKey: [key, 'min']
                                }}>
                                    <Input placeholder={labelMin}/>
                                </Form.Item>
                            </Col>,
                            <Col span={12} key={key + '-max'}>
                                <Form.Item key={key + '-max'} {...{
                                    ...field,
                                    required: true,
                                    label: labelMax,
                                    name: [field.name, 'max'],
                                    fieldKey: [key, 'max']
                                }}>
                                    <Input placeholder={labelMax}/>
                                </Form.Item>
                            </Col>
                        ]
                    })}
                </Form.List>
            </Row>}
            <Form.Item name="smooth" valuePropName="checked">
                <Checkbox>Enable smoothing</Checkbox>
            </Form.Item>
        </>;
    }
}

export default LineChartComponent;
