import React, {useEffect} from 'react';
import {Button} from "antd";
import {CaretRightOutlined, PauseOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {selectVessel} from "../../../../util/store/selectors";
import {vesselDetailActions} from "../../reducer";

let intervalPlay: any;

interface IProps {
    loading: boolean
}

const ButtonPlay: React.FC<IProps> = ({loading}) => {
    const replay = useSelector(selectVessel.replay);
    const {isPlay, speed} = replay;
    const dispatch = useDispatch();

    useEffect(() => {
        if (intervalPlay)
            clearInterval(intervalPlay)
        if (isPlay) {
            intervalPlay = setInterval(() => {
                dispatch(vesselDetailActions.addTime(speed * 1000))
            }, 1000)
        }

        return () => {
            if (intervalPlay)
                clearInterval(intervalPlay)
        }
    }, [isPlay, speed, dispatch]);

    const handleClick = () => {
        const state: any = {replay: {...replay, isPlay: !isPlay}};
        if (isPlay) {
            state.isLive = false;
        }
        dispatch(vesselDetailActions.update(state))
    }

    return <Button {...{
        className: 'bt-ghost',
        type: 'primary',
        htmlType: 'submit',
        loading,
        'data-icon': 'true',
        'data-is-play': isPlay,
        icon: isPlay ? <PauseOutlined/> : <CaretRightOutlined style={{fontSize: '18px'}}/>,
        onClick: handleClick
    }}/>;
};

export default ButtonPlay;
