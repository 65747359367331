import {HARVEST_OPTION_KEY} from "../../../PreferencePage/utils";

export const initCustomizeHarvest = [
    HARVEST_OPTION_KEY.ID,
    HARVEST_OPTION_KEY.OPERATION_STATUS,
    HARVEST_OPTION_KEY.SITE_UNIT,
    HARVEST_OPTION_KEY.FACTORY,
    HARVEST_OPTION_KEY.WEATHER,
    HARVEST_OPTION_KEY.SUM_WEIGHT,
    HARVEST_OPTION_KEY.FISH_AMOUNT,
    HARVEST_OPTION_KEY.DURATION
]

export const VESSEL_COLOR = [
    '#78A5A1',
    '#A9BCC6',
    '#DDDDD0',
    '#BBCCDD',
    '#91ADB6',
    '#BECBDA',
];

export const getBgVessel = (color: string) =>
    `repeating-linear-gradient(45deg, rgba(142, 142, 142, .8), rgba(142, 142, 142, .8) 1.7px, ${color} 0, ${color} 5.8px)`

export enum EL_RULE {
    VESSEL = 'vessel',
    AVAILABLE_TIME = 'available-time',
    OPERATION = 'operation',
    CONTRACT = 'contract'
}

export const handleGenerateCustomize = (customize: any) => {
    customize = customize.length > 0 ? customize : initCustomizeHarvest;
    const keys: any = customize.reduce((data: any, item: string) => {
        data[item] = true;
        return data;
    }, {});
    const isSection1 = keys[HARVEST_OPTION_KEY.SUM_WEIGHT] || keys[HARVEST_OPTION_KEY.FISH_AMOUNT];
    const isSection2 = keys[HARVEST_OPTION_KEY.START_TIME]
        || keys[HARVEST_OPTION_KEY.ARRIVAL_TIME_AT_SITE]
        || keys[HARVEST_OPTION_KEY.ARRIVAL_TIME_AT_FACTORY]
        || keys[HARVEST_OPTION_KEY.COMPLETED_TIME]
        || keys[HARVEST_OPTION_KEY.DURATION];
    const isSection3 = keys[HARVEST_OPTION_KEY.DELIVERY_TYPE];
    return {keys, isSection1, isSection2, isSection3}
}

export const calculateWidth = (countVessels: number): number => {
    const {innerWidth} = window;
    let maxColumn = 0, width: any = 0;
    const columnPerPage = Math.floor(innerWidth / 400);
    if (countVessels > 0) {
        maxColumn = columnPerPage > countVessels ? countVessels : columnPerPage;
        width = 100 / maxColumn;
    }
    return width
}
