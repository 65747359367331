import React, {Component} from 'react';
import {IPropsSearchItem} from "../constants";
import {OPERATION_TYPE} from "../../../../../util/varibles/constants";
import Harvest from "./Harvest";
import Treatment from "./Treatment";
import Event from "./Event";
import Transport from "./Transport";

interface IProps extends IPropsSearchItem {
}

class OperationComponent extends Component<IProps> {
    render() {
        const {operation_type} = this.props.data;
        switch (operation_type) {
            case OPERATION_TYPE.HARVEST:
                return <Harvest {...this.props} />
            case OPERATION_TYPE.TREATMENT:
                return <Treatment {...this.props} />
            case OPERATION_TYPE.EVENT:
                return <Event {...this.props}/>
            case OPERATION_TYPE.TRANSPORT:
                return <Transport {...this.props}/>
            default:
                return null;
        }
    }
}

export default OperationComponent;
