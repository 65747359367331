import React, {useCallback, useState} from 'react';
import {Button, Form, Input, Modal, Select} from "antd";
import Header from "../../../../components/Popup/Component/Header";
import {propsModal} from "../../../../util/varibles/constants";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";

const layout = {
    labelCol: {span: 7},
    wrapperCol: {span: 17},
};

const style = {marginBottom: 0, display: 'inline-block', width: '100%'}
const styleItem = {marginBottom: '5px', lineHeight: '36px'};

interface IProps {
    visible?: boolean

    onOk(params: any): void

    onClose(): void
}

const PopupAutoRoute: React.FC<IProps> = (props) => {
    const {visible = true, onClose, onOk} = props;
    const [open, setOpen] = useState(visible);
    const handleClose = useCallback(() => {
        setOpen(false)
        onClose();
    }, [onClose]);

    const handleOk = useCallback((values: any) => {
        onOk(values);
        setOpen(false)
    }, [onOk]);

    return <Modal
        {...propsModal}
        width={400}
        open={open}
        title={<Header title={<div className='font-s2'>Auto route</div>} onClose={handleClose}/>}
        onCancel={handleClose}
    >
        <Form {...layout}
              onFinish={handleOk}
              initialValues={{
                  mode: 'Exact',
                  safe_depth: 10,
                  airDraft: 10
              }}
        >
            <Form.Item label='Mode' style={styleItem}>
                <Form.Item {...{style, name: 'mode'}}>
                    <Select
                        className='select-single'
                        style={{width: '100%'}}
                        options={[
                            {value: 'Exact', label: 'Exact'},
                            {value: 'Greedy', label: 'Greedy'},
                            {value: 'Fast', label: 'Fast'},
                        ]}
                    />
                </Form.Item>
            </Form.Item>
            <Form.Item label='Safe depth' style={styleItem}>
                <Form.Item {...{style, name: 'safe_depth'}}>
                    <Input className='border-r-40 h-36' placeholder='Safe depth' suffix='m'/>
                </Form.Item>
            </Form.Item>
            <Form.Item label='Safe height' style={styleItem}>
                <Form.Item {...{style, name: 'airDraft'}}>
                    <Input className='border-r-40 h-36' placeholder='Safe height' suffix='m'/>
                </Form.Item>
            </Form.Item>
            <div className='d-flex justify-content-end'>
                <Button className='bt-primary mt-10' htmlType="submit">Generate</Button>
            </div>
        </Form>
    </Modal>;
}

interface IOpenAutoRoute {
    onOk(params: any): void
}

export const openAutoRoute = (props: IOpenAutoRoute) => {
    const div = openPopup(<PopupAutoRoute {...{
        onOk: props.onOk,
        onClose: () => div.remove()
    }}/>)
}

export default PopupAutoRoute;