import React from 'react';
import styles from "./style.module.scss";
import {diseaseType} from "../../../varibles/constants";

interface IProps {
    data: any
}

const Site: React.FC<IProps> = ({data}) => {
    const {units, name, diseases = {}} = data;
    const {cases = []} = diseases;
    const {disease = null} = cases[0] || {};
    const {color = '#1B6399'} = diseaseType[disease] || {};
    const style: any = {background: color}
    return <div className={styles['container-info']}>
        <div className={[styles.header, styles['location-name']].join(' ')}>
            <div className={styles['site-icon']} style={style}/>
            <span>{name}</span>
        </div>
        {units && <div className={styles.contact}>
            <div className={styles.unit}>
                <div className={styles.icon}/>
                <span>{units.length + ` {units.length > 1 ? 'Units' : 'Unit'}`} </span>
            </div>
        </div>}
    </div>;
};

export default Site;