import React from 'react';
import {convertPercent, durationTxt, uppercaseFirstLetter} from "../../../../util/varibles/global";
import {Table, Tooltip} from "antd";
import {datetime} from "../../../../util/library/datetime";
import {
    FULL_DATE_FORMAT,
    getOperationStatus,
    OPERATION_TYPE,
    operationType,
    PATHNAME
} from "../../../../util/varibles/constants";
import {getTimeByOpMode} from "../../util/function_operation/constants";
import {IUnit, TOperation} from "../../../../util/varibles/interface";
import styles from "./style.module.scss";
import {getTypesAndUnitOfTreatment} from "../../util";
import {openPage} from "../../../../util/library/Router";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../util/store/selectors";


interface IProps {
    loading: boolean
    ops: TOperation[]

    changeUnit(unit?: IUnit): void
}

const Operations: React.FC<IProps> = ({loading, ops, changeUnit}) => {
    const opMode = useSelector(selectPlan.opMode);
    const column = {
        week: () => ({
            title: <div className='text-center'>Week</div>,
            key: 'week',
            render: (data: any) => {
                const {processes} = data;
                const week = `${datetime(getTimeByOpMode[opMode](processes[0])).week}`;
                return <div className='text-center'>{week.length === 1 ? `0${week}` : week}</div>
            },
        }),
        name: () => ({
            title: 'ID',
            key: 'operation_code',
            render: ({id, operation_code, tenant_id}: TOperation) => {
                return <div className='link' onMouseDown={() => openPage(`${PATHNAME.OPERATION}/${tenant_id}/${id}`)}>
                    #{operation_code}
                </div>
            },
        }),
        type: () => ({
            title: 'Operation type',
            key: 'type',
            render: (data: any) => {
                const {operation_type} = data;
                if (operation_type === OPERATION_TYPE.TREATMENT) {
                    const {types} = getTypesAndUnitOfTreatment(data.tasks);
                    return Object.keys(types).map(key => types[key]).join(', ')
                }

                const {name} = operationType[operation_type] || {};
                return name
            }
        }),
        units: () => ({
            title: 'Unit',
            dataIndex: 'sub_operations',
            key: 'unit',
            render: (data: any) => data.map((item: IUnit, i: number) => {
                return [
                    !!i ? <span key={i}>, </span> : null,
                    <span
                        key={item.id}
                        {
                            ...item.selected
                                ? {className: "whitespace-nowrap font-bold"}
                                : {className: "whitespace-nowrap link font-s4", onClick: () => changeUnit(item)}
                        }
                    >
                        {item.unit_id}
                    </span>
                ]
            }),
        }),
        date: () => ({
            title: 'Date',
            key: 'date',
            render: (data: any) => {
                const {processes} = data;
                const time = getTimeByOpMode[opMode](processes[0]);
                return <span>{datetime(time).format(FULL_DATE_FORMAT)}</span>
            },
        }),
        duration: () => ({
            title: <Tooltip overlayInnerStyle={{width: '380px'}} title={<div>
                <div className='mb-2'>Avg. Duration is the time to treat 1 ton of fish of certain treatment or combined
                    treatments and be calculated by formula:
                </div>
                <div className='font-bold'>(unit: X minute/ton) operation duration/ ∑ total weight of all units within
                    treatment operation
                </div>
            </div>}>
                Avg. duration | Duration
            </Tooltip>,
            key: 'duration',
            render: (data: any) => {
                const {processes, avg_duration} = data;
                const start = getTimeByOpMode[opMode](processes[0]);
                const finish = getTimeByOpMode[opMode](processes[processes.length - 1]);
                return <span>{typeof avg_duration !== 'number' ? 'N/A' : `${durationTxt(avg_duration)}/t`} | {durationTxt(finish - start)}</span>
            },
        }),
        mortality: () => ({
            title: <Tooltip overlayInnerStyle={{width: '380px'}} title={<div>
                <div className='mb-2'>% Increased mortality is the increase of mortality after implementing a specific
                    treatment and be calculated by formula:
                </div>
                <div className='font-bold'>(Avg. of % mortality 14 days after treatment - Avg. of % mortality 14 days
                    before treatment)/ Avg. % mortality 14 days after treatment * 100%
                </div>
            </div>}>
                % Increased mortality
            </Tooltip>,
            key: 'mortality',
            render: (data: any) => {
                const {mortality_increase, sub_operations} = data;
                return sub_operations.map((item: IUnit, i: number) => {
                    const value = convertPercent(item.mortality_increase || mortality_increase);
                    return [
                        !!i ? <span key={i}>, </span> : null,
                        <Tooltip
                            key={item.id}
                            className={`whitespace-nowrap${item.selected ? ' font-bold' : ''}`}
                            title={item.unit_id}
                        >
                            {value}
                        </Tooltip>
                    ]
                })
            }
        }),
        lice: () => ({
            title: <Tooltip overlayInnerStyle={{width: '380px'}} title={<div>
                <div className='mb-2'>% Lice Reduction is the decrease of lice after implementing a specific treatment,
                    and be calculated by formula:
                </div>
                <div className='font-bold'>(∑ lice of 2 weeks before - ∑ Lice of 2 weeks after treatment) : ∑ Lice 2
                    week before * 100%
                </div>
            </div>}>
                % Lice reduction <br/> (Stationary, Mobile, Adult female lice)
            </Tooltip>,
            key: 'lice',
            className: 'min-w-[250px] max-w-[450px]',
            render: (data: any) => {
                const {lice_reduction, sub_operations} = data;

                return sub_operations.map((item: IUnit, i: number) => {
                    const value = item.lice_reduction || lice_reduction;
                    const result = [
                        convertPercent(value?.stationary_lice),
                        convertPercent(value?.mobile_lice),
                        convertPercent(value?.female_lice)
                    ];
                    const count = result.filter(x => x === 'N/A');
                    return [
                        !!i ? <span key={i}>, </span> : null,
                        <Tooltip
                            key={item.id}
                            className={`whitespace-nowrap${item.selected ? ' font-bold' : ''}`}
                            title={item.unit_id}
                        >
                            ({count.length === 3 ? "N/A" : result.join(', ')})
                        </Tooltip>
                    ]
                })
            }
        }),
        status: () => ({
            title: '',
            key: 'status',
            dataIndex: 'status',
            render: (data: any) => uppercaseFirstLetter(getOperationStatus(data))
        })
    }

    return <div className={styles.smallBox}>
        <Table {...{
            rowKey: "id",
            pagination: false,
            columns: [
                column.week(),
                column.name(),
                column.type(),
                column.units(),
                column.date(),
                column.duration(),
                column.mortality(),
                column.lice(),
                column.status(),
            ],
            loading,
            dataSource: ops
        }} />
    </div>;
};

export default Operations;
