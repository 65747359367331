import React, {useCallback, useRef, useState} from 'react';
import {Modal, notification, TimePicker} from "antd";
import styles from "./style.module.scss";
import {datetime} from "../../../../../../util/library/datetime";
import {propContainer, propsModal} from "../../../../../../util/varibles/constants";

interface IProps {
    name: string
    visible: boolean
    checked: any

    onSave(data: any): void

    onClose?(): void

}

const PopupSetArrivalTime: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const {name, checked, onSave, onClose} = props;
    const [arrivalTime, setArrivalTime] = useState(null);
    const inputRef = useRef(null);

    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            onClose();
    }, [onClose])

    const handleSave = useCallback(() => {
        if (arrivalTime == null) {
            notification.error({message: 'Error', description: 'Time is required'})
            return;
        }
        const hour = datetime(arrivalTime).hour;
        const minus = datetime(arrivalTime).minute;
        const data: any = {};
        checked.forEach((item: any) => {
            data[item] = {arrival_time: hour + (minus / 60)}
        });
        onSave(data);
        handleClose();
    }, [arrivalTime, checked, handleClose, onSave]);

    const handleChange = (value: any) => {
        setArrivalTime(value)
    }

    return <Modal {...{
        ...propsModal,
        wrapClassName: styles.container,
        title: null,
        width: 350,
        open: visible,
        onCancel: handleClose
    }}>
        <div className={styles.header}>
            <span>Arrival time for {checked.size} selected {name}</span>
        </div>
        <div className={styles.body}>
            <TimePicker {...{
                ...propContainer,
                allowClear: false,
                ref: inputRef,
                value: arrivalTime,
                className: styles['time-picker'],
                showNow: true,
                minuteStep: 15,
                format: 'HH:mm',
                placeholder: '',
                onChange: handleChange,
            }}/>
        </div>
        <div className={styles.footer}>
            <div className='bt-default' onClick={handleClose}>Cancel</div>
            <div className='bt-primary' onClick={handleSave}>Save</div>
        </div>
    </Modal>;
};


export default PopupSetArrivalTime;
