import React from 'react';
import {IOperation} from "../../../../../util/varibles/interface";
import WrapperOperation from "../WrapperOperation";
import Logo from "../Logo";
import {OPERATION_TYPE, operationType} from "../../../../../util/varibles/constants";
import Icon from "../../../../../components/Icon";
import {IPropsOp} from "../index";

interface IProps extends IPropsOp {
    data: IOperation
}

const Service: React.FC<IProps> = (props) => {
    const {icon} = operationType[OPERATION_TYPE.SERVICE];
    const {site_name, sites = []} = props.data.operation;
    const location = sites.length === 0
        ? site_name
        : sites.map((item: any) => item.name).join(' | ')

    return <WrapperOperation {...props}>
        <Logo>
            <Icon icon={icon}/>
        </Logo>
        <div className='text-nowrap'>{location}</div>
    </WrapperOperation>;
};

export default Service;