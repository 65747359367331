import {api} from "../api/callApi";
import {urlGlobal} from "../api/v1";
import {OPERATION_TYPE} from "../varibles/constants";

interface IDownload {
    start_time: number
    finish_time: number
    field: string
    operation_type: OPERATION_TYPE
    status: string
    ids: any[]
    tenant_ids: string[]
    permission: boolean
    timezone: number
}

interface ISendMail {
    id: string
    tenant_id: string
    vessel_id: string
    recipients: string[]
}

interface IExportOpToPdf {
    vessel_id: string
    id: string
    tenant_id: string
}


export const GlobalService = {
    search: (value: string) => api.get(urlGlobal + '/search/' + value),
    opsToExcel: (params: IDownload) => api.put(urlGlobal + '/excel', params),
    opsToPdf: (params: IDownload) => api.put(urlGlobal + '/pdf', params),
    loginBrantSwatch: () => api.get(urlGlobal + '/barentswatch'),
    sendMail: (params: ISendMail) => api.post(urlGlobal + '/email', params),
    opToPdf: (params: IExportOpToPdf) => api.post(urlGlobal + "/pdf", params)
}

