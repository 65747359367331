import React from 'react';
import styles from "./style.module.scss";
import {cloneObj, formatNumber} from "../../../../../../../util/varibles/global";
import DeliveryTypes from "../DeliveryTypes";
import Operation from "./Operation";
import OpName from "./OpName";
import {TOperation} from "../../../../../../../util/varibles/interface";
import OpInfo from "./OpInfo";
import MortalityRisk from "../../../_Component/MortalityRisk";
import Factory from "../../../_Component/Factory";
import OpTotal from "./OpTotal";
import {EditSubOpProvider} from "../../../../../../../contexts/EditOperationContext/EditSubOperationProvider";
import {getEditOp, useEditOp} from "../../../../../../../contexts/EditOperationContext";
import {getAmountAndWeightFromAllDeliveryTypes} from "../../../../../util";
import DragEl from "./DragEl";

interface IProps {
}

const Operations: React.FC<IProps> = () => {
    const [ops, set] = useEditOp(state => state.ops)
    const get = getEditOp();

    const handleDelete = ({...args}) => {
        const {units} = get();
        const {indexHarvest, indexUnit} = args;
        const result = cloneObj(ops);
        const {sub_operations} = result[indexHarvest];
        const {id, harvest_id = '-', fish_amount, total_weight} = result[indexHarvest].sub_operations[indexUnit];
        const keyStore = id + '|' + harvest_id;
        units[keyStore] = {
            ...units[keyStore],
            fish_amount: units[keyStore].fish_amount + Number(fish_amount),
            total_weight: units[keyStore].total_weight + Number(total_weight)
        }
        if (result.length === 1 && sub_operations.length === 1) {
            set({ops: [], units});
            return;
        } else if (sub_operations.length === 1) {
            result.splice(indexHarvest, 1);
        } else {
            result[indexHarvest].sub_operations.splice(indexUnit, 1);
            result[indexHarvest] = {
                ...result[indexHarvest],
                ...sub_operations.reduce((list: any, item: any) => {
                    const {fish_amount, total_weight} = item;
                    list.fish_amount += fish_amount;
                    list.total_weight += total_weight;
                    return list;
                }, {fish_amount: 0, total_weight: 0}),
            }
        }
        set({ops: result, units});
    }

    const handleUpdateOp = (data: TOperation, index: number, isCalculate?: boolean) => {
        set(({ops, isCalculate: old}) => {
            const result = cloneObj(ops)
            result.splice(index, 1, data)
            return {ops: result, isCalculate: old || isCalculate}
        })
    }

    const handleUpdateUnit = (sub: TOperation['sub_operations'], value: TOperation['sub_operations'][0], index: number, isCalculate?: boolean) => {
        const sub_operations = cloneObj(sub);
        sub_operations.splice(index, 1, value);
        if (isCalculate)
            return getAmountAndWeightFromAllDeliveryTypes(sub_operations)
        return {sub_operations}
    }

    return <div className={styles.operations}>
        <div className={styles.body}>
            {ops.map((harvest, indexHarvest: number) =>
                <div className={styles.containerOp} key={indexHarvest}>
                    <OpName
                        indexHarvest={indexHarvest}
                        harvest={harvest}
                        onUpdate={value => handleUpdateOp({...harvest, ...value}, indexHarvest)}
                    />
                    <OpInfo/>
                    <Operation
                        key={indexHarvest}
                        harvest={harvest}
                        indexHarvest={indexHarvest}
                    >
                        {harvest.sub_operations.map((unit, indexUnit: number) => <div
                                key={unit.id + unit.harvest_id} className={styles.line}
                                data-lev='sub'
                            >
                                <EditSubOpProvider op={harvest} unit={unit} delivery_types={harvest.delivery_types}>
                                    <div data-lev='info'>{[unit.site_name, unit.unit_id].join(', ')} </div>
                                    <div className='text-truncate' data-lev='info' title={unit.harvest_id}>
                                        {unit.harvest_id}
                                    </div>
                                    <div className='justify-end pr-16' data-lev='info'>
                                        {unit.avg_weight < 0 ? '-' : formatNumber(unit.fish_amount)}
                                    </div>
                                    <div className='justify-end pr-16' data-lev='info'>
                                        {formatNumber(Math.round(unit.total_weight / 1000) / 1000)} t
                                    </div>
                                    <MortalityRisk
                                        value={unit.mortality_risk}
                                        onChange={value => handleUpdateOp({
                                            ...harvest,
                                            ...handleUpdateUnit(harvest.sub_operations, {
                                                ...unit,
                                                mortality_risk: value
                                            }, indexUnit)
                                        }, indexHarvest)}
                                    />
                                    {indexUnit === 0 ? <Factory
                                        data={harvest}
                                        onChange={value => handleUpdateOp({...harvest, ...value}, indexHarvest, true)}
                                    /> : <div/>}
                                    <DeliveryTypes
                                        onChange={(value, isCalculate) => handleUpdateOp({
                                            ...harvest,
                                            ...handleUpdateUnit(harvest.sub_operations, {...unit, ...value}, indexUnit, isCalculate)
                                        }, indexHarvest, true)}
                                        onDeleteOp={() => handleDelete({indexHarvest, indexUnit})}
                                    />
                                </EditSubOpProvider>
                            </div>
                        )}
                        <OpTotal
                            op={harvest}
                            onUpdate={value => handleUpdateOp({...harvest, ...value}, indexHarvest, true)}
                        />
                    </Operation>
                </div>)}
        </div>
        {ops.length === 0 && <div className={styles.line} data-lev='empty'>No trip</div>}
        <DragEl/>
    </div>;
};

export default Operations;
