import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {notification} from "antd";
import {getDevicesConfigFetch, sendDevicesFetch} from "../../util/services/device";
import {configurationActions} from "./reducer";
import {selectConfiguration} from "../../util/store/selectors";
import {PayloadAction} from "@reduxjs/toolkit";

function* getDevicesWatcher(args: PayloadAction<{ iotName: string }>): Generator {
    try {
        const devices = yield call(getDevicesConfigFetch, args.payload.iotName);
        yield put(configurationActions.getDevicesSuccess(devices))
    } catch (error) {
        console.log(error);
    }
}

function* sendDeviceWatcher(args: PayloadAction<any>): Generator {
    try {
        const device = args.payload;
        const iotName = yield select(selectConfiguration.iotName);
        yield call(sendDevicesFetch, device);
        yield put(configurationActions.sendDeviceSuccess())
        yield put(configurationActions.getDevicesRequest(iotName))
        notification['success']({
            message: 'Update successful',
            description: 'Configuration of device has been updated.',
        });
    } catch (error) {
        console.log(error);
        yield put(configurationActions.sendDeviceFailure())
    }
}

export default function* configurationSaga() {
    yield all([
        takeEvery(configurationActions.getDevicesRequest.type, getDevicesWatcher),
        takeEvery(configurationActions.sendDeviceRequest.type, sendDeviceWatcher)
    ])
}
