import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IStateGlobal} from "../../../util/varibles/interface";
import {IUser} from "../../../pages/LoginPage/constants";
import styles from './style.module.scss';

const mapStateToProps = (state: IStateGlobal) => ({
    user: state.login.user
})

interface IProps {
    user: IUser
    data: any
}

class MessageAddUser extends Component<IProps> {
    render() {
        const [currentUser] = this.props.user.username.split('@');
        const [username] = this.props.data.username.split('@');
        const {members = []} = this.props.data;
        const userA = username === currentUser ? 'You' : username;
        const userB = members.map((item: any) => {
            const [name] = item.username.split('@');
            return name === currentUser ? 'you' : name
        })
        return <div className={styles['change-user']}>
            {userA} added {userB.join(', ')} to the group
        </div>;
    }
}

export default connect(mapStateToProps, {})(MessageAddUser);
