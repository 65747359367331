import React, {useCallback, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import styles from './style.module.scss';
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {OverviewSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {AppState} from "../../../../util/store/store";

const weeks = [
    {id: 4, name: '4 weeks'},
    {id: 3, name: '3 weeks'},
    {id: 2, name: '2 weeks'},
    {id: 1, name: '1 week'}
];

const key = OverviewSetting.WEEK_PER_PAGE;
const mapStateToProps = (state: AppState) => {
    const weekPerPage = getSetting(state.login.user.setting, key);
    return {
        weekPerPage
    }
};

interface IProps {
    weekPerPage: number
}

const WeekControl: React.FC<IProps> = ({weekPerPage}) => {
    const [selected, setSelected] = useState(weekPerPage);
    const dispatch = useDispatch();

    const onClick = useCallback((value: number) => {
        if (value !== selected) {
            setSelected(value);
            dispatch(loginActions.updateUserSetting([{[key]: value}]))
        }
    }, [selected, dispatch]);

    return <div className={styles.weekControl}>
        {weeks.map(({id, name}) => <div
            key={id}
            className={styles.weekOption}
            data-selected={id === selected}
            onClick={() => onClick(id)}
        >
            {name}
        </div>)}
    </div>;
};

export default connect(mapStateToProps, {})(WeekControl);
