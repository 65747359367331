import {
    OPERATION_TYPE,
    operationType,
    TREATMENT_TYPE,
    treatmentType,
    VESSEL_TYPE
} from "../../../util/varibles/constants";
import {ReactNode} from "react";
import {Symbol} from "../../../util/varibles/template";
import {IRuleForm, IVessel} from "../../../util/varibles/interface";

export const optionOpTypes = {
    [OPERATION_TYPE.HARVEST]: operationType[OPERATION_TYPE.HARVEST],
    [OPERATION_TYPE.EVENT]: operationType[OPERATION_TYPE.EVENT],
    [OPERATION_TYPE.TRANSPORT]: operationType[OPERATION_TYPE.TRANSPORT],
    [OPERATION_TYPE.SERVICE]: operationType[OPERATION_TYPE.SERVICE],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.FRESHWATER].join(',')]: treatmentType[TREATMENT_TYPE.FRESHWATER],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.HYDROGENPEROXIDE].join(',')]: treatmentType[TREATMENT_TYPE.HYDROGENPEROXIDE],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.SALTWATER].join(',')]: treatmentType[TREATMENT_TYPE.SALTWATER],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.ECTOSAN].join(',')]: treatmentType[TREATMENT_TYPE.ECTOSAN],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.FLS].join(',')]: treatmentType[TREATMENT_TYPE.FLS],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.OPTILICER].join(',')]: treatmentType[TREATMENT_TYPE.OPTILICER],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.SALMOSAN].join(',')]: treatmentType[TREATMENT_TYPE.SALMOSAN],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.SORTING].join(',')]: treatmentType[TREATMENT_TYPE.SORTING],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.THERMOLICER].join(',')]: treatmentType[TREATMENT_TYPE.THERMOLICER],
    [[OPERATION_TYPE.TREATMENT, TREATMENT_TYPE.WELLFIGHTER].join(',')]: treatmentType[TREATMENT_TYPE.WELLFIGHTER],
}

export const treatmentName = (value: string = ''): string => {
    const list = value.split(',');
    return list.map(key => {
        const {name = ''} = treatmentType[key] || {};
        return name
    }).join(' & ');
}

export const vesselForm: {
    [key: string]: {
        label: string,
        rules: IRuleForm,
        type?: 'number' | 'select',
        suffix?: string | ReactNode,
        condition?: (vessel: Partial<IVessel>) => boolean
    }
} = {
    name: {label: 'Name', rules: {require: true}},
    type: {label: 'Type', rules: {require: true}, type: 'select'},
    contact: {label: 'Contact number', rules: {}},
    length: {label: 'Length', rules: {requireZero: true}, type: 'number', suffix: 'meter'},
    width: {label: 'Width', rules: {requireZero: true}, type: 'number', suffix: 'meter'},
    speed: {label: 'Speed', rules: {requireZero: true}, type: 'number', suffix: 'knots'},
    capacity: {
        label: 'Capacity',
        rules: {requireZero: true},
        type: 'number',
        suffix: 'tons',
        condition: (vessel) => vessel.type !== VESSEL_TYPE.SERVICE
    },
    harvest_capacity: {
        label: 'Harvesting speed',
        rules: {requireZero: true},
        type: 'number',
        suffix: 'fish/h',
        condition: (vessel) => vessel.type !== VESSEL_TYPE.SERVICE
    },
    factory_discharge: {
        label: 'Factory discharging speed',
        rules: {requireZero: true},
        type: 'number',
        suffix: 'ton/h',
        condition: (vessel) => vessel.type !== VESSEL_TYPE.SERVICE
    },
    waiting_unit_capacity: {
        label: 'Waiting unit discharging speed',
        rules: {requireZero: true},
        type: 'number',
        suffix: 'ton/h',
        condition: (vessel) => vessel.type === VESSEL_TYPE.WELL_BOAT
    },
    cleaning_time: {label: 'Cleaning time', rules: {requireZero: true}, type: 'number', suffix: 'minute'},
    mmsi: {label: 'MMSI', rules: {}},
}

export const storageForm: {
    [key: string]: {
        label: string,
        rules: IRuleForm,
        type?: 'number' | 'select',
        suffix?: string | ReactNode
    }
} = {
    name: {label: 'Tank volume', rules: {require: true}},
    value: {label: 'Volume', rules: {requireZero: true}, type: 'number', suffix: Symbol.m3},
    speed: {label: 'Speed', rules: {requireZero: true}, type: 'number', suffix: 't/h'},
}

export const equipmentForm: {
    [key: string]: {
        label: string,
        rules: IRuleForm,
        type?: 'number' | 'select',
        suffix?: string | ReactNode
    }
} = {
    name: {label: 'Name', rules: {require: true}},
    value: {label: 'Speed', rules: {requireZero: true}, type: 'number', suffix: 't/h'},
}
