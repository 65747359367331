export enum KEY_PREFERENCE {
    PLAN_OPERATION = '2',
    CUSTOMIZE_HARVEST = '2-1',
    CERTAINTY_OPERATION = '2-2',
    SET_ARRIVAL_TIME = '2-3',
    CONFIGURE_PLAN = '2-4',
    VESSEL_CREW_APP = '4',
    MODE_VIEWS = '5',
    SUBSCRIBE_EMAIL = '6'
}
