import React, {ReactNode, useEffect} from "react";
import createFastContext from "../createFastContext";
import {IUnit} from "../../util/varibles/interface";
import {KEY_STORAGE, OPERATION_TYPE} from "../../util/varibles/constants";
import {deleteDragEl, KEY_SPLIT, MODE_DRAG} from "../../pages/PlanOperationPage/BiologyBrowser/constants";
import {planOpActions} from "../../pages/PlanOperationPage/reducer";
import {funcGlobal} from "../../pages/PlanOperationPage/util/function_operation/global";
import {FuncOp} from "../../pages/PlanOperationPage/util/function_operation/constants";
import {useDispatch, useSelector} from "react-redux";
import {openPopup} from "../../components/Popup/Component/WrapperPopup";
import DragView from "../../pages/PlanOperationPage/BiologyBrowser/_Component/DragView";
import {selectDefaultOpType, selectTenantId} from "../../util/store/selectors";
import styles from "../../pages/PlanOperationPage/BiologyBrowser/style.module.scss";
import {useResizeDetector} from "react-resize-detector";

const onChangePosition = (e: any) => {
    const position = [e.screenX, e.screenY].join(KEY_SPLIT);
    localStorage.setItem(KEY_STORAGE.POSITION, position);
}

const createDragEl = () => {
    const units = JSON.parse(localStorage.getItem(KEY_STORAGE.UNITS_SELECTED) || '');
    const el = openPopup(<DragView units={units}/>);
    el.style.position = 'absolute';
    el.style.zIndex = '9999';
    el.style.display = 'none';
    return el;
}

const WrapperBiology = ({children}: { children: ReactNode }) => {
    const {activeDrag, cancelDrag, updateDragEl, unCheckAll} = callEventBiology();
    const get = getBiology();
    const set = setBiology();

    const handleResize = (w = 1, h = 1) => set({width: w, height: h});

    const {ref} = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 1000,
        onResize: handleResize
    });

    useEffect(() => {
        const onStorageUpdate = (e: any) => {
            const {key, newValue} = e;
            switch (key) {
                case KEY_STORAGE.MODE_DRAG: {
                    if (newValue === MODE_DRAG.START) {
                        createDragEl();
                        activeDrag();
                    } else if (newValue === MODE_DRAG.END) {
                        const {dragEl} = get();
                        deleteDragEl(dragEl);
                        cancelDrag();
                    }
                    break;
                }
                case KEY_STORAGE.POSITION: {
                    const [x, y] = (newValue || '').split(KEY_SPLIT);
                    const {screenX} = window;
                    const screenY = window.outerHeight - window.innerHeight + window.screenY;
                    updateDragEl(x - screenX, y - screenY);
                    break;
                }
                case KEY_STORAGE.CLEAR_CHECK_BIOLOGY: {
                    unCheckAll();
                    break;
                }
                default:
                    return null;
            }
        }
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            cancelDrag();
            window.removeEventListener("storage", onStorageUpdate);
        }
    }, []);

    return <div id='biology' className={styles['biology-browser-view'] + ' no-select'} ref={ref}>
        <div className={styles['container-tabs']} id='container-tabs'>
            {children}
        </div>
    </div>
}

interface IState {
    clear?: { [id: string]: () => void },
    dragEl?: HTMLDivElement,
    width: number,
    height: number
}

const {
    Provider,
    useStore: useBiology,
    setStore: setBiology,
    getStore: getBiology,
    callEvent: callEventBiology
} = createFastContext<IState, {
    onDragStart: (e: React.DragEvent, units: { [id: string]: IUnit }, requestType: OPERATION_TYPE[]) => void,
    onDragEnd: (e: React.DragEvent) => void,
    handleDragOver: (e: any) => void,
    handleDrop: (e: any) => void,
    updateDragEl: (x: number, y: number) => void,
    unCheckAll: () => void,
    activeDrag: () => void,
    cancelDrag: () => void
}>({
    state: {
        width: 0,
        height: 0
    }
});

const BiologyProvider = ({children, ...args}: { children: ReactNode } & Partial<IState>) => {
    const dispatch = useDispatch();
    const defaultOpType = useSelector(selectDefaultOpType);
    const userTenantId = useSelector(selectTenantId);

    return <Provider props={args} event={{
        activeDrag: ({event}) => {
            const {handleDragOver, handleDrop} = event();
            document.body.style.overflow = 'hidden';
            document.body.addEventListener('dragover', handleDragOver);
            document.body.addEventListener('drop', handleDrop);
        },
        cancelDrag: ({event}) => {
            const {handleDragOver, handleDrop} = event();
            document.body.style.overflow = '';
            document.body.removeEventListener('dragover', handleDragOver);
            document.body.removeEventListener('drop', handleDrop);
        },
        onDragStart: (e: React.DragEvent, units: { [id: string]: IUnit }, requestType: OPERATION_TYPE[], {
            set,
            event
        }) => {
            localStorage.setItem(KEY_STORAGE.REQUIRE_OPERATION_TYPE, JSON.stringify(requestType));
            localStorage.setItem(KEY_STORAGE.UNITS_SELECTED, JSON.stringify(units));

            const mode = MODE_DRAG.START
            localStorage.setItem(KEY_STORAGE.MODE_DRAG, mode);

            onChangePosition(e);

            const el = createDragEl();
            set({dragEl: el});
            const {activeDrag} = event();
            activeDrag();
        },
        onDragEnd: (e: React.DragEvent, {get, event}) => {
            const target = e.currentTarget as HTMLDivElement;
            target.style.cursor = 'grab';
            const mode = MODE_DRAG.END;
            localStorage.setItem(KEY_STORAGE.MODE_DRAG, mode);
            const {cancelDrag} = event();
            cancelDrag();
            const {dragEl} = get()
            deleteDragEl(dragEl);
        },


        handleDragOver: (e: any, {event}) => {
            const {pageX, pageY} = e;
            const {updateDragEl} = event();
            updateDragEl(pageX, pageY);
            onChangePosition(e);

            e.preventDefault();
        },

        handleDrop: (e: any, {get, event}) => {
            const {dragEl} = get()
            const {unCheckAll} = event();
            deleteDragEl(dragEl);
            dispatch(planOpActions.togglePopupOperation({
                e,
                defaultOpType,
                userTenantId,
                callback: () => funcGlobal[FuncOp.UncheckBiology]({uncheck: unCheckAll})
            }))
        },

        updateDragEl: (pageX: number, pageY: number, {get}) => {
            const {dragEl} = get();
            if (dragEl) {
                dragEl.style.cursor = 'grabbing';
                dragEl.style.display = 'block';
                dragEl.style.left = (pageX - 5) + 'px';
                dragEl.style.top = (pageY - 5) + 'px';
            }
        },

        unCheckAll: ({get}) => {
            const {clear = {}} = get();
            Object.keys(clear).forEach(key => clear[key]())
        }
    }}>
        <WrapperBiology>
            {children}
        </WrapperBiology>
    </Provider>
}


export {useBiology, setBiology, getBiology, callEventBiology, BiologyProvider}

