import React, {Component} from 'react';
import styles from "./style.module.scss";

interface IProps {
    operation: any
    visible: boolean
}

class NameSpot extends Component<IProps> {
    static defaultProps = {
        visible: true
    }

    render() {
        const {tenant_name = ''} = this.props.operation;
        const {visible} = this.props;
        if (!visible) return null;
        return <div className={styles.spot}>
            {tenant_name}
            <span
                ref={(node) => {
                    node?.style.setProperty("color", '#FFF', "important")
                }}
            >
                Spot
            </span>
        </div>
    }
}

export default NameSpot;