import React from 'react';
import styles from "../../../../components/Operation/style.module.scss";
import Id from "../../../../components/Operation/Id";
import OperationStatus from "../../../../components/Operation/OperationStatus";
import Certain from "../../../../components/Operation/Certain";
import SiteUnit from "../../../../components/Operation/SiteUnit";
import Factory from "../../../../components/Operation/Factory";
import Note from "../../../../components/Operation/Note";
import RequireClosedTransport from "../../../../components/Operation/RequireClosedTransport";
import ExternalSite from "../../../../components/Operation/ExternalSite";
import {OP_STATUS, OPERATION_TYPE, SITE_TYPE} from "../../../../util/varibles/constants";
import Weather from "../../../../components/Operation/Weather";
import NameSpot from "../../../../components/Operation/NameSpot";
import SumWeight from "../../../../components/Operation/SumWeight";
import FishAmount from "../../../../components/Operation/FishAmount";
import StartTime from "../../../../components/Operation/StartTime";
import ArrivalTimeAtSite from "../../../../components/Operation/ArrivalTimeAtSite";
import ArrivalTimeAtFactory from "../../../../components/Operation/ArrivalTimeAtFactory";
import CompletedTime from "../../../../components/Operation/CompletedTime";
import Duration from "../../../../components/Operation/Duration";
import {useSelector} from "react-redux";
import {selectOneSetting} from "../../../../util/store/selectors";
import {handleGenerateCustomize} from "../../Plan/Calendar/constants";
import Delay from "../../../../components/Operation/Delay";
import FishDisease from "../../../../components/Location/FishDisease";
import NoteDetail from "../../../../components/Operation/NoteDetail";
import {IOperation} from "../../../../util/varibles/interface";
import Logo from "../../../../components/Operation/Logo";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {HARVEST_OPTION_KEY} from "../../../PreferencePage/utils";
import LiceList from "../../../../components/Operation/LiceList";

interface IProps {
    data: IOperation
    isOwn: boolean
    status: OP_STATUS
}

const HarvestView: React.FC<IProps> = ({data, isOwn, status}) => {
    const {vessel, operation, activity_log} = data;
    const customizeHarvest = useSelector(selectOneSetting(PreferenceSetting.CUSTOMIZE_HARVEST));
    const {keys = {}, isSection1, isSection2} = handleGenerateCustomize(customizeHarvest);
    return <>
        <div className={styles.title}>
            <div className={styles.left}>
                <Logo type={OPERATION_TYPE.HARVEST} status={status}/>
                <Id {...{visible: !!keys[HARVEST_OPTION_KEY.ID], operation, status}}/>
                <OperationStatus {...{visible: !!keys[HARVEST_OPTION_KEY.OPERATION_STATUS], status}}/>
                <Certain {...{visible: !!keys[HARVEST_OPTION_KEY.CERTAIN], operation, status}}/>
                <div className='d-flex'>
                    <SiteUnit {...{visible: !!keys[HARVEST_OPTION_KEY.SITE_UNIT], operation}}/>
                    {(keys['site-unit'] && keys.factory) &&
                        <span className='arrow-right'>&#8594;</span>}
                    <Factory {...{visible: !!keys[HARVEST_OPTION_KEY.FACTORY], operation}}/>
                </div>
            </div>
            <div className={styles.right}>
                <Delay visible={true} operation={operation}/>
                <Note {...{visible: !!keys[HARVEST_OPTION_KEY.NOTE], operation}}/>
                <RequireClosedTransport {...{
                    visible: !!keys[HARVEST_OPTION_KEY.REQUIRE_CLOSED_TRANSPORT],
                    operation,
                    vessel
                }}/>
                <ExternalSite {...{
                    visible: !!keys[HARVEST_OPTION_KEY.EXTERNAL_SITE],
                    type: operation.source_site_type === SITE_TYPE.EXTERNAL
                }}/>
                <Weather {...{visible: !!keys[HARVEST_OPTION_KEY.WEATHER], operation}}/>
                {!isOwn && <NameSpot {...{operation}}/>}
            </div>
        </div>
        <FishDisease diseases={operation.diseases} units={operation.sub_operations} wrapperClass={styles.section}/>
        <LiceList diseases={operation.diseases} count={operation.sites} short wrapperClass={styles.section}/>
        {isSection1 && <div className={styles.section}>
            <SumWeight{...{visible: !!keys[HARVEST_OPTION_KEY.SUM_WEIGHT], operation, vessel}}/>
            <FishAmount{...{visible: !!keys[HARVEST_OPTION_KEY.FISH_AMOUNT], operation}}/>
        </div>}
        {isSection2 && <div className={styles.section}>
            <StartTime {...{
                visible: !!keys[HARVEST_OPTION_KEY.START_TIME],
                current_process: operation.current_process,
                activity_log
            }}/>
            <ArrivalTimeAtSite {...{
                visible: keys[HARVEST_OPTION_KEY.ARRIVAL_TIME_AT_SITE],
                current_process: operation.current_process,
                activity_log
            }}/>
            <ArrivalTimeAtFactory{...{
                visible: !!keys[HARVEST_OPTION_KEY.ARRIVAL_TIME_AT_FACTORY],
                current_process: operation.current_process,
                activity_log
            }}/>
            <CompletedTime {...{
                visible: !!keys[HARVEST_OPTION_KEY.COMPLETED_TIME],
                current_process: operation.current_process,
                activity_log
            }}/>
            <Duration {...{visible: !!keys[HARVEST_OPTION_KEY.DURATION], activity_log}}/>
        </div>}
        <NoteDetail operation={operation} visible={true}/>
    </>;
};

export default HarvestView;
