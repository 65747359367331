import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {widthMinus} from "./constants";
import styles from "./style.module.scss";
import {SHORT_TIME_FORMAT} from "../../../../util/varibles/constants";
import {datetime} from "../../../../util/library/datetime";
import {selectVessel} from "../../../../util/store/selectors";

let intervalTime: any;

const CurrentTime: React.FC = () => {
    const [currentTime, setCurrentTime] = useState(datetime())

    useEffect(() => {
        intervalTime = setInterval(() => setCurrentTime(datetime()), 1000)
        return () => {
            if (intervalTime)
                clearInterval(intervalTime)
        }
    }, []);

    const finishTime = useSelector(selectVessel.finishTime)
    const right = (finishTime - currentTime.time) / 3600000 * widthMinus * 3 + 45;
    if (right > 45)
        return null

    return <div className={styles['current-time']} style={{right: right + 'px'}}>
        <span>{currentTime.format(SHORT_TIME_FORMAT)}</span>
    </div>;
};

export default CurrentTime;