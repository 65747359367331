import React, {Component} from 'react';
import Diseases from "./Diseases";
import {connect} from "react-redux";
import {diseaseActions} from "./reducer";

interface IProps {
    getDiseases(): void
}

class DiseasePage extends Component<IProps> {

    constructor(props: IProps) {
        super(props);
        this.props.getDiseases();
    }

    render() {
        return <div className='m-header bgPage'>
            <div className='header-page'>
                <div className='title-page'>
                    <b>Disease Management</b>
                </div>
            </div>
            <div className='body-page' data-box='true'>
                <Diseases/>
            </div>
        </div>;
    }
}

export default connect(null, {
    getDiseases: diseaseActions.getListRequest
})(DiseasePage);