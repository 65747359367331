import React, {Component} from 'react';
import styles from "./style.module.scss";

interface IProps {
    keyItem: string
    item: any
}

class Property extends Component<IProps> {
    render() {
        const {keyItem, item} = this.props;
        return <div className={styles['property']} key={keyItem}>
            <div>{keyItem}:</div>
            <div>{item[keyItem]}</div>
        </div>;
    }
}

export default Property;
