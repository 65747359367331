import {initialState, IVesselOfUser} from './constants'
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {KEY_STORAGE} from "../../util/varibles/constants";
import {IOperation} from "../../util/varibles/interface";

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        getUserRequest: (state, action?) => {
        },
        getUserSuccess: (state, action) => {
            state.user = action.payload;
        },
        getUserFailure: (state) => {
        },
        loginRequest: (state, action) => {
        },
        logout: (state) => {
        },
        deleteOpOfVessel: (state, action) => {
        },
        getDomainsRequest: () => {
        },
        getDomainsSuccess: (state, action) => {
            state.domains = action.payload;
        },
        updateUserSetting: (state, action) => {
            const [setting, args] = action.payload;
            const {default_vessel = ''} = args || {};
            const {user} = state;
            state.loadingSetting = true;
            state.user = {
                ...user,
                setting: {...user.setting, ...setting},
                default_vessel
            };
        },
        updateLoadingUserSetting: (state) => {
            state.loadingSetting = false;
        },
        addDomain: (state, action) => {
            const {domain} = action.payload;
            const {domains} = state;
            state.domains = [...domains, domain];
        },
        saveBarentsSwatch: (state, action) => {
            const {payload} = action;
            localStorage.setItem(KEY_STORAGE.BARENTS_SWATCH, JSON.stringify(payload));
            state.barentsSwatch = payload;
        },
        updateAccount: (state, action) => {
            const {user} = state;
            state.user = {...user, ...action.payload.user};
        },
        getOpsOfVesselRequest: (state) => {
            state.loadingDashboard = true
        },
        getOpsOfVesselSuccess: (state, action) => {
            const vessels = action.payload;
            state.vessels = vessels.map((item: any) => {
                const {operations, vessel_id, vessel_type, ...args} = item;
                return {
                    ...item,
                    id: vessel_id,
                    type: vessel_type,
                    operations: operations.map((sub: any) => ({
                        ...sub,
                        vessel: {...args, id: vessel_id, type: vessel_type}
                    }))
                }
            })
            state.loadingDashboard = false
        },
        getOpsOfVesselFailure: (state) => {
            state.loadingDashboard = false
        },
        updateOpOfVessel: (state, action: PayloadAction<IOperation>) => {
            const data = action.payload;
            const {id, vessel_id} = data.operation;
            const {vessels} = state;
            state.vessels = vessels.map(item => {
                if (item.vessel_id === vessel_id) {
                    const {operations} = item;
                    return {
                        ...item,
                        operations: operations.map(sub => sub.operation.id === id ? data : sub)
                    }
                }
                return item;
            })
        },
        updateOpsOfVessel: (state, action) => {
            const operations = action.payload;
            const {vessels} = state;

            state.vessels = operations.reduce((rs: IVesselOfUser[], item: IOperation) => {
                const {id, vessel_id} = item.operation;
                const indexVessel = rs.findIndex(vessel => vessel.vessel_id === vessel_id);
                if (indexVessel !== -1) {
                    const {operations, ...vessel} = rs[indexVessel];
                    if (operations.length === 0) {
                        rs[indexVessel].operations = [{...item, vessel}]
                    } else {
                        const indexOperation = operations.findIndex(op => op.operation.id === id);
                        if (indexOperation !== -1) {
                            const data = rs[indexVessel].operations[indexOperation];
                            rs[indexVessel].operations[indexOperation] = {
                                ...data,
                                ...item,
                                operation: {...data.operation, ...item.operation}
                            }
                        }
                    }
                }
                return rs;
            }, JSON.parse(JSON.stringify(vessels)))
        },
        updateLoadingVesselSide: (state, action) => {
            state.loadingDashboard = action.payload
        },
        updateTenant: (state, action) => {
            const data = action.payload;
            const {user} = state;
            state.user = {...user, tenant: {...user.tenant || {}, ...data}}
        }
    },
})

export const loginActions = loginSlice.actions;
export const {updateUserSetting} = loginActions;

const loginReducer = loginSlice.reducer;

export default loginReducer
