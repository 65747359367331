import React, {Component} from 'react';
import styles from "./style.module.scss";
import {ConfigurationState, FORM_TYPES} from "./constants";
import {connect} from 'react-redux';
import FormObject from "./FormObject";
import FormServer from "./FormServer";
import FormTag from "./FormTag";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    configuration: state.configuration
});

interface IProps {
    configuration: ConfigurationState

    formType: FORM_TYPES | any
}

class Form extends Component<IProps> {

    renderForm = () => {
        const {target, device} = this.props.configuration
        const {configuration} = device;
        if (!target || !configuration) return null;
        const {servers} = configuration;
        let props: any = {target};
        switch (target.type) {
            case FORM_TYPES.SERVER: {
                return <FormServer {...props}/>
            }
            case FORM_TYPES.OBJECT: {
                return <FormObject {...props}/>
            }
            case FORM_TYPES.TAG: {
                props = {...props, servers}
                return <FormTag {...props}/>
            }
            default:
                return null
        }
    }

    render() {
        return <div className={styles['form']}>
            {this.renderForm()}
        </div>;
    }
}

export default connect(mapStateToProps, {})(Form);

