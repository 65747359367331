import React from 'react';
import {layout} from "../../PlanOperationPage/Popup/Vessels/constants";
import {Form} from "antd";
import {CONTRACT_TYPE} from "../../../util/varibles/constants";

interface IProps {
    values: any
    formRef: any
    name: string

    onOk(value: any): void
}

const DeclineContract: React.FC<IProps> = (props) => {
    const {values, name} = props
    const contractTypeText = values.type === CONTRACT_TYPE.SINGLE ? 'single operation' : 'fixed lease';

    return <Form {...{
        ...layout,
        className: 'mt-2 mb-10',
        ref: props.formRef,
        name: 'fixed-contract',
        layout: 'vertical',
        onFinish: () => props.onOk(values.statusConfirm),
    }}>
        Are you sure you want to {name} {contractTypeText} contract on {values.vesselName}?
    </Form>;
};

export default DeclineContract