import React from 'react';
import {LAYOUT_CALENDER} from "../../../constants";
import styles from "./style.module.scss";
import {usePlan} from "../../../../../contexts/PlanOperationContext/Plan";

interface IProps {
    calendarWidth: number
}

const Layout: React.FC<IProps> = ({calendarWidth}) => {
    const [isDrag] = usePlan(state => state.isDrag);

    return <div
        id={LAYOUT_CALENDER}
        className={styles['layout-calendar']}
        style={{width: `${calendarWidth}%`}}
        data-is-drag={isDrag}
    />;
};

export default Layout;
