import React, {useCallback, useEffect, useState} from 'react';
import styles from './style.module.scss';
import {FULL_MONTH_YEAR_FORMAT} from "../../../../util/varibles/constants";
import {CALENDAR_ID} from "../../constants";
import {connect} from "react-redux";
import {datetime} from "../../../../util/library/datetime";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    return {
        weeks: state.overview.weeks
    }
};

interface IProps {
    weeks: { key: string, time: number }[]
}

const Month: React.FC<IProps> = ({weeks}) => {
    const [month, setMonth] = useState(datetime().format(FULL_MONTH_YEAR_FORMAT));

    const onScroll = useCallback(() => {
        const el = document.getElementById(CALENDAR_ID);
        if (el) {
            const {scrollWidth, scrollLeft} = el;
            const widthPerWeek = (scrollWidth - 160) / weeks.length;
            const widthPerDay = widthPerWeek / 7;

            const position = Math.floor(scrollLeft / widthPerWeek);
            const day = Math.floor((scrollLeft - (position * widthPerWeek)) / widthPerDay);
            const {time = 0} = weeks[position] || {};
            if (time) {
                const date = datetime(time).add(day, 'day');
                setMonth(date.format(FULL_MONTH_YEAR_FORMAT));
            }
        }
    }, [weeks]);

    useEffect(() => {
        const el = document.getElementById(CALENDAR_ID);
        if (el)
            el.addEventListener('scroll', onScroll);
        return () => {
            if (el)
                el.removeEventListener('scroll', onScroll);
        }
    }, [onScroll]);


    return <div className={styles.month}>
        {month}
    </div>;
};

export default connect(mapStateToProps, {})(Month);
