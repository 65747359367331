import {ElementType} from "../../constants";
import {ELEMENT_BASIC, ELEMENT_DEFINED} from "../../Element/constants";
import {columns, SEPARATOR_VALUE} from "../RightSide/constants";

export enum ELEMENT_MODE {
    BASIC = 'basic',
    DEFINED = 'defined'
}

export const elementTypes = [
    {key: ELEMENT_MODE.BASIC, name: 'Basic'},
    {key: ELEMENT_MODE.DEFINED, name: 'Defined component'}
]

export function initPropertiesElement(type: ElementType) {
    const params = {
        type,
        label: '',
        properties: {}
    }
    switch (type) {
        case ELEMENT_BASIC.HEADER: {
            params.properties = {
                subLabel: '',
                fontSize: 'default',
                textAlign: 'left'
            }
            break;
        }
        case ELEMENT_BASIC.SHORT_TEXT: {
            params.properties = {
                required: true,
                placeHolder: '',
                subLabel: ''
            }
            break;
        }
        case ELEMENT_BASIC.LONG_TEXT: {
            params.properties = {
                required: true,
                placeHolder: '',
                subLabel: '',
                numberOfRows: 2
            }
            break;
        }
        case ELEMENT_BASIC.NUMBER: {
            params.properties = {
                required: true,
                placeHolder: '',
                subLabel: ''
            }
            break;
        }
        case ELEMENT_BASIC.DROPDOWN: {
            params.properties = {
                required: true,
                subLabel: '',
                multipleSelections: false,
                options: '',
                optionDefault: JSON.stringify('')
            }
            break;
        }
        case ELEMENT_BASIC.RADIO: {
            params.properties = {
                required: true,
                subLabel: '',
                options: '',
                optionDefault: JSON.stringify(''),
                numberOfColumns: JSON.stringify(columns[0])
            }
            break;
        }
        case ELEMENT_BASIC.CHECKBOX: {
            params.properties = {
                required: true,
                subLabel: '',
                options: '',
                optionDefault: JSON.stringify([]),
                numberOfColumns: JSON.stringify(columns[0])
            }
            break;
        }
        case ELEMENT_BASIC.DATE: {
            params.properties = {
                required: true,
                separator: SEPARATOR_VALUE.SLASH,
                isCalendarPopup: true,
                isShowTime: false,
                subLabel: ''
            }
            break;
        }
        case ELEMENT_BASIC.TIME: {
            params.properties = {
                required: true,
                minuteStep: 1,
                isTimeRange: false,
                subLabel: ''
            }
            break;
        }
        case ELEMENT_DEFINED.SIGN: {
            params.label = "Sign";
            params.properties = {
                subLabel: '',
                options: JSON.stringify([]),
            }
            break;
        }
        default:
            params.properties = {}
    }
    return params;
}
