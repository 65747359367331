import React, {useCallback, useEffect, useState} from 'react';
import {ICapability} from "../../../../util/varibles/interface";
import {showErrorResponse, uppercaseFirstLetter} from "../../../../util/varibles/global";
import Menu from "../../../../components/Menu";
import {Checkbox} from "antd";
import styles from "../style.module.scss";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import ValueAndClear from "../../../../components/Basic/ValueAndClear";
import LoadingBox, {MessageEmpty} from "../../../../components/LoadingBox";
import {CapabilityService} from "../../../../util/services/capability";

interface IProps {
}

const ServiceCapabilities: React.FC<IProps> = () => {
    const [{loading, capabilities}, setCapabilities] = useState<{ loading: boolean, capabilities: ICapability[] }>({
        loading: false,
        capabilities: []
    });
    const [currentData = [], setStore] = useEditVessel(state => state.vessel?.capabilities);

    useEffect(() => {
        setCapabilities(prev => ({...prev, loading: true}));
        CapabilityService.gets()
            .then(rs => setCapabilities({capabilities: rs, loading: false}))
            .catch(error => {
                showErrorResponse('Get capabilities failed', error);
                setCapabilities(prev => ({...prev, loading: false}))
            });
    }, []);

    const handleClear = useCallback((id: string) => {
        setStore(({vessel}) => ({
            vessel: {
                ...vessel,
                capabilities: (vessel?.capabilities || []).filter(item => item.id !== id)
            }
        }))
    }, [setStore]);

    return <div>
        <Menu
            disabled={loading}
            items={[{
                key: 'option',
                className: 'no-hover w-[160px]',
                disabled: true,
                label: <Checkbox.Group
                    className={styles.tankSubTypes}
                    value={currentData.map(item => item.id)}
                    options={capabilities.map(item => ({
                        label: uppercaseFirstLetter(item.name),
                        value: item.id,
                        data: item,
                    }))}
                    onChange={selected => {
                        const ids = new Set(selected);
                        setStore(({vessel}) => ({
                            vessel: {
                                ...vessel,
                                capabilities: capabilities.filter(item => ids.has(item.id))
                            }
                        }))
                    }}
                />
            }]}
        >
            <div className='flex gap-2 items-center text-[15px] mb-2 font-[600] cursor-pointer leading-[28px] w-[190px]'>
                <span className='whitespace-nowrap'>Select service equipment</span>
                {loading
                    ? <LoadingBox loading={true} spinnerSize={16}/>
                    : <Icon icon={ICON_NAME.ARROW_DOWN} size={13} bg='colorMain'/>}
            </div>
        </Menu>
        <div className='flex items-center gap-[8px]'>
            {currentData.map(item => <ValueAndClear
                key={item.id}
                name={uppercaseFirstLetter(item.name)}
                onClear={() => handleClear(item.id)}
            />)}
            {currentData.length === 0 && <MessageEmpty/>}
        </div>
    </div>;
};

export default ServiceCapabilities;
