import React, {useState} from 'react';
import styles from './style.module.scss';
import {Input} from "antd";

interface IProps {
    weight: [number | null, number | null]

    onChange(value: [number | null, number | null]): void
}

const AvgWeight: React.FC<IProps> = ({weight, onChange}) => {
    const [focus, setFocus] = useState(false);

    const common = {
        suffix: 'gr',
        type: 'number',
        min: 1,
        onFocus: () => setFocus(true),
        onBlur: () => setFocus(false)
    }

    return <div className={styles.wrapper} data-focus={focus}>
        <Input
            variant={'borderless'}
            placeholder="From AVG Weight"
            {...common}
            value={weight[0] || ''}
            onChange={({target: {value}}: any) => onChange([Number(value) && Number(value) > 0 ? Number(value) : null, weight[1]])}
        />
        <div className={styles.divide}/>
        <Input
            variant={'borderless'}
            placeholder="To AVG Weight"
            {...common}
            value={weight[1] || ''}
            onChange={({target: {value}}: any) => onChange([weight[0], Number(value) && Number(value) > 0 ? Number(value) : null])}
        />
    </div>;
};

export default AvgWeight;
