import React from 'react';
import {OPERATION_TYPE} from "../../../../util/varibles/constants";
import Harvest from "./Harvest";
import Treatment from "./Treatment";
import Transport from "./Transport";
import Service from "./Service";
import Event from "./Event";
import {IOperation} from "../../../../util/varibles/interface";
import {useSelector} from "react-redux";
import {selectOpMode} from "../../../../util/store/selectors";

interface IProps {
    isEdit?: boolean
    isOwn: boolean
    isApprove: boolean
    loading: {
        weather: boolean
    }
    data: IOperation
    weather: any

    event: {
        handleUpdateActivityLog(activityLog: any): void

        handleUpdateNote(note: any): void

        handleChangeState(params: any): void

        onClose(): void
    }
}

const Info: React.FC<IProps> = (props) => {
    // const {operationType,   activityLogRoot = []}: any = this.state;
    const {isEdit = false, isOwn, isApprove, weather, loading, event, data} = props;
    const opMode = useSelector(selectOpMode)
    const {operation_type} = data.operation;
    const common = {
        isEdit,
        isOwn,
        isApprove,
        data,
        loading,
        weather,
        opMode,
        ...event
    }

    switch (operation_type) {
        case OPERATION_TYPE.HARVEST: {
            return <Harvest {...common}/>
        }
        case OPERATION_TYPE.TREATMENT: {
            return <Treatment {...common}/>
        }
        case OPERATION_TYPE.TRANSPORT: {
            return <Transport {...common}/>
        }
        case OPERATION_TYPE.SERVICE: {
            return <Service {...common}/>
        }
        case OPERATION_TYPE.EVENT: {
            return <Event {...common}/>
        }
        default:
            return null;
    }
};

export default Info;
