import React, {ReactNode, useCallback, useState} from 'react';
import TreatmentDensity from "../../../VesselManagementPage/PopupEditVessel/Component/TreatmentDensity";
import {useDispatch} from "react-redux";
import {vesselDetailActions} from "../../reducer";
import {IDensityMap, IVessel} from "../../../../util/varibles/interface";
import {VesselService} from "../../../../util/services/vessel";
import {showErrorResponse} from "../../../../util/varibles/global";

interface IProps {
    id?: string
    title?: ReactNode | string
    vessel: IVessel
    value?: IDensityMap
}

const Density: React.FC<IProps> = ({id = '', title, vessel, value = {}}) => {
    const dispatch = useDispatch();
    const {id: vesselId} = vessel;
    const [loading, setLoading] = useState(false);

    const handleSave = useCallback((data: IDensityMap) => {
        setLoading(true);
        VesselService.density(data, vesselId)
            .then(() => {
                dispatch(vesselDetailActions.updateDensity(data));
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Update density failed', error)
            })
    }, [dispatch, vesselId]);

    return <TreatmentDensity
        id={id}
        {...title ? {title: {view: title, source: value}} : {value: value[id]}}
        loading={loading}
        onSave={handleSave}
    />;
};

export default Density;
