import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Modal} from "antd";
import {propsModal, treatmentType} from "../../../../util/varibles/constants";
import Header from "../../../../components/Popup/Component/Header";
import Footer from "../../../../components/Popup/Component/Footer";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import styles from './style.module.scss';
import InputForm from "../../../../components/Basic/InputForm";
import {Symbol} from "../../../../util/varibles/template";
import {cloneObj} from "../../../../util/varibles/global";
import TreatmentSelect from "./TreatmentSelect";
import {IDensityMap, IErrorForm} from "../../../../util/varibles/interface";
import {funcValidation} from "../../../../util/varibles/validation";

type TDensity = [number | '', number | '', number | '']

interface IProps {
    id?: string,
    value?: { [value: string]: [number, number] }
    combine?: { [types: string]: { [avg_weight: string]: [number, number] } }

    onSave(value: IDensityMap): void

    onClose?(): void
}

const PopupAddDensity: React.FC<IProps> = ({id, value = {}, combine, onSave, onClose}) => {
    const [visible, setVisible] = useState(true);
    const [treatmentTypes, setTreatmentTypes] = useState<string>(id || '');
    const [density, setDensity] = useState<TDensity[]>([]);
    const [error, setError] = useState<{ [index: string]: { [key: string]: IErrorForm } }>({});
    const rootRef = useRef<HTMLDivElement>(null);

    const focus = useCallback(() => {
        setTimeout(() => {
            const el = rootRef.current;
            if (el) {
                const child = el.lastElementChild;
                if (child) {
                    const inputRef = child.getElementsByTagName('input')[0];
                    if (inputRef) {
                        inputRef.focus();
                    }
                }
            }
        }, 300)
    }, []);

    useEffect(() => {
        const {[treatmentTypes]: data = {}} = combine || {[treatmentTypes]: value};
        setDensity(Object.keys(data).length > 0
            ? Object.keys(data).map(key => [+key || '', +data[key][0] || '', +data[key][1] || ''])
            : [['', '', '']]
        )
        focus();
    }, [treatmentTypes, focus, combine, value]);

    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            setTimeout(onClose, 300)
    }, [onClose]);

    const handleAdd = useCallback(() => {
        setDensity(prev => [...prev, ['', '', '']]);
        focus();
    }, [focus]);

    const handleChange = useCallback((i: number, data?: TDensity) => {
        setDensity(prev => {
            const result = cloneObj(prev);
            if (data)
                result.splice(i, 1, data);
            else
                result.splice(i, 1);
            return result
        })
    }, []);

    const handleSave = useCallback((value: TDensity[]) => {
        const check = funcValidation.requireZero;
        const validData: TDensity[] = []
        const newError = value.reduce((rs: { [index: number]: { [key: string]: IErrorForm } }, item, i) => {
            const [avgWeight, density] = item;
            const errorAvgWeight = check(+avgWeight);
            const errorDensity = check(+density);
            const result: { [key: string]: IErrorForm } = {};
            let countError = 0;
            if (errorAvgWeight) {
                result[0] = {requireZero: errorAvgWeight};
                countError++;
            }
            if (errorDensity) {
                result[1] = {requireZero: errorDensity};
                countError++;
            }
            if (countError === 1) {
                rs[i] = result;
            } else if (countError === 0) {
                validData.push(item)
            }

            return rs;
        }, {})

        if (Object.keys(newError).length > 0) {
            setError(newError);
            return;
        }
        onSave({
            [treatmentTypes]: validData.reduce((rs: { [id: string]: [number, number] }, item) => {
                rs[item[0]] = [+item[1], +item[2]]
                return rs;
            }, {})
        });

        handleClose();
    }, [handleClose, onSave, treatmentTypes])

    return <Modal
        {...propsModal}
        zIndex={1011}
        width={800}
        open={visible}
        title={<Header className='h-[42px] mb-2' title="Treatment density" onClose={handleClose}/>}
        onCancel={handleClose}
    >
        <div className={styles.body}>
            <div className={styles.title}>
                <div className='text-[15px] mb-3 flex items-center flex-wrap gap-2 h-[32px]'>
                    {combine && <TreatmentSelect value={treatmentTypes} onChange={setTreatmentTypes}/>}
                    {treatmentTypes.length > 0 ? treatmentTypes.split(',').map(key => {
                        const {name} = treatmentType[key] || {};
                        return <div key={key} className='fill-bg-second'>
                            {name}
                        </div>
                    }) : null}
                </div>
                <div className='flex items-center gap-3 mb-2'>
                    <div className={styles.name}>
                        <div>AVG.WEIGHT</div>
                        <div>DENSITY</div>
                        {/*<div>MAX LOAD</div>*/}
                    </div>
                    <div>
                        <Button
                            className="bt-ghost"
                            data-icon={true} size='small'
                            onClick={() => handleAdd()}
                        >
                            <Icon icon={ICON_NAME.ADD_2} size='small'/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className='overflow-auto max-h-full' ref={rootRef}>
                {density.map((item, i) => {
                    const subError = error[i] || {};
                    return <div className='flex gap-3 mb-2' key={i}>
                        <div className={styles.density}>
                            <InputForm
                                inputClassName={styles.densityInput}
                                type='number'
                                label="Avg.weight"
                                hideLabel
                                value={item[0]}
                                error={subError[0]}
                                onChange={data => handleChange(i, [data, item[1], item[2]])}
                                suffix='g'
                            />
                            <InputForm
                                inputClassName={styles.densityInput}
                                type='number'
                                label="Density"
                                hideLabel
                                value={item[1]}
                                error={subError[1]}
                                onChange={data => handleChange(i, [item[0], data, item[2]])}
                                suffix={<>kg/{Symbol.m3}</>}
                            />
                            {/*<InputForm*/}
                            {/*    inputClassName={styles.densityInput}*/}
                            {/*    type='number'*/}
                            {/*    value={item[2]}*/}
                            {/*    onChange={data => handleChange(i, [item[0], item[1], data])}*/}
                            {/*    suffix='t'*/}
                            {/*/>*/}
                        </div>
                        <div>
                            <Button
                                className='bt-ghost'
                                data-icon='true'
                                data-danger={true}
                                onClick={() => handleChange(i)}
                                onKeyDown={e => {
                                    if (i === density.length - 1 && e.code === 'Tab') {
                                        handleAdd();
                                    }
                                }}
                            >
                                <Icon icon={ICON_NAME.DELETE}/>
                            </Button>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <Footer
            className='mt-[30px]'
            ok={{
                text: 'Save',
                click: () => handleSave(density),
            }}
            cancel={{click: handleClose}}
        />
    </Modal>;
};

export default PopupAddDensity;
