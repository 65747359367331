import React, {Component} from 'react';
import styles from './style.module.scss';
import {
    OP_STATUS,
    OPERATION_MODE,
    SHORT_MONTH_DATETIME_FORMAT,
    SHORT_TIME_FORMAT
} from "../../../../../util/varibles/constants";
import Vessel from "../Vessel";
import Time from "./Time";
import {IActivityLog, TOperation} from "../../../../../util/varibles/interface";
import {generateListTime} from "../../constants";
import {datetime} from "../../../../../util/library/datetime";

const classByType = [styles.state, styles.site, styles.factory, styles.state]

interface IProps {
    isEdit: boolean
    activity_log: any
    operation: TOperation,
    vessel: any
    format: any
    opMode: OPERATION_MODE

    handleUpdate(activityLog: any): void
}

function range(start: number, end: number) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

interface IState {
    isDrag: boolean
    timeState: number
    listTime: any
    duration: number
    activity_log: any
}

class ActivityLog extends Component<IProps, IState> {
    state = {
        isDrag: false,
        timeState: -1,
        listTime: [],
        duration: 0,
        activity_log: [],
    }
    activityLog: any = [];
    target = 0;
    time = 0;

    static defaultProps = {
        format: SHORT_MONTH_DATETIME_FORMAT
    }

    constructor(props: IProps) {
        super(props);
        const {activity_log, operation, opMode} = this.props
        const listTime = generateListTime[operation.operation_type](activity_log, opMode, operation)
        this.state = {isDrag: false, timeState: -1, listTime, duration: 0, activity_log}
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {activity_log} = nextProps
        if (JSON.stringify((this.props.activity_log)) !== JSON.stringify(activity_log)) {
            const {operation, opMode} = nextProps
            const listTime = generateListTime[operation.operation_type](activity_log, opMode, operation)
            this.setState({listTime, activity_log});
        }
        return this.state !== nextState
    }

    handleChangeTime = (e: any, state: number) => {
        const {listTime, activity_log}: any = this.state;
        const {newListTime, newActivityLog} = this.updateTime(listTime, activity_log, state, e.valueOf());
        this.setState({listTime: newListTime, activity_log: newActivityLog});
        this.props.handleUpdate(newActivityLog);
    }

    updateTime = (listTime: any, activity_log: IActivityLog[], state: number, newTime: number) => {
        const oldTime = listTime[state].time;
        const duration = newTime - oldTime;
        const {operation, opMode} = this.props;
        let limit = 0;
        for (let i = 0; i < state; i++) {
            limit += (activity_log[i + 1].est_start_time - activity_log[i].est_start_time);
        }

        const newActivityLog = activity_log.reduce((list: any, item: any, index: number) => {
            if (index >= state) {
                return [...list, {...item, est_start_time: item.est_start_time + duration}];
            }
            const limitTime = newTime - limit;
            if (index < state && limit)
                limit -= (activity_log[index + 1].est_start_time - activity_log[index].est_start_time);
            if (item.est_start_time >= limitTime)
                return [...list, {...item, est_start_time: limitTime}];
            return [...list, item];
        }, [])

        const newListTime = generateListTime[operation.operation_type](newActivityLog, opMode, operation)
        return {newListTime, newActivityLog}
    }

    disabledDate = (current: any, prev: any) => {
        return prev && current < datetime(prev.time).startOf('day').time;
    }

    disabledTime = (current: any, prev: any) => {
        if (current && prev) {
            const currentDate = datetime(current).startOf('day').time;
            if (currentDate !== datetime(prev.time).startOf('day').time)
                return false;

            const hour = datetime(prev.time).hour;
            const hourCurrent = current.get('hour');

            const disable: any = {
                disabledHours: () => range(0, hour),
            }
            if (hourCurrent === hour) {
                const minus = datetime(prev.time).minute;
                disable.disabledMinutes = () => range(0, minus + 1)
            }
            return disable
        }
        return false
    }

    render() {
        const {vessel, operation, activity_log} = this.props;
        const {status} = operation
        const {listTime}: any = this.state;
        const isEdit = this.props.isEdit && !(status === OP_STATUS.PROCESSING || status === OP_STATUS.FINISHED)
        const start = listTime[0].time;
        const finish = listTime[listTime.length - 1].time;
        const format = new Date(start).getDate() !== new Date(finish).getDate()
            ? SHORT_MONTH_DATETIME_FORMAT
            : SHORT_TIME_FORMAT;
        const style = {
            gridTemplateColumns: `repeat(${listTime.length}, 1fr)`
        }
        return <>
            <div className={styles['info-route']}>
                <div className={styles['route-time']}>
                    {vessel && <Vessel {...{vessel, operation, max_point: activity_log.length}}/>}
                    {listTime.map((item: any, index: number) => <div key={index} {...{
                        className: classByType[item.type],
                        style: {left: item.left},
                        title: item.title
                    }}/>)}
                    <div className={styles['route-line']}/>
                </div>
            </div>
            <div className={styles['container-times']}>
                <div className={styles['container-time-picker']} style={style}>
                    {listTime.map((item: any, i: number) =>
                        <Time key={i} {...{
                            item,
                            isEdit,
                            format,
                            handleChangeTime: (e: any) => this.handleChangeTime(e, i),
                            disabledDate: (current: any) => this.disabledDate(current, listTime[i - 1]),
                            disabledTime: (current: any) => this.disabledTime(current, listTime[i - 1]),
                        }}/>
                    )}
                </div>
            </div>
        </>;
    }
}

export default ActivityLog
