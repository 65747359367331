import React from 'react';
import Note from "../../../../components/Popup/PopupOpInfo/Component/Note";
import {opDetailActions} from "../../reducer";
import {IOperation} from "../../../../util/varibles/interface";
import {useDispatch} from "react-redux";

interface IProps {
    note: string
    data: IOperation
    isOwn: boolean
}

const ViewNote: React.FC<IProps> = ({data, note, isOwn}) => {
    const dispatch = useDispatch();

    const handleUpdate = (note: string) => {
        dispatch(opDetailActions.updateOp([{...data, operation: {...data.operation, note}}]))
    }

    if ((note || '').trim().length === 0)
        return null;

    return <div>
        <Note {...{operation: data.operation, note, isEdit: isOwn, handleUpdate}}/>
    </div>;
};

export default ViewNote;