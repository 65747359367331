import {ELEMENT_BASIC, ELEMENT_DEFINED, ELEMENT_PAGE} from "./Element/constants";
import {IChecklist, IServiceTask, IVessel} from "../../util/varibles/interface";

export type ElementType = ELEMENT_BASIC | ELEMENT_PAGE | ELEMENT_DEFINED

export interface IElement {
    type: ElementType
    label: string
    properties: any
}

export interface ChecklistState {
    checklists: IChecklist[]
    vessels: IVessel[],
    serviceTasks: IServiceTask[]

    loadingServiceTasks: boolean
    loading: {
        checklists: boolean
        deleteChecklist: boolean
        assignUsingBy: boolean
    }
}

export const initialState: ChecklistState = {
    checklists: [],
    vessels: [],
    serviceTasks: [],

    loadingServiceTasks: false,

    loading: {
        checklists: false,
        deleteChecklist: false,
        assignUsingBy: false
    }
};
