import React, {useCallback, useState} from 'react';
import styles from './style.module.scss';
import Header from "./Header";
import {ISchedule} from "../../util/varibles/interface";
import {aWeekMillisecond, FULL_DATE_FORMAT, YEAR_WEEK_FORMAT} from "../../util/varibles/constants";
import Week from "./Week";
import Loading from "./Loading";
import NoData from "./NoData";
import Diseases from "./Diseases";
import BackToTop from "./BackToTop";
import {getElById} from "../../util/varibles/global";
import {datetime} from "../../util/library/datetime";

export const SCHEDULE_ID = 'schedule'

interface IProps {
    time: number[]
    source: ISchedule[]
    loading?: boolean
    isFull?: boolean
    isFilter?: boolean

    loadMore?(): void
}

const Itinerary: React.FC<IProps> = (props) => {
    const {source, isFull= true, isFilter, loading = false, loadMore} = props;
    const [start, finish] = props.time;
    const [isTop, setIsTop] = useState(true);

    const handleWheel = useCallback((e: any) => {
        const el = getElById(SCHEDULE_ID);
        if (!el)
            return;

        const {scrollTop, scrollHeight, offsetHeight} = el;
        const {deltaY} = e;
        setIsTop((scrollTop + deltaY) <= 30);

        if (!loadMore || loading || isFull || isFilter)
            return;

        const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;
        if (isScrolledToBottom && deltaY > 0) {
            loadMore();
        }
    }, [loadMore, loading, isFull, isFilter])

    let currentPoint = start, weeks: any[] = [], schedules = [...source];
    const diseases: any = {};

    while (currentPoint <= finish) {
        const key = datetime(currentPoint).format(YEAR_WEEK_FORMAT);
        const weekNo = datetime(currentPoint).week;
        const year = datetime(currentPoint).weekYear
        const {list, rest} = schedules.reduce((rs: any, item) => {
            const subKey = datetime(item.time).format(YEAR_WEEK_FORMAT);
            if (key === subKey) {
                const date = datetime(item.time).format(FULL_DATE_FORMAT);
                rs.list[date] = [...rs.list[date] || [], item]
            } else
                rs.rest.push(item);
            return rs;
        }, {list: {}, rest: []});
        schedules = [...rest];

        weeks = [{
            key,
            weekNo,
            year,
            list
        }, ...weeks];
        currentPoint += aWeekMillisecond
    }

    if (weeks.length > 0)
        weeks[0].finish = finish;

    const body = weeks.map((item: any) => <Week
        key={item.key}
        weekNo={item.weekNo}
        year={item.year}
        finish={item.finish}
        source={item.list}
        setDisease={(point: any) => {
            const {status} = point;
            const {type, start_time, finish_time} = point.disease;
            const key = [type, point.name, start_time, finish_time].join('/');
            diseases[key] = {...diseases[key] || {}, start_time, finish_time, [status]: point.index, type}
        }}
    />)

    return <div className={styles.wrapper}>
        <div id={SCHEDULE_ID} className={styles.schedule} onWheel={handleWheel}>
            <Header/>
            {body}
            {(weeks.length === 0 && !loading) && <NoData/>}
            <Diseases diseases={diseases}/>
            <Loading loading={true}/>
        </div>
        <BackToTop visible={!isTop} onClick={() => setIsTop(true)}/>
    </div>;
};

export default Itinerary;
