import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';

interface IProps {
    target: any
}

const NavUnderline: React.FC<IProps> = ({target}) => {
    const [style, setStyle] = useState({left: '0', width: '0'});

    useEffect(() => {
        setTimeout(() => {
            const el = document.getElementById('tab-' + target);
            const {offsetLeft = 0, clientWidth = 0} = el || {};
            setStyle({left: offsetLeft + 'px', width: clientWidth + 'px'})
        }, 100);
    }, [target]);

    return <div
        className={styles.underline}
        style={style}
        data-tab={target}
    />;
};

export default NavUnderline;
