import React from 'react';
import FishDisease from "../../../../../components/Location/FishDisease";
import {ISite} from "../../../../../util/varibles/interface";
import {UnitModel} from "../../../BiologyBrowser/constants";

interface IProps {
    site: ISite
}

const SiteDiseases: React.FC<IProps> = ({site}) => {
    return <FishDisease
        wrapperClass='h-[28px] flex items-center'
        diseases={[site.diseases]}
        units={site.units.map(item => UnitModel(item, site))}
    />;
};

export default SiteDiseases;