import React, {useMemo} from 'react';
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import Density from "./Density";
import TreatmentDensity from "./TreatmentDensity";

interface IProps {
}

const CombineDensity: React.FC<IProps> = () => {
    const [density = {}, setStore] = useEditVessel(state => state.vessel?.density_map);
    const value = useMemo(() => Object.keys(density || {}).reduce((rs: string[], key) => {
        const value = key.split(',');
        return value.length > 1 ? [...rs, key] : rs;
    }, []), [density]);

    return <div>
        <TreatmentDensity
            title={{
                view: <div className='link font-[600] ml-[-10px]'>
                    Define combined treatments’ density
                </div>,
                source: density,
            }}
            onSave={value => setStore(({vessel}) => {
                const {density_map = {}} = vessel || {}
                return {vessel: {...vessel, density_map: {...density_map, ...value}}}
            })}
        />
        <div className='flex flex-col gap-[8px] mt-3'>
            {value.map(item => <Density key={item} id={item}/>)}
        </div>
    </div>;
};

export default CombineDensity;