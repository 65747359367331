import callApi, {api} from "../api/callApi";
import {OPERATION_TYPE, PROCESS_TYPE} from "../varibles/constants";
import WSService from "../../web-socket/web-socket-service";
import {IActivityLog, ILocation, IRoundPlan, TTaskTreatment} from "../varibles/interface";
import {urlServer} from "../api/v1";

const urlBase = urlServer + '/operation'

interface IOperationConfirm {
    delete_operations: string[]
    vessels: {
        vessel_id: string
        operations: any[]
    }
}

export function createOperationsFetch(body: IOperationConfirm) {
    const connection_id = WSService.getConnectId();
    return callApi(urlBase + '/confirm', {
        method: 'POST',
        body: JSON.stringify({...body, connection_id}),
    })
}

export function saveOperationsFetch(body: IOperationConfirm) {
    const connection_id = WSService.getConnectId();
    return callApi(urlBase + '/confirm', {
        method: 'PUT',
        body: JSON.stringify({...body, connection_id}),
    })
}

export function updateActivityLogFetch(body: any, id: any) {
    return callApi(urlBase + "/" + id, {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export function getScheduleOfWeeksFetch(start_time: number, finish_time: number) {
    return callApi(urlBase + '/history', {
        method: 'PUT',
        body: JSON.stringify({
            start_time,
            finish_time
        })
    });
}

export function getScheduleOfWeeksFetch2(start_time: number, finish_time: number, abort?: AbortController) {
    return callApi(urlBase + '/history', {
        method: 'POST',
        body: JSON.stringify({
            start_time,
            finish_time
        }),
        signal: abort?.signal
    });
}

export function deleteOperationFetch(orderId: string) {
    const connection_id = WSService.getConnectId();
    return callApi(urlBase + `/${orderId}`, {
        method: 'DELETE',
        body: JSON.stringify({connection_id})
    })
}

export function getOperationAtDashboardFetch() {
    return callApi(urlBase + "/dashboard", {
        method: "GET"
    })
}

interface IParamGetOperationWeather {
    operation_type: OPERATION_TYPE
    source_id?: string
    destination_id?: string
    pois: ILocation[]
    activity_log: {
        est_start_time: number
    }[]
}

export function getOperationWeatherFetch(param: IParamGetOperationWeather) {
    return callApi(urlBase + '/weather', {
        method: 'POST',
        body: JSON.stringify(param)
    })
}

export function updateOperationNoteFetch(param: any, operationId: string) {
    return callApi(urlBase + '/note/' + operationId, {
        method: 'PUT',
        body: JSON.stringify(param)
    })
}

interface IUpdateOperationStatus {
    operation_id: string,
    tenant_id: string,
    status: string
}

export function updateOperationStatusFetch(params: IUpdateOperationStatus) {
    const connection_id = WSService.getConnectId();
    return callApi(urlBase + '/update', {
        method: 'PUT',
        body: JSON.stringify({...params, connection_id})
    })
}

interface IGetOpsForDelete {
    operation_ids: string[]
    vessels: {
        vessel_id: string
        vessel_owner_id: string
    }[],
    start_time: number
    finish_time: number
}

interface IAddDelayTime {
    operation_id: string
    tenant_id: string
    time_late: number
    step: number
}

interface IGetActivityLogParams {
    is_weather: boolean
    is_activity_log: boolean
    is_waiting_unit: boolean

    vessel_id: string
    router_id: string

    priority: number
    est_start_time: number
    operation_type: number
    total_amount: number
    total_weight: number

    start_place: {
        id: string
        field: string
        latitude: number
        longitude: number
    },
    source: {
        id: string
        time: number
        latitude: number
        longitude: number
    },
    destination: {
        id: string
        time: number
        latitude: number
        longitude: number
    }
}

export interface IGetTanks {
    vessel_id: string
    units: {
        id: string
        ave_weight: number
        total_weight: number
        tanks: any[],
        process_type: PROCESS_TYPE
        density_percent?: number
    }[]
    after_round?: {
        [round_id: string]: any
    }
    start_time: number
}

interface IParamsAutoPan {
    vessels: string[],
    harvests: {
        harvest_id: string,
        site_id: string,
        factory_id: string,
        unit_id: string,
        avg_weight: number,
        fish_amount: number,
        packing_date: number
    }[]
}

interface IResultActivityLog {
    activity_log: IActivityLog[]
    router_id: string
    prev_op_router_id: string
    support_vessels: any[]
    tasks: TTaskTreatment[]
}

export const OpService = {
    detail: (tenant_id: any, id: any) => api.get(urlBase + '/' + tenant_id + '/' + id),
    autoPlan: (body: IParamsAutoPan) => api.post(urlBase + '/plan', body),
    getCanceled: () => api.post<{ operations: any }>(urlBase + '/cancel'),
    activityLog: (body: IGetActivityLogParams, controller?: AbortController) => api.post<IResultActivityLog>(urlBase + '/activity_log', body, {signal: controller ? controller.signal : null}),
    tanks: (body: IGetTanks, controller?: AbortController) => api.post<{
        round_map: { [round_id: string]: IRoundPlan }
        duration: number
    }>(urlBase + '/tank', body, {signal: controller ? controller.signal : null}),
    addDelay: (body: IAddDelayTime) => {
        const connection_id = WSService.getConnectId();
        return api.put(urlBase + '/update/ongoing', {...body, connection_id})
    },
    getOpsForDelete: (body: IGetOpsForDelete) => {
        const connection_id = WSService.getConnectId();
        return api.post(urlBase + '/delete', {...body, connection_id})
    },
    clearOps: (body: any, controller?: AbortController) => api.post(urlBase + '/ids', body, {signal: controller ? controller.signal : null})
}
