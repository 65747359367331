import React, {useState} from 'react';
import Icon, {ICON_NAME} from "../../../components/Icon";
import {ILocation} from "../../../util/varibles/interface";
import Menu from "../../../components/Menu";
import {PoiService} from "../../../util/services/poi";
import {useDispatch} from "react-redux";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";
import modal from "../../../components/Popup/ModalCustom";
import styles from '../style.module.scss';
import {locationActions} from "../../../util/store/location/reducer";

interface IProps {
    data: ILocation

    handleEdit(data: ILocation): void
}

const Action: React.FC<IProps> = ({data, handleEdit}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = () => {
        if (loading)
            return;
        modal.confirm({
            content: `Are you sure delete ${data.name}?`,
            ok: {
                click: () => {
                    setLoading(true)
                    new Promise(resolve => resolve(PoiService.delete(data.id)))
                        .then(() => {
                            setLoading(false);
                            notify.success(NotifyCode.S4)([data.name]);
                            dispatch(locationActions.delete(data.id))
                        })
                        .catch(error => {
                            setLoading(false);
                            showErrorResponse(notify[NotifyCode.E36]([data.name]), error)
                        })
                }
            }
        })
    }

    return <div className={styles.action}>
        <Menu
            disabled={loading}
            placement='bottomRight'
            items={[
                {
                    key: 'edit',
                    label: <div className='menu-line' onClick={() => handleEdit(data)}>
                        <Icon icon={ICON_NAME.EDIT}/>
                        <div data-lev='name'>Edit</div>
                    </div>
                },
                {
                    key: 'delete',
                    loading,
                    label: <div className='menu-line' data-danger={true} onClick={handleDelete}>
                        <Icon icon={ICON_NAME.DELETE}/>
                        <div data-lev='name'>Delete</div>
                    </div>
                }
            ]}>
            <div className='three-dot'/>
        </Menu>
    </div>;
};

export default Action;