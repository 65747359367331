import React from 'react';
import styles from "./style.module.scss";
import CurrentTimeLine from "./CurrentTimeLine";
import {datetime} from "../../../../../util/library/datetime";
import {HEIGHT_PER_DAY} from "../Operations/constants";
import {SHORT_DAY_DATE_MONTH_FORMAT} from "../../../../../util/varibles/constants";
import {getKeyWeek} from "../../../../../util/varibles/global";

interface IProps {
    startTime: number
    finishTime: number
}

const Weeks: React.FC<IProps> = ({startTime, finishTime}) => {

    let key = startTime, weekEl: any = [];
    const dates = [], calendar = [];
    while (key < finishTime) {
        const style = {height: `${HEIGHT_PER_DAY}px`};
        weekEl.push(
          <div key={key} {...{id: `${key}`, className: styles['day-info'], style}} >
              <span>{datetime(key).format(SHORT_DAY_DATE_MONTH_FORMAT)}</span>
          </div>
        )
        if (datetime(key).day === 6) {
            dates.push(<div key={'week_' + getKeyWeek(key)} className={styles.week}>
                <div className={styles['container-week-no']}>
                    <span className={styles['week-no']}>{`Week ${datetime(key).week}`}</span>
                </div>
                {weekEl}
            </div>)
            weekEl = [];
        }
        calendar.push([
            <div key={key} {...{className: styles.day, id: `${key}`, 'data-date': key, style}}/>,
            <div key={key + 'line'} {...{className: styles['day-line']}} data-line-type='date'/>
        ]);
        key = datetime(key).add(1, 'day').time;
    }

    return <>
        <div className={styles.weeks}>
            {dates}
        </div>
        <CurrentTimeLine {...{startPoint: startTime}} />
    </>;
};

export default Weeks;