import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IVessel} from "../../util/varibles/interface";
import styles from "./style.module.scss";
import ConfigurationPage from "../ConfigurationPage";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    vessel: state.vesselDetail.vessel
});

interface IProps {
    vessel: IVessel
}

class Configuration extends Component<IProps> {

    render() {
        const {vessel} = this.props;
        if (!vessel || !vessel.iot_name)
            return null

        return <div className={styles['config-device']}>
            <ConfigurationPage {...{iotName: vessel.iot_name}} />
        </div>;
    }
}

export default connect(mapStateToProps, {})(Configuration);
