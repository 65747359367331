import WebSocket from 'isomorphic-ws';
import {urlWebSocket} from "../util/api/v1";
import {ACTION_SOCKET, SOCKET_FUNC, SOCKET_LEV} from "./constants";

let wsService: WSService, messageListener: any = {}
const waitingTime = 30000;

export interface IResultSocket {
    function: SOCKET_FUNC,
    message: any
}

export interface IValueListener {
    function: SOCKET_FUNC
    message: any
}

export interface IRegisterListener {
    lev: SOCKET_LEV,
    func: (value: IValueListener) => void
}

interface IUser {
    user_id: string
}

class WSService {
    private readonly websocket: WebSocket;
    private readonly user: IUser = {
        user_id: ''
    };
    private isOpen: boolean = false;
    private timeout = 0;
    private connectId: string = '';
    protected groups: string[] = [];

    constructor(props?: { groups?: string[], user?: IUser, listener?: IRegisterListener, renew?: boolean }) {
        const {
            groups = [],
            user = wsService?.user,
            listener,
            renew
        } = props || {};
        this.isOpen = true;
        if (wsService && !renew) {
            this.websocket = wsService.websocket;
            this.user = wsService.user;
            this.registerGroups(groups);
            if (listener) {
                const {lev, func} = listener;
                messageListener[lev] = func
            }
        } else {
            this.websocket = new WebSocket(urlWebSocket)
            this.user = user;
            this.groups = groups;
            this.websocket.onopen = () => this.onConnOpen(listener);
            this.websocket.onmessage = this.onMessage;
            this.websocket.onclose = this.onReconnect;
            this.websocket.onerror = this.onError;
            wsService = this;
        }
    }

    addListener = (lev: SOCKET_LEV, listener: (value: IValueListener) => void) => {
        messageListener[lev] = listener
    }

    removeListener = (lev: SOCKET_LEV) => {
        const {[lev]: old, ...rest} = messageListener;
        messageListener = rest || {};
    }

    getGroups = () => wsService.groups

    onConnOpen = (listener?: IRegisterListener) => {
        console.log('Websocket connected!');
        this.isOpen = true;
        wsService.isOpen = this.isOpen;
        this.sendMessage(ACTION_SOCKET.GET_CONNECT_ID);
        this.registerGroups(wsService.groups);
        if (listener) {
            const {lev, func} = listener;
            messageListener[lev] = func
        }
    }

    onReconnect = () => {
        if (wsService.isOpen) {
            const count = Date.now() - wsService.timeout;
            if (!wsService.timeout || count > waitingTime) {
                const {groups} = wsService;
                wsService = new WSService({groups, renew: true});
                wsService.timeout = Date.now();
            } else {
                setTimeout(this.onReconnect, waitingTime)
            }
        }
    }

    onMessage = (response: any) => {
        if (response) {
            const message = JSON.parse(response.data) || {};
            if (message.function === SOCKET_FUNC.ON_OPEN) {
                wsService.connectId = message.msg;
            } else
                Object.keys(messageListener).forEach(lev => {
                    if (message.function)
                        messageListener[lev](message)
                    else {
                        console.log(`Message invalid!!`);
                    }
                })
        }
    }

    onError = () => {

    }

    onClose = () => {
        this.isOpen = false;
        wsService.isOpen = false;
        wsService.websocket.close();
        console.log('Websocket closed!');
    }

    sendMessage = (actionKey: any, message?: any) => {
        if (wsService && wsService.isOpen) {
            const param: any = {action: actionKey}

            if (message)
                param.msg = JSON.stringify(message)
            try {
                wsService.websocket.send(JSON.stringify(param));
            } catch (error) {
                console.log(`Send failed`, error);
            }
        } else {
            console.log(`Websocket connection not found!!`);
        }
    }

    registerGroups = (groups: string[]) => {
        if (groups.length === 0)
            return;
        const newGroups = new Set([...groups, ...wsService.groups])
        this.groups = Array.from(newGroups);
        wsService.groups = this.groups;
        console.log("Register groups")
        if (wsService.isOpen)
            this.sendMessage(ACTION_SOCKET.REGISTER, {groups, userId: wsService.user.user_id})
    }

    unregisterGroups = (groups: any) => {
        if (!wsService?.user)
            return
        this.sendMessage(ACTION_SOCKET.UNREGISTER, {groups, userId: wsService.user.user_id})
        const excludeIds = new Set(groups);
        this.groups = wsService.groups.filter(id => !excludeIds.has(id));
        wsService.groups = this.groups;
        console.log("Unregister groups")
    }

    searchGroup = (group: string) => wsService.groups.some(item => item === group)

    static getConnectId = () => wsService?.connectId

    static get = () => wsService;
}

export default WSService
