import React from 'react';
import styles from "../../style.module.scss";
import Logo from "../../../../../../../../components/Operation/Logo";
import {EVENT_TYPE, OP_STATUS, OPERATION_TYPE} from "../../../../../../../../util/varibles/constants";
import OperationStatus from "../../../../../../../../components/Operation/OperationStatus";
import Id from "../../../../../../../../components/Operation/Id";

interface IProps {
    status: OP_STATUS
    operation_type: OPERATION_TYPE
    operation_code: string
    event_type?: EVENT_TYPE
}

const BannerOperation: React.FC<IProps> = ({status, operation_type, operation_code, event_type}) => {

    const styleTop = {
        // height: isHide ? `calc((100% - 20px) / 2)` : '7px'
        height: '7px'
    }

    return <div className={styles.banner} style={styleTop}>
        <div className={styles.contentBanner}>
            <Logo
                type={operation_type}
                status={status}
                dataAttribute={{'data-event-type': event_type}}
            />
            <Id {...{visible: true, operation: {operation_code}, status}}/>
            <OperationStatus {...{status}}/>
        </div>
    </div>;
};

export default BannerOperation;
