import React, {useCallback, useEffect, useState} from 'react';
import {IGroupActivityLog, IOperation} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import Unit from "./Unit";
import UnitInfo from "./UnitInfo";
import Steps from "./Steps";
import OrderControl from "./OrderControl";
import PointChange from "./PointChange";
import {OP_STATUS} from "../../../../util/varibles/constants";

let orderOrigin: string[] = [];

interface IProps {
    activityLog: IGroupActivityLog[]
    data: IOperation
    step: number

    changeStep(step: number): void

    onUpdate?(args: any): void
}

const Units: React.FC<IProps> = ({activityLog, data, step, changeStep, onUpdate}) => {
    const [selected, setSelected] = useState('');
    const [units, setUnits]: any = useState({});
    const [orderUnit, setOrderUnit] = useState<string[]>([]);
    const {status, current_process} = data.operation;

    useEffect(() => {
        let count = 0, unitId = '', order: string[] = [];
        const result = activityLog.reduce((rs: any, item: any) => {
            const {id, list} = item;
            const max = count + list.length;
            if (id === '_')
                return rs;
            const [unit_id, round] = item.id.split('_');
            const isOngoing = current_process >= count && current_process < max;
            const isSelect = step >= count && step < max;
            const isLock = current_process >= count;

            if (rs[unit_id]) {
                rs[unit_id].list[round] = list;
                rs[unit_id].isOngoing = rs[unit_id].isOngoing || isOngoing;
                rs[unit_id].isSelect = rs[unit_id].isSelect || isSelect;
                rs[unit_id].isSelect = rs[unit_id].isSelect || isSelect;
                rs[unit_id].isLock = rs[unit_id].isLock || isLock;
            } else {
                order.push(unit_id);
                rs[unit_id] = {list: {[round]: list}, isOngoing, isSelect, isLock, index: count}
            }

            if (rs[unit_id].isSelect)
                unitId = unit_id;

            count = max;
            return rs;
        }, {});

        setSelected(unitId === '' ? Object.keys(result)[0] : unitId);
        setUnits(result);
        setOrderUnit(order);
        orderOrigin = order;
    }, [activityLog, current_process, step]);

    const handleOrderUnits = useCallback((id: string, index: number) => {
        const units = JSON.parse(JSON.stringify(orderUnit));
        units.splice(index, 0, id);
        setOrderUnit(units.filter((item: string, i: number) => !(item === id && i !== index)));
    }, [orderUnit]);

    const handleSelect = useCallback((key: string) => {
        const {index} = units[key];
        setSelected(key);
        changeStep(index);
    }, [units, changeStep]);


    const {list: schedule = {}} = units[selected] || {};

    const isChange = orderOrigin.join(',') !== orderUnit.join(',');

    return <div className={styles.wrapper}>
        <div className={styles.units} data-change={isChange}>
            {status === OP_STATUS.APPROVED && <PointChange index={0}/>}
            {orderUnit.map((key, index) => <Unit
                key={key}
                id={key}
                index={index + 1}
                name={key}
                isLock={units[key].isLock}
                isOngoing={units[key].isOngoing}
                isSelect={selected === key}
                changeStep={changeStep}
                onSelect={() => handleSelect(key)}
                onOrderUnits={handleOrderUnits}
            />)}
        </div>
        <OrderControl
            data={data}
            isChange={isChange}
            orderUnit={orderUnit}
            onUpdate={onUpdate}
            onCancel={() => setOrderUnit(orderOrigin)}
        />
        <UnitInfo data={data} unitId={selected}/>
        <Steps current_process={current_process} step={step} schedule={schedule} changeStep={changeStep}/>
    </div>;
};

export default Units;
