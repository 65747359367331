import React, {useState} from 'react';
import {Switch} from "antd";
import {IVessel} from "../../../util/varibles/interface";
import {changeSpotModeFetch} from "../../../util/services/spot";
import {vesselManagementActions} from "../reducer";
import {showErrorResponse} from "../../../util/varibles/global";
import {useDispatch} from "react-redux";

interface IProps {
    vessel: IVessel
}

const ChangeSpot: React.FC<IProps> = ({vessel}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const changeSpot = (enable_spot: boolean) => {
        const {id: vessel_id} = vessel;
        if (loading)
            return;
        setLoading(true);
        new Promise(resolve => resolve(changeSpotModeFetch({vessel_id, enable_spot})))
            .then(() => {
                dispatch(vesselManagementActions.updateVessel({...vessel, enable_spot}));
                setLoading(false);
            })
            .catch(error => {
                showErrorResponse('Update failed', error)
                setLoading(false);
            })
    }

    return <Switch
        checkedChildren="On"
        unCheckedChildren="Off"
        defaultChecked
        checked={vessel.enable_spot}
        loading={loading}
        onClick={(e) => changeSpot(e)}
    />
};

export default ChangeSpot;
