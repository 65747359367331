import React, {useCallback, useEffect, useState} from 'react';
import {checkZero, showErrorResponse} from "../../../util/varibles/global";
import {IFactory, ITenant} from "../../../util/varibles/interface";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import styles from "../style.module.scss";
import {Button, Divider, Input, Table, Tooltip} from "antd";
import {ACTION} from "../../../util/varibles/permission";
import {PlusOutlined} from "@ant-design/icons";
import PopupEdit from "./PopupEdit";
import {FactoryService} from "../../../util/services/factory";
import {AccountService} from "../../../util/services/account";
import Icon, {ICON_NAME} from "../../../components/Icon";

const column = {
    name: {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
    },
    position: {
        title: 'Lat, Long',
        dataIndex: 'position',
        key: 'position',
        render: (data: any) => <span>{[data.latitude, data.longitude].join(', ')}</span>,
    },
    discharge: {
        title: 'Discharging speed',
        dataIndex: 'discharging_speed',
        key: 'discharging_speed',
        render: (discharging_speed: any) => <span>{discharging_speed} t</span>,
    },
    waiting: {
        title: 'Waiting unit speed',
        dataIndex: 'waiting_unit_speed',
        key: 'waiting_unit_speed',
        render: (waiting_unit_speed: any) => <span>{waiting_unit_speed} t</span>,
    },
    work_time: {
        title: 'Work time',
        dataIndex: 'work_time',
        key: 'work_time',
        render: (work_time: any) => {
            let hours = Math.floor(work_time);
            let min = Math.floor(work_time - hours) * 60;
            hours = hours % 12;
            hours = hours ? hours : 12
            return <span>{`${hours}:${min > 0 ? checkZero(min) : '00'} ${hours < 12 ? 'AM' : 'PM'}`}</span>
        },
    },
    capabilities: {
        title: 'Capabilities',
        dataIndex: 'capabilities',
        key: 'capabilities',
        render: (data: any) => {
            if (!Array.isArray(data))
                return null
            return data.map(item => item.name).join(', ')
        },
    }
}

interface IProps {
    permission: any
}

const Factories: React.FC<IProps> = ({permission}) => {
    const [search, setSearch] = useState('');
    const [{loading, factories}, setFactories] = useState<{ loading: boolean, factories: IFactory[] }>({
        loading: false,
        factories: []
    });
    const [tenants, setTenants] = useState<ITenant[]>([]);

    const getFactories = useCallback(() => {
        setFactories({loading: true, factories: []})
        FactoryService.getByAdmin()
            .then(rs => {
                setFactories({loading: false, factories: rs})
            })
            .catch(error => {
                setFactories({loading: false, factories: []})
                showErrorResponse('Get data failed', error)
            })
    }, [])

    useEffect(() => {

        const getTenants = () => {
            AccountService.tenant().then(rs => setTenants(rs))
        }

        getFactories();
        getTenants();
    }, []);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Escape')
            setSearch('')
    }

    const handleClick = (data?: IFactory) => {
        const el = openPopup(<PopupEdit
            data={data}
            tenants={tenants}
            reload={getFactories}
            onClose={() => el.remove()}
        />)
    }

    return <div className={styles.content}>
        <div className='d-flex justify-content-end mb-10'>
            <Input
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder='Search by name'
                className={styles.search}
            />
            {permission[ACTION.LOCATION.CREATE] && <>
                <Divider className='divider-vertical' type='vertical'/>
                <Tooltip title="Add location">
                    <Button className='bt-primary bt-plus' type="primary" shape="circle"
                            icon={<PlusOutlined style={{fontSize: '18px'}}/>}
                            onClick={() => handleClick()}
                    />
                </Tooltip>
            </>}
        </div>
        <Table {...{
            rowKey: "id",
            pagination: false,
            columns: [
                column.name,
                {
                    title: 'Owner',
                    dataIndex: 'tenant_id',
                    key: 'tenant_id',
                    render: (tenant_id: any) => tenants.reduce((list: any, tenant) => tenant.tenant_id === tenant_id
                        ? [...list, <span key={tenant_id}>{tenant.name}</span>]
                        : list, []),
                },
                column.discharge,
                column.waiting,
                column.work_time,
                column.position,
                column.capabilities,
                {
                    className: 'p-0',
                    render: (data: any) => permission[ACTION.LOCATION.UPDATE] && <Button
                        className='bt-ghost w-[32px]'
                        data-icon='true'
                        onClick={() => handleClick(data)}
                    >
                        <Icon icon={ICON_NAME.EDIT} size="normal"/>
                    </Button>,
                },
            ],
            loading,
            dataSource: factories.filter(({name}) => {
                return search.length === 0 || name.toLowerCase().includes(search.trim().toLowerCase())
            })
        }} />
    </div>;
};

export default Factories;
