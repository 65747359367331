import React from 'react';
import Icon, {ICON_NAME} from '../Icon';
import styles from './style.module.scss';

interface IProps {
    data: any
}

const Phone: React.FC<IProps> = ({data}) => {
    return <div className={styles.wrapperInfoIcon}>
        <Icon icon={ICON_NAME.PHONE} size='large'/>
        <div className={styles.info}>
            <div>Contact number:</div>
            <div>{data.contact_number || '-'}</div>
        </div>
    </div>;
};

export default Phone;