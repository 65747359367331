import React, {Component} from 'react';
import {connect} from 'react-redux';
import ListOfTaskType from "./ListOfTaskType";
import {taskTypeActions} from "./reducer";

const mapStateToProps = null;

interface IProps {
    getTaskTypes(): void
}

class TaskTypeManagementPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.props.getTaskTypes();
    }

    render() {
        return <div className='m-header bgPage'>
            <div className='header-page'>
                <div className='title-page'>
                    <b>Task Type Management</b>
                </div>
            </div>
            <div className='body-page' data-box='true'>
                <ListOfTaskType/>
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getTaskTypes: taskTypeActions.getListRequest
})(TaskTypeManagementPage);
