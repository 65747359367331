import React from 'react';
import styles from "./style.module.scss";
import {Badge} from "antd";
import {CHAT_TYPE} from "../../../../../util/varibles/constants";
import {useSelector} from "react-redux";
import {IStateGlobal} from "../../../../../util/varibles/interface";
import {cloneObj} from "../../../../../util/varibles/global";

interface IProps {
    data: any
    selected: CHAT_TYPE

    handleSelectTab(id: CHAT_TYPE): void
}

const CountNew: React.FC<IProps> = ({data, selected, handleSelectTab}) => {
    const count = useSelector((state: IStateGlobal) => {
        const source: any = cloneObj(state.message || {})
        return source[data.keyNew] || 0
    });

    return <div
        className={styles.section}
        data-actived={data.id === selected}
        onClick={() => data.id === selected ? null : handleSelectTab(data.id)}
    >
        <Badge className={styles.badge} count={count} offset={[2, 1]}
               overflowCount={99}>
            <div className={styles[data.icon]}/>
        </Badge>
    </div>;
};

export default CountNew;
