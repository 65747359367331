import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import ContainerElement from "./ContainerElement";
import styles from './style.module.scss';

interface IProps extends IPropsElement {

}

class DropdownElement extends Component<IProps> {
    render() {
        const {
            isAllowSelect,
            label,
            properties: {subLabel = '', optionDefault = JSON.stringify(''), multipleSelections}
        } = this.props;
        const value = JSON.parse(optionDefault);
        const data = value ? (multipleSelections ? value.map((item: any) => item.label).join(', ') : value.label) : '';
        return <ContainerElement {...this.props}>
            <label data-is-empty={label.length === 0}>{label || (isAllowSelect ? 'Label' : '')}</label>
            <div className={styles['control']}>
                <div className={styles['dropdown']}>{data}</div>
                <div className={styles['sub-label']}>{subLabel || (isAllowSelect ? 'Sub label' : '')}</div>
            </div>
        </ContainerElement>;
    }
}

export default DropdownElement;
