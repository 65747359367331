import React from 'react';
import styles from "./style.module.scss";
import {EL_RULE, getBgVessel, VESSEL_COLOR} from "../constants";
import {IContract, IVessel} from "../../../../../util/varibles/interface";
import {callEventPlan, usePlan} from "../../../../../contexts/PlanOperationContext/Plan";

interface IProps {
    vesselIndex: number
    isOwn: boolean
    contract: IContract
    vessel: IVessel
    width: number
}

const Contract: React.FC<IProps> = (props) => {
    const {isOwn, vesselIndex, contract, width, vessel} = props;
    const [startPoint] = usePlan(state => state.startPoint);
    const {setTarget} = callEventPlan();

    const handleContentMenu = (e: any) => {
        if (!isOwn) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        setTarget({type: EL_RULE.CONTRACT, time: Date.now(), contract, vessel}, e)
    }

    const {start_time, finish_time, contract_code, id, type} = contract;
    const color = VESSEL_COLOR[(vesselIndex + 1) % VESSEL_COLOR.length];
    const background = isOwn ? {
        background: color,
    } : {
        backgroundAttachment: 'fixed',
        backgroundImage: getBgVessel(color),
    };
    const style = {
        width: width + '%',
        left: `${width * vesselIndex}%`,
        maxHeight: `${(finish_time - start_time) / 86400000 * 120}px`,
        top: `${(Math.max(0, start_time - startPoint)) / 86400000 * 120}px`,
    }

    return <div key={contract_code} {...{
        id: contract_code,
        className: styles.contract,
        style: {...style, ...background},
        'data-rule': EL_RULE.CONTRACT,
        'data-contract-id': id,
        'data-contract-type': type,
        'data-is-own': isOwn,
        onMouseDown: handleContentMenu,
        onContextMenu: handleContentMenu
    }}/>;
};

export default Contract;