import React from 'react';
import stylesContainer from "../../style.module.scss";
import styles from "./style.module.scss";
import {IOperation} from "../../../../util/varibles/interface";
import {convertPercent, durationTxt} from "../../../../util/varibles/global";

interface IProps {
    data: IOperation
}

const Analytics: React.FC<IProps> = ({data}) => {
    const {mortality_increase, avg_duration, lice_reduction} = data.operation;
    const result = [
        convertPercent(lice_reduction?.stationary_lice),
        convertPercent(lice_reduction?.mobile_lice),
        convertPercent(lice_reduction?.female_lice)
    ];
    const count = result.filter(x => x === 'N/A');

    return <div className={stylesContainer.section}>
        <div className={stylesContainer['header-box']}>
            <span>Analytics</span>
        </div>
        <div className={stylesContainer['body-box']}>
            <div className={styles.titleAnalytics}>
                <div>Avg. duration</div>
                <div>% Increased mortality</div>
                <div>% Lice reduction (Stationary, Mobile, Adult female lice)</div>
            </div>
            <div className={styles.bodyAnalytics}>
                <div>{typeof avg_duration !== 'number' ? '-' : `${durationTxt(avg_duration)}/tons`}</div>
                <div>{convertPercent(mortality_increase)}</div>
                <div>{count.length === 3 ? "N/A" : result.join(', ')}</div>
            </div>
        </div>
    </div>;
};

export default Analytics;
