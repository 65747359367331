import {datetime} from "../../util/library/datetime";

export const getCurrentVesselData = (history: any, time: any) => {
    const index = history.findIndex(([subTime]: any, index: number) => {
        const startTime = datetime(subTime).time;
        if (startTime === time)
            return true;
        if (startTime > time)
            return false
        if (!history[index + 1])
            return true;
        else {
            const finishTime = datetime(history[index + 1][0]).time
            if (time < finishTime)
                return true
        }
        return false
    })
    return {...history[index], index}
}

const spaceScroll = 25;
export const handleScrollPointer = ({...args}) => {
    const {pageX, target, action, clearInterval, min, max} = args;
    const {scrollWidth, offsetWidth} = target;
    if (!target)
        return;

    let internal: any;
    if (pageX > (max - spaceScroll)) {
        const space = spaceScroll - Math.floor(max - pageX);
        internal = window.setInterval(() => {
            const {scrollLeft} = target;
            target.scrollLeft = target.scrollLeft + space;
            if (target.scrollLeft === 0) {
                action(0 - scrollLeft);
                clearInterval()
            } else
                action(space);
        }, 50)
    }
    if (pageX < (min + spaceScroll)) {
        const space = spaceScroll + Math.floor(min - pageX);
        internal = window.setInterval(() => {
            const {scrollLeft} = target;
            target.scrollLeft = target.scrollLeft - space;
            if (target.scrollLeft === -(scrollWidth - offsetWidth)) {
                action(-(scrollWidth - offsetWidth + scrollLeft));
                clearInterval()
            } else
                action(-space);
        }, 50)
    }
    return internal;
}
