import React from 'react';
import {useDispatch} from "react-redux";
import {loginActions} from "../LoginPage/reducer";
import {nextPage} from "../../util/library/Router";
import {PATHNAME} from "../../util/varibles/constants";

interface IProps {
}

const AccessTokenPage: React.FC<IProps> = () => {
    const dispatch = useDispatch()

    dispatch(loginActions.getUserRequest({action: () => nextPage(PATHNAME.HOME)}));
    return <div/>
};

export default AccessTokenPage;
