import callApi, {api} from "../api/callApi";
import {urlDevice, urlDevicesService, urlDeviceType} from "../api/v1";
import {store} from "../store/store";

export function getDeviceTypesFetch() {
    return callApi(urlDeviceType, {
        method: 'GET'
    })
}

export function getInfoDevices(body: {
    data_type: string,
    device_ids: string[],
    start_time: number,
    finish_time: number
}) {
    return callApi(urlDevice, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}


export function getSensorsDataFetch(body: IBodySensorData) {
    return callApi(urlDevicesService + '/sensors', {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export function getDevicesFetch(ioName: string) {
    return callApi(urlDevicesService + '/' + ioName, {
        method: 'GET'
    })
}

export function getDevicesConfigFetch(iotName: any) {
    return callApi(urlDevicesService + '/config/' + iotName, {
        method: "GET"
    })
}

export function sendDevicesFetch(device: any) {
    return callApi(urlDevicesService + '/config', {
        method: "POST",
        body: JSON.stringify(device)
    })
}

interface IBodySensorData {
    start_time: number,
    end_time: number
    device_id: string
    sensor: string
}

interface IBodyGPSData {
    start_time: number,
    end_time: number

}

export const DeviceService = {
    getSensorData: (body: IBodySensorData) => api.post(urlDevicesService + '/sensors', body),
    getGPS: (body: IBodyGPSData) => {
        const state = store.getState();
        const {iot_name = ''} = state.vesselDetail.vessel || {};
        return api.post(urlDevicesService + '/gps', {...body, iot_name})
    }
}