import React, {Component} from 'react';
import ContainerElement from "./ContainerElement";
import styles from "./style.module.scss";
import {IPropsElement} from "./constants";

interface IProps extends IPropsElement {

}

class SignElement extends Component<IProps> {
    render() {
        const {isAllowSelect, label, properties: {options = ''}} = this.props;
        const data = options.length > 0 ? JSON.parse(options) : [];
        return <ContainerElement {...this.props}>
            <label className='pt-2'
                   data-is-empty={label.length === 0}>{label || (isAllowSelect ? 'Label' : '')}</label>
            <div className={styles['control']}>
                <div className={styles['sign']}>
                    {[...data || []].map((item: any) => <div key={item.value}>{item.label}</div>)}
                </div>
            </div>
        </ContainerElement>;
    }
}

export default SignElement;
