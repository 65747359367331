import React, {useEffect, useState} from 'react';
import styles from "../../style.module.scss";
import {Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {DISEASED_ZONE, diseasedZone} from "../../../../../util/varibles/constants";
import {BarentsWatchService} from "../../../../../util/barentswatch/api";
import {DashboardSetting, PreferenceSetting} from "../../../../../util/varibles/userSetting";
import {convertDisease} from "../../../../../util/varibles/global";
import {VIEW_MODE} from "../../../../../util/varibles/permission";
import {loginActions} from "../../../../LoginPage/reducer";
import {datetime} from "../../../../../util/library/datetime";
import {DiseaseZonePolygon} from "../../../../../contexts/WorldMapContext/Polygon";
import {selectOneSetting} from "../../../../../util/store/selectors";
import {useWorldMap} from "../../../../../contexts/WorldMapContext";
import {inside} from "../../../../../util/library/googlemap/constants";

interface DiseasedZoneHover {
    source: any[],
    type: DISEASED_ZONE
}

const DiseasedZoneHover: React.FC<DiseasedZoneHover> = ({source, type}) => {
    const [location, setStore] = useWorldMap(state => state.location);

    useEffect(() => {
        if (location) {
            setStore(prev => ({
                zones: {
                    ...prev.zones,
                    [type]: source.filter(item => inside(location, item.coordinates[0]))
                }
            }))
        }

        return () => {
            setStore(prev => ({zones: {...prev.zones, [type]: []}}))
        }
    }, [location, source]);

    return null
}


interface IProps {
    data: { id: DISEASED_ZONE, name: string }
}

const DiseasedZone: React.FC<IProps> = (props) => {
    const {id, name} = props.data;
    const viewMode = useSelector(selectOneSetting(PreferenceSetting.VIEW_MODE))
    const filter = useSelector(selectOneSetting(DashboardSetting.DISEASED_ZONE))

    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const [{week, zones, loading}, setZones] = useState<{ week?: string, zones: any[], loading: boolean }>({
        zones: [],
        loading: false
    });

    useEffect(() => {
        const weekNew = [datetime().year, datetime().week].join('/');
        if (weekNew !== week) {
            setZones(prev => ({...prev, week: weekNew, loading: true}))
            BarentsWatchService.getIlaControlAreaV2(id, weekNew)
                .then((rs: any) => {
                    const zones = rs.map((item: any) => convertDisease(item));
                    setZones(prev => ({...prev, zones, loading: false}))
                })
                .catch(() => {
                    setZones(prev => ({...prev, loading: false}))
                })
        }
        setVisible(filter[id] && viewMode === VIEW_MODE.PLANNER);
    }, [id, filter, viewMode]);

    const onChange = (checked: any) => {
        const value = {...filter, [id]: checked};
        dispatch(loginActions.updateUserSetting([{[DashboardSetting.DISEASED_ZONE]: value}]));
    }

    const {color = ''} = diseasedZone[id] || {};
    const style: any = {'--diseased-color': color};

    return <div key={id} className={styles.line} style={style}>
        <div className={styles.diseasedLine}>
            <div className='diseased-zone' data-code={id}/>
            <div className='text-[15px]'>{name}</div>
        </div>
        <Switch
            loading={loading}
            checked={filter[id]}
            onChange={(checked) => onChange(checked)}
        />
        {zones.map(item => <DiseaseZonePolygon
            key={item.id}
            data={item}
            show={visible}
            diseaseType={id}
        />)}
        {visible && <DiseasedZoneHover source={zones} type={id}/>}
    </div>
};

export default DiseasedZone;

