import React, {Component} from 'react';
import {convertActivityLog, durationTxtByStartAndFinish} from "../../../../util/varibles/global";
import {OPERATION_TYPE} from "../../../../util/varibles/constants";
import Operation from "../../_Component/Operation";
import Vessel from "../../_Component/Vessel";
import Route from "../../_Component/Route";
import stylesOp from "../../_Component/Operation/style.module.scss";
import AttachFile from "../../../../components/Popup/PopupOpInfo/Component/AttachFile";
import Tasks from "../../../../components/Popup/PopupOpInfo/Info/Treatment/Tasks";
import FishDisease from "../../../../components/Location/FishDisease";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import SectionRight from "../../_Component/SectionRight";
import WrapperActivityLog from "../../_Component/WrapperActivityLog";
import {IOperation} from "../../../../util/varibles/interface";
import ViewNote from "../../_Component/ViewNote";
import PlannedTime from "../../_Component/PlannedTime";
import LiceList from "../../../../components/Operation/LiceList";
import Analytics from "../../_Component/Analytics";
import stylesContainer from "../../style.module.scss";
import Round from "../../../PlanOperationPage/Popup/EditOp/Body/Treatment/RoundMap/Round";
import Tank from "../../../PlanOperationPage/Popup/EditOp/Body/Treatment/RoundMap/Tank";
import {RoundMapProvider} from "../../../../contexts/RoundMapContext";

interface IProps {
    data: IOperation
    isOwn: boolean
    viewMode: VIEW_MODE
}

class Treatment extends Component<IProps> {

    render() {
        const {data} = this.props;
        const {note = '', files = [], sub_operations, round_map = {}} = data.operation;
        const isNote = note.length === 0;

        const {activity_log} = data;
        const est_start_time = activity_log[0].est_start_time;
        const est_finish_time = activity_log[activity_log.length - 1].est_start_time;
        const activityLog = convertActivityLog[OPERATION_TYPE.TREATMENT](activity_log);

        return <>
            <div>
                <Operation {...{isNote}}>
                    <div className={stylesOp['info-line']}>
                        <span>Operation type:</span>
                        <span>Treatment</span>
                    </div>
                    <PlannedTime activity_log={activity_log}/>
                    <div className={stylesOp['info-line']}>
                        <span>Planned duration: </span>
                        <span>{durationTxtByStartAndFinish(est_start_time, est_finish_time)}</span>
                    </div>
                    <div className={stylesOp['info-line']}>
                        <AttachFile files={files}/>
                    </div>
                    <FishDisease diseases={data.operation.diseases || []} units={sub_operations} wrapperClass='mb-5'/>
                    <LiceList diseases={data.operation.diseases || []}/>
                    <div className={stylesOp['info-line']}>
                        <Tasks data={data}/>
                    </div>
                    <ViewNote data={data} isOwn={this.props.isOwn} note={note}/>
                </Operation>
                <Analytics data={data}/>
                <Vessel/>
                <Route/>
                <div className={stylesContainer.section}>
                    <div className={stylesContainer['header-box']}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <span className="mr-3">Activity log</span>
                            </div>
                        </div>
                    </div>
                    <RoundMapProvider>
                        {Object.keys(round_map).map((key, i, arr) => {
                            const {after_processes, tanks} = round_map[key];
                            const nextRound = arr[i + 1];

                            return <Round
                                key={key}
                                roundId={key}
                                after_processes={after_processes}
                            >
                                <div className='grid gap-3 z-[1]'
                                     style={{gridTemplateColumns: `repeat(${tanks.length}, 1fr)`}}>
                                    {tanks.map((item, i: number) => <Tank
                                        key={i}
                                        data={item}
                                        nextUnit={!!round_map[nextRound]}
                                    />)}
                                </div>
                            </Round>
                        })}
                    </RoundMapProvider>
                </div>
            </div>
            <SectionRight>
                <WrapperActivityLog
                    isOwn={this.props.isOwn}
                    data={this.props.data}
                    activity_log={activityLog}
                    activity_log_root={activity_log}
                />
            </SectionRight>
        </>;
    }
}

export default Treatment;
