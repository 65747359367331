import {all, call, put, takeEvery} from 'redux-saga/effects'
import {DiseaseService} from "../../util/services/disease";
import {IDisease} from "../../util/varibles/interface";
import {showErrorResponse} from "../../util/varibles/global";
import {diseaseActions} from "./reducer";


function* getDiseasesWatcher() {
    try {
        const diseases: IDisease[] = yield call(DiseaseService.gets);
        yield put(diseaseActions.getListSuccess(diseases));
    } catch (error) {
        showErrorResponse('Get diseases failure', error)
        yield put(diseaseActions.getListFailure());
    }
}

export default function* diseaseSaga() {
    yield all([
        takeEvery(diseaseActions.getListRequest.type, getDiseasesWatcher)
    ])
}
