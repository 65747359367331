import callApi from "../api/callApi";
import {urlTenant} from "../api/v1";
import {ISite} from "../varibles/interface";
import {OPERATION_MODE} from "../varibles/constants";

export function getTenantsFetch() {
    return callApi(urlTenant, {
        method: "GET"
    })
}

interface IEditWeatherStatusFetch {
    tenant_id: string,
    weather_license: boolean
}

export function editWeatherStatusFetch(data: IEditWeatherStatusFetch) {
    return callApi(urlTenant + '/weather', {
        method: 'PUT',
        body: JSON.stringify(data)
    })
}

interface ICreateTenantFetch {
    name: string,
    weather_license: boolean,
    customer_type: string,
    domain: {
        provider_name: string,
        name: string,
        sso_mode: string
    },
    integration_mode: string,
    integration: {
        pass: string,
        user: string,
        type: string,
        url: string
    }
}

export function createTenantFetch(params: ICreateTenantFetch) {
    return callApi(urlTenant, {
        method: 'POST',
        body: JSON.stringify(params)
    })
}

interface IUpdateTenantFetch {
    id: string
    name: string
    weather_license: boolean
    customer_type: string
    domain: {
        provider_name: string
        name: string
        sso_mode: string
    }
    integration_mode: string
    integration: {
        pass: string
        user: string
        type: string
        url: string
    }
}

export function updateTenantFetch(params: IUpdateTenantFetch) {
    return callApi(urlTenant, {
        method: 'PUT',
        body: JSON.stringify(params)
    })
}

interface IGenerateSitesFetch {
    tenant_id: string
    business_name: string
}

export function generateSitesFetch(params: IGenerateSitesFetch) {
    return callApi(urlTenant + '/trial', {
        method: 'POST',
        body: JSON.stringify(params)
    })
}

export function saveGenerateFetch(params: { sites: ISite[] }) {
    return callApi(urlTenant + '/trial', {
        method: 'PUT',
        body: JSON.stringify(params)
    })
}

interface IUpdateDensity {
    density?: {
        [index: string]: number[]
    }
    enable_change_operation_status?: boolean
    operation_mode?: OPERATION_MODE
}

export function updateDensitySettingFetch(body: IUpdateDensity) {
    return callApi(urlTenant + '/setting', {
        method: 'PUT',
        body: JSON.stringify(body)
    })
}