import {notification} from "antd";
import {ICON_NAME} from "../../components/Icon";
import {IVesselConfig} from "./interface";

export const FULL_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const FULL_DATE_SHORT_TIME_FORMAT = "YYYY-MM-DD, HH:mm";
export const SHORT_DATETIME_FORMAT = "YYYY/MM/DD, HH:mm";
export const SHORT_MONTH_DATETIME_FORMAT = "DD/MM, HH:mm";
export const SHORT_DAY_DATE_MONTH_FORMAT = "ddd DD/MM";
export const FULL_DATE_FORMAT_ORDINAL = "dddd, MMMM Do";
export const FULL_DAY_TIME_FORMAT = "dddd hh:mma";
export const FULL_DATE_FORMAT = "YYYY/MM/DD";
export const MONTH_DATE_FORMAT = "DD/MM";
export const FULL_TIME_FORMAT = "HH:mm:ss";
export const SHORT_TIME_FORMAT = "HH:mm";
export const SHORT_TIME_FORMAT_AMPM = "hh:mma";
export const FULL_MONTH_DAY_YEAR_FORMAT = 'MMMM D, YYYY'
export const FULL_MONTH_YEAR_FORMAT = 'MMMM YYYY'
export const YEAR_WEEK_FORMAT = 'YYYYWW'

export const patternInteger = /^\d*$/;
export const patternFloat2 = /^\d+[.]?\d*$/;
export const patternFloat = '(^\\d+([\\.,]\\d*)?|^\\d*)$';
export const patternEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const patternPassword = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/;
export const colors = ["FF8D68", "56A513", "1B6399", "63316D", "FFC900", "AD55E3", "D06643", "8E8E8E", "2F8ED5", "C5DFBA"];
export const aWeekMillisecond = 604800000;
export const aDayMillisecond = 86400000;
export const aHourMillisecond = 3600000;
export const aMinutesMillisecond = 60000;
export const timeRequestSetting = 1500;

export const TIME_PER_STEP = 900000;

export const timeLate = 300000;

export enum AVAILABLE_TIME_TYPE {
    ALL,
    ONLY_AVAILABLE_TIME
}

export enum VESSEL_TYPE {
    PROCESS_VESSEL = 1,
    WELL_BOAT = 2,
    SERVICE = 3
}

export const vesselType: any = {
    [VESSEL_TYPE.PROCESS_VESSEL]: {id: VESSEL_TYPE.PROCESS_VESSEL, name: 'Process vessel', shortName: 'PV'},
    [VESSEL_TYPE.WELL_BOAT]: {id: VESSEL_TYPE.WELL_BOAT, name: 'Wellboat', shortName: 'WB'},
    [VESSEL_TYPE.SERVICE]: {id: VESSEL_TYPE.SERVICE, name: 'Service vessel', shortName: 'SV'},
}
export const vesselTypes = [
    vesselType[VESSEL_TYPE.PROCESS_VESSEL],
    vesselType[VESSEL_TYPE.WELL_BOAT],
    vesselType[VESSEL_TYPE.SERVICE]
]

export enum OPERATION_TYPE {
    UNAVAILABLE = -1,
    HARVEST = 0,
    TREATMENT = 1,
    TRANSPORT = 2,
    EVENT = 3,
    SERVICE = 4
}

export const operationType: any = {
    [OPERATION_TYPE.UNAVAILABLE]: {id: OPERATION_TYPE.UNAVAILABLE, name: 'Unavailable'},
    [OPERATION_TYPE.HARVEST]: {id: OPERATION_TYPE.HARVEST, name: 'Harvest', icon: ICON_NAME.HARVEST},
    [OPERATION_TYPE.TREATMENT]: {id: OPERATION_TYPE.TREATMENT, name: 'Treatment', icon: ICON_NAME.TREATMENT},
    [OPERATION_TYPE.TRANSPORT]: {id: OPERATION_TYPE.TRANSPORT, name: 'Smolt Transport', icon: ICON_NAME.TRANSPORT},
    [OPERATION_TYPE.EVENT]: {id: OPERATION_TYPE.EVENT, name: 'Event', icon: ICON_NAME.EVENT},
    [OPERATION_TYPE.SERVICE]: {id: OPERATION_TYPE.SERVICE, name: 'Service', icon: ICON_NAME.SERVICE},
}

export const operationTypes = [
    operationType[OPERATION_TYPE.HARVEST],
    operationType[OPERATION_TYPE.TREATMENT],
    operationType[OPERATION_TYPE.TRANSPORT],
    operationType[OPERATION_TYPE.EVENT],
    operationType[OPERATION_TYPE.SERVICE],
]

export enum PROCESS_TYPE {
    SEQUENCE = 'sequence',
    PARALLEL = 'parallel'
}

export const processType = {
    [PROCESS_TYPE.SEQUENCE]: {id: PROCESS_TYPE.SEQUENCE, name: 'Sequence'},
    [PROCESS_TYPE.PARALLEL]: {id: PROCESS_TYPE.PARALLEL, name: 'Parallel'},
}

export enum OP_STATUS {
    ERROR = 'error',
    PENDING = 'pending',
    WARNING = 'warning',
    SAVED = 'saved',
    NEW = 'new',
    WAITING = 'waiting',
    APPROVED = 'approved',
    CANCELED = 'canceled',
    PROCESSING = 'processing',
    FINISHED = 'finished',
}

export enum TREATMENT_TYPE {
    THERMOLICER = 0,
    OPTILICER = 1,
    FRESHWATER = 2,
    WELLFIGHTER = 3,
    HYDROGENPEROXIDE = 4,
    SORTING = 5,
    SALTWATER = 7,
    SALMOSAN = 8,
    FLS = 9,
    ECTOSAN = 10
}

export const treatmentType: any = {
    [TREATMENT_TYPE.THERMOLICER]: {id: TREATMENT_TYPE.THERMOLICER, name: 'Thermolicer'},
    [TREATMENT_TYPE.OPTILICER]: {id: TREATMENT_TYPE.OPTILICER, name: 'Optilicer'},
    [TREATMENT_TYPE.FRESHWATER]: {id: TREATMENT_TYPE.FRESHWATER, name: 'Freshwater'},
    [TREATMENT_TYPE.WELLFIGHTER]: {id: TREATMENT_TYPE.WELLFIGHTER, name: 'Wellfighter'},
    [TREATMENT_TYPE.HYDROGENPEROXIDE]: {id: TREATMENT_TYPE.HYDROGENPEROXIDE, name: 'Hydrogenperoxide'},
    [TREATMENT_TYPE.SALTWATER]: {id: TREATMENT_TYPE.SALTWATER, name: 'Saltwater'},
    [TREATMENT_TYPE.SALMOSAN]: {id: TREATMENT_TYPE.SALMOSAN, name: 'Salmosan'},
    [TREATMENT_TYPE.FLS]: {id: TREATMENT_TYPE.FLS, name: 'FLS'},
    [TREATMENT_TYPE.SORTING]: {id: TREATMENT_TYPE.SORTING, name: 'Sorting'},
    [TREATMENT_TYPE.ECTOSAN]: {id: TREATMENT_TYPE.ECTOSAN, name: 'Ectosan'},
}

export const treatmentTypes: { id: TREATMENT_TYPE, name: string }[] = [
    treatmentType[TREATMENT_TYPE.ECTOSAN],
    treatmentType[TREATMENT_TYPE.FRESHWATER],
    treatmentType[TREATMENT_TYPE.THERMOLICER],
    treatmentType[TREATMENT_TYPE.OPTILICER],
    treatmentType[TREATMENT_TYPE.WELLFIGHTER],
    treatmentType[TREATMENT_TYPE.HYDROGENPEROXIDE],
    treatmentType[TREATMENT_TYPE.SALTWATER],
    treatmentType[TREATMENT_TYPE.SALMOSAN],
    treatmentType[TREATMENT_TYPE.FLS],
    treatmentType[TREATMENT_TYPE.SORTING],
]

export const treatmentInTanks = [
    TREATMENT_TYPE.ECTOSAN,
    TREATMENT_TYPE.FRESHWATER,
    TREATMENT_TYPE.HYDROGENPEROXIDE,
    TREATMENT_TYPE.SALMOSAN,
    TREATMENT_TYPE.SALTWATER
]

export enum EVENT_TYPE {
    CUSTOM,
    MAINTENANCE,
    RE_FUEL,
    QUARANTINE,
    CREW_CHANGE,
    HOLIDAY,
    CLEANING,
    HYGIENE_CONTROL,
    GET_WATER
}

export const eventType: any = {
    [EVENT_TYPE.CUSTOM]: {id: EVENT_TYPE.CUSTOM, name: 'Custom', icon: ''},
    [EVENT_TYPE.MAINTENANCE]: {id: EVENT_TYPE.MAINTENANCE, name: 'Maintenance', icon: ''},
    [EVENT_TYPE.RE_FUEL]: {id: EVENT_TYPE.RE_FUEL, name: 'Re-fuel', icon: ''},
    [EVENT_TYPE.QUARANTINE]: {id: EVENT_TYPE.QUARANTINE, name: 'Quarantine', icon: ''},
    [EVENT_TYPE.CREW_CHANGE]: {id: EVENT_TYPE.CREW_CHANGE, name: 'Crew change', icon: ''},
    [EVENT_TYPE.HOLIDAY]: {id: EVENT_TYPE.HOLIDAY, name: 'Holiday', icon: ''},
    [EVENT_TYPE.CLEANING]: {id: EVENT_TYPE.CLEANING, name: 'Cleaning', icon: ''},
    [EVENT_TYPE.HYGIENE_CONTROL]: {id: EVENT_TYPE.HYGIENE_CONTROL, name: 'Hygiene Control', icon: ''},
    [EVENT_TYPE.GET_WATER]: {id: EVENT_TYPE.GET_WATER, name: 'Getting water', icon: ''},
}

export const eventTypes: { id: EVENT_TYPE, name: string }[] = [
    eventType[EVENT_TYPE.CUSTOM],
    eventType[EVENT_TYPE.MAINTENANCE],
    eventType[EVENT_TYPE.RE_FUEL],
    eventType[EVENT_TYPE.QUARANTINE],
    eventType[EVENT_TYPE.CREW_CHANGE],
    eventType[EVENT_TYPE.HOLIDAY],
    eventType[EVENT_TYPE.CLEANING],
    eventType[EVENT_TYPE.HYGIENE_CONTROL],
    eventType[EVENT_TYPE.GET_WATER],
]

export enum TASK_TYPE {
    PICK_UP = 1,
    DELIVER = 2,
    MOVE = 3,
    TREATMENT = 4,
    CUSTOMIZE = 5,
    SORTING = 6,
    HARVEST = 7,
    COMBINE = 8
}

export const taskTypes: any = {
    [TASK_TYPE.PICK_UP]: {id: TASK_TYPE.PICK_UP, name: 'Pickup', disabled: false, isShow: true},
    [TASK_TYPE.DELIVER]: {id: TASK_TYPE.DELIVER, name: 'Deliver', disabled: false, isShow: true},
    [TASK_TYPE.MOVE]: {id: TASK_TYPE.MOVE, name: 'Transfer', disabled: false, isShow: true},
    [TASK_TYPE.TREATMENT]: {id: TASK_TYPE.TREATMENT, name: 'Treatment', disabled: false, isShow: true},
    [TASK_TYPE.CUSTOMIZE]: {id: TASK_TYPE.CUSTOMIZE, name: 'Customize', disabled: false, isShow: true},
    [TASK_TYPE.HARVEST]: {id: TASK_TYPE.HARVEST, name: 'Harvest', disabled: false, isShow: true},
    [TASK_TYPE.COMBINE]: {id: TASK_TYPE.COMBINE, name: 'Combine', disabled: false, isShow: true},
}

export enum SITE_TYPE {
    INTERNAL = "internal",
    EXTERNAL = "external",
}

export const siteType: any = {
    [SITE_TYPE.INTERNAL]: {id: SITE_TYPE.INTERNAL, name: 'Internal'},
    [SITE_TYPE.EXTERNAL]: {id: SITE_TYPE.EXTERNAL, name: 'External'}
}

export const siteTypes = [
    siteType[SITE_TYPE.INTERNAL],
    siteType[SITE_TYPE.EXTERNAL],
]

export enum FISH_TYPE {
    PRODUCTION = 'Production site',
    SMOLT = 'Hatchery'
}

export const fishType: any = {
    [FISH_TYPE.PRODUCTION]: {id: 'site', name: 'Production site'},
    [FISH_TYPE.SMOLT]: {id: 'site-smolt', name: 'Hatchery'}
}

export const fishTypes = [
    fishType[FISH_TYPE.PRODUCTION],
    fishType[FISH_TYPE.SMOLT],
]

export enum CONTRACT_PERMISSION_TYPE {
    FULL = 'full',
    BASIC = 'basic'
}

export const contractPermissionType: any = {
    [CONTRACT_PERMISSION_TYPE.BASIC]: {id: CONTRACT_PERMISSION_TYPE.BASIC, name: 'Spot'},
    [CONTRACT_PERMISSION_TYPE.FULL]: {id: CONTRACT_PERMISSION_TYPE.FULL, name: 'Fixed chart'},
}

export const VIEW_SMOOTH: ScrollIntoViewOptions = {behavior: 'smooth', block: 'start', inline: "nearest"};

export enum LOCATION_TYPE {
    SITE = 'site',
    FACTORY = 'factory',
    HATCHERY = 'hatchery',
    POI = 'poi'
}

export const locationType = {
    [LOCATION_TYPE.SITE]: {id: LOCATION_TYPE.SITE, name: 'Site'},
    [LOCATION_TYPE.FACTORY]: {id: LOCATION_TYPE.SITE, name: 'Factory'},
    [LOCATION_TYPE.HATCHERY]: {id: LOCATION_TYPE.SITE, name: 'Hatchery'},
    [LOCATION_TYPE.POI]: {id: LOCATION_TYPE.SITE, name: 'POI'},
}

export const STATE_HARVEST: string[] = [
    "Travelling to site",
    "Arrived at site",
    "Harvesting",
    "Finished harvesting",
    "Travelling to factory",
    "Arrived at factory",
    "Discharging",
    "Finished discharging",
    "Cleaning vessel",
    "Completed"
];

export const STATE_EVENT: string[] = [
    "Start event",
    "Finish event",
];

export const STATE_TRANSPORT: string[] = [
    'Travelling to smolt site',
    'Arrived at smolt Site',
    'Loading',
    'Finished loading',
    'Travelling to site',
    'Arrived at site',
    'Discharging',
    'Finished discharging',
    'Cleaning vessel',
    'Completed',
];

export enum ACTION_TYPE {
    NONE,
    CREATE,
    EDIT,
    DELETE
}

export enum CONTRACT_STATUS {
    REQUESTING = 'requesting',
    APPROVED = 'offered',
    DECLINE = 'canceled',
    COMPLETE = 'done',
    INACTIVE = 'inactive',
}

export enum CONTRACT_TYPE {
    SINGLE = 'single_operation',
    FIXED = 'fix',
}

export const contractType = {
    [CONTRACT_TYPE.SINGLE]: {id: CONTRACT_TYPE.SINGLE, name: 'Single operation contract'},
    [CONTRACT_TYPE.FIXED]: {id: CONTRACT_TYPE.FIXED, name: 'Fixed contract'},
}

export enum CUSTOMER_TYPE {
    TRIAL = 'trial',
    ENTERPRISE = 'enterprise'
}

export const customerType = {
    [CUSTOMER_TYPE.TRIAL]: {id: CUSTOMER_TYPE.TRIAL, name: 'Trial'},
    [CUSTOMER_TYPE.ENTERPRISE]: {id: CUSTOMER_TYPE.ENTERPRISE, name: 'Enterprise'},
}

export const customerTypes = [
    customerType[CUSTOMER_TYPE.TRIAL],
    customerType[CUSTOMER_TYPE.ENTERPRISE],
]

export enum SSO_MODE {
    AZURE = 'azure',
    COGNITO = 'cognito'
}

export const ssoMode = {
    [SSO_MODE.AZURE]: {id: SSO_MODE.AZURE, name: 'Azure'},
    [SSO_MODE.COGNITO]: {id: SSO_MODE.COGNITO, name: 'Cognito'}
}
export const ssoModes = [
    ssoMode[SSO_MODE.AZURE],
    ssoMode[SSO_MODE.COGNITO],
]

export enum INTEGRATION_MODE {
    STHP_FILE = 'STHP_FILE',
    ODATA = 'odata'
}

export const integrationMode = {
    [INTEGRATION_MODE.STHP_FILE]: {id: INTEGRATION_MODE.STHP_FILE, name: 'STHP File'},
    [INTEGRATION_MODE.ODATA]: {id: INTEGRATION_MODE.ODATA, name: 'Odata'}
}

export const integrationModes = [
    integrationMode[INTEGRATION_MODE.STHP_FILE],
    integrationMode[INTEGRATION_MODE.ODATA],
]

export enum USER_ROLE {
    SUPER_ADMIN = 'super_admin',
    ADMIN = 'admin',
    CUSTOMER = 'customer',
    VESSEL_OWNER = 'vessel_owner',
    VESSEL_VIEWER = 'vessel_viewer',
    CREW = 'crew',
    SITE_MANAGER = 'site_manager',
    FACTORY_MANAGER = 'factory_manager'
}

export const userRole: any = {
    [USER_ROLE.SUPER_ADMIN]: {id: USER_ROLE.SUPER_ADMIN, name: 'Super admin'},
    [USER_ROLE.ADMIN]: {id: USER_ROLE.ADMIN, name: 'Admin'},
    [USER_ROLE.CUSTOMER]: {id: USER_ROLE.CUSTOMER, name: 'Planner'},
    [USER_ROLE.VESSEL_OWNER]: {id: USER_ROLE.VESSEL_OWNER, name: 'Vessel owner'},
    [USER_ROLE.VESSEL_VIEWER]: {id: USER_ROLE.VESSEL_VIEWER, name: 'Vessel viewer'},
    [USER_ROLE.CREW]: {id: USER_ROLE.CREW, name: 'Crew'},
    [USER_ROLE.SITE_MANAGER]: {id: USER_ROLE.SITE_MANAGER, name: 'Site'},
    [USER_ROLE.FACTORY_MANAGER]: {id: USER_ROLE.FACTORY_MANAGER, name: 'Factory'},
}

export enum VESSEL_DEVICE_TYPE_NAME {
    LOCATION = 'Location',
    SENSORS = 'Sensors',
}

export enum VESSEL_IOT {
    HEADING = 'Heading',
    SPEED = 'Speed',
    LAT = 'Latitude',
    LNG = 'Longitude',
}


export function getOperationStatus(status: any) {
    switch (status) {
        case OP_STATUS.SAVED:
            return "saved"
        case OP_STATUS.NEW:
            return "new"
        case OP_STATUS.PENDING:
            return "reviewing"
        case OP_STATUS.WAITING:
            return "waiting"
        case OP_STATUS.APPROVED:
            return "approved"
        case OP_STATUS.CANCELED:
            return "canceled"
        case OP_STATUS.PROCESSING:
            return "ongoing"
        case OP_STATUS.FINISHED:
            return "completed"
        default:
            return status
    }
}

export function getRoleNames(roles: USER_ROLE[], separation = ', ') {
    return roles.map(key => {
        const {name = ''} = userRole[key] || {};
        return name
    }).join(separation);
}

export const volumeUnit: any = {
    // {
    //     id: 'km3',
    //     html: '&#13221;'
    // },
    m3: {
        id: 'm3',
        short: 'm³',
        index_number: 0,
    },
    l: {
        id: 'l',
        short: 'l',
        index_number: -3
    },
    // {
    //     id: 'dm3',
    //     html: '&#13177;'
    // },
    // {
    //     id: 'cm3',
    //     html: '&#13120;'
    // },
    // {
    //     id: 'mm3',
    //     html: '&#13119;'
    // }
}

export const weightUnit: any = {
    tons: {
        id: 'tons',
        short: 't',
        index_number: 6
    },
    kg: {
        id: 'kg',
        short: 'kg',
        index_number: 3
    },
    gr: {
        id: 'gram',
        short: 'gr',
        index_number: 0
    }
}

export enum KeyOfStep {
    // Treatment

    RESTING = 'resting',
    SORTING = 'sorting',

    LOADING = 'loading',
    UNLOADING = 'unloading',
    TREATING_FRESHWATER = 'treating_fresh_water',
    TREATING_THERMOLICER = 'treating_themolicer',
    TREATING_OPTILICER = 'treating_optilicer',
    TREATING_WELLFIGHTER = 'treating_wellfighter',
    TREATING_HYDROGENPEROXIDE = 'treating_hydrogenperoxide',
    TREATING_SALTWATER = 'treating_salt_water',
    TREATING_FRESH_WELLFIGHTER = 'treating_fresh_wellfighter',
    TREATING_SALMOSAN = 'treating_salmosan',
    TREATING_FLS = 'treating_FLS',
    TREATING_ECTOSAN = 'treating_ectosan',


    // Harvest
    GO_TO_SITE = 'journey_to_site_state',
    ARRIVE_SITE = 'arrive_site_state',
    HARVEST = 'harvesting_state',
    FINISHED_HARVEST = 'finished_harvesting_state',
    GO_TO_FACTORY = 'journey_to_factory_state',
    ARRIVE_FACTORY = 'arrive_factory_state',
    DISCHARGE_FACTORY = 'discharging_factory_state',
    FINISH_DISCHARGE_FACTORY = 'finished_discharging_state',
    CLEANING_HARVEST = 'cleaning_state',
    COMPLETED_HARVEST = 'completed_state',

    // Task move
    TRAVELING = 'traveling',
    MOVING = 'moving',
    MOVED = 'moved',

    // Global
    CLEANING = 'cleaning',
    COMPLETED = 'completed',
}

export const listOfStep: { [key: string]: { id: KeyOfStep, name: string } } = {
    // Task treatment
    [KeyOfStep.LOADING]: {id: KeyOfStep.LOADING, name: 'Loading'},
    [KeyOfStep.UNLOADING]: {id: KeyOfStep.UNLOADING, name: 'Unloading'},
    [KeyOfStep.TREATING_FRESHWATER]: {id: KeyOfStep.TREATING_FRESHWATER, name: 'Treating fresh water'},
    [KeyOfStep.TREATING_THERMOLICER]: {id: KeyOfStep.TREATING_THERMOLICER, name: 'Treating thermolicer'},
    [KeyOfStep.TREATING_OPTILICER]: {id: KeyOfStep.TREATING_OPTILICER, name: 'Treating optilicer'},
    [KeyOfStep.TREATING_WELLFIGHTER]: {id: KeyOfStep.TREATING_WELLFIGHTER, name: 'Treating wellfighter'},
    [KeyOfStep.TREATING_HYDROGENPEROXIDE]: {id: KeyOfStep.TREATING_HYDROGENPEROXIDE, name: 'Treating hydrogenperoxide'},
    [KeyOfStep.TREATING_SALTWATER]: {id: KeyOfStep.TREATING_SALTWATER, name: 'Treating saltwater'},
    [KeyOfStep.TREATING_FRESH_WELLFIGHTER]: {
        id: KeyOfStep.TREATING_FRESH_WELLFIGHTER,
        name: 'Treating fresh wellfighter'
    },
    [KeyOfStep.TREATING_SALMOSAN]: {id: KeyOfStep.TREATING_SALMOSAN, name: 'Treating salmosan'},
    [KeyOfStep.TREATING_FLS]: {id: KeyOfStep.TREATING_FLS, name: 'Treating FLS'},
    [KeyOfStep.RESTING]: {id: KeyOfStep.RESTING, name: 'Resting'},
    [KeyOfStep.TREATING_ECTOSAN]: {id: KeyOfStep.TREATING_ECTOSAN, name: 'Treating ectosan'},
    [KeyOfStep.SORTING]: {id: KeyOfStep.SORTING, name: 'Sorting'},

    // Task harvest
    [KeyOfStep.GO_TO_SITE]: {id: KeyOfStep.GO_TO_SITE, name: 'Travelling to site'},
    [KeyOfStep.ARRIVE_SITE]: {id: KeyOfStep.ARRIVE_SITE, name: 'Arrived at site'},
    [KeyOfStep.HARVEST]: {id: KeyOfStep.HARVEST, name: 'Harvesting'},
    [KeyOfStep.FINISHED_HARVEST]: {id: KeyOfStep.FINISHED_HARVEST, name: 'Finished harvesting'},
    [KeyOfStep.GO_TO_FACTORY]: {id: KeyOfStep.GO_TO_FACTORY, name: 'Travelling to factory'},
    [KeyOfStep.ARRIVE_FACTORY]: {id: KeyOfStep.ARRIVE_FACTORY, name: 'Arrived at factory'},
    [KeyOfStep.DISCHARGE_FACTORY]: {id: KeyOfStep.DISCHARGE_FACTORY, name: 'Discharging'},
    [KeyOfStep.FINISH_DISCHARGE_FACTORY]: {id: KeyOfStep.FINISH_DISCHARGE_FACTORY, name: 'Finished discharging'},
    [KeyOfStep.CLEANING_HARVEST]: {id: KeyOfStep.CLEANING_HARVEST, name: 'Cleaning'},
    [KeyOfStep.COMPLETED_HARVEST]: {id: KeyOfStep.COMPLETED_HARVEST, name: 'Completed'},

    // Task move
    [KeyOfStep.TRAVELING]: {id: KeyOfStep.TRAVELING, name: 'Travelling to unit'},
    [KeyOfStep.MOVING]: {id: KeyOfStep.MOVING, name: 'Transfer to unit'},
    [KeyOfStep.MOVED]: {id: KeyOfStep.MOVED, name: 'Arrived at unit'},

    // Global
    [KeyOfStep.CLEANING]: {id: KeyOfStep.CLEANING, name: 'Cleaning'},
    [KeyOfStep.COMPLETED]: {id: KeyOfStep.COMPLETED, name: 'Completed'},
}

export const harvestSteps = [
    listOfStep[KeyOfStep.GO_TO_SITE],
    listOfStep[KeyOfStep.ARRIVE_SITE],
    listOfStep[KeyOfStep.HARVEST],
    listOfStep[KeyOfStep.FINISHED_HARVEST],
    listOfStep[KeyOfStep.GO_TO_FACTORY],
    listOfStep[KeyOfStep.ARRIVE_FACTORY],
    listOfStep[KeyOfStep.DISCHARGE_FACTORY],
    listOfStep[KeyOfStep.FINISH_DISCHARGE_FACTORY],
    listOfStep[KeyOfStep.CLEANING_HARVEST],
    listOfStep[KeyOfStep.COMPLETED_HARVEST],
]

export const treatmentSteps = [
    listOfStep[KeyOfStep.LOADING],
    listOfStep[KeyOfStep.UNLOADING],
    listOfStep[KeyOfStep.TREATING_FRESHWATER],
    listOfStep[KeyOfStep.TREATING_THERMOLICER],
    listOfStep[KeyOfStep.TREATING_OPTILICER],
    listOfStep[KeyOfStep.TREATING_WELLFIGHTER],
    listOfStep[KeyOfStep.TREATING_HYDROGENPEROXIDE],
    listOfStep[KeyOfStep.TREATING_SALTWATER],
    listOfStep[KeyOfStep.TREATING_SALMOSAN],
    listOfStep[KeyOfStep.TREATING_ECTOSAN],
    listOfStep[KeyOfStep.TREATING_FLS],
    listOfStep[KeyOfStep.SORTING],
    listOfStep[KeyOfStep.CLEANING],
    listOfStep[KeyOfStep.COMPLETED],
]

export const transportSteps = [
    {id: 0, name: 'Travelling to smolt site'},
    {id: 1, name: 'Arrived at smolt Site'},
    {id: 2, name: 'Loading'},
    {id: 3, name: 'Finished loading'},
    {id: 4, name: 'Travelling to site'},
    {id: 5, name: 'Arrived at site',},
    {id: 6, name: 'Discharging'},
    {id: 7, name: 'Finished discharging'},
    {id: 8, name: 'Cleaning vessel'},
    {id: 9, name: 'Completed'},
]

export const eventSteps = [
    {id: 0, name: 'Start event'},
    {id: 1, name: 'Finish event'},
]

export const stepsFull: any = {
    [OPERATION_TYPE.HARVEST]: harvestSteps,
    [OPERATION_TYPE.TREATMENT]: treatmentSteps,
    [OPERATION_TYPE.TRANSPORT]: transportSteps,
    [OPERATION_TYPE.EVENT]: eventSteps,
    [OPERATION_TYPE.SERVICE]: []
}

export const productionArea: any = {
    1: {id: '1', name: 'Svenskegrensen til Jæren'},
    2: {id: '2', name: 'Ryfylke'},
    3: {id: '3', name: 'Karmøy til Sotra'},
    4: {id: '4', name: 'Nordhordland til Stadt'},
    5: {id: '5', name: 'Stadt til Hustadvika'},
    6: {id: '6', name: 'Nordmøre til Sør-Trøndelag'},
    7: {id: '7', name: 'Nord-Trøndelag med Bindal'},
    8: {id: '8', name: 'Helgeland til Bodø'},
    9: {id: '9', name: 'Vestfjorden og Vesterålen'},
    10: {id: '10', name: 'Andøya til Senja'},
    11: {id: '11', name: 'Kvaløya til Loppa'},
    12: {id: '12', name: 'Vest-Finnmark'},
    13: {id: '13', name: 'Øst-Finnmark'}
}

export const productionAreas = [
    productionArea[1],
    productionArea[2],
    productionArea[3],
    productionArea[4],
    productionArea[5],
    productionArea[6],
    productionArea[7],
    productionArea[8],
    productionArea[9],
    productionArea[10],
    productionArea[11],
    productionArea[12],
    productionArea[13],
]

export enum DELIVERY_TYPE {
    DIRECT = 'direct',
    WAITING_CAGE = 'waiting_cage',
    TANK = 'tank'
}

export const deliveryType = {
    [DELIVERY_TYPE.DIRECT]: {id: DELIVERY_TYPE.DIRECT, name: 'Direct'},
    [DELIVERY_TYPE.WAITING_CAGE]: {id: DELIVERY_TYPE.WAITING_CAGE, name: 'Waiting cage'},
    [DELIVERY_TYPE.TANK]: {id: DELIVERY_TYPE.TANK, name: 'Tank'},
}

export const deliveryTypes = [
    deliveryType[DELIVERY_TYPE.DIRECT],
    deliveryType[DELIVERY_TYPE.WAITING_CAGE],
    deliveryType[DELIVERY_TYPE.TANK],
]

export enum CERTAIN_TYPE {
    NONE = 'none',
    MEDIUM = 'medium',
    HIGH = 'high'
}

export const certainType: any = {
    [CERTAIN_TYPE.NONE]: {
        id: CERTAIN_TYPE.NONE,
        name: 'Low',
        icon: 'pin',
        fontColor: '#FFF',
        color: '#F15656',
        description: 'No setting'
    },
    [CERTAIN_TYPE.MEDIUM]: {
        id: CERTAIN_TYPE.MEDIUM,
        name: 'Medium',
        icon: 'pin',
        fontColor: '#002C4D',
        color: '#F4E5AF',
        description: 'Pretty sure this operation will happen'
    },
    [CERTAIN_TYPE.HIGH]: {
        id: CERTAIN_TYPE.HIGH,
        name: 'High',
        icon: 'pin',
        color: '#B3DBA0',
        fontColor: '#002C4D',
        description: 'This operation is going to happen absolutely'
    },
}

export const certainTypes = [
    certainType[CERTAIN_TYPE.NONE],
    certainType[CERTAIN_TYPE.MEDIUM],
    certainType[CERTAIN_TYPE.HIGH],
]

export const statusHideCertain = [
    OP_STATUS.CANCELED,
    OP_STATUS.PROCESSING,
    OP_STATUS.FINISHED
]

export enum KEY_STORAGE {
    AUTHENTICATION = 'authentication',
    BARENTS_SWATCH = 'barentsSwatch',
    STATE = 'state',
    POSITION = 'position',
    MODE_DRAG = 'modeDrag',
    UNITS_SELECTED = 'unitsSelected',
    REQUIRE_OPERATION_TYPE = 'requireOperationType',
    CONVERSATION = 'conversation',
    CHAT_BOX = 'chatbox',
    CHAT_TYPE = 'chatType',
    CLEAR_CHECK_BIOLOGY = 'clearCheckBiology',
    CANCEL_OP_SELECTED = 'cancelOpSelected',
    CANCEL_DRAG = 'cancelDrag',
    POSITION_DRAG = 'positionDrag',
    THEME = 'theme'
}

export const propsEmpty: any = {};

export function getParentEl(): HTMLElement {
    return (document.getElementsByClassName('view')[0] as HTMLElement) || document.body as HTMLElement;
}

export const propContainer: any = {
    getPopupContainer: getParentEl,
}

export const propsConfirm = {
    ...propContainer,
    okText: "Yes",
    cancelText: "No",
}

export const propsModal = {
    destroyOnClose: true,
    closable: false,
    keyboard: true,
    footer: null,
    centered: true,
    getContainer: getParentEl,
}

export enum PriorityTime {
    SITE,
    FACTORY
}

export const priorityTime = {
    [PriorityTime.SITE]: {id: PriorityTime.SITE, name: 'Site', description: 'Site’s arrival time'},
    [PriorityTime.FACTORY]: {id: PriorityTime.FACTORY, name: 'Factory', description: 'Factory’s arrival time'},
}

export const prioryTimes = [
    priorityTime[PriorityTime.SITE],
    priorityTime[PriorityTime.FACTORY],
]

export const initVesselConfig: { [id: string]: IVesselConfig } = {
    [VESSEL_TYPE.PROCESS_VESSEL]: {
        delivery_type: DELIVERY_TYPE.DIRECT,
        priority: PriorityTime.FACTORY,
        is_journey_to_site: false,
        cleaning_types: [DELIVERY_TYPE.DIRECT]
    },
    [VESSEL_TYPE.WELL_BOAT]: {
        delivery_type: DELIVERY_TYPE.WAITING_CAGE,
        priority: PriorityTime.SITE,
        is_journey_to_site: false,
        cleaning_types: [DELIVERY_TYPE.DIRECT]
    }
}

export const initCloseTransport = {
    process: true,
    wellboat: true
}

export const initAutoPlanConfig: {
    vessel: { [id: string]: IVesselConfig }
    close_transport: any,
    merge: boolean
} = {
    vessel: initVesselConfig,
    close_transport: initCloseTransport,
    merge: false
}

export enum DISEASED_ZONE {
    ILA_10_CIRCLE = 'ila10circle',
    ILA_PROTECTION = 'ilaprotection',
    ILA_SURVEILLANCE = 'ilasurveillance',
    PD_PROTECTION = 'pdprotection',
    PD_SURVEILLANCE = 'pdsurveillance',
    PD_ZONE = 'pdzone'
}

export enum DISEASE_TYPE {
    ISA = 'ILA',
    PD = 'PD'
}

export const diseaseType: any = {
    [DISEASE_TYPE.ISA]: {id: DISEASE_TYPE.ISA, name: 'ISA', color: '#7352DF', message: 'infectious salmon anemia'},
    [DISEASE_TYPE.PD]: {id: DISEASE_TYPE.PD, name: 'PD', color: '#EBCB49', message: 'pancreatic disease'},
}

export const diseasedZone: any = {
    [DISEASED_ZONE.ILA_10_CIRCLE]: {
        id: DISEASED_ZONE.ILA_10_CIRCLE,
        name: 'ISA 10 km circles',
        shortName: 'ISA 10 km circles',
        disease_type: DISEASE_TYPE.ISA,
        color: '#7352DF',
        zIndex: 2,
        description: `<div>Fish from aquaculture farms inside circle may not be exported to Australia.</div>
            <div>More info from the Norwegian Food Safety Authority, in Norwegian, is available on this webpage.</div>`
    },
    [DISEASED_ZONE.ILA_PROTECTION]: {
        id: DISEASED_ZONE.ILA_PROTECTION,
        name: 'ISA protection zones',
        shortName: 'ISA protection',
        disease_type: DISEASE_TYPE.ISA,
        color: '#C7502A',
        zIndex: 1,
        description: `<div>A zone established around an infected farm. An evaluation of risk for further spread of disease is carried out as basis for establishing the geographical demarcation of the zone. The protection zone may include other farms than the infected farm.</div>`
    },
    [DISEASED_ZONE.ILA_SURVEILLANCE]: {
        id: DISEASED_ZONE.ILA_SURVEILLANCE,
        name: 'ISA surveillance zones',
        shortName: 'ISA surveillance',
        disease_type: DISEASE_TYPE.ISA,
        color: '#D7AB68',
        zIndex: 0,
        description: `<div>A zone established around the protection zone. The geographical demarcation of the zone depends on epidemiological conditions, such as possible contact with the infected farm, routes of transportation, as well as topographic and hydrographic conditions in the area. There will be an increased level of surveillance of fish in the farms in order to detect any spread of the disease from the protections zone as soon as possible.</div>`
    },
    [DISEASED_ZONE.PD_PROTECTION]: {
        id: DISEASED_ZONE.PD_PROTECTION,
        name: 'PD protection zones',
        shortName: 'PD protection',
        disease_type: DISEASE_TYPE.PD,
        color: '#2381B3',
        zIndex: 2,
        description: `<div>A zone established around the protection zone. The geographical demarcation of the zone depends on epidemiological conditions, such as possible contact with the infected farm, routes of transportation, as well as topographic and hydrographic conditions in the area. There will be an increased level of surveillance of fish in the farms in order to detect any spread of the disease from the protections zone as soon as possible.</div>`
    },
    [DISEASED_ZONE.PD_SURVEILLANCE]: {
        id: DISEASED_ZONE.PD_SURVEILLANCE,
        name: 'PD surveillance zones',
        shortName: 'PD surveillance',
        disease_type: DISEASE_TYPE.PD,
        color: '#328DA2',
        zIndex: 1,
        description: `<div>A zone established around the protection zone. The geographical demarcation of the zone depends on epidemiological conditions, such as possible contact with the infected farm, routes of transportation, as well as topographic and hydrographic conditions in the area. There will be an increased level of surveillance of fish in the farms in order to detect any spread of the disease from the protections zone as soon as possible.</div>`
    },
    [DISEASED_ZONE.PD_ZONE]: {
        id: DISEASED_ZONE.PD_ZONE,
        name: 'PD zones',
        shortName: 'PD',
        disease_type: DISEASE_TYPE.PD,
        color: '#053057',
        zIndex: 0,
        description: `<div>The following applies in addition to general rules and other regulations:</div>
            <div>Requirements to transport of fish in sea</div>
            <div>Requirements to service ships, service personnel and equipment</div>
            <div>Countermeasures from confirmed PD</div>
            <div>Requirements to transport and handling of farming nets</div>`
    }
}

export const diseasedZones = [
    // diseasedZone[DISEASED_ZONE.ILA_10_CIRCLE],
    diseasedZone[DISEASED_ZONE.ILA_PROTECTION],
    diseasedZone[DISEASED_ZONE.ILA_SURVEILLANCE],
    diseasedZone[DISEASED_ZONE.PD_PROTECTION],
    diseasedZone[DISEASED_ZONE.PD_SURVEILLANCE],
    // diseasedZone[DISEASED_ZONE.PD_ZONE],
]

export enum KEY_TAB {
    PRODUCTION = 'production',
    HARVEST = 'harvest',
    SMOLT = 'smolt',
    CANCEL = 'cancel'
}

export enum PATHNAME {
    HOME = '/',
    PLAN = '/plan_operation',
    VESSEL = '/vessel',
    DASHBOARD = '/dashboard',
    OVERVIEW = '/overview',
    OPERATION = '/operation',
    ROUTE = '/route',
    CHECKLIST = '/checklist',
    WEATHER = '/weather',
    USER = '/user',
    FACTORY = '/factory',
    SITE_MONITORING = '/site_monitoring',
    PREFERENCE = '/preference',
    TASK_TYPE = '/task_type',
    OPERATION_SETTING = '/operation_setting',
    LOCATION = '/location',
    TRACKING_OP = '/tracking_op',
    COMBINED_TREATMENT = '/combined_operation',
    VERSION = '/version',
    MESSAGE = '/message',
    EQUIPMENT = '/equipment',
    DISEASE = '/disease',
    LOGIN = '/login',
    ACCESS_TOKEN = '/access_token',
    ACCESS_DENIED = "/403",
    ROUTE_TEST = 'route_test',
}

export enum MESSAGE {
    PUSH_OP = 'The moving hits spot operation so your operations may be pushed over spot ones. Please scroll over spot operations and check these changes.',
}

export const showAlert = {
    pushOp: () => {
        notification.warning({message: 'Warning', description: MESSAGE.PUSH_OP, duration: 6});
    }
}

export const signer = {
    [USER_ROLE.CREW]: {label: 'Crew', value: USER_ROLE.CREW},
    [USER_ROLE.SITE_MANAGER]: {label: 'Site manager', value: USER_ROLE.SITE_MANAGER},
    [USER_ROLE.FACTORY_MANAGER]: {label: 'Factory manager', value: USER_ROLE.FACTORY_MANAGER},
    [USER_ROLE.CUSTOMER]: {label: 'Customer', value: USER_ROLE.CUSTOMER},
}

export const signers = [
    signer[USER_ROLE.CREW],
    signer[USER_ROLE.SITE_MANAGER],
    signer[USER_ROLE.FACTORY_MANAGER],
    signer[USER_ROLE.CUSTOMER],
]

export enum KeyOfTask {
    TYPE = 'type',
    TREATMENT_TYPE = 'treatment_type',
    CONDITIONS = 'conditions',
    SUB_TASKS = 'sub_tasks',
    SUB_TYPES = 'sub_types',
    DESTINATION_ID = 'destination_id',
    DESTINATION_NAME = 'destination_name',
    DESTINATION_FIELD = 'destination_field',
    DELIVERY_TYPES = 'delivery_types',
    SUB_OPERATION = 'sub_operation',
    SUB_OPERATIONS = 'sub_operations',
    TANK_ID = 'middle_tank',
    TANK_NAME = 'middle_tank_name',
    ERROR = 'error',

    IS_SUPPORT_VESSEL = 'is_support_vessel',
    FACTORY = 'FACTORY'
}

export enum NOTE_TYPE {
    DAY = 'day',
    WEEK = 'week'
}

export enum STORAGE_TYPE {
    TANK = 'tank'
}

export enum OPERATION_MODE {
    PLAN = 'plan',
    ETA = 'eta',
}

export const operationMode = {
    [OPERATION_MODE.PLAN]: {id: OPERATION_MODE.PLAN, name: 'Planned time'},
    [OPERATION_MODE.ETA]: {id: OPERATION_MODE.ETA, name: 'ETA time'},
}

export const operationModes = [
    operationMode[OPERATION_MODE.PLAN],
    operationMode[OPERATION_MODE.ETA]
]

export enum VALIDATION_STATUS {
    VALID,
    WARN,
    ERROR,
}

export enum OPERATION_CONFIRM_STATUS {
    NEW = 1,
    UPDATE,
}

export enum CHAT_TYPE {
    USER = 'user-chat',
    OPERATION = 'operation-chat'
}

export enum MESSAGE_STATUS {
    SENDING = 'sending',
    ERROR = 'error',
    SENT = 'sent',
    UNREAD = 'unread',
    READ = 'read',
    NEW = 'new'
}

export enum THEME_COLOR {
    LIGHT = 'light',
    DARK = 'dark'
}

export enum WEATHER_STATUS {
    BAD,
    MODERATE,
    GOOD
}

export enum COMBINE_TREATMENT_LEVEL {
    LOCAL = 'local',
    GLOBAL = 'global'
}

export const combineTreatmentLevel = {
    [COMBINE_TREATMENT_LEVEL.LOCAL]: {
        id: COMBINE_TREATMENT_LEVEL.LOCAL,
        name: 'Internal using',
        description: 'It will be shown on your own, hired vessels. Other companies will not see it.'
    },
    [COMBINE_TREATMENT_LEVEL.GLOBAL]: {
        id: COMBINE_TREATMENT_LEVEL.GLOBAL,
        name: 'Global sharing',
        description: 'It will be shown on your own, hired vessels. Other companies, who is hiring your vessel, will see and be able to use it.'
    }
}

export const combineTreatmentLevels = [
    combineTreatmentLevel[COMBINE_TREATMENT_LEVEL.LOCAL],
    combineTreatmentLevel[COMBINE_TREATMENT_LEVEL.GLOBAL],
]

export enum LOG_STATUS {
    UPDATE = 'updated',
    CREATE = 'created',
    DELETE = 'deleted',
    CANCEL = 'canceled',
    ADDED = 'added'
}

export enum KeyChangeLog {
    DURATION = 'duration',
    START_TIME = 'est_start_time',
    FINISH_TIME = 'est_finish_time',
    SUB_OPS = 'sub_operations',
    STATUS = 'status',
    TASKS = 'tasks',
    NOTE = 'note',
    FISH_AMOUNT = 'fish_amount',
    TOTAL_WEIGHT = 'total_weight',
    DELIVERY_TYPES = 'delivery_types',
    VESSEL_NAME = 'vessel_name',
    CLEANING = 'is_cleaning',
    CERTAIN_MODE = 'certain_mode',
    EVENT_TYPE = 'event_type',
    PARALLEL = 'parallel',
    FACTORY = 'factory',
    DELAY_TIME = 'delay_time',
    ROUTER_ID = 'router_id',
    FILES = 'files'
}

export const changeLog: any = {
    [KeyChangeLog.DURATION]: {id: KeyChangeLog.DURATION, name: 'Duration'},
    [KeyChangeLog.START_TIME]: {id: KeyChangeLog.DURATION, name: 'Start time'},
    [KeyChangeLog.FINISH_TIME]: {id: KeyChangeLog.DURATION, name: 'Finish time'},
    [KeyChangeLog.SUB_OPS]: {id: KeyChangeLog.DURATION, name: 'Units'},
    [KeyChangeLog.STATUS]: {id: KeyChangeLog.DURATION, name: 'Status'},
    [KeyChangeLog.TOTAL_WEIGHT]: {id: KeyChangeLog.TOTAL_WEIGHT, name: 'Total weight'},
    [KeyChangeLog.DELIVERY_TYPES]: {id: KeyChangeLog.DELIVERY_TYPES, name: 'Delivery method'},
    [KeyChangeLog.VESSEL_NAME]: {id: KeyChangeLog.VESSEL_NAME, name: 'Vessel'},
    [KeyChangeLog.NOTE]: {id: KeyChangeLog.VESSEL_NAME, name: 'Note'},
    [KeyChangeLog.CLEANING]: {id: KeyChangeLog.CLEANING, name: 'Cleaning'},
    [KeyChangeLog.CERTAIN_MODE]: {id: KeyChangeLog.CERTAIN_MODE, name: 'Certain mode'},
    [KeyChangeLog.EVENT_TYPE]: {id: KeyChangeLog.EVENT_TYPE, name: 'Type'},
    [KeyChangeLog.PARALLEL]: {id: KeyChangeLog.EVENT_TYPE, name: 'Parallel event'},
    [KeyChangeLog.DELAY_TIME]: {id: KeyChangeLog.EVENT_TYPE, name: 'Add delay time'},
}

export enum NotificationType {
    Operation = 'operation',
    Contract = 'contract'
}

export enum LineStatus {
    Up = 'up',
    Down = 'down',
    NoChange = 'equal'
}

export const lineStatus: { [id: string]: { id: LineStatus, nameCss: string } } = {
    [LineStatus.Up]: {id: LineStatus.Up, nameCss: 'up'},
    [LineStatus.Down]: {id: LineStatus.Down, nameCss: 'down'},
    [LineStatus.NoChange]: {id: LineStatus.NoChange, nameCss: 'no-change'},
}

export enum LineKey {
    licePerFish = 'total_lice',
    adultFemaleLice = 'female_lice',
    mobileLice = 'mobile_lice',
    stationaryLice = 'stationary_lice'
}

export const liceInfo = {
    [LineKey.licePerFish]: {
        id: LineKey.licePerFish,
        name: 'Lice per fish (total)',
        sum: [LineKey.adultFemaleLice, LineKey.mobileLice, LineKey.stationaryLice]
    },
    [LineKey.adultFemaleLice]: {id: LineKey.adultFemaleLice, name: 'Adult female lice'},
    [LineKey.mobileLice]: {id: LineKey.mobileLice, name: 'Mobile license'},
    [LineKey.stationaryLice]: {id: LineKey.stationaryLice, name: 'Stationary lice'},
}

export const liceOpts: {
    id: LineKey,
    name: string,
    sum?: LineKey[]
}[] = [
    liceInfo[LineKey.licePerFish],
    liceInfo[LineKey.stationaryLice],
    liceInfo[LineKey.mobileLice],
    liceInfo[LineKey.adultFemaleLice],
]

export enum ROUND_JOB {
    Traveling = 'traveling',
    BufferTime = 'buffer',
    GetWater = 'get_water',
    Cleaning = 'cleaning'
}

export const roundJob: { [id: string]: { id: ROUND_JOB, name: string } } = {
    [ROUND_JOB.Traveling]: {id: ROUND_JOB.Traveling, name: 'Traveling'},
    [ROUND_JOB.BufferTime]: {id: ROUND_JOB.BufferTime, name: 'Buffer time'},
    [ROUND_JOB.GetWater]: {id: ROUND_JOB.GetWater, name: 'Get water'},
    [ROUND_JOB.Cleaning]: {id: ROUND_JOB.Cleaning, name: 'Cleaning'},
}