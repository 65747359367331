import React, {useEffect, useRef, useState} from 'react';
import {
    aHourMillisecond,
    CERTAIN_TYPE,
    EVENT_TYPE,
    eventTypes,
    OPERATION_TYPE,
    propContainer,
    SHORT_DATETIME_FORMAT
} from "../../../../../../util/varibles/constants";
import {disabledDate, layout} from "../../../Vessels/constants";
import {Button, Checkbox, DatePicker, Form, Input, Select} from "antd";
import styles from "./style.module.scss";
import {FormInstance} from "antd/lib/form";
import {EL_RULE} from "../../../../Plan/Calendar/constants";
import Certain from "../../_Component/Certain";
import {datetime} from "../../../../../../util/library/datetime";
import {renderOpts} from "../../../../../../util/varibles/global";
import TextArea from "antd/es/input/TextArea";
import {useSelector} from "react-redux";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import Location from "../../_Component/Location";
import {Rule} from "../../../../../../util/varibles/validation";
import {selectTenantId} from "../../../../../../util/store/selectors";
import {getEditOp, useEditOp} from "../../../../../../contexts/EditOperationContext";
import {IOperation} from "../../../../../../util/varibles/interface";

const {RangePicker} = DatePicker;

let contract: any;

interface IProps {
    loading?: boolean

    onClose(): void;
}

const Event: React.FC<IProps> = (props) => {
    const formRef = useRef<FormInstance>(null);
    const [certainMode, setCertainMode] = useState(CERTAIN_TYPE.NONE);
    const {loading} = props;
    const userTenantId = useSelector(selectTenantId);
    const get = getEditOp();
    const [operation] = useEditOp(state => state.operation);

    useEffect(() => {
        const {activity_log} = get();
        contract = undefined;

        const {
            event_type: eventType = null,
            note = null,
            parallel,
            certain_mode = CERTAIN_TYPE.NONE,
            pois = []
        } = operation || {}
        setCertainMode(certain_mode);
        const [start, finish] = activity_log
        const time: any = [];

        let isDuration = 0;
        if (start && start.est_start_time) {
            isDuration = 1;
            time.push(datetime(start.est_start_time).value);
        }
        if (finish && finish.est_start_time) {
            isDuration = 2;
            time.push(datetime(finish.est_start_time).value);
        }
        const duration = isDuration === 2 ? (finish.est_start_time - start.est_start_time) / aHourMillisecond : null

        setTimeout(() => {
            formRef.current?.setFieldsValue({time, eventType, note, duration, parallel, pois});
        }, 10)
    }, [get]);

    const handleSubmit = (form: any) => {
        const {id, available_time_id = '-', contract_id = '-'} = operation || {id: null}
        const {time, eventType, note = '', parallel = false, pois = []} = form;
        const {vessel, onSave} = get();

        if (!time[0] || !time[1]) {
            notify.error(NotifyCode.E29)();
            return;
        }

        if (!vessel)
            return;

        const start = datetime(time[0]).time;
        const finish = datetime(time[1]).time;

        const data = {
            vessel,
            operation: {
                ...operation,
                id: id || '',
                est_start_time: start,
                est_finish_time: finish,
                operation_type: OPERATION_TYPE.EVENT,
                event_type: eventType,
                tenant_id: userTenantId,
                note,
                available_time_id,
                contract_id,
                certain_mode: certainMode,
                parallel
            },
            activity_log: [
                {est_start_time: start},
                {est_start_time: finish}
            ]
        } as IOperation;

        if (pois?.length > 0) {
            data.operation.pois = pois;
        } else {
            delete data.operation.pois;
        }

        if (contract) {
            const {data: currentValue, rule} = contract;
            if (rule === EL_RULE.AVAILABLE_TIME) {
                data.operation.available_time_id = currentValue.id;
            } else {
                data.operation.contract_id = currentValue.id;
            }
        }

        onSave(id ? {update: {[id]: data}} : {add: {new: data}});
        if (formRef.current)
            formRef.current.resetFields();
    }

    const handleChangeTime = (value: any) => {
        const [startTime, finishTime] = value;
        const duration = Math.floor(((finishTime - startTime) / (3600 * 1000) * 100)) / 100;
        if (formRef.current)
            formRef.current.setFieldsValue({duration})
    }

    const handleChangeDuration = (e: any) => {
        if (!formRef.current)
            return;
        const {value} = e.target
        const duration = Number(value) * 3600 * 1000;
        const time = formRef.current.getFieldValue('time');
        if (time[0] === undefined) return;
        time[1] = datetime(time[0].valueOf() + duration).value;
        formRef.current.setFieldsValue({time: [...time]});
    }

    return <Form {...{
        ...layout,
        className: styles['container-event'],
        ref: formRef,
        name: 'event',
        layout: 'vertical',
        onFinish: handleSubmit
    }}>
        <div className={styles['input-group']}>
            <div data-lev='label'>Certainty</div>
            <Certain
                className='w-full'
                isLabel={false}
                value={certainMode}
                onChange={(certain_mode: any) => setCertainMode(certain_mode)}
            />
        </div>
        <Form.Item label="Type" name='eventType' rules={[Rule.Require('Type')]}>
            <Select className='h-36 select-single' options={renderOpts(eventTypes)}/>
        </Form.Item>
        <Form.Item
            name='parallel'
            valuePropName='checked'
            extra={<div className='text-comment line-h-18 italic'>
                Select this checkbox means to allow planning another operation in the same time with this event.
            </div>}
        >
            <Checkbox>Parallel event</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, current) => prev.eventType !== current.eventType}>
            {({getFieldValue}) => {
                const eventType = getFieldValue('eventType') || []
                if (eventType !== EVENT_TYPE.GET_WATER)
                    return null;
                return <Form.Item label="Location" name='pois'>
                    <Location/>
                </Form.Item>
            }}
        </Form.Item>
        <Form.Item
            label="Start time - Finish time" name="time"
            rules={[Rule.Requires('Start time and Finish time')]}
        >
            <RangePicker {...{
                ...propContainer,
                className: 'w-full h-36',
                allowClear: false,
                showTime: {format: 'HH:mm', minuteStep: 15},
                disabledDate,
                format: SHORT_DATETIME_FORMAT,
                placeholder: ['Start time', 'Finish time'],
                onChange: handleChangeTime,
            }} />
        </Form.Item>

        <Form.Item label="Duration" name="duration">
            <Input className='h-36' suffix="Hour(s)" onChange={handleChangeDuration}/>
        </Form.Item>
        <Form.Item label="Note" name="note">
            <TextArea rows={3}/>
        </Form.Item>
        <Button
            className={['bt-primary', styles.btSubmit].join(' ')}
            data-full='true'
            htmlType='submit'
            loading={loading}
        >
            Save
        </Button>
    </Form>;
};

export default Event;