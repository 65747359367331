import React, {useCallback} from 'react';
import {ACTION_TYPE, OP_STATUS, OPERATION_TYPE} from "../../../../../../../util/varibles/constants";
import WrapperOperation from "../Component/WrapperOperation";
import stylesContainer from "../style.module.scss";
import EventView from '../../../../../_Component/ContentOperation/EventView';
import {IPropsOp} from "../index";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";

interface IProps extends IPropsOp {
    operationType: OPERATION_TYPE
    isOwn: boolean
    isVesselOwn: boolean
    permission: any
    status: OP_STATUS
}

const Event: React.FC<IProps> = (props) => {
    const {isOwn, data} = props;

    const edit = useCallback(() => {
        return {
            isShow: true,
            operationType: OPERATION_TYPE.EVENT,
            editMode: ACTION_TYPE.EDIT,
            values: data,
        }
    }, [data])


    const icon = <Icon icon={ICON_NAME.EVENT} dataAttribute={{'data-event-type': data.operation.event_type}}/>

    return <WrapperOperation {...{...props, icon, edit}}>
        <div className={[stylesContainer['content-wrapper'], stylesContainer.layout].join(' ')}>
            <EventView
                status={props.status}
                data={data}
                isOwn={isOwn}
            />
        </div>
    </WrapperOperation>;
};

export default Event;