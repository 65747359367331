import React, {useState} from 'react';
import {IElement} from "../constants";
import styles from './style.module.scss';
import MenuChecklist from "./MenuChecklist";
import {ELEMENT_BASIC, ELEMENT_DEFINED, ELEMENT_PAGE} from "../Element/constants";
import HeaderElement from "../Element/HeaderElement";
import ShortTextElement from "../Element/ShortTextElement";
import LongTextElement from "../Element/LongTextElement";
import NumberElement from "../Element/NumberElement";
import DropdownElement from "../Element/DropdownElement";
import RadioElement from "../Element/RadioElement";
import CheckboxElement from "../Element/CheckboxElement";
import DateElement from "../Element/DateElement";
import TimeElement from "../Element/TimeElement";
import Footer from "../Element/Footer";
import Tags from "../Element/Tags";
import SignElement from "../Element/SignElement";
import {IChecklist} from "../../../util/varibles/interface";
import Divide from "../Element/Divide";
import {Spin} from "antd";
import {useDispatch} from "react-redux";
import {checklistActions} from "../reducer";

interface IProps extends IChecklist {
}

const ViewChecklist: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const {content} = props;
    const dispatch = useDispatch();

    const renderElement = () => {
        const data = JSON.parse(content)
        if (data.length > 0)
            return data.map((element: IElement, index: number) => {
                const {type} = element;
                let props: any = {
                    index,
                    isAllowSelect: false,
                    ...element
                }
                switch (type) {
                    case ELEMENT_BASIC.HEADER: {
                        return <HeaderElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.SHORT_TEXT: {
                        return <ShortTextElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.LONG_TEXT: {
                        return <LongTextElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.NUMBER: {
                        return <NumberElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.DROPDOWN: {
                        return <DropdownElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.RADIO: {
                        return <RadioElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.CHECKBOX: {
                        return <CheckboxElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.DATE: {
                        return <DateElement {...props} key={index}/>
                    }
                    case ELEMENT_BASIC.TIME: {
                        return <TimeElement {...props} key={index}/>
                    }
                    case ELEMENT_PAGE.DIVIDE: {
                        return <Divide {...props} key={index}/>
                    }
                    case ELEMENT_DEFINED.SIGN: {
                        return <SignElement {...props} key={index}/>
                    }
                    default:
                        return null
                }
            })
        else
            return null
    }

    const handleUpdate = (data: IChecklist) => {
        dispatch(checklistActions.update(data))
    }


    return <div className={styles.container}>
        <Spin spinning={loading}>
            <div className={styles.checklist}>
                <div className={styles.header}>
                    <Tags onSave={handleUpdate} isSend={true} checklist={props}/>
                    <MenuChecklist {...props} loading={loading} setLoading={setLoading}/>
                </div>
                <div className={styles.form}>
                    {renderElement()}
                    <Footer onSave={handleUpdate} isSend={true} checklist={props}/>
                </div>
            </div>
        </Spin>
    </div>;
};

export default ViewChecklist;

