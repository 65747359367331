import React from 'react';
import styles from "./style.module.scss";

interface IProps {
}

const Empty: React.FC<IProps> = () => {
    return <div className={styles.wrapper}>
        <div>Vessel has no activity this week</div>
    </div>;
};

export default Empty;
