import React, {Component} from 'react';
import {connect} from "react-redux";
import Checklists from "./Checklists";
import {checklistActions} from "./reducer";

const mapStateToProps = () => ({});

interface IProps {
    getServiceTasks(): void

    getCheckLists(): void
}

class ChecklistPage extends Component<IProps> {

    constructor(props: IProps) {
        super(props);
        this.props.getCheckLists();
        this.props.getServiceTasks();
    }

    render() {
        return <Checklists/>;
    }
}

export default connect(mapStateToProps, {
    getServiceTasks: checklistActions.getServiceTasksRequest,
    getCheckLists: checklistActions.getListRequest
})(ChecklistPage);
