import React, {useEffect, useState} from 'react';
import {ACTION_TYPE, getParentEl, propsModal} from "../../../util/varibles/constants";
import styles from "./style.module.scss";
import {IChecklist} from "../../../util/varibles/interface";
import {Button, Drawer} from "antd";
import Header from "../../../components/Popup/Component/Header";
import LeftSide from "./LeftSide";
import MiddleSide from "./MiddleSide";
import RightSide from "./RightSide";
import {IElement} from "../constants";
import {createChecklistFetch, updateChecklistFetch} from "../../../util/services/checklist";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";
import {connect} from "react-redux";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    checklists: state.checklist.checklists,
    userId: state.login.user.user_id
});

interface IProps {
    checklists: IChecklist[]
    userId: string
    checklist?: IChecklist
    editMode: ACTION_TYPE

    onSave(checklist: IChecklist): void

    onClose?(): void
}

const PopupEdit: React.FC<IProps> = (props) => {
    const {editMode, checklists, onSave, onClose} = props;
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<any>();
    const [rightOpen, setRightOpen] = useState(true);
    const [checklist, setChecklist] = useState<any>();

    useEffect(() => {
        if (props.checklist)
            setChecklist(props.checklist)
        else {
            const idLast = checklists.reduce((id: number, item) => {
                const currentId = item.id.split('#');
                if (id < Number(currentId[0]))
                    id = Number(currentId[0])
                return id;
            }, 0)
            setChecklist({
                id: (idLast + 1) + '#' + Date.now(),
                tags: [],
                content: JSON.stringify([]),
                vessel_id: '-',
                operation_type: -1,
                step: -1
            })
        }
    }, [props.checklist]);


    const title = editMode === ACTION_TYPE.CREATE ? 'Create checklist' : 'Update checklist';

    const handleSubmit = () => {
        let fetch: any, message: NotifyCode, value = {...checklist};
        if (editMode === ACTION_TYPE.CREATE) {
            fetch = createChecklistFetch;
            message = NotifyCode.S1;
        } else {
            fetch = updateChecklistFetch
            message = NotifyCode.S2;
        }
        const {id} = checklist || {};
        setLoading(true);
        new Promise(resolve => resolve(fetch(value)))
            .then((rs: any) => {
                setLoading(false);
                notify.success(message)(['The checklist']);
                onSave({...rs, oldId: id});
                handleClose();
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse(title + ' failed', error);
            })
    }

    const handleDelete = (index: number) => {
        const {content} = checklist || {};
        let elements = JSON.parse(content || '""');
        elements.splice(index, 1);
        setChecklist({...checklist, content: JSON.stringify(elements)})
        setSelected(undefined);
    }

    const handleSelect = (value: any) => {
        setSelected(value);
        setRightOpen(true);
    }

    const handleAdd = (element: IElement, index: number | null) => {
        const {content} = checklist || {};
        let elements = JSON.parse(content || '""');
        if (index !== null) {
            index++;
            elements.splice(index, 0, element);
        } else
            elements = [...elements, element];

        setChecklist({...checklist || {}, content: JSON.stringify(elements)})
        setSelected({...element, index: index || elements.length - 1})
        setRightOpen(true);
    }

    const handleUpdate = (element: IElement, index: number) => {
        const {content} = checklist || {};
        let elements = JSON.parse(content || '""');
        elements.splice(index, 1, element);
        setChecklist({...checklist || {}, content: JSON.stringify(elements)});
        setSelected({...element, index})
    }

    const handleClose = () => {
        setVisible(false);
        if (onClose)
            onClose();
    }

    return <Drawer
        {...propsModal}
        title={<Header title={<div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <div className={styles.action}>
                <Button className='bt-default' onClick={handleClose}>Cancel</Button>
                <Button className='bt-primary' loading={loading} onClick={handleSubmit}>Save</Button>
            </div>
        </div>} size='large' onClose={handleClose}/>}
        getContainer={getParentEl}
        width={'100%'}
        className={styles.modal}
        open={visible}
        onClose={handleClose}
    >
        <div className={styles.dialog}>
            <div className={styles['dialog-body']}>
                <LeftSide onAdd={handleAdd}/>
                <MiddleSide
                    selected={selected}
                    checklist={checklist}
                    onDelete={handleDelete}
                    onSelect={handleSelect}
                    setChecklist={setChecklist}
                />
                <RightSide
                    selected={selected}
                    rightOpen={rightOpen}
                    setRightOpen={setRightOpen}
                    onDelete={handleDelete}
                    onAdd={handleAdd}
                    onUpdate={handleUpdate}
                />
            </div>
        </div>
    </Drawer>
};

export default connect(mapStateToProps, {})(PopupEdit);

