import React, {useEffect} from 'react';
import styles from "./style.module.scss";
import {getKeyWeek} from "../../../util/varibles/global";
import Header from "./Header";
import Schedule from "./Schedule";
import {CALENDAR_ID} from "../constants";

interface IProps {
}

const Calendar: React.FC<IProps> = () => {
    useEffect(() => {
        setTimeout(() => {
            const week = getKeyWeek(Date.now())
            const element = document.getElementById(week);
            if (element) {
                const {offsetLeft} = element;
                const calendar = document.getElementById(CALENDAR_ID);
                if (calendar)
                    calendar.scrollLeft = offsetLeft - 162;
            }
        }, 100)
    }, []);


    return <div id={CALENDAR_ID} className={styles.calendar}>
        <div className={styles.screen}>
            <Header/>
            <Schedule/>
        </div>
    </div>
};

export default Calendar;

