import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import {ILocation} from "../../../util/varibles/interface";
import Header from "../../../components/Popup/Component/Header";
import Footer from "../../../components/Popup/Component/Footer";
import {LOCATION_TYPE, propsModal} from "../../../util/varibles/constants";
import {PoiService} from "../../../util/services/poi";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {filterOption, renderOpts, showErrorResponse} from "../../../util/varibles/global";
import {Rule} from "../../../util/varibles/validation";
import {FormInstance} from "antd/lib/form";
import {locationActions} from "../../../util/store/location/reducer";
import {selectRegion} from "../../../util/store/region/constants";
import styles from '../style.module.scss';

const actions = {
    true: {
        api: PoiService.create,
        handle: locationActions.add,
        successCode: NotifyCode.S1,
        errorCode: NotifyCode.E22,
    },
    false: {
        api: PoiService.update,
        handle: locationActions.update,
        successCode: NotifyCode.S2,
        errorCode: NotifyCode.E35,
    }
}

export const parserNumber = (val?: string) => {
    if (!val) return 0;
    return Number.parseFloat(val.replace(/\$\s?/g, "").replace(/(,)/g, "."))
}

interface IProps {
    location?: ILocation

    onClose(): void
}

const PopupEdit: React.FC<IProps> = ({location, onClose}) => {
    const formRef = React.createRef<FormInstance>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(true);
    const regions = useSelector(selectRegion.regions);
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        setOpen(false);
        setTimeout(() => onClose(), 300);
    }, [onClose])
    const isNew = !location;

    const handleSubmit = (values: any) => {
        const {position, work_time} = values;
        const workTime = Number(work_time);
        const latitude = Number(position?.latitude) || 0;
        const longitude = Number(position?.longitude) || 0;
        const params = {
            ...location || {},
            ...values,
            type: LOCATION_TYPE.POI,
            position: {latitude, longitude},
            capabilities: [],
            work_time: isNaN(workTime) ? '-' : workTime * 60
        }
        const {api, handle, successCode, errorCode} = actions[`${isNew}`];
        setLoading(true);
        new Promise(resolve => resolve(api(params)))
            .then(rs => {
                setLoading(false);
                notify.success(successCode)([params.name]);
                dispatch(handle(rs));
                handleClose();
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse(notify[errorCode]([params.name]), error);
            })
    }

    const {work_time = '-'} = location || {};

    return <Modal
        {...propsModal}
        className={styles.popupEdit}
        open={open}
        onCancel={handleClose}
        title={<Header title={isNew ? 'New POI location' : 'Update location'} onClose={handleClose}/>}
        width={900}
    >
        <Form
            className="form"
            ref={formRef}
            onFinish={handleSubmit} layout='vertical'
            initialValues={{
                ...location || {},
                work_time: work_time !== '-' ? Math.round(work_time / 60 * 100) / 100 : ''
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="POI name" name="name" rules={[Rule.Require('Name')]}>
                        <Input className='border-r-40'/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="Latitude" name={['position', 'latitude']}
                               rules={[Rule.Require('Latitude'), Rule.Number()]}>
                        <InputNumber className='border-r-40 w-full' min={-90} max={90} parser={parserNumber}
                                     controls={false}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Longitude" name={['position', 'longitude']}
                               rules={[Rule.Require('Longitude'), Rule.Number()]}>
                        <InputNumber className='border-r-40 w-full' min={-180} max={180} parser={parserNumber}
                                     controls={false}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="Phone" name="phone">
                        <Input className='border-r-40'/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Working time" name="work_time">
                        <Input className='border-r-40 pl-16' suffix="hours"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label="Region" name="region_id" rules={[Rule.Require('Region')]}>
                        <Select
                            className='select-single'
                            placeholder='Region'
                            filterOption={filterOption}
                            options={renderOpts(regions)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Footer
                cancel={{click: handleClose}}
                ok={{loading, text: isNew ? 'Create new POI' : 'Save'}}
            />
        </Form>
    </Modal>;
};

export default PopupEdit;