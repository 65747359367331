import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {VesselService} from "../../util/services/vessel";
import {getChecklistsByUserFetch} from "../../util/services/checklist";
import {CapabilityService} from "../../util/services/capability";
import {checklistActions} from "./reducer";
import {AppState} from "../../util/store/store";

const getUserId = (state: AppState) => state.login.user.user_id;

function* getChecklistWatcher(): Generator<any, any, any> {
    try {
        const userId: string = yield select(getUserId);
        const checklists = yield call(getChecklistsByUserFetch, userId);
        yield put(checklistActions.getListSuccess(checklists));
        const vessels = yield call(VesselService.gets) || [];
        yield put(checklistActions.getVessels(vessels));
    } catch (error) {
        yield put(checklistActions.getListFailure());
    }
}

function* getTaskOfServiceWatcher(): Generator<any, any, any> {
    try {
        const serviceTasks = yield call(CapabilityService.serviceTasks);
        yield put(checklistActions.getServiceTasksSuccess(serviceTasks));
    } catch (error) {
        yield put(checklistActions.getServiceTasksFailure());
    }
}

export default function* checklistSaga() {
    yield all([
        takeEvery(checklistActions.getListRequest.type, getChecklistWatcher),
        takeEvery(checklistActions.getServiceTasksRequest.type, getTaskOfServiceWatcher)
    ])
}
