import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {INoteCalender} from "../../../../util/varibles/interface";
import {connect} from "react-redux";
import {createKeyOfNote} from "../../constants";
import {NOTE_TYPE} from "../../../../util/varibles/constants";
import Note from "../Note";
import {openNoteCalendar} from "../Note/PopupNote";
import {datetime} from "../../../../util/library/datetime";
import {overviewActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState, props: IRootProps) => {
    const {notes} = state.overview;
    const {time} = props;
    return {
        notes: notes[createKeyOfNote(time, NOTE_TYPE.DAY)]
    }
};

interface IRootProps {
    time: number
    notes?: INoteCalender[]
}

interface IProps extends IRootProps {
    updateNotesCalendar(payload: INoteCalender[]): void

    deleteMotesCalendar(payload: INoteCalender[]): void
}

const Weekday: React.FC<IProps> = (props) => {
    const {time, notes = [], updateNotesCalendar, deleteMotesCalendar} = props;

    const handleOpenNote = useCallback(
        () => openNoteCalendar({
            notes,
            time,
            level: NOTE_TYPE.DAY,
            onSave: updateNotesCalendar,
            onDelete: () => deleteMotesCalendar(notes)
        }), [time, notes, updateNotesCalendar, deleteMotesCalendar]);

    return <Note notes={notes}>
        <div
            className={styles.weekday}
            data-note={notes.length > 0}
            onClick={handleOpenNote}
        >
            <div>
                {datetime(time).format('ddd DD').split(' ').map((item: string) => <span key={item}>{item}</span>)}
            </div>
        </div>
    </Note>;
};

export default connect(mapStateToProps, {
    updateNotesCalendar: overviewActions.updateNotesCalendar,
    deleteMotesCalendar: overviewActions.deleteNotesCalendar
})(Weekday);

