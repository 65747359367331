import {IRegion} from "../../varibles/interface";
import {AppState} from "../store";

export const NAME_SLICE = 'region'

export interface RegionState {
    data: IRegion[]

    loading: boolean
}

export const initialState: RegionState = {
    data: [],

    loading: false,
}

// Location
export const selectRegion = {
    regions: (state: AppState) => state[NAME_SLICE].data,
    loading: (state: AppState) => state[NAME_SLICE].loading
}