import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import {CERTAIN_TYPE, certainType, statusHideCertain} from "../../../../../util/varibles/constants";
import {LoginState} from "../../../../../pages/LoginPage/constants";
import {IStateGlobal} from "../../../../../util/varibles/interface";

const mapStateToProps = (state: IStateGlobal) => ({
    login: state.login
});

interface IProps {
    login: LoginState
    operation: any
    status: any
}

class Certain extends Component<IProps> {
    render() {
        const {status} = this.props;
        const {certain_mode = CERTAIN_TYPE.NONE} = this.props.operation;
        const {isCertainty = false} = this.props.login.user.setting || {}
        if (statusHideCertain.indexOf(status) !== -1 || !isCertainty)
            return null;

        const {icon, name, color} = certainType[certain_mode] || {icon: null, name: '', color: ''};
        const style: any = {'--color': color}
        return <div className={styles['certain']} data-icon={icon} title={name} style={style}/>
    }
}

export default connect(
    mapStateToProps,
)(Certain);
