import React, {ReactNode} from 'react';
import {Provider} from "react-redux";
import {store} from "../../util/store/store";

interface IProps {
    children: ReactNode
}

const WrapperStore: React.FC<IProps> = ({children}) => {
    return <Provider store={store}>
        {children}
    </Provider>;
};

export default WrapperStore;