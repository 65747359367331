import React, {createRef, useEffect} from 'react';
import {IPropsChildren} from "../../../../../util/varibles/interface";
import styles from "./style.module.scss";

interface IProps extends IPropsChildren {
}

const BoxDrag: React.FC<IProps> = ({children}) => {
    const rootRef = createRef<HTMLDivElement>()

    useEffect(() => {
        // const el = rootRef.current;
        // if (el) {
        //     if (isDrag)
        //         el.addEventListener('mousedown', this.handleMouseDown)
        // const popupEl = getElById('popup-operation');
        // elementModal = elParent.getElementsByClassName('ant-modal')[0];
        // const {offsetLeft: leftParent, offsetTop: topParent} = elementModal;
        // targetX = leftParent;
        // targetY = topParent;
        // }
    }, [rootRef.current]);

    const handleMouseDown = (e: any) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        // const {pageX, pageY} = e;
        // const elParent = props.parentElement();
        // const {offsetLeft, offsetTop} = elParent.getElementsByClassName('ant-modal')[0];
        // relX = pageX - (offsetLeft - targetX);
        // relY = pageY - (offsetTop - targetY);
        // addClassName(elementModal);
    }

    const handleMouseMove = (e: any) => {
        const {pageX, pageY} = e;
        // const targetLeft = checkLimit(limitWidth[0], limitWidth[1], pageX - relX);
        // const targetTop = checkLimit(limitHeight[0], limitHeight[1], pageY - relY);
        // elementModal.style.left = targetLeft + 'px';
        // elementModal.style.top = targetTop + 'px';
    }

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        // removeClassName(elementModal);
    }


    return <div ref={rootRef} className={styles.header} data-is-drag={true}>
        {children}
    </div>;
};

export default BoxDrag;
