import React, {Component} from 'react';
import styles from "./style.module.scss";
import OptionItem from "./OptionItem";
import {Checkbox} from "antd";

const getIcon = (id: string) => {
    const icons = ['note', 'require-closed-transport', 'mortality-risk', 'external-site', 'weather'];
    if (icons.indexOf(id) === -1) return null;
    return <div className={[styles['icon'], styles[id]].join(' ')}/>
}

const sections = [
    [0, 1, 18, 2, 3],
    [4, 5, 7, 8],
    [9, 10],
    [12, 13, 14, 15, 16],
    [17]
]

interface IProps {
    options: any

    handleClick(id: string): void

    handleCheckAll(value: boolean): void
}


class Options extends Component<IProps> {

    render() {
        const {options, handleClick, handleCheckAll} = this.props;
        const listCheck = options.filter((item: any) => item.isCheck);
        const checkAll = listCheck.length === options.length;
        const indeterminate = listCheck.length > 0 && !checkAll
        return <div className={styles['options']}>
            {sections.reduce((list: any, item, index) => [...list, <div key={index} className={styles['section']}>
                {item.map(position => <OptionItem key={options[position].id} {...{
                    ...options[position],
                    icon: getIcon(options[position].id),
                    handleClick
                }}/>)}
            </div>], [])}
            <div className={styles['check-all']}>
                <Checkbox  {...{
                    indeterminate,
                    checked: checkAll,
                    onChange: (e: any) => handleCheckAll(e.target.checked)
                }}>
                    Check all
                </Checkbox>
            </div>
        </div>;
    }
}

export default Options;
