import React from 'react';
import {WEATHER_EL} from "../../util";
import styles from "./style.module.scss";

const before: any = {
    [WEATHER_EL.OPERATOR]: true,
    [WEATHER_EL.VALUE]: true,
    [WEATHER_EL.RELATION]: true,
}

const after: any = {
    [WEATHER_EL.RELATION]: true,
}

export const renderLabel = (role: WEATHER_EL, label: string) => `${before[role] ? ' ' : ''}${label}${after[role] ? ' ' : ''}`

interface IProps {
    role: WEATHER_EL
    label: any
    value?: any
    isInvalid?: boolean
    style?: any
    unit?: any
}

const Method: React.FC<IProps> = (props) => {
    const {role, value, label, style, unit} = props;
    return <span
        spellCheck={false}
        className={styles[role]}
        style={style}
        data-role={role}
        data-value={value || '-'}
        data-unit={unit}
    >
        {renderLabel(role, label)}
    </span>;
};

export default Method;
