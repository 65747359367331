import React, {useMemo} from 'react';
import Header from "./Header";
import SmoltSite from "../SmoltSite";
import {FISH_TYPE, INTEGRATION_MODE} from "../../../../util/varibles/constants";
import {useSelector} from "react-redux";
import {selectPlan, selectTenant} from "../../../../util/store/selectors";
import {TabBiologyProvider} from "../../../../contexts/PlanOperationContext/TabBiologyContext";
import Units from "../Units";
import Planned from "../Planned";

interface IProps {
}

const BoxSites: React.FC<IProps> = () => {
    const sites = useSelector(selectPlan.sites);
    const loading = useSelector(selectPlan.loadingSites);
    const {integration = {}} = useSelector(selectTenant) || {}
    const {type = ''} = integration || {};

    const {productionSites = [], smoltSites = []} = useMemo(() => sites.reduce((rs: any, item: any) => {
        if (item.site_type === FISH_TYPE.SMOLT) {
            rs.smoltSites.push(item)
        } else {
            rs.productionSites.push(item)
        }
        return rs;
    }, {productionSites: [], smoltSites: []}), [sites]);

    const common = {
        loading
    }

    return <>
        <Header smoltSites={smoltSites}/>
        <TabBiologyProvider>
            <Units {...common} sites={productionSites}/>
        </TabBiologyProvider>
        {type === INTEGRATION_MODE.STHP_FILE && <TabBiologyProvider>
            <Planned {...common}/>
        </TabBiologyProvider>}
        <TabBiologyProvider>
            <SmoltSite {...common} smoltSites={smoltSites}/>
        </TabBiologyProvider>
    </>;
};

export default BoxSites;
