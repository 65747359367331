import React, {ReactElement, useCallback} from 'react';
import styles from './style.module.scss';
import {NOTE_TYPE} from "../../../../util/varibles/constants";
import Weekday from "./Weekday";
import {connect} from "react-redux";
import {INoteCalender} from "../../../../util/varibles/interface";
import {createKeyOfNote} from "../../constants";
import Note from "../Note";
import {openNoteCalendar} from "../Note/PopupNote";
import {datetime} from "../../../../util/library/datetime";
import {overviewActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState, props: IRootProps) => {
    const {notes} = state.overview;
    const {time} = props;
    return {
        notes: notes[createKeyOfNote(time, NOTE_TYPE.WEEK)]
    }
};

interface IRootProps {
    id: string
    time: number
    notes?: INoteCalender[]
}

interface IProps extends IRootProps {
    children: ReactElement

    updateNotesCalendar(payload: INoteCalender[]): void

    deleteMotesCalendar(payload: INoteCalender[]): void
}

const Week: React.FC<IProps> = (props) => {
    const {id, time, notes = [], children, updateNotesCalendar, deleteMotesCalendar} = props;
    let days = [];
    for (let i = 0; i < 7; i++) {
        days.push(datetime(time).add(i, 'day').time);
    }

    const handleOpenNote = useCallback(() => openNoteCalendar({
        notes,
        time,
        level: NOTE_TYPE.WEEK,
        onSave: updateNotesCalendar,
        onDelete: () => deleteMotesCalendar(notes)
    }), [notes, time, updateNotesCalendar, deleteMotesCalendar]);

    return <div id={id} className={styles.week}>
        <div className={styles.weekdays}>
            <Note notes={notes}>
                <div
                    className={styles.weekNo}
                    data-note={notes.length > 0}
                    onClick={handleOpenNote}
                >
                    W {id.split('|')[1]}
                </div>
            </Note>
            {days.map((item, i) => <Weekday key={i} time={item}/>)}
        </div>
        {children}
    </div>;
};

export default connect(mapStateToProps, {
    updateNotesCalendar: overviewActions.updateNotesCalendar,
    deleteMotesCalendar: overviewActions.deleteNotesCalendar
})(Week);
