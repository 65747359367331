import React, {createRef, useEffect, useState} from 'react';
import * as echarts from "echarts";
import {EChartsType} from "echarts";
import {ECBasicOption} from "echarts/types/dist/shared";
import {datetime} from "../../../util/library/datetime";
import {chartUtil, colors} from "../../../util/library/chart-util";

interface IProps {
    data: any
}

const Chart: React.FC<IProps> = ({data}) => {
    const rootRef = createRef<HTMLDivElement>();
    const [chartOpt, setChartOpt] = useState<EChartsType>();

    useEffect(() => {
        if (chartOpt) {
            const series = data.reduce((list: any, item: any) => {
                const {feed, fuel, oxygen, waste} = item;
                const time = datetime(item.est_time).UTCText;
                list[0].data.push([time, Math.round(feed / 10000) / 100]);
                list[1].data.push([time, fuel]);
                list[2].data.push([time, Math.round(oxygen / 10000) / 100]);
                list[3].data.push([time, waste]);
                return list
            }, [{
                ...chartUtil.initLine,
                name: 'Feed',
                data: [],
                yAxisIndex: 0,
                ...chartUtil.addUnit("t"),
            }, {
                ...chartUtil.initLine,
                name: 'Fuel',
                data: [],
                yAxisIndex: 1,
                ...chartUtil.addUnit("m³")
            }, {
                ...chartUtil.initLine,
                name: 'Oxygen',
                data: [],
                yAxisIndex: 0,
                ...chartUtil.addUnit("t"),
            }, {
                ...chartUtil.initLine,
                name: 'Waste',
                data: [],
                yAxisIndex: 1,
                ...chartUtil.addUnit("m³")
            }])
            const option: ECBasicOption = {series}
            chartOpt.setOption(option);
        }
    }, [data, chartOpt]);

    useEffect(() => {
        if (rootRef.current) {
            const el = rootRef.current;
            const existInstance = echarts.getInstanceByDom(el)
            if (existInstance) echarts.dispose(el)
            const chart = echarts.init(el);
            const option: ECBasicOption = {
                tooltip: chartUtil.formatTimeTooltip(),
                legend: {
                    bottom: '-5px',
                },
                grid: {
                    top: '32px',
                    right: '40px',
                    bottom: '45px',
                    left: '16px',
                    containLabel: true
                },
                xAxis: {
                    type: 'time',
                    min: 'dataMin',
                    minInterval: 86400000,
                    axisLabel: {
                        label: {
                            formatter: function (params: any) {
                                var seriesValue = (params.seriesData[0] || {}).value;
                                return (
                                    params.value +
                                    (seriesValue != null
                                        ? '\n' + echarts.format.addCommas(seriesValue)
                                        : '')
                                );
                            }
                        },
                        formatter: '{dd} {ee}'
                    }
                },
                yAxis: [
                    {
                        min: 0,
                        nameGap: 20,
                        nameTextStyle: {
                            padding: [0, 5, 0, 0],
                            align: 'right'
                        },
                        type: 'value',
                        name: 't',
                        position: 'left',
                        offset: 5,
                        alignTicks: true,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: colors[0]
                            }
                        }
                    },
                    {
                        nameGap: 20,
                        nameTextStyle: {
                            padding: [0, 0, 0, 8],
                            align: 'left'
                        },
                        type: 'value',
                        name: 'm³',
                        position: 'right',
                        offset: 5,
                        alignTicks: true,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: colors[1]
                            }
                        }
                    },
                ],
            }
            chart.setOption(option, true);
            setChartOpt(chart)
        }
    }, [rootRef]);

    return <div ref={rootRef} className='h-full w-full'/>
};

export default Chart;
