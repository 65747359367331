import React from 'react';
import stylesLayout from "./style.module.scss";
import stylesComponent from "../../../../components/Operation/style.module.scss";
import styles from "../../../../components/Operation/style.module.scss";
import Id from "../../../../components/Operation/Id";
import OperationStatus from "../../../../components/Operation/OperationStatus";
import Certain from "../../../../components/Operation/Certain";
import NameSpot from "../../../../components/Operation/NameSpot";
import SumWeight from "../../../../components/Operation/SumWeight";
import Note from "../../../../components/Operation/Note";
import ExternalSite from "../../../../components/Operation/ExternalSite";
import {OP_STATUS, OPERATION_TYPE, SITE_TYPE} from "../../../../util/varibles/constants";
import Weather from "../../../../components/Operation/Weather";
import Delay from "../../../../components/Operation/Delay";
import FishDisease from "../../../../components/Location/FishDisease";
import NoteDetail from "../../../../components/Operation/NoteDetail";
import {IOperation} from "../../../../util/varibles/interface";
import Logo from "../../../../components/Operation/Logo";
import LiceList from "../../../../components/Operation/LiceList";
import Duration from "../../../../components/Operation/Duration";

interface IProps {
    data: IOperation
    isOwn: boolean
    status: OP_STATUS
}

const TransportView: React.FC<IProps> = ({data, isOwn, status}) => {
    const {operation, vessel, activity_log} = data;

    return <>
        <div className={stylesComponent.title}>
            <Logo type={OPERATION_TYPE.TRANSPORT} status={status}/>
            <Id {...{visible: true, operation, status}}/>
            <OperationStatus {...{status,}}/>
            <Certain {...{operation, status}}/>
            <div className='d-flex'>
                <div className={stylesLayout['site-unit']}>{operation.source_name}</div>
                <span className='arrow-right'>&#8594;</span>
                <div className={stylesLayout.factory}>{operation.destination_name}</div>
            </div>
            <div className={stylesComponent.right}>
                <Delay visible={true} operation={operation}/>
                {!isOwn && <NameSpot {...{visible: true, operation}}/>}
                <Note {...{visible: true, operation}}/>
                <ExternalSite {...{
                    visible: true,
                    operation,
                    type: operation.source_site_type === SITE_TYPE.EXTERNAL || operation.destination_site_type === SITE_TYPE.EXTERNAL
                }}/>
                <Weather {...{visible: true, operation}}/>
            </div>
        </div>
        <FishDisease diseases={operation.diseases} units={operation.sub_operations} wrapperClass={styles.section}/>
        <LiceList diseases={operation.diseases} count={operation.sites} short wrapperClass={styles.section}/>
        <div className={stylesComponent.section}>
            <SumWeight{...{
                visible: true,
                className: stylesLayout['sum-weight'],
                operation,
                vessel,
                isLabel: false
            }}/>
        </div>
        <div className={styles.section}>
            <Duration {...{status, activity_log}}/>
        </div>
        <NoteDetail operation={operation} visible={true}/>
    </>;
};

export default TransportView;
