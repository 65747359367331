import React from 'react';
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import InputForm from "../../../../components/Basic/InputForm";
import {vesselForm} from "../constants";

interface IProps {
}

const VesselCapacity: React.FC<IProps> = () => {
    const [value, setStore] = useEditVessel(state => state.vessel?.capacity)
    const [error] = useEditVessel(state => state.error?.capacity)
    const {label, type, rules, suffix} = vesselForm.capacity;

    return <InputForm
        label={label}
        error={error}
        require={rules.require}
        suffix={suffix}
        type={type}
        value={value ? value / 1000000 : value}
        onChange={newValue => setStore(({vessel: old}) => ({vessel: {...old, capacity: newValue * 1000000}}))}
    />;
};

export default VesselCapacity;
