import React, {useEffect, useRef} from 'react';
import styles from './style.module.scss';
import {checkLimit} from "../../../../../../../util/varibles/global";
import {getParentEl} from "../../../../../../../util/varibles/constants";
import ErrorOfOperation from "../../../_Component/ErrorOfOperation";
import ReactDOM from "react-dom/client";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";
import {getEditOp, setEditOp} from "../../../../../../../contexts/EditOperationContext";
import Destination from "./Destination";
import {TOperation} from "../../../../../../../util/varibles/interface";

interface IProps {
    data: TOperation
    index: number
    isAllowDrag?: boolean

    onChange(value: TOperation): void
}

let relHeight = 0,
    draggingEl: HTMLDivElement | any,
    relX: number = 0,
    relY: number = 0,
    hoverEle: any

const Operation: React.FC<IProps> = (props) => {
    const {index, data, isAllowDrag = true, onChange} = props;
    const {sub_destinations = [], error_detail = {}, destination_id = null, source_id} = props.data;
    const rootRef = useRef<HTMLDivElement>(null);
    const btDrag = useRef<HTMLDivElement>(null);
    const get = getEditOp();
    const set = setEditOp();

    useEffect(() => {
        const el = btDrag.current;
        if (el) {
            el.addEventListener('mousedown', onMouseDown);
        }
        return () => {
            if (el) {
                el.removeEventListener('mousedown', onMouseDown);
            }
        }
    }, [btDrag]);

    const handleMerge = (indexTarget: number) => {
        if (indexTarget === index) return;
        const {ops, vessel} = get();
        const target = {...ops[indexTarget]};
        const totalWeight = target.total_weight + data.total_weight;
        const {capacity = 0} = vessel || {};
        if (totalWeight > capacity) {
            notify.error(NotifyCode.E1)();
            return;
        }
        const isDifferent = target.destination_id !== destination_id;
        const subOperationsNew = sub_destinations.reduce((list: any, itemNew: any) => {
            const index = list.findIndex((itemOld: any) => itemOld.id === itemNew.id && itemOld.delivery_id === itemNew.delivery_id);
            if (index !== -1) {
                const {fish_amount, total_weight} = itemNew;
                list[index].fish_amount += fish_amount;
                list[index].total_weight += total_weight;
            } else {
                list.push(isDifferent ? {...itemNew, delivery_id: null, delivery_name: null} : itemNew);
            }
            return list;
        }, [...target?.sub_destinations || []])
        Object.assign(ops[indexTarget], {
                ...target,
                sub_destinations: subOperationsNew,
                fish_amount: target.fish_amount + data.fish_amount,
                total_weight: totalWeight
            }
        )
        ops.splice(index, 1);
        set({ops})
    }

    const handleDelete = (indexDetail: number) => {
        const {units, ops} = get();
        const {sub_destinations = []} = ops[index];

        if (ops.length === 1 && sub_destinations.length === 1) {
            set({ops: [], units});
            return;
        } else if (sub_destinations.length === 1) {
            ops.splice(index, 1);
        } else {
            sub_destinations.splice(indexDetail, 1);
            ops[index] = {
                ...ops[index],
                ...sub_destinations.reduce((list: any, item: any) => {
                    const {fish_amount, total_weight} = item;
                    list.fish_amount += fish_amount;
                    list.total_weight += total_weight;
                    return list;
                }, {fish_amount: 0, total_weight: 0}),
            }
        }
        set({ops});
    }

    const onMouseDown = (e: React.MouseEvent | any) => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        const {pageX, pageY} = e;
        const {x = 0, y = 0} = rootRef.current?.getBoundingClientRect() || {};
        relX = pageX - x;
        relY = pageY - y;
        const el = rootRef.current as HTMLDivElement
        const {offsetWidth} = el;
        el.style.height = '0';
        el.style.opacity = '0';
        el.style.visibility = 'hidden';
        draggingEl = document.createElement('div');
        draggingEl.style.position = 'fixed';
        draggingEl.style.display = 'block';
        draggingEl.style.zIndex = '1032';
        draggingEl.style.left = x + 'px';
        draggingEl.style.top = y + 'px';
        draggingEl.style.height = relHeight + 'px';
        draggingEl.style.width = offsetWidth + 'px';
        const root = ReactDOM.createRoot(draggingEl as HTMLElement);
        root.render(<Operation {...props} isAllowDrag={false}/>);
        const parentEl = getParentEl()
        parentEl.append(draggingEl);
        hoverEle = null;
    };

    const onMouseMove = (e: React.MouseEvent | any) => {
        draggingEl.style.display = 'none';
        const {pageX, pageY} = e;
        let left = checkLimit(0, window.innerWidth, pageX);
        let top = checkLimit(0, window.innerHeight, pageY);
        draggingEl.style.left = (left - relX) + 'px';
        draggingEl.style.top = (top - relY) + 'px';
        draggingEl.style.display = 'block';
    };

    const onMouseUp = (e: React.MouseEvent | any) => {
        let {pageX, pageY} = e;
        pageX = checkLimit(110, window.innerWidth, pageX);
        pageY = checkLimit(0, window.innerHeight, pageY);
        draggingEl.remove();
        let element: HTMLElement | any = document.elementFromPoint(pageX, pageY);
        let operationEl = element.closest('.' + styles.operation);
        if (operationEl)
            handleMerge(Number(operationEl.id));
        if (hoverEle)
            delete hoverEle.dataset.isHover;
        draggingEl = undefined;
        const el = rootRef.current as HTMLDivElement
        el.style.opacity = '1';
        el.style.visibility = 'visible';
        el.style.height = relHeight + 'px';
        document.body.style.cursor = 'unset';
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };

    relHeight = sub_destinations.length * 40 + 4;

    const style = !isAllowDrag ? {opacity: 1, cursor: 'grabbing'} : {cursor: 'grab', transition: 'all .2s'};
    const error = Object.keys(error_detail).length > 0;

    return <div ref={rootRef} className={styles['container-sub']}>
        <div
            id={`${index}`}
            style={{height: relHeight + 'px'}}
            className={styles.operation + ' no-select'}
            data-error={error}
            data-is-dragging={!isAllowDrag}
        >
            <div className={styles['bt-drag']} ref={btDrag} style={style}/>
            <Destination data={data} onChange={onChange} onDelete={handleDelete}/>
        </div>
        <ErrorOfOperation {...{
            error_detail,
            data: {source_id, destination_id},
            style: error ? {} : {display: 'none'}
        }}/>
    </div>;
};

export default Operation;

