import React, {useEffect, useState} from 'react';
import Week from "./Week";
import styles from "./style.module.scss";
import {connect} from "react-redux";
import {IVessel} from "../../../../util/varibles/interface";
import Day from "./Day";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {OverviewSetting} from "../../../../util/varibles/userSetting";
import {IVesselHeight} from "../Vessels";
import CurrentDay from "./CurrentDay";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const weekPerPage = getSetting(state.login.user.setting, OverviewSetting.WEEK_PER_PAGE);

    return {
        weeks: state.overview.weeks,
        weekPerPage
    }
};

const week = [0, 1, 2, 3, 4, 5, 6];

interface IProps {
    weeks: { key: string, time: number }[]
    moreHeight: number
    vesselHeight: IVesselHeight
    vessels: IVessel[]
    weekPerPage: number
}

const Weeks: React.FC<IProps> = ({weeks, moreHeight, vesselHeight, vessels, weekPerPage}) => {
    const style: any = {'--week-per-screen': weekPerPage};
    const [days, setDays] = useState(<div/>);

    useEffect(() => {
        const list = []
        const vesselCount = vessels.length;
        for (let i = 0; i < vesselCount; i++) {
            const {id, isPermission = true} = vessels[i] || {};
            const {height = 0} = vesselHeight[id] || {};
            const styleDays = {
                height: `${height + moreHeight}px`
            }
            list.push(<div key={i} className={styles.days} style={styleDays} data-is-permission={isPermission}>
                {week.map((item) => <Day key={item}/>)}
            </div>)
        }

        setDays(<div className={styles.vessels}>
            {list}
        </div>)
    }, [vessels]);

    return <div className={styles.weeks} style={style}>
        {weeks.map(({key, time}) => <Week key={key} id={key} time={time}>
            {days}
        </Week>)}
        <CurrentDay weeks={weeks}/>
    </div>;
};

export default connect(mapStateToProps, {})(Weeks);

