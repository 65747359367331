import React, {Component} from 'react';
import styles from './style.module.scss';
import {CaretDownOutlined} from "@ant-design/icons";


interface IProps {
    menu: any
    focusId: any

    changeMenuItem(menu: any): any
}

class Menu extends Component<IProps> {
    state = {
        focusId: this.props.focusId
    }

    handleClick = (item: any, index: number) => {
        const {menu} = this.props;
        const {id, type} = item;
        const menuEl: any = document.getElementById('menu_' + id);
        if (type === 'group') {
            const isOpen = !menu[index].isOpen;
            const {focusId = ''} = this.state;
            menu[index].isOpen = isOpen;
            menuEl.dataset.isOpen = isOpen;
            const height = isOpen ? 32 : 0;
            const menuEls = document.getElementsByClassName(styles['menu-item']);
            let countEl = 0, isFlag = true;
            for (let i = 0, max = menuEls.length; i < max; i++) {
                const el: any = menuEls[i];
                const {key} = el.dataset;
                if (key.indexOf(id) === 0 && key !== id) {
                    el.style.height = height + 'px';
                    if (isFlag)
                        countEl++;
                    if (key === focusId)
                        isFlag = false
                }
            }
            const inkEl: any = document.getElementById('menu-ink');
            const {offsetTop} = inkEl;
            if (offsetTop >= menuEl.offsetTop)
                inkEl.style.top = offsetTop + ((isOpen ? 32 : -32) * countEl) + 'px';
        } else {
            const inkEl: any = document.getElementById('menu-ink');
            this.props.changeMenuItem(menu[index])
            setTimeout(() => inkEl.style.top = menuEl.offsetTop + 'px', 1)
            this.setState({focusId: id});
        }
    }

    renderMenu = () => {
        const {focusId} = this.state;

        return this.props.menu.map((item: any, index: number) => <div key={item.id} {...{
            id: 'menu_' + item.id,
            className: styles['menu-item'],
            'data-key': item.id,
            'data-type': item.type,
            'data-is-active': item.id === focusId,
            'data-is-open': item.isOpen,
            style: {paddingLeft: (item.id.split('-').length * 20) + 'px'},
            onClick: () => this.handleClick(item, index)
        }}>
            <span>{item.label}</span>
            {item.type === 'group' && <div className={styles['arrow']}>
                <CaretDownOutlined/>
            </div>}
        </div>)
    }

    render() {
        const {focusId} = this.state;
        const focusIndex = this.props.menu.findIndex((item: any) => item.id === focusId);
        const style = focusIndex !== -1 ? {top: focusIndex * 32 + 20 + 'px'} : {display: 'none'};
        return <div className={styles['container-menu']}>
            <div id='menu-ink' className={styles['menu-ink']} style={style}/>
            {this.renderMenu()}
        </div>;
    }
}

export default Menu;
