import React from 'react';
import styles from "./style.module.scss";
import {DatePicker} from "antd";
import {datetime} from "../../../../../../util/library/datetime";
import {propContainer} from "../../../../../../util/varibles/constants";
import {useEditOp} from "../../../../../../contexts/EditOperationContext";

interface IProps {
    extend?: Function
}

const SelectTime: React.FC<IProps> = ({extend}) => {
    const [startTime, set] = useEditOp(state => state.startTime);

    return <div className={styles.wrapper}>
        <DatePicker variant={"borderless"}{...{
            ...propContainer,
            className: styles.picker,
            showTime: {format: 'HH:mm', minuteStep: 15},
            format: 'DD MMM, HH:mm',
            onChange: (value: any) => {
                set({startTime: value.valueOf()})
                if (extend)
                    extend()
            },
            inputReadOnly: true,
            allowClear: false,
            value: datetime(startTime).value,
            suffixIcon: null
        }}/>
    </div>;
};

export default SelectTime;
