import React from 'react';
import styles from "./style.module.scss";
import {SHORT_TIME_FORMAT} from "../../../../../../../../util/varibles/constants";
import {datetime} from "../../../../../../../../util/library/datetime";
import {HEIGHT_PER_DAY} from "../../../constants";
import {usePlan} from "../../../../../../../../contexts/PlanOperationContext/Plan";

interface IProps {
    data: any
    title?: string
    isEdit: boolean
    time: number
    index: number
    style: any

    handleMouseDown(e: any, index: number): void
}

const TimePoint: React.FC<IProps> = ({data, title, isEdit, style, time, index, handleMouseDown}) => {
    const [startPoint] = usePlan(state => state.startPoint);
    const {id, name, label} = data;
    const value = time;
    const top = `${(time - startPoint) / 86400000 * HEIGHT_PER_DAY}px`;

    return <div {...{
        className: styles.timeline,
        style: {...style, top},
        'data-edit': isEdit,
        'data-operation-id': id,
        'data-name': name,
    }}>
            <span {...{
                title,
                onClick: (e) => e.stopPropagation(),
                onMouseDown: (e) => handleMouseDown(e, index)
            }}>
                {label}: {datetime(value).format(SHORT_TIME_FORMAT)}
            </span>
    </div>
};

export default TimePoint;
