import React, {useEffect, useState} from 'react';
import styles from "./style.module.scss";
import Title from "./Title";
import Content from "./Content";
import LoadingBox from "../../../../components/LoadingBox";
import {IWeather} from "../../../../util/varibles/interface";
import {ACTION_TYPE} from "../../../../util/varibles/constants";
import {Select} from "antd";
import {notify, NotifyCode} from "../../../../util/varibles/message";

interface IProps {
    data: IWeather
    opts?: { label: string, value: string }[]
}

const Weather: React.FC<IProps> = ({data, opts = []}) => {
    const [loading, setLoading] = useState(false);
    const [weather, setWeather] = useState<IWeather>(data);

    useEffect(() => {
        setWeather(data)
    }, [data]);

    const handleInput = (e: any) => {
        const {value = ''} = e.target || {}
        if (value.length < 100) {
            e.target.parentNode.dataset.value = value;
            handleUpdate({name: value})
        } else {
            notify.error(NotifyCode.E33)([100])
        }
    }
    const [, groupId] = weather.group_id.split('#')

    const handleChangeGroup = (value: any) => {
        handleUpdate({group_id: [weather.type, value].join('#')})
    }

    const handleUpdate = (value: any) => {
        setWeather((prev) => ({...prev, ...value, actionType: ACTION_TYPE.EDIT}))
    }

    return <div className={styles.weather} id={weather.id}>
        <Title
            data={weather}
            loading={loading}
            setLoading={setLoading}
            cancel={() => setWeather(data)}
        >
            <div {...{
                className: styles.name,
                title: weather.name.length === 0 ? notify[NotifyCode.E32](['Name']) : '',
                'data-value': weather.name,
                'data-is-error': weather.name.length === 0
            }}>
                <input type="text" value={weather.name} onChange={handleInput} placeholder="Name" size={4}/>
            </div>
            {opts?.length > 0 && <Select
                value={groupId}
                popupMatchSelectWidth={false}
                className={['select-single', styles.select].join(' ')}
                placement='bottomRight'
                options={opts}
                onChange={handleChangeGroup}
            />}
        </Title>
        <Content
            data={weather}
            editing={!!weather.actionType}
            openEditMode={() => setWeather({...weather, actionType: ACTION_TYPE.EDIT})}
        />
        {loading && <LoadingBox level='bg' radius={8} loading={true}/>}
    </div>
};

export default Weather;
