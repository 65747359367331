import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {initReplay} from "../../constants";
import {Button} from "antd";
import {initHistory, vesselDetailActions} from "../../reducer";
import styles from "./style.module.scss";
import {selectVessel} from "../../../../util/store/selectors";
import {speedList} from "./constants";

interface IProps {
    loadData(time?: { start_time: number, end_time: number }): void
}

const ButtonLive: React.FC<IProps> = ({loadData}) => {
    const isLive = useSelector(selectVessel.isLive);
    const dispatch = useDispatch();

    const handleLive = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isLive)
            return
        const history = initHistory();
        loadData({start_time: history.startTime, end_time: history.finishTime});
        dispatch(vesselDetailActions.update({
            isLive: true,
            replay: {...initReplay, isPlay: true, speed: speedList[0].key},
            history,
            currentTime: history.finishTime,
            isTracking: true
        }))
    }

    return <Button {...{className: styles.btLive, type: "primary", 'data-icon': true, onClick: handleLive}}>
        <div className={styles.live} data-live={isLive}/>
        <span>Live</span>
    </Button>;
};

export default ButtonLive;
