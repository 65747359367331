import {all, call, put, takeEvery} from "redux-saga/effects";
import {SiteService} from "../../util/services/site";
import {notification} from "antd";
import {showErrorResponse} from "../../util/varibles/global";
import {siteMonitoringActions} from "./reducer";
import {PayloadAction} from "@reduxjs/toolkit";

function* getSitesWatcher(): Generator {
    try {
        const sites = yield call(SiteService.getSimulation)
        yield put(siteMonitoringActions.getSitesSuccess(sites))
    } catch (error) {
        yield put(siteMonitoringActions.getSitesFailure())
    }
}

function* updateSitesWatcher(args: PayloadAction<any>) {
    try {
        const site = args.payload;
        yield call(SiteService.updateSimulation, site)
        notification['success']({
            message: 'Update successful',
        })
        yield put(siteMonitoringActions.getSitesRequest())
    } catch (error) {
        showErrorResponse('Update failure', error);
    }
}

export default function* siteMonitoringSaga() {
    yield all([
        takeEvery(siteMonitoringActions.getSitesRequest.type, getSitesWatcher),
        takeEvery(siteMonitoringActions.updateSiteRequest.type, updateSitesWatcher)
    ])
}
