import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import ContainerElement from "./ContainerElement";
import styles from "./style.module.scss";

interface IProps extends IPropsElement {

}

class CheckboxElement extends Component<IProps> {
    render() {
        const {isAllowSelect, label, properties: {options = '', numberOfColumns = '', optionDefault = ''}} = this.props;
        const value = optionDefault !== 'null' ? JSON.parse(optionDefault).map((item: any) => item.value) : [];
        const data = options.length > 0 ? options.split('\n')
            .reduce((list: any[], item: string, index) => {
                if (item.trim().length > 0)
                    list = [...list, <div className={styles['check']} key={item}
                                          data-type-check='checkbox'
                                          data-is-check={value.indexOf(index) !== -1}
                    >
                        {item}
                    </div>]
                return list
            }, []) : [];
        const style: any = {'--number-of-columns': JSON.parse(numberOfColumns).value}
        return <ContainerElement {...this.props}>
            <label data-is-empty={label.length === 0}>{label || (isAllowSelect ? 'Label' : '')}</label>
            <div className={styles['control']} style={style}>
                <div className={styles['columns']}>
                    {data}
                </div>
            </div>
        </ContainerElement>;
    }
}

export default CheckboxElement;
