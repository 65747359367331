import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {IVesselOfUser} from "../../../pages/LoginPage/constants";
import {VIEW_MODE} from "../../../util/varibles/permission";
import {PreferenceSetting} from "../../../util/varibles/userSetting";
import {Select} from "antd";
import styles from './style.module.scss';
import {getSetting} from "../../../util/varibles/defaultSetting";
import {loginActions} from "../../../pages/LoginPage/reducer";
import {propContainer} from "../../../util/varibles/constants";
import {AppState} from "../../../util/store/store";

const KEY_SPLIT = '_';

const mapStateToProps = (state: AppState) => {
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE)

    return {
        viewMode,
        permission: state.login.user.permission || {},
        vessels: state.login.vessels || [],
        default_vessel: state.login.user.default_vessel,
    }
}

interface IProps {
    permission: any
    viewMode: VIEW_MODE
    vessels: IVesselOfUser[]
    default_vessel?: string
}

const ViewMode: React.FC<IProps> = (props) => {
    const {permission, vessels, viewMode, default_vessel} = props;
    const [mode, setMode] = useState('');
    const dispatch = useDispatch()

    useEffect(() => {
        if (permission[VIEW_MODE.VESSEL]) {
            dispatch(loginActions.getOpsOfVesselRequest())
        }
    }, [dispatch, permission]);

    useEffect(() => {
        let mode;
        if (viewMode === VIEW_MODE.VESSEL) {
            const {vessel_id = null} = vessels.find(item => item.vessel_id === default_vessel) || vessels[0] || {};
            if (vessel_id) {
                if (!default_vessel) {
                    dispatch(loginActions.updateUserSetting([{}, {default_vessel: vessel_id}]))
                }
                mode = [VIEW_MODE.VESSEL, vessel_id].join(KEY_SPLIT);
            } else
                mode = '';
        } else
            mode = VIEW_MODE.PLANNER;

        setMode(mode);
    }, [viewMode, default_vessel, vessels, dispatch]);

    if (!permission[VIEW_MODE.VESSEL])
        return null;

    const handleChange = (id: string) => {
        const [modeView, vesselId] = id.split(KEY_SPLIT);
        dispatch(loginActions.updateUserSetting([
            {[PreferenceSetting.VIEW_MODE]: modeView},
            {default_vessel: vesselId || ''}
        ]))
    }

    const {Option} = Select;

    return <div className={styles.wrapper}>
        <Select
            {...propContainer}
            className={styles.select}
            value={mode}
            style={{width: 180}}
            popupMatchSelectWidth={false}
            placement={"bottomRight"}
            onChange={handleChange}
        >
            {permission[VIEW_MODE.PLANNER] && <Option value={VIEW_MODE.PLANNER}>Planner view</Option>}
            {vessels.map((item: any) => <Option
                key={item.vessel_id}
                value={[VIEW_MODE.VESSEL, item.vessel_id].join(KEY_SPLIT)}
            >
                Vessel view: {item.name}
            </Option>)}
        </Select>
    </div>;
};


export default connect(mapStateToProps, {
    getOpsOfVessel: loginActions.getOpsOfVesselRequest,
    updateUserSetting: loginActions.updateUserSetting
})(ViewMode);