import React, {createRef, useEffect, useState} from 'react';
import styles from "./style.module.scss";
import stylesContainer from "../style.module.scss";
import {formatNumber} from "../../../../../util/varibles/global";
import {Checkbox, Collapse} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {getHarvests} from "../constants";
import {IPropsChildren} from "../../../../../util/varibles/interface";
import {setTabBiology} from "../../../../../contexts/PlanOperationContext/TabBiologyContext";

interface IProps extends IPropsChildren {
    item: any
}

const GroupByWeek: React.FC<IProps> = (props) => {
    const {weekNo, fish_amount, total_weight, checkAll, indeterminate, isActive} = props.item;
    const [open, setOpen] = useState(false);
    const rootRef = createRef<HTMLDivElement>();
    const set = setTabBiology();

    useEffect(() => {
        const el = rootRef.current;
        if (el) {
            const element = el.getElementsByClassName('ant-collapse-header')[0];
            element.addEventListener('mousedown', handleClick)
        }
        return () => {
            if (el) {
                const element = el.getElementsByClassName('ant-collapse-header')[0];
                element.removeEventListener('mousedown', handleClick)
            }
        }
    }, [rootRef.current]);

    const handleClick = (e: any) => {
        if (!e.target.closest('.ant-checkbox-wrapper')) {
            setOpen(prev => !prev)
        }
    }

    const handleChange = (checked: boolean) => {
        const getLastChild = (item: any): any => {
            const key = Object.keys(item.child)[0];
            const {child = {}} = item.child[key] || {};
            if (Object.keys(child).length === 0)
                return getHarvests(item.child)
            else {
                return Object.keys(item.child).reduce((rs: any, key) => ({
                    ...rs,
                    ...getLastChild(item.child[key])
                }), {})
            }
        };

        if (checked) {
            set({selected: getLastChild(props.item)})
        } else {
            set({})
        }
    }

    return <Collapse
        className={stylesContainer.wrapper}
        activeKey={open ? [1] : []}
        expandIconPosition={'end'}
        expandIcon={({isActive}) => <LeftOutlined rotate={isActive ? -90 : 0}/>}
        items={[{
            key: '1',
            ref: rootRef,
            label: <div {...{className: styles['title-week'], 'data-is-active': isActive}} >
                <Checkbox {...{
                    checked: checkAll,
                    indeterminate,
                    disabled: !isActive,
                    onChange: (e: any) => handleChange(e.target.checked)
                }}/>
                <span>Week {weekNo}</span>
                <span className='text-right'>{formatNumber(fish_amount)} fish(s)</span>
                <span className='text-right'>{formatNumber(Math.floor(total_weight / 1000) / 1000)} t</span>
            </div>,
            children: props.children
        }]}
    />
};

export default GroupByWeek;