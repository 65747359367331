import React from 'react';
import styles from "./style.module.scss";
import {Table} from "antd";
import {TASK_TYPE, taskTypes, TREATMENT_TYPE, treatmentType} from '../../../util/varibles/constants';
import {formatNumber} from "../../../util/varibles/global";
import ValueOfCondition from "../../Operation/Task/ValueOfCondition";

interface IProps {
    unit_id?: string,
    tasks: any
}

const SortingInfo: React.FC<IProps> = ({unit_id, tasks}) => {
    if (!unit_id || !tasks)
        return null;

    function getTreatmentV2Destinations(tasks: any) {
        return tasks.map((task: any, index: any) => {
            let destination;
            if (task.type === TASK_TYPE.TREATMENT) {
                destination = (treatmentType[task.treatment_type] || {}).name
            } else if (task.type === TASK_TYPE.MOVE) {
                destination = 'Move to' + task.destination_name + (task.sub_operation.fish_amount && " (" + formatNumber(task.sub_operation.fish_amount) + ")")
            } else {
                destination = taskTypes[task.type].name
            }
            return destination + (index < tasks.length - 1 ? ', ' : '')
        })
    }

    const columns = [
        {
            title: 'Sort condition',
            key: 'condition',
            render: ({key, condition}: any) => {
                return <ValueOfCondition id={key} data={condition}/>
            }
        },
        {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination',
        },
    ];

    const dataSource: any = [];
    console.log(tasks);
    tasks.forEach((task: any) => {
        if (task.treatment_type === TREATMENT_TYPE.SORTING && unit_id === task.sub_operation.unit_id) {
            const {conditions, sub_tasks} = task;
            Object.keys(conditions).forEach((condition) => {

                dataSource.push({
                    key: condition,
                    condition: conditions[condition].value,
                    destination: getTreatmentV2Destinations(sub_tasks[condition])
                })
            })
        }
    })

    return <Table
        dataSource={dataSource}
        columns={columns}
        bordered={true}
        className={styles['sorting-table']}
        pagination={false}
    />

}
export default SortingInfo;