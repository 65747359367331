import React, {Component} from 'react';
import styles from './style.module.scss';
import {getOperationStatus, OP_STATUS} from "../../util/varibles/constants";

interface IProps {
    visible: boolean
    className?: any
    status: OP_STATUS
}

class OperationStatus extends Component<IProps> {
    static defaultProps = {
        visible: true,
        className: ''
    }

    render() {
        const {visible} = this.props;

        if (!visible) return null;
        const {status} = this.props

        return <div className={[styles['operation-status'], this.props.className].join(' ')} data-status={status}>
            {getOperationStatus(status)}
        </div>;
    }
}

export default OperationStatus;
