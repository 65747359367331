import React, {useState} from 'react';
import styles from './style.module.scss'
import {IPropsChildren} from "../../../util/varibles/interface";

interface IProps extends IPropsChildren {
    width: string | number;
}

const WrapperRoute: React.FC<IProps> = (props) => {
    const {width} = props;
    const [isShow, setIsShow] = useState(true)
    let styleBody = {width}, styleContent: any = {width}, styleToggle = {}, styleOverFlow: any = {},
        classOverflow = styles['menu-overflow'];
    if (!isShow) {
        styleBody = {
            width: '0',
        };
        classOverflow = '';
        styleOverFlow = {
            overflow: 'hidden',
        };
        styleContent = {
            ...styleContent,
            transform: 'translateX(-100%)',
        };
        styleToggle = {
            transform: 'rotate(180deg)',
            opacity: '1',
            visibility: 'visible',
        };
    }

    return <div className={styles['menu-map']}>
        <div className={styles['position-relative']} style={styleBody}>
            <div className={classOverflow} style={styleOverFlow}>
                <div className={styles['menu-content']} style={styleContent}>
                    {props.children}
                </div>
                <div className={styles['menu-toggle']} onClick={() => setIsShow(prev => !prev)}
                     style={styleToggle}>
                    <svg width='32' height='35' xmlns='http://www.w3.org/2000/svg' version='1.1'>
                        <polyline points='18,12 12,17.5 18,23' stroke='#002C4D' strokeWidth='3'
                                  strokeLinecap='round' fill='none' strokeLinejoin='round'/>
                    </svg>
                </div>
            </div>
        </div>
    </div>;
};

export default WrapperRoute;
