import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import Input from "antd/es/input/Input";
import {Button} from "antd";
import {showErrorResponse, validationNumberOrder} from "../../../../util/varibles/global";
import {OPERATION_TYPE, patternFloat2} from "../../../../util/varibles/constants";
import {updateDensitySettingFetch} from "../../../../util/services/tenant";

interface IProps {
    visible: boolean
    density: any
    type: OPERATION_TYPE
    value: number[]

    onChange(conditions: number[]): void

    onClose(): void
}

const Edit: React.FC<IProps> = ({visible, density, type, value = [], onChange, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [input, setInput] = useState('');
    const inputRef: any = useRef(null);

    useEffect(() => {
        if (visible) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
            setInput(value.join(' '));
            setError('')
        }
    }, [visible, value, inputRef]);

    const handleSave = useCallback(() => {
        if (error.length > 0)
            return;
        const data = value.reduce((rs: number[], item: any) => item.toString().length > 0 ? [...rs, +item] : rs, []);
        const body = {
            density: {...density, [type]: data}
        }
        setLoading(true);
        new Promise(resolve => resolve(updateDensitySettingFetch(body)))
            .then(() => {
                setLoading(false);
                onClose();
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse('Update density failed', error);
            })
    }, [error, density, type, value, onClose]);


    const handleChange = useCallback((e: any) => {
        const data = (e.target.value || '').replace(/ {2,}/g, ' ');
        const list = data.split(' ');

        if (list.length > 2)
            return;

        const reg = new RegExp(patternFloat2);
        const error = list.some((item: string) => item.length > 0 && !reg.test(item));
        if (error)
            return;

        const isValid = validationNumberOrder(list.filter((item: string) => item.length > 0));
        if (isValid) {
            onChange(list);
            setError('');
        } else {
            setInput(data);
            setError('The numbers’ order must be from smallest to largest');
        }
    }, [onChange]);


    const handleMouseDown = useCallback((e: any) => {
        if (e.key === 'Escape')
            onClose();
    }, [onClose])

    return <div className={styles.wrapper} data-visible={visible}>
        <div className={styles.description}>
            <div>
                Input one or two numbers to define corresponding operation's density states. Maximum is 2 numbers.
            </div>
            <ul>
                <li>1 number is to generate 2 states of density: Low, High.</li>
                <li>2 numbers (order from smallest to largest) generate 3 states: Low, Medium, High.</li>
            </ul>
        </div>
        <div className={styles.edit}>
            <Input
                ref={inputRef}
                status={error.length > 0 ? 'error' : ''}
                value={input}
                onKeyDown={handleMouseDown}
                onChange={handleChange}
                suffix={<span>t/&#13221;</span>}
            />
            <Button className='bt-ghost w-[90px]' onClick={onClose}>Cancel</Button>
            <Button
                className='bt-ghost w-[90px]'
                data-bt-type='primary'
                disabled={error.length > 0}
                loading={loading}
                onClick={handleSave}
            >
                Save
            </Button>
        </div>
        {error
            ? <div className={styles.error}>{error}</div>
            : <div className={styles.comment}>Separate numbers by space</div>}
    </div>;
};

export default Edit;
