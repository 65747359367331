import React, {Component} from 'react';
import {Button, Modal, Select} from "antd";
import InputTime from "../../../../components/Basic/InputTime";
import styles from './style.module.scss';
import {OpService} from "../../../../util/services/operation";
import Header from "../../../../components/Popup/Component/Header";
import {MinusSquareOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {listOfStep} from "../../../../util/varibles/constants";
import {durationTxt, showErrorResponse} from "../../../../util/varibles/global";
import Schedule from "./Schedule";
import ActivityLog from "./ActivityLog";
import Note from "./Note";
import {IOperation, IOperations, IStateGlobal} from '../../../../util/varibles/interface';
import {connect} from "react-redux";

const getSteps = (data: any) => {
    const {activity_log} = data;
    const {current_process = undefined} = data.operation || {};
    if (current_process === undefined)
        return [];
    const steps = [];
    for (let i = 0; i < activity_log.length - 1; i++) {
        const {key} = activity_log[i];
        const {name = ''} = listOfStep[key] || {};
        if (name.length > 0)
            steps.push({
                value: i,
                label: name,
                disabled: i < current_process
            })
    }
    return steps || [];
}

const mapStateToProps = (state: IStateGlobal) => {
    return {
        userTenantId: state.login.user.tenant_id,
        operations: state.planOperation.operations,
    }
};

interface IProps {
    visible: boolean
    data: IOperation
    userTenantId: string
    operations: IOperations

    onClose?(): void

    onSave(payload: { data: IOperation[], mode: string, tenantId: string }): void
}

class PopupAddDelay extends Component<IProps> {
    schedule = [];
    operations: any = [];
    state = {
        isDetail: false,
        loading: false,
        visible: false,
        duration: 0,
        step: 0,
        activityLog: [],
    }
    steps: any = [];

    constructor(props: IProps) {
        super(props);
        this.steps = getSteps(this.props.data);
        const {current_process, vessel_id} = this.props.data.operation;
        if (current_process === undefined)
            this.onClose();

        const activityLog: any = this.props.data.activity_log.map((item: any, index: number) => {
            const {real_start_time, key, est_start_time} = item;
            if (index > current_process)
                return {time: est_start_time, key, state: 1}

            return {time: real_start_time || est_start_time, key, state: index === current_process ? 0 : -1}
        })
        const start = activityLog[0].time;
        const {operations} = this.props;
        this.operations = Object.keys(operations).reduce((list: any[], key) => {
            const item = operations[key];
            const finish = item.activity_log[item.activity_log.length - 1].est_start_time;
            if (item.operation.vessel_id === vessel_id && start <= finish && item.action_type)
                list.push(item);
            return list;
        }, []);

        this.schedule = activityLog;

        this.state = {
            ...this.state,
            activityLog,
            step: current_process,
            visible: this.props.visible
        }
    }

    onChangeStep = (step: number) => {
        const {duration} = this.state;
        this.setState({
            step,
            activityLog: this.schedule.map((item: any, index: number) =>
                index > step ? {...item, time: item.time + duration} : item)
        });
    }

    onChangeDuration = (duration: number = 0) => {
        const {step} = this.state;
        this.setState({
            duration,
            activityLog: this.schedule.map((item: any, index: number) =>
                index > step ? {...item, time: item.time + duration} : item)
        });
    }

    onClose = () => {
        this.setState({visible: false});
        const {onClose} = this.props;
        if (onClose) {
            onClose();
        }
    }

    submit = () => {
        const {tenant_id = '', id} = this.props.data.operation;
        const {duration, step} = this.state;
        const params = {
            operation_id: id,
            tenant_id,
            time_late: duration,
            step
        }
        this.setState({loading: true});
        OpService.addDelay(params)
            .then((rs: any) => {
                const {operations = []} = rs || {};
                this.props.onSave({data: operations, mode: 'none', tenantId: this.props.userTenantId});
                this.onClose();
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false});
                showErrorResponse('Update operation failed', error);
            })
    }

    render() {
        const {visible, loading, isDetail, step, duration, activityLog} = this.state;
        const {time: start = 0} = activityLog[0] || {};
        const {time: finish = 0} = activityLog[activityLog.length - 1] || {};
        const totalTime = finish - start;

        const title = `Add delay time for #${this.props.data.operation.operation_code}`

        return <Modal
            className={styles['popup']}
            open={visible}
            title={<Header title={title} onClose={this.onClose}/>}
            centered
            footer={null}
            onCancel={this.onClose}
            closable={false}
        >
            <ActivityLog
                step={step}
                duration={duration}
                data={activityLog}
                totalTime={totalTime}
                onSelect={this.onChangeStep}
            />
            <div className={styles['change']} data-full={!isDetail}>
                <div className={styles['edit']}>
                    <div className={styles['duration']}>
                        <div className={styles['info-line']}>
                            <div>Duration:</div>
                            <div className='font-w4'>{durationTxt(totalTime)}</div>
                        </div>
                        <div className={styles.bt} onClick={() => this.setState({isDetail: !isDetail})}>
                            {isDetail ? <MinusSquareOutlined/> : <PlusSquareOutlined/>}
                        </div>
                    </div>
                    <div>
                        <Select
                            value={step}
                            className={styles['select']}
                            options={this.steps}
                            onChange={this.onChangeStep}
                            placeholder='Select step to add time'
                        />
                    </div>
                    <div>
                        <InputTime value={duration} onChange={this.onChangeDuration}/>
                    </div>
                    <Note data={this.operations}/>
                    <div className={styles['footer']}>
                        <Button className='bt-default' onClick={this.onClose}>Cancel</Button>
                        <Button
                            className='bt-primary pr-4 pl-4'
                            disabled={this.operations.length > 0}
                            onClick={this.submit}
                            loading={loading}
                        >
                            Add
                        </Button>
                    </div>
                </div>
                <Schedule data={activityLog} isDetail={isDetail}/>
            </div>
        </Modal>;
    }
}

export default connect(mapStateToProps, {})(PopupAddDelay);
