import React from 'react';
import styles from "./style.module.scss";
import ValueOfSorting from "./ValueOfSorting";
import {ISorting, TTaskTreatment} from "../../../../../../../../util/varibles/interface";
import Condition from "./Condition";
import {IPropsTask} from "../Combine";
import ValueOfCondition from "../../../../../../../../components/Operation/Task/ValueOfCondition";

interface IProps extends IPropsTask {
    data: ISorting
}

const Sorting: React.FC<IProps> = ({unit, data, onUpdate}) => {
    const {sub_tasks, conditions = {}} = data;

    const handleClickOperator = (key: string, value: [string, number]) => {
        console.log(key, value)
    }

    return <>
        <ValueOfSorting onUpdate={onUpdate} data={data}/>
        <div/>
        <div className={styles['container-sorting']}>
            {Object.keys(conditions).map(key => {
                return <Condition
                    id={key}
                    condition={conditions[key]}
                    key={key}
                    taskParentId={data.group_id}
                    tasks={sub_tasks[key] || []}
                    onUpdate={(tasks: TTaskTreatment[]) => onUpdate({sub_tasks: {...sub_tasks, [key]: tasks}})}
                    onUpdateCondition={value => onUpdate({conditions: {...conditions, [key]: value}})}
                    unit={unit}
                >
                    <ValueOfCondition id={key} data={conditions[key].value} onClick={handleClickOperator}/>
                </Condition>
            })}
        </div>
    </>;
};

export default Sorting;
