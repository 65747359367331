import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IStateGlobal} from "../../util/varibles/interface";
import SiteItem from "./SiteItem";
import styles from './style.module.scss';
import {siteMonitoringActions} from "./reducer";

const mapStateToProps = (state: IStateGlobal) => ({
    sites: state.siteMonitoring.sites,
    loading: state.siteMonitoring.loading
});

interface IProps {
    sites: any
    loading: boolean

    getSites(): void
}

class SiteMonitoringPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.props.getSites()
    }

    render() {
        const {sites} = this.props;
        return <div className={[styles['container'], 'm-header bgPage'].join(' ')}>
            <div className={styles['header']}>
                <div className={styles['title']}>
                    <b>Site monitoring</b>
                </div>
            </div>
            <div className={[styles['body'], 'scroll-small'].join(' ')}>
                {sites.map((item: any, index: number) => <SiteItem key={index} data={item}/>)}
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getSites: siteMonitoringActions.getSitesRequest
})(SiteMonitoringPage);
