import {KeyOfStep, OPERATION_MODE, OPERATION_TYPE} from "../../../util/varibles/constants";
import {cloneObj, getWidthOfText} from "../../../util/varibles/global";
import {IActivityLog, TOperation} from "../../../util/varibles/interface";
import {getTimeByOpMode} from "../../../pages/PlanOperationPage/util/function_operation/constants";

export enum TIME_TYPE {
    START,
    SITE,
    FACTORY,
    FINISH
}

export function getSubOfTransport(sub_operations: any, sub_destinations: any) {
    const details = cloneObj(sub_destinations);
    const total: any = {};
    const sub = sub_operations.reduce((list: any, sub: any) => {
        const {id} = sub;
        const detail = details.reduce((items: any, item: any, index: number) => {
            if (item.id === id) {
                items = [...items, item];
                total[item.delivery_id] = (total[item.delivery_id] || 0) + item.fish_amount;
                details.splice(index, 1);
            }
            return items
        }, [])
        return [...list, {...sub, detail}]
    }, [])
    return {sub, total}
}

const maxWidth = 620
const maxWidthTime = 80;
const listOfStyleTime = [
    {right: 'calc(100% + 4px)', top: 0,},
    {left: '15px', top: 0}
]
const positionTimeName = (widthTime: any) => widthTime < maxWidthTime
    ? listOfStyleTime[0] : listOfStyleTime[1];

export const generateListTime: any = {
    [OPERATION_TYPE.HARVEST]: (activity_log: IActivityLog[], opMode: OPERATION_MODE, operation: TOperation) => {
        const startTime = getTimeByOpMode[opMode](activity_log[0]);
        const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);

        const listTimeOfSite = activity_log.reduce((rs: any, item: any, index) =>
            (item.key === KeyOfStep.ARRIVE_SITE && index)
                ? [...rs, {time: getTimeByOpMode[opMode](item), index}]
                : rs, []);
        const factoryIndex = activity_log.findIndex(item => item.key === KeyOfStep.ARRIVE_FACTORY);
        const factoryTime = getTimeByOpMode[opMode](activity_log[factoryIndex] || {}) || 0;

        const duration = finishTime - startTime;
        const {site_name, sites = [], factory_name} = operation;
        const listOfSite = sites.length === 0 ? [{name: site_name}] : sites;

        const widthTime1 = (listTimeOfSite[0] - startTime) / duration * maxWidth;
        const widthTime3 = (finishTime - factoryTime) / duration * maxWidth;

        const leftDestination = (factoryTime - startTime) / duration * maxWidth + 'px';
        const isSmallDestination = maxWidthTime > widthTime3;

        return [
            {
                time: startTime,
                type: TIME_TYPE.START,
                title: 'Start time',
                left: 0,
                index: 0,
                styleContainer: {minWidth: `${widthTime1}px`},
            },
            ...listTimeOfSite.map((item: any, i: number) => {
                const next = listTimeOfSite[i + 1] || factoryTime;
                const {time, index} = item;
                const minWidth = (next - time) / duration * maxWidth;
                const left = ((time - startTime) / duration * maxWidth) + 'px';
                return {
                    time,
                    type: TIME_TYPE.SITE,
                    title: listOfSite[i].name,
                    left,
                    index,
                    styleContainer: {minWidth: `${minWidth}px`},
                }
            }),
            {
                time: factoryTime,
                type: TIME_TYPE.FACTORY,
                title: factory_name,
                left: leftDestination,
                index: factoryIndex,
                styleContainer: isSmallDestination ? {minWidth: `${widthTime3}px`, height: '45px'}
                    : {minWidth: `${widthTime3}px`},
            },
            {
                time: finishTime,
                type: TIME_TYPE.FINISH,
                title: 'Finish time',
                left: '100%',
                index: activity_log.length - 1,
            }
        ];
    },
    [OPERATION_TYPE.TREATMENT]: (activity_log: IActivityLog[], opMode: OPERATION_MODE, operation: TOperation) => {
        const startTime = getTimeByOpMode[opMode](activity_log[0]);
        const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
        const {site_name} = operation;
        return [
            {
                time: startTime,
                type: TIME_TYPE.START,
                left: 0,
                title: site_name,
                styleTime: listOfStyleTime[1],
                index: 0,
                styleContainer: {width: 'calc(100% - 90px)'}
            },
            {
                time: finishTime,
                type: TIME_TYPE.FINISH,
                title: 'Finish time',
                left: '100%',
                index: activity_log.length - 1,
                styleTime: listOfStyleTime[1],
            }
        ];
    },
    [OPERATION_TYPE.TRANSPORT]: (activity_log: IActivityLog[], opMode: OPERATION_MODE, operation: TOperation) => {
        const startTime = getTimeByOpMode[opMode](activity_log[0]);
        const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
        const smoltSiteTime = getTimeByOpMode[opMode](activity_log[1]);
        const siteTime = getTimeByOpMode[opMode](activity_log[5]);
        const duration = finishTime - startTime;

        const {source_name, destination_name} = operation;

        const widthTime1 = (smoltSiteTime - startTime) / duration * maxWidth;
        const widthSource = (siteTime - smoltSiteTime) / duration * maxWidth;
        const widthTime3 = (finishTime - siteTime) / duration * maxWidth;

        const leftSource = widthTime1 + 'px';
        const leftDestination = (siteTime - startTime) / duration * maxWidth + 'px';

        const isShort = (getWidthOfText(source_name) + 10) > widthSource || (getWidthOfText(destination_name) + 10) > widthSource
        const spaceFree = widthSource / 2 - 16;
        return [
            {
                time: startTime,
                type: TIME_TYPE.START,
                title: 'Start',
                left: 0,
                styleContainer: {minWidth: `${widthTime1}px`},
                styleTime: positionTimeName(widthTime1),
                index: 0,
                placeHolder: 'Start time'
            },
            {
                time: smoltSiteTime,
                type: TIME_TYPE.SITE,
                title: source_name,
                left: leftSource,
                styleContainer: {minWidth: `${widthSource}px`},
                styleTitle: isShort ? {
                    left: (6 + spaceFree) + 'px',
                    transform: 'translate(-100%)'
                } : {
                    left: 0,
                    transform: 'translate(-50%)'
                },
                index: 1,
                styleTime: positionTimeName(widthSource),
            },
            {
                time: siteTime,
                type: TIME_TYPE.SITE,
                title: destination_name,
                left: leftDestination,
                styleContainer: {minWidth: `${widthTime3}px`},
                styleTitle: isShort ? {
                    left: (-7 - spaceFree) + 'px',
                    maxWidth: (widthTime3 + 100) + 'px'
                } : {
                    left: 0,
                    transform: 'translate(-50%)'
                },
                index: 5,
                styleTime: positionTimeName(widthTime3),
            },
            {
                time: finishTime,
                type: TIME_TYPE.FINISH,
                title: 'Finish time',
                styleContainer: {minWidth: `90px`},
                left: '100%',
                styleTime: listOfStyleTime[1],
                index: activity_log.length - 1,
                placeHolder: 'Finish time'
            }
        ];
    },
    [OPERATION_TYPE.EVENT]: (activity_log: IActivityLog[], opMode: OPERATION_MODE) => {
        const startTime = getTimeByOpMode[opMode](activity_log[0]);
        const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
        return [
            {
                time: startTime,
                type: TIME_TYPE.START,
                left: 0,
                title: 'Start time',
                styleTime: listOfStyleTime[1],
                index: 0,
                styleContainer: {width: 'calc(100% - 90px)'}
            },
            {
                time: finishTime,
                type: TIME_TYPE.FINISH,
                title: 'Finish time',
                left: '100%',
                index: activity_log.length - 1,
                styleTime: listOfStyleTime[1],
            }
        ];
    },
    [OPERATION_TYPE.SERVICE]: (activity_log: IActivityLog[], opMode: OPERATION_MODE, operation: TOperation) => {
        const startTime = getTimeByOpMode[opMode](activity_log[0]);
        const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
        const {site_name} = operation;
        return [
            {
                time: startTime,
                type: TIME_TYPE.START,
                left: 0,
                title: site_name,
                styleTime: listOfStyleTime[1],
                styleContainer: {width: 'calc(100% - 90px)'}
            },
            {
                time: finishTime,
                type: TIME_TYPE.FINISH,
                title: 'Finish time',
                left: '100%',
                styleTime: listOfStyleTime[1],
            }
        ];
    },
}
