import React from 'react';
import {OP_STATUS} from "../../../../../util/varibles/constants";
import {TOperation} from "../../../../../util/varibles/interface";

interface IProps {
    isOwn: boolean
    operation: TOperation
}

const Approver: React.FC<IProps> = ({isOwn, operation}) => {
    const {status, approver} = operation;
    const isApprover = !isOwn && ((status === OP_STATUS.WAITING || status === OP_STATUS.CANCELED) && approver);

    if (!isApprover)
        return null;

    return <div className='mb-10'>
        {approver + (status === OP_STATUS.CANCELED ? ' canceled' : ' approved')}
    </div>
};

export default Approver;
