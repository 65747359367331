import React, {Component} from 'react';
import styles from "../style.module.scss";
import {Divider} from "antd";
import {IPropsSearchItem} from "../constants";
import {eventTypes, SHORT_DATETIME_FORMAT, STATE_EVENT} from "../../../../../util/varibles/constants";
import {SwapRightOutlined} from "@ant-design/icons";
import {datetime} from "../../../../../util/library/datetime";

interface IProps extends IPropsSearchItem {
}

class Event extends Component<IProps> {
    render() {
        const {
            operation_code,
            event_type,
            vessel_name,
            est_start_time,
            est_finish_time,
            status,
            current_process
        } = this.props.data;
        const statusTxt = status === "processing" ? STATE_EVENT[current_process] : status;
        return <div className={styles['item-result']}>
            <div className={styles['line-info-item']}>
                <div className={styles['icon-event']} data-event-type={event_type}/>
                <span className='ml-2 mr-3'>{'#' + operation_code}</span>
            </div>
            <div className={styles['line-info-item']}>
                <div className={styles['operation-type']}>
                </div>
            </div>
            <div className={styles['line-info-item']}>
                <span>{vessel_name}</span>
                <Divider className={styles['divider-line']} type="vertical"/>
                <div className={styles['operation-type']}>{eventTypes[event_type].name}</div>
                <div className={[styles['operation-status'], 'ml-2'].join(' ')}>{statusTxt}</div>
            </div>
            <div className={styles['line-info-item']}>
                {datetime(est_start_time).format(SHORT_DATETIME_FORMAT)}
                <SwapRightOutlined className='ml-2 mr-2 font-s3'/>
                {datetime(est_finish_time).format(SHORT_DATETIME_FORMAT)}
            </div>
        </div>;
    }
}

export default Event;
