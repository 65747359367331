import {Row} from 'antd';
import React from 'react';
import {ISite} from "../../../../../util/varibles/interface";
import styles from "../style.module.scss";
import Section from "./Section";
import Position from "../../../../../components/Location/Position";
import Phone from "../../../../../components/Location/Phone";
import ProductionArea from "../../../../../components/Location/ProductionArea";
import SiteDiseases from "./SiteDiseases";
import FishDisease from "../../../../DashboardPage/Component/FishDisease";
import Diseases from "./Diseases";
import {openPage} from "../../../../../util/library/Router";
import {PATHNAME} from "../../../../../util/varibles/constants";
import {useDispatch} from "react-redux";
import {planOpActions} from "../../../reducer";

interface IProps {
    site: ISite
}

const Info: React.FC<IProps> = ({site}) => {
    const dispatch = useDispatch();

    const handleUpdate = (data: any) => {
        dispatch(planOpActions.updateUnit({site_id: site.id, unit_diseases: data}))
    }

    return <Row className={styles.smallBox} gutter={[16, 18]}>
        <Section>
            <div className={styles.lineName}>Site detail info</div>
            <Phone data={site}/>
            <Position data={site}/>
            <ProductionArea data={site}/>
            <SiteDiseases site={site}/>
        </Section>
        <Section>
            <div className={styles.lineName}>Site lice info</div>
            <FishDisease data={site.diseases}/>
        </Section>
        <Section>
            <div className='flex items-center gap-[20px] mb-2'>
                <div className='font-semibold text-[15px]'>Add disease into site</div>
                <div className='link' onClick={() => openPage(PATHNAME.DISEASE)}>Define new disease</div>
            </div>
            <Diseases site_id={site.id} unit_diseases={site.units[0]?.unit_diseases} onUpdate={handleUpdate}/>
        </Section>
    </Row>
};

export default Info;
