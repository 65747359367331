import React, {useCallback} from 'react';
import WrapperOperation from "../Component/WrapperOperation";
import stylesContainer from "../style.module.scss";
import ServiceView from "../../../../../_Component/ContentOperation/ServiceView";
import {ACTION_TYPE, OP_STATUS, OPERATION_TYPE, SITE_TYPE} from "../../../../../../../util/varibles/constants";
import {IPropsOp} from "../index";
import {selectPlan} from "../../../../../../../util/store/selectors";
import {store} from "../../../../../../../util/store/store";


interface IProps extends IPropsOp {
    operationType: OPERATION_TYPE

    isOwn: boolean
    isVesselOwn: boolean
    permission: any
    status: OP_STATUS
}

const Service: React.FC<IProps> = (props) => {
    const {isOwn, data} = props;

    const edit = useCallback(() => {
        const sites = selectPlan.sites(store.getState());
        const {operation, vessel, activity_log} = data;

        const {site_id, site_name, tasks} = operation;
        const unitsOp = tasks.reduce((rs: any, item: any) => {
            const {id = ''} = item.sub_operation || {};
            rs[id] = true;
            return rs;
        }, {})
        const {units = [], type = SITE_TYPE.INTERNAL} = sites.find((item: any) => item.id === site_id) || {};

        return {
            isShow: true,
            operationType: OPERATION_TYPE.SERVICE,
            editMode: ACTION_TYPE.EDIT,
            values: {
                activity_log,
                operation,
                vessel,
                store: Object.keys(unitsOp).reduce((list: any, id: any) => {
                    const key = id + '|-';
                    list[key] = {
                        ...units.find((unit: any) => unit.id === id),
                        site_id,
                        site_name,
                        site_type: type,
                    }
                    return list;
                }, {})
            }
        }
    }, [data])

    return <WrapperOperation {...{...props, edit}}>
        <div className={[stylesContainer['content-wrapper'], stylesContainer.layout].join(' ')}>
            <ServiceView
                status={props.status}
                data={data}
                isOwn={isOwn}
            />
        </div>
    </WrapperOperation>;
};

export default Service;
