import React, {useCallback, useEffect, useState} from 'react';
import Loading from "../Component/Loading";
import Operation from "./Operation";
import {IOperation, IStateGlobal} from "../../../util/varibles/interface";
import {IVesselOfUser} from "../../../pages/LoginPage/constants";
import styles from './style.module.scss';
import {connect} from "react-redux";

const mapStateToProps = (state: IStateGlobal) => ({
    default_vessel: state.login.user.default_vessel,
    vessels: state.login.vessels || [],
});

interface IProps {
    vessels: IVesselOfUser[]
    default_vessel: any

    onUpdate?(args: any): void

    onTracking?(data: IOperation | { operation: undefined, vessel: IVesselOfUser }): any
}

const Operations: React.FC<IProps> = ({default_vessel, vessels, onUpdate, onTracking}) => {
    const [vessel, setVessel] = useState<IVesselOfUser>();
    const [active, setActive] = useState(0);
    const {operations = []} = vessel || {};

    useEffect(() => {
        const value = vessels.find(item => item.vessel_id === default_vessel) || vessels[0];
        setVessel(value);
    }, [default_vessel, vessels, onTracking]);

    const handleOpen = useCallback((i: number) => {
        setActive(i);
        if (onTracking && vessel)
            onTracking(operations[i] || {vessel});
    }, [onTracking, vessel]);

    useEffect(() => {
        handleOpen(0);
    }, [handleOpen]);

    return <>
        <Loading data={operations}/>
        <div className={styles.operations}>
            {operations.map((item, i) => <Operation
                key={item.operation.id}
                data={item}
                active={active === i}
                extend={() => handleOpen(i)}
                onUpdate={onUpdate}
            />)}
        </div>
    </>;
};

export default connect(mapStateToProps, {})(Operations);