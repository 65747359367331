import React, {Component} from 'react';
import styles from "./style.module.scss";

interface IProps {
    name: string
    onClick: any
}

class Title extends Component<IProps> {
    render() {
        const {name, onClick} = this.props;
        return <div className={styles['title']}>
            {name}
            <span onClick={onClick}/>
        </div>;
    }
}

export default Title;
