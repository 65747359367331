import React, {useState} from 'react';
import styles from './style.module.scss';
import {FULL_DATE_FORMAT} from "../../../util/varibles/constants";
import {uppercaseFirstLetter} from "../../../util/varibles/global";
import {Button} from 'antd';
import {useDispatch} from 'react-redux';
import {siteMonitoringActions} from "../reducer";
import {datetime} from "../../../util/library/datetime";
import Chart from "./Chart";

const renderDetail = (list: any) => {
    return list.map((item: any) => {
        const {name, amount} = item;
        let value;
        switch (name) {
            case 'feed':
            case 'oxygen':
                value = (Math.round(amount / 10000) / 100) + ' t';
                break;
            case 'fuel':
            case 'waste':
                value = amount + ' m³';
                break;
            default:
                value = amount
        }
        return <span>{uppercaseFirstLetter(name)}: {value}</span>
    });
}

const renderSiteDetail = (site: any, onChange: any, updateSite: any) => {
    return <div className={styles['table-wrapper']}>
        <table className={styles.table}>
            <thead>
            <tr>
                <th className={styles['site-detail-th']}/>
                <th className={styles['site-detail-th']}>Start Amount</th>
                <th className={styles['site-detail-th']}>Rate</th>
                <th className={styles['site-detail-th']}>Variation</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Feed</td>
                <td className={styles['ton-input']}><input className={styles['site-detail-input']} type="number"
                                                           value={site.feed / 1000000} name='feed'
                                                           onChange={onChange}/></td>
                <td className={styles['ton-input']}><input className={styles['site-detail-input']} type="number"
                                                           value={site.feed_rate / 1000000} name='feed_rate'
                                                           onChange={onChange}/></td>
                <td className={styles['percent-input']}><input className={styles['site-detail-input']} type="number"
                                                               value={site.feed_variation} name='feed_variation'
                                                               onChange={onChange}/></td>
            </tr>
            <tr>
                <td>Oxigen</td>
                <td className={styles['ton-input']}><input className={styles['site-detail-input']} type="number"
                                                           value={site.oxygen / 1000000} name='oxygen'
                                                           onChange={onChange}/></td>
                <td className={styles['ton-input']}><input className={styles['site-detail-input']} type="number"
                                                           value={site.oxygen_rate / 1000000} name='oxygen_rate'
                                                           onChange={onChange}/></td>
                <td className={styles['percent-input']}><input className={styles['site-detail-input']} type="number"
                                                               value={site.oxygen_variation} name='oxygen_variation'
                                                               onChange={onChange}/></td>
            </tr>
            <tr>
                <td>Fuel</td>
                <td className={styles['cubic-metre-input']}><input className={styles['site-detail-input']}
                                                                   type="number" value={site.fuel} name='fuel'
                                                                   onChange={onChange}/></td>
                <td className={styles['cubic-metre-input']}><input className={styles['site-detail-input']}
                                                                   type="number" value={site.fuel_rate}
                                                                   name='fuel_rate' onChange={onChange}/>
                </td>
                <td className={styles['percent-input']}><input className={styles['site-detail-input']} type="number"
                                                               value={site.fuel_variation} name='fuel_variation'
                                                               onChange={onChange}/></td>
            </tr>
            <tr>
                <td>Waste</td>
                <td className={styles['cubic-metre-input']}><input className={styles['site-detail-input']}
                                                                   type="number" value={site.waste} name='waste'
                                                                   onChange={onChange}/></td>
                <td className={styles['cubic-metre-input']}><input className={styles['site-detail-input']}
                                                                   type="number" value={site.waste_rate}
                                                                   name='waste_rate' onChange={onChange}/>
                </td>
                <td className={styles['percent-input']}><input className={styles['site-detail-input']} type="number"
                                                               value={site.waste_variation} name='waste_variation'
                                                               onChange={onChange}/></td>
            </tr>
            <tr>
                <td>Day Generator</td>
                <td colSpan={3}><input className={styles['site-detail-input']} type="number" value={site.day_amount}
                                       name='day_amount' onChange={onChange}/></td>
            </tr>
            <tr>
                <td>Buffer Time</td>
                <td colSpan={3}><input className={styles['site-detail-input']} type="number"
                                       value={site.day_amount_buffer} name='day_amount_buffer'
                                       onChange={onChange}/></td>
            </tr>
            </tbody>
        </table>
        <Button className={styles['btn-update']} type="primary" onClick={updateSite}>Update</Button>
    </div>
}

interface IProps {
    data: any
}

const SiteItem: React.FC<IProps> = ({data}) => {
    const dispatch = useDispatch();
    const [site, setSite] = useState<any>(data.site)
    const onChangeSite = (e: any) => {
        let {name, value} = e.target
        value = Number(value)
        if (["feed", "feed_rate", "oxygen", "oxygen_rate"].includes(name)) {
            value = value * 1000000
        }
        setSite((prev: any) => ({...prev, [name]: value}))
    }

    const updateSite = () => {
        dispatch(siteMonitoringActions.updateSiteRequest(site))
    }

    const {operations = [], inventory} = data;
    return <div className={styles.container}>
        <div className={styles.header}>{site.name}</div>
        <div className={styles.graph}>
            <Chart data={inventory}/>
        </div>
        <table className={styles['table']}>
            <thead>
            <tr>
                <th>Date</th>
                <th>Pickups</th>
                <th>Delivery</th>
                <th>Open Amount</th>
                <th>Close Amount</th>
            </tr>
            </thead>
            <tbody>
            {operations.map((item: any, index: number) => <tr key={index}>
                <td className='text-center'>{datetime(item.date).format(FULL_DATE_FORMAT)}</td>
                <td>
                    <div className={styles.detail}>
                        {renderDetail(item.pickups)}
                    </div>
                </td>
                <td>
                    <div className={styles.detail}>
                        {renderDetail(item.fillings)}
                    </div>
                </td>
                <td>
                    <div className={styles.detail}>
                        {renderDetail(item.open_amount)}
                    </div>
                </td>
                <td>
                    <div className={styles.detail}>
                        {renderDetail(item.close_amount)}
                    </div>
                </td>
            </tr>)}
            </tbody>
        </table>
        {renderSiteDetail(site, onChangeSite, updateSite)}
    </div>;
};

export default SiteItem;
