import React, {useState} from 'react';
import {operationTypes} from "../../../util/varibles/constants";
import Section from "../Component/Section";
import styles from "../Component/Section/style.module.scss";
import {WEATHER_TYPE} from "../util";
import {renderWeathers} from "../";
import Filter from "../Component/Filter";
import {renderOpts} from "../../../util/varibles/global";
import {IWeather} from "../../../util/varibles/interface";

const operationOpts = operationTypes.map(item => ({label: item.name, value: `${item.id}`}));

interface IProps {
    weathers: IWeather[]
}

const OperationTypes: React.FC<IProps> = (props) => {
    const [filter, setFilter] = useState([]);

    const {weathers} = props;
    const opTypeSelected = new Set<number>(filter);
    let count = 0;
    const group = weathers.reduce((rs: any, item: any) => {
        const {group_id} = item;
        const [, opType,] = group_id.split('#');
        if (opTypeSelected.size === 0 || opTypeSelected.has(+opType)) {
            count++;
            rs[opType] = [...rs[opType] || [], item]
        }
        return rs;
    }, {})

    return <Section
        title='Operation limit'
        type={WEATHER_TYPE.OPERATION}
        extra={<Filter options={renderOpts(operationTypes)} value={filter} onChange={setFilter}/>}
        count={count}
    >
        {operationTypes.map(item => {
            const {id, name} = item;
            if (group[id]) {
                return <div className={styles.group} key={id}>
                    <div className={styles.groupName}>{name}</div>
                    {renderWeathers(group[id], operationOpts)}
                </div>
            } else {
                return null
            }
        })}
    </Section>;
};

export default OperationTypes;
