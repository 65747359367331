import React from 'react';
import {connect} from 'react-redux';
import {operationType} from "../../../util/varibles/constants";
import styles from "./style.module.scss";
import {IChecklist, IVessel} from "../../../util/varibles/interface";
import {getStepNameOfChecklist} from "../../../util/varibles/global";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupAssign from "../PopupAssign";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    vessels: state.checklist.vessels
});

interface IProps {
    checklist: IChecklist
    vessels: IVessel[]
    isSend?: boolean

    onSave(checklist: IChecklist): void
}

const stepName = (vesselName: string, checklist?: IChecklist) => {
    if (!checklist)
        return null;
    const {operation_type} = checklist
    const {name = ''} = operationType[operation_type] || {};
    const checklistName = getStepNameOfChecklist(checklist);
    return <span>{[vesselName, name, checklistName].join(' / ')}</span>
}

const Footer: React.FC<IProps> = (props) => {
    const {checklist, isSend, vessels = [], onSave} = props;
    const {content, vessel_id} = checklist || {};
    const openAssign = (isDisable: boolean) => {
        if (isDisable) return;
        const el = openPopup(<PopupAssign
            checklist={checklist}
            isSend={isSend}
            onSave={onSave}
            onClose={() => el.remove()}
        />)
    }

    if (vessels.length === 0) return null;

    const data = (content || '').length > 0 ? JSON.parse(content || '') : '';
    const isDisable = data.length === 0;
    const {name: vesselName = ''} = vessels.find((item: IVessel) => item.id === vessel_id) || {};

    return <div className={[styles.footer, 'no-select'].join(' ')}>
        <label data-is-empty={data.length === 0}>Assign to vessel</label>
        <div className={styles['using-by']} onClick={() => openAssign(isDisable)}>
            <div className={styles.vessel}>
                {stepName(vesselName, checklist)}
            </div>
            <div
                className={styles['bt-assign']}
                data-empty={data.length === 0}
                data-is-show={vesselName.length === 0}
            >
                <div>Assign this checklist to</div>
            </div>
        </div>
    </div>;
};

export default connect(mapStateToProps, {})(Footer);
