import React, {Component} from 'react';
import styles from "./style.module.scss";
import {formatNumber} from "../../../../../util/varibles/global";
import {SITE_TYPE} from "../../../../../util/varibles/constants";

interface IProps {
    units: any
}

class DragView extends Component<IProps> {
    render() {
        const {units} = this.props;
        const sites = Object.keys(units).reduce((list: any, key: any) => {
            const {site_id, unit_id, harvest_id, fish_amount, avg_weight, site_type} = units[key];
            if (list[site_id]) {
                const isExist = list[site_id].unitIds.indexOf(unit_id) !== -1;
                if (!isExist)
                    list[site_id].unitIds.push(unit_id);
                if (harvest_id)
                    list[site_id].harvestIds = [...list[site_id].harvestIds, harvest_id];

                if (site_type === SITE_TYPE.INTERNAL)
                    list[site_id].sumWeight += (fish_amount * avg_weight);
            } else
                list[site_id] = {
                    ...units[key],
                    site_type,
                    unitIds: [unit_id],
                    harvestIds: harvest_id ? [harvest_id] : [],
                    sumWeight: fish_amount * avg_weight
                }
            return list;
        }, {});

        const data = Object.keys(sites).reduce((rs: any, key) => {
            const item = sites[key];
            const values = [
                {label: 'Site:', value: item.site_name},
                {label: 'Units:', value: item.unitIds.join(', ')},
            ];

            if (item.site_type === SITE_TYPE.INTERNAL)
                values.push({
                    label: 'Sum weight:',
                    value: `${formatNumber(Math.round(item.sumWeight / 1000) / 1000)} t`
                })

            const {harvestIds = []} = item;
            if (harvestIds.length > 0)
                return [...rs, [{label: 'Harvests:', value: item.harvestIds.join(', ')}, ...values]];

            return [...rs, values];
        }, []);

        return <div className={styles.dragView}>
            <table>
                <tbody>
                {data.map((item: any, i: number) => <tr key={i}>
                    {item.map((sub: any) => <td key={sub.value}>
                        {sub.label} {(sub.value || '').toString().length > 0 && <b className='ml-'>{sub.value}</b>}
                    </td>)}
                </tr>)}
                </tbody>
            </table>
        </div>;
    }
}

export default DragView;