import React, {Component} from 'react';
import Conversation from "./Conversation";
import ChatInput from "./ChatInput";
import styles from './style.module.scss';
import WSService from "../../../web-socket/web-socket-service";
import {CHAT_TYPE} from "../../../util/varibles/constants";
import {IConversation, IMessage} from "../../../util/varibles/interface";

interface IProps {
    data: IConversation | any
    messages: IMessage[]
    loading: boolean
    isMore: boolean
    isActiveInput: boolean

    onSend(params: any): void
}

class ChatBox extends Component<IProps> {

    static defaultProps = {
        isActiveInput: true,
        loading: false,
        isMore: false,
        messages: [],
        data: {
            type: CHAT_TYPE.USER,
            group_chat: {
                id: '',
                members: []
            }
        }
    }

    conversationRef: any = React.createRef();

    handleSend = (params: any) => {
        if (this.conversationRef)
            this.conversationRef.updateScrollMode('bottom');

        this.props.onSend({
            ...params,
            connection_id: WSService.getConnectId()
        })
    }

    render() {
        const {loading, messages, isActiveInput} = this.props;

        return <div className={styles.wrapper}>
            <Conversation
                data={this.props.data}
                childRef={(ref: any) => this.conversationRef = ref}
                loading={loading}
                messages={messages}
            />
            {isActiveInput && <ChatInput
                data={this.props.data}
                loading={loading}
                onSend={this.handleSend}
            />}
        </div>;
    }
}

export default ChatBox;