import React, {useCallback, useRef} from 'react';
import styles from './style.module.scss'
import {CALENDAR_ID} from "../../constants";
import {useDispatch, useSelector} from 'react-redux'
import Weeks from "./Weeks";
import Vessels from "./Vessels";
import {selectOpMode, selectPlan, selectTenantId} from "../../../../util/store/selectors";
import {getMondayByKey, getStartTimeFromDate, getSundayByKey} from "../../../../util/varibles/global";
import {PlanProvider} from "../../../../contexts/PlanOperationContext/Plan";
import {planOpActions} from "../../reducer";
import {IVessel} from "../../../../util/varibles/interface";
import {store} from "../../../../util/store/store";
import {ACTION_TYPE, AVAILABLE_TIME_TYPE, OP_STATUS, OPERATION_TYPE} from "../../../../util/varibles/constants";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import PopupAvailableTime from "../../Popup/AvailableTime";
import {EL_RULE} from "./constants";
import {MENU_OP, menuOp, menuOptions} from "./Operations/constants";
import {renderMenu} from "./Operations/actions";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {TargetTypes} from "./Vessels/constants";

const Calendar: React.FC = () => {
    const weeks = useSelector(selectPlan.weeks);
    const tenantId = useSelector(selectTenantId);
    const startTime = getMondayByKey(weeks[0]).time;
    const finishTime = getSundayByKey(weeks[weeks.length - 1]).time;
    const calendarRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch()

    const handleAddEvent = useCallback((date: number, vessel: IVessel) => {
        const state = store.getState();
        const {operations, level} = selectPlan.root(state);
        const opMode = selectOpMode(state);
        const start = getStartTimeFromDate({date, operations, level, opMode, vessel});
        dispatch(planOpActions.togglePopupOperation({
            isShow: true,
            operationType: OPERATION_TYPE.EVENT,
            editMode: ACTION_TYPE.CREATE,
            values: {
                activity_log: [{est_start_time: start}, {est_start_time: null}],
                vessel
            }
        }))
    }, [dispatch])

    const handleAddAvailableTime = useCallback((date: number, vessel: IVessel) => {
        const state = store.getState();
        const {operations, level} = selectPlan.root(state);
        const opMode = selectOpMode(state);
        const start = getStartTimeFromDate({date, operations, level, opMode, vessel});

        const el = openPopup(<PopupAvailableTime
            visible={true}
            editMode={ACTION_TYPE.CREATE}
            availableTime={{start_time: start}}
            vessel={vessel}
            onClose={() => el.remove()}
        />)
    }, []);

    const generateMenu = useCallback((target: TargetTypes) => {
        let list = [];
        const {type} = target;
        switch (type) {
            case EL_RULE.VESSEL: {
                const {time, vessel} = target;
                const {isOwn, permission, available_time_type} = vessel;
                if (isOwn || permission) {
                    list.push({
                        key: 'event',
                        label: <div onMouseDown={() => handleAddEvent(time, vessel)}>Add
                            event</div>
                    })
                    if (available_time_type === AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME)
                        list.push({
                            key: 'time',
                            label: <div onMouseDown={() => handleAddAvailableTime(time, vessel)}>Add available
                                time</div>
                        })
                } else if (available_time_type === AVAILABLE_TIME_TYPE.ALL) {
                    list.push({
                        key: 'event',
                        label: <div onMouseDown={() => handleAddEvent(time, vessel)}>Add event</div>
                    })
                }
                break;
            }
            case EL_RULE.OPERATION: {
                const {data, edit, isOwn} = target;
                const {status, operation_type} = data.operation;
                const {permission, isOwn: isVesselOwn} = data.vessel;

                const start = data.activity_log[0].est_start_time;
                const isActive = status !== OP_STATUS.PROCESSING
                    && status !== OP_STATUS.FINISHED
                    && start > Date.now();

                const op = {...data, isActive};
                const common = {dispatch, operations: selectPlan.ops(store.getState())}
                let opts;
                if (isOwn) {
                    opts = menuOptions[operation_type].map((type: MENU_OP) => menuOp[type]({
                        ...data,
                        isActive
                    }, {...common, edit}))

                } else if (permission || isVesselOwn) {
                    opts = [
                        menuOp[MENU_OP.DETAIL](op),
                        menuOp[MENU_OP.APPROVE](op, common),
                        menuOp[MENU_OP.DECLINE](op, common)
                    ];
                } else {
                    opts = [menuOp[MENU_OP.DETAIL](op)]
                }
                list = renderMenu(opts);
                break;
            }
            case EL_RULE.AVAILABLE_TIME: {
                const {time, vessel, availableTime} = target;
                const {isOwn, permission} = vessel;
                list = (!isOwn && !permission) ? [{
                    key: 'add',
                    label: <div
                        className='menu-line'
                        onMouseDown={() => handleAddEvent(Math.max(time, availableTime.start_time), vessel)}
                    >
                        <Icon icon={ICON_NAME.ADD}/>
                        <span>Add event</span>
                    </div>
                }] : [
                    {
                        key: 'edit',
                        label: <div className='menu-line' onMouseDown={target.edit}>
                            <Icon icon={ICON_NAME.EDIT}/>
                            <span>Edit available time</span>
                        </div>
                    },
                    {
                        key: 'delete',
                        label: <div className='menu-line' data-danger='true' onMouseDown={target.remove}>
                            <Icon icon={ICON_NAME.DELETE} danger/>
                            <span>Delete available time</span>
                        </div>
                    }
                ]
                break;
            }
            case EL_RULE.CONTRACT: {
                break;
            }
        }
        return list;
    }, [handleAddEvent, handleAddAvailableTime, dispatch]);


    return <div ref={calendarRef} id={CALENDAR_ID} className={styles.calendar}>
        <Weeks startTime={startTime} finishTime={finishTime}/>
        <PlanProvider
            userTenantId={tenantId}
            startPoint={startTime}
            calendarRef={() => calendarRef.current as HTMLDivElement}
            generateMenu={generateMenu}
            onDragOp={params => dispatch(planOpActions.dragOperation(params))}
        >
            <Vessels startTime={startTime} finishTime={finishTime}/>
        </PlanProvider>
    </div>
};

export default Calendar;
