import React, {useState} from 'react';
import {Button, Divider, Input, Modal} from "antd";
import styles from './style.module.scss';
import Sites from "./Sites";
import Header from "../../../components/Popup/Component/Header";
import Footer from "../../../components/Popup/Component/Footer";
import {propsModal} from "../../../util/varibles/constants";
import {generateSitesFetch, saveGenerateFetch} from "../../../util/services/tenant";
import {showErrorResponse} from "../../../util/varibles/global";
import {notify, NotifyCode} from "../../../util/varibles/message";

interface IProps {
    visible?: boolean
    data: any

    onClose?(): void
}

let isFlag = false;

const PopupGenerate: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [businessName, setBusinessName] = useState('');
    const [sites, setSites]: any[] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const {name = '', tenant_id = ''} = props.data || {}


    const generate = () => {
        if (businessName.trim().length === 0) {
            notify.error(NotifyCode.E25)();
            return;
        }
        setLoadingData(true);
        new Promise(resolve => resolve(generateSitesFetch({tenant_id, business_name: businessName})))
            .then((rs) => {
                setLoadingData(false);
                setSites(rs);
            })
            .catch(error => {
                setLoadingData(false);
                showErrorResponse('Generate failed', error);
            })
    }

    const handleSave = () => {
        if (sites.length === 0) {
            notify.error(NotifyCode.E24)();
            return;
        }
        if (!loadingData) {
            setLoadingSave(true);
            new Promise(resolve => resolve(saveGenerateFetch(sites)))
                .then(() => {
                    setLoadingSave(false);
                    handleClose();
                })
                .catch(error => {
                    setLoadingSave(false);
                    showErrorResponse('Save failed', error);
                })
        }
    }

    const handleClose = () => {
        setVisible(false);
        if (props.onClose)
            props.onClose();
    }

    const handleKeyDown = (e: any) => {
        isFlag = e.key === 'Enter';
    }

    const handleKeyUp = () => {
        if (isFlag)
            generate()
    }

    return <Modal
        {...propsModal}
        className={styles.popup}
        title={<Header title={<span className="font-s2 font-w4">
                <span>{name}</span>
                <Divider type="vertical" className={styles['divider']}/>
                <span>Generate site, unit</span>
            </span>} onClose={handleClose}/>}
        open={visible}
        onCancel={handleClose}
    >
        <div className={styles['container-business-name']}>
            <div className={styles['business-name']}>
                <Input
                    id='input-business-name'
                    value={businessName}
                    placeholder='Business name'
                    onChange={(e: any) => setBusinessName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                />
                <Button
                    type='primary'
                    className='bt-primary pl-16 pr-16'
                    loading={loadingData}
                    onClick={generate}
                >
                    Generate
                </Button>
            </div>
        </div>
        <div className={styles.filter}>
            <div className={styles.count}>
                Count: {sites.length}
            </div>
        </div>
        <div className={styles.list}>
            <Sites sites={sites} loading={loadingData} onUpdate={setSites}/>
        </div>
        <Footer
            cancel={{click: handleClose}}
            ok={{disabled: sites.length === 0, loading: loadingSave, click: handleSave}}
        />
    </Modal>;
};

export default PopupGenerate;

