import {CHAT_TYPE, KEY_STORAGE} from "../../util/varibles/constants";
import {IMessages} from "../../util/varibles/interface";


export enum MESSAGE_TYPE {
    ADD = 'add-notification',
    DELETE = 'delete-notification',
    LEAVE = 'leave-notification'
}

export enum CONVERSATION_MODE {
    CLOSE = 'close',
    OPEN = 'open',
    MINIMIZE = 'minimize'
}

export const tabMessage: any = {
    [CHAT_TYPE.USER]: {
        id: CHAT_TYPE.USER,
        icon: 'chat',
        name: 'Chat',
        keyNew: 'chatNew',
        keyData: 'chat'
    },
    [CHAT_TYPE.OPERATION]: {
        id: CHAT_TYPE.OPERATION,
        icon: 'operation',
        name: 'Operations',
        keyNew: 'operationsNew',
        keyData: 'operations'
    }
}

export const tabs = [
    tabMessage[CHAT_TYPE.USER],
    tabMessage[CHAT_TYPE.OPERATION]
]

export interface MessageState {
    chat: any[]
    chatIndex: number
    chatNew: number
    loadingChat: boolean
    chatMore: boolean
    operations: any[]
    operationsIndex: number
    operationsNew: number
    loadingOperation: boolean
    operationsMore: boolean
    messages: IMessages
    conversations: any[]
}

export const initialState: MessageState = {
    chat: [],
    chatIndex: 1,
    chatNew: 0,
    loadingChat: false,
    chatMore: false,
    operations: [],
    operationsIndex: 1,
    operationsNew: 0,
    loadingOperation: false,
    operationsMore: false,
    messages: {},
    conversations: JSON.parse(localStorage.getItem(KEY_STORAGE.CONVERSATION) || '[]')
}


