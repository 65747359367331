import React, {createRef, useEffect, useState} from 'react';
import * as echarts from "echarts";
import {EChartsType} from "echarts";
import {ECBasicOption} from "echarts/types/dist/shared";
import {IWidget} from "../../../constants";

interface IProps extends IWidget {
    unit: any
    width: number
    data: any
}

let options = {}
const Gauge: React.FC<IProps> = (props) => {
    const {data, width, sensors = []} = props;
    const [{min = 0, max = 100}] = props.yAxis || [];

    const rootRef = createRef<HTMLDivElement>();
    const [chartOpt, setChartOpt] = useState<EChartsType>();

    useEffect(() => {
        if (rootRef.current) {
            const el = rootRef.current;
            const existInstance = echarts.getInstanceByDom(el)
            if (existInstance) echarts.dispose(el)
            const chart = echarts.init(el);
            setChartOpt(chart);
            if (Object.keys(options).length > 0)
                chart.setOption(options, true);
        }
    }, [rootRef, width]);

    useEffect(() => {
        if (chartOpt) {
            const value: ECBasicOption = {
                tooltip: {
                    formatter: '{b}: {c} ' + sensors[0]?.unit
                },
                series: [
                    {
                        min,
                        max,
                        type: 'gauge',
                        detail: {
                            formatter: '{value}'
                        },
                        data: [
                            {
                                value: data.length > 0 ? data[0].data[0] : Number(min),
                                name: sensors[0]?.name
                            }
                        ]
                    }
                ]
            };
            options = {...options, ...value}
            chartOpt.setOption(value, true);
        }
    }, [chartOpt, data, min, max, sensors]);

    return <div ref={rootRef} className='h-full w-full'/>;
};

export default Gauge;
