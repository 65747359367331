import React, {Component} from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../Icon";

interface IProps {
    visible?: boolean
    type: boolean
}

class ExternalSite extends Component<IProps> {
    static defaultProps = {
        visible: true
    }

    render() {
        const {type, visible} = this.props;
        if (!visible || !type) return null;

        return <div className={styles['external-site']} title='External site'>
            <Icon icon={ICON_NAME.EXTERNAL_SITE} size='large'/>
        </div>
    }
}

export default ExternalSite;
