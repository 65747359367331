import React from 'react';
import stylesContainer from "../../style.module.scss";
import {Switch} from "antd";

interface IProps {
    isShow: boolean

    onClick(): void
}

const ActivitiesLogToggle: React.FC<IProps> = ({isShow, onClick}) => {

    return <div className={stylesContainer['operation-info-right']}>
        <div className={stylesContainer['activities-log']}>Activities log</div>
        <Switch checked={isShow} onChange={onClick}/>
    </div>;
};

export default ActivitiesLogToggle;
