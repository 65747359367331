import React from 'react';
import {onOpenPage} from "../../../../util/varibles/global";
import styles from "./style.module.scss";
import Certain from "../Component/Certain";
import {OP_STATUS, operationType} from "../../../../util/varibles/constants";
import {BrowserRouter, NavLink} from "react-router-dom";
import {IPropsChildren, TOperation} from "../../../../util/varibles/interface";
import Icon from "../../../Icon";
import Weather from "../../../WeatherChart";

interface IProps extends IPropsChildren {
    isOwn: boolean
    loading: any
    weather: any
    keyWeather: string
    operation: TOperation

    onClose(): void
}

const Header: React.FC<IProps> = (props) => {
    const {operation, isOwn, loading, weather, keyWeather, onClose} = props;
    const {tenant_name, status, operation_type, event_type} = operation;
    const link = `/operation/${operation.tenant_id}/${operation.id}`;
    const {icon} = operationType[operation_type];
    return <>
        <div className={[styles['popup-header'], 'flex justify-center mb-2'].join(' ')}>
            <div className='w-full no-select d-flex align-items-center'>
                <div className={styles.infoId}>
                    <Icon icon={icon} dataAttribute={{'data-event-type': event_type}}/>
                    {status !== OP_STATUS.NEW ? <BrowserRouter>
                        <NavLink onMouseDown={(e: any) => onOpenPage(e, link, onClose)} to={link}>
                            #{operation.operation_code}
                        </NavLink>
                    </BrowserRouter> : <span>#{operation.operation_code}</span>}
                </div>
                {props.children}
            </div>
            <div className='d-flex align-items-center'>
                {!isOwn && <div className={styles['spot']}>({tenant_name}) <div/></div>}
                <Certain operation={operation} status={status}/>
                <div className='bt-close' onMouseDown={onClose}/>
            </div>
        </div>
        <Weather {...{keyWeather, loading: loading.weather, weather, minInterval: 10800000}}/>
    </>;
};

export default Header;
