import React, {Component} from 'react';

class Tank extends Component {
    render() {
        return <div>

        </div>;
    }
}

export default Tank;
