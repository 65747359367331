import React from 'react';
import {IOperationLog, IVessel} from "../../../../../util/varibles/interface";
import styles from './style.module.scss';
import {LOG_STATUS, operationType, VIEW_SMOOTH} from "../../../../../util/varibles/constants";
import Differences from "./Differences";
import Icon from "../../../../../components/Icon";
import {getElById} from "../../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../../util/varibles/message";

export const status = {
    [LOG_STATUS.CREATE]: 'be created',
    [LOG_STATUS.UPDATE]: 'be updated',
    [LOG_STATUS.DELETE]: 'be deleted',
    [LOG_STATUS.CANCEL]: 'be canceled',
    [LOG_STATUS.ADDED]: 'be added',
}

interface IProps {
    data: IOperationLog
    vessels: IVessel[]
}

const Operation: React.FC<IProps> = ({data, vessels}) => {
    const {operation_id, operation_code, operation_type, site_name, vessel_id, differences, state} = data;
    const {icon} = operationType[operation_type] || {};
    const code = [operation_code];
    if (operation_type === undefined)
        return null;

    if ((site_name || '').length > 0)
        code.push(site_name)

    const onClick = () => {
        const el = getElById(operation_id);
        const vessel = vessels.find(item => item.id === vessel_id);
        if (el) {
            el.scrollIntoView(VIEW_SMOOTH)
        }

        if (vessel) {
            const {isShow, name} = vessel;
            if (!isShow)
                notify.warn(NotifyCode.W5)([name, operation_code]);
        }
    }

    const {is_active_vessel} = data.differences || {};

    return <div className={styles.operation}>
        <div className={styles.opId}>
            <Icon icon={icon}/>
            <div className={styles.opCode}>
                <span onClick={onClick}>#{code.join(', ')}</span>
                <span> - {is_active_vessel?.new ? ' be sent to vessel' : status[state]}</span>
            </div>
        </div>
        <Differences data={differences}/>
    </div>;
};

export default Operation;
