import {initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";

const combineTreatmentSlice = createSlice({
    name: 'combineTreatment',
    initialState,
    reducers: {
        getListRequest: (state) => {
            state.list = [];
            state.loadingList = true;
        },
        getListSuccess: (state, action) => {
            state.list = action.payload;
            state.loadingList = false
        },
        getListFailure: (state) => {
            state.loadingList = false
        },
        add: (state, action) => {
            const data = action.payload;
            const {list} = state;
            state.list = [...list, data]
        },
        update: (state, action) => {
            const data = action.payload;
            const {id} = data;
            const {list} = state;
            state.list = list.map(item => item.id === id ? data : item);
        },
        delete: (state, action) => {
            const {id} = action.payload;
            const {list} = state;
            state.list = list.filter(item => item.id !== id);
        }
    },
})

export const combineTreatmentActions = combineTreatmentSlice.actions

const combineTreatmentReducer = combineTreatmentSlice.reducer;

export default combineTreatmentReducer;