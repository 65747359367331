import loginReducer, {loginActions} from "../../pages/LoginPage/reducer";
import messageReducer from "../../pages/MessagePage/reducer";
import dashboardReducer from "../../pages/DashboardPage/reducer";
import planOperationReducer from "../../pages/PlanOperationPage/reducer";
import overviewReducer from "../../pages/OverviewPage/reducer";
import opDetailReducer from "../../pages/OperationDetailPage/reducer";
import diseaseReducer from "../../pages/DiseasePage/reducer";
import combineTreatmentReducer from "../../pages/CombineTreatmentPage/reducer";
import routeReducer from "../../pages/RoutePage/reducer";
import checklistReducer from "../../pages/ChecklistPage/reducer";
import configurationReducer from "../../pages/ConfigurationPage/reducer";
import siteMonitoringReducer from "../../pages/SiteMonitoringPage/reducer";
import taskTypeReducer from "../../pages/TaskTypeManagementPage/reducer";
import vesselDetailReducer from "../../pages/VesselDetailPage/reducer";
import vesselManagementReducer from "../../pages/VesselManagementPage/reducer";
import userReducer from "../../pages/UserPage/reducer";
import weatherReducer from "../../pages/WeatherPage/reducer";
import {combineReducers} from "@reduxjs/toolkit";
import locationReducer from "./location/reducer";
import {NAME_SLICE as location} from "./location/constants";
import {NAME_SLICE as region} from "./region/constants";
import regionReducer from "./region/reducer";

const appReducer = combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    message: messageReducer,
    planOperation: planOperationReducer,
    overview: overviewReducer,
    operationDetail: opDetailReducer,
    disease: diseaseReducer,
    combineTreatment: combineTreatmentReducer,
    route: routeReducer,
    checklist: checklistReducer,
    configuration: configurationReducer,
    siteMonitoring: siteMonitoringReducer,
    taskType: taskTypeReducer,
    vesselDetail: vesselDetailReducer,
    vesselManagement: vesselManagementReducer,
    user: userReducer,
    weather: weatherReducer,
    [location]: locationReducer,
    [region]: regionReducer
});

const rootReducer = (state: any, action: any) => {
    if (action.type === loginActions.logout.type) {
        const data: any = {};
        return appReducer(data, action);
    }
    return appReducer(state, action);
}
export default rootReducer;