import React from 'react';
import {connect, useDispatch} from "react-redux";
import {IVessel} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import PopupFilterVessel from "../../../../components/Popup/PopupFilterVessel";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {OverviewSetting} from "../../../../util/varibles/userSetting";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {overviewActions} from "../../reducer";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import {AppState} from "../../../../util/store/store";

const key = OverviewSetting.VESSEL_FILTER;

const mapStateToProps = (state: AppState) => {
    const vesselFilter = getSetting(state.login.user.setting, key);
    return {
        vessels: state.overview.vessels,
        vesselFilter
    }
};

interface IProps {
    vessels: IVessel[]
    vesselFilter: string[]

    updateVessels(payload: IVessel[]): void
}

const VesselFilter: React.FC<IProps> = ({vessels, vesselFilter, updateVessels}) => {
    const dispatch = useDispatch();

    const onChange = (data: string[]) => {
        const isEmpty = data.length === 0;
        const newVessels = vessels.reduce((rs: any, item: any) =>
            [...rs, {...item, isShow: isEmpty || data.indexOf(item.id) !== -1}], []);
        updateVessels(newVessels);
        dispatch(loginActions.updateUserSetting([{[key]: data}]));
    };

    const handleClear = (e: any) => {
        e.stopPropagation();
        const newVessels = vessels.reduce((data: any, item: any) => [...data, {...item, isShow: true}], [])
        updateVessels(newVessels);
        dispatch(loginActions.updateUserSetting([{[key]: []}]));
    }

    const handleOpen = () => {
        const div = openPopup(<PopupFilterVessel {...{
            selected: vesselFilter,
            vessels,
            onSave: onChange,
            onClose: () => {
                div.remove();
            }
        }}/>)
    }

    let count = vessels.filter(({isShow}) => isShow).length;

    return <div className={styles.vesselFilter} onClick={handleOpen}>
        <Icon className={styles.icon} icon={ICON_NAME.VESSEL}/>
        <span className={styles.name}>Vessel filter</span>
        {(count > 0 && count !== vessels.length) && <span className={styles.clear} onClick={handleClear}>
            <span>{[count, vessels.length].join('/')}</span>
            <Icon icon={ICON_NAME.CLOSE} size='small'/>
        </span>}
    </div>;
};

export default connect(mapStateToProps, {
    updateVessels: overviewActions.updateVessels,
})(VesselFilter);
