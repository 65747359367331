import {aMinutesMillisecond, KEY_STORAGE} from "../varibles/constants";
import {GlobalService} from "../services/global";
import {store} from "../store/store";
import {loginActions} from "../../pages/LoginPage/reducer";

async function getToken() {
    try {
        const authentication: any = await GlobalService.loginBrantSwatch()

        const params = {...authentication, time: Date.now()};

        store.dispatch(loginActions.saveBarentsSwatch(params));

        return authentication.access_token;
    } catch (err) {
        console.error('Get data of BrantSwatch failed')
        return ''
    }
}

export default async function callApi(url: string, options?: any) {

    let token;
    try {
        const authentication = JSON.parse(localStorage.getItem(KEY_STORAGE.BARENTS_SWATCH) || '');
        const {expires_in, time = 0} = authentication;
        const expiredTime = expires_in * 1000 - (15 * aMinutesMillisecond) + time;
        if (expiredTime < Date.now()) {
            token = await getToken();
        } else
            token = authentication.access_token || '';
    } catch (error) {
        token = await getToken();
        // console.log(error)
    }

    if (token.length === 0) {
        return;
    }

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`,

    }

    try {
        let response = await fetch(url, {method: 'GET', headers, ...options || {}});
        return  await response.json();
    } catch (error) {
        throw error
    }
}