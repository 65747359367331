import {api} from "../api/callApi";
import {urlFactory} from "../api/v1";
import {IFactory} from "../varibles/interface";

export const FactoryService = {
    create: (body: any) => api.post<IFactory>(urlFactory, body),
    update: (body: any) => api.put<IFactory>(urlFactory, body),
    gets: () => api.get<IFactory[]>(urlFactory),
    getByAdmin: () => api.get<IFactory[]>(urlFactory + '/s'),
    getBySuperAdmin: () => api.get<IFactory[]>(urlFactory + '/admin'),
}