import React, { Component } from 'react';
import { IPropsElement } from "./constants";
import ContainerElement from "./ContainerElement";
import { Radio } from "antd";

interface IProps extends IPropsElement {

}

class RadioElement extends Component<IProps> {
    render() {
        const { options = '', numberOfColumns = '' } = this.props.properties;
        const data = options.length > 0 ? options.split('\n').reduce((list: any[], item, index) => {
            if (item.trim().length > 0)
                list = [ ...list, { label: item, value: index } ]
            return list;
        }, []) : [];
        const style: any = {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: `repeat(${ numberOfColumns.length > 0 ? JSON.parse(numberOfColumns).value : 1 }, 1fr)`
        };
        return <ContainerElement { ...this.props } >
            <Radio.Group { ...{ style, options: data, } } />
        </ContainerElement>;
    }
}

export default RadioElement;
