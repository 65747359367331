import React from 'react';
import styles from "./style.module.scss";
import Icon, {ICON_NAME} from "../Icon";
import {Symbol} from "../../util/varibles/template";
import {getStatusDensity} from "../../pages/PlanOperationPage/util/function_operation/constants";

interface IProps {
    density: number
    tenantDensity: number[]
    short?: boolean
    max_density?: number
}

const Density: React.FC<IProps> = React.memo(({density, tenantDensity, short, max_density}) => {

    const value = <>
        <span>{Math.round(density * 100000) / 100} kg/{Symbol.m3} </span>
        {max_density && <span className='text-comment ml-[5px]'>({Math.round(max_density * 100000) / 100}kg/{Symbol.m3})</span>}
    </>
    const {short: name = '', color = ''} = getStatusDensity(tenantDensity, density);

    return <div className={styles.density}>
        <Icon icon={ICON_NAME.TANK} style={{background: color, marginBottom: '-1px'}}/>
        <div>
            {short ? value : <>{name} density - {value} </>}
        </div>
    </div>;
}, (prev, next) =>
    prev.density === next.density
    && prev.tenantDensity === next.tenantDensity
    && prev.max_density === next.max_density);

export default Density;
