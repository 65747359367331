import {initialState, initReplay, MODE_UPDATE_HISTORY} from "./constants";
import {createSlice} from "@reduxjs/toolkit";
import {SHORT_TIME_FORMAT} from "../../util/varibles/constants";
import {datetime} from "../../util/library/datetime";
import {checkLimit, cloneObj} from "../../util/varibles/global";
import {speedList} from "./ProcessData/Timeline/constants";

export const initHistory = () => {
    return {
        startTime: datetime().subtract(1, 'day').startOf('day').time,
        finishTime: Date.now(),
    }
}

const vesselDetailSlice = createSlice({
    name: 'vesselDetail',
    initialState,
    reducers: {
        getVesselRequest: (state, action) => {
            state.replay = initReplay;
            state.isLive = false;
            state.isTracking = false;
            state.currentTime = Date.now();
            state.history = initHistory()
            state.update = {mode: MODE_UPDATE_HISTORY.NONE, keys: []}
        },
        getVesselSuccess: (state, action) => {
            const {vessel, location, callback} = action.payload;
            state.vessel = vessel;

            if (location) {
                const {vesselLive} = state;
                let time;
                if (location && location.time) {
                    time = new Date(location.time.replace(' ', 'T') + 'Z').getTime()
                    state.vesselLive = {
                        ...vesselLive,
                        time: Date.now(),
                        gps: {...location, time}
                    }
                } else {
                    state.vesselLive = {
                        gps: {
                            Longitude: 0,
                            Latitude: 0,
                            Heading: 0,
                            Speed: 0,
                            time: Date.now()
                        },
                        time: Date.now()
                    }
                }
                state.isLive = true;
                state.isTracking = true;
            }
            if (callback)
                callback(vessel)
        },
        getVesselFailure: (state) => {
        },
        update: (state: any, action) => {
            const params = cloneObj(action.payload);
            Object.keys(params).forEach((key: string) => {
                state[key] = params[key];
            })
        },
        getDashboardsRequest: (state, action) => {
        },
        getDashboardsSuccess: (state, action) => {
            const {dashboards, dashboard, keys = []} = action.payload;
            state.dashboards = dashboards;
            state.dashboard = dashboard;
            state.deviceIds = keys
        },
        getDashboardsFailure: (state) => {
        },
        createDashboard: (state, action) => {
            const {dashboard, history} = action.payload;
            const {dashboards} = state;
            state.dashboard = dashboard;
            state.history = history;
            state.dashboards = [...dashboards, dashboard]
        },
        updateDashboard: (state, action) => {
            const {dashboard} = action.payload;
            const {dashboards} = state;
            const {dashboard_id} = dashboard;
            state.dashboard = dashboard;
            state.dashboards = dashboards.map((item: any) => item.dashboard_id === dashboard_id ? dashboard : item);
            const content = dashboard ? JSON.parse(dashboard.content) : [];
            state.deviceIds = content.reduce((list: any, {keys = []}: any) => {
                return [...list, ...keys.map(({device_id, sensor}: any) => ({
                    key: device_id + '/' + sensor,
                    device_id,
                    sensor,
                }))]
            }, []);
        },
        getCurrentSensorDataRequest: (state, action) => {
        },
        getCurrentSensorDataSuccess: (state, action) => {
            const sensors = action.payload;
            const {vesselLive} = state;
            state.vesselLive = {...vesselLive, ...sensors, time: Date.now()};
        },
        getCurrentSensorDataFailure: (state) => {
        },
        getDevices: (state, action) => {
            state.devices = action.payload;
        },
        updateLocation: (state, action) => {
            const {vesselLive, history, isLive} = state;
            if (isLive) {
                const data = action.payload;
                const {name, value, time, device_id} = data;
                if (Object.keys(vesselLive).length === 0)
                    return state;
                const {gps = {}} = vesselLive;
                const time_stamp = new Date(time.replace(' ', 'T') + 'Z').getTime();
                const key = device_id + '/' + name;
                const isExist = gps[name] !== undefined;
                const finishTime = time_stamp > vesselLive.time ? time_stamp : vesselLive.time;
                state.vesselLive = {
                    ...vesselLive,
                    time: finishTime,
                    gps: isExist ? {...gps, [name]: value, time: time_stamp > gps.time ? time_stamp : gps.time} : gps,
                    [key]: {...vesselLive[key], value, time, time_stamp}
                };
                state.currentTime = finishTime

                state.history = {...history, finishTime};
                state.update = {mode: MODE_UPDATE_HISTORY.ADD_RIGHT, keys: [{key, value: {value, time}}]}
            }
        },
        updateHistory: (state, action) => {
            state.history = action.payload;
        },
        addGps: (state, action) => {
            const {vesselLive, history} = state;
            const time = datetime(action.payload).toISOString().replace('T', ' ').slice(0, -1)
            state.history = history
            state.update = {
                mode: MODE_UPDATE_HISTORY.ADD_RIGHT,
                keys: [{key: 'gps', value: {value: vesselLive, time}}]
            }
        },
        updateVessel: (state, action) => {
            state.vessel = {...state.vessel, ...action.payload};
        },
        updateDensity: (state, action) => {
            state.vessel = {...state.vessel, density_map: {...state.vessel.density_map || {}, ...action.payload}};
        },
        beginDrag: (state) => {
            state.replay = {...state.replay, isPlay: false};
            state.isDragging = true;
            state.isLive = false
        },
        dragging: (state, action) => {
            const {startTime, finishTime} = state.history;
            const {duration, key} = action.payload;
            const {[key]: base}: any = {finishTime, currentTime: state.currentTime};
            const time = checkLimit(startTime, finishTime, base + duration);
            const isPlay = datetime(time).format(SHORT_TIME_FORMAT) === datetime().format(SHORT_TIME_FORMAT);
            state.isTracking = true;
            state.isLive = isPlay
            state.replay.isPlay = isPlay
            state.currentTime = time
        },
        addTime: (state, action) => {
            const newTime = state.currentTime + action.payload;
            let currentTime
            if (newTime >= Date.now()) {
                currentTime = Date.now()
                state.isLive = true;
                state.replay.speed = speedList[0].key;
                state.history.finishTime = currentTime;
            } else {
                currentTime = newTime;
            }
            state.currentTime = currentTime
        }
    },
})

export const vesselDetailActions = vesselDetailSlice.actions

const vesselDetailReducer = vesselDetailSlice.reducer;

export default vesselDetailReducer;
