import React, {useState} from 'react';
import {CHAT_TYPE, KEY_STORAGE} from "../../../../../util/varibles/constants";
import styles from './style.module.scss';
import Chat from "./Chat";
import Operation from "./Operation";
import {tabs} from "../../../../../pages/MessagePage/constants";
import CountNew from "./CountNew";

interface IProps {
    onOpenConversation(id: string): void

    onAddConversation(chatType: CHAT_TYPE): void
}

const Messages: React.FC<IProps> = (props) => {
    const [open, setOpen] = useState(localStorage.getItem(KEY_STORAGE.CHAT_BOX) === 'true');
    const [tab, setTab] = useState<CHAT_TYPE>(localStorage.getItem(KEY_STORAGE.CHAT_TYPE) === CHAT_TYPE.OPERATION ? CHAT_TYPE.OPERATION : CHAT_TYPE.USER)

    const attr: any = {
        className: styles.wrapper,
        'data-is-open': open
    }
    if (!open) {
        attr.onClick = () => toggleBox(true)
    }

    const toggleBox = (isOpen: boolean) => {
        setOpen(isOpen)
        localStorage.setItem(KEY_STORAGE.CHAT_BOX, isOpen.toString());
    }

    const handleSelectTab = (tab: CHAT_TYPE) => {
        localStorage.setItem(KEY_STORAGE.CHAT_TYPE, tab);
        setTab(tab)
    }

    const renderContent: any = {
        [CHAT_TYPE.USER]: <Chat
            onOpenConversation={props.onOpenConversation}
            onAddConversation={() => props.onAddConversation(CHAT_TYPE.USER)}
        />,
        [CHAT_TYPE.OPERATION]: <Operation
            onOpenConversation={props.onOpenConversation}
            onAddConversation={() => props.onAddConversation(CHAT_TYPE.OPERATION)}
        />
    }

    return <div  {...attr}>
        <div className={styles.group}>
            <div className={styles.messages}>
                {tabs.map(item => <div key={item.id} className={styles.section} data-actived={item.id === tab}>
                    {renderContent[item.id]}
                </div>)}
            </div>
            <div className={styles.sidebar}>
                <div className={styles.close} onClick={() => toggleBox(false)}/>
                {tabs.map(item => <CountNew
                    key={item.id}
                    data={item} selected={tab}
                    handleSelectTab={handleSelectTab}
                />)}
            </div>
        </div>
    </div>;
};

export default Messages;
