import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {
    ACTION_TYPE,
    CUSTOMER_TYPE,
    customerTypes,
    INTEGRATION_MODE,
    integrationModes,
    OPERATION_TYPE,
    operationType,
    propsModal
} from "../../../util/varibles/constants";
import {Button, Drawer, Form, Input, Radio, Select, Switch} from "antd";
import styles from "./style.module.scss";
import {FormInstance} from "antd/lib/form";
import {IDomain, ITenant} from "../../../util/varibles/interface";
import Header from "../../../components/Popup/Component/Header";
import {formValidation} from "../../../util/varibles/validation";
import {userActions} from "../reducer";
import {createTenantFetch, updateTenantFetch} from "../../../util/services/tenant";
import {loginActions} from "../../LoginPage/reducer";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";
import AddDomain from "./AddDomain";
import {AppState} from "../../../util/store/store";

const rulesOfForm = {
    name: [formValidation.require('Name')],
    domain: [formValidation.require('Domain')],
    integration_user: [formValidation.require('Username')],
    integration_password: [formValidation.require('Password')],
    integration_url: [formValidation.require('Url')],
}

const initForm = {
    customer_type: CUSTOMER_TYPE.TRIAL,
    integration: {
        type: INTEGRATION_MODE.STHP_FILE
    },
    default_op_type: {
        global: OPERATION_TYPE.HARVEST
    }
}


const mapStateToProps = (state: AppState) => ({
    domains: state.login.domains,
    userManagement: state.user
})

interface IProps {
    visible?: boolean
    customer?: ITenant
    domains: (IDomain & { isNew?: boolean })[]
    editMode: ACTION_TYPE

    onClose?(): void

    addTenant(payload: ITenant): void

    updateTenant(payload: ITenant): void

    addDomain(payload: IDomain): void
}

const PopupEditCustomer: React.FC<IProps> = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [loading, setLoading] = useState(false);
    const [domains, setDomains] = useState(props.domains);
    const formRef = useRef<FormInstance>(null);

    useEffect(() => setDomains(props.domains), [props.domains]);

    const {editMode, customer = {customer_type: CUSTOMER_TYPE.TRIAL, tenant_id: null}} = props;
    useEffect(() => {
        let params: any = initForm;
        if (editMode === ACTION_TYPE.EDIT)
            params = customer.customer_type === CUSTOMER_TYPE.TRIAL ? {
                ...customer,
                integration: {type: null}
            } : customer;

        setTimeout(() => {
            if (formRef.current)
                formRef.current.setFieldsValue(params)
        }, 100)
    }, [customer, editMode])

    const handleClose = () => {
        setVisible(false);
        if (props.onClose)
            props.onClose()
    }

    const handleSubmit = (values: any) => {
        const {
            name,
            customer_type,
            auto_route_license = false,
            weather_license = false,
            domain,
            default_op_type
        } = values;
        const rootDomain = domains.find((item: any) => item.domain === domain);
        if (!rootDomain) {
            notify.error(NotifyCode.E23)(['domain']);
            return;
        }
        const {isNew = false, provider_name, sso_mode} = rootDomain || {};

        const params: any = {
            name,
            domain,
            customer_type,
            auto_route_license,
            weather_license,
            default_op_type
        }

        if (isNew) {
            params.provider_name = provider_name;
            params.sso_mode = sso_mode;
        }

        if (customer_type === CUSTOMER_TYPE.TRIAL) {
            const {business_name} = values;
            params.business_name = business_name;
            params.integration_mode = CUSTOMER_TYPE.TRIAL;
            params.integration = {type: CUSTOMER_TYPE.TRIAL}
        } else {
            const {type} = values.integration;
            if (type === INTEGRATION_MODE.STHP_FILE) {
                params.integration_mode = 'sthp';
                params.integration = {
                    type: INTEGRATION_MODE.STHP_FILE
                }
            } else {
                const {integration} = values;
                params.integration_mode = INTEGRATION_MODE.ODATA;
                params.integration = integration;
            }
        }

        setLoading(true);
        if (editMode === ACTION_TYPE.EDIT) {
            const {tenant_id} = customer;
            params.tenant_id = tenant_id;
            new Promise(resolve => resolve(updateTenantFetch(params)))
                .then((rs: any) => {
                    props.updateTenant(rs);
                    afterEdit(rootDomain);
                    setLoading(false);
                    handleClose();
                })
                .catch(error => {
                    setLoading(false);
                    showErrorResponse('Update failed', error);
                })
        } else {
            new Promise(resolve => resolve(createTenantFetch(params)))
                .then((rs: any) => {
                    props.addTenant(rs);
                    afterEdit(rootDomain);
                    setLoading(false);
                    handleClose();
                })
                .catch(error => {
                    setLoading(false);
                    showErrorResponse('Create failed', error);
                })
        }
    }

    const afterEdit = (domain: any) => {
        const {isNew, ...args} = domain;
        if (isNew)
            props.addDomain(args);
    }

    const handleAddDomain = (data: IDomain) => {
        setDomains([{...data, isNew: true}, ...domains]);
        setTimeout(() => {
            if (formRef.current) {
                formRef.current.setFieldsValue({domain: data.domain})
            }
        }, 100)
    }

    return <Drawer
        {...propsModal}
        className={styles['popup-edit-customer']}
        title={<Header
            title={`${editMode === ACTION_TYPE.CREATE ? 'Add' : 'Update'} customer`}
            onClose={handleClose}
        />}
        open={visible}
        width='30%'
        height='100%'
        destroyOnClose
        closable={false}
        onClose={handleClose}
    >
        <Form
            layout='vertical'
            ref={formRef}
            onFinish={handleSubmit}
            requiredMark={false}
        >
            <Form.Item
                label={<span className='text-[15px]'>Name</span>}
                name="name"
                rules={rulesOfForm.name}
            >
                <Input className='border-r-40 h-36' placeholder='Name'/>
            </Form.Item>
            <Form.Item
                label={<span className='text-[15px]'>Domain</span>}
                name="domain"
                rules={rulesOfForm.domain}
            >
                <Select
                    className='select-single'
                    placeholder='Domain'
                    dropdownRender={(menu: any) => <AddDomain {...{
                        domains,
                        menu,
                        onAdd: handleAddDomain,
                    }}/>}
                    options={domains.map(item => ({value: item.domain, label: item.domain}))}
                />
            </Form.Item>
            <Form.Item name={['default_op_type', 'global']} label="Default operation type">
                <Select
                    className='select-single'
                    placeholder='Default operation type'
                    options={[
                        operationType[OPERATION_TYPE.HARVEST],
                        operationType[OPERATION_TYPE.TREATMENT],
                        operationType[OPERATION_TYPE.TRANSPORT],
                        operationType[OPERATION_TYPE.SERVICE],
                    ].map(item => ({value: item.id, label: item.name}))}
                />
            </Form.Item>
            <Form.Item
                label='Weather license:'
                className='mb-12 form-item-horizontal'
                name="weather_license"
                valuePropName="checked"
            >
                <Switch checkedChildren="On" unCheckedChildren="Off"/>
            </Form.Item>
            <Form.Item
                className='mb-12 form-item-horizontal'
                name="auto_route_license"
                label="Auto route:"
                valuePropName="checked"
            >
                <Switch checkedChildren="On" unCheckedChildren="Off"/>
            </Form.Item>
            <Form.Item
                className='mb-12 form-item-horizontal'
                name="customer_type"
                label="Type: "
            >
                <Radio.Group className='ml-16'>
                    {customerTypes.map(({id, name}) => <Radio key={id} value={id}>{name}</Radio>)}
                </Radio.Group>
            </Form.Item>
            <Form.Item noStyle
                       shouldUpdate={(prev: any, current: any) => prev.customer_type !== current.customer_type}>
                {({getFieldValue}: any) => {
                    const customerType = getFieldValue('customer_type') || CUSTOMER_TYPE.TRIAL
                    return customerType === CUSTOMER_TYPE.ENTERPRISE && <>
                        <Form.Item name={['integration', 'type']} label="Integration mode">
                            <Select
                                className='select-single'
                                placeholder='Integration type'
                                options={integrationModes.map(item => ({value: item.id, lalbel: item.name}))}
                            />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prev: any, current: any) => prev.integration && current.integration
                                && (prev.integration.type !== current.integration.type)}
                        >
                            {({getFieldValue}: any) => {
                                const {type: integrationType} = getFieldValue('integration') || {type: INTEGRATION_MODE.STHP_FILE}
                                return integrationType === INTEGRATION_MODE.ODATA && <>
                                    <Form.Item label={<span className='text-[15px]'>Username</span>}
                                               name={['integration', 'user']}
                                               rules={rulesOfForm.integration_user}
                                    >
                                        <Input className='input' placeholder='Name'/>
                                    </Form.Item>
                                    <Form.Item label={<span className='text-[15px]'>Password</span>}
                                               name={['integration', 'pass']}
                                               rules={rulesOfForm.integration_password}
                                    >
                                        <Input className='input' placeholder='Name'/>
                                    </Form.Item>
                                    <Form.Item label={<span className='text-[15px]'>Url</span>}
                                               name={['integration', 'url']}
                                               rules={rulesOfForm.integration_url}
                                    >
                                        <Input className='input' placeholder='Name'/>
                                    </Form.Item>
                                </>
                            }}
                        </Form.Item>
                    </>
                }}
            </Form.Item>
            <div className={styles['footer']}>
                <Button className='bt-default' onClick={handleClose}> Cancel </Button>
                <Button type='primary' loading={loading} htmlType="submit" className='bt-primary'> Save </Button>
            </div>
        </Form>
    </Drawer>;
};

export default connect(mapStateToProps, {
    addTenant: userActions.addTenant,
    updateTenant: userActions.updateTenant,
    addDomain: loginActions.addDomain
})(PopupEditCustomer);