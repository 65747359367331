import {initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";


const taskTypeSlice = createSlice({
    name: 'taskType',
    initialState,
    reducers: {
        getListRequest: (state) => {
            state.taskTypes = [];
            state.loading = true;
        },
        getListSuccess: (state, action) => {
            state.taskTypes = action.payload;
            state.loading = false
        },
        getListFailure: (state) => {
            state.loading = false
        },
        add: (state, action) => {
            const taskType = action.payload;
            const {taskTypes} = state;
            state.taskTypes = [...taskTypes, taskType]
        },
        update: (state, action) => {
            const taskType = action.payload;
            const {id} = taskType;
            const {taskTypes} = state;
            state.taskTypes = taskTypes.map(item => item.id === id ? taskType : item);
        },
        delete: (state, action) => {
            const {id} = action.payload;
            const {taskTypes} = state;
            state.taskTypes = taskTypes.filter(item => item.id !== id);
        }
    },
})

export const taskTypeActions = taskTypeSlice.actions

const taskTypeReducer = taskTypeSlice.reducer;

export default taskTypeReducer;

