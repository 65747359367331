import React from 'react';
import styles from "./style.module.scss";
import {Button} from "antd";
import {useSelector} from "react-redux";
import {ACTION_TYPE} from "../../../../../util/varibles/constants";
import {selectPlan} from "../../../../../util/store/selectors";

interface IProps {
    saveTxt?: string
    disableSave?: boolean
    loading?: boolean

    handleSubmit(): void

    onClose(): void
}

const Footer: React.FC<IProps> = (props) => {
    const {editMode} = useSelector(selectPlan.popupEdit);

    const {
        disableSave,
        saveTxt = editMode === ACTION_TYPE.CREATE ? 'Plan' : 'Update',
        loading,
        handleSubmit
    } = props;

    return <div className={styles.footer}>
        <Button {...{
            className: 'bt-primary',
            type: 'primary',
            loading,
            onClick: handleSubmit,
            disabled: disableSave
        }}>
            <span>{saveTxt}</span>
        </Button>
        <Button className='bt-default' onClick={props.onClose}>Cancel</Button>
    </div>;
};

export default Footer;


