import React, {useMemo} from 'react';
import {IUnitStorage} from "../../../util/varibles/interface";
import styles from './style.module.scss';
import {useSelector} from "react-redux";
import {OPERATION_TYPE} from "../../../util/varibles/constants";
import {selectTenant} from "../../../util/store/selectors";
import {Tooltip} from "antd";
import Icon, {ICON_NAME} from "../../Icon";
import Density from "../Density";

interface IPropsStorages {
    tenantDensity: number[]
    isEmpty: boolean
    storage: IUnitStorage
}


const Storage: React.FC<IPropsStorages> = ({tenantDensity, isEmpty, storage}) => {
    const {value = 0} = storage;
    if (!value) {
        if (isEmpty) {
            return <Tooltip title="Input vessel's volume to have operation's density.">
                <div className={styles.storage}>
                    <Icon icon={ICON_NAME.TANK}/>
                    <div className={styles.name}>N/A</div>
                </div>
            </Tooltip>;
        } else
            return null;
    }
    return <Density density={storage.density} tenantDensity={tenantDensity}/>
};

interface IProps {
    className?: string
    type: OPERATION_TYPE
    isEmpty?: boolean
    storages?: IUnitStorage[]
}

const TankDensities: React.FC<IProps> = ({className, type, isEmpty = false, storages = []}) => {
    const {density = {}} = useSelector(selectTenant);
    const tenantDensity = useMemo(() => density[type] || [], [type, density]);

    if (tenantDensity.length === 0 || storages.length === 0)
        return null

    return <div className={[styles.densities, className].join(' ')}>
        {storages.map((item, index) => {
            return <Storage key={index} isEmpty={isEmpty} tenantDensity={tenantDensity} storage={item}/>
        })}
    </div>;
};

export default TankDensities;
