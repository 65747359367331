import React, {Component} from 'react';
import styles from './style.module.scss';
import {checkLimit, getWidthOfText} from "../../../../../util/varibles/global";
import {vesselColors} from "../../../../../util/library/googlemap/constants";

interface IProps {
    vessel: any;
    operation: any
    max_point: number
}

class Vessel extends Component<IProps> {
    render() {
        const {vessel, operation, max_point = 1} = this.props;
        const {status, current_process: currentProcess = 0} = operation;
        const styleVessel = {left: '0'};
        if (status === 'processing') {
            styleVessel.left = checkLimit(0, undefined, currentProcess / max_point * 100) + '%'
        }
        if (status === 'finished')
            styleVessel.left = '100%';
        const {strokeColor, fillColor} = vesselColors[vessel.type - 1] || vesselColors[0];
        const nameWidth = getWidthOfText(vessel.name);
        const nameStyle = nameWidth > 80 ? {right: '-15px'} : {left: 0}
        return <div className={styles.vessel} style={styleVessel}>
            <span style={nameStyle}>{vessel.name}</span>
            <svg height="18" width="42" fill={fillColor} stroke={strokeColor} strokeWidth="2">
                <path d="M 2 2 L 30 2 L 40 9 L 30 16 L 2 16 Z"/>
            </svg>
        </div>;
    }
}

export default Vessel;
