import React, {ReactNode} from 'react';
import {ILevelByOp} from "../../../../../util/varibles/interface";
import Operation from "./Operation";
import {OperationProvider} from "../../../../../contexts/OperationContext";
import {usePlan} from "../../../../../contexts/PlanOperationContext/Plan";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../util/store/selectors";

interface IProps {
    index: number
    width: number
    level?: ILevelByOp
}

const Operations: React.FC<IProps> = ({level = {}, width, ...args}) => {
    const [userTenantId] = usePlan(state => state.userTenantId);
    const operations = useSelector(selectPlan.ops);

    return <>
        {Object.keys(level).reduce((rs: ReactNode[], key) => {
            const item = operations[key] || {};

            if (!item?.operation)
                return rs;

            const {id, tenant_id} = item.operation || {};
            const isOwn = tenant_id === userTenantId;
            return [...rs, <OperationProvider
                key={item.operation.id}
                isOwn={isOwn}
                data={item}
                level={level[id]}
                width={width}
            >
                <Operation {...args} data={item} isOwn={isOwn}/>
            </OperationProvider>]
        }, [])}
    </>;
};

export default Operations;
