import {
    clearElements,
    colorProductionArea,
    ICreateDiseasedZone,
    ICreateProductionArea,
    IPropsMap,
    MAP_ELEMENT
} from "./constants";
import {diseasedZone} from "../../varibles/constants";
import {closeAllInfoWindow, createDialog} from "./Dialog";

/*
* Date: 22/09/2022
* Author: Vinh.Pham
* Description: Create diseased zone
*/

export function createDiseasedZones(args: ICreateDiseasedZone & IPropsMap) {
    const {id, elements, zones, visible = true} = args;
    const {color = '', zIndex = 0} = diseasedZone[id] || {};
    const old = elements.diseasedZones[id] || {};
    const map = visible ? args.map : null;

    const result = zones.reduce((rs: any, item: any) => {
        if (old[item.id]) {
            old[item.id].elements.forEach(sub => sub.setMap(map));
            rs[item.id] = old[item.id];
            delete old[item.id];
        } else {
            rs[item.id] = {
                elements: item.coordinates.map((sub: any) => {
                    const zone = new google.maps.Polygon({
                        paths: sub.map((coords: any) => coords.map(([lng, lat]: any) => ({lat, lng}))),
                        strokeColor: color,
                        strokeOpacity: 0.8,
                        fillColor: color,
                        fillOpacity: 0.35,
                        strokeWeight: 3,
                        zIndex,
                        map
                    });

                    zone.addListener('mousemove', (e: any) => {
                        const dialog = args.getElement(MAP_ELEMENT.DIALOG)
                        const {lock} = dialog[item.id] || {};
                        if (lock)
                            return;

                        closeAllInfoWindow(dialog || {}, Object.keys(dialog).reduce((rs: string[], key) => dialog[key].lock ? [...rs, key] : rs, []));
                        const diseasedZones = args.getElement(MAP_ELEMENT.DISEASED_ZONE);
                        const zones = getZonesByLocation(e, diseasedZones);
                        createDialog({
                            ...args,
                            contentType: MAP_ELEMENT.DISEASED_ZONE,
                            data: {id: item.id, zones: zones.length === 0 ? [{...item, diseasedType: id}] : zones},
                            position: e.latLng,
                        })
                    });

                    zone.addListener('mouseout', () => {
                        const dialog = args.getElement(MAP_ELEMENT.DIALOG)
                        const {lock} = dialog[item.id] || {};
                        if (lock)
                            return;
                        closeAllInfoWindow(dialog || {}, Object.keys(dialog).reduce((rs: string[], key) => dialog[key].lock ? [...rs, key] : rs, []));
                    });

                    zone.addListener('click', (e: any) => {
                        const diseasedZones = args.getElement(MAP_ELEMENT.DISEASED_ZONE);
                        const zones = getZonesByLocation(e, diseasedZones);
                        createDialog({
                            ...args,
                            contentType: MAP_ELEMENT.DISEASED_ZONE,
                            data: {id: item.id, zones: zones.length === 0 ? [{...item, diseasedType: id}] : zones},
                            position: e.latLng,
                            lock: true
                        })
                    });

                    return zone;
                }),
                info: item
            };
        }
        return rs;
    }, {});

    Object.keys(old).forEach(key => {
        const {elements} = old[key];
        clearElements(elements);
    })
    return {...elements.diseasedZones, [id]: result}
}

export function getZonesByLocation(e: any, diseasedZones: any) {

    const list: any = {};
    // Object.keys(diseasedZones).forEach(key => {
    //     const sub = diseasedZones[key];
    //     Object.keys(sub).forEach(subKey => {
    //         const {elements, info} = sub[subKey];
    //         const isContain = elements.some((item: google.maps.Polygon) => {
    //             const list = item.getPath().getArray().map(sub => [sub.lat(), sub.lng()]);
    //             return inside(position, list);
    //         });
    //
    //         if (isContain) {
    //             const {forskLink} = info;
    //             list[[forskLink, key].join('_')] = {...info, diseasedType: key}
    //         }
    //     })
    // });

    return Object.keys(list).reduce((rs: any, key) => [...rs, list[key]], []);
}

/*
* Date: 22/09/2022
* Author: Vinh.Pham
* Description: Create production area
*/

export function createProductionAreas(args: ICreateProductionArea & IPropsMap) {
    const {elements, zones, visible = true} = args;
    const map = visible ? args.map : null;
    return zones.reduce((rs: any, item: any) => {
        const {id, coordinates, color} = item;
        const path = coordinates.map(([lng, lat]: any) => ({lat, lng}));
        let element;
        if (elements.productionAreas[id]) {
            element = elements.productionAreas[id].element;
            element.setMap(map)

        } else {
            element = new google.maps.Polyline({
                map,
                path,
                geodesic: true,
                strokeColor: colorProductionArea[color],
                strokeOpacity: 0.7,
                strokeWeight: 7,
            });

            const {dialog} = elements;
            element.addListener('mousemove', (e: any) => {
                closeAllInfoWindow(dialog || {}, [...Object.keys(dialog).reduce((rs: string[], key) => dialog[key].lock ? [...rs, key] : rs, []), rs.id]);
                createDialog({
                    ...args,
                    contentType: MAP_ELEMENT.PRODUCTION_AREA,
                    data: item,
                    position: e.latLng,
                })
            });
            element.addListener('mouseout', () => {
                closeAllInfoWindow(dialog || {}, Object.keys(dialog).reduce((rs: string[], key) => dialog[key].lock ? [...rs, key] : rs, []));
            });
        }

        rs[id] = {element, info: item};

        return rs;
    }, {});
}