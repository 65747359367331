import React, {ReactNode} from 'react';
import createFastContext from "../createFastContext";
import {
    IActivityLog,
    ICombineTreatment,
    IFactory,
    ILocation,
    ISite,
    ITaskService,
    IUnit,
    IVessel,
    TOperation,
    TTaskTreatment
} from "../../util/varibles/interface";
import {IGetTanks, OpService} from "../../util/services/operation";
import {PoiService} from "../../util/services/poi";
import {debounce} from "debounce";
import {ACTION_TYPE, TASK_TYPE, taskTypes, treatmentType} from "../../util/varibles/constants";
import {VesselService} from "../../util/services/vessel";
import {useDispatch} from "react-redux";
import {planOpActions} from "../../pages/PlanOperationPage/reducer";
import {IGetActivityLog} from "../../pages/PlanOperationPage/saga";
import {IUpdateOp} from "../../pages/PlanOperationPage/constants";

export const SPLIT_KEY = '/'

export type TTasks = TTaskTreatment[]
    | ITaskService[]

export interface IUnitWhenEdit extends IUnit {
    isSorting?: boolean
    isLock?: boolean
    index?: number
    tasks?: TTasks
    total_weight_text?: string
}

interface IState {
    files: any[]
    count: number

    locations: {
        loading: boolean
        data: ILocation[]
    }

    vesselTasks: {
        loading: boolean
        data: ICombineTreatment[]
    }

    bodyGetRoundMap?: IGetTanks
    loadingRoundMap?: boolean

    site?: ISite
    units: { [id: string]: IUnitWhenEdit }

    startTime: number
    supportTasks: TTaskTreatment[]
    loadingSubmit?: boolean

    isCalculate?: boolean
    ops: TOperation[]
    operation?: TOperation
    vessel?: IVessel
    activity_log: IActivityLog[]

    factories: IFactory[],
    serviceTasks: any[]

    editMode?: ACTION_TYPE
    dragData?: {
        x: number
        y: number
        height: number
        width: number
        View?: ReactNode
        merge(index: number): void
        cancel(): void
    }

    onSave(params: IUpdateOp): void
}

const {
    Provider,
    useStore: useEditOp,
    setStore: setEditOp,
    getStore: getEditOp,
    callEvent: callEventEditOp
} = createFastContext<IState, {
    getLocations(): void
    getVesselTasks(vessel: IVessel): void
    getRoundMap(params?: Partial<IGetTanks>): void
    turnOnCalculate(): void
    getActivityLog(props: IGetActivityLog): void
    handeUpdateOp(value: Partial<TOperation>): void
}>({
    state: {
        files: [],
        count: 0,
        startTime: 0,
        supportTasks: [],
        units: {},
        locations: {
            loading: false,
            data: [],
        },
        vesselTasks: {
            loading: false,
            data: [],
        },
        ops: [],
        activity_log: [],
        factories: [],
        serviceTasks: [],
        onSave: () => null
    },
});

let control: AbortController;

const EditOperationProvider = ({children, ...args}: { children: ReactNode } & Partial<IState>) => {
    const dispatch = useDispatch();

    return <Provider
        props={args}
        event={{
            getLocations: ({set}) => {
                set(({locations}) => {
                    if (locations.loading || locations.data.length > 0)
                        return {locations}
                    PoiService.gets()
                        .then(rs => set({locations: {loading: false, data: rs}}))
                        .catch(error => {
                            console.log('Get locations failed', error);
                            set({locations: {loading: false, data: []}})
                        })
                    return {locations: {...locations, loading: true}}
                });
            },
            getRoundMap: (params, {get, set}) => {
                const {startTime, bodyGetRoundMap = {}, vessel, isCalculate} = get();
                const body = {
                    ...bodyGetRoundMap,
                    ...params,
                    vessel_id: vessel?.id,
                    start_time: startTime,
                } as IGetTanks;
                if (JSON.stringify(body) !== JSON.stringify(bodyGetRoundMap)) {
                    set({bodyGetRoundMap: body});
                    if (!isCalculate)
                        return;
                    debounce(() => set(() => {
                        if (control)
                            control.abort();
                        control = new AbortController();

                        OpService.tanks(body, control)
                            .then(({round_map, duration}) => {
                                set(({operation}) => ({
                                    operation: operation
                                        ? {...operation, round_map, duration}
                                        : operation,
                                    loadingRoundMap: false
                                }))
                            })
                            .catch(err => {
                                console.log(err)
                                set({loadingRoundMap: false})
                            })
                        return {loadingRoundMap: true}
                    }), 500)()
                }
            },
            getVesselTasks: (vessel, {set}) => {
                const {id} = vessel;
                const operation_sub_types = Array.from(vessel.operation_sub_types).sort((a: any, b: any) => a - b);
                const staticTasks = [
                    ...operation_sub_types.map((item: any) => ({
                        ...treatmentType[item],
                        id: [TASK_TYPE.TREATMENT, item].join(SPLIT_KEY),
                    })),
                    taskTypes[TASK_TYPE.MOVE],
                ]

                const listOfCheck = new Set(staticTasks.map(item => item.id));
                set(({vesselTasks}) => {
                    if (vesselTasks.loading || vesselTasks.data.length > 0)
                        return {vesselTasks}

                    VesselService.combineTreatment(id)
                        .then(rs => {
                            const validCombineTask = rs.reduce((list: any, item) => {
                                const {sub_types} = item;
                                const isInvalid = sub_types.some((sub: any) => !listOfCheck.has([sub.type, sub.treatment_type].join('/')));
                                const id = [TASK_TYPE.COMBINE, item.id].join('/');
                                if (!isInvalid)
                                    list.push({...item, id});

                                return list;
                            }, []);
                            set({
                                vesselTasks: {
                                    loading: false,
                                    data: [...validCombineTask, ...staticTasks]
                                }
                            })
                        })
                        .catch(error => {
                            console.log('Get combine treatment failed', error);
                            set({
                                vesselTasks: {
                                    loading: false,
                                    data: staticTasks
                                }
                            })
                        })
                    return {vesselTasks: {...vesselTasks, loading: true}}
                })
            },
            turnOnCalculate: ({set}) => set({isCalculate: true}),
            getActivityLog: ({source, properties}, {get, set}) => {
                set({loadingSubmit: true})
                const {editMode, onSave} = get();
                dispatch(planOpActions.getActivityLog({
                    source,
                    properties,
                    success: (ops) => {
                        set({loadingSubmit: false})
                        onSave(editMode === ACTION_TYPE.CREATE ? {add: ops} : {update: ops});
                    },
                    failure: () => {
                        set({loadingSubmit: false})
                    }
                }))
            },
            handeUpdateOp: (value: Partial<TOperation>, {set}) => {
                set(({operation}) => {
                    if (operation)
                        return {operation: {...operation || {}, ...value}}
                    return {operation}
                })
            }
        }}>
        {children}
    </Provider>
};

export {useEditOp, setEditOp, getEditOp, callEventEditOp, EditOperationProvider}
