import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../Icon";

interface IProps {
    index: number
}

const PointChange: React.FC<IProps> = ({index}) => {
    return <div className={styles.pointChange} data-index={index}>
        <div className={styles.pointView}>
            <Icon icon={ICON_NAME.ADD}/>
        </div>
    </div>;
};

export default PointChange;
