import React from 'react';
import List from 'rc-virtual-list';
import {useBiology} from "../../../../../contexts/PlanOperationContext/Biology";
import LoadingBox from "../../../../../components/LoadingBox";
import styles from "./style.module.scss";

interface IProps<S> {
    data: S[],
    child: {
        width?: number
        height: number
        View: React.FC<{ data: S }>
    }
    loading?: boolean
    rowClassName?: string
}

function Cards<S>({data, child, rowClassName = '', loading}: IProps<S>) {
    const [height] = useBiology(state => state.height);
    const [width] = useBiology(state => state.width);
    const widthItem = child.width || 320;
    const col = Math.max(1, Math.floor(Math.abs(width - 17) / widthItem));
    const total = data.length;
    const maxRow = Math.ceil(total / col);
    const list: { id: number, indexes: number[] }[] = [];
    const row = {
        className: ['grid pr-[10px]', rowClassName].join(' '),
        style: {
            height: `${child.height}px`,
            gridTemplateColumns: `repeat(${col}, 1fr)`
        }
    }
    if (maxRow) {
        for (let i = 0; i < maxRow; i++) {
            const id = i * col;
            const result: number[] = [];
            for (let j = 0; j < col; j++) {
                if (data[id + j]) {
                    result.push(id + j)
                }
            }
            list.push({id, indexes: result})
        }
        const { View} = child;

        return <>{height > 65 && <List data={list} height={height - 65} itemHeight={child.height} itemKey='id'>
            {(item) => <div key={`row-${item.id}`} className={[row.className, styles.row].join(' ')} style={row.style}>
                {item.indexes.map(i => {
                    if (data[i]) {
                        return <View key={i} data={data[i]}/>
                    }
                    return null
                })}
            </div>}
        </List>}</>
    }
    return <LoadingBox loading={loading} className={styles.loading}/>
}

export default React.memo(Cards, (prev, next) => JSON.stringify(prev.data) === JSON.stringify(next.data)
    && prev.loading === next.loading) as typeof Cards;
