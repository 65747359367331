import React, {Component} from 'react';
import styles from './style.module.scss';
import {SHORT_DATETIME_FORMAT, timeLate} from "../../../../util/varibles/constants";
import {durationTxt} from "../../../../util/varibles/global";
import Delay from "./Delay";
import {datetime} from "../../../../util/library/datetime";

interface IProps {
    data: any
    activity_log: any
    current_process: any
    lastPoint: any
}

class ActivityLog extends Component<IProps> {

    render() {
        const {index, est_start_time: startTime, real_start_time = 0, eta = 0, steps, time_late} = this.props.data;
        const {current_process, lastPoint, activity_log} = this.props;
        let state;
        let nextPoint = '';
        if (index <= current_process) {
            state = index === current_process && index < lastPoint;
            nextPoint = `Actual: ${datetime(real_start_time).format(SHORT_DATETIME_FORMAT)}`;
        } else if (eta)
            nextPoint = `Est: ${datetime(eta).format(SHORT_DATETIME_FORMAT)}`
        const finishTime = (index + 1) < lastPoint ? activity_log[index + 1].est_start_time : activity_log[lastPoint].est_start_time;
        const durationEst = new Date(finishTime).setSeconds(0, 0) - new Date(startTime).setSeconds(0, 0);
        const durationAct = (index + 1) < lastPoint
            ? new Date(activity_log[index + 1].real_start_time).setSeconds(0, 0) - new Date(real_start_time).setSeconds(0, 0) : 0
        const delayTime = real_start_time - startTime;
        const length = (steps || []).length - 1;

        return <div key={index} className={styles.step} data-is-current-step={state} data-is-last={index === lastPoint}>
            <div className={styles.duration}>
                {index < current_process && <p className='font-w4'>
                    {durationTxt(durationAct, '0m')}
                </p>}
                <p>{durationTxt(durationEst)}</p>
            </div>
            <div className={styles.info}>
                <div className={styles.name}>
                    {(steps || []).map((item: any, i: number) => <span key={i}>{item?.name}{i !== length ? ', ' : ''}</span>)}
                    <Delay time={time_late} visible={time_late}/>
                </div>
                <p data-status={delayTime <= timeLate}>
                    {nextPoint}
                    {index <= current_process && Math.abs(delayTime) > timeLate ?
                        <i className='ml-1'>(diff. {Math.round(Math.abs(delayTime) / 60000)}m)</i> : ''}
                </p>
                <p>Planned: {datetime(startTime).format(SHORT_DATETIME_FORMAT)}</p>
            </div>
        </div>
    }
}

export default ActivityLog;
