import React from 'react';
import styles from "./style.module.scss";

interface IProps {
    column: number
    name: string
    index: number
}

const RoundName: React.FC<IProps> = ({column, name, index}) => {


    return <div className={styles.roundName} data-first={column % (index + 1) === column}>
        <span>Round {name}</span>
    </div>;
};

export default RoundName;
