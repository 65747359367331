import React, {Component} from 'react';
import styles from "./style.module.scss";
import {Select} from "antd";
import {searchOpFetch, searchUserFetch} from "../../../../../util/services/chat";
import {getSubInfoChatOp} from "../../../../../components/Chat/constants";
import {CHAT_TYPE, OP_STATUS} from "../../../../../util/varibles/constants";
import LoadingBox from "../../../../../components/LoadingBox";
import Id from "../../../../../components/Operation/Id";
import Logo from "../../../../../components/Operation/Logo";

interface IProps {
    values: any
    chatType: CHAT_TYPE
    selectRef: React.Ref<any>

    onChange(values: any): void
}

class AddChat extends Component<IProps> {
    state = {
        loading: false,
        options: [],
        users: [],
        isMore: false,
        key: ''
    }
    timeout: any;

    handleSelectUser = (isSelect: boolean, value: any, event: any) => {
        const {values} = this.props;
        const {children} = event;
        this.props.onChange(isSelect
            ? [...values, {id: value, name: children}]
            : values.filter((item: any) => item.id !== value));
    }

    handleSelectOp = (isSelect: boolean, value: any, event: any) => {
        const {values} = this.props;
        const {op} = event;
        this.props.onChange(isSelect ? [...values, {id: value, op}] : values.filter((item: any) => item.id !== value));

        if (isSelect) {
            setTimeout(() => {
                const el = document.getElementById('input-' + value);
                if (el)
                    el.focus()
            }, 100)
        }
    }

    handleSearchUser = (key: string) => {
        const value = key.trim();
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        if (value.length < 2) {
            this.setState({loading: false, options: []});
            return;
        }

        this.setState({key: value});
        this.timeout = setTimeout(() => {
            this.setState({loading: true, isMore: false});
            new Promise((resolve) => {
                resolve(searchUserFetch({key: value, index: 1}));
            }).then((rs: any) => {
                const {users, next} = rs;
                this.setState({
                    loading: false,
                    options: users.map((item: any) => <Select.Option key={item.id} value={item.id}>
                        {item.username}
                    </Select.Option>),
                    isMore: next
                });
            }).catch(() => {
                this.setState({loading: false});
            })
        }, 250)
    }

    handleSearchOp = (key: string) => {
        const value = key.trim();
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        if (value.length < 2) {
            this.setState({loading: false, options: []});
            return;
        }

        this.setState({key: value});
        this.timeout = setTimeout(() => {
            this.setState({loading: true, isMore: false});
            new Promise((resolve) => {
                resolve(searchOpFetch({key: value, index: 1}));
            }).then((rs: any) => {
                const {operations, next} = rs;
                this.setState({
                    loading: false,
                    options: operations.map((item: any) => {
                        const {operation_type, name, id} = item;
                        return <Select.Option key={id} value={id} op={item}>
                            <div className={styles['option-op']}>
                                <Logo type={operation_type} status={OP_STATUS.NEW}/>
                                <Id operation={{operation_code: name}} status={OP_STATUS.NEW}/>
                                <div>{getSubInfoChatOp(item)}</div>
                            </div>
                        </Select.Option>
                    }),
                    isMore: next
                });
            }).catch(() => {
                this.setState({loading: false});
            })
        }, 250)
    }

    renderSelect = () => {
        const {options, loading} = this.state;
        const {values, chatType, selectRef} = this.props;
        const props: any = {}
        if (chatType === CHAT_TYPE.USER) {
            props.mode = 'multiple';
            props.className = 'w-full select-mode-tag'
            props.onSearch = this.handleSearchUser;
            props.placeholder = 'Choose people to join the chat';
            props.onSelect = (value: any, event: any) => this.handleSelectUser(true, value, event);
            props.onDeselect = (value: any, event: any) => this.handleSelectUser(false, value, event)
        } else {
            props.className = 'w-full select-single'
            props.onSearch = this.handleSearchOp;
            props.placeholder = 'Choose operation';
            props.onSelect = (value: any, event: any) => this.handleSelectOp(true, value, event);
            props.onDeselect = (value: any, event: any) => this.handleSelectOp(false, value, event);
        }

        return <Select
            {...props}
            value={values.map((item: any) => item.id)}
            ref={selectRef}
            showSearch
            filterOption={false}
            popupMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            notFoundContent={<LoadingBox loading={loading}/>}
        >
            {options}
        </Select>
    }

    render() {

        return <div className={styles.wrapper}>
            {this.renderSelect()}
        </div>
    }
}

export default AddChat;