import React from 'react';
import styles from './style.module.scss';
import ButtonJumpCalendar from "../../../../components/Basic/ButtonJumpCalendar";
import {CALENDAR_ID} from "../../constants";
import stylesCalendar from '../style.module.scss';
import {datetime} from "../../../../util/library/datetime";

interface IProps {
}

const Header: React.FC<IProps> = () => {
    return <div className={styles.title}>
        <div className={styles['title-left']}>
            <span className="font-s1 font-w5 mr-3">
                {datetime().format('MMMM, YYYY')}
            </span>
            <ButtonJumpCalendar
                calenderId={CALENDAR_ID}
                getHeightWeek={() => {
                    const {offsetHeight}: any = document.getElementsByClassName(stylesCalendar.week)[0] || {offsetHeight: 0}
                    return offsetHeight;
                }}
            />
        </div>
    </div>;
};

export default Header;
