import React from 'react';
import styles from '../style.module.scss';
import {EL_DASHBOARD} from "../constants";
import Toggle from "./Toggle";
import {Collapse} from 'antd';
import ProductionArea from "./ProductionArea";
import Factory from "./Factory";
import ProductionAreas from "./ProductionAreas";
import DiseasedZones from "./DiseasedZones";
import Vessel from "./Vessel";
import ActiveSite from "./ActiveSite";
import {LeftOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {DashboardSetting, PreferenceSetting} from "../../../../util/varibles/userSetting";
import {loginActions} from "../../../LoginPage/reducer";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import {selectSetting} from "../../../../util/store/selectors";


interface IProps {
}

const RightControl: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const {
        [DashboardSetting.SECTION_FILTER]: sectionFilter,
        [PreferenceSetting.VIEW_MODE]: viewMode,
    } = useSelector(selectSetting([DashboardSetting.SECTION_FILTER, PreferenceSetting.VIEW_MODE]))

    if (viewMode !== VIEW_MODE.PLANNER)
        return null;

    return <>
        <div id={EL_DASHBOARD.RIGHT_CONTROL} className={styles.controlRight}>
            <div className={styles.header}>
                <div data-lev='title'>Map Filter</div>
            </div>
            <div className={styles.body}>
                <Collapse
                    onChange={(value: any) => dispatch(loginActions.updateUserSetting([{[DashboardSetting.SECTION_FILTER]: value}]))}
                    bordered={false}
                    defaultActiveKey={sectionFilter}
                    expandIconPosition='end'
                    expandIcon={({isActive}) => <LeftOutlined rotate={isActive ? -90 : 0}/>}
                    items={[
                        {
                            key: 1,
                            className: styles.section,
                            label: "Production areas, sites, factories",
                            children: <>
                                <ProductionArea/>
                                <Factory/>
                                <ActiveSite/>
                            </>
                        },
                        {
                            key: 2,
                            className: [styles.section, styles.grey].join(' '),
                            label: "Select site by production area",
                            children: <ProductionAreas/>,
                        },
                        {
                            key: 3,
                            className: styles.section,
                            label: "Disease zones",
                            children: <DiseasedZones/>,
                        },
                        {
                            key: 4,
                            className: [styles.section, styles.grey].join(' '),
                            label: "Vessels",
                            children: <Vessel/>,
                        }
                    ]}
                />
            </div>
        </div>
        <Toggle/>
    </>;
};

export default RightControl;
