import React from 'react';
import styles from "./style.module.scss";
import {getProdAreaName} from "../../../varibles/global";

interface IProps {
    data: any
}

const ProductionArea: React.FC<IProps> = ({data}) => {
    const style: any = {
        borderBottom: 'unset'
    };

    return <div className={styles['container-info']}>
        <div className={[styles['header'], 'text-center'].join(' ')} style={style}>{getProdAreaName(data)}</div>
    </div>;
};

export default ProductionArea;