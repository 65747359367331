import React, {Component} from 'react';
import styles from './style.module.scss';
import {IPropsChildren} from "../../../../util/varibles/interface";

class WrapperContent extends Component<IPropsChildren> {
    render() {
        return <div className={styles.wrapper}>
            <div className={styles.logo}/>
            <div className={styles.content}>
                {this.props.children}
            </div>
        </div>;
    }
}

export default WrapperContent;
