import React from "react";
import {Modal} from "antd";
import {OP_STATUS} from "../../../util/varibles/constants";
import {changeLoadingOp, getElById, showErrorResponse} from "../../../util/varibles/global";
import {updateStateOpOfVesselSideFetch, updateStatusOpOfVesselSideFetch} from "../../../util/services/vessel-side";
import {IOperation} from "../../../util/varibles/interface";
import {Dispatch} from "@reduxjs/toolkit";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {overviewActions} from "../reducer";
import {loginActions} from "../../LoginPage/reducer";

export const handleCancelOp = (data: IOperation, dispatch: Dispatch) => {
    const {id, tenant_id, operation_code} = data.operation;

    const params = {
        operation_id: id,
        tenant_id,
        status: OP_STATUS.CANCELED
    }

    const modal = Modal.confirm({
        icon: null,
        content: <div className='font-w4 font-s16'>Are you sure you want to cancel this operation?</div>,
        maskClosable: true,
        centered: true,
        okText: 'Confirm',
        width: 450,
        onOk: () => {
            modal.destroy();
            const el = getElById(id + '-loading');
            changeLoadingOp(el, true);
            new Promise((resolve) => resolve(updateStatusOpOfVesselSideFetch(params)))
                .then(rs => {
                    notify.success(NotifyCode.S8)([`#${operation_code}`])
                    changeLoadingOp(el, false);
                    afterChangeState(data, rs, dispatch);
                })
                .catch((error) => {
                    changeLoadingOp(el, false);
                    showErrorResponse(`#${operation_code} canceled failed`, error);
                })
        },
        okButtonProps: {
            danger: true,
            className: 'font-w4 bt-danger'
        },
        cancelButtonProps: {
            className: 'font-w4 bt-default'
        }
    })
}

export const handleApproveOp = (data: IOperation, dispatch: Dispatch) => {
    const {id, tenant_id = '', operation_code} = data.operation;
    const params = {
        operation_id: id,
        tenant_id,
        status: OP_STATUS.APPROVED
    }
    const el = getElById(id + '-loading');
    changeLoadingOp(el, true);
    new Promise((resolve) => resolve(updateStatusOpOfVesselSideFetch(params)))
        .then((rs) => {
            notify.success(NotifyCode.S7)([`#${operation_code}`])
            changeLoadingOp(el, false);
            afterChangeState(data, rs, dispatch);
        })
        .catch(error => {
            showErrorResponse(`#${operation_code} approved failed`, error)
            changeLoadingOp(el, false);
        })
}

export const handleStartOp = (data: IOperation, dispatch: Dispatch) => {
    const {id, tenant_id = '', operation_code, checklists, vessel_id} = data.operation;
    const el = getElById(id + '-loading');
    const body = {
        operation_id: id,
        tenant_id,
        vessel_id,
        time: Date.now(),
        checklists
    };
    changeLoadingOp(el, true);
    new Promise(resolve => resolve(updateStateOpOfVesselSideFetch(body)))
        .then((rs: any) => {
            notify.success(NotifyCode.S6)([`#${operation_code}`])
            changeLoadingOp(el, false);
            afterChangeState(data, rs, dispatch);
        })
        .catch((error) => {
            console.log(error);
            showErrorResponse(`#${operation_code} started failed`, error)
            changeLoadingOp(el, false);
        })
}


export const afterChangeState = (data: IOperation, rs: any, dispatch: Dispatch) => {
    const {activity_log} = rs;
    const {status, current_process, checklists} = rs.operation;
    const ops = [{
        ...data,
        operation: {...data.operation, status, current_process, checklists},
        activity_log
    }]

    dispatch(overviewActions.updateOps(ops));
    if (status === OP_STATUS.CANCELED) {
        dispatch(loginActions.deleteOpOfVessel([data.operation.id]));
    } else {
        dispatch(loginActions.updateOpsOfVessel(ops));
    }

}
