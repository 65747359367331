import React, {useEffect, useState} from 'react';
import styles from "../style.module.scss";
import {getPlan} from "../../../../../contexts/PlanOperationContext/Plan";
import {useDispatch} from "react-redux";
import {planOpActions} from "../../../reducer";

interface IProps {
    calendarWidth: number
}

const LazyLoading: React.FC<IProps> = (props) => {
    const get = getPlan();
    const style = {width: `${props.calendarWidth}%`};
    const [loadMore, setLoadMore] = useState<number>(0);
    const dispatch = useDispatch()

    useEffect(() => {
        const {calendarRef} = get();
        const el = calendarRef ? calendarRef() : undefined;
        const action = () => setLoadMore(0);
        const handleWheel = (e: WheelEvent) => {
            const {deltaY} = e;

            setLoadMore(prev => {
                if (prev) return prev;
                const {scrollTop, scrollHeight, offsetHeight} = el as HTMLElement;
                const isScrolledToTop = scrollTop + deltaY <= 0;
                const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;
                if (isScrolledToTop && deltaY < 0) {
                    setTimeout(() => dispatch(planOpActions.lazyLoadingRequest({key: 'beforeCount', action})), 100)
                    return 1
                } else if (isScrolledToBottom && deltaY > 0) {
                    setTimeout(() => dispatch(planOpActions.lazyLoadingRequest({key: 'afterCount', action})), 100)
                    return 2;
                } else if (prev !== 0)
                    return 0;

                return prev;
            })
        }

        if (el)
            el.addEventListener('wheel', handleWheel)

        return () => {
            if (el)
                el.removeEventListener('wheel', handleWheel)
        }
    }, [dispatch, get]);

    return <>
        <div className={styles['loading-top']} data-is-show={loadMore === 1} style={style}>
            <div className={styles['container-loading']}>
                <div className=' spinner-border text-secondary' role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div className={styles['loading-bottom']} data-is-show={loadMore === 2} style={style}>
            <div className={styles['container-loading']}>
                <div className=' spinner-border text-secondary' role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </>;
};

export default LazyLoading;
