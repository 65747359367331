import React from 'react';
import styles from "./style.module.scss";

const OpInfo: React.FC = () => {
    return <div className={styles.line} data-lev='header'>
        <div>Site, Unit</div>
        <div>Harvest ID</div>
        <div className='text-center'>Amount</div>
        <div className='text-center'>Total weight</div>
        <div>Mortality risk</div>
        <div>Factory</div>
        <div>Delivery method</div>
    </div>;
};

export default OpInfo;
