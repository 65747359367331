import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {HEIGHT_PER_DAY} from "../Operations/constants";
import {checkLimit, showErrorResponse} from "../../../../../util/varibles/global";
import DragLine from "./DragLine";
import {EL_RULE, VESSEL_COLOR} from "../constants";
import {ACTION_TYPE} from "../../../../../util/varibles/constants";
import {IAvailableTime, IVessel} from '../../../../../util/varibles/interface';
import {openPopup} from "../../../../../components/Popup/Component/WrapperPopup";
import PopupAvailableTime from "../../../Popup/AvailableTime";
import Loading from "../Loading";
import {SpotService} from "../../../../../util/services/spot";
import {useDispatch} from "react-redux";
import {planOpActions} from "../../../reducer";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import modal from "../../../../../components/Popup/ModalCustom";
import {callEventPlan, usePlan} from "../../../../../contexts/PlanOperationContext/Plan";

interface IProps {
    data: IAvailableTime
    finishTime: number
    vesselIndex: number
    vessel: IVessel
    width: number
}

const AvailableTime: React.FC<IProps> = (props) => {
    const [time, setTime] = useState({start_time: 0, finish_time: 0});
    const [loading, setLoading] = useState(false);
    const {data, finishTime, vesselIndex, vessel, width} = props;
    const [startPoint] = usePlan(state => state.startPoint);
    const {setTarget} = callEventPlan();
    const dispatch = useDispatch();

    useEffect(() => {
        const {start_time, finish_time} = data;
        setTime({start_time, finish_time})
    }, [data]);

    const edit = () => {
        const el = openPopup(<PopupAvailableTime
            visible={true}
            editMode={ACTION_TYPE.EDIT}
            availableTime={data}
            vessel={vessel}
            onClose={() => el.remove()}
        />)
    }

    const remove = () => {
        if (loading)
            return;
        modal.confirm({
            content: 'Are you sure you want to delete this available time?',
            ok: {
                click: () => {
                    setLoading(true);
                    SpotService.deleteAvailableTime({id, vessel_id})
                        .then(() => {
                            setLoading(false);
                            dispatch(planOpActions.deleteAvailableTime({id, vessel_id}));
                            notify.success(NotifyCode.S5)(['Available time'])
                        })
                        .catch(error => {
                            setLoading(false);
                            showErrorResponse('Delete failed', error);
                        })
                }
            }
        })

    }

    const onClick = (e: any) => {
        setTarget({type: EL_RULE.AVAILABLE_TIME, availableTime: data, vessel, time: Date.now(), edit, remove}, e)
    }

    const {id, vessel_id} = data;
    const {isOwn = false, permission} = vessel;

    const propsLine = {
        data,
        vessel,
        setTime,
        setLoading
    }

    const end = checkLimit(0, finishTime, time.finish_time);

    const style = {
        width: width + '%',
        maxHeight: `${(end - time.start_time) / 86400000 * HEIGHT_PER_DAY}px`,
        top: `${(time.start_time - startPoint) / 86400000 * 120}px`,
        left: `${width * vesselIndex}%`,
        '--background-color': VESSEL_COLOR[(vesselIndex + 1) % VESSEL_COLOR.length]
    }

    return <div
        className={styles.available_time}
        data-loading={loading}
        style={style}
        data-vessel-id={vessel_id}
        data-available-time-id={id}
        data-is-rent={!isOwn && !permission}
        data-rule={EL_RULE.AVAILABLE_TIME}
        data-date={time.start_time}
        onMouseDown={onClick}
    >
        {(isOwn || permission) && <>
            <DragLine {...propsLine} name="start"/>
            <DragLine {...propsLine} name="finish"/>
        </>}
        <Loading loading={loading} isFull={false}/>
    </div>
};

export default AvailableTime;
