import React, {useState} from 'react';
// import AWS from "aws-sdk";
import {FULL_DATE_FORMAT, propContainer, propsEmpty} from "../../../util/varibles/constants";
import {Button, Col, DatePicker, Form, Input, Row, Upload} from "antd";
import {disabledDate, layout} from "../../PlanOperationPage/Popup/Vessels/constants";
import {datetime} from "../../../util/library/datetime";
import {UploadOutlined} from "@ant-design/icons";

interface IProps {
    values: any
    formRef: any

    onOk(formValues: any): void
}

const FixContract: React.FC<IProps> = (props) => {
    const [file, setFile] = useState();
    const handleOk = (values: any) => {
        const {file, time, note = '-'} = values;
        props.onOk({time, note, file})
    }

    const {RangePicker} = DatePicker;
    const {TextArea} = Input;
    const {start_time, finish_time, customer} = props.values;

    const propsFile = {
        fileList: file ? [file] : [],
        multiple: false,
        onRemove: () => {
            setFile(file);
        },
        beforeUpload: (value: any) => {
            setFile(value);
            return false;
        },
    };

    return <div>
        <Row>
            <Col span={7}>Tenant:</Col>
            <Col className='font-w5'>{customer.name}</Col>
        </Row>
        <Row>
            <Col span={7}>Request for period:</Col>
            <Col className='font-w5'>
                {datetime(start_time).format(FULL_DATE_FORMAT)} - {datetime(finish_time).format(FULL_DATE_FORMAT)}
            </Col>
        </Row>
        <Form {...{
            ...layout,
            className: 'mt-12',
            ref: props.formRef,
            name: 'fixed-contract',
            layout: 'vertical',
            onFinish: handleOk,
            initialValues: {time: [datetime(start_time).value, datetime(finish_time).value]}
        }}>
            <Form.Item
                label="Start time - Finish time"
                name="time"
                rules={[{required: true, message: 'Start time and finish time are required'}]}
            >
                <RangePicker
                    {...propContainer}
                    disabledDate={disabledDate}
                    format={FULL_DATE_FORMAT}
                    placeholder={['Start time', 'Finish time']}
                />
            </Form.Item>
            <Form.Item
                label="Note"
                name="note"
            >
                <TextArea {...propsEmpty} rows={2} placeholder='Type the note here' allowClear maxLength={150}/>
            </Form.Item>
        </Form>
        <Upload {...propsFile} >
            <Button className='mb-1'><UploadOutlined/> Attach file</Button>
        </Upload>
    </div>;
};

export default FixContract;
