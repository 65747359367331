import React from 'react';
import LeftControl from "./LeftControl";
import TopControl from "./TopControl";
import RightControl from "./RightControl";
import styles from '../style.module.scss';
import {EL_DASHBOARD} from "./constants";
import {ACTION} from "../../../util/varibles/permission";
import {useSelector} from "react-redux";
import {selectPermission} from "../../../util/store/selectors";

const Control: React.FC = () => {
    const permission = useSelector(selectPermission);

    return <div id={EL_DASHBOARD.CONTROL} className={styles.control}>
        {permission[ACTION.DASHBOARD.LEFT_CONTROL] && <LeftControl/>}
        {permission[ACTION.DASHBOARD.TOP_CONTROL] && <TopControl/>}
        {permission[ACTION.DASHBOARD.RIGHT_CONTROL] && <RightControl/>}
    </div>;
};

export default Control;
