import React, {Component} from 'react';
import {Col, Form, Input, Row, Select} from "antd";
import {initSensors} from "../../constants";

interface IProps {
    formRef: any
    devices: any
}

class GaugeComponent extends Component<IProps> {

    handleChangeDevice = (device: any) => {
        const sensorValue = this.props.formRef.current.getFieldValue('sensors');
        const sensors = JSON.parse(device).sensors;
        if (sensors.length === 0)
            this.props.formRef.current.setFieldsValue(initSensors)
        else if (sensorValue && sensorValue.length > 0) {
            const {name} = JSON.parse(sensorValue);
            const isExist = sensors.find((item: any) => item.name === name);
            if (!isExist)
                this.props.formRef.current.setFieldsValue(initSensors)
        }
    }

    handleChangeSensor = () => {
        const {sensors} = this.props.formRef.current.getFieldsValue();
        if (sensors.length > 0) {
            const {unit} = JSON.parse(sensors);
            this.props.formRef.current.setFieldsValue({yAxis: [{min: 0, max: null, format: unit}]})
        }
    }

    render() {
        const {devices = []} = this.props;
        const {Option} = Select;
        return <>
            <Form.Item label="Device" name="devices" required>
                <Select placeholder='Select device'>
                    {devices.map((item: any) =>
                        <Option key={item.device_id} value={JSON.stringify(item)}>{item.device_name}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item noStyle
                       shouldUpdate={(prevValues, currentValues) => prevValues.devices !== currentValues.devices}>
                {({getFieldValue}) => {
                    const device = getFieldValue('devices');
                    const sensors = device ? JSON.parse(device).sensors : [];
                    return <Form.Item label="Sensor" name="sensors" required>
                        <Select placeholder='Select sensor' onChange={this.handleChangeSensor}>
                            {sensors.map((item: any) =>
                                <Option key={item.name} value={JSON.stringify(item)}>{item.name}</Option>)}
                        </Select>
                    </Form.Item>
                }}
            </Form.Item>
            <Row gutter={24}>
                <Form.List name="yAxis">
                    {(fields) => fields.map(({key, ...field}, index) => {
                        const {yAxis = []} = this.props.formRef.current.getFieldsValue();
                        const {format} = yAxis[index] || {};
                        const labelMin = `Min value ${format.length > 0 ? `(${format})` : ''}`;
                        const labelMax = `Max value ${format.length > 0 ? `(${format})` : ''}`;
                        return <>
                            <Col span={12} key={key + '-min'}>
                                <Form.Item key={key + '_min'}  {...{
                                    ...field,
                                    required: true,
                                    label: labelMin,
                                    name: [field.name, 'min'],
                                    fieldKey: [key, 'min']
                                }}>
                                    <Input placeholder={labelMin}/>
                                </Form.Item>
                            </Col>
                            <Col span={12} key={key + '-max'}>
                                <Form.Item key={key + '_max'} {...{
                                    ...field,
                                    required: true,
                                    label: labelMax,
                                    name: [field.name, 'max'],
                                    fieldKey: [key, 'max']
                                }}>
                                    <Input placeholder={labelMax}/>
                                </Form.Item>
                            </Col>
                        </>
                    })}
                </Form.List>
            </Row>
        </>;
    }
}

export default GaugeComponent;
