import React, {useState} from 'react';
import {connect} from 'react-redux';
import styles from './style.module.scss'
import {Switch} from 'antd';
import {updateDensitySettingFetch} from "../../../../util/services/tenant";
import {showErrorResponse} from "../../../../util/varibles/global";
import {AppState} from "../../../../util/store/store";


const mapStateToProps = (state: AppState) => {
    const {enable_change_operation_status = false} = state.login.user.tenant;
    return {enable_change_operation_status}
};

interface IProps {
    enable_change_operation_status: boolean
}

const VesselCrewApp: React.FC<IProps> = ({enable_change_operation_status}) => {
    const [loading, setLoading] = useState(false);
    const onChange = async (value: any) => {
        setLoading(true)
        new Promise(resolve => resolve(updateDensitySettingFetch({enable_change_operation_status: value})))
            .then(() => setLoading(false))
            .catch(error => {
                setLoading(false)
                showErrorResponse('Update failed', error);
            })
    }

    return <div className={styles.header}>
        <div className={styles.title}>
            <div>
                Change status automatically
            </div>
            <div className={styles.switch}>
                <Switch loading={loading} defaultChecked={enable_change_operation_status} onChange={onChange}/>
            </div>
        </div>
        <div className={styles.description}>
            Whenever vessel arrive at site, factory, Ocean Planner vessel app will update these statuses
            automatically.
        </div>
    </div>
};


export default connect(mapStateToProps, {})(VesselCrewApp);
