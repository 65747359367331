import React, {useEffect} from 'react';
import styles from "./style.module.scss";
import {SHORT_MONTH_DATETIME_FORMAT} from "../../../../../util/varibles/constants";
import {checkLimit, convertNumberTimeToStringTime, durationTxt, getElById} from "../../../../../util/varibles/global";
import {datetime} from "../../../../../util/library/datetime";

interface IProps {
    data: any
    step: number
    duration: number
    totalTime: number

    onSelect(step: number): void
}

const ActivityLog: React.FC<IProps> = ({data, step, duration, totalTime, onSelect}) => {
    const time = data[step + 1].time - data[step].time;
    const list = [];
    for (let i = 0; i < data.length - 1; i++) {
        const {time: prevTime} = data[i];
        const nextStep = data[i + 1];
        if (!nextStep)
            continue;

        const {time = prevTime, state} = nextStep;
        const style = {width: ((time - prevTime) / totalTime * 100) + '%'};

        list.push(<div
            id={['delay', i].join('_')}
            key={i}
            className={styles['point']}
            style={style}
            data-state={state}
            data-selected={i === step}
            onClick={() => state === 1 ? onSelect(i) : null}
        />)
    }

    useEffect(() => {
        const el = getElById(['delay', step].join('_'));
        if (el) {
            const {offsetLeft, offsetWidth} = el;
            const durationEl = getElById('delay-duration');

            if (durationEl) {
                const {clientWidth} = durationEl;
                const {clientWidth: parentWidth = 0} = durationEl.parentElement || {};
                durationEl.style.left = checkLimit(0, parentWidth - clientWidth, offsetLeft + (offsetWidth / 2) - (clientWidth / 2)) + 'px';
            }
        }
    })

    return <div className={styles['way']}>
        <div id='delay-duration' className={styles['duration']}>
            <div>{durationTxt(time)}</div>
            {duration > 0 && <div>(+{convertNumberTimeToStringTime(duration)})</div>}
        </div>
        {list}
        <div className={styles['time']} data-time='start'>
            {datetime(data[0].time).format(SHORT_MONTH_DATETIME_FORMAT)}
        </div>
        <div className={styles['time']} data-time='end'>
            {datetime(data[data.length - 1].time).format(SHORT_MONTH_DATETIME_FORMAT)}
        </div>
    </div>;
};

export default ActivityLog;