import React, {createRef} from 'react';
import {ILiceInfo} from "../../util/varibles/interface";

interface IProps {
    lice?: ILiceInfo
    name?: string
    short?: string | boolean
    wrapperClass?: string
}

const UnitLice: React.FC<IProps> = ({lice = {}, wrapperClass}) => {
    const root = createRef<HTMLDivElement>()
    if (Object.keys(lice).length === 0)
        return null;

    const {stationary_lice, mobile_lice, female_lice} = lice;
    const stationary = Math.round((stationary_lice?.value || 0) * 100) / 100;
    const mobile = Math.round((mobile_lice?.value || 0) * 100) / 100;
    const female = Math.round((female_lice?.value || 0) * 100) / 100;
    const total_lice = stationary + mobile + female;

    return <div ref={root} className={['whitespace-nowrap', wrapperClass].join(' ')}>
        <span className='total_lice'>{Math.round(total_lice * 100) / 100}</span>
        <span className='ml-[3px]'>(</span>
        <span className='stationary_lice'>{stationary}</span>
        <span className='mr-1'>,</span>
        <span className='mobile_lice'>{mobile}</span>
        <span className='mr-1'>,</span>
        <span className='female_lice'>{female}</span>
        <span>)</span>
    </div>
};

export default UnitLice;
