import React from 'react';
import styles from "./style.module.scss";
import {timeLate} from "../../../util/varibles/constants";
import {NavLink} from "react-router-dom";
import {durationTxt} from "../../../util/varibles/global";
import Logo from "../../Operation/Logo";
import OperationStatus from "../../Operation/OperationStatus";
import Id from "../../Operation/Id";
import Underline from "./Underline";
import {IOperation, IPropsChildren} from "../../../util/varibles/interface";
import ActivitiesLogToggle from "../ActivitiesLog/ActivitiesLogToggle";

interface IProps extends IPropsChildren {
    data: IOperation
    icon: any
    step: number
    active: boolean
    isShowActivityLog: boolean

    changeShowActivityLog(): void

    extend(): void
}

const Header: React.FC<IProps> = (props) => {
    const {icon, step, active, isShowActivityLog, changeShowActivityLog, extend} = props;
    const {activity_log = [], est_start_time, operation} = props.data;
    const {status, tenant_id, id} = operation;
    let estStartTime, realStartTime;

    if (step > -1) {
        estStartTime = activity_log[step] ? activity_log[step].est_start_time : 0;
        realStartTime = (activity_log[step] ? activity_log[step].real_start_time : 0) || 0;
    } else {
        estStartTime = est_start_time || 0
        realStartTime = Date.now();
    }

    return <div className={styles.header} data-active={active} onClick={extend}>
        <div
            className={styles.title}
            data-operation-status={status}
            data-is-late={(estStartTime - realStartTime + timeLate) < 0 ? 'true' : 'false'}
        >
            <Logo status={status}>{icon}</Logo>
            <NavLink to={`/operation/${tenant_id}/${id}`}>
                <Id {...{operation, status}}/>
            </NavLink>
            <OperationStatus status={status}/>
            {props.children}
            {step >= 0 && <div className={styles['operation-delta-time']}>
                - {estStartTime - realStartTime + timeLate > 0 ? 'On time' : durationTxt(estStartTime - realStartTime) + ' behind schedule'}
            </div>}
            {active && <ActivitiesLogToggle
                isShow={isShowActivityLog}
                onClick={changeShowActivityLog}
            />}
        </div>
        <div className={styles.headerLine}><Underline/></div>
    </div>;
};

export default Header;