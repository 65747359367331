import React from 'react';
import {INotification} from "../../../util/varibles/interface";
import {Drawer} from "antd";
import Header from "../../Popup/Component/Header";
import LoadingBox from "../../LoadingBox";
import {propsModal} from "../../../util/varibles/constants";
import ViewNotification from "./ViewNotification";
import styles from './style.module.scss';

interface IProps {
    source: INotification[]
    more: boolean
    loading: boolean
    open: boolean

    loadData(id?: string): void

    onClose(): void

    onClick(data: INotification): void
}

const PopupNotification: React.FC<IProps> = ({source, more, loading, open, loadData, onClose, onClick}) => {

    const handleScroll = (e: any) => {
        if (loading || !more)
            return;

        const {deltaY} = e;
        const {scrollTop, scrollHeight, offsetHeight}: any = e.target;
        const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;
        if (isScrolledToBottom && deltaY > 0) {
            loadData(source[source.length - 1]?.id)
        }
    }

    return <Drawer
        {...propsModal}
        rootClassName={styles.popup}
        title={<Header title='Notification' onClose={onClose}/>}
        open={open}
        mask={false}
        onClose={onClose}
    >
        <div className={styles.notifications} onWheel={handleScroll}>
            {source.map(item => <ViewNotification key={item.timestamp} data={item} onClick={onClick}/>)}
            {(loading || source.length === 0) && <LoadingBox className={styles.loading} loading={loading}/>}
        </div>
    </Drawer>;
};

export default PopupNotification;
