import {all, call, put, takeEvery} from "redux-saga/effects";
import {getSpotsFetch} from "../../util/services/spot";
import {vesselManagementActions} from "./reducer";

function* initialWatcher() {
    try {
        const {vessels: ownVessels, vessels_customer: hiredVessels} = yield call(getSpotsFetch);
        yield put(vesselManagementActions.getListSuccess({ownVessels, hiredVessels}))
    } catch (error) {
        yield put(vesselManagementActions.getListFailure())
    }
}

export default function* vesselManagementSaga() {
    yield all([
        takeEvery(vesselManagementActions.getListRequest.type, initialWatcher),
    ])
}
