import React, {useEffect, useRef, useState} from 'react';
import styles from "./style.module.scss";
import stylesContainer from "../../style.module.scss";
import {notification, Popconfirm, TimePicker} from "antd";
import {datetime} from "../../../../../../../util/library/datetime";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import {propContainer, propsConfirm} from "../../../../../../../util/varibles/constants";

interface IProps {
    value: any
    isDisable: boolean

    onChange(value: any): void

    onDelete(): void
}

const Time: React.FC<IProps> = (props) => {
    const [focus, setFocus] = useState(false);
    const [arrivalTime, setArrivalTime]: any | null = useState(undefined);
    const [isDelete, setIsDelete] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const {value, isDisable} = props;

    useEffect(() => {
        if (value) {
            const hour = Math.floor(value / 60);
            const minute = Math.floor(value - (hour * 60));
            setArrivalTime(datetime().set({hour, minute}).value)
        } else {
            setArrivalTime(undefined)
        }
    }, [value]);

    const handleFocus = () => {
        setFocus(true);
        setTimeout(() => {
            if (inputRef.current)
                inputRef.current.focus()
        }, 100)
    }

    const handleChange = (value: any) => {
        if (value == null) {
            notification.error({message: 'Error', description: 'Time is required'})
            return;
        }

        const time = datetime(value);
        const hour = time.hour;
        const minus = time.minute;
        props.onChange((hour * 60) + minus)
        setTimeout(() => {
            if (inputRef.current)
                inputRef.current.blur()
        }, 100)
    }

    const handleDelete = () => {
        props.onDelete();
        setIsDelete(false);
    }

    if (isDisable)
        return null;

    return <div className={styles.wrapper}>
        {(arrivalTime || focus)
            ? <TimePicker
                {...propContainer}
                showNow={false}
                ref={inputRef}
                value={arrivalTime}
                open={focus}
                variant={"borderless"}
                className={stylesContainer.picker}
                popupClassName={stylesContainer['popup-picker']}
                minuteStep={5}
                format={'HH:mm'}
                placeholder={''}
                onChange={handleChange}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
            />
            : <div className='bt-ghost' data-icon={true} onClick={handleFocus}>
                <Icon icon={ICON_NAME.EDIT} size='large'/>
            </div>}
        {arrivalTime && <Popconfirm
            {...propsConfirm}
            title="Are you sure to delete this setting?"
            open={isDelete}
            onConfirm={handleDelete}
            onCancel={() => setIsDelete(false)}
        >
            <div className='bt-ghost' data-icon={true} data-danger='true' data-show={isDelete} onClick={() => setIsDelete(true)}>
                <Icon icon={ICON_NAME.DELETE} size='large'/>
            </div>
        </Popconfirm>}
    </div>;
};


export default Time;
