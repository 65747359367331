export interface VesselManagementState {
    ownVessels: any[]
    hiredVessels: any[]
    loadingList: boolean
    loading: {
        approve: boolean
    },
}

export const initialState: VesselManagementState = {
    ownVessels: [],
    hiredVessels: [],
    loadingList: false,
    loading: {
        approve: false
    },
}

