import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ACTION_TYPE} from "../../../../../../util/varibles/constants";
import {checkRoute2} from "../../../../util";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";
import styles from './style.module.scss';
import Operations from "./Operations";
import Footer from "../../Footer";
import {createTransport, createTransports, getSubData} from "./constants";
import {IUnit} from "../../../../../../util/varibles/interface";
import Units from "../../_Component/Units";
import {callEventEditOp, getEditOp, useEditOp} from "../../../../../../contexts/EditOperationContext";
import {selectPlan, selectTenantId} from "../../../../../../util/store/selectors";
import {cloneObj} from "../../../../../../util/varibles/global";

interface IProps {
    popupData: any
    isFull: boolean
    loading?: boolean

    onClose(): void;
}

const Transport: React.FC<IProps> = ({popupData, isFull, loading, onClose}) => {
    const [loadingSubmit, set] = useEditOp(state => state.loadingSubmit);
    const userTenantId = useSelector(selectTenantId)
    const operations = useSelector(selectPlan.ops);
    const routeIds = useSelector(selectPlan.routeIds);
    const get = getEditOp();
    const {getActivityLog} = callEventEditOp()

    useEffect(() => {
        const generateState = () => {
            const {editMode} = get()
            const {operation, vessel, store} = popupData.values;

            if (editMode === ACTION_TYPE.CREATE) {
                const {sub_operations} = operation
                const {operations} = createTransports({
                    data: sub_operations,
                    store,
                    vessel,
                    isFull,
                    properties: {tenant_id: userTenantId}
                });

                return {
                    isCalculate: true,
                    loadingSubmit: false,
                    ops: operations,
                    units: store,
                    count: operations.length
                };
            } else {
                let error_detail: any = {};
                if (operation.total_weight > vessel.capacity) {
                    error_detail.weight = notify[NotifyCode.E1]()
                }
                const operations = cloneObj([{...operation, error_detail}])
                return {
                    isCalculate: false,
                    loadingSubmit: false,
                    ops: operations,
                    units: store,
                    count: operations.length
                };
            }
        }
        set(generateState())
    }, [get, set, popupData, isFull, userTenantId]);

    const addOperation = (unit: IUnit) => {
        const {ops, count, vessel} = get();
        if (!vessel)
            return;

        const {operation} = createTransport({
            vessel,
            unit,
            count,
            properties: {tenant_id: userTenantId}
        })

        ops.push(operation)
        set({ops, count: count + 1})
    }

    const handleSubmit = () => {
        const {isCalculate, ops: dataNew} = get();
        if (isCalculate) {
            const isError = checkError();
            const {vessel, activity_log, operation} = get()
            if (isError || !vessel) return;

            const {id: opId = 0} = operation || {};
            const properties: any = {[opId]: {start: activity_log[0].est_start_time}};
            const operations = dataNew.map((item: any, index: number) => {
                const {sub_operations, sub_destinations} = getSubData(item);
                properties[index] = {
                    ...properties[index] || {},
                    start_place: dataNew[index - 1]
                        ? {start_place_id: dataNew[index - 1].factory_id, start_place_field: 'factory',}
                        : undefined
                }

                return {
                    operation: {...item, sub_operations, sub_destinations,},
                    vessel
                }
            })
            getActivityLog({source: operations, properties})
        } else {
            const operation = dataNew[0];
            const {id} = dataNew[0];
            const {onSave} = get();
            onSave({update: {[id]: {...operations[id], operation}}, isCheck: false});
        }
    };

    const checkError = () => {
        const {ops} = get();
        let isError = false;
        const operationsAfterCheck = ops.reduce((list: any, item: any) => {
            let {
                sub_destinations,
                error_detail = {},
                source_id,
                source_name,
                destination_id,
                destination_name
            } = item;
            if (Object.keys(error_detail).length > 0) {
                isError = true
                return [...list, item];
            }

            let error = sub_destinations.some((sub: any) => sub.fish_amount === 0 || sub.total_weight === 0)
            if (error) {
                isError = true;
                error_detail.fish_amount = notify[NotifyCode.E37](['Fish amount, total weight']);
            }

            if (destination_id) {
                error = sub_destinations.some((sub: any) => !sub.delivery_id)
                if (error) {
                    isError = true
                    error_detail.delivery_id = 'Deliv. unit is required field';
                }

                const validRoute = checkRoute2({id: source_id, name: source_name}, {
                    id: destination_id,
                    name: destination_name
                }, routeIds);
                if (validRoute.error === 2) {
                    isError = true;
                    error_detail.route = [{source_id: source_id, destination_id, message: validRoute.message}];
                }
            } else {
                isError = true
                error_detail.destination_id = 'Deliv. site is required field';
            }

            return [...list, {...item, error_detail}];
        }, []);
        if (isError)
            set({ops: operationsAfterCheck})
        return isError;
    }

    return <div className={styles['container-body']}>
        <Units {...{
            addOperation,
            nameRow: (data: any) => <span>{[data.site_name, data.unit_id].join(', ')}</span>
        }}>
            <span>Smolt site</span>
        </Units>
        <div className={styles['title-table']}>
            Delivery info.
        </div>
        <Operations/>
        <Footer {...{
            loading: loadingSubmit || loading,
            onClose,
            handleSubmit,
        }}/>
    </div>
};

export default Transport;

