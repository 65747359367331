import React, {ReactNode} from 'react';
import {TASK_TYPE, taskTypes, treatmentType} from "../../../../../../../util/varibles/constants";
import Treatment from "./Treatment";
import styles from './style.module.scss';
import {ICombineTreatment, TTaskTreatment} from "../../../../../../../util/varibles/interface";
import MoveToUnit from "./MoveToUnit";
import {cloneObj} from "../../../../../../../util/varibles/global";
import {IUnitWhenEdit} from "../../../../../../../contexts/EditOperationContext";

interface ISubProps {
    data: TTaskTreatment
    unit: IUnitWhenEdit

    onUpdate(change: any): void
}

const view: { [id: string]: (props: ISubProps) => ReactNode } = {
    [TASK_TYPE.TREATMENT]: (props: ISubProps) => <Treatment  {...props}/>,
    [TASK_TYPE.MOVE]: (props: ISubProps) => <MoveToUnit {...props}/>,
}

export interface IPropsTask {
    data: TTaskTreatment
    unit: IUnitWhenEdit

    onUpdate(change: any): void
}

interface IProps extends IPropsTask {
    data: ICombineTreatment
}

const Combine: React.FC<IProps> = ({data, unit, onUpdate}) => {
    const {sub_tasks = []} = data;

    const handleChange = (index: number, value: any) => {
        const newTasks = cloneObj(sub_tasks);
        newTasks?.splice(index, 1, value);
        onUpdate({sub_tasks: newTasks});
    }

    return <div className={styles['container-combine']}>
        {sub_tasks.map((item, index) => {
            const {type, treatment_type = '', error} = item;
            const node = view[type || ''];
            const {name = ''} = treatmentType[treatment_type] || taskTypes[type || ''] || {};
            let isError = false, styleError = {height: '0'}
            if (Object.keys(error).length > 0) {
                styleError.height = (Object.keys(error).length * 20 + 3) + 'px';
                isError = true;
            }
            const common = {
                data: item,
                unit,
                onUpdate: (value: any) => handleChange(index, {...item, ...value}),
            }

            return [
                <div className={styles['combine-line']} key={index} data-error={isError}>
                    <div data-lev='task-name'>{name}</div>
                    {node ? node(common) : null}
                </div>,
                <div className={styles['task-error']} key={index + 'error'} style={styleError}>
                    {Object.keys(error).map(key =>
                        <div className={styles['error-line']} key={key}
                             dangerouslySetInnerHTML={{__html: error[key]}}/>)}
                </div>
            ]
        })}
    </div>;
};

export default Combine;