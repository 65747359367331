import React from 'react';
import styles from "../../../../components/Operation/style.module.scss";
import Id from "../../../../components/Operation/Id";
import OperationStatus from "../../../../components/Operation/OperationStatus";
import Certain from "../../../../components/Operation/Certain";
import NameSpot from "../../../../components/Operation/NameSpot";
import Duration from "../../../../components/Operation/Duration";
import {eventType, OP_STATUS, OPERATION_TYPE} from "../../../../util/varibles/constants";
import Delay from "../../../../components/Operation/Delay";
import NoteDetail from "../../../../components/Operation/NoteDetail";
import {IOperation} from "../../../../util/varibles/interface";
import Logo from "../../../../components/Operation/Logo";
import Locations from "../../../../components/Operation/Locations";
import Weather from "../../../../components/Operation/Weather";

interface IProps {
    data: IOperation
    isOwn: boolean
    status: OP_STATUS
}

const EventView: React.FC<IProps> = ({data, isOwn, status}) => {
    const {operation, activity_log} = data;
    const {name = ''} = eventType[operation.event_type] || {};

    return <>
        <div className={styles.title}>
            <Logo
                type={OPERATION_TYPE.EVENT}
                status={status}
                dataAttribute={{'data-event-type': operation.event_type}}
            />
            <Id {...{operation, status}}/>
            <OperationStatus {...{status}}/>
            <Certain {...{operation, status}}/>
            <Locations locations={operation.pois || []} separate={', '}/>
            <div className={styles.right}>
                <Weather {...{visible: true, operation}}/>
                <Delay visible={true} operation={operation}/>
                {!isOwn && <NameSpot {...{visible: true, operation}}/>}
            </div>
        </div>
        <div className={styles.section}>
            {name}
            <Duration {...{visible: true, status, activity_log}}/>
        </div>
        <NoteDetail operation={operation} visible={true}/>
    </>;
};

export default EventView;
