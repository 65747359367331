import React from 'react';
import styles from "./style.module.scss";
import {CERTAIN_TYPE, certainType, OP_STATUS, statusHideCertain} from "../../util/varibles/constants";
import {useSelector} from "react-redux";
import {selectOneSetting} from "../../util/store/selectors";
import {PreferenceSetting} from "../../util/varibles/userSetting";
import {TOperation} from "../../util/varibles/interface";

interface IProps {
    visible?: boolean
    operation: TOperation
    status: OP_STATUS
}

const Certain: React.FC<IProps> = (props) => {
    const isCertainty = useSelector(selectOneSetting(PreferenceSetting.SHOW_CERTAINTY));
    const {certain_mode = CERTAIN_TYPE.NONE} = props.operation;
    const {status, visible = true} = props;

    if (!visible || !isCertainty || statusHideCertain.indexOf(status) !== -1)
        return null;

    const {icon, name, color, fontColor} = certainType[certain_mode] || {
        icon: null,
        name: '',
        color: '',
        fontColor: ''
    };
    const style: any = {'--color': fontColor, background: color}

    return <div
        ref={(node) => {
            node?.style.setProperty("color", fontColor, "important")
        }}
        className={styles.certain}
        data-icon={icon}
        title={name}
        style={style}
    >
        {name}
    </div>
};

export default Certain;