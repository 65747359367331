import {initialState} from "./constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IChecklist} from "../../util/varibles/interface";

const checklistSlice = createSlice({
    name: 'checklist',
    initialState,
    reducers: {
        getListRequest: (state,) => {
            state.loading = {...state.loading, checklists: true};
        },
        getListSuccess: (state, action) => {
            state.checklists = action.payload;
            state.loading = {...state.loading, checklists: false};
        },
        getListFailure: (state) => {
            state.loading = {...state.loading, checklists: false};
        },
        getVessels: (state, action) => {
            state.vessels = action.payload || []
        },
        add: (state, action) => {
            const checklist = action.payload;
            const {checklists} = state;
            state.checklists = [...checklists, checklist]
        },
        update: (state, action: PayloadAction<{ oldId?: string } & IChecklist>) => {
            const {checklists} = state;
            const {oldId = action.payload.id, ...checklist} = action.payload;
            state.checklists = checklists.map(item => item.id === oldId ? checklist : item);
        },
        delete: (state, action) => {
            const id = action.payload;
            const {checklists} = state;
            state.checklists = checklists.filter(checklist => checklist.id !== id)
        },
        getServiceTasksRequest: (state) => {
            state.loadingServiceTasks = true;
        },
        getServiceTasksSuccess: (state, action) => {
            state.serviceTasks = action.payload;
            state.loadingServiceTasks = false;
        },
        getServiceTasksFailure: (state) => {
            state.loadingServiceTasks = false;
        }
    },
})

export const checklistActions = checklistSlice.actions

const checklistReducer = checklistSlice.reducer;

export default checklistReducer;

