import {KEY_STORAGE} from "../varibles/constants";
import {notification} from "antd";
import {store} from "../store/store";
import {loginActions} from "../../pages/LoginPage/reducer";

// import { notification } from "antd";

export function err(response: any, data: any) {
    return {response, data};
}

function checkStatus(response: Response) {
    const data = response.json();

    checkStatusCode(response.status);

    if (!response.ok) {
        throw err(response, data);
    }

    return data;
}

export function checkStatusCode(status: any) {
    if (status === 401) {
        store.dispatch(loginActions.logout());
    }
    if (status === 403) {
        notification.error({message: 'Access Denied'})
    }
}

const getToken = (isAuthentication: boolean): {Authorization: string} | {} | undefined => {
    if (!isAuthentication)
        return {}

    try {
        const authentication = localStorage.getItem(KEY_STORAGE.AUTHENTICATION);
        if (!authentication) {
            const result = () => {
                throw Error('Authentication invalid')
            };
            result()
        } else {
            const token = JSON.parse(authentication).jwtToken;
            return {Authorization: ['Bearer', token].join(' ')}
        }
    } catch (error) {
        throw error;
    }
}


const callApi = async <T>(url: string, options: {
    callback?: (value: T) => void,
    method: "GET" | "POST" | "PUT" | "DELETE"
    body?: any
    signal?: any
    headers?: {
        [key: string]: any
    }
}, isAuthentication = true) => {
    const {callback, ...args} = options;
    const headers = {
        'Content-Type': 'application/json',
        ...args.headers,
        ...getToken(isAuthentication)
    };

    try {
        let response = await fetch(url, {...args, headers});
        let json: any = await checkStatus(response);
        const value = typeof json === 'object' ? json : (json && json.length > 0) ? JSON.parse(json) : {};
        return callback ? callback(value) : value;
    } catch (error) {
        throw error
    }
};
export default callApi;

export const api = {
    get: <T>(url: string, opts: any = {}): Promise<T> => {
        const {isAuthentication = true} = opts;
        return callApi<T>(url, {...opts, method: 'GET'}, isAuthentication);
    },
    post: <T>(url: string, body: any = {}, opts: any = {}): Promise<T> => {
        const {isAuthentication = true} = opts;
        return callApi<T>(url, {...opts, body: JSON.stringify(body), method: 'POST'}, isAuthentication);
    },
    put: <T>(url: string, body: any, opts: any = {}): Promise<T> => {
        const {isAuthentication = true} = opts;
        return callApi<T>(url, {...opts, body: JSON.stringify(body), method: 'PUT'}, isAuthentication);
    },
    delete: <T>(url: string, opts: any = {}): Promise<T> => {
        const {isAuthentication = true} = opts;
        const options = {...opts, method: 'DELETE'}
        return callApi<T>(url, options, isAuthentication);
    },
    deleteBody: <T>(url: string, body: any, opts: any = {}): Promise<T> => {
        const {isAuthentication = true} = opts;
        return callApi<T>(url, {...opts, body: JSON.stringify(body), method: 'DELETE'}, isAuthentication);
    }
}
