import React from 'react';
import styles from "./style.module.scss";
import {diseaseType} from "../../../util/varibles/constants";

interface IProps {
    status: any
    size?: 'small' | 'large'
}

const DiseaseStatus: React.FC<IProps> = ({status, size = "small"}) => {
    const {name = '', color = ''} = diseaseType[status] || {};

    const style: any = {'--disease-type-color': color}
    return <div className={styles.status} data-code={status} data-size={size} style={style}>
        {name}
    </div>;
};

export default DiseaseStatus;