import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import Loading from "./Loading";
import {MESSAGE_TYPE} from "../../../pages/MessagePage/constants";
import {FULL_DATE_FORMAT} from "../../../util/varibles/constants";
import Message from "./Message";
import Timeline from "./Timeline";
import Empty from "./Empty";
import {updateMessageFetch} from "../../../util/services/chat";
import MessageAddUser from "./MessageAddUser";
import MessageRemoveUser from "./MessageRemoveUser";
import MessageLeave from "./MessageLeave";
import Seen from "./Seen";
import {userColors} from "../constants";
import {messageActions} from "../../../pages/MessagePage/reducer";
import {datetime} from "../../../util/library/datetime";
import {IConversation, IMessage, IMessages, IStateGlobal} from "../../../util/varibles/interface";

const mapStateToProps = (state: IStateGlobal) => ({
    lists: state.message.messages
})

interface IProps {
    data: IConversation
    loading: boolean
    childRef: any
    lists: IMessages
    messages: IMessage[]

    getMessages(payload: any): void

    updateMessage(payload: IConversation): void
}

class Conversation extends Component<IProps> {

    state = {
        scrollMode: 'bottom'
    }

    rootRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);

        if (this.rootRef.current && this.state.scrollMode === 'bottom') {
            this.rootRef.current.scrollTo({top: this.rootRef.current.scrollHeight});
        }
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        const {messages} = this.props;
        if (JSON.stringify(messages) !== JSON.stringify(prevProps.messages)) {
            if (this.rootRef.current && this.state.scrollMode === 'bottom') {
                const options: any = prevProps.messages.length > 0
                    ? {top: this.rootRef.current.scrollHeight, behavior: 'smooth'}
                    : {top: this.rootRef.current.scrollHeight}
                this.rootRef.current.scrollTo(options);
            }
        }
    }

    handleWheel = (e: any) => {
        if (this.rootRef.current) {
            const {loading} = this.props;
            const {deltaY} = e;
            const {scrollTop, scrollHeight, offsetHeight}: any = this.rootRef.current;
            const isScrolledToTop = scrollTop + deltaY <= 0;
            const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;

            if (isScrolledToTop && deltaY < 0) {
                const {group_id} = this.props.data;
                const {messages} = this.props;

                if (!group_id || messages.length === 0)
                    return;

                const {prev} = this.props.lists[group_id] || {}
                if (!prev || loading) return;

                this.updateScrollMode('top')
                const {id: chat_id} = messages[messages.length - 1]
                this.props.getMessages({id: group_id, chat_id});
                return;
            }

            if (isScrolledToBottom && deltaY > 0) {
                this.updateScrollMode('bottom')
            } else {
                this.updateScrollMode('middle')
            }
        }
    }

    updateScrollMode = (scrollMode: string) => this.setState({scrollMode})

    onUpdateMessage = async (chat: IMessage, message: string) => {
        if (!this.props.data)
            return;
        const {chat_id} = chat;
        const {id} = this.props.data.group_chat;
        await updateMessageFetch({group_id: id, chat_id, message})
        this.props.updateMessage({...this.props.data, message: {...chat, message}});
        this.setState({isEdit: false});
    }

    render() {
        const {loading,} = this.props;
        const {messages} = this.props;
        const {members = []} = this.props.data.group_chat;
        const isEmpty = messages.length === 0;
        const timeline: string[] = [];

        const common: any = {
            id: 'message-history',
            ref: this.rootRef,
            className: styles['history'],
            'data-is-empty': messages.length === 0
        }

        // const propsHistory = (prev || next)
        //     ? {...common, onWheel: this.handleWheel}
        //     : common;
        const propsHistory = {...common, onWheel: this.handleWheel}

        const colors = members.reduce((rs: any, item: any, index: number) => {
            rs[item.username] = userColors[index % 10];
            return rs;
        }, {})

        return <div className={styles['wrapper-conversation']} data-is-empty={isEmpty}>
            {(isEmpty && !loading)
                ? <Empty/>
                : <div className={styles['container-history']}>
                    <div {...propsHistory}>
                        {loading && <Loading/>}
                        {messages.map((item: any, index: number) => {
                            const {timestamp, message_type} = item;
                            const date = datetime(timestamp).time;
                            switch (message_type) {
                                case MESSAGE_TYPE.ADD: {
                                    return <MessageAddUser key={index} data={item}/>
                                }
                                case MESSAGE_TYPE.DELETE: {
                                    return <MessageRemoveUser key={index} data={item}/>
                                }
                                case MESSAGE_TYPE.LEAVE: {
                                    return <MessageLeave key={index} data={item}/>
                                }
                                default: {
                                    const props = {
                                        data: item,
                                        color: colors[item.username] || colors[0],
                                        onUpdate: this.onUpdateMessage
                                    }
                                    if (date.isValid) {
                                        const key = date.format(FULL_DATE_FORMAT);
                                        const isExist = timeline.some(item => item === key);
                                        if (!isExist) {
                                            timeline.push(key)
                                            return [
                                                <Timeline key={'timeline' + index} data={date}/>,
                                                <Message key={index} {...props}/>
                                            ]
                                        }
                                    }
                                    return <Message key={index} {...props}/>
                                }
                            }
                        })}
                    </div>
                </div>}
            <Seen data={messages[messages.length - 1]} members={members} colors={colors}/>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getMessages: messageActions.getMessagesRequest,
    updateMessage: messageActions.updateMessage
})(Conversation);