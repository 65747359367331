import {IDisease} from "../../util/varibles/interface";

export const initialState: DiseaseState = {
    diseases: [],
    loading: false
}


export interface DiseaseState {
    diseases: IDisease[]
    loading: boolean
}
