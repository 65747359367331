import React from 'react';
import styles from  './style.module.scss';

interface IProps {
    data: any
}

const Note: React.FC<IProps> = ({data}) => {

    return <div className={styles['note']} data-show={data.length > 0}>
        Please save {[data.map((item: any) => `#${item.operation.operation_code}`)].join(', ')} before adding delay time
    </div>;
};

export default Note;
