import {initialState,} from "./constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IDisease} from "../../util/varibles/interface";

const diseaseSlice = createSlice({
    name: 'disease',
    initialState,
    reducers: {
        getListRequest: (state) => {
            state.diseases = [];
            state.loading = true;
        },
        getListSuccess: (state, action: PayloadAction<IDisease[]>) => {
            state.diseases = action.payload.filter(item => item.is_active);
            state.loading = false
        },
        getListFailure: (state) => {
            state.loading = false
        },
        add: (state, action) => {
            const disease = action.payload;
            const {diseases} = state;
            state.diseases = [...diseases, disease]
        },
        update: (state, action) => {
            const disease = action.payload;
            const {id} = disease;
            const {diseases} = state;
            state.diseases = diseases.map(item => item.id === id ? disease : item);
        },
        delete: (state, action) => {
            const {id} = action.payload;
            const {diseases} = state;
            state.diseases = diseases.filter(item => item.id !== id);
        }
    },
})

export const diseaseActions = diseaseSlice.actions

const diseaseReducer = diseaseSlice.reducer;

export default diseaseReducer;
