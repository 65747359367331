import React, {useCallback} from 'react';
import {Checkbox} from "antd";
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import {AVAILABLE_TIME_TYPE} from "../../../../util/varibles/constants";

interface IProps {
}

const AvailableTimeType: React.FC<IProps> = () => {
    const [value, setStore] = useEditVessel(state => state.vessel?.available_time_type);

    const handleChange = useCallback((checked: boolean) => {
        setStore(({vessel: old}) => ({
            vessel: {
                ...old,
                available_time_type: checked ? AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME : AVAILABLE_TIME_TYPE.ALL
            }
        }))
    }, [setStore])

    return <div className='mb-4'>
        <Checkbox
            checked={value === AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME}
            onChange={({target}) => handleChange(target.checked)}
        >
            Use available time only
        </Checkbox>
    </div>;
};

export default AvailableTimeType;