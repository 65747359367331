import React, {useCallback, useState} from 'react';
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {IVessel} from "../../../../util/varibles/interface";
import {ACTION_TYPE, AVAILABLE_TIME_TYPE, OPERATION_TYPE, VESSEL_TYPE} from "../../../../util/varibles/constants";
import {VesselService} from "../../../../util/services/vessel";
import styles from "../style.module.scss";
import Footer from "../../../../components/Popup/Component/Footer";
import {getEditVessel, setEditVessel} from "../../../../contexts/EditVesselContext";
import {showErrorResponse} from "../../../../util/varibles/global";
import {Storage} from 'aws-amplify';
import {datetime} from "../../../../util/library/datetime";
import {validateForm} from "../../../../util/varibles/validation";

const request: {
    [id: string]: { fetch: (data: Partial<IVessel>) => Promise<IVessel>, code: NotifyCode, message: string }
} = {
    [ACTION_TYPE.CREATE]: {fetch: VesselService.add, code: NotifyCode.S1, message: 'Create'},
    [ACTION_TYPE.EDIT]: {fetch: VesselService.update, code: NotifyCode.S2, message: 'Update',}
}

interface IProps {
    editMode: ACTION_TYPE

    onSave(vessel: IVessel): void

    onClose(): void
}

const VesselSubmit: React.FC<IProps> = ({editMode, onSave, onClose}) => {
    const [loading, setLoading] = useState(false);
    const hook = getEditVessel();
    const setStore = setEditVessel();

    const handleSend = useCallback(() => {
        const {vessel, file} = hook();
        const error = validateForm({vessel});
        console.log(error)

        if (Object.keys(error).length > 0) {
            setStore({error})
            return;
        }

        const {
            type,
            contact = '',
            available_time_type = AVAILABLE_TIME_TYPE.ALL,
            storages = [],
            equipments = [],
            operation_type = [],
            tank_sub_types = [],
            capacity = 0,
        } = vessel;

        if (storages.length === 0 && type !== VESSEL_TYPE.SERVICE) {
            notify.error(NotifyCode.E41)();
            return;
        }

        const data = {
            ...vessel,
            contact,
            capacity,
            operation_sub_types: [],
            available_time_type,
            operation_type: (equipments.length > 0 || tank_sub_types.length > 0)
                ? Array.from(new Set([...operation_type, OPERATION_TYPE.TREATMENT, OPERATION_TYPE.EVENT]))
                : Array.from((() => {
                    const value = new Set([...operation_type, OPERATION_TYPE.EVENT]);
                    value.delete(OPERATION_TYPE.TREATMENT);
                    return value;
                })())
        }

        const send = (data: Partial<IVessel>) => {
            const {fetch, code, message = ''} = request[editMode];
            setLoading(true);
            fetch(data)
                .then(rs => {
                    onSave(rs);
                    setLoading(false);
                    notify.success(code)([rs.name]);
                    onClose();
                })
                .catch((error: any) => {
                    setLoading(false);
                    showErrorResponse(message + ' failed', error);
                })
        }

        if (file) {
            const name = file.name.split('.');
            name[name.length - 2] = name[name.length - 2] + '_' + datetime().format('YYYYMMDD_HHmmSSZZ');
            const key = 'vessel/images/' + name.join('.');
            const image_url = 'vessel/images/' + name.join('.');
            Storage.put(key, file, {contentType: 'image/jpeg'})
                .then(() => send({...data, image_url}))
                .catch((err: any) => showErrorResponse('Upload file failed', err));
        } else {
            if (editMode === ACTION_TYPE.EDIT) {
                send({...vessel, ...data})
            } else {
                send({...data, image_url: '-'})
            }
        }
    }, [editMode, hook, setStore, onClose, onSave])

    return <Footer
        className={styles.footer}
        cancel={{click: onClose}}
        ok={{
            click: handleSend,
            loading
        }}
    />
};

export default VesselSubmit;
