import React, {useState} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE} from "../../../util/varibles/constants";
import {FormInstance} from "antd/lib/form";
import {Button, Form, Input, Modal} from "antd";
import styles from './style.module.scss';
import {taskTypeActions} from "../reducer";
import {createTaskTypeFetch, updateTaskTypeFetch} from "../../../util/services/taskType";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";

const rules = {
    name: [{required: true, message: `Name is required`}],
    tag: [{required: true, message: `Tag is required`}],
    unit: [{required: true, message: `Unit is required`}],
}

const mapStateToProps = () => ({});

interface IProps {
    editMode: ACTION_TYPE
    task: any
    visible?: boolean

    add(payload: any): void

    update(payload: any): void

    onClose?(): any
}

const PopupEditTaskType: React.FC<IProps> = (props) => {
    const formRef: any = React.createRef<FormInstance>();
    const {editMode} = props;
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(props.visible);

    const handleSubmit = (data: any) => {
        const {editMode = ACTION_TYPE.CREATE, task} = props;
        const {id = undefined, name = '', unit = '', value = 0} = {...task, ...data};
        let action: any, response: any, success: any, titleError: string = '';
        if (editMode === ACTION_TYPE.CREATE) {
            action = createTaskTypeFetch
            response = props.add;
            success = () => notify.success(NotifyCode.S1)([name]);
            titleError = 'Create failed';
        } else {
            action = updateTaskTypeFetch
            response = props.update
            success = () => notify.success(NotifyCode.S2)([name]);
            titleError = 'Update failed';
        }
        const params = {
            id,
            name,
            in_fields: [],
            unit,
            value: Number(value)
        }
        setLoading(true);
        new Promise(resolve => resolve(action(params)))
            .then((rs: any) => {
                response(rs);
                success();
                handleClose();
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse(titleError, error)
            })
    }

    const handleClose = () => {
        setVisible(false);
        if (props.onClose)
            props.onClose()
    }

    return <Modal
        centered={true}
        open={visible}
        closable={false}
        keyboard={true}
        footer={null}
        styles={{body: {padding: 0}}}
        onCancel={handleClose}
    >
        <div className={styles['header']}>
            <span>{editMode === ACTION_TYPE.CREATE ? 'Add task type' : 'Update task type'}</span>
            <span onClick={handleClose}>&times;</span>
        </div>
        <Form className={styles['body']} ref={formRef} onFinish={handleSubmit} name='factory'
              layout='vertical' requiredMark={'optional'}>
            <Form.Item label={<span className='text-[15px]'>Name</span>} name="name"
                       rules={rules.name}>
                <Input className='border-r-40'/>
            </Form.Item>
            {/*<Form.Item label={ <span className='text-[15px]'>Tag</span> } name="in_fields"*/}
            {/*           rules={ rules.tag }>*/}
            {/*    <Select mode="multiple" placeholder="Tag" style={ { width: '100%' } }>*/}
            {/*        <Option className={ styles['option'] } value='site'>Site</Option>*/}
            {/*        <Option className={ styles['option'] } value='factory'>Factory</Option>*/}
            {/*    </Select>*/}
            {/*</Form.Item>*/}
            <Form.Item label={<span className='text-[15px]'>Unit</span>} name="unit"
                       rules={rules.unit}>
                <Input className='border-r-40'/>
            </Form.Item>
            <Form.Item
                label={<span className='text-[15px]'>Value</span>}
                name="value">
                <Input className='border-r-40'/>
            </Form.Item>
            <div className={styles['footer']}>
                <Button className='bt-default pl-4 pr-4' onClick={handleClose}>Cancel</Button>
                <Button type='primary' loading={loading}
                        className='ml-3 pl-4 pr-4 bt-primary'
                        onClick={() => formRef.current.submit()}>
                    Save
                </Button>
            </div>
        </Form>
    </Modal>;
};

export default connect(mapStateToProps, {
    add: taskTypeActions.add,
    update: taskTypeActions.update,
})(PopupEditTaskType);
