import React, {useEffect} from 'react';
import styles from "../../Control/LeftControl/style.module.scss";
import {ZoomInOutlined} from "@ant-design/icons";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import PopupWeather from "../PopupWeather";
import WeatherChart from "../../../../components/WeatherChart";
import {useSelector} from "react-redux";
import {selectDashboard} from "../../../../util/store/selectors";

let weatherRef: any;

interface IProps {
    name: string
}

const Weather: React.FC<IProps> = ({name}) => {
    const loading = useSelector(selectDashboard.loadingWeather);
    const weather = useSelector(selectDashboard.weather);

    useEffect(() => {
        if (weatherRef)
            weatherRef.setState({loading, weather})
    }, [loading, weather])

    const handleOpen = () => {
        const el = openPopup(<PopupWeather
            isShow={true}
            name={name}
            loading={loading}
            weather={weather}
            childRef={(ref: any) => weatherRef = ref}
            onClose={() => {
                weatherRef = undefined;
                el.remove();
            }}
        />)
    }

    return <>
        <div className={styles.title}>
            <div>Weather info.</div>
            <div className={styles['zoom-in']} onClick={handleOpen} title='Zoom in'>
                <ZoomInOutlined/>
            </div>
        </div>
        <div className={styles.body}>
            <WeatherChart {...{
                loading,
                weather,
                width: 395,
                height: !weather.weather || weather.weather.length === 0 ? 200 : 250
            }}/>
        </div>
    </>
};

export default Weather;
