import React, {useEffect} from 'react';
import LoadingBox from "../../../../../../../components/LoadingBox";
import {PROCESS_TYPE} from "../../../../../../../util/varibles/constants";
import {checkTaskOfTreatment} from "../constants";
import Round from "./Round";
import Tank from "./Tank";
import {IRoundPlan, TTaskTreatment} from "../../../../../../../util/varibles/interface";
import {callEventEditOp, getEditOp, IUnitWhenEdit, useEditOp} from "../../../../../../../contexts/EditOperationContext";


interface IProps {
    units: IUnitWhenEdit[]
}

const RoundMap: React.FC<IProps> = React.memo(({units}) => {
    const [round_map = {}, set] = useEditOp(state => state.operation?.round_map);
    const [loadingRoundMap] = useEditOp(state => state.loadingRoundMap);
    const {getRoundMap, getLocations, turnOnCalculate} = callEventEditOp();
    const get = getEditOp();

    useEffect(() => {
        if (units.length === 0) {
            set(({operation}) => ({operation: operation ? {...operation, round_map: {}} : operation}))
            return;
        }

        const value = units.reduce((rs: any, item) => {
            const {
                unit_id,
                total_weight = 0,
                avg_weight = 0,
                tasks = [],
                density_percent,
                tank_number,
                process_type = PROCESS_TYPE.SEQUENCE
            } = item;
            if (!total_weight || !avg_weight)
                return rs;

            const jobs = (tasks as TTaskTreatment[]).reduce((subRs: TTaskTreatment[], job: TTaskTreatment) => {
                const {type = null} = job;
                if (type === null)
                    return subRs;

                const {isError} = checkTaskOfTreatment(job);
                if (isError)
                    return subRs;

                subRs.push(job);
                return subRs
            }, [])

            if (jobs.length === 0)
                return rs;

            rs.push({unit_id, avg_weight, total_weight, density_percent, tank_number, tasks: jobs, process_type});

            return rs;
        }, [])
        if (value.length === 0) {
            set(({operation}) => ({operation: operation ? {...operation, round_map: {}} : operation}))
        } else {
            getRoundMap({units: value, after_round: {}})
        }
    }, [units, get, set]);

    const handleChangeTank = (data: IRoundPlan['tanks'][0], isAdd: boolean) => {
        const {unit_id} = data;
        if (unit_id) {
            const tank_number = Object.keys(round_map).reduce((rs: number, key) => {
                return rs + round_map[key].tanks.filter(item => item.unit_id === unit_id).length;
            }, 0)

            set(({units}) => {
                const {density_percent, ...args} = units[unit_id]
                return {units: {...units, [unit_id]: {...args, tank_number: isAdd ? tank_number + 1 : tank_number - 1}}}
            })
            turnOnCalculate()
        }
    }

    const handleParallel = (currentRound: string, nextRound: string) => {
        const {tanks: currentTanks} = round_map[currentRound];
        const {tanks: nextTanks} = round_map[nextRound];
        if (currentTanks && nextTanks) {
            const ids = new Set([...currentTanks, ...nextTanks].map(item => item.unit_id));
            ids.delete(undefined);
            set(({units}) => {
                return {
                    units: Object.keys(units).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                        if (ids.has(key)) {
                            return {...rs, [key]: {...units[key], process_type: PROCESS_TYPE.PARALLEL}}
                        }
                        rs[key] = units[key]
                        return rs
                    }, {})
                }
            })

            turnOnCalculate();
        }
    }

    return <div className='flex flex-col gap-[8px] px-[24px]'>
        {round_map.loading && <LoadingBox loading={loadingRoundMap} level='bg'/>}
        {Object.keys(round_map).map((key, i, arr) => {
            const {after_processes, tanks} = round_map[key];
            const nextRound = arr[i + 1];

            return <Round
                key={key}
                roundId={key}
                after_processes={after_processes}
                getLocations={getLocations}
                onUpdateAfterRound={value => {
                    const {after_round = {}} = get().bodyGetRoundMap || {};
                    console.log(value);
                    turnOnCalculate()
                    setTimeout(() => getRoundMap({after_round: {...after_round, ...value}}), 10);
                }}
            >
                <div className='grid gap-3 z-[1]' style={{gridTemplateColumns: `repeat(${tanks.length}, 1fr)`}}>
                    {tanks.map((item, i: number) => <Tank
                        key={i}
                        data={item}
                        nextUnit={!!round_map[nextRound]}
                        onParallel={() => handleParallel(key, nextRound)}
                        onChangeTank={isAdd => handleChangeTank(item, isAdd)}
                    />)}
                </div>
            </Round>
        })}
    </div>;
}, (prev, next) => JSON.stringify(prev.units) === JSON.stringify(next.units));

export default RoundMap;
