import {OPERATION_TYPE} from "../../../util/varibles/constants";
import {MAP_ELEMENT} from "../../../util/library/googlemap/constants";
import {createPolyline} from "../../../util/varibles/global";

export enum EL_DASHBOARD {
    LEFT_CONTROL = 'dashboard-left-control',
    RIGHT_CONTROL = 'dashboard-right-control',
    TOP_CONTROL = 'dashboard-top-control',
    CONTROL = 'dashboard-control'
}

export const filterSites = (sites: any, activeSite: boolean, productionAreas: any[]) => {
    const list = activeSite ? sites.filter((item: any) => item.isActive) : sites;
    if (productionAreas.length > 0) {
        const filter = new Set(productionAreas);
        return list.reduce((list: any, item: any) => {
            return filter.has(`${item.prod_area}`) ? [...list, item.id] : list
        }, []);
    } else
        return list.map((item: any) => item.id);
}


export const generateMarkers: any = {
    [OPERATION_TYPE.HARVEST]: (data: any) => {
        const {factory_id} = data.operation;
        const {sites} = data.operation;
        return [
            ...sites.map((item: any) => ({type: MAP_ELEMENT.SITE, id: item.id})),
            {type: MAP_ELEMENT.FACTORY, id: factory_id},
        ]
    },
    [OPERATION_TYPE.TREATMENT]: (data: any) => {
        const {id = data.operation.site_id || ''} = data.operation.site || {};
        return [
            {type: MAP_ELEMENT.SITE, id},
        ]
    },
    [OPERATION_TYPE.TRANSPORT]: (data: any) => {
        const {source_id, destination_id} = data.operation;
        return [
            {type: MAP_ELEMENT.SITE, id: source_id},
            {type: MAP_ELEMENT.SITE, id: destination_id},
        ]
    },
}

export const generateSeaWays: any = {
    [OPERATION_TYPE.HARVEST]: (data: any) => [createPolyline(data.operation.route, 0)],
    [OPERATION_TYPE.TRANSPORT]: (data: any) => [createPolyline(data.operation.route, 0)],
}
