import React from 'react';
import {IOperation} from "../../../../../util/varibles/interface";
import WrapperOperation from "../WrapperOperation";
import Logo from "../Logo";
import {OPERATION_TYPE, operationType} from "../../../../../util/varibles/constants";
import Icon from "../../../../../components/Icon";
import {IPropsOp} from "../index";

interface IProps extends IPropsOp {
    data: IOperation
}

const Transport: React.FC<IProps> = (props) => {
    const {operation} = props.data;
    const {icon} = operationType[OPERATION_TYPE.TRANSPORT];
    return <WrapperOperation {...props}>
        <Logo>
            <Icon icon={icon}/>
        </Logo>
        <div className='text-nowrap'>{operation.source_name}</div>
        <div className='arrow-right'>&#8594;</div>
        <div className='text-nowrap'>{operation.destination_name}</div>
    </WrapperOperation>;
};

export default Transport;