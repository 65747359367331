import React from 'react';
import styles from "./style.module.scss";
import {ISite} from "../../../util/varibles/interface";
import {Table} from "antd";
import Units from "./Units";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEditSite from "./PopupEditSite";

interface IProps {
    sites: ISite[]
    loading: boolean

    onUpdate(sites: ISite[]): void
}

const Sites: React.FC<IProps> = ({sites, loading, onUpdate}) => {
    const columnsSite = [
        {
            title: 'No',
            key: 'no',
            className: styles['no'],
            width: 40,
            render: (value: any, item: any, index: number) => index + 1,
        },
        {
            title: 'Site',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            className: 'text-capitalize'
        },
        {
            title: 'Fish type',
            dataIndex: 'site_type',
            key: 'site_type',
            className: 'text-capitalize'
        },
        {
            width: 90,
            render: (site: any) =>
                <div className={styles['action-table']}>
                    <div className={styles['container-action']} onClick={() => handleEdit(site)}>
                        <div className={styles['icon-edit']}/>
                    </div>
                    <div
                        className={styles['container-action']}
                        data-is-delete={'true'}
                        onClick={() => handleDelete(site.id)}
                    >
                        <div className={styles['icon-delete']}/>
                    </div>
                </div>,
        }
    ];

    const handleDelete = (siteId: string) => {
        onUpdate(sites.filter((item: any) => item.id !== siteId))
    }

    const handleEdit = (site: ISite) => {
        const div = openPopup(<PopupEditSite
            visible={true}
            data={site}
            onSave={handleUpdate}
            onClose={() => div.remove()}
        />)
    }

    const handleUpdate = (values: any) => {
        const {id: siteId} = values;
        onUpdate(sites.map((item: any) => item.id !== siteId ? {...item, ...values} : item))
    }

    return <Table
        rowKey='id'
        pagination={false}
        loading={loading}
        columns={columnsSite}
        dataSource={sites}
        expandable={{
            columnWidth: 38,
            expandedRowRender: (record: any) => <Units
                key={record.id}
                siteId={record.id}
                units={record.units}
                onUpdate={handleUpdate}
            />,
        }}
    />;
};

export default Sites;