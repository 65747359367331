import React from 'react';
import Header from "../../../components/Popup/Component/Header";
import Icon, {ICON_NAME} from "../../../components/Icon";
import styles from './style.module.scss';
import {Popconfirm} from "antd";
import {propsConfirm} from "../../../util/varibles/constants";

interface IProps {
    disable: boolean

    onClear(): void

    onClose(): void
}

const Title: React.FC<IProps> = (props) => {
    return <Header
        title={<div className={styles.title}>
            <div className='font-s2 font-w5'>Assign checklist to vessel</div>
            {!props.disable && <Popconfirm
                {...propsConfirm}
                placement="topLeft"
                title='Are you sure to unassign this checklist?'
                description='Unassign this checklist'
                onConfirm={props.onClear}
            >
                <div className={styles.delete}>
                    <Icon icon={ICON_NAME.DELETE} danger/>
                </div>
            </Popconfirm>}
        </div>}
        onClose={props.onClose}
    />;
};

export default Title;
