import React, {useEffect, useState} from 'react';
import {DISEASED_ZONE, diseasedZone, FULL_DATE_FORMAT} from "../../../util/varibles/constants";
import styles from './style.module.scss';
import {SCHEDULE_STATUS} from "../../../util/varibles/interface";
import {getElById} from "../../../util/varibles/global";
import {SCHEDULE_ID} from "../index";
import {datetime} from "../../../util/library/datetime";

const right: any = {
    [DISEASED_ZONE.ILA_10_CIRCLE]: 0,
    [DISEASED_ZONE.ILA_PROTECTION]: '10px',
    [DISEASED_ZONE.ILA_SURVEILLANCE]: '20px',
    [DISEASED_ZONE.PD_PROTECTION]: '30px',
    [DISEASED_ZONE.PD_SURVEILLANCE]: '40px',
    [DISEASED_ZONE.PD_ZONE]: '50px'
}

interface IProps {
    data: any
}

const Disease: React.FC<IProps> = ({data}) => {
    const [style, setStyle]: any = useState({});

    useEffect(() => {
        setTimeout(() => {
            const {start_time, finish_time, [SCHEDULE_STATUS.ENTER]: start, [SCHEDULE_STATUS.LEAVE]: finish} = data;
            let top = 31, bottom;

            const startKey = datetime(start_time).format(FULL_DATE_FORMAT);
            const startEl = getElById(startKey);
            if (startEl && start !== undefined) {
                const activity = startEl.childNodes[1].childNodes[start] as HTMLElement;
                bottom = (startEl.parentElement?.offsetTop || 0) + activity.offsetTop + activity.offsetHeight;
            } else {
                const el = getElById(SCHEDULE_ID);
                bottom = el.scrollHeight;
            }

            const finishKey = datetime(finish_time).format(FULL_DATE_FORMAT);
            const finishEl = getElById(finishKey);
            if (finishEl && finish !== undefined) {
                const activity = finishEl.childNodes[1].childNodes[finish] as HTMLElement;
                top = (finishEl.parentElement?.offsetTop || 0) + activity.offsetTop;
            }

            setStyle({top: top + 'px', height: (bottom - top) + 'px'})
        }, 100);
    }, [data]);

    const {type} = data;
    const {color = null} = diseasedZone[type] || {};

    if (!color || !right[type])
        return null;

    return <div
        className={styles.disease}
        style={{...style, right: right[type], background: color}}
    />;
};

export default Disease;
