import React, {Component} from 'react';
import styles from './style.module.scss';
import {ELEMENT_MODE, elementTypes} from "./constants";

interface IProps {
    activeType: ELEMENT_MODE,

    onClick(activeType: ELEMENT_MODE): void
}


class SwitchElementMode extends Component<IProps> {


    render() {
        const {activeType, onClick} = this.props;
        return <div className={styles['mode']}>
            {
                elementTypes.map(elementType =>
                    <div className={styles['element-mode']}
                         data-is-active={elementType.key === activeType}
                         key={elementType.key}
                         onClick={() => onClick(elementType.key)}
                    >
                        {elementType.name}
                    </div>)
            }
        </div>;
    }
}

export default SwitchElementMode;
