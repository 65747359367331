import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import ContainerElement from "./ContainerElement";
import {TimePicker} from "antd";
import {propContainer} from "../../../util/varibles/constants";

const format = 'HH:mm'

interface IProps extends IPropsElement {

}

class TimeElement extends Component<IProps> {
    render() {
        return <ContainerElement {...this.props}>
            <TimePicker {...{...propContainer, format}}/>
        </ContainerElement>;
    }
}

export default TimeElement;
