import React, {useEffect, useState} from 'react';
import {ACTION} from "../../util/varibles/permission";
import {nextPage} from "../../util/library/Router";
import {PATHNAME} from "../../util/varibles/constants";
import {useSelector} from "react-redux";
import {selectPermission} from "../../util/store/selectors";
import {VesselService} from "../../util/services/vessel";
import LoadingBox from "../../components/LoadingBox";
import {showErrorResponse} from "../../util/varibles/global";
import List from "./List";


const EquipmentsPage: React.FC = () => {
    const permission = useSelector(selectPermission);
    const [{loading, equipments}, setEquipments] = useState<{ loading: boolean, equipments: any[] }>({
        loading: false,
        equipments: []
    });
    useEffect(() => {
        if (!permission[ACTION.EQUIPMENT.VIEW]) {
            nextPage(PATHNAME.ACCESS_DENIED)
        } else {
            setEquipments(prev => ({...prev, loading: true}));
            VesselService.equipments()
                .then(rs => setEquipments({loading: false, equipments: rs}))
                .catch(error => {
                    setEquipments(prev => ({...prev, loading: false}));
                    showErrorResponse('Get data failed', error)
                });
        }
    }, [permission]);

    return <div className='m-header bgPage'>
        <div className='header-page'>
            <div className='title-page'>
                <b>Equipment Management</b>
            </div>
        </div>
        <div className='body-page' data-box='true'>
            {equipments.length === 0 && <LoadingBox loading={loading}/>}
            <List data={equipments}/>
        </div>
    </div>;
};

export default EquipmentsPage;
