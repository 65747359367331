import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import styles from './style.module.scss';
import {CALENDAR_ID} from "../../constants";
import {aWeekMillisecond} from "../../../../util/varibles/constants";
import {getKeyWeek} from "../../../../util/varibles/global";
import {overviewActions} from "../../reducer";
import {AppState} from "../../../../util/store/store";
import LoadingBox from "../../../../components/LoadingBox";

const mapStateToProps = (state: AppState) => {
    return {
        weeks: state.overview.weeks,
        loadingData: state.overview.loadingData,
        loadingLazy: state.overview.loadingLazy,
        beforeCount: state.overview.beforeCount,
        afterCount: state.overview.afterCount,
    }
};

interface IProps {
    weeks: { key: string, time: number }[]
    loadingData: boolean
    loadingLazy: boolean
    beforeCount: boolean
    afterCount: boolean

    lazyLoading(payload: { isLoad: boolean, key: string, weeks: any, startTime: number, finishTime: number }): void
}

const Loading: React.FC<IProps> = ({weeks, loadingData, loadingLazy, beforeCount, afterCount, lazyLoading}) => {
    const [mode, setMode] = useState('');

    const onScroll = useCallback((e: any) => {
        if (loadingLazy || !e.target)
            return;
        const {scrollLeft, scrollWidth, offsetWidth} = e.target;
        if (scrollLeft === 0) {
            const {time: finishTime} = weeks[0];
            const startTime = finishTime - (3 * aWeekMillisecond);
            let newWeeks = [...weeks];
            for (let i = 1; i <= 3; i++) {
                const time = finishTime - (i * aWeekMillisecond);
                const keyWeek = getKeyWeek(time);
                newWeeks = [{key: keyWeek, time}, ...newWeeks]
            }
            e.target.scrollLeft = 5;
            lazyLoading({
                isLoad: beforeCount,
                key: 'beforeCount',
                weeks: newWeeks,
                startTime,
                finishTime: finishTime - 1
            });
            setMode('left');
        } else if (scrollLeft + offsetWidth === scrollWidth) {
            const {time: startTime} = weeks[weeks.length - 1];
            const finishTime = startTime + (3 * aWeekMillisecond) - 1;
            let newWeeks = [...weeks];
            for (let i = 1; i <= 3; i++) {
                const time = startTime + (i * aWeekMillisecond);
                const keyWeek = getKeyWeek(time);
                newWeeks = [...newWeeks, {key: keyWeek, time}]
            }
            lazyLoading({
                isLoad: afterCount,
                key: 'afterCount',
                weeks: newWeeks,
                startTime,
                finishTime: finishTime - 1
            });
            setMode('right');
        }
    }, [weeks, loadingLazy, beforeCount, afterCount, lazyLoading]);

    useEffect(() => {
        const el = document.getElementById(CALENDAR_ID);
        if (el)
            el.addEventListener('scroll', onScroll);
        return () => {
            if (el)
                el.removeEventListener('scroll', onScroll);
        }
    }, [onScroll]);


    return <>
        {(loadingLazy && mode === 'left') && <div className={styles.loading} data-position='left'>
            <LoadingBox loading={true}/>
        </div>}
        {loadingData && <div className={styles.loading} data-position='center'><LoadingBox loading={true}/></div>}
        {(loadingLazy && mode === 'right') && <div className={styles.loading} data-position='right'>
            <LoadingBox loading={true}/>
        </div>}
    </>;
};

export default connect(mapStateToProps, {
    lazyLoading: overviewActions.lazyLoadingRequest
})(Loading);