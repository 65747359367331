import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../Icon";
import {HARVEST_OPTION_KEY} from "../../pages/PreferencePage/utils";

interface IProps {
    keys?: any
    visible?: boolean
    operation: any
}

const Delay: React.FC<IProps> = (props) => {
    const {keys = {}, visible = false} = props;

    if (visible || keys[HARVEST_OPTION_KEY.ADD_DELAY] || !props.operation.delay) return null;

    return <div className={styles['delay']}>
        <Icon icon={ICON_NAME.ADD_DELAY}/>
    </div>;
};

export default Delay;
