import React from 'react';
import styles from "../../../../components/Operation/style.module.scss";
import Id from "../../../../components/Operation/Id";
import OperationStatus from "../../../../components/Operation/OperationStatus";
import Certain from "../../../../components/Operation/Certain";
import SiteUnit from "../../../../components/Operation/SiteUnit";
import NameSpot from "../../../../components/Operation/NameSpot";
import Duration from "../../../../components/Operation/Duration";
import {OP_STATUS, OPERATION_TYPE, SITE_TYPE} from "../../../../util/varibles/constants";
import Delay from "../../../../components/Operation/Delay";
import Weather from "../../../../components/Operation/Weather";
import FishDisease from "../../../../components/Location/FishDisease";
import NoteDetail from "../../../../components/Operation/NoteDetail";
import {IOperation} from "../../../../util/varibles/interface";
import Logo from "../../../../components/Operation/Logo";
import ExternalSite from "../../../../components/Operation/ExternalSite";
import LiceList from "../../../../components/Operation/LiceList";
import {getTypesAndUnitOfTreatment} from "../../util";

interface IProps {
    data: IOperation
    isOwn: boolean
    status: OP_STATUS
}

const TreatmentView: React.FC<IProps> = ({data, isOwn, status}) => {
    const {operation, activity_log} = data;
    const {tasks = []} = operation || {};
    const {types} = getTypesAndUnitOfTreatment(tasks);

    return <>
        <div className={styles.title}>
            <Logo type={OPERATION_TYPE.TREATMENT} status={status}/>
            <Id {...{visible: true, operation, status}}/>
            <OperationStatus {...{status}}/>
            <Certain {...{operation, status}}/>
            <SiteUnit {...{operation}}/>
            <div className={styles.right}>
                <ExternalSite {...{
                    type: operation.source_site_type === SITE_TYPE.EXTERNAL
                }}/>
                <Weather {...{visible: true, operation}}/>
                <Delay visible={true} operation={operation}/>
                {!isOwn && <NameSpot {...{visible: true, operation}}/>}
            </div>
        </div>
        <FishDisease diseases={operation.diseases} units={operation.sub_operations} wrapperClass={styles.section}/>
        <LiceList diseases={operation.diseases} short wrapperClass={styles.section}/>
        <div className={styles.section}>
            {Object.keys(types).map(key => types[key]).join(', ')}
            <Duration {...{status, activity_log}}/>
        </div>
        <NoteDetail operation={operation} visible={true}/>
    </>;
};

export default TreatmentView;
