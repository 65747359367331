export interface UserManagementState {
    users: any[]
    customers: any[]
    regions: any[]
    sites: any[]
    factories: any[]

    loadingUser: boolean
    loadingCustomer: boolean
    loadingSites: boolean
    loadingFactories: boolean
    loadingRegions: boolean
}

export const initialState: UserManagementState = {
    users: [],
    customers: [],
    regions: [],
    sites: [],
    factories: [],

    loadingUser: false,
    loadingCustomer: false,
    loadingSites: false,
    loadingFactories: false,
    loadingRegions: false,
}
