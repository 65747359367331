import React, {ReactNode, useMemo} from 'react';
import InputNumber from "../InputNumber";
import {Input, Select} from "antd";
import styles from './style.module.scss';
import {notify} from "../../../util/varibles/message";
import {IErrorForm} from "../../../util/varibles/interface";

interface IView {
    value: any
    suffix?: string | ReactNode
    placeholder?: string
    options?: {
        label: string
        value: any
    }[]

    inputClassName?: string

    onChange(value: any): void

    onBlur?(): void
}

const views: { [type: string]: (props: IView) => ReactNode } = {
    number: ({inputClassName = styles.input, ...props}) => <InputNumber
        {...props}
        className={inputClassName}
    />,
    text: ({inputClassName = 'h-32', onChange, ...props}) => <Input
        {...props}
        onChange={({target}) => onChange(target.value || '')}
        className={inputClassName}
    />,
    select: ({inputClassName = 'w-full h-32', ...props}) => <Select
        {...props}
        className={inputClassName}
    />
}

interface IProps extends IView {
    type?: 'number' | 'text' | 'select'
    className?: string,
    label?: string
    hideLabel?: boolean
    error?: IErrorForm
    require?: boolean
}

const InputForm: React.FC<IProps> = ({className, type = 'text', require, label, hideLabel, error = {}, ...args}) => {
    const view = useMemo(() => views[type], [type]);

    return <div className={[styles.box, className].join(' ')}>
        {!hideLabel && <div className={styles.label} data-lev='label' data-require={require}>{label}</div>}
        {view(args)}
        <div className={styles.error} data-lev='error' data-show={Object.keys(error).length > 0}>
            {Object.keys(error).map(key => notify[error[key]]([label]))}
        </div>
    </div>;
};

export default InputForm;