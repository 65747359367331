import React, {Component} from 'react';
import ContainerElement from "./ContainerElement";
import {IPropsElement} from "./constants";
import styles from './style.module.scss';

interface IProps extends IPropsElement {

}

class HeaderElement extends Component<IProps> {
    render() {
        const {isAllowSelect, label, properties: {subLabel, textAlign, fontSize}} = this.props;
        const style = {textAlign}
        return <ContainerElement {...this.props}>
            <div className={styles['header-element']} style={style}>
                <p data-font-size={fontSize}
                   data-is-empty={label.length === 0}>
                    {label || (isAllowSelect ? 'Heading text' : '')}
                </p>
                <p className={styles['sub-label']}>{subLabel || (isAllowSelect ? 'Sub label' : '')}</p>
            </div>
        </ContainerElement>;
    }
}

export default HeaderElement;
