import React, {useState} from 'react';
import styles from './style.module.scss';
import SwitchElementMode from "./SwitchElementMode";
import {ELEMENT_MODE} from "./constants";
import BasicElement from "./BasicElement";
import DefinedElement from "./DefinedElement";
import {IElement} from "../../constants";

interface IProps {
    onAdd(element: IElement, index: number | null): void
}

const LeftSide: React.FC<IProps> = ({onAdd}) => {
    const [activeType, setActiveType] = useState(ELEMENT_MODE.BASIC);

    return <div className={styles['left-side']}>
        <SwitchElementMode activeType={activeType} onClick={(activeType: ELEMENT_MODE) => setActiveType(activeType)}/>
        {activeType === ELEMENT_MODE.BASIC ? <BasicElement onAdd={onAdd}/> : <DefinedElement onAdd={onAdd}/>}
    </div>;
};

export default LeftSide;
