import React, {Component} from 'react';
import styles from "./style.module.scss";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";

interface IProps {
    error_detail: any
    style: any
}

class ErrorOfOperation extends Component<IProps> {
    static defaultProps = {
        style: {}
    }

    renderItem = (key: string, value: any) => {
        switch (key) {
            case 'route': {
                return value.map((item: any, index: number) => <div className={styles['error-line']} key={index}>
                    {item.message}.
                    <NavLink className={styles.url}
                             to={`/route?source=${item.source_id}&destination=${item.destination_id}`}>
                        Create one
                    </NavLink>
                </div>)
            }
            default:
                return <div {...{key}}>{value}</div>
        }
    }

    render() {
        const {error_detail = {}, style} = this.props;
        const count = Object.keys(error_detail).length;
        const isError = count > 0;
        return <div className={styles['error-message']} data-show={isError} style={style}>
            <div className={styles.icon}><ExclamationCircleOutlined/></div>
            <div>
                {Object.keys(error_detail).map(key => this.renderItem(key, error_detail[key]))}
            </div>
        </div>;
    }
}

export default ErrorOfOperation;
