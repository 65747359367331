import React, {ReactNode} from 'react';
import styles from "./style.module.scss";

interface IProps {
    children?: ReactNode
    visible?: boolean
}

const Dialog: React.FC<IProps> = ({children, visible}) => {
    if (!children)
        return null

    return <div className={styles['popup-container']} data-show={visible}>
        {visible && <div className={styles['popup-bubble-anchor']}>
            <div className={styles['popup-bubble']}>
                {children}
            </div>
        </div>}
    </div>
};

export default Dialog;
