import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IVessel} from "../../../../../util/varibles/interface";
import styles from './style.module.scss';
import {PlanSetting} from "../../../../../util/varibles/userSetting";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import PopupFilterVessel from "../../../../../components/Popup/PopupFilterVessel";
import {getSetting} from "../../../../../util/varibles/defaultSetting";
import {loginActions} from "../../../../LoginPage/reducer";
import {planOpActions} from "../../../reducer";
import {openPopup} from "../../../../../components/Popup/Component/WrapperPopup";
import {AppState} from "../../../../../util/store/store";

const key = PlanSetting.VESSEL_FILTER

const mapStateToProps = (state: AppState) => {
    const vesselFilter = getSetting(state.login.user.setting, key)
    return {
        vesselFilter,
        vessels: state.planOperation.vessels
    }
};

interface IProps {
    vessels: IVessel[]
    vesselFilter: string[]

    updateUserSetting(payload: any[]): void

    updateVessels(payload: IVessel[]): any
}

class VesselFilter extends Component<IProps> {

    onChange = (data: string[]) => {
        const {vessels} = this.props;
        const newVessels = vessels.map(item => ({...item, isShow: data.indexOf(item.id) !== -1}));
        this.props.updateVessels(newVessels);
        this.props.updateUserSetting([{[key]: data}]);
    };

    handleClear = (e: any) => {
        e.stopPropagation();
        const {vessels} = this.props;
        this.props.updateVessels(vessels.map(item => ({...item, isShow: true})));
        this.props.updateUserSetting([{[key]: []}]);
    }

    handleOpen = () => {
        const {vessels, vesselFilter} = this.props;
        const div = openPopup(<PopupFilterVessel {...{
            selected: vesselFilter,
            vessels,
            onSave: this.onChange,
            onClose: () => {
                div.remove();
            }
        }}/>)
    }

    render() {
        const {vessels, vesselFilter} = this.props;
        let count = vessels.filter(({isShow}) => isShow).length;

        if (vesselFilter.length === 0)
            count = 0

        return <div className={styles.button} onClick={this.handleOpen}>
            <Icon className={styles.icon} icon={ICON_NAME.VESSEL}/>
            <span className={styles.name}>Vessel filter</span>
            {count > 0 && <span className={styles.clear} onClick={this.handleClear}>
                <span>{[count, vessels.length].join('/')}</span>
                <Icon className={styles.close} icon={ICON_NAME.CLOSE} size='small'/>
            </span>}
        </div>;
    }
}

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting,
    updateVessels: planOpActions.updateVessels
})(VesselFilter);
