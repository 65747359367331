import {WEATHER_EL, WEATHER_TYPE} from "./util";
import {ISite, IWeather} from "../../util/varibles/interface";
import {notification} from "antd";
import styles from "./Component/Weather/Title/style.module.scss";

export interface WeatherState {
    weathers: IWeather[]
    indexId: number
    locations: {
        [id: string]: ISite
    }
    loadingList: boolean
    loadingLocations: boolean
    sections: {
        [WEATHER_TYPE.GLOBAL]: {
            isOpen: boolean
        }
        [WEATHER_TYPE.OPERATION]: {
            isOpen: boolean
        }
        [WEATHER_TYPE.SITE]: {
            isOpen: boolean
        }
    }
}

export const initialState: WeatherState = {
    weathers: [],
    indexId: 0,
    locations: {},
    loadingList: false,
    loadingLocations: false,
    sections: {
        global: {
            isOpen: true
        },
        operation: {
            isOpen: true
        },
        site: {
            isOpen: true
        }
    }
}

export const getCondition = (childNodes: any = []) => {
    let condition = ''
    let error = false;
    if (childNodes.length > 0 && childNodes[0].className !== 'text-comment') {
        childNodes.forEach((item: any) => {
            const {textContent} = item;
            const {role, value, invalid} = item.dataset;
            if (invalid === 'true')
                error = true;
            switch (role) {
                case WEATHER_EL.PARAM:
                    condition += value;
                    break;
                case WEATHER_EL.OPERATOR:
                    condition += ` ${value}`;
                    break;
                default:
                    condition += textContent;
            }
        })
    }
    if (error)
        notification.error({message: 'Invalid condition', className: styles.notification})
    return {condition, error};
}

export const getElementByRole = (role?: WEATHER_EL) => {
    const sel: any = window.getSelection();
    const list = [sel.anchorNode, sel.anchorNode.parentNode];
    return list.find(item => role ? item?.dataset?.role === role : item?.dataset?.role);
}