import React from 'react';
import styles from "../../../pages/PlanOperationPage/Popup/EditOp/Body/Treatment/Task/Treatment/style.module.scss";
import {operatorObject} from "../../../pages/WeatherPage/util";

interface IProps {
    id: string
    data: [[string, number] | null, [string, number] | null]

    onClick?(key: string, value: [string, number]): void
}

const ValueOfCondition: React.FC<IProps> = ({id, data: [first, last], onClick}) => {
    return <div className='flex items-center gap-1'>
        {first ? <>
            <div className={styles.operator} onClick={() => onClick && onClick(id, first)}>
                {operatorObject[first[0]]?.labelHtml}
            </div>
            <div>{first[1]}g</div>
        </> : null}
        {(first && last) && <div className='ml-1'>and</div>}
        {last ? <>
            <div className={styles.operator} onClick={() => onClick && onClick(id, last)}>
                {operatorObject[last[0]]?.labelHtml}
            </div>
            <div>{last[1]}g</div>
        </> : null}
    </div>;
};

export default ValueOfCondition;
