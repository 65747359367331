import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {
    FULL_DATE_FORMAT,
    KEY_TAB,
    OPERATION_TYPE,
    SITE_TYPE,
    YEAR_WEEK_FORMAT
} from "../../../../util/varibles/constants";
import stylesContainer from "../style.module.scss";
import styles from "./style.module.scss";
import {selectPlan, selectSetting} from "../../../../util/store/selectors";
import {useSelector} from "react-redux";
import {calculateWidth} from "../constants";
import Search from "./Filter/Search";
import AvgWeight from "../_Component/AvgWeight";
import Filter from "./Filter";
import DisplayMode from "./DisplayMode";
import AutoPlan from "./AutoPlan";
import List from "./List";
import ListHeader from "./List/ListHeader";
import {Checkbox} from "antd";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import {onFilter} from "./constants";
import {checkLimit} from "../../../../util/varibles/global";
import {datetime} from "../../../../util/library/datetime";
import Item from "./List/Item";
import GroupByUnit from "./GroupByUnit";
import GroupByWeek from "./GroupByWeek";
import {IUnit} from "../../../../util/varibles/interface";
import {callEventBiology, useBiology} from '../../../../contexts/PlanOperationContext/Biology';
import {setTabBiology, useTabBiology} from "../../../../contexts/PlanOperationContext/TabBiologyContext";

export enum EL_STHP {
    PLAN,
    UNIT,
    WEEK
}

interface IPropsItem {
    key: string,
    item: IUnit,
    getCount: () => any,
    children?: ReactNode

    onDragStart(e: React.DragEvent, units: { [id: string]: IUnit }, requestType: OPERATION_TYPE[]): void;

    onDragEnd(e: React.DragEvent): void;
}

const renderEl = {
    [EL_STHP.PLAN]: {
        getKey: (harvest: any) => [harvest.id, harvest.harvest_id].join('|'),
        convertData: (harvest: any, args?: any) => {
            const {fish_amount, total_weight, count, keyCount, isValid, isCheck} = args;
            return {
                ...harvest,
                isValid,
                isCheck,
                site_type: SITE_TYPE.INTERNAL,
                fish_amount,
                total_weight,
                count: count[keyCount],
            }
        },
        render: (args: IPropsItem) => {
            const {key, item, getCount, onDragStart, onDragEnd} = args;
            return <Item key={key} {...{
                data: {...item, site_type: SITE_TYPE.INTERNAL},
                className: styles['planned-item'],
                getCount,
                onDragStart: (e: any, units: any) => onDragStart(e, units, [OPERATION_TYPE.HARVEST]),
                onDragEnd,
            }}/>
        }
    },
    [EL_STHP.UNIT]: {
        getKey: (harvest: any) => [harvest.site_id, harvest.unit_id].join('|'),
        convertData: (harvest: any, args?: any) => {
            const {list, key, fish_amount, total_weight, isValid, isCheck} = args;
            const {site_id, site_name, unit_id, id} = harvest;
            const data = list[key];
            if (data) {
                if (isValid) {
                    data.checkAll = data.checkAll === null ? isCheck : (data.checkAll && isCheck);
                    data.indeterminate = data.indeterminate || (!data.checkAll && isCheck);
                }

                data.isActive = data.isActive || isValid;
                data.fish_amount += fish_amount;
                data.total_weight += total_weight;
                return data;
            } else {
                return {
                    isActive: isValid,
                    site_id,
                    site_name,
                    site_type: SITE_TYPE.INTERNAL,
                    id,
                    unit_id,
                    fish_amount,
                    total_weight,
                    diseases: harvest.diseases,
                    checkAll: isValid ? isCheck : null,
                    indeterminate: false,
                    child: {}
                }
            }
        },
        render: (args: IPropsItem) => {
            const {key, item, children, onDragStart, onDragEnd} = args;
            return <GroupByUnit key={key} {...{
                item,
                onDragStart: (e: any, units: any) => onDragStart(e, units, [OPERATION_TYPE.HARVEST]),
                onDragEnd,
            }}>
                {children}
            </GroupByUnit>
        }
    },
    [EL_STHP.WEEK]: {
        getKey: (harvest: any) => datetime(harvest.packing_date.replace(' ', 'T')).format(YEAR_WEEK_FORMAT),
        convertData: (harvest: any, args?: any) => {
            const {list, key, fish_amount, total_weight, isCheck, isValid} = args;
            const data = list[key];

            if (data) {
                if (isValid) {
                    data.checkAll = data.checkAll === null ? isCheck : (data.checkAll && isCheck);
                    data.indeterminate = data.indeterminate || (!data.checkAll && isCheck);
                }

                data.isActive = data.isActive || isValid;
                data.fish_amount += fish_amount;
                data.total_weight += total_weight;
                return data;
            } else {
                return {
                    key,
                    isActive: isValid,
                    weekNo: datetime(harvest.packing_date.replace(' ', 'T')).week,
                    fish_amount,
                    total_weight,
                    checkAll: isValid ? isCheck : null,
                    indeterminate: false,
                    child: {}
                }
            }
        },
        render: (args: IPropsItem) => {
            const {key, item, children} = args;
            return <GroupByWeek key={key} item={item}>
                {children}
            </GroupByWeek>
        }
    },
}


interface IState {
    isExpand: boolean
    checkAll: boolean
    indeterminate: boolean
    searchValue: string
    weightFilter: [number | null, number | null],
}

export const initState: IState = {
    isExpand: false,
    checkAll: false,
    indeterminate: false,
    searchValue: '',
    weightFilter: [null, null]
}

interface IProps {
    loading: boolean
}

const Planned: React.FC<IProps> = React.memo(({loading}) => {
    const [colPerRow, setColPerRow] = useState<number>(2);

    // const {ref} = useResizeDetector({
    //     handleHeight: false,
    //     refreshMode: 'debounce',
    //     refreshRate: 1000,
    //     onResize: () => setColPerRow(calculateWidth())
    // });

    const {
        [PlanSetting.GROUP_BY_WEEK]: groupByWeek,
        [PlanSetting.GROUP_BY_UNIT]: groupByUnit,
        [PlanSetting.SHOW_STHP_EMPTY]: showSTHPEmpty,
        [PlanSetting.STHP_PRIOR_WEEKS]: sthpPriorWeeks = true,
        [PlanSetting.STHP_SITE_FILTER]: plannedSiteFilter = [],
        [PlanSetting.STHP_FACTORY_FILTER]: plannedFactoryFilter = [],
        [PlanSetting.STHP_PROD_AREA_FILTER]: plannedProdAreaFilter = [],
    } = useSelector(selectSetting([
        PlanSetting.GROUP_BY_WEEK,
        PlanSetting.GROUP_BY_UNIT,
        PlanSetting.SHOW_STHP_EMPTY,
        PlanSetting.STHP_PRIOR_WEEKS,
        PlanSetting.STHP_SITE_FILTER,
        PlanSetting.STHP_FACTORY_FILTER,
        PlanSetting.STHP_PROD_AREA_FILTER
    ]))
    const source = useSelector(selectPlan.harvests);
    const fishChanges = useSelector(selectPlan.fishChanges);
    const routeIds = useSelector(selectPlan.routeIds);
    const [form, setForm] = useState(initState)
    const {onDragStart, onDragEnd} = callEventBiology();
    const [width, set] = useBiology(state => state.width);
    const setTab = setTabBiology();
    const [selected] = useTabBiology(state => state.selected);

    useEffect(() => {
        if (width)
            setColPerRow(calculateWidth())
    }, [width]);

    useEffect(() => {
        set(({clear}) => ({
            clear: {
                ...clear, harvest: () => {
                    setTab({selected: {}})
                    setForm(prev => ({...prev, checkAll: false, indeterminate: false}))
                }
            }
        }))
    }, [])

    const harvests = useMemo(() => {
        return onFilter({
            ...form,
            fishChanges,
            routeIds,
            harvests: source,
            plannedFactoryFilter,
            plannedProdAreaFilter,
            plannedSiteFilter,
            showSTHPEmpty,
            sthpPriorWeeks,
        });
    }, [fishChanges, routeIds, source, plannedFactoryFilter, plannedProdAreaFilter, plannedSiteFilter, showSTHPEmpty, sthpPriorWeeks, form]);

    const handleChangeCheckAll = (e: any) => {
        const {checked} = e.target;
        if (checked) {
            setTab(({selected}) => ({
                selected: harvests.reduce((list: any, item: any) => {
                    const {id, harvest_id, fish_amount, isRoute} = item;
                    if (fish_amount <= 0 || !isRoute)
                        return list;
                    list[id + '|' + harvest_id] = item;
                    return list;
                }, selected)
            }))
        } else
            setTab({selected: {}})
        setForm(prev => ({...prev, checkAll: checked, indeterminate: false}))
    }

    const clear = () => {
        setTab({selected: {}})
        setForm(initState)
    }

    const renderContent = () => {
        const count: any = {};
        const childProps = {
            getCount: () => count,
            onDragStart,
            onDragEnd
        }

        let action: EL_STHP[] = [EL_STHP.PLAN];

        const render = () => harvests.reduce((rs: any, item) => {
            const {factory_id, id, harvest_id = '-'} = item;
            const fish_amount = checkLimit(0, undefined, item.fish_amount);
            const keyCount = [datetime(item.packing_date).format(FULL_DATE_FORMAT), factory_id].join('|');
            const {[keyCount]: index = 0} = count;
            count[keyCount] = index + 1;
            const common = {
                fish_amount,
                total_weight: item.avg_weight * fish_amount,
                isValid: fish_amount > 0 && item.isRoute,
                isCheck: !!selected[[id, harvest_id].join('|')],
                keyCount,
                count
            }

            let list = rs;
            action.forEach(sub => {
                const key = renderEl[sub].getKey(item)
                list[key] = renderEl[sub].convertData(item, {list, key, ...common});
                list = list[key].child;
            })

            return rs;
        }, {});
        const view = (source: any = {}, action: EL_STHP[], index = 0): ReactNode[] =>
            Object.keys(source).reduce((rs: ReactNode[], key) => {
                const item = source[key];
                const args = {
                    ...childProps,
                    key,
                    item,
                    children: view(item.child, action, index + 1),
                    getCount: () => count,
                }
                return [...rs, renderEl[action[index]].render(args)];
            }, []);

        if (groupByUnit)
            action = [EL_STHP.UNIT, ...action]

        if (groupByWeek)
            action = [EL_STHP.WEEK, ...action]

        return view(render(), action);
    }
    const {checkAll, indeterminate, weightFilter} = form;

    const handleUpdateForm = (value: { searchValue?: string, weightFilter?: [number | null, number | null] }) => {
        setForm(prev => ({...prev, value}))
    }

    return <div id={KEY_TAB.HARVEST} className={stylesContainer['wrapper-tab']}>
        <div className={stylesContainer['tab-header']}>
            <div className={stylesContainer['header-left']} data-operaion-type='harvest'>
                <Search search={value => handleUpdateForm({searchValue: value})}/>
                <AvgWeight weight={weightFilter} onChange={value => handleUpdateForm({weightFilter: value})}/>
            </div>
            <div className={stylesContainer['header-right']}>
                <Filter/>
                <DisplayMode/>
                <AutoPlan clear={clear}/>
            </div>
        </div>
        <div className={stylesContainer['tab-body']}>
            <List {...{className: stylesContainer.body, colPerRow, loading, list: harvests}}>
                <ListHeader  {...{
                    colPerRow,
                    columns: [
                        {title: 'Id', className: 'text-truncate pr-2'},
                        {title: 'Harvest Date', className: 'text-truncate text-center'},
                        {title: 'Factory Date', className: 'text-truncate text-center'},
                        {title: 'Site, Unit', className: 'text-truncate pl-2'},
                        {title: 'Factory', className: 'text-truncate pl-2'},
                        {title: 'Count', className: 'text-truncate pr-2 text-right'},
                        {title: 'Avg Weight', className: 'text-truncate pr-2 text-right'},
                        {title: 'Biomass', className: 'text-truncate text-right'},
                    ],
                    titleClassName: styles['title-plan']
                }}>
                    <Checkbox {...{
                        checked: checkAll,
                        indeterminate,
                        disabled: loading,
                        onChange: handleChangeCheckAll
                    }}/>
                </ListHeader>
                {renderContent()}
            </List>
        </div>
    </div>
}, (prev, next) => prev.loading === next.loading);

export default Planned;


