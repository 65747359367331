import {ILocation, IRoute} from "../../util/varibles/interface";
import GoogleMap from "../../util/library/googlemap/googlemap";

export enum RouteMode {
    VIEW = 'view',
    EDIT = 'edit'
}

export interface RouteState {
    loading: {
        routes: boolean
    }
    params: any
    routes: IRoute[]
    locations: ILocation[]
    showId: string
    map?: GoogleMap

    loadingLocations: boolean
    loadingAuto: boolean
    mode: RouteMode,

    isShowAll: boolean

    route: any
    requestId: string
}

export const initialState: RouteState = {
    loading: {
        routes: false,
    },
    params: {
        source: null,
        destination: null,
    },
    routes: [],
    showId: '',
    locations: [],

    loadingLocations: false,
    loadingAuto: false,

    isShowAll: false,
    mode: RouteMode.VIEW,

    route: {},
    requestId: ''
};
