import React from 'react';
import {Tooltip} from "antd";
import styles from "../../style.module.scss";
import Loading from "../../../../Loading";
import {useOperation} from "../../../../../../../../contexts/OperationContext";
import {getElById} from "../../../../../../../../util/varibles/global";

interface IProps {
    id: string
    message?: string
}

const LoadingOperation: React.FC<IProps> = ({id, message}) => {
    const [modeDrag] = useOperation(state => state.modeDrag);
    const [loading] = useOperation(state => state.loading);
    if (modeDrag === 'move')
        return null;

    const propsTooltip: any = {
        title: message,
        getPopupContainer: () => getElById(id),
    }

    if (modeDrag.length > 0)
        propsTooltip.open = false;

    return <Tooltip {...propsTooltip}>
        <div className={styles.topLayer} data-operation-id={id}>
            <Loading id={id + '-loading'} loading={loading}/>
        </div>
    </Tooltip>
};

export default LoadingOperation;
