import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IWeather} from "../../util/varibles/interface";
import styles from "./style.module.scss";
import {WEATHER_TYPE} from "./util";
import {weatherActions} from "./reducer";
import {AppState} from "../../util/store/store";
import Global from "./Global";
import OperationTypes from "./OprationTypes";
import Sites from "./Sites";
import Weather from "./Component/Weather";

export const renderWeathers = (value: any, opts?: any[]) => value.map((item: any) => <Weather
    key={item.id}
    data={item}
    opts={opts}
/>);

const views: any = {
    [WEATHER_TYPE.GLOBAL]: {
        render: (data: any[]) => <Global key={0} weathers={data}/>
    },
    [WEATHER_TYPE.OPERATION]: {
        render: (data: any[]) => <OperationTypes key={1} weathers={data}/>
    },
    [WEATHER_TYPE.SITE]: {
        render: (data: any[]) => <Sites key={2} weathers={data}/>
    },
}


const mapStateToProps = (state: AppState) => ({
    weathers: state.weather.weathers
});

interface IProps {
    weathers: IWeather[]

    getWeathers(): void
}

class WeatherPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.props.getWeathers();
    }

    render() {
        const {weathers} = this.props;
        const group = weathers.reduce((data: any, item: any) => {
            const {type} = item;
            data[type] = [...data[type] || [], item]
            return data;
        }, {})


        return <div className="m-header bgPage">
            <div className='header-page'>
                <div className='title-page'>
                    <b>Weather Management</b>
                </div>
            </div>
            <div className='body-page' data-box={true}>
                <div className={styles.content}>
                    {/*<div className='d-flex justify-content-end mt-12 mb-12'>*/}
                    {/*    <Filter/>*/}
                    {/*    <div className='ml-3'>*/}
                    {/*        <SearchComponent/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <ul className='pl-0 mb-2'>
                        <li>Set condition for Global limit to show weather in Plan Operation, Operation overview,
                            Dashboard.
                        </li>
                        <li>Set condition for specific operation type or site to have their own weather condition (These
                            conditions will override global one).
                        </li>
                    </ul>
                    {Object.keys(views).map(key => {
                        const {render} = views[key];
                        const {[key]: value = []} = group || {};
                        return render(value);
                    })}
                </div>
            </div>
            <div id='weather-suggestions' className={styles.menu}/>
        </div>;
    }
}

export default connect(mapStateToProps, {
    getWeathers: weatherActions.getListRequest
})(WeatherPage);
