import React from 'react';
import {Radio} from "antd";

const values = ['2022', '2023', '2024'];

interface IProps {
    disabled?: boolean
    year: string

    onChange(year: string): void
}

const Year: React.FC<IProps> = ({disabled, year, onChange}) => {
    return <Radio.Group disabled={disabled} onChange={({target}) => onChange(target.value)} value={year}>
        {values.map(value => <Radio className="text-[15px]" key={value} value={value}>{value}</Radio>)}
    </Radio.Group>;
};

export default Year;
