import {USER_ROLE} from "./constants";

export enum VIEW_MODE {
    PLANNER = 'screen/planner',
    VESSEL = 'screen/vessel'
}

export const viewMode = {
    [VIEW_MODE.PLANNER]: {id: VIEW_MODE.PLANNER, name: 'Planner mode'},
    [VIEW_MODE.VESSEL]: {id: VIEW_MODE.VESSEL, name: 'Vessel mode'},
}

export const viewModes = [
    viewMode[VIEW_MODE.PLANNER],
    viewMode[VIEW_MODE.VESSEL],
]

const prefix = {
    planOp: 'plan_operation/',
    overview: 'overview/',
    dashboard: 'dashboard/',
    messenger: 'messenger/',
    opDetail: 'op_detail/',
    opSetting: 'op_setting',

    operation: 'operation/',
    checklist: 'checklist/',
    combineTreatment: 'combine_treatment/',
    route: 'route/',
    vessel: 'vessel/',
    weather: 'weather/',
    user: 'user/',
    tenant: 'tenant/',
    factory: 'factory/',
    disease: 'disease/',
    preference: 'preference/',
    global: 'global/',
    vesselSide: 'vessel_side/',
    subscribe_email: 'subscribe_email/',
    location: 'location/',
    equipment: 'equipment/'
}

const PLAN_OP = {
    VIEW: prefix.planOp + 'view',
    AUTO_PLAN: prefix.planOp + 'auto',
    DRAG_PLAN: prefix.planOp + 'drag_plan',
    PLAN_MENU: prefix.planOp + 'plan_menu',

    VESSEL_MENU: prefix.planOp + 'vessel_menu',
    OP_MENU: prefix.planOp + 'op_menu',

    CLEAR: prefix.planOp + 'clear',
    DRAG_OP: prefix.planOp + 'drag_op',
    EXPORT_FILE: prefix.planOp + 'export_file',
    SAVE: prefix.planOp + 'save',
    SEND: prefix.planOp + 'send',
}

const OVERVIEW = {
    VIEW: prefix.overview + 'view',
}

const DASHBOARD = {
    VIEW: prefix.dashboard + 'view',
    LEFT_CONTROL: prefix.dashboard + 'left_control',
    TOP_CONTROL: prefix.dashboard + 'top_control',
    RIGHT_CONTROL: prefix.dashboard + 'right_control',
    SEARCH: prefix.dashboard + 'search',
    TRACKING_VESSEL: prefix.dashboard + 'tracking_vessel',
    MAP_STYLE: prefix.dashboard + 'map_style',
}

const MESSENGER = {
    VIEW: prefix.messenger + 'view',
}

const OP_DETAIL = {
    VIEW: prefix.opDetail + 'view',
}

const OPERATION = {
    CREATE: prefix.operation + 'create',
    UPDATE: prefix.operation + 'update',
    DELETE: prefix.operation + 'delete',
}

const CHECKLIST = {
    VIEW: prefix.checklist + 'view',
    CREATE: prefix.checklist + 'create',
    UPDATE: prefix.checklist + 'update',
    DELETE: prefix.checklist + 'delete'
}

const COMBINE_TREATMENT = {
    VIEW: prefix.combineTreatment + 'view',
    CREATE: prefix.combineTreatment + 'create',
    UPDATE: prefix.combineTreatment + 'update',
    DELETE: prefix.combineTreatment + 'delete',
    GLOBAL: prefix.combineTreatment + 'global',
}

const ROUTE = {
    VIEW: prefix.route + 'view',
    CREATE: prefix.route + 'create',
    UPDATE: prefix.route + 'update',
    DELETE: prefix.route + 'delete'
}

const VESSEL = {
    VIEW: prefix.vessel + 'view',
    CREATE: prefix.vessel + 'create',
    UPDATE: prefix.vessel + 'update',
    DELETE: prefix.vessel + 'delete'
}

const WEATHER = {
    VIEW: prefix.weather + 'view',
    CREATE: prefix.weather + 'create',
    UPDATE: prefix.weather + 'update',
    DELETE: prefix.weather + 'delete'
}

const USER = {
    VIEW: prefix.user + 'view',
    CREATE: prefix.user + 'create',
    UPDATE: prefix.user + 'update',
    DELETE: prefix.user + 'delete',
}

const TENANT = {
    VIEW: prefix.tenant + 'view',
    CREATE: prefix.tenant + 'create',
    UPDATE: prefix.tenant + 'update',
    DELETE: prefix.tenant + 'delete',
    GENERATE_SITES: prefix.tenant + 'generate-sites',
}

const FACTORY = {
    VIEW: prefix.factory + 'view',
    CREATE: prefix.factory + 'create',
    UPDATE: prefix.factory + 'update',
    DELETE: prefix.factory + 'delete'
}

const DISEASE = {
    VIEW: prefix.disease + 'view',
    CREATE: prefix.disease + 'create',
    UPDATE: prefix.disease + 'update',
    DELETE: prefix.disease + 'delete'
}

const PREFERENCE = {
    VIEW: prefix.preference + 'view',
    PLAN: prefix.preference + 'plan',
    HARVEST_CUSTOMIZE: prefix.preference + 'harvest_customize',
    OP_CERTAIN: prefix.preference + 'op_certain',
    WORK_TIME: prefix.preference + 'work_time',
    CONFIGURE_PLAN: prefix.preference + 'config_plan',
    VESSEL_APP: prefix.preference + 'vessel_app',
    MODE: prefix.preference + 'mode',
}

const SUBSCRIBE_EMAIL = {
    VIEW: prefix.subscribe_email + 'view',
    OPERATION: prefix.subscribe_email + 'op',
    VESSEL: prefix.subscribe_email + 'vessel',
}

const GLOBAL = {
    SEARCH: prefix.global + 'search'
}

const VESSEL_SIDE = {
    UPDATE: prefix.vesselSide + 'update'
}

const OP_SETTING = {
    VIEW: prefix.opSetting + 'view',
    CREATE: prefix.opSetting + 'create',
    UPDATE: prefix.opSetting + 'update',
    DELETE: prefix.opSetting + 'delete',
}

const EQUIPMENT = {
    VIEW: prefix.equipment + 'view',
    CREATE: prefix.equipment + 'create',
    UPDATE: prefix.equipment + 'update',
    DELETE: prefix.equipment + 'delete',
}

const LOCATION = {
    VIEW: prefix.location + 'view',
    CREATE: prefix.location + 'create',
    UPDATE: prefix.location + 'update',
    DELETE: prefix.location + 'delete',
}

export const ACTION = {
    PLAN_OP,
    OVERVIEW,
    DASHBOARD,
    MESSENGER,
    OP_DETAIL,
    OPERATION,
    CHECKLIST,
    COMBINE_TREATMENT,
    ROUTE,
    VESSEL,
    WEATHER,
    USER,
    TENANT,
    FACTORY,
    PREFERENCE,
    GLOBAL,
    VESSEL_SIDE,
    DISEASE,
    OP_SETTING,
    SUBSCRIBE_EMAIL,
    LOCATION,
    EQUIPMENT
}

export const getViewMode = (actions: any, mode?: VIEW_MODE): VIEW_MODE => {
    if (mode && actions[mode])
        return mode;
    const data = viewModes.find(item => actions[item.id]) || {id: VIEW_MODE.PLANNER};
    return data.id
}

const generatePermissions = (list: any) => Object.keys(list).reduce((rs: any, key) => {
    rs[list[key]] = true;
    return rs;
}, {});

export const permissions: any = {
    [USER_ROLE.SUPER_ADMIN]: {
        ...generatePermissions(PLAN_OP),
        ...generatePermissions(OVERVIEW),
        ...generatePermissions(DASHBOARD),
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        ...generatePermissions(OPERATION),
        ...generatePermissions(CHECKLIST),
        ...generatePermissions(COMBINE_TREATMENT),
        ...generatePermissions(ROUTE),
        ...generatePermissions(VESSEL),
        ...generatePermissions(WEATHER),
        ...generatePermissions(USER),
        ...generatePermissions(TENANT),
        ...generatePermissions(FACTORY),
        ...generatePermissions(DISEASE),
        ...generatePermissions(PREFERENCE),
        ...generatePermissions(SUBSCRIBE_EMAIL),
        ...generatePermissions(GLOBAL),
        ...generatePermissions(VESSEL_SIDE),
        ...generatePermissions(OP_SETTING),
        ...generatePermissions(LOCATION),
        ...generatePermissions(EQUIPMENT),
        [VIEW_MODE.PLANNER]: true,
        [VIEW_MODE.VESSEL]: true
    },
    [USER_ROLE.ADMIN]: {
        ...generatePermissions(PLAN_OP),
        ...generatePermissions(OVERVIEW),
        ...generatePermissions(DASHBOARD),
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        ...generatePermissions(OPERATION),
        ...generatePermissions(CHECKLIST),
        ...generatePermissions(COMBINE_TREATMENT),
        ...generatePermissions(ROUTE),
        ...generatePermissions(VESSEL),
        ...generatePermissions(WEATHER),
        ...generatePermissions(USER),
        ...generatePermissions(FACTORY),
        ...generatePermissions(DISEASE),
        ...generatePermissions(PREFERENCE),
        ...generatePermissions(SUBSCRIBE_EMAIL),
        ...generatePermissions(GLOBAL),
        ...generatePermissions(VESSEL_SIDE),
        ...generatePermissions(OP_SETTING),
        ...generatePermissions(LOCATION),
        ...generatePermissions(EQUIPMENT),
        [VIEW_MODE.PLANNER]: true,
        [VIEW_MODE.VESSEL]: true
    },
    [USER_ROLE.CUSTOMER]: {
        ...generatePermissions(PLAN_OP),
        ...generatePermissions(OVERVIEW),
        ...generatePermissions(DASHBOARD),
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        ...generatePermissions(OPERATION),
        ...generatePermissions(CHECKLIST),
        [COMBINE_TREATMENT.VIEW]: true,
        [COMBINE_TREATMENT.CREATE]: true,
        [COMBINE_TREATMENT.UPDATE]: true,
        [COMBINE_TREATMENT.DELETE]: true,
        [VESSEL.VIEW]: true,
        ...generatePermissions(ROUTE),
        ...generatePermissions(WEATHER),
        [FACTORY.VIEW]: true,
        ...generatePermissions(DISEASE),
        ...generatePermissions(PREFERENCE),
        ...generatePermissions(OP_SETTING),
        ...generatePermissions(LOCATION),
        [SUBSCRIBE_EMAIL.VIEW]: true,
        [SUBSCRIBE_EMAIL.OPERATION]: true,
        [GLOBAL.SEARCH]: true,
        [VIEW_MODE.PLANNER]: true,
    },
    [USER_ROLE.VESSEL_OWNER]: {
        ...generatePermissions(PLAN_OP),
        ...generatePermissions(OVERVIEW),
        ...generatePermissions(DASHBOARD),
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        ...generatePermissions(COMBINE_TREATMENT),
        ...generatePermissions(VESSEL),
        ...generatePermissions(VESSEL_SIDE),
        [PREFERENCE.VIEW]: true,
        [PREFERENCE.MODE]: true,
        [SUBSCRIBE_EMAIL.VIEW]: true,
        [SUBSCRIBE_EMAIL.VESSEL]: true,
        [GLOBAL.SEARCH]: true,
        [VIEW_MODE.PLANNER]: true,
    },
    [USER_ROLE.VESSEL_VIEWER]: {
        ...generatePermissions(OVERVIEW),
        [DASHBOARD.VIEW]: true,
        [DASHBOARD.TOP_CONTROL]: true,
        [DASHBOARD.TRACKING_VESSEL]: true,
        [DASHBOARD.MAP_STYLE]: true,
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        [PREFERENCE.VIEW]: true,
        [PREFERENCE.MODE]: true,
        [VIEW_MODE.VESSEL]: true
    },
    [USER_ROLE.CREW]: {
        ...generatePermissions(OVERVIEW),
        [DASHBOARD.VIEW]: true,
        [DASHBOARD.TOP_CONTROL]: true,
        [DASHBOARD.TRACKING_VESSEL]: true,
        [DASHBOARD.MAP_STYLE]: true,
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        ...generatePermissions(VESSEL_SIDE),
        [PREFERENCE.VIEW]: true,
        [PREFERENCE.PLAN]: true,
        [PREFERENCE.OP_CERTAIN]: true,
        [PREFERENCE.MODE]: true,
        [VIEW_MODE.VESSEL]: true
    },
    [USER_ROLE.SITE_MANAGER]: {
        ...generatePermissions(OVERVIEW),
        ...generatePermissions(DASHBOARD),
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        [SUBSCRIBE_EMAIL.VIEW]: true,
        [VIEW_MODE.PLANNER]: true,
    },
    [USER_ROLE.FACTORY_MANAGER]: {
        ...generatePermissions(OVERVIEW),
        ...generatePermissions(DASHBOARD),
        ...generatePermissions(MESSENGER),
        ...generatePermissions(OP_DETAIL),
        [SUBSCRIBE_EMAIL.VIEW]: true,
        [VIEW_MODE.PLANNER]: true,
    },
}

export function generateActions(roles: USER_ROLE[] = []) {
    return roles.reduce((rs: any, item) => ({...rs, ...permissions[item] || {}}), {})
}

