import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import {debounce} from "debounce";
import {KEY_STORAGE} from "../varibles/constants";
import {createStateSyncMiddleware, initMessageListener} from 'redux-state-sync';
import {loginActions} from "../../pages/LoginPage/reducer";
import {messageActions} from "../../pages/MessagePage/reducer";

const customState = (state: AppState) => {
    const {login} = state;
    return {login};
}

const saveState = (state: AppState) => {
    try {
        const data = customState(state);
        const serializedState = JSON.stringify(data);
        localStorage.setItem(KEY_STORAGE.STATE, serializedState);
    } catch (error) {
        console.log(error)
    }
};

const loadState = () => {
    try {
        const serializedState = localStorage.getItem(KEY_STORAGE.STATE);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

const sagaMiddleware = createSagaMiddleware();

const config = {
    whitelist: [
        // login actions
        loginActions.getUserSuccess.type,
        loginActions.logout.type,
        loginActions.updateUserSetting.type,

        // route actions
        // ADD_ROUTE_PLAN,

        // plan actions
        // CREATE_OP_SUCCESS,
        // DELETE_OPERATIONS_REQUEST,
        // UPDATE_TREATMENT_OPERATION,
        // ADD_EVENT_OPERATION,
        // UPDATE_EVENT_OPERATION,

        // message actions
        messageActions.readMessageRequest.type,
        messageActions.updateMessage.type,
    ],
};

export const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadState(),
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            serializableCheck: false,
            thunk: false
        }),
        createStateSyncMiddleware(config),
        sagaMiddleware
    ],
});

initMessageListener(store);

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

store.subscribe(debounce(() => saveState(store.getState()), 800));