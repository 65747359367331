import React from 'react';
import {elementBasics, elementPages} from "../../Element/constants";
import ElementComponent from "./ElementComponent";
import {IElement} from "../../constants";

interface IProps {
    onAdd(element: IElement, index: number | null): void
}

const BasicElement: React.FC<IProps> = ({onAdd}) => {
    return <>
        {[...elementBasics, ...elementPages].map(item => <ElementComponent
            {...item}
            key={item.id}
            onAdd={onAdd}
        />)}
    </>;
};

export default BasicElement;
