import React from 'react';
import styles from "./style.module.scss";
import {durationTxt} from "../../../util/varibles/global";

interface IProps {
    duration: number
}

const FreshWaterDuration: React.FC<IProps> = ({ duration }) => {
    if (!duration)
        return null;

    return <div className={styles['unit-note']}>
        <div className={styles['operation-info-title']}>
            Fresh water: <span className={styles['operation-info-content']}>{durationTxt(duration)}</span>
        </div>
    </div>

}
export default FreshWaterDuration;