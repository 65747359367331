import React from 'react';
import {diseasedZone, diseaseType} from "../../../util/varibles/constants";
import styles from './style.module.scss';
import DiseaseStatus from "./DiseaseStatus";
import {IUnit} from "../../../util/varibles/interface";
import Icon, {ICON_NAME} from "../../Icon";

interface IProps {
    diseases: any
    units: IUnit[]
    short?: boolean
    wrapperClass?: string
}

const FishDisease: React.FC<IProps> = ({diseases = [], short = false, units = [], wrapperClass}) => {
    if (!Array.isArray(diseases))
        return null;

    const ids = new Set();
    const sites = units.reduce((list: any, item: any) => {
        const {site_id, site_name} = item;
        if (!ids.has(site_id)) {
            ids.add(site_id);
            return [...list, {site_id, site_name}];
        }
        return list;
    }, []);

    diseases.forEach((item: any) => {
        const {id, name} = item || {};
        if (!id)
            return;
        const [, site_id] = id.split('#');
        if (!ids.has(site_id)) {
            ids.add(site_id);
            sites.push({site_id, site_name: name});
        }
    })

    const items = sites.reduce((list: any, item: any, i: number) => {
        const {site_id, site_name} = item;
        const disease = diseases.find((sub: any) => (sub?.site_id || '').indexOf(site_id) !== -1) || {};
        const {unit_diseases} = units.find(sub => sub.site_id === site_id) || {};
        const {types = []} = unit_diseases || {};
        const {cases = [], diseases: subDiseases = []} = disease;
        const {disease: subDisease = null, status = ''} = cases[0] || {};

        let {logo, diseaseNames} = subDiseases.reduce((rs: any, sub: any, index: number) => {
            const {shortName = '', color = ''} = diseasedZone[sub.type] || {};
            if (color.length > 0) {
                const style: any = {'--diseased-color': color};
                rs.logo.push(<div key={index} className={styles.disease} style={style}/>);
            }

            rs.diseaseNames.push(shortName);
            return rs;
        }, {logo: [], diseaseNames: []})
        if (short) {
            diseaseNames = []
        } else {
            if (subDisease) {
                const {name: typeName} = diseaseType[subDisease];
                diseaseNames.push(`${typeName} ${status}`);
            }
            diseaseNames = [...diseaseNames, ...types.map((item: any) => item.name)];
        }
        if (logo.length === 0 && diseaseNames.length === 0 && !subDisease)
            return list;
        return [...list, <div key={i} className={styles.line}>
            {logo.length > 0 && <div className={styles.logo}>{logo}</div>}
            {subDisease && <div className='ml-5'>
                <DiseaseStatus status={subDisease}/>
            </div>}
            {diseaseNames.length > 0 && <div className={styles.name}>
                {site_name}:
            </div>}
            <div className={styles.diseases}>
                {diseaseNames.join(', ')}
            </div>
        </div>]
    }, []);

    if (items.length === 0)
        return null;

    const content = <div className={styles.wrapper} data-short={short} data-size={items.length}>
        <Icon icon={ICON_NAME.DISEASE} size='large'/>
        {items}
    </div>

    return wrapperClass ? <div className={wrapperClass}>{content}</div> : content
};

export default FishDisease;