import {CONVERSATION_MODE, initialState, tabMessage,} from './constants'
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {KEY_STORAGE, MESSAGE_STATUS} from "../../util/varibles/constants";
import {checkLimit} from "../../util/varibles/global";
import {IConversation} from "../../util/varibles/interface";


const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        getChatRequest: (state, action) => {
            const {index, isClear} = action.payload;
            state.loadingChat = true;
            state.chatIndex = index
            if (isClear)
                state.chat = [];
        },
        getChatSuccess: (state, action) => {
            const {notifications, prev, count_new} = action.payload;
            state.chat = notifications.reduce((list: any, item: any) => {
                const isExist = list.some((sub: any) => sub.group_chat.id === item.group_chat.id)
                return isExist ? list : [...list, item];
            }, state.chat);
            state.chatMore = prev;
            state.chatNew = count_new;
            state.loadingChat = false;
        },
        getChatFailure: (state) => {
            state.loadingChat = false;
        },
        getChatOfOpRequest: (state, action) => {
            const {index, isClear} = action.payload;
            state.operationsIndex = index
            if (isClear)
                state.operations = [];
            state.loadingOperation = true
        },
        getChatOfOpSuccess: (state, action) => {
            const {notifications, prev, count_new} = action.payload;
            state.operations = notifications.reduce((list: any, item: any) => {
                const isExist = list.some((sub: any) => sub.group_chat.id === item.group_chat.id)
                return isExist ? list : [...list, item];
            }, state.operations);
            state.operationsMore = prev;
            state.operationsNew = count_new;
            state.loadingOperation = false
        },
        getChatOfOpFailure: (state) => {
            state.loadingOperation = false
        },
        sendMessageRequest: (state: any, action) => {
            const params = action.payload;
            const {messages} = state;
            const {group_id, type} = params;
            const {keyData} = tabMessage[type];
            const {[keyData]: list}: any = state;
            let target = {};
            const prev = list.reduce((rs: any, item: any) => {
                if (item.group_id === group_id) {
                    target = item;
                    return rs;
                }
                return [...rs, item]
            }, []);
            state[keyData] = [{...target, message: params}, ...prev];
            state.messages = {
                ...messages,
                [group_id]: {
                    ...messages[group_id],
                    messages: [...messages[group_id].messages, {...params, sending_status: MESSAGE_STATUS.SENDING}]
                }
            }
        },
        sendMessageSuccess: (state, action) => {
            const {params, data} = action.payload;
            const {group_id} = data;
            const {uuid} = params;
            const {messages} = state;
            state.messages = {
                ...messages,
                [group_id]: {
                    ...messages[group_id],
                    messages: messages[group_id].messages.map((item: any) => item.uuid === uuid ? {
                        ...data.message,
                        sending_status: MESSAGE_STATUS.SENT
                    } : item)
                }
            }
        },
        sendMessageFailure: (state, action) => {
            const params = action.payload;
            const {group_id, uuid} = params;
            const {messages} = state;
            state.messages = {
                ...messages,
                [group_id]: {
                    ...messages[group_id],
                    messages: messages[group_id].messages.map((item: any) => item.uuid === uuid ? {
                        ...params,
                        sending_status: MESSAGE_STATUS.ERROR
                    } : item)
                }
            }
        },
        getMessagesRequest: (state, action) => {
            const {id} = action.payload;
            const {messages} = state;
            state.messages = {...messages, [id]: {...messages[id] || {id}, loading: true}};
        },
        getMessagesSuccess: (state, action) => {
            const {chatId, data} = action.payload;
            const {messages: old} = state;
            const {messages, prev} = data;
            state.messages = {
                ...old,
                [chatId]: {
                    ...old[chatId],
                    messages: [
                        ...messages,
                        ...old[chatId].messages || []
                    ],
                    prev,
                    loading: false
                }
            };
        },
        getMessagesFailure: (state, action) => {
            const chatId = action.payload;
            const {messages} = state;
            state.messages = {...messages, [chatId]: {...messages[chatId], loading: false}}
        },
        addMessage: (state: any, action: PayloadAction<IConversation>) => {
            const message = action.payload;
            const {messages} = state;
            const {group_id, type} = message;
            const {keyData, keyNew} = tabMessage[type];
            const {[keyData]: list, [keyNew]: count}: any = state;
            const index = list.findIndex((item: any) => item.group_id === group_id);
            if (index !== -1) {
                const {status: oldStatus} = list[index];
                if (oldStatus !== MESSAGE_STATUS.NEW) {
                    state[keyNew] = count + 1;
                }
                state[keyData] = list.reduce((rs: any, item: any) => {
                    if (item.group_id === group_id)
                        return rs;
                    return [...rs, item]
                }, [message])
            } else {
                state[keyNew] = count + 1;
                state[keyData] = [message, ...list];
            }

            state.messages = messages[group_id] ? {
                ...messages,
                [group_id]: {
                    ...messages[group_id],
                    messages: [
                        ...messages[group_id].messages,
                        {...message.message, status: MESSAGE_STATUS.NEW}
                    ]
                }
            } : messages
        },
        updateMessage: (state: any, action: PayloadAction<IConversation>) => {
            const {message, group_id, chat_id, status, type} = action.payload;
            const {keyData, keyNew} = tabMessage[type];
            const {[keyData]: list, [keyNew]: count, messages}: any = state;
            state[keyData] = list.map((item: any) => {
                if (item.group_chat.id === group_id) {
                    if (item.status !== MESSAGE_STATUS.NEW && status === MESSAGE_STATUS.NEW)
                        state[keyNew] = count + 1;

                    return {...item, message: {...item.message, message}}
                }
                return item
            });

            state.messages = {
                ...messages,
                [group_id]: {
                    ...messages[group_id],
                    messages: messages[group_id].messages.map((item: any) => item.chat_id === chat_id
                        ? {...item, message}
                        : item)
                }
            }
        },
        readMessageRequest: (state: any, action) => {
            const data = action.payload;
            const {id} = data.message || {};
            if (id) {
                const {keyData = null, keyNew = null} = tabMessage[data.type] || {};
                if (!keyData || !keyNew)
                    return state;
                const {[keyData]: list, [keyNew]: count}: any = state;
                state[keyData] = list.map((item: any) => item.group_chat.id === id ? {
                    ...item,
                    status: MESSAGE_STATUS.READ,
                } : item);
                state[keyNew] = checkLimit(0, undefined, count - 1);
            }
        },
        addChat: (state, action) => {
            const data = action.payload;
            const {chat, messages} = state;
            const {message} = data;
            const {id} = data.group_chat;
            state.chat = [data, ...chat];
            state.messages = {
                ...messages,
                [id]: {id, index: 1, loading: false, messages: [message], prev: false}
            }
        },
        addChatOfOp: (state, action) => {
            const data = action.payload
            const {operations, messages} = state;
            const {id} = data.group_chat;
            const {message} = data;
            if (messages[id])
                state.messages = {
                    ...messages,
                    [id]: {...messages[id], messages: [...messages[id].messages, message]}
                }

            state.operations = operations.reduce((rs: any, item: any) => {
                if (item.group_chat.id === id)
                    return rs
                return [...rs, item];
            }, [data]);
        },
        leaveChat: (state: any, action) => {
            const {chatId, chatType} = action.payload;
            const {keyData = null, keyNew = null} = tabMessage[chatType] || {};

            if (!keyData || !keyNew)
                return state;

            const {[keyData]: list, [keyNew]: count, messages}: any = state;
            let chat: any = {};
            const {[chatId]: old, ...newMessage} = messages;
            state[keyData] = list.filter((item: any) => {
                if (item.group_chat.id === chatId) {
                    chat = item;
                    return false
                }
                return true
            });
            state.messages = newMessage
            state[keyNew] = chat.status === MESSAGE_STATUS.NEW ? count - 1 : count
        },
        addUser: (state: any, action) => {
            const notification = action.payload;
            const {type} = notification;
            const {members: users} = notification.message;
            const {id: chatId} = notification.group_chat;
            const {keyData = null} = tabMessage[type] || {};
            const {[keyData]: list, messages}: any = state;

            if (!keyData || !messages[chatId])
                return state;

            state.messages = {
                ...messages,
                [chatId]: {
                    ...messages[chatId],
                    messages: [...messages[chatId].messages, notification.message]
                }
            };
            state[keyData] = list.map((item: any) => {
                if (item.group_chat.id === chatId) {
                    const {members: oldMember, name: oldName} = item.group_chat
                    const name = oldMember.map((sub: any) => sub.username.split('@')[0]);
                    const {
                        newName,
                        users: newUsers,
                        members,
                    } = [...oldMember, ...users].reduce((rs: any, sub: any) => {
                        rs.users.push(sub.id);
                        rs.members.push(sub);
                        rs.newName.push(sub.username.split('@')[0]);
                        return rs;
                    }, {newName: [], users: [], members: []});

                    return {
                        ...notification,
                        group_chat: {
                            ...notification.group_chat,
                            name: name.join(', ') === oldName ? newName.join(', ') : oldName,
                            users: newUsers,
                            members
                        }
                    }
                }

                return item;
            })
        },
        kickUser: (state, action) => {
            const notification = action.payload;
            const {type} = notification;
            const {members: users} = notification.message;
            const {id: chatId} = notification.group_chat;
            const {keyData = null} = tabMessage[type] || {};
            const {[keyData]: list, messages}: any = state;

            if (!keyData || !messages[chatId])
                return state;

            const deleteIds = new Set(users.map((item: any) => item.id));

            return {
                ...state,
                messages: {
                    ...messages,
                    [chatId]: {
                        ...messages[chatId],
                        messages: [...messages[chatId].messages, notification.message]
                    }

                },
                [keyData]: list.map((item: any) => {
                    if (item.group_chat.id === chatId) {
                        const {members: oldMember, name: oldName} = item.group_chat;
                        const {name, users: newUsers, members, newName} = oldMember.reduce((rs: any, sub: any) => {
                            const username = sub.username.split('@')[0];
                            rs.name.push(username);
                            if (!deleteIds.has(sub.id)) {
                                rs.users.push(sub.id);
                                rs.members.push(sub);
                                rs.newName.push(username);
                            }
                            return rs;
                        }, {name: [], users: [], members: [], newName: []});

                        return {
                            ...notification,
                            group_chat: {
                                ...notification.group_chat,
                                name: name.join(', ') === oldName ? newName.join(', ') : oldName,
                                users: newUsers,
                                members
                            }
                        }
                    }

                    return item;
                })
            }
        },
        deleteConversation: (state, action) => {
            const chatId = action.payload;
            const {chat, chatNew, messages, conversations} = state;
            let conversation: any = {};
            const {[chatId]: oldData, ...newMessages} = messages;
            state.chat = chat.filter((item: any) => {
                if (item.group_chat.id === chatId) {
                    conversation = item
                    return false
                }
                return true
            });
            state.chatNew = conversation.status === MESSAGE_STATUS.NEW ? checkLimit(0, undefined, chatNew - 1) : chatNew;
            state.messages = newMessages;
            state.conversations = conversations.filter((item: any) => item.id !== chatId);
        },
        updateConversations: (state, action) => {
            const {conversation, mode, isTop} = action.payload;
            const {conversations} = state;
            const {id} = conversation.group_chat;
            let newConversations;

            if (mode === CONVERSATION_MODE.CLOSE) {
                newConversations = conversations.filter((item: any) => item.id !== id);
            } else {
                const exist = conversations.some((item: any) => item.id === id);
                let newValue = {id, conversation, mode};
                if (exist) {
                    if (isTop) {
                        newConversations = conversations.reduce((rs: any, item: any) => {
                            if (item.id === id)
                                return rs
                            return [...rs, item];
                        }, [newValue]);
                    } else
                        newConversations = conversations.map((item: any) => item.id === id ? newValue : item)
                } else
                    newConversations = [newValue, ...conversations];
            }

            localStorage.setItem(KEY_STORAGE.CONVERSATION, JSON.stringify(newConversations));
            state.conversations = newConversations
        },
        seenMessage: (state, action) => {
            const {messages} = state;
            const {id, chat_id, seen} = action.payload;

            if (messages[id]) {
                let isFlag = true;
                state.messages = {
                    ...messages,
                    [id]: {
                        ...messages[id],
                        messages: messages[id].messages.map((item: any) => {
                            if (isFlag)
                                delete item.sending_status;

                            if (item.chat_id === chat_id) {
                                isFlag = false;
                                return {...item, seen}
                            }
                            return item;
                        })
                    },
                }
            }
        }
    },
})

export const messageActions = messageSlice.actions

const messageReducer = messageSlice.reducer;

export default messageReducer;
