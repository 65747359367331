import React, {Component} from 'react';
import styles from './style.module.scss';
import {TOperation} from "../../util/varibles/interface";

interface IProps {
    visible?: boolean
    operation: TOperation
}

class SiteUnit extends Component<IProps> {
    static defaultProps = {
        visible: true,
    }

    render() {
        const {site_name, sub_operations, sites = [], site = {name: ''}} = this.props.operation;

        if (!this.props.visible) return null;

        let value;
        value = sites.length === 0
            ? [site_name || site.name, sub_operations.map((item: any) => item.unit_id).join(', ')].join(' - ')
            : sites.map((item: any) => {
                return [item.name, sub_operations.reduce((list: any, sub: any) =>
                        sub.site_id === item.id ? [...list, sub.unit_id] : list
                    , []).join(', ')].join(' - ')
            }).join(' | ')

        return <div className={styles['site-unit']}>
            {value}
        </div>;
    }
}

export default SiteUnit;
