import React, {useState} from 'react';
import {operationType, propsModal} from "../../../../util/varibles/constants";
import {Modal} from "antd";
import styles from "./style.module.scss";
import {IChecklist} from "../../../../util/varibles/interface";
import {getStepNameOfChecklist} from "../../../../util/varibles/global";
import Header from "../../../../components/Popup/Component/Header";
import Checklist from "../../../../components/Checklist";

interface IProps {
    checklist: IChecklist

    onClose?(): void
}

const PopupChecklist: React.FC<IProps> = (props) => {
    const {checklist, onClose} = props;
    const [visible, setVisible] = useState(true);

    const handleClose = () => {
        setVisible(false);
        if (onClose)
            onClose()
    }

    const {operation_type} = checklist;
    const checklistName = getStepNameOfChecklist(checklist);
    const {name: opTypeName = ''} = operationType[operation_type] || {};

    const title = [opTypeName, checklistName].join(' - ');

    return <Modal {...{
        ...propsModal,
        wrapClassName: styles['popup-checklist'],
        width: '670px',
        title: <Header title={<div className='m-0 font-s1 font-w5'>{title}</div>} onClose={handleClose}/>,
        open: visible,
        onCancel: handleClose
    }}>
        <Checklist checklist={checklist} isConfirm={false}/>
    </Modal>;
};

export default PopupChecklist;
