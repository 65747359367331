import React from 'react';
import {IOperation} from "../../../../../util/varibles/interface";
import WrapperOperation from "../WrapperOperation";
import Logo from "../Logo";
import Icon from "../../../../../components/Icon";
import {eventType, OPERATION_TYPE, operationType} from "../../../../../util/varibles/constants";
import {IPropsOp} from "../index";

interface IProps extends IPropsOp {
    data: IOperation
}

const Event: React.FC<IProps> = (props) => {
    const {data} = props;
    const {icon} = operationType[OPERATION_TYPE.EVENT];
    const {event_type} = data.operation;
    const {name = ''} = eventType[event_type] || {}
    return <WrapperOperation {...props}>
        <Logo>
            <Icon icon={icon} dataAttribute={{'data-event-type': event_type}}/>
        </Logo>
        <div className='text-nowrap'>{name}</div>
    </WrapperOperation>;
};

export default Event;