import type {Ref} from 'react';
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {useMap} from '@vis.gl/react-google-maps';
import Dialog from "./Dialog";
import {OverlayView} from './OverlayView';
import styles from './style.module.scss'
import {getProdAreaName} from "../../util/varibles/global";
import {colors} from "../../util/library/googlemap/constants";

type PolylineEventProps = {
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onDrag?: (e: google.maps.MapMouseEvent) => void;
    onDragStart?: (e: google.maps.MapMouseEvent) => void;
    onDragEnd?: (e: google.maps.MapMouseEvent) => void;
    onMouseMove?: (e: google.maps.MapMouseEvent) => void;
    onMouseOver?: (e: google.maps.MapMouseEvent) => void;
    onMouseOut?: (e: google.maps.MapMouseEvent) => void;
};

type PolylineCustomProps = {
    /**
     * this is an encoded string for the path, will be decoded and used as a path
     */
    path?: any[];
    show?: boolean;
};

export type PolylineProps = google.maps.PolylineOptions &
    PolylineEventProps &
    PolylineCustomProps;

export type PolylineRef = Ref<google.maps.Polyline | null>;

function usePolyline(props: PolylineProps) {
    const {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseMove,
        onMouseOut,
        path,
        show = true,
        ...polylineOptions
    } = props;
    // This is here to avoid triggering the useEffect below when the callbacks change (which happen if the user didn't memoize them)
    const callbacks = useRef<Record<string, (e: unknown) => void>>({});
    Object.assign(callbacks.current, {
        onClick,
        onDrag,
        onDragStart,
        onDragEnd,
        onMouseOver,
        onMouseMove,
        onMouseOut
    });

    const polyline = useRef(new google.maps.Polyline()).current;
    // update PolylineOptions (note the dependencies aren't properly checked
    // here, we just assume that setOptions is smart enough to not waste a
    // lot of time updating values that didn't change)
    useEffect(() => {
        polyline.setOptions(polylineOptions);
    }, [polyline, polylineOptions]);

    // update the path with the encodedPath
    useMemo(() => {
        if (!path) return;
        // const path = geometryLibrary.encoding.decodePath(encodedPath);
        polyline.setPath(path);
    }, [polyline, path]);

    const map = useMap();
    // create polyline instance and add to the map once the map is available
    useEffect(() => {
        if (!map) {
            if (map === undefined)
                console.error('<Polyline> has to be inside a Map component.');

            return;
        }

        polyline.setMap(show ? map : null);

        return () => {
            polyline.setMap(null);
        };
    }, [map, show]);

    // attach and re-attach event-handlers when any of the properties change
    useEffect(() => {
        if (!polyline) return;

        // Add event listeners
        const gme = google.maps.event;
        [
            ['click', 'onClick'],
            ['drag', 'onDrag'],
            ['dragstart', 'onDragStart'],
            ['dragend', 'onDragEnd'],
            ['mouseover', 'onMouseOver'],
            ['mousemove', 'onMouseMove'],
            ['mouseout', 'onMouseOut']
        ].forEach(([eventName, eventCallback]) => {
            gme.addListener(polyline, eventName, (e: google.maps.MapMouseEvent) => {
                const callback = callbacks.current[eventCallback];
                if (callback) callback(e);
            });
        });

        return () => {
            gme.clearInstanceListeners(polyline);
        };
    }, [polyline]);

    return polyline;
}

/**
 * Component to render a polyline on a map
 */
const Polyline = forwardRef((props: PolylineProps, ref: PolylineRef) => {
    const polyline = usePolyline(props);

    useImperativeHandle(ref, () => polyline, []);

    return null;
});


export const colorProductionArea: any = {
    green: '#5CA76A',
    yellow: '#F6D585',
    red: '#E78382'
}

interface IProductionAreaPolyline {
    data: any
    show?: boolean

    onClick?(): void
}

const ProductionAreaPolyline: React.FC<IProductionAreaPolyline> = ({data, show = true}) => {
    const {coordinates, color} = data
    const strokeColor = useMemo(() => colorProductionArea[color], [color]);
    const path = useMemo(() => coordinates.map(([lng, lat]: any) => ({lat, lng})), [coordinates]);
    const [hover, setHover] = useState<google.maps.LatLng | null>()

    return <>
        <Polyline
            geodesic={true}
            show={show}
            path={path}
            strokeColor={strokeColor}
            strokeOpacity={0.7}
            strokeWeight={7}
            onMouseMove={e => setHover(e.latLng)}
            onMouseOut={() => setHover(null)}
        />
        {hover && <OverlayView
            position={hover}
            content={<Dialog visible={!!hover}>
                <div className={styles['container-info']}>
                    <div
                        className={[styles.header, 'text-center'].join(' ')}
                        style={{borderBottom: 'unset'}}
                    >
                        {getProdAreaName(data)}
                    </div>
                </div>
            </Dialog>}
        />}
    </>
};

interface ISeaWayPolyline {
    data: {
        id?: string
        name?: string
        points: (google.maps.LatLng | { lat: number, lng: number })[]
    }
    show?: boolean
    width?: number
    color?: string

    onClick?(): void
}

const SeaWayPolyline: React.FC<ISeaWayPolyline> = ({data, show = true, width = 1, color = colors[0]}) => {
    const {points} = data

    return <>
        <Polyline
            geodesic={true}
            show={show}
            path={points}
            strokeColor={"#" + color}
            strokeOpacity={1}
            strokeWeight={width}
            zIndex={width === 3 ? 2 : 1}
        />
    </>
};

export {ProductionAreaPolyline, SeaWayPolyline}