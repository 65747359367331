import React, {useEffect, useState} from 'react';
import {Drawer, Empty, Tabs, theme} from "antd";
import Header from "../../../../components/Popup/Component/Header";
import {propsModal} from "../../../../util/varibles/constants";
import Units from "./Units";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../util/store/selectors";
import {ISite, IUnit} from "../../../../util/varibles/interface";
import Info from "./Info";
import styles from "./style.module.scss"
import UnitChart from "./UnitChart";
import {datetime} from "../../../../util/library/datetime";

enum TabId {
    Info = 'info',
    Analytics = 'analytics',
}

export interface IProps {
    site: {
        id: string
        name: string
    }
    unitId?: string
    year?: number
    tab?: TabId

    onClose(): void
}

const Site: React.FC<IProps> = ({
                                    site: {id: siteId, name: siteName},
                                    unitId,
                                    tab = TabId.Analytics,
                                    year = datetime().year,
                                    onClose
                                }) => {
    const [open, setOpen] = useState(true);
    const [selected, setSelected] = useState<IUnit>();
    const [{loading, site}, setSite] = useState<{ loading: boolean, site?: ISite }>({loading: true});
    const {token: {colorBgContainer}} = theme.useToken();
    const sites = useSelector(selectPlan.sites);

    useEffect(() => {
        const site = sites.find(item => item.id === siteId);
        if (site) {
            const {units} = site;
            setSite({loading: false, site});
            setSelected(units.find(item => item.id === unitId));
        } else {
            setSite({loading: false});
        }
    }, [sites, siteId, unitId]);

    const handleClose = () => {
        setOpen(false);
        onClose();
    }

    return <Drawer
        {...propsModal}
        open={open}
        width='100%'
        title={<Header title={siteName} onClose={handleClose}/>}
        onClose={handleClose}
    >
        <div className={styles.content}>
            <Units site={site} selected={selected} onChange={setSelected}/>
            {(!loading && site) ? <Tabs
                defaultActiveKey={tab}
                renderTabBar={(props, DefaultTabBar) => (
                    <div className={styles.tabs}>
                        <DefaultTabBar {...props} style={{background: colorBgContainer}}/>
                    </div>
                )}
                items={[
                    {
                        key: TabId.Info,
                        label: 'Site info',
                        children: <Info site={site}/>

                    },
                    {
                        key: TabId.Analytics,
                        label: 'Analytics',
                        children: <UnitChart year={year} unit={selected} changeUnit={setSelected} site={site}/>
                    }
                ]}
            /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`${siteName} was not found`}/>}

        </div>
    </Drawer>;
};

export default Site;
