import React, {createContext, useCallback, useContext, useEffect, useRef, useSyncExternalStore,} from "react";

interface IGetSet<S> {
    get: () => S
    set: (value: ((subProps: S) => Partial<S>) | Partial<S>) => void
}

type TCaseEvent = {
    [id: string]: (...props: any[]) => void
}

type IEvent<S, E extends TCaseEvent> = {
    [Name in keyof E]: (...props: ([...Parameters<E[Name]>, IGetSet<S> & {
        event: () => E
    }])) => void
}

export default function createFastContext<Store, CaseEvent extends TCaseEvent = {}>({state}: {
    state: Store,
    cache?: () => Partial<Store>
}) {

    function useStoreData(props: Store, event?: IEvent<Store, CaseEvent>): IGetSet<Store> & {
        subscribe: (callback: () => void) => () => void
        event?: IEvent<Store, CaseEvent>
    } {
        const store = useRef(props);

        const get = useCallback(() => store.current, []);

        const subscribers = useRef(new Set<() => void>());

        const set = useCallback((value: ((subProps: Store) => Partial<Store>) | Partial<Store>) => {
            const valueFinal = typeof value === 'function' ? value(store.current) : value
            store.current = {...store.current, ...valueFinal};
            subscribers.current.forEach((callback) => callback());
        }, []);


        const subscribe = useCallback((callback: () => void) => {
            subscribers.current.add(callback);
            return () => subscribers.current.delete(callback);
        }, []);

        return {
            get,
            set,
            subscribe,
            event,
        };
    }

    type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

    const StoreContext = createContext<UseStoreDataReturnType | null>(null);

    const Update = (props: Partial<Store>) => {
        const store = useContext(StoreContext);

        useEffect(() => {
            if (store)
                store.set(props);
        }, [props, store])

        return null;
    }

    function Provider({children, props = {}, event}: {
        children: React.ReactNode,
        props?: Partial<Store>,
        event?: IEvent<Store, CaseEvent>
    }) {

        return <StoreContext.Provider
            value={useStoreData({...state, ...props}, event)}
        >
            <Update {...props} />
            {children}
        </StoreContext.Provider>
    }

    function UseStore<SelectorOutput>(selector: (store: Store) => SelectorOutput): [SelectorOutput, (value: ((subProps: Store) => Partial<Store>) | Partial<Store>) => void] {
        const store = useContext(StoreContext);
        if (!store) {
            throw new Error("Store not found");
        }

        const value = useSyncExternalStore(
            store.subscribe,
            () => selector(store.get()),
            () => selector(state),
        );

        return [value, store.set];
    }

    function SetStore(): (value: ((subProps: Store) => Partial<Store>) | Partial<Store>) => void {
        const store = useContext(StoreContext);
        if (!store) {
            throw new Error("Store not found");
        }

        return store.set;
    }

    function GetStore(): () => Store {
        const store = useContext(StoreContext);
        if (!store) {
            throw new Error("Store not found");
        }

        return store.get;
    }

    function CallEvent(): CaseEvent {
        const store = useContext(StoreContext);
        if (!store) {
            throw new Error("Store not found");
        }
        const {event, get, set} = store;

        if (!event) {
            // return {} as any
            throw new Error("Event not created");
        }

        const createEvent = ({get, set}: IGetSet<Store>) => Object.keys(event).reduce((rs: any, key) => {
            rs[key] = (...payload: any) => {
                payload.push({get, set, event: () => createEvent({get, set})})
                event[key](...payload);
            }
            return rs;
        }, {});

        return createEvent({get, set});
    }

    return {
        Provider,
        // Get state and subscribe renew state and set state
        useStore: UseStore,
        // Set state
        setStore: SetStore,
        // Get state and don't subscribe
        getStore: GetStore,
        // Call event has been defined
        callEvent: CallEvent
    };
}