import {
    aDayMillisecond,
    aHourMillisecond,
    aMinutesMillisecond,
    aWeekMillisecond,
    colors,
    eventType,
    FULL_DATE_FORMAT,
    KeyOfTask,
    listOfStep,
    OP_STATUS,
    OPERATION_MODE,
    OPERATION_TYPE,
    stepsFull,
    TIME_PER_STEP,
    USER_ROLE,
    VIEW_SMOOTH
} from "./constants";
import {
    IActivityLog,
    ICapability,
    ILevelByVessel,
    IOperation,
    IOperations,
    IServiceTask,
    ITaskService,
    IVessel,
    TOperation
} from "./interface";
import {notification} from "antd";
import Datetime, {datetime} from "../library/datetime";
import {nextPage} from "../library/Router";
import {v4 as UUID} from "uuid";

/*
* Date: 06/02/2019
* Author: Vinh.Pham
* Description: Return zoom level from bounds in google map
 */
export function getBoundsZoomLevel(bounds: any, mapDim: any) {
    let WORLD_DIM = {height: 256, width: 256};
    let ZOOM_MAX = 21;

    function latRad(lat: any) {
        let sin = Math.sin(lat * Math.PI / 180);
        let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: any, worldPx: any, fraction: any) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();

    let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    let lngDiff = ne.lng() - sw.lng();
    let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    let latZoom = zoom(mapDim.offsetHeight, WORLD_DIM.height, latFraction);
    let lngZoom = zoom(mapDim.offsetWidth, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}


export function downloadBlob(blob: any, filename: any) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

/*
* Date: 14/10/2019
* Author: Vinh.Pham
* Description: Get week number of year
 */
export function getWeekOfYear(date: any): number {
    date = new Date(date);
    let target: any = new Date(date);
    let dayNr = (date.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNr + 3);
    let firstThursday = target.valueOf();
    target.setMonth(0, 1);
    if (target.getDay() !== 4) {
        target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - target) / aWeekMillisecond);
}

/*
* Date: 28/11/2019
* Author: Vinh.Pham
* Description: Get year by week no
 */
export function getYearByWeekNo(date: any, weekNo: number): number {
    date = new Date(date);
    let year = date.getFullYear();
    return date.getMonth() === 11 && weekNo === 1 ? year + 1 : year;
}

/*
* Date: 14/10/2019
* Author: Vinh.Pham
* Description: add 0 to the first if the string is 1 in length
 */
export function checkZero(data: any) {
    if (data.toString().length === 1)
        data = "0" + data;
    return data;
}

/*
* Date: 14/10/2019
* Author: Vinh.Pham
* Description: Get monday of key week
 */
export function getMondayByKey(key: string): Datetime {
    const [year, weekNo] = key.split(/\|/);
    return datetime().set({year, week: weekNo, day: 0}).startOf('day');
}

/*
* Date: 14/10/2019
* Author: Vinh.Pham
* Description: Get sunday of key week
 */
export function getSundayByKey(key: string): Datetime {
    const [year, weekNo] = key.split(/\|/);
    return datetime().set({year, week: weekNo, day: 6}).endOf('day');
}

/*
* Date: 14/10/2019
* Author: Vinh.Pham
* Description: Change deg to radians
 */
export function degToRadians(deg: any) {
    return deg * Math.PI / 180
}

/*
* Date: 14/10/2019
* Author: Vinh.Pham
* Description: Get dates between 2 dates (include: finish)
 */
export function getDatesBetween2Dates(start: any, finish: any, isFinish = true) {
    start = new Date(start);
    finish = new Date(finish);
    let listDates: any[] = [];
    if (isFinish) {
        while (start <= finish) {
            listDates.push(new Date(start));
            start.setDate(start.getDate() + 1);
        }
        let lastDate = new Date(listDates[listDates.length - 1]);
        if (lastDate.getDate() < finish.getDate())
            listDates.push(finish);
        return listDates;
    } else {
        while (start < finish) {
            listDates.push(new Date(start));
            start.setDate(start.getDate() + 1);
        }
        return listDates;
    }
}

/*
* Date: 29/10/2019
* Author: Vinh.Pham
* Description: Get percent Brightness from color
 */
export function percentBrightness(color: any) {
    color = color.substring(1);      // strip #
    let rgb = parseInt(color, 16);   // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff;  // extract red
    let g = (rgb >> 8) & 0xff;  // extract green
    let b = (rgb >> 0) & 0xff;  // extract blue
    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
}

/*
* Date: 31/10/2019
* Author: Vinh.Pham
* Description: create polyline from route
 */
export function createPolyline(route: any, index: number, width = 2, isMarkers = true) {
    const {id = '', points = [], name = ''} = route || {};
    return {
        id,
        isMarkers,
        points: points.map((point: any) => ({
            name: point.name || "",
            lat: point.latitude,
            lng: point.longitude,
        })),
        width,
        color: colors[index % 10],
        name
    }
}

/*
* Date: 25/11/2019
* Author: Vinh.Pham
* Description: Check arrival time to factory
 */
export function checkFactoryTime(factoryTime: any, factoryWorkTime: any, error: any) {
    let timeActivity = new Date(factoryTime.est_start_time);
    let time = timeActivity.getHours() + (timeActivity.getMinutes() / 60);
    if (time > factoryWorkTime) {
        factoryTime.invalid = 1;
        let hour = Math.floor(factoryWorkTime);
        let min = checkZero((factoryWorkTime - hour) * 60);
        hour = checkZero(hour);
        error.push({
            invalid: 1,
            name: "Arrived at factory",
            description: `time should be before ${hour}:${min} ${hour > 12 ? "PM" : "AM"}`
        })
    } else
        delete factoryTime.invalid;
    return {factoryTime, error}
}

/*
* Date: 17/12/2019
* Author: Vinh.Pham
* Description: Check limit of value
 */
export function checkLimit(min: number | undefined, max: number | undefined, value: number) {
    if (min !== undefined)
        value = value < min ? min : value;
    if (max !== undefined)
        value = value > max ? max : value;
    return value;
}

/*
* Date: 04/01/2019
* Author: Vinh.Pham
* Description: Get key week from date
 */
export function getKeyWeek(date: any) {
    const time = datetime(date);
    const weekNo = time.week;
    const year = time.year;
    return [year, weekNo].join('|');
}

/*
* Date: 10/01/2019
* Author: Vinh.Pham
* Description: calculate TotalWeight And TotalFish
 */
export function calculateTotalWeightAndTotalFish(item: any) {
    let totalWeight = 0, totalAmount = 0;
    item = item.operation.sub_operations.reduce((total: any, harvest: any) => {
        totalWeight += (harvest.fish_amount * harvest.avg_weight);
        totalAmount += harvest.fish_amount;
        return total;
    }, item);
    return {...item, operation: {...item.operation, totalWeight, totalAmount}};
}

/*
* Date: 11/01/2019
* Author: Vinh.Pham
* Description: add "." and "," in number
 */
export function formatNumber(value: any) {
    // return value.toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    if (!value) return value;
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/*
* Date: 26/12/2019
* Author: Vinh.Pham
* Description: Calculate duration between start time and finish time
 */
export function durationTxtByStartAndFinish(startTime: number, finishTime: number, isShowSecond = false): string {
    const duration = (finishTime - startTime) / 3600000;
    if (duration < 0) return '';
    const hours = Math.floor(duration);
    let result = hours > 0 ? `${hours}h ` : '';
    const minutes = Math.round((duration - hours) * 60);
    if (minutes === 60) {
        result = `${hours + 1}h `;
    } else
        result += minutes > 0 ? minutes + "m " : '';
    if (result.length === 0 && isShowSecond) {
        const seconds = Math.round(duration * 3600);
        if (seconds > 0 && seconds < 60)
            result = seconds + 's'
    }
    return result
}

/*
* Date: 13/01/2019
* Author: Trung.Nguyen
* Description: Show duration
 */
export function durationTxt(duration: number, empty?: string) {
    const value = Math.abs(duration);
    if (value !== 0) {
        let days = Math.floor(value / (24 * 3600000));
        let durationText = days > 0 ? days + "d" : "";
        let hours = Math.floor((value - 24 * days * 3600000) / 3600000);
        durationText = hours > 0 ? durationText + " " + hours + "h" : durationText;
        let mins = value - hours * 3600000 - days * 24 * 3600000;
        durationText = mins > 0 ? `${durationText} ${Math.round(mins / 60000)}m` : durationText;
        return durationText
    }
    return empty || ""
}

/*
* Date: 06/02/2020
* Author: Vinh.Pham
* Description: Create markers
 */
export function createMarkers(items: any = []) {
    return items.reduce((list: any[], item: any) => {
        const {position, id, name} = item;
        if (position) {
            const {latitude = 0, longitude = 0} = position || {}
            return [...list, {...item, id, name, lat: latitude, lng: longitude}]
        } else {
            const {latitude, longitude} = item;
            return [...list, {...item, id, name, lat: latitude, lng: longitude}]
        }
    }, []);
}

/*
* Date: 12/05/2020
* Author: Vinh.Pham
* Description: Uppercase first letter
 */
export function uppercaseFirstLetter(s: any) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

/*
* Date: 05/08/2020
* Author: Vinh.Pham
* Description: get element special by position
 */
export function getElementByPosition(left: number, top: number, condition: any) {
    let element: HTMLElement | any = document.elementFromPoint(left, top)
    let hideElements = [];
    while (condition(element) && element) {
        element.style.display = 'none';
        hideElements.push(element)
        element = document.elementFromPoint(left, top)
    }
    hideElements.forEach((item: any) => item.style.display = null)
    return element
}

/*
* Date: 19/01/2020
* Author: Vinh.Pham
* Description: get name of event
 */
export function getEventTypeCode(type: number): string {
    const {name = ''} = eventType[type] || {};
    return name
}

/*
* Date: 19/01/2020
* Author: Vinh.Pham
* Description: get character by key code
 */
export function mapKeyPressToActualCharacter(isShiftKey: any, characterCode: any): string {
    if (characterCode === 27 || characterCode === 8 || characterCode === 9 || characterCode === 20
        || characterCode === 16 || characterCode === 17 || characterCode === 91 || characterCode === 13
        || characterCode === 92 || characterCode === 18) {
        return '';
    }
    if (typeof isShiftKey != "boolean" || typeof characterCode != "number") {
        return '';
    }

    let character;

    if (isShiftKey) {
        if (characterCode >= 65 && characterCode <= 90) {
            character = String.fromCharCode(characterCode);
        } else {
            const characterMap = [];
            characterMap[192] = "~";
            characterMap[49] = "!";
            characterMap[50] = "@";
            characterMap[51] = "#";
            characterMap[52] = "$";
            characterMap[53] = "%";
            characterMap[54] = "^";
            characterMap[55] = "&";
            characterMap[56] = "*";
            characterMap[57] = "(";
            characterMap[48] = ")";
            characterMap[109] = "_";
            characterMap[107] = "+";
            characterMap[219] = "{";
            characterMap[221] = "}";
            characterMap[220] = "|";
            characterMap[59] = ":";
            characterMap[222] = "\"";
            characterMap[188] = "<";
            characterMap[190] = ">";
            characterMap[191] = "?";
            characterMap[32] = " ";
            character = characterMap[characterCode] || String.fromCharCode(characterCode);
        }
    } else {
        if (characterCode >= 65 && characterCode <= 90) {
            character = String.fromCharCode(characterCode).toLowerCase();
        } else {
            const characterMap = [];
            characterMap[188] = ",";
            characterMap[190] = ".";
            character = characterMap[characterCode] || String.fromCharCode(characterCode);
        }
    }
    return character || '';
}

/*
* Date: 19/01/2020
* Author: Vinh.Pham
* Description: get width of text on html
 */
export function getWidthOfText(text: string) {
    const span = document.createElement('span');
    span.textContent = text;
    span.style.whiteSpace = 'no-wrap';
    document.body.appendChild(span);
    const width = span.offsetWidth;
    span.remove();
    return width;
}

/*
* Date: 18/02/2020
* Author: Vinh.Pham
* Description: Get hour and minute from number
 */
export function getHourMinute(value: number) {
    const hour = Math.floor(value / 60);
    const minute = Math.floor(value - (hour * 60));
    return {hour, minute}
}

/*
* Date: 01/03/2021
* Author: Vinh.Pham
* Description: Get element by ID
 */
export function getElById(id: string): HTMLElement {
    return document.getElementById(id) as HTMLElement;
}

/*
* Date: 23/11/2021
* Author: Vinh.Pham
* Description: Get element by className
 */
export function getElByClassName(className: string) {
    const el: any = document.getElementsByClassName(className)[0];
    return el;
}

/*
* Date: 08/03/2021
* Author: Vinh.Pham
* Description: filter option by text
 */
export const filterOption = (input: any, option: any) => (option.children || option.label || '').toLowerCase().indexOf(input.toLowerCase()) >= 0

/*
* Date: 08/03/2021
* Author: Vinh.Pham
* Description:
*   Get the start time of the date.
    But in case there is an operation in the day it will be taken after 30 minutes from the end time of the operation.
 */
interface IGetStartTimeFromDate {
    date: number
    operations: IOperations
    level?: ILevelByVessel
    vessel: IVessel
    opMode: OPERATION_MODE
}

export const getStartTimeFromDate = (args: IGetStartTimeFromDate) => {
    const {opMode, level = {}, operations} = args;
    const {id: vesselId} = args.vessel;
    const startTime = Math.max(Date.now() + TIME_PER_STEP, datetime(args.date).time);
    const finishTime = Math.min(startTime + (aDayMillisecond / 4), datetime(startTime).endOf('day').time);
    const result = Object.keys(level[vesselId] || {}).reduce((rs: any, key) => {
        const item = operations[key];
        if (item) {
            const {activity_log} = item;
            const {start, finish} = getStartFinishByOpMode[opMode](activity_log, item.operation.status)
            if (startTime <= finish && start <= finishTime)
                if (rs < finish)
                    rs = finish + TIME_PER_STEP;
        }
        return rs
    }, startTime);
    return Math.round(result / TIME_PER_STEP) * TIME_PER_STEP
}

export const getStartTimeFromDate1 = (date: any, operations: IOperation[]) => {
    const startTime = checkLimit(Date.now(), undefined, datetime(Number(date)).time);
    const finishTime = startTime + aDayMillisecond - 1;
    return operations.reduce((rs: any, item: any) => {
        const {activity_log} = item;
        const value = activity_log[activity_log.length - 1].est_start_time;
        if (startTime <= value && activity_log[0].est_start_time <= finishTime)
            if (rs < value)
                rs = value + TIME_PER_STEP;
        return rs
    }, startTime)
}

export function descriptionDeliveryType(item: any) {
    const {name, fish_amount, total_weight} = item;
    if (fish_amount > 0) {
        return `${name}: ${formatNumber(fish_amount)} fish (or ${Math.round(total_weight / 1000) / 1000} t)`
    } else {
        return `${name}: ${Math.round(total_weight / 1000) / 1000} t`
    }
}

export function getElementFromPoint(x: number, y: number, condition?: any) {
    const elements = [];
    const display = [];
    let count = 0;
    let item: any = document.elementFromPoint(x, y), element;
    while (item && item !== document.body && item !== window && item !== document && item !== document.documentElement && count < 10) {
        elements.push(item);
        display.push(item.style.display);
        item.style.display = "none";
        item = document.elementFromPoint(x, y);
        if (condition && condition(item)) {
            element = item;
            break;
        }
        count++;
    }
    // restore display property
    for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = display[i];
    }
    return element;
}

export function formatDate(date: any, format = FULL_DATE_FORMAT) {
    const value = datetime(date);
    return value.isValid ? value.format(format) : '-'
}

export function checkPermission(allow: USER_ROLE[], roles: USER_ROLE[]): boolean {
    return allow.some(item => roles.includes(item));
}

export function convertNumberTimeToStringTime(value: any): string {
    if (typeof value !== 'number')
        return '';
    let surplus, time = Math.abs(value);
    const day = Math.floor(time / aDayMillisecond);
    surplus = time - (day * aDayMillisecond);
    time = surplus / aHourMillisecond;
    const hour = Math.floor(time);
    surplus = time - hour
    const minute = Math.floor(surplus * 60);
    const rs = [];
    if (day > 0)
        rs.push(`${day}d`)
    if (hour > 0)
        rs.push(`${hour}h`)
    if (minute > 0)
        rs.push(`${minute}m`)

    return rs.join(' ');
}

const reg = /^\d+d( \d+h)?( \dm)?$|^\d+h( \d+m)?$|^\d+m$/i
const unitOfTime: any = {
    d: aDayMillisecond,
    h: aHourMillisecond,
    m: aMinutesMillisecond
}

export function convertStringTimeToNumberTime(value: any) {
    const isValid = value.match(reg);

    if (isValid) {
        const list = value.split(' ');
        return list.reduce((sum: number, item: any) => {
            const suffix = item.slice(-1);
            const num = +item.slice(0, item.length - 1);
            const unit = unitOfTime[suffix] || 1
            return sum + (num * unit)
        }, 0)
    }
    return;
}

interface IGetStatusOfOp {
    current_process: number
    operation: any
    activity_log: any
}

export function getStatusOfOp(params: IGetStatusOfOp): string {
    const {status} = params.operation;
    if (status === OP_STATUS.PROCESSING) {
        const getName = getStepNameOfOp[params.operation.operation_type] || getStepNameOfOp.default;
        return getName(params);
    } else
        return uppercaseFirstLetter(status)
}


interface IConvertStepOfLocation {
    step: number
    key: string
    operation: TOperation
    activity_log: IActivityLog[]
}

export const convertStepOfLocation = {
    site: (params: IConvertStepOfLocation) => {
        const {key} = params;
        const {name} = listOfStep[key];
        const {name: siteName = ''} = params.activity_log[params.step] || {};
        return {...listOfStep[key], name: (name || '').replace('site', siteName)};
    },
    factory: (params: IConvertStepOfLocation) => {
        const {key} = params;
        const {name} = listOfStep[key];
        const {destination_name = '', factory_name = ''} = params.operation || {};
        return {...listOfStep[key], name: (name || '').replace('factory', factory_name || destination_name)};
    }
}

interface IGetStepNameOfChecklist {
    operation_type: OPERATION_TYPE
    step: any
    step_name?: string
}

export function getStepNameOfChecklist(params: IGetStepNameOfChecklist) {
    const {operation_type, step} = params;
    switch (operation_type) {
        case OPERATION_TYPE.HARVEST:
        case OPERATION_TYPE.TREATMENT: {
            const {name = ''} = listOfStep[step] || {};
            return name
        }
        case OPERATION_TYPE.SERVICE: {
            return params.step_name
        }
        default: {
            const {name = ''} = (stepsFull[operation_type] || {})[step] || {};
            return name
        }
    }
}

interface IGetNameOfStep {
    current_process: number
    operation: TOperation
    activity_log: IActivityLog[]
}

export const getStepNameOfOp: any = {
    [OPERATION_TYPE.HARVEST]: (params: IGetNameOfStep): string => {
        const {current_process, operation, activity_log} = params;
        const {key = ''} = activity_log[current_process] || {};
        const list = key.split(',');
        return list.map((step: string) => {
            if (step.indexOf('site') !== -1) {
                const {name} = convertStepOfLocation.site({
                    step: current_process,
                    key: step,
                    operation,
                    activity_log
                });
                return name;
            } else if (step.indexOf('factory') !== -1) {
                const {name} = convertStepOfLocation.factory({
                    step: current_process,
                    key: step,
                    operation,
                    activity_log
                });
                return name;
            } else {
                const {name = ''} = listOfStep[step] || {};
                return uppercaseFirstLetter(name);
            }
        }).join(', ')
    },
    [OPERATION_TYPE.TREATMENT]: (params: IGetNameOfStep): string => {
        const {current_process} = params;
        const {activity_log} = params;
        const {key = ''} = activity_log[current_process] || {};
        const list = key.split(',');
        return list.map((step: string) => {
            const {name = ''} = listOfStep[step] || {};
            return uppercaseFirstLetter(name);
        }).join(', ')
    },
    [OPERATION_TYPE.SERVICE]: (params: IGetNameOfStep): string => {
        const {current_process} = params;
        const {activity_log} = params;
        const {tasks} = params.operation;
        const {key = ''} = activity_log[current_process] || {};
        const [prefix, taskId] = key.split('_');
        const {name = ''} = tasks.find((item: any) => item.id === taskId) || {} as ITaskService;
        return [uppercaseFirstLetter(prefix), name].join(' ')
    },
    default: (params: IGetNameOfStep): string => {
        const {operation_type} = params.operation;
        const {current_process} = params;
        const {name = ''} = stepsFull[operation_type][current_process] || {};
        return uppercaseFirstLetter(name);
    }
}

export const onOpenPage = (e: any, link: string, action?: any) => {
    if (e.button === 0) {
        nextPage(link);
        if (action)
            action();
    }
}

export const checkLoadMore = (el: any, action: any, limit: number = 0) => {
    const {scrollHeight} = el;
    const isMore = scrollHeight <= (window.innerHeight - limit)
    if (isMore)
        action();
}

export function getProdAreaName(data: any) {
    return `P${checkZero(data.id)} - ${data.name}`
}

export function convertDisease(data: any) {
    const {
        id,
        forskNavn,
        forskLink,
        forskNr,
        originalDate,
        fromDate,
        geometry: {coordinates}
    } = data;
    return {
        id,
        forskNavn,
        forskLink,
        forskNr,
        originalDate,
        fromDate,
        coordinates: coordinates.reduce((list: any[], sub: any[]) => [
            ...list,
            ...sub.map((coords: any) => coords.map(([lng, lat]: any) => ({
                lat,
                lng
            })))], [])
    }
}

interface IGetTasksByVessel {
    serviceTasks: IServiceTask[]
    vessel: any
}

export function getServiceTasksByVessel(params: IGetTasksByVessel) {
    const {serviceTasks, vessel} = params;
    const requireIds = new Set((vessel.capabilities || []).map((item: any) => item.id));
    return serviceTasks.reduce((list: any, item) => {
        const {capabilities = []} = item;
        const isInvalid = capabilities.some((sub: ICapability) => !requireIds.has(sub.id));
        if (isInvalid)
            return list;

        return [...list, item];
    }, []);
}

export function showErrorResponse(title: any, error: any) {
    let message = (error.response ? error.response.statusText : error.statusText) || error.message || '';
    if (typeof message !== 'string') {
        notification.error({message: title});
        console.log(error)
        return;
    }

    if (message.length === 0) {
        if (error.data) {
            error.data.then((rs: any) => {
                console.log(rs);
                try {
                    const ms = rs.message || JSON.parse(rs)?.message;
                    notification.error({message: title, description: ms})
                } catch (e) {
                    notification.error({message: title})
                }
            });
            return;
        } else {
            notification.error({message: title});
        }
    } else
        notification.error({message: title, description: message})
}

export enum SCROLL_TYPE {
    HORIZONTAL,
    VERTICAL
}

export function jumpToToday(calendarId: string, targetId = datetime().startOf('day').time, type = SCROLL_TYPE.VERTICAL, space = 0) {
    const element = document.getElementById(targetId);
    const calendar = document.getElementById(calendarId);

    if (element && calendar) {
        const {offsetTop, offsetLeft} = element;
        const {value, ...position} = type === SCROLL_TYPE.VERTICAL
            ? {top: getTopOfEl(element, calendar) - space, value: offsetTop}
            : {left: offsetLeft - space, value: offsetLeft};
        if (value) {
            calendar.scroll({...position, ...VIEW_SMOOTH})
        } else {
            element.scrollIntoView(VIEW_SMOOTH);
        }
    }
}

/*
    Date: 23/05/2023
    Author: Vinh.Pham
    Description: get offsetTop of element of special parent
 */

export function getTopOfEl(element: HTMLElement, calendar: Element): number {
    const {id} = calendar;
    let parentEl = element.parentElement, top = element.offsetTop, count = 0;
    while (parentEl && parentEl.id !== id && count <= 5) {
        top += parentEl.offsetTop;
        parentEl = parentEl.parentElement;
        count++;
    }
    return top;
}

export const convertActivityLog = {
    [OPERATION_TYPE.TREATMENT]: (source: IActivityLog[]) => {
        return source.reduce((list: any, item: any, index: number) => {
            const {key, round, unit_id} = item;
            const steps = key.length > 0 ? key.split(',').map((step: string) => {
                const {name = ''} = listOfStep[step] || {};
                if (name.indexOf('unit') !== -1) {
                    const {destination_name = ''} = item;
                    if (destination_name.length > 0)
                        return {...listOfStep[step], name: (name || '').replace('unit', destination_name)};
                }

                return listOfStep[step];
            }) : [];

            const id = [unit_id, round].join('_');
            const isExist = list.findIndex((sub: any) => sub.id === id);
            const value = {...item, steps, index};

            if (isExist === -1) {
                return [...list, {id, name: round ? [unit_id, `Round ${round}`].join(', ') : null, list: [value]}];
            }
            list[isExist].list.push(value);
            return list
        }, []);
    }
}

export function isEmpty(value: any) {
    return value === null || value === undefined || value.toString().length === 0;
}

export function createParamsForGet(params: any): string {
    const value = Object.keys(params).reduce((list: any, key: string) =>
        isEmpty(params[key]) ? list : [...list, `${key}=${params[key]}`], []).join('&');
    return value.length > 0 ? `?${value}` : '';
}

export function getTaskByGroupId(tasks: any, groupId?: string) {
    if (!Array.isArray(tasks))
        return null;

    return [...tasks].reduce((rs: any, task: any, i: number, arr: any) => {
        const {[KeyOfTask.SUB_TASKS]: sub_tasks} = task;
        if (task.group_id === groupId) {
            arr.splice(i);
            rs = task;
        } else if (sub_tasks) {
            let data;
            if (Array.isArray(sub_tasks)) {
                data = sub_tasks.find((sub: any) => sub.group_id === groupId);
            } else {
                data = Object.keys(sub_tasks).reduce((subResult: any, key: any, subIndex, subArr) => {
                    const data = sub_tasks[key].find((sub: any) => sub.group_id === groupId);
                    if (data) {
                        subArr.splice(subIndex);
                        rs = data;
                    }
                    return rs;
                }, null)
            }
            if (data) {
                arr.splice(i);
                rs = data;
            }
        }
        return rs;
    }, null)
}

export function convertAutoRoute(data: any) {
    const {features = []} = data;
    return features.reduce((rs: any, item: any) => {
        const {type = null, coordinates = []} = item.geometry || {};
        // if (type === 'MultiLineString') {
        // rs = [...rs, ...coordinates[0].map(([lng, lat]: any) => ({lat, lng}))]
        if (type === 'Point') {
            const [lng, lat] = coordinates;
            rs.push({lat, lng});
        }
        return rs;
    }, []);
}

interface ICompareInCircle {
    point: {
        x: number
        y: number
    },
    o: {
        x: number
        y: number
    },
    radius: number
}

export function compareInCircle(body: ICompareInCircle): boolean {
    const {point, o, radius} = body;
    return Math.pow(point.x - o.x, 2) + Math.pow(point.y - o.y, 2) <= Math.pow(radius, 2);
}

export function clearSelect() {
    if (window.getSelection) {
        const selection = window.getSelection();
        if (selection) {
            if (selection.empty) {
                selection.empty();
            } else if (selection.removeAllRanges) {
                selection.removeAllRanges();
            }
        }
    } else {
        const {selection}: any = document;
        if (selection) {
            selection.empty();
        }
    }
}

export function validationNumberOrder(arr: number[]) {
    for (let i = 0; i < (arr.length - 1); i++) {
        if (+arr[i] >= +arr[i + 1]) {
            return false;
        }
    }
    return true;
}

/*
    Date: 20/04/2023
    Author: Vinh.Pham
    Description: Get start finish by operation mode (plan time or est time)
 */
const getStartFinish: any = {
    normal: (activityLog: IActivityLog[]) => {
        const start = activityLog[0].est_start_time;
        const finish = activityLog[activityLog.length - 1].est_start_time;
        return {start, finish}
    },
    [OP_STATUS.PROCESSING]: (activityLog: IActivityLog[]) => {
        const start = activityLog[0].real_start_time;
        const finish = activityLog[activityLog.length - 1].eta || 0;
        return {start, finish}
    },
    [OP_STATUS.FINISHED]: (activityLog: IActivityLog[]) => {
        const start = activityLog[0].real_start_time || 0;
        const finish = activityLog[activityLog.length - 1].real_start_time || 0;
        return {start, finish}
    },
}

export const getStartFinishByOpMode = {
    [OPERATION_MODE.PLAN]: getStartFinish.normal,
    [OPERATION_MODE.ETA]: (activityLog: IActivityLog[], status?: OP_STATUS) => {
        const action = getStartFinish[status || ''] || getStartFinish.normal;
        return action(activityLog)
    }
}

/*
    Date: 25/04/2023
    Author: Vinh.Pham
    Description: Add class to element
 */
export function addClassName(el: any, name = 'no-select') {
    const {className} = el;
    if (className.indexOf(name) === -1)
        el.className += ` ${name}`
}

/*
    Date: 25/04/2023
    Author: Vinh.Pham
    Description: Remove class to element
 */
export function removeClassName(el: any, name = 'no-select') {
    const {className} = el;
    el.className = className.split(` ${name}`).join('');
}

/*
    Date: 25/04/2023
    Author: Vinh.Pham
    Description: Get width of element by className
 */
export function getWidthByClassName(className: string) {
    const elementVessels: any = document.getElementsByClassName(className);
    const elementVessel: any = Array.from(elementVessels).find((item: any) => item.offsetWidth);
    return elementVessel.offsetWidth;
}

/*
    Date: 18/05/2023
    Author: Vinh.Pham
    Description: Generate option of antd
 */
export function renderOpts(list: { id: any, name: string }[]) {
    return list.map((item: any) => ({value: item.id, label: item.name}));
}

/*
    Date: 23/05/2023
    Author: Vinh.Pham
    Description: Change loading of operation element
 */
export function changeLoadingOp(el: any, value: boolean) {
    if (el)
        el.dataset.loading = value
}

/*
    Date: 15/11/2023
    Author: Vinh.Pham
    Description: Clone object without preference
 */
export function cloneObj(data: any) {
    return JSON.parse(JSON.stringify(data))
}

/*
    Date: 20/02/2024
    Author: Vinh.Pham
    Description: UUID with Datetime
 */
export function uuid() {
    return UUID() + '/' + Date.now();
}

/*
    Date: 01/04/2024
    Author: Vinh.Pham
    Description: Convert number to percent
 */
export function convertPercent(value: any) {
    return typeof value !== 'number' ? 'N/A' : Math.round(value * 100) / 100 + '%'
}
