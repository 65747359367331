import React from 'react';
import styles from './style.module.scss';
import {TOperation} from "../../util/varibles/interface";

interface IProps {
    visible?: boolean
    operation: TOperation
}

const Factory: React.FC<IProps> = (props) => {
    const {visible = true} = props;
    if (!visible) return null;

    const {factory, factory_name} = props.operation;
    const {name = factory_name} = factory || {};

    return <div className={styles.factory}>
        {name}
    </div>;
};

export default Factory;
