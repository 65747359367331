import React, {ReactElement, ReactNode} from 'react';
import {KEY_STORAGE, PATHNAME} from "../../util/varibles/constants";
import {Navigate, useLocation} from "react-router-dom";
import Header from "../Header";
import MessageMini from "../Chat/MessageMini";
import {IPropsChildren} from "../../util/varibles/interface";

export const requireAuth = (children: ReactNode | ReactElement) => <RequireAuth>
    {children}
</RequireAuth>;

interface IProps extends IPropsChildren {

}

const RequireAuth: React.FC<IProps> = ({children}) => {
    const location = useLocation();

    if (!localStorage.getItem(KEY_STORAGE.AUTHENTICATION)) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={PATHNAME.LOGIN} state={{from: location}} replace/>;
    }

    return <>
        <Header/>
        <MessageMini/>
        {children}
    </>;
};

export default RequireAuth;