import React from 'react';

interface IProps {
    data: any[]
}

const List: React.FC<IProps> = (props) => {
    return <div>
        
    </div>;
};

export default List;
