import {forwardRef, ReactNode, Ref, useEffect, useImperativeHandle, useRef} from "react";
import {useMap} from "@vis.gl/react-google-maps";
import ReactDOM from "react-dom/client";

export type OverlayViewRef = Ref<google.maps.OverlayView | null>;

type OverlayViewEventProps = {};

type OverlayViewCustomProps = {
    position: google.maps.LatLng | google.maps.LatLngLiteral,
    content: any,
};

export type OverlayViewProps = OverlayViewEventProps &
    OverlayViewCustomProps;

function useOverlayView() {
    const overlayView = useRef(new google.maps.OverlayView() as any).current;

    useEffect(() => {
        const element = document.createElement('div');
        const root = ReactDOM.createRoot(element as HTMLElement);
        overlayView.onAdd = function () {
            const panes = overlayView.getPanes();
            if (panes) {
                element.style.position = 'absolute';
                panes.floatPane.appendChild(element);
                element.onclick = function (event) {
                    event.stopPropagation();
                }
            }
        }
        overlayView.onRemove = () => {
            if (element.parentElement) {
                element.parentElement.removeChild(element);
            }
        }
        const setContent = (content: ReactNode) => {
            overlayView.content = content;
            root.render(content);
        }
        const setPosition = (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => {
            overlayView.position = latLng;
            const point = overlayView.getProjection()?.fromLatLngToDivPixel(latLng);
            if (!point)
                return;

            const display = Math.abs(point.x) < 4000 && Math.abs(point.y) < 4000;
            if (display) {
                element.style.opacity = '1';
                element.style.visibility = 'visible';
                element.style.left = point.x + 'px';
                element.style.top = point.y + 'px';
            } else {
                element.style.opacity = '0';
                element.style.visibility = 'hidden';
            }
        }

        overlayView.setContent = setContent

        overlayView.setPosition = setPosition

        overlayView.draw = () => {
            setPosition(overlayView.position)
            setContent(overlayView.content)
        }

        return () => {
            element.remove()
        }
    }, []);

    return overlayView
}

export const OverlayView = forwardRef(({content, position}: OverlayViewProps, ref: OverlayViewRef) => {
    const overlayView = useOverlayView();
    const map = useMap();

    useImperativeHandle(ref, () => overlayView, []);

    useEffect(() => {
        if (!map) {
            console.error('<OverlayView> has to be inside a Map component.');
        } else
            overlayView.setMap(map);

        return () => {
            overlayView.setMap(null)
        };
    }, []);

    useEffect(() => {
        overlayView.setContent(content);
    }, [content])


    useEffect(() => {
        overlayView.setPosition(position);
    }, [position])

    return null;
});
