const prefix = {
    dashboard: 'dashboard/',
    preference: 'preference/',
    plan: 'plan/',
    overview: 'overview/',
    route: 'route/'
}

export const DashboardSetting = {
    SECTION_FILTER: prefix.dashboard + 'sectionFilter',
    FACTORIES: prefix.dashboard + 'factories',
    PRODUCT_AREA: prefix.dashboard + 'productionArea',
    PRODUCT_AREAS: prefix.dashboard + 'productionAreas',
    ACTIVE_SITE: prefix.dashboard + 'activeSite',
    DISEASED_ZONE: prefix.dashboard + 'diseasedZone',
    VESSELS: prefix.dashboard + 'vessels',
    MAP_STYLE: prefix.dashboard + 'mapStyle'
}


export const OverviewSetting = {
    VESSEL_FILTER: prefix.overview + 'vesselFilter',
    WEEK_PER_PAGE: prefix.overview + 'weekPerPage'
}

export const PreferenceSetting = {
    VIEW_MODE: prefix.preference + 'viewMode',
    FACTORIES_TIME: 'factoriesTime',
    SITES_TIME: 'sitesTime',
    PRIORITY_TIME: 'priorityTime',
    SHOW_CERTAINTY: 'isCertainty',
    AUTO_PLAN: 'autoPlanConfig',
    CUSTOMIZE_HARVEST: 'customizeHarvest',
    THEME_COLOR: prefix.preference + 'themeColor'
}

export const PlanSetting = {
    GROUP_BY_WEEK: 'planGroupByWeek',
    GROUP_BY_UNIT: 'planGroupByUnit',
    SHOW_STHP_EMPTY: 'planShowAllPlanned',
    STHP_PRIOR_WEEKS: prefix.plan + 'sthp/priorWeeks',
    BIOLOGY_TAB: 'planStoreTabs',
    BIOLOGY_LICE_SORT: prefix.plan + 'biologyLiceSort',
    BIOLOGY_SITE: 'biologySiteFilter',
    BIOLOGY_PROD_AREA: 'biologyProdAreaFilter',
    BIOLOGY_AVG_WEIGHT: 'smoltProdAreaFilter',
    STHP_SITE_FILTER: 'plannedSiteFilter',
    STHP_FACTORY_FILTER: 'plannedFactoryFilter',
    STHP_PROD_AREA_FILTER: 'plannedProdAreaFilter',

    SMOLT_SITE: 'smoltFilter',
    SMOLT_PROD_AREA: 'smoltProdAreaFilter',
    SMOLT_LICE_SORT: prefix.plan + 'smoltLiceSort',
    VESSEL_FILTER: 'planVessels',
}

export const RouteSetting = {
    AUTO_ROUTE: prefix.route + 'autoRoute'
}

export const GlobalSetting = {
    WEATHER: 'weatherFilter',
    MAP_STYLE: 'googleMapStyle',
    MAIL: 'mail'
}

