import React, {Component} from 'react';
import {eventSteps, eventTypes} from "../../../../util/varibles/constants";
import {durationTxtByStartAndFinish} from "../../../../util/varibles/global";
import Operation from "../../_Component/Operation";
import stylesOp from "../../_Component/Operation/style.module.scss";
import Vessel from "../../_Component/Vessel";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import SectionRight from "../../_Component/SectionRight";
import WrapperActivityLog from "../../_Component/WrapperActivityLog";
import {IOperation} from "../../../../util/varibles/interface";
import ViewNote from "../../_Component/ViewNote";
import PlannedTime from "../../_Component/PlannedTime";

interface IProps {
    data: IOperation
    isOwn: boolean
    viewMode: VIEW_MODE
}

class Event extends Component<IProps> {

    render() {
        const {note = '', event_type} = this.props.data.operation;

        const {activity_log} = this.props.data
        const est_start_time = activity_log[0].est_start_time;
        const est_finish_time = activity_log[activity_log.length - 1].est_start_time;

        const activityLog = activity_log.map((item: any, index: number) => ({
            list: [{...item, steps: [eventSteps[index]], index}],
        }))

        const event = eventTypes.find((item: any) => item.id === event_type) || {name: ''};
        return <>
            <div>
                <Operation {...{isNote: note.length === 0}}>
                    <div className={stylesOp['info-line']}>
                        <span>Operation type: </span>
                        <span>{['Event', event.name].join((' - '))}</span>
                    </div>
                    <PlannedTime activity_log={activity_log}/>
                    <div className={stylesOp['info-line']}>
                        <span>Planned duration: </span>
                        <span>{durationTxtByStartAndFinish(est_start_time, est_finish_time)}</span>
                    </div>
                    <ViewNote data={this.props.data} isOwn={this.props.isOwn} note={note}/>
                </Operation>
                <Vessel>
                    <div/>
                </Vessel>
            </div>
            <SectionRight>
                <WrapperActivityLog
                    isOwn={this.props.isOwn}
                    data={this.props.data}
                    activity_log={activityLog}
                    activity_log_root={activity_log}
                />
            </SectionRight>
        </>
    }
}

export default Event;