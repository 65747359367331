import React, {Component} from 'react';
import {Modal} from "antd";
import styles from './style.module.scss';
import TextArea from "antd/es/input/TextArea";
import {openPopup} from "../Component/WrapperPopup";
import Footer from "../Component/Footer";
import {TOperation} from "../../../util/varibles/interface";
import {updateOperationNoteFetch} from "../../../util/services/operation";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {showErrorResponse} from "../../../util/varibles/global";
import Header from "../Component/Header";

interface IOpenNote {
    title?: string
    note: string
    operation?: TOperation

    onSave(note: string): void

    onCancel?(): void
}

export function openNote(params: IOpenNote) {
    const {title, note, operation, onSave, onCancel} = params;
    const modalNode = openPopup(<PopupOpNote {...{
        isShow: true,
        info: title ? {code: title} : {code: 'Treatment'},
        note: note.trim(),
        operation: operation ? operation : undefined,
        destroyOnClose: true,
        handleSave: onSave,
        handleClose: () => {
            modalNode.remove();
            if (onCancel)
                onCancel();
        }
    }}/>);
}

interface IProps {
    note?: string
    isShow: boolean
    info: any
    operation?: TOperation
    destroyOnClose: boolean

    handleSave(value: string): void

    handleClose?(): void
}

interface IState {
    isShow: boolean
    disabled: boolean
    loading: boolean
    value: string
    bounds: any
}

class PopupOpNote extends Component<IProps, IState> {
    static defaultProps = {
        loading: false,
        destroyOnClose: true,
        note: ''
    }

    state = {
        isShow: false,
        disabled: true,
        loading: false,
        value: '',
        bounds: {left: 0, top: 0, bottom: 0, right: 0},
    }

    dragRef: any = React.createRef();
    inputRef: any = React.createRef();

    constructor(props: IProps) {
        super(props);
        const {isShow, note = ''} = this.props;
        this.state = {
            isShow,
            disabled: true,
            loading: false,
            value: note,
            bounds: {left: 0, top: 0, bottom: 0, right: 0},
        }
    }


    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {isShow, note = ''} = nextProps;
        if (this.props.isShow !== isShow)
            this.setState({isShow, value: note});
        return this.state !== nextState;
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.state.isShow !== prevState.isShow)
            setTimeout(() => {
                if (this.inputRef.current)
                    this.inputRef.current.focus({cursor: 'end'});
            }, 1);
    }

    handleClear = () => {
        this.setState({value: ''});
    }

    handleSave = () => {
        const note = this.state.value.trim();
        const {operation} = this.props;
        if (operation) {
            const {id, operation_code} = operation;
            this.setState({loading: true});
            new Promise(resolve => resolve(updateOperationNoteFetch({note}, id)))
                .then(() => {
                    notify.success(NotifyCode.S2)([`Note of #${operation_code}`]);
                    this.setState({loading: false, isShow: false});
                    this.props.handleSave(note);
                })
                .catch(error => {
                    this.setState({loading: false});
                    showErrorResponse('Update failed', error)
                })
        } else {
            this.props.handleSave(note);
            this.setState({isShow: false});
        }
    }

    handleChange = (e: any) => {
        const {value} = e.target;
        if (value.length <= 500)
            this.setState({value})
    }

    handleDrag = (event: any, uiData: any) => {
        const {innerWidth, innerHeight} = window;
        if (this.dragRef.current) {
            const targetRect = this.dragRef.current.getBoundingClientRect();
            this.setState({
                bounds: {
                    left: -targetRect.left + uiData.x,
                    right: innerWidth - (targetRect.right - uiData.x),
                    top: -targetRect.top + uiData.y,
                    bottom: innerHeight - (targetRect.bottom - uiData.y),
                },
            });
        }
    };

    handleClose = () => {
        this.setState({isShow: false});
        if (this.props.handleClose)
            this.props.handleClose();
    }

    render() {
        const {isShow, value = '', loading} = this.state;
        const {info, destroyOnClose} = this.props;
        const {code} = info;
        const props: any = {
            open: isShow,
            title: <Header title={`Note for ${code}`} onClose={this.handleClose}/>,
            closable: false,
            keyboard: true,
            footer: null,
            centered: true,
            wrapClassName: styles['wrap-modal'],
            destroyOnClose,
            onCancel: this.handleClose,
            modalRender: (modal: any) => <div ref={this.dragRef}>{modal}</div>
        }

        return <Modal {...props} styles={{body: {padding: 0}}}>
            <div className={styles['content']}>
                <TextArea {...{
                    className: 'scroll-small',
                    ref: this.inputRef,
                    rows: 4,
                    value,
                    onChange: this.handleChange
                }}/>
                <div className='mt-10 text-comment'>{value.length}/500 characters</div>
            </div>
            <Footer
                className={styles.footer}
                ok={{
                    loading,
                    click: this.handleSave
                }}
                cancel={{
                    click: this.handleClose
                }}
            />
        </Modal>;
    }
}

export default PopupOpNote;
