import React, {useMemo} from 'react';
import styles from "./style.module.scss";
import {Button, Select} from "antd";
import {propsSelect} from "../../../../../../../util/varibles/template";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../../../util/store/selectors";
import {ISite, ISubDestination, IUnit, TOperation} from "../../../../../../../util/varibles/interface";
import {checkRoute2, getAmountAndWeightFromGroup} from "../../../../../util";
import {cloneObj, formatNumber} from "../../../../../../../util/varibles/global";
import {ACTION_TYPE} from "../../../../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import {getEditOp} from "../../../../../../../contexts/EditOperationContext";
import InputNumber from "../../../../../../../components/Basic/InputNumber";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";

interface ISDestination {
    data: ISubDestination
    opts: IUnit[]

    onChange(data: ISubDestination, isCalculate?: boolean): void

    onChangeValue(value: { fish_amount: number, total_weight: number }): void
}

const SubDestination: React.FC<ISDestination> = ({data, opts, onChange, onChangeValue}) => {
    const {avg_weight} = data;
    const avgWeight = Math.max(avg_weight, 1);

    return <>
        <Select
            {...propsSelect}
            key={`select-${data.id}`}
            className={styles.select}
            value={data.delivery_id}
            options={opts.map(item => ({value: item.id, label: item.unit_id}))}
            onSelect={(...args) => onChange({...data, delivery_id: `${args[1]?.value}`, delivery_name: `${args[1]?.label}`})}
        />
        <InputNumber
            key={`input-${data.id}`}
            className='text-right'
            placeholder='Count'
            value={data.fish_amount}
            onChange={(value: number) => {
                const fish_amount = Math.round(value);
                const total_weight = Math.round(fish_amount * avgWeight);
                onChangeValue({fish_amount, total_weight})
            }}
        />
        <InputNumber
            key={`weight-${data.id}`}
            className='text-right'
            placeholder='Total weight'
            value={Math.round(data.total_weight / 1000) / 1000}
            onChange={(value: number) => {
                const total_weight = Math.round(value * 1000000);
                const fish_amount = avg_weight !== -1 ? Math.round(total_weight / avgWeight) : 0;
                onChangeValue({fish_amount, total_weight})
            }}
            onBlur={() => avg_weight > 0 ? onChange({...data, total_weight: data.fish_amount * avgWeight}, true) : null}
            suffix={'t'}
        />
    </>
}

interface IProps {
    data: TOperation

    onChange(value: TOperation): void

    onDelete(indexDetail: number): void
}

const Destination: React.FC<IProps> = ({data, onChange, onDelete}) => {
    const sites = useSelector(selectPlan.sites);
    const routeIds = useSelector(selectPlan.routeIds);
    const {source_id, destination_id = '', sub_destinations = []} = data;
    const get = getEditOp();
    const {editMode} = get();

    const opts = useMemo(() => {
        const {units = []} = sites.find(item => item.id === destination_id) || {};
        return units;
    }, [destination_id, sites]);

    const handleChangeDestination = (value: ISite) => {
        const {source_name, error_detail} = data;
        const {error, message} = checkRoute2({id: source_id, name: source_name}, value, routeIds)
        if (error === 2) {
            error_detail.route = [{source_id: source_id, destination_id: value.id, message}]
        } else {
            delete error_detail.route;
        }
        onChange({
            ...data,
            destination_id: value.id,
            destination_name: value.name,
            error_detail,
            sub_destinations: sub_destinations.map(item => ({
                ...item,
                delivery_id: '',
                delivery_name: '',
            }))
        })
    }

    const handleChange = (value: ISubDestination, index: number, isCalculate?: boolean) => {
        const result = cloneObj(sub_destinations);
        result.splice(index, 1, value);
        if (isCalculate) {
            const {total_weight, fish_amount} = getAmountAndWeightFromGroup(result)
            onChange({...data, total_amount: fish_amount, total_weight, sub_destinations: result})
        } else
            onChange({...data, sub_destinations: result})
    }

    const handleChangeValue = (value: IUnit, index: number) => {
        const {total_weight: opTotalWeight} = data;
        const {vessel} = get();
        const {capacity = 0} = vessel || {};
        const {total_weight: oldTotalWeight} = sub_destinations[index];
        let {fish_amount, total_weight, avg_weight} = value;
        if ((opTotalWeight - oldTotalWeight + total_weight) > capacity) {
            const subTotalWeight = capacity - opTotalWeight + oldTotalWeight;
            fish_amount = avg_weight < 0 ? 0 : Math.floor(subTotalWeight / avg_weight);
            total_weight = avg_weight < 0 ? subTotalWeight : fish_amount * avg_weight;
            notify.error(NotifyCode.E1)();
        } else {
            // if ((fish_amount - oldFishAmount) > Math.max(0, rootFishAmount) || (total_weight - oldTotalWeight) > rootTotalWeight)
            //     notify.warn(NotifyCode.W6)();
        }
        const result = cloneObj(sub_destinations);
        result.splice(index, 1, {...value, fish_amount, total_weight});
        const {total_weight: newOpTotalWeight, fish_amount: newOpFishAmount} = getAmountAndWeightFromGroup(result)
        onChange({...data, total_amount: newOpFishAmount, total_weight: newOpTotalWeight, sub_destinations: result})

    }

    const children = useMemo(() => {
        let result = sub_destinations.reduce((list: any, item: any, index: number) => {
            if (list[item.id]) {
                list[item.id].fish_amount += item.fish_amount;
                list[item.id].total_weight += item.total_weight;
                list[item.id].detail.push({...item, index});
            } else
                list[item.id] = {...item, detail: [{...item, index}]};
            return list;
        }, {});

        return Object.keys(result).reduce((list: any, key) => [...list, result[key]], []);
    }, [sub_destinations]);


    return <>
        {children.map((unit: any, indexChild: number) => {
            const style: any = {'--row-span': unit.detail?.length || 1}
            return <div {...{
                key: [unit.id, unit.destination_id].join('_'),
                className: styles.line,
                style,
                'data-lev': 'sub'
            }}>
                <div
                    className={[styles.fullRow, 'pl-10'].join(' ')}
                    title={`${unit.unit_id}: ${formatNumber(unit.fish_amount)}`}
                >
                    {unit.unit_id}: {formatNumber(unit.fish_amount)}
                </div>
                {indexChild === 0 && <div className={styles.fullRow}>
                    <Select
                        {...propsSelect}
                        className={styles.select}
                        value={destination_id}
                        options={sites.reduce((rs: { value: string, label: string, site: ISite }[], item) => item.id !== source_id
                            ? [...rs, {label: item.name, value: item.id, site: item}]
                            : rs, [])}
                        onSelect={(...args) => handleChangeDestination(args[1].site)}
                    />
                </div>}
                {unit.detail.map((item: any, i: number) => [
                    <SubDestination
                        data={item}
                        opts={opts}
                        onChange={(value, isCalculate) => handleChange(value, item.index, isCalculate)}
                        onChangeValue={(value) => handleChangeValue({...item, ...value}, item.index)}
                    />,
                    editMode === ACTION_TYPE.CREATE ? <Button
                        key={`action-${i}`}
                        className='bt-primary'
                        data-icon={true}
                        data-danger={true}
                        onClick={() => onDelete(item.index)}
                    >
                        <Icon icon={ICON_NAME.DELETE}/>
                    </Button> : null
                ])}
            </div>
        })}
    </>
};

export default Destination;
