import {all, call, put, takeEvery} from "redux-saga/effects";
import {UserService} from "../../util/services/user"
import {AccountService} from "../../util/services/account"
import {SiteService} from "../../util/services/site";
import {FactoryService} from "../../util/services/factory";
import {userActions} from "./reducer";

function* getUsersWatcher(): Generator {
    try {
        const users = yield call(UserService.gets);
        yield put(userActions.getUsersSuccess(users))
    } catch (error) {
        yield put(userActions.getUsersFailure());
    }
}

function* getTenantWatcher(): Generator {
    try {
        const customers = yield call(AccountService.tenant);
        yield put(userActions.getTenantsSuccess(customers));
    } catch (error) {
        yield put(userActions.getTenantsFailure());
    }
}

function* getSitesWatcher(): Generator {
    try {
        const sites = yield call(SiteService.getBySAdmin) || [];
        yield put(userActions.getSitesSuccess(sites));
    } catch (error) {
        yield put(userActions.getSitesFailure());
    }
}

function* getFactoriesWatcher(): Generator {
    try {
        const factories = yield call(FactoryService.getBySuperAdmin) || [];
        yield put(userActions.getFactoriesSuccess(factories));
    } catch (error) {
        yield put(userActions.getFactoriesFailure());
    }
}

export default function* userSaga() {
    yield all([
        takeEvery(userActions.getUsersRequest.type, getUsersWatcher),
        takeEvery(userActions.getTenantsRequest.type, getTenantWatcher),
        takeEvery(userActions.getSitesRequest.type, getSitesWatcher),
        takeEvery(userActions.getFactoriesRequest.type, getFactoriesWatcher),
    ])
}
