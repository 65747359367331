import {datetime} from "../../../../util/library/datetime";

export const options: any = [
    {label: 'Vessel list', value: 'vessel'},
    {label: 'Requested list', value: 'request'},
];

export const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

export function disabledDate(current: any, target = Date.now()) {
    // Can not select days before today and today
    return current < datetime(target).endOf('day').time;
}

export enum ModeTabVesselPopup {
    List = 'available',
    Requests = 'requests'
}
