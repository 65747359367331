import React, {useCallback} from 'react';
import {IOperation} from "../../../../util/varibles/interface";
import {getElById} from "../../../../util/varibles/global";
import {VIEW_SMOOTH} from "../../../../util/varibles/constants";
import styles from './style.module.scss';

interface IProps {
    op: IOperation
    unitIndex: number
}

const ErrorBox: React.FC<IProps> = ({op, unitIndex}) => {
    const {sub_operations, operation_code, id} = op.operation;
    const {unit_id} = sub_operations[unitIndex];
    const handleClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        const el = getElById(id)
        if (el)
            el.scrollIntoView(VIEW_SMOOTH)
    }, [id])

    return <div>
        {op.vessel.name} - <b className={styles.link} onClick={handleClick}>#{operation_code}</b>: Total weight of
        unit {unit_id} is empty.
    </div>
};

export default ErrorBox;

export const generateError = (op: IOperation, unitIndex: number) => <ErrorBox op={op} unitIndex={unitIndex}/>