import styles from './style.module.scss';
import {Button} from "antd";
import PopupUnit from "./PopupUnit";
import {IUnit} from '../../../../../../util/varibles/interface';
import {openPopup} from "../../../../../../components/Popup/Component/WrapperPopup";
import Icon, {ICON_NAME} from "../../../../../../components/Icon";
import React from "react";
import {getEditOp, IUnitWhenEdit, setEditOp} from "../../../../../../contexts/EditOperationContext";
import {initTask} from "../../Body/Treatment/constants";

interface IProps {
    isEmpty?: boolean
}

const AddUnit: React.FC<IProps> = ({isEmpty = false}) => {
    const get = getEditOp();
    const set = setEditOp();

    const handleOpenPopup = () => {
        const {site, units} = get();
        if (!site)
            return;

        const modalNode = openPopup(<PopupUnit {...{
            sites: [site],
            except: Object.keys(units).reduce((rs: IUnitWhenEdit[], key) => {
                const {tasks = []} = units[key];
                if (tasks.length > 0) {
                    rs.push(units[key])
                }
                return rs
            }, []),
            isShow: true,
            isEmpty,
            onAdd: (value: IUnit[]) => {
                set(({units}) => ({
                    units: value.reduce((rs: { [id: string]: IUnitWhenEdit }, item) => {
                        const {[item.id]: current, ...args} = rs;
                        return {
                            ...args,
                            [item.id]: {...current, tasks: [initTask(item)]}
                        }
                    }, units)
                }))
            },
            onClose: () => modalNode.remove()
        }}/>)
    }

    return <div className={styles['container-add-unit']}>
        <Button className='bt-primary pl-10 pr-10' data-icon={true} onClick={handleOpenPopup}>
            <Icon icon={ICON_NAME.ADD} size='small'/>
            <span className='ml-5'>Add unit</span>
        </Button>
    </div>;
};

export default AddUnit;
