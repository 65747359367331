import React, { Component } from 'react';
import ContainerElement from "./ContainerElement";
import { IPropsElement } from "./constants";
import { Select } from "antd";
import styles from './style.module.scss';

interface IProps extends IPropsElement {

}

class DropdownElement extends Component<IProps> {

    filterSelect = (inputValue: any, option: any) => option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

    render() {
        const { Option } = Select;
        const { options = '', multipleSelections } = this.props.properties;
        const attrSelect: any = multipleSelections ? { mode: "multiple", showArrow: true } : {};
        return <ContainerElement { ...this.props } >
            <Select { ...attrSelect } filterOption={ this.filterSelect }>
                { options.split('\n').map((item, index) =>
                    <Option className={styles.option} key={ index } value={ index }>{ item }</Option>)
                }
            </Select>
        </ContainerElement>;
    }
}

export default DropdownElement;
