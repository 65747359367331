import React, {useEffect, useRef, useState} from 'react';
import {durationTxtByStartAndFinish} from "../../util/varibles/global";

interface IProps {
    time: number
    loop?: number // milliseconds (default: 20000) 0 to disable auto-update.
}

const DurationReel: React.FC<IProps> = ({time, loop = 20000}) => {
    const interval = useRef<NodeJS.Timer | null>(null);
    const [current, setCurrent] = useState(Date.now());
    useEffect(() => {
        interval.current = setInterval(() => {
            setCurrent(Date.now())
        }, loop)

        return () => {
            if (interval.current)
                clearInterval(interval.current)
        }
    }, []);
    const duration = durationTxtByStartAndFinish(time, current, true);

    return <>
        {duration.length > 0 ? `Updated ${duration} ago` : 'Just updated'}
    </>;
};

export default DurationReel;
