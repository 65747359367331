import React from 'react';
import {CONTRACT_TYPE} from "../../../../../util/varibles/constants";
import Contract from "./Contract";
import {IContract, IVessel} from "../../../../../util/varibles/interface";
import {getPlan} from "../../../../../contexts/PlanOperationContext/Plan";

interface IProps {
    contracts: IContract[]
    vessel: IVessel
    vesselIndex: number
    width: number
}

const Contracts: React.FC<IProps> = (props) => {
    const {userTenantId} = getPlan()();
    const {vesselIndex, width, contracts} = props;

    return <>
        {contracts.map((item) => {
            if (item.type === CONTRACT_TYPE.FIXED)
                return <Contract key={item.id} {...{
                    vesselIndex,
                    width,
                    contract: item,
                    vessel: props.vessel,
                    isOwn: item.tenant_id === userTenantId,
                }}/>
            return null
        }, [])}
    </>
};

export default Contracts;
