import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {KEY_TAB, OPERATION_TYPE, SITE_TYPE} from "../../../../util/varibles/constants";
import {UnitModel} from "../constants";
import HeaderSmoltComponent from "./HeaderSmoltComponent";
import stylesContainer from "../style.module.scss";
import {ISite, IUnit} from "../../../../util/varibles/interface";
import {PlanSetting} from "../../../../util/varibles/userSetting";
import Cards from "../_Component/Cards1";
import Card from "../_Component/Cards1/Card";
import Location from "../_Component/Location";
import UnitDiseases from "../_Component/UnitDiseases";
import FishDisease from "../../../../components/Location/FishDisease";
import FishAndWeight from "../_Component/FishAndWeight";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import Site from "../../Popup/Site";
import UnitLice from "../../../../components/Operation/UnitLice";
import {selectSetting} from "../../../../util/store/selectors";
import {setBiology} from "../../../../contexts/PlanOperationContext/Biology";
import {setTabBiology} from "../../../../contexts/PlanOperationContext/TabBiologyContext";

interface IState {
    searchValue: string
    weightFilter: [number | null, number | null],
}

const initState: IState = {
    searchValue: '',
    weightFilter: [null, null],
}

interface IProps {
    loading: boolean
    smoltSites: ISite[]
}

const SmoltSite: React.FC<IProps> = (props) => {
    const {
        [PlanSetting.SMOLT_SITE]: smoltFilter = [],
        [PlanSetting.SMOLT_PROD_AREA]: smoltProdAreaFilter = [],
        [PlanSetting.SMOLT_LICE_SORT]: liceSort = null
    } = useSelector(selectSetting([PlanSetting.SMOLT_SITE, PlanSetting.SMOLT_PROD_AREA, PlanSetting.SMOLT_LICE_SORT]))
    const [form, setForm] = useState(initState);
    const {smoltSites, loading} = props;
    const set = setBiology();
    const setTab = setTabBiology();

    useEffect(() => {
        set(({clear}) => ({
            clear: {...clear, smolt: () => setTab({selected: {}})}
        }))
    }, [set, setTab]);

    const {searchValue, weightFilter} = form;
    const countSite = smoltFilter.length;
    const countProdArea = smoltProdAreaFilter.length;
    const listOfSiteFilter = new Set(smoltFilter);
    const listOfProdAreaFilter = new Set(smoltProdAreaFilter);
    const [minWeight, maxWeight] = weightFilter;
    const liceInSites: ISite[] = [];
    const normal = smoltSites.reduce((list: any[], site: ISite) => {
        const {id, name, prod_area = ''} = site
        const isSearch = searchValue.length === 0
            || (searchValue.length > 0 && name.indexOf(searchValue.toLowerCase()) !== -1)
        if (!isSearch) return list;

        const isSite = (countSite === 0 || listOfSiteFilter.has(id))
            && (countProdArea === 0 || listOfProdAreaFilter.has(prod_area))
        if (!isSite) return list;

        const {lice} = site.diseases || {};
        if (lice && lice[liceSort]) {
            liceInSites.push(site)
            return list;
        }

        return [...list, ...site.units.reduce((items: any, unit: IUnit) => {
            const {avg_weight = 0, fish_amount} = unit;
            const isMin = minWeight ? (minWeight <= avg_weight && fish_amount) : true;
            if (!isMin) return items;

            const isMax = maxWeight ? (maxWeight >= avg_weight && fish_amount) : true;
            if (!isMax) return items;

            return [...items, UnitModel(unit, site)]
        }, [])]
    }, [])
    const units = [
        ...liceInSites
            .sort((a: ISite, b: ISite) => {
                const {[liceSort]: liceA} = a.diseases.lice || {};
                const {[liceSort]: liceB} = b.diseases.lice || {};
                return liceB.value - liceA.value
            })
            .reduce((rs: any, site) => [...rs, ...site.units.reduce((items: any, unit: IUnit) => {
                const {avg_weight, fish_amount} = unit;
                const isMin = minWeight ? (minWeight <= avg_weight && fish_amount) : true;
                const isMax = maxWeight ? (maxWeight >= avg_weight && fish_amount) : true;

                if (!isMin || !isMax)
                    return items;
                return [...items, UnitModel(unit, site)]
            }, [])], []),
        ...normal
    ];

    const handleOpenSite = (unit: IUnit) => {
        const el = openPopup(<Site
            site={{id: unit.site_id || '', name: unit.site_name || ''}}
            unitId={unit.id}
            onClose={() => el.remove()}
        />)
    }


    return <div id={KEY_TAB.SMOLT} className={stylesContainer['wrapper-tab']}>
        <HeaderSmoltComponent {...{
            searchValue,
            liceSort,
            weightFilter,
            handleSearch: value => setForm(prev => ({...prev, ...value})),
            sites: smoltSites
        }} />
        <div className={stylesContainer['tab-body']}>
            <Cards<IUnit>
                data={units}
                loading={loading}
                child={{
                    height: 80,
                    View: ({data}) => {
                        const {unit_id, site_name = '', fish_amount, type, diseases = [], lice} = data;
                        const isInternal = type === SITE_TYPE.INTERNAL;
                        const isActive = !(isInternal && fish_amount <= 0);

                        return <Card
                            data={data}
                            isActive={isActive}
                            key={data.id}
                            style={{height: '80px', maxHeight: '80px'}}
                            types={[OPERATION_TYPE.HARVEST, OPERATION_TYPE.TREATMENT, OPERATION_TYPE.SERVICE]}
                            onContext={() => handleOpenSite(data)}
                        >
                            <div className={stylesContainer.line}>
                                <div className={stylesContainer.infoLine}>
                                    <Location source={[site_name, `, ${unit_id}`]}/>
                                    {isInternal && <FishAndWeight data={data}/>}
                                </div>
                                <div className={stylesContainer.infoLine} data-right={true}>
                                    <UnitLice lice={lice}/>
                                    <FishDisease diseases={diseases} units={[data]} short={true}/>
                                    <UnitDiseases data={data.unit_diseases}/>
                                </div>
                            </div>
                        </Card>
                    }
                }}
            />
        </div>
    </div>;
};

export default SmoltSite;

