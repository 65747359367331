import React from 'react';
import {ISchedule} from "../../../util/varibles/interface";
import styles from "./style.module.scss";
import Activity from "../Activity";
import Empty from "./Empty";
import {SHORT_DAY_DATE_MONTH_FORMAT} from "../../../util/varibles/constants";
import {datetime} from "../../../util/library/datetime";

interface IProps {
    weekNo: string
    year: string
    source: {
        [index: string]: ISchedule[]
    }
    finish?: number

    setDisease(disease: any): void
}

const Week: React.FC<IProps> = ({weekNo, year, source, finish, setDisease}) => {
    const count = Object.keys(source).length;

    return <div className={styles.week}>
        {count === 0
            ? <Empty weekNo={weekNo} finish={finish}/>
            : Object.keys(source).reduce((list: any, key, index) => {
                const isTop = index === count - 1;
                const minHeight = (isTop && source[key].length > 1) ? `${source[key].length * 52 / 2 - 10}px` : '';
                const max = source[key].length;
                return [<div id={key} className={styles.day} key={key} data-lev={isTop ? 'top' : ''}>
                    <div className={styles.time}>
                        <span className={styles.dayName} style={{minHeight}}>
                            {datetime(key).format(SHORT_DAY_DATE_MONTH_FORMAT)}
                        </span>
                        {isTop && <span className={styles.weekNo}>WEEK {weekNo} - {year}</span>}
                    </div>
                    <div>
                        {source[key].reduce((subList: any, item: any, index: number) => [<Activity
                            key={index}
                            data={{...item, index: max - index - 1}}
                            setDisease={setDisease}
                        />, ...subList], [])}
                    </div>
                </div>, ...list]
            }, [])}
    </div>;
};

export default Week;
