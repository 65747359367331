import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Form, Input, Select} from "antd";
import styles from "./style.module.scss";
import {ACTION_TYPE} from "../../../../util/varibles/constants";
import {initSensors, WIDGET_TYPE} from "../../constants";
import GaugeComponent from "./GaugeComponent";
import TankComponent from "./TankComponent";
import LineChartComponent from "./LineChartComponent";
import {FormInstance} from "antd/lib/form";
import {IVessel} from "../../../../util/varibles/interface";
import {vesselDetailActions} from "../../reducer";
import Footer from "../../../../components/Popup/Component/Footer";
import {createDashboardFetch, updateDashboardFetch} from "../../../../util/services/dashboard";
import {showErrorResponse} from "../../../../util/varibles/global";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {AppState} from "../../../../util/store/store";

const widgetTypes = [
    {label: 'Gauge', value: WIDGET_TYPE.GAUGE},
    {label: 'Line chart', value: WIDGET_TYPE.LINE},
    // {label: 'Tank', value: WIDGET_TYPE.TANK}
];

const mapStateToProps = (state: AppState) => ({
    dashboard: state.vesselDetail.dashboard,
    vessel: state.vesselDetail.vessel,
    devices: state.vesselDetail.devices,
});

interface IProps {
    widget: any
    editMode: ACTION_TYPE
    dashboard: any
    vessel: IVessel
    devices: any

    createDashboard(payload: any): void

    updateDashboard(payload: any): void

    getCurrentSensorData(payload: any): void

    onClose(): void
}

const FormComponent: React.FC<IProps> = (props) => {
    const {widget, vessel, dashboard, editMode} = props;
    const formRef: any = React.createRef<FormInstance>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (formRef.current && widget) {
            const {widgetType, devices = [], sensors = []} = widget;
            if (widgetType === WIDGET_TYPE.LINE) {
                const devicesOrigin: any = [];
                const sensorsOrigin: any = [];
                const sensorsValue: any = [];
                devices.reduce((list: any, device: any) => {
                    devicesOrigin.push(JSON.stringify(device));
                    device.sensors.forEach((sensor: any) => {
                        if (!list[sensor.name])
                            list[sensor.name] = 1;
                        else
                            list[sensor.name]++;
                        if (list[sensor.name] === devices.length)
                            sensorsOrigin.push(sensor)
                    })
                    return list;
                }, {})
                if (sensorsOrigin.length === 0 || sensors.length === 0) {
                    formRef.current.setFieldsValue({...widget, ...initSensors, devices: devicesOrigin})
                } else {
                    const isCheck = sensors.some((item: any) => {
                        const {name} = item;
                        sensorsValue.push(JSON.stringify(item));
                        return !sensorsOrigin.find((sensorData: any) => sensorData.name === name)
                    })
                    if (isCheck)
                        formRef.current.setFieldsValue({...widget, ...initSensors, devices: devicesOrigin})
                    else
                        formRef.current.setFieldsValue({
                            ...widget,
                            devices: devicesOrigin,
                            sensors: sensorsValue,
                        })
                }
            } else {
                const sensorsOrigin = devices[0].sensors;
                if (sensorsOrigin.length === 0 || sensors.length === 0) {
                    formRef.current.setFieldsValue({
                        ...widget, ...initSensors, devices: JSON.stringify(devices[0])
                    })
                } else {
                    const isCheck = sensorsOrigin.findIndex((sensorData: any) => sensorData.name === sensors[0].name) === -1
                    if (isCheck)
                        formRef.current.setFieldsValue({
                            ...widget, ...initSensors, devices: JSON.stringify(devices[0])
                        })
                    else
                        formRef.current.setFieldsValue({
                            ...widget,
                            sensors: JSON.stringify(sensors[0]),
                            devices: JSON.stringify(devices[0])
                        })
                }
            }
        }
    }, [formRef, widget]);

    const handleSubmit = (values: any) => {
        let {devices, sensors, widgetType, yAxis = []} = values;
        let data: any;
        let params: any[] = [], keys: any = [];
        if (widgetType === WIDGET_TYPE.LINE) {
            sensors = sensors.map((item: any) => JSON.parse(item));
            devices = devices.map((item: any) => {
                const device = JSON.parse(item);
                const {device_id, device_name} = device;
                params = [...params, ...sensors.reduce((listSensors: any, {name, unit}: any) => {
                    const yAxisIndex = yAxis.findIndex(({format}: any) => format === unit);
                    keys.push({device_id, device_name, sensor: name, yAxis: yAxisIndex !== -1 ? yAxisIndex : 0});
                    return [...listSensors, {device_id, sensor: name}]
                }, [])]
                return device;
            });

        } else {
            devices = [JSON.parse(devices)];
            sensors = [JSON.parse(sensors)];
            const {device_id, device_name} = devices[0];
            const {name} = sensors[0];
            keys = [{device_id, device_name, sensor: name, yAxis: 0}]
            params = [{device_id, sensor: name}]
        }
        yAxis = yAxis.map((item: any) => {
            const {min, max} = item;
            return {...item, min: min && min.length > 0 ? min : 0, max: max && max.length > 0 ? max : null}
        })
        data = {...values, devices, sensors, keys, yAxis}
        props.getCurrentSensorData(params);


        if (dashboard) {
            const content = JSON.parse(dashboard.content);
            if (editMode === ACTION_TYPE.EDIT)
                content.splice(widget.index, 1, {...data})
            else
                content.push({...data})

            handleSend(updateDashboardFetch, {
                ...dashboard,
                content: JSON.stringify([...content])
            }, (rs: any) => {
                props.updateDashboard({dashboard: rs})
            }, NotifyCode.S2, 'Create', data.name)
        } else {
            handleSend(createDashboardFetch, {
                vessel_id: vessel.id,
                is_default: true,
                content: JSON.stringify([{...data}])
            }, (rs: any) => {
                props.createDashboard({dashboard: rs})
            }, NotifyCode.S1, 'Update', data.name)
        }
    };

    const handleSend = (fetch: any, data: any, handleResponse: Function, code: NotifyCode, message: string, name: string) => {
        setLoading(true);
        new Promise(resolve => resolve(fetch(data)))
            .then((rs: any) => {
                handleResponse(rs);
                notify.success(code)([name])
                props.onClose();
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                showErrorResponse(message + ' failed', error)
            })
    }


    const renderContent = (widgetType: WIDGET_TYPE) => {
        const {devices} = props;
        const common = {devices, formRef: formRef};
        switch (widgetType) {
            case WIDGET_TYPE.GAUGE:
                return <GaugeComponent {...{...common}}/>
            case WIDGET_TYPE.TANK:
                return <TankComponent/>
            case WIDGET_TYPE.LINE:
                return <LineChartComponent {...{...common}}/>
            default:
                return <LineChartComponent {...{...common}}/>
        }
    }

    const handleChangeWidgetType = (widgetType: WIDGET_TYPE) => {
        const devices = formRef.current.getFieldValue('devices');
        const isArray = Array.isArray(devices);
        if (widgetType === WIDGET_TYPE.LINE && !isArray)
            formRef.current.setFieldsValue({devices: devices ? [devices] : []})
        else if (widgetType !== WIDGET_TYPE.LINE && isArray)
            formRef.current.setFieldsValue({devices: devices[0]})
    }

    const {Option} = Select;
    return <Form{...{
        ref: formRef,
        onFinish: handleSubmit,
        name: 'vessel',
        layout: 'vertical',
        requiredMark: 'optional'
    }}>
        <div className={styles['modal-body']}>
            <Form.Item label="Widget type" name="widgetType" required>
                <Select placeholder='Select a widget type' onChange={handleChangeWidgetType}>
                    {widgetTypes.map(({label, value}) =>
                        <Option key={value} value={value}>{label}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="Widget name" name="name" required>
                <Input placeholder='Widget name'/>
            </Form.Item>
            <Form.Item noStyle
                       shouldUpdate={(prev: any, current: any) => prev.widgetType !== current.widgetType}>
                {({getFieldValue}: any) => renderContent(getFieldValue('widgetType'))}
            </Form.Item>
        </div>

        <Footer
            className={styles.footer}
            cancel={{click: props.onClose}}
            ok={{loading}}
        />
    </Form>;
};

export default connect(mapStateToProps, {
    createDashboard: vesselDetailActions.createDashboard,
    updateDashboard: vesselDetailActions.updateDashboard,
    getCurrentSensorData: vesselDetailActions.getCurrentSensorDataRequest,
})(FormComponent);
