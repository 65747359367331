import React from 'react';
import {useTabBiology} from "../../../../../contexts/PlanOperationContext/TabBiologyContext";
import stylesContainer from "../../style.module.scss";
import Icon, {ICON_NAME} from "../../../../../components/Icon";

interface IProps {
}

const CountSelected: React.FC<IProps> = () => {
    const [selected, set] = useTabBiology(state => state.selected);
    const count = Object.keys(selected).length;
    if (count === 0)
        return null;

    return <div className={stylesContainer.clear}>
        <span>{count} unit selected</span>
        <div className={stylesContainer.close} onClick={() => set({selected: {}})}>
            <Icon icon={ICON_NAME.CLOSE} size='small'/>
        </div>
    </div>;
};

export default CountSelected;
