import React, {useCallback, useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {handleNext, options, updatePositionMenu, WEATHER_EL} from "../../util";
import {renderLabel} from "./Method";
import {TAddEl, toggleMenu} from "./index";
import {getElementByRole} from "../../constants";

interface IProps {
    role: WEATHER_EL

    selected?: number

    addEl: TAddEl

    openEditMode?: Function
}

const Menu: React.FC<IProps> = (props) => {
    const {addEl, openEditMode} = props;
    const [selected, setSelected] = useState<number>(props.selected || 0);
    const [role, setRole] = useState(props.role);
    const opts = options[role] || [];

    const selectItem = useCallback((e: any, option: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (openEditMode)
            openEditMode();
        const {value, label, unit} = option;
        const el = getElementByRole(role);
        if (el) {
            el.setAttribute('data-value', value);
            el.textContent = renderLabel(role, label);
            const {nextRole, nextEl} = handleNext[role](el, {addEl, unit});
            setRole(nextRole)
            if (options[nextRole]) {
                updatePositionMenu(nextEl);
            } else {
                toggleMenu()
            }
        }
    }, [addEl, role, openEditMode])

    const onKeyUp = useCallback((e: any) => {
        let keyCaught = false;
        switch (e.key) {
            case 'ArrowDown':
                setSelected(prev => Math.min(prev + 1, opts.length - 1))
                keyCaught = true
                break
            case 'ArrowUp':
                setSelected(prev => Math.max(prev - 1, 0))
                keyCaught = true
                break
            case 'Enter':
                selectItem(e, opts[selected]);
                break
        }

        if (keyCaught) {
            e.stopPropagation();
            e.preventDefault()
        }
    }, [selected, selectItem])

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            const flag = new Set(['ArrowUp','ArrowDown','Enter'])

            if (flag.has(e.key)) {
                e.stopPropagation();
                e.preventDefault()
            }
        };
        document.addEventListener('keydown', onKeyDown)
        document.addEventListener('keyup', onKeyUp)
        return () => {
            document.removeEventListener('keydown', onKeyDown)
            document.removeEventListener('keyup', onKeyUp)
        }
    }, [role, onKeyUp]);


    return <>
        {opts.map((item: any, i: number) => {
            return <div
                key={item.value}
                className={styles.option}
                onMouseDown={(e) => selectItem(e, item)}
                data-selected={i === selected}
            >
                {item.label}
            </div>
        })}
    </>;
};

export default Menu;
