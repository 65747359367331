import React, {useState} from 'react';
import {OP_STATUS} from "../../../../../util/varibles/constants";
import stylesOp from "../style.module.scss";
import Header from "../../../Component/Header";
import ActivityLogs2, {IActivityLogByUnit} from "../../../ActivitiesLog/ActivityLogs2";
import UnitNote from "../../../Component/UnitNote";
import Note from "../../../Component/Note";
import {uppercaseFirstLetter} from "../../../../../util/varibles/global";
import StepControl from "../../../Component/StepControl";
import Icon, {ICON_NAME} from "../../../../Icon";
import {IPropsOp} from "../index";

interface IProps extends IPropsOp {

}

const Service: React.FC<IProps> = ({data, step, active, changeStep, extend, onUpdate}) => {
    const [isShowActivityLog, setIsShowActivityLog] = useState(false)
    const {status, site, note, sub_operations, current_process} = data.operation;
    const {name: siteName = ""} = site || {}
    const {activity_log = []} = data;
    const {unit_id = undefined, round = undefined} = activity_log[step] || {};

    const tasks = data.operation.tasks.reduce((rs: any, item: any) => {
        rs[item.id] = item;
        return rs;
    }, {})

    const activityLog: IActivityLogByUnit[] = activity_log.reduce((list: any, item: any, index: number) => {
        const [prefix, taskId] = item.key.split('_');

        const {name = ''} = tasks[taskId] || {};
        const steps = [{id: taskId, name: uppercaseFirstLetter([prefix, name].join(' '))}];
        const {unit_id} = item;
        const isExist = list.findIndex((sub: any) => sub.id === unit_id);
        const value = {...item, steps, index};
        if (isExist === -1) {
            return [...list, {id: unit_id, name: unit_id, list: [value]}];
        }
        list[isExist].list.push(value);
        return list
    }, []);

    const unitInfo = sub_operations.find((subOperation: any) => subOperation.unit_id === unit_id);

    return <>
        <Header
            data={data}
            step={step}
            icon={<Icon icon={ICON_NAME.SERVICE}/>}
            extend={extend}
            active={active}
            isShowActivityLog={isShowActivityLog}
            changeShowActivityLog={() => setIsShowActivityLog(!isShowActivityLog)}
        >
            <div className={[stylesOp['operation-route'], 'd-flex'].join(' ')}>
                <div>{[siteName, sub_operations.map((item: any) => item.unit_id).join(', ')].join(' - ')}</div>
            </div>
        </Header>
        <div className={stylesOp['operation-info']}>
            <div className={stylesOp['operation-content']}>
                {(unit_id && round) &&
                <div className={stylesOp['round-and-unit']}>
                    Unit {unit_id}, round {round}
                </div>}
                <StepControl data={data} step={step} changeStep={changeStep} onUpdate={onUpdate}/>
                {isShowActivityLog ?
                    <div className={stylesOp.activityLog}>
                        <ActivityLogs2
                            current_process={current_process}
                            activity_log={activityLog}
                            activity_log_root={activity_log}
                        />
                    </div>
                    : (status === OP_STATUS.PROCESSING && <div className={stylesOp['operation-step-content']}>
                        <UnitNote unit={unitInfo}/>
                        <Note note={note}/>
                    </div>)}
            </div>
        </div>
    </>;
};

export default Service;
