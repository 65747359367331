import React from 'react';
import styles from './style.module.scss';
import {ACTION_TYPE, OPERATION_TYPE, operationType, vesselTypes} from "../../../../../util/varibles/constants";
import {Select} from "antd";
import Icon from "../../../../../components/Icon";
import BoxDrag from "./BoxDrag";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../util/store/selectors";
import {IVessel} from "../../../../../util/varibles/interface";

interface IProps {
    isDrag?: boolean
    opType?: OPERATION_TYPE
    vessel?: IVessel

    changeOpType(operationType: OPERATION_TYPE): void

    onClose(): void
}

interface IPropsTitle {
    vessel: IVessel
    editMode?: ACTION_TYPE
}

const {Option} = Select;
const defaultTitle = ({vessel}: IPropsTitle) => {
    const {name, capacity} = vessel;
    return name + ', ' + Math.round(capacity / 1000) / 1000 + 't'
}
const views: { [id: string]: (props: IPropsTitle) => string } = {
    [OPERATION_TYPE.TREATMENT]: ({vessel}: IPropsTitle) => {
        const {name, type} = vessel;
        const {shortName = ''} = vesselTypes[type - 1] || {};
        return [name, shortName].join(' - ');
    },
    [OPERATION_TYPE.EVENT]: ({editMode}: IPropsTitle) => {
        return editMode === ACTION_TYPE.CREATE ? 'New event' : 'Edit event';
    }
}

const Header: React.FC<IProps> = (props) => {
    const {
        opType = OPERATION_TYPE.EVENT,
        vessel,
        changeOpType,
    } = props;

    const {limitOperationTypes = [], isAllowChange = false, editMode} = useSelector(selectPlan.popupEdit);
    const {icon} = operationType[opType];
    const view = views[opType] || defaultTitle

    return <BoxDrag>
        <div className={styles.title}>
            <div className={styles.name}>
                <Icon icon={icon} size='large'/>
                {vessel && <span>{view({vessel, editMode})}</span>}
            </div>
            {(isAllowChange && vessel) && <Select {...{
                className: styles.selectOpType,
                popupClassName: styles.operationTypes,
                value: opType,
                onChange: changeOpType,
                popupMatchSelectWidth: false,
            }}>
                {limitOperationTypes.reduce((list: any, item: number) => {
                    return [...list, <Option key={operationType[item].id} value={operationType[item].id}>
                        {operationType[item].name}
                    </Option>]
                }, [])}
            </Select>}
        </div>
        <div className='bt-close' onClick={props.onClose}/>
    </BoxDrag>;
};

export default Header;