import React from 'react';
import styles from '../style.module.scss';
import {connect} from "react-redux";
import Operations from "./Operations";
import DiseasedZone from "./DiseasedZone";
import Vessel from "./Vessel";
import Site from "./Site";
import Factory from "./Factory";
import Toggle from "./Toggle";
import {EL_DASHBOARD} from "../constants";
import {MAP_ELEMENT} from "../../../../util/library/googlemap/constants";
import {VIEW_MODE} from "../../../../util/varibles/permission";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {AppState} from "../../../../util/store/store";
import Poi from "./Poi";

const mapStateToProps = (state: AppState) => {
    const viewMode = getSetting(state.login.user.setting, PreferenceSetting.VIEW_MODE);

    return {
        target: state.dashboard.target,
        viewMode
    }
};

interface IProps {
    target: any
    viewMode: VIEW_MODE
}

const LeftControl: React.FC<IProps> = ({target, viewMode}) => {
    if ((!target.targetType || target.targetType === MAP_ELEMENT.POLYLINE) && viewMode === VIEW_MODE.VESSEL)
        return null;

    function renderContent() {
        const props = target

        switch (target.targetType) {
            case MAP_ELEMENT.VESSEL:
                return <Vessel {...props}/>;
            case MAP_ELEMENT.SITE:
                return <Site {...props}/>;
            case MAP_ELEMENT.FACTORY:
                return <Factory {...props}/>;
            case MAP_ELEMENT.DISEASED_ZONE:
                return <DiseasedZone {...props}/>
            case MAP_ELEMENT.POI:
                return <Poi {...props}/>
            default:
                return <Operations/>;
        }
    }

    return <div id={EL_DASHBOARD.LEFT_CONTROL} className={styles.controlLeft}>
        {renderContent()}
        <Toggle/>
    </div>;
};
export default connect(mapStateToProps, {})(LeftControl);

