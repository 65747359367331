import React from 'react';
import styles from './style.module.scss';
import {HEIGHT_PER_DAY} from "../../../constants";
import {durationTxtByStartAndFinish} from "../../../../../../../../util/varibles/global";
import {usePlan} from "../../../../../../../../contexts/PlanOperationContext/Plan";

interface IProps {
    start: number
    finish: number
    style: any
}

const Duration: React.FC<IProps> = ({start, finish, style}) => {
    const [startPoint] = usePlan(state => state.startPoint);
    const top = `${(finish - startPoint) / 86400000 * HEIGHT_PER_DAY}px`;

    return <div className={styles.duration} style={{...style, top}}>
        <span>{durationTxtByStartAndFinish(start, finish)}</span>
    </div>;
};

export default Duration;
