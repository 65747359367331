import {notify, NotifyCode} from "./message";
import {patternFloat2} from "./constants";
import {isEmpty} from "./global";
import {IVessel} from "./interface";
import {equipmentForm, storageForm, vesselForm} from "../../pages/VesselManagementPage/PopupEditVessel/constants";

export const formValidation = {
    require: (name: string) => {
        return {
            required: true,
            message: `"${name}" is required`
        }
    },
    confirmPassword: () => ({getFieldValue}: any) => ({
        validator(_: any, value: any) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    })
}

export const Rule = {
    Require: (name: string) => ({required: true, message: notify[NotifyCode.E32]([name])}),
    Requires: (name: string) => ({required: true, message: notify[NotifyCode.E29]([name])}),
    Number: () => ({pattern: new RegExp(patternFloat2), message: notify[NotifyCode.E9]()})
}


export const funcValidation: { [type: string]: (value: any) => NotifyCode | undefined } = {
    require: (value: any) => isEmpty(value) ? NotifyCode.E32 : undefined,
    requireZero: (value: any = 0) => +value ? undefined : NotifyCode.E32,
}

export const validateForm = ({vessel}: { vessel: IVessel | Partial<IVessel> }): any => {
    const {storages = [], equipments = []} = vessel;
    const result: any = Object.keys(vesselForm).reduce((rs: {
        [key: string]: {
            [rule: string]: NotifyCode
        }
    }, key) => {
        const {[key]: value} = vessel as any;
        const {rules, condition} = vesselForm[key];
        if (!condition || condition(vessel))
            Object.keys(rules).forEach(rule => {
                const check = funcValidation[rule];
                if (check) {
                    const result = check(value);
                    if (result)
                        rs[key] = {...rs[key] || {}, [rule]: result};
                }
            })
        return rs;
    }, {})

    let errorCustom = storages.reduce((rs: {
        [index: string]: { [key: string]: { [rule: string]: NotifyCode } }
    }, item, i) => {
        Object.keys(storageForm).forEach(key => {
            const {[key]: value} = item as any;
            const {rules} = storageForm[key];
            Object.keys(rules).forEach(rule => {
                const check = funcValidation[rule];
                if (check) {
                    const result = check(value);
                    if (result)
                        rs[i] = {...rs[i] || {}, [key]: {[rule]: result}};
                }
            })
        })
        return rs;
    }, {});

    if (Object.keys(errorCustom).length > 0)
        result.storages = errorCustom

    errorCustom = equipments.reduce((rs: {
        [index: string]: { [key: string]: { [rule: string]: NotifyCode } }
    }, item, i) => {
        Object.keys(equipmentForm).forEach(key => {
            const {[key]: value} = item as any;
            const {rules} = equipmentForm[key];
            Object.keys(rules).forEach(rule => {
                const check = funcValidation[rule];
                if (check) {
                    const result = check(value);
                    if (result)
                        rs[i] = {...rs[i] || {}, [key]: {[rule]: result}};
                }
            })
        })
        return rs;
    }, {});

    if (Object.keys(errorCustom).length > 0)
        result.equipments = errorCustom

    return result
}