import React from 'react';
import {Checkbox} from "antd";
import {TOperation} from "../../../../../../util/varibles/interface";

interface IProps {
    op: TOperation

    onChange(value: Partial<TOperation>): void
}

const TravellingToSite: React.FC<IProps> = ({op, onChange}) => {
    return <div>
        <Checkbox
            checked={op.is_journey_to_site || op.is_journey_to_site === undefined}
            onChange={(e: any) => onChange({is_journey_to_site: e.target.checked})}
        >
            Travelling to site
        </Checkbox>
    </div>;
};

export default TravellingToSite;
