import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './style.module.scss';
import Messages from "./Component/Messages";
import {CONVERSATION_MODE} from "../../../pages/MessagePage/constants";
import Conversations from "./Component/Conversations";
import {addRouterListener} from "../../../util/library/Router";
import {messageActions} from "../../../pages/MessagePage/reducer";
import {CHAT_TYPE} from "../../../util/varibles/constants";

const mapStateToProps = null

interface IProps {
    updateConversations(payload: { conversation: any, mode: CONVERSATION_MODE, isTop?: boolean }): void

    getChat(payload: { index: number, isClear: boolean }): void

    getChatOfOp(payload: { index: number, isClear: boolean }): void
}

class MessageMini extends Component<IProps> {
    state = {
        isInvisible: window.location.pathname.includes('message')
    }
    listen: any;
    callAdd: any;

    constructor(props: IProps) {
        super(props);
        this.props.getChat({index: 1, isClear: true});
        this.props.getChatOfOp({index: 1, isClear: true});

        this.listen = addRouterListener((state) => {
            const {pathname = ''} = state.location || {};
            this.setState({isInvisible: pathname.includes('message')})
        })
    }

    componentWillUnmount() {
        if (this.listen)
            this.listen();
    }

    handleOpenConversation = (conversation: any) => {
        this.props.updateConversations({conversation, mode: CONVERSATION_MODE.OPEN});
        const {id} = conversation.group_chat;
        setTimeout(() => {
            const el = document.getElementById('input-' + id);
            if (el)
                el.focus();
        }, 100);
    }

    handleAddConversation = (chatType: CHAT_TYPE) => {
        if (this.callAdd) {
            this.callAdd(chatType)
        }
    }

    render() {
        const {isInvisible} = this.state;

        if (isInvisible)
            return null;

        return <div className={styles.wrapper}>
            <Conversations callAdd={(ref: any) => this.callAdd = ref}/>
            <Messages onOpenConversation={this.handleOpenConversation} onAddConversation={this.handleAddConversation}/>
        </div>;
    }
}

export default connect(mapStateToProps, {
    updateConversations: messageActions.updateConversations,
    getChat: messageActions.getChatRequest,
    getChatOfOp: messageActions.getChatOfOpRequest,
})(MessageMini);
