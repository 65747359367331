import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLocation} from "../../../util/store/location/constants";
import {PoiMarker} from "../../../contexts/WorldMapContext/Marker";
import {dashboardActions} from "../reducer";
import {MAP_ELEMENT} from "../../../util/library/googlemap/constants";
import {LOCATION_TYPE} from "../../../util/varibles/constants";

interface IProps {
    ids: Set<string>
}

const Pois: React.FC<IProps> = ({ids}) => {
    const source = useSelector(selectLocation.locations);
    const dispatch = useDispatch();

    const onClick = useCallback((data: any) => {
        dispatch(dashboardActions.showInfo({data, targetType: MAP_ELEMENT.POI}));
        dispatch(dashboardActions.getWeatherRequest({type: LOCATION_TYPE.POI, id: data.id}))
    }, [dispatch])

    return <>
        {source.map(item => <PoiMarker
            key={item.id}
            data={item}
            show={ids.size === 0 || ids.has(item.id)}
            onClick={() => onClick(item)}
        />)}
    </>;
};

export default Pois;
