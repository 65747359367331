import React from 'react';
import {IChangeLog, IVessel} from "../../../../../util/varibles/interface";
import styles from './style.module.scss';
import {datetime} from "../../../../../util/library/datetime";
import {SHORT_MONTH_DATETIME_FORMAT} from "../../../../../util/varibles/constants";
import Operation from "./Operation";
import Author from "./Author";

interface IProps {
    data: IChangeLog
    vessels: IVessel[]
}

const Log: React.FC<IProps> = ({data, vessels}) => {
    const {timestamp, operation, username} = data;

    return <div className={styles.log}>
        <div className={styles.time}>{datetime(timestamp).format(SHORT_MONTH_DATETIME_FORMAT)}</div>
        <div className={styles.ops}>
            {Object.keys(operation).map(key => <Operation key={key} data={operation[key]} vessels={vessels}/>)}
        </div>
        <Author username={username}/>
    </div>;
};

export default Log;
