import {initialState, IUpdateOp,} from "./constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IContract, IOperation, IOperations, IRoute, ISite, IUnit, IVessel} from "../../util/varibles/interface";
import {
    addFishChanges,
    checkValidOperation,
    convertOpsFromResponse,
    downLevel,
    getContract,
    ICheckValidOperation,
    pushOperations,
    removeFishChanges,
    updateNextOperation,
    upLevel
} from "./util";
import {
    aDayMillisecond,
    CONTRACT_PERMISSION_TYPE,
    CONTRACT_TYPE,
    initAutoPlanConfig,
    OP_STATUS,
    OPERATION_CONFIRM_STATUS,
    TIME_PER_STEP,
    VALIDATION_STATUS,
    VIEW_SMOOTH
} from "../../util/varibles/constants";
import {cloneObj, getElById} from "../../util/varibles/global";
import {focusOp, funcGlobal} from "./util/function_operation/global";
import {FuncOp, getTimeByOpMode} from "./util/function_operation/constants";
import {onMouseDown} from "./BiologyBrowser/constants";
import {IGetActivityLog} from "./saga";
import {funcHarvest} from "./util/function_operation/harvest";

const planOperationSlice = createSlice({
    name: 'planOperation',
    initialState,
    reducers: {
        initialRequest: (state) => {
            state.popupClose = false;
        },
        initialSuccess: (state, action) => {
            const {factories, serviceTasks} = action.payload;
            state.serviceTasks = serviceTasks || [];
            state.factories = factories || [];
        },
        updateWeeks: (state, action) => {
            state.weeks = action.payload;
        },
        confirmOpRequest: (state, action) => {
            state.isFetching = {...state.isFetching, confirm: true};
        },
        confirmOpSuccess: (state, action) => {
            const {operations, level, operationsDelete, files, fishChanges} = action.payload;
            state.files = files;
            state.operations = {...operations};
            state.level = level;
            state.operationsDelete = {...operationsDelete};
            state.isFetching = {...state.isFetching, confirm: false};
            state.fishChanges = fishChanges;
        },
        confirmOpFailure: (state) => {
            state.isFetching = {...state.isFetching, confirm: false};
        },
        updateFiles: (state, action) => {
            const {files} = state;
            state.files = {...files, ...action.payload};
        },
        addExternalSite: (state, action) => {
            const site = action.payload;
            const {sites} = state;
            let isUpdate = false;

            const list = sites.map(item => {
                if (item.id === site.id) {
                    isUpdate = true;
                    return site
                }
                return item;
            });

            state.sites = isUpdate ? list : [...sites, site];
        },
        setFocusId: (state, action) => {
            state.opFocusId = action.payload;
        },
        saveRoutes: (state, action: PayloadAction<IRoute[]>) => {
            const routes = action.payload || [];
            state.routeIds = Array.from(new Set(routes.map(item => [item.source_id, item.destination_id].join('_'))));
        },
        addRoute: (state, action: PayloadAction<IRoute>) => {
            const item = action.payload;
            state.routeIds = Array.from(new Set([...Array.from(state.routeIds), [item.source_id, item.destination_id].join('_')]));
        },
        getSitesRequest: (state, action) => {
            state.isFetching = {...state.isFetching, sites: true}
        },
        getSitesSuccess: (state, action) => {
            const {sites = [], harvests = []} = action.payload;
            state.sites = sites;
            state.harvests = harvests.map((item: any) => ({...item, id: item.unit_id, unit_id: item.unit_number}));
            state.isFetching = {...state.isFetching, sites: false};
        },
        getSitesFailure: (state) => {
            state.isFetching = {...state.isFetching, sites: false}
        },
        getCancelOps: (state, action) => {
            const {vessels} = state;
            const data = action.payload;
            if (vessels.length > 0) {
                state.canceled = data.map((item: any) => {
                    const vessel = vessels.find((sub: any) => sub.id === item.operation.vessel_id);
                    return {...item, vessel}
                });
            } else {
                state.canceled = data;
            }
        },
        togglePopupOperation: (state, action) => {
            const {e, defaultOpType, userTenantId, callback} = action.payload;
            if (e) {
                const target = onMouseDown(e, state.vessels);
                if (!target)
                    return;

                const {vessel, operationId, availableTimeId, contractId, startTime} = target;
                const {operations, level, opMode} = state;
                let params: any;
                if (operationId) {
                    params = funcHarvest[FuncOp.BeginMerge]({id: operationId, ...state});
                } else if (vessel) {
                    const finishTime = startTime + aDayMillisecond - 1;

                    const finish = Object.keys(level[vessel.id] || {}).reduce((rs, key) => {
                        const {activity_log} = operations[key];

                        const value = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
                        if (startTime <= value && activity_log[0].est_start_time <= finishTime)
                            if (rs < value)
                                return value;
                        return rs;
                    }, 0);
                    params = funcGlobal[FuncOp.BeginCreate]({
                        vessel,
                        startTime: finish ? finish + TIME_PER_STEP : startTime,
                        availableTimeId,
                        contractId,
                        defaultOpType,
                        userTenantId
                    })
                }
                state.popupOperation = {...params, action: callback}
            } else {
                state.popupOperation = action.payload
            }
        },
        renewOp: (state, action) => {
            const data = action.payload;
            const {id} = data.operation;
            const {canceled, operations, factories, opMode} = state;

            state.canceled = canceled.map(item => item.operation.id === id ? {...item, isShow: false} : item);
            state.opFocusId = id;
            state.operations = {
                ...operations,
                [id]: {
                    ...data,
                    ...checkValidOperation({
                        operations,
                        data,
                        level: state.level,
                        factories,
                        opMode
                    }),
                    operation: {...data.operation, status: OP_STATUS.NEW},
                    action_type: OPERATION_CONFIRM_STATUS.UPDATE
                }
            }
            state.level = upLevel(state.level, data, state.operations, opMode);
            setTimeout(() => {
                const el = getElById(id);
                if (el)
                    el.scrollIntoView(VIEW_SMOOTH)
            }, 300)
        },
        updateUnit: (state, action) => {
            const {sites} = state;
            const {site_id, unit_diseases, ...args} = action.payload;
            const change = unit_diseases
                ? (item: IUnit) => ({...item, unit_diseases: {...item.unit_diseases, types: unit_diseases}})
                : (item: IUnit) => ({...item, ...args});

            state.sites = sites.map(item => item.id === site_id ? {
                ...item,
                units: item.units.map(sub => change(sub))
            } : item)
        },
        cancelContract: (state, action) => {
            const [vesselId, contractId] = action.payload;
            const {vessels, operations} = state;
            let operationsNew = {...operations};
            let index = 0;
            state.vessels = vessels.reduce((list: any, item) => {
                const {id} = item;
                if (id !== vesselId) {
                    list = [...list, {...item, index}];
                    index++;
                } else {
                    const {contracts} = item;
                    const contractsNew = contracts.filter(contract => contract.id !== contractId);
                    if (contractsNew.length > 0) {
                        index++;
                        return [...list, {...item, contracts: contractsNew}]
                    } else {
                        operationsNew = Object.keys(operationsNew).reduce((list: any, key) => {
                            return operationsNew[key].vessel.id === vesselId ? list : {
                                ...list,
                                [key]: operationsNew[key]
                            }
                        }, {})
                    }
                }
                return list;
            }, []);
            state.operations = {...operationsNew};
        },
        clearFocus: (state) => {
            state.opFocusId = null
        },
        updateVessels: (state, action) => {
            state.vessels = action.payload
        },
        updateAvailableTime: (state, action) => {
            const available_time = action.payload;
            const {vessel_id, id} = available_time;
            const {vessels} = state;
            state.vessels = vessels.map((item: any) => {
                if (item.id === vessel_id) {
                    let isFlag = true;
                    const available_times = item.available_times.map((sub: any) => {
                        if (sub.id === id) {
                            isFlag = false;
                            return available_time
                        }
                        return sub;
                    });
                    if (isFlag)
                        available_times.push(available_time)
                    return {...item, available_times}
                }
                return item;
            });
        },
        deleteAvailableTime: (state, action) => {
            const {vessel_id, id} = action.payload;
            const {vessels} = state;
            state.vessels = vessels.map((item: any) => item.id === vessel_id ? {
                ...item,
                available_times: item.available_times.filter((sub: any) => sub.id !== id)
            } : item);
        },
        updateUnitsAndHarvests: (state, action) => {
            const {units = [], harvests = []} = action.payload;
            state.sites = units.reduce((rs: ISite[], item: any) => {
                const indexSite = rs.findIndex(sub => sub.id === item.site_id);
                if (indexSite !== -1) {
                    const site = rs[indexSite];
                    const {fish_amount, avg_weight, total_weight} = item;
                    rs[indexSite] = {
                        ...site,
                        units: site.units.map(sub => sub.id === item.id
                            ? {...sub, fish_amount, avg_weight, total_weight}
                            : sub)
                    }
                }
                return rs;
            }, cloneObj(state.sites));
            state.harvests = harvests.reduce((rs: IUnit[], item: any) => {
                const indexHarvest = rs.findIndex(sub => sub.harvest_id === item.harvest_id);
                if (indexHarvest !== -1) {
                    const harvest = rs[indexHarvest];
                    const {fish_amount, avg_weight, total_weight} = item;
                    rs[indexHarvest] = {...harvest, fish_amount, avg_weight, total_weight}
                }
                return rs;
            }, cloneObj(state.harvests));
        },
        dragOperation: (state, action: PayloadAction<IOperation>) => {
            const {operations: dataOld, factories, opMode} = state;
            const target = action.payload;
            const {id} = target.operation;

            if (!state.level[dataOld[id]?.vessel.id])
                return state

            const {[dataOld[id].vessel.id]: levelOld} = state.level;
            state.level = downLevel(state.level, dataOld[id], dataOld, opMode);
            const list = !target.operation.parallel ? pushOperations(target, {
                ...dataOld,
                [id]: target
            }, false) : {[id]: target};

            state.opFocusId = id;
            state.operations = Object.keys(list).reduce((operations: IOperations, key: string) => {
                const {status} = operations[key];
                const action_type = dataOld[key].action_type || OPERATION_CONFIRM_STATUS.UPDATE;

                if (key.toString() === target.operation.id.toString() || status === VALIDATION_STATUS.ERROR) {
                    operations[key] = {
                        ...operations[key],
                        ...checkValidOperation({
                            operations,
                            level: state.level,
                            data: operations[key],
                            factories,
                            opMode
                        }),
                        action_type
                    }
                }
                state.level = upLevel(state.level, operations[id], operations, opMode, levelOld);
                return operations;
            }, {...dataOld, ...list});
        },
        updateOps: (state, action: PayloadAction<IUpdateOp>) => {
            const {add = {}, update = {}, files = {}, isCheck = true, isFocus = ''} = action.payload;
            const validation = isCheck ? (props: ICheckValidOperation) => checkValidOperation(props) : () => ({});
            const {operations, vessels, opMode} = state;
            const source = {...add, ...update};
            const firstKey = Object.keys(source)[0];
            const {parallel} = source[firstKey].operation;
            let moveOp = {};
            if (!parallel) {
                const lastKey = Object.keys(source)[Object.keys(source).length - 1];
                const start = source[firstKey].activity_log[0].est_start_time;
                const finish = source[lastKey].activity_log[source[lastKey].activity_log.length - 1].est_start_time;
                const {id: vesselId} = source[firstKey].vessel;
                const {id, tenant_id = ''} = source[firstKey].operation;
                moveOp = updateNextOperation({
                    level: state.level,
                    startTime: start,
                    finishTime: finish,
                    tenantId: tenant_id,
                    vesselId,
                    ids: [id],
                    operations
                });
            }

            state.operations = {...operations, ...moveOp};
            Object.keys(add).forEach((key, index) => {
                const item = add[key];
                const {contract_id, available_time_id} = getContract(item, vessels);
                const data = {
                    ...item,
                    operation: {
                        ...item.operation,
                        id: `${state.indexId}`,
                        operation_code: `${state.indexId}`,
                        contract_id,
                        available_time_id,
                        status: OP_STATUS.NEW
                    },
                };

                state.operations[state.indexId] = {
                    ...data,
                    ...checkValidOperation({
                        operations: state.operations,
                        level: state.level,
                        data,
                        opMode,
                    }),
                    action_type: OPERATION_CONFIRM_STATUS.NEW
                }

                state.fishChanges = addFishChanges(state.fishChanges, state.operations[state.indexId]);

                if (key === isFocus)
                    focusOp(data.operation.id);

                state.indexId = state.indexId + 1;
                state.level = upLevel(state.level, data, state.operations, opMode);
                state.files = {...state.files, ...files[index]};
            });

            const realUpdate: IOperations = {...moveOp, ...update};

            Object.keys(realUpdate).forEach(key => {
                state.level = downLevel(state.level, state.operations[key], state.operations, opMode);
                const {contract_id, available_time_id} = getContract(realUpdate[key], vessels);
                const item = {
                    ...realUpdate[key],
                    operation: {...realUpdate[key].operation, contract_id, available_time_id}
                };
                const old = cloneObj(state.operations[key].operation);
                state.operations[key] = {
                    ...item,
                    ...validation({operations: state.operations, level: state.level, data: item, opMode}),
                    action_type: item.action_type || operations[key].action_type || OPERATION_CONFIRM_STATUS.UPDATE
                };
                state.fishChanges = addFishChanges(state.fishChanges, state.operations[key], old);

                if (key === isFocus)
                    focusOp(key);

                state.level = upLevel(state.level, item, state.operations, opMode);
                state.files = {...state.files, ...files[key]};
            })
        },
        updateOpsByWs: (state, action: PayloadAction<{ data: any[], mode?: any }>) => { //RECEIVED_OPERATIONS_CONFIRMED
            const {data, mode} = action.payload;
            let {operations, vessels, opMode} = state;
            let canceled = Array.from(state.canceled)
            let newOperations;
            if (mode === 'remove') {
                data.forEach((key: string) => {
                    const item = operations[key];
                    if (!item)
                        return;
                    state.level = downLevel(state.level, item, operations, opMode);
                    const {[key]: old, ...value} = operations;
                    operations = {...value};
                })
                newOperations = {...operations}
            } else if (mode === 'update') {
                newOperations = data.reduce((list: any, item: IOperation) => {
                    const {id} = item.operation;
                    const index = vessels.findIndex(vessel => vessel.id === item.operation.vessel_id)
                    list[id] = JSON.parse(JSON.stringify({...item, vessel: {...vessels[index], index}}));
                    state.level = downLevel(state.level, list[id], list, opMode);
                    state.level = upLevel(state.level, list[id], list, opMode);
                    return list;
                }, operations);
            } else {
                newOperations = data.reduce((list: any, item: IOperation) => {
                    const {id, vessel_id, status} = item.operation;
                    const index = vessels.findIndex(vessel => vessel.id === vessel_id);
                    const operation = {...item, vessel: {...vessels[index], index}};
                    state.level = downLevel(state.level, operation, list, opMode);
                    if (status === OP_STATUS.CANCELED) {
                        const indexOp = canceled.findIndex((sub: IOperation) => sub.operation.id === id);
                        if (indexOp === -1)
                            canceled.push(operation);
                        else {
                            canceled[indexOp] = {...operation, isShow: true};
                        }
                        const {[id]: old, ...value} = list;
                        return value || {};
                    }
                    list[id] = operation;
                    state.level = upLevel(state.level, list[id], list, opMode);
                    return list;
                }, operations);
            }

            state.canceled = [...canceled];
            state.operations = newOperations;
        },
        autoPlan: (state, action) => {
            const {operations, harvests} = action.payload;
            const {harvests: harvestsOld, opMode} = state;
            const updateHarvests = JSON.parse(JSON.stringify(harvestsOld));
            Object.keys(harvests).forEach(key => {
                const {fish_amount} = harvests[key];
                const index = updateHarvests.findIndex((item: any) => item.harvest_id === key);
                const {avg_weight} = updateHarvests[index];
                updateHarvests[index].fish_amount = fish_amount;
                updateHarvests[index].total_weight = fish_amount * avg_weight;
            })
            const {operations: operationsOld, vessels} = state;
            let operationsNew = operations.reduce((list: any, item: IOperation) => {
                const {vessel_id, id} = item.operation;
                const index = vessels.findIndex((vesselItem: any) => vesselItem.id === vessel_id);
                list[id] = {
                    ...item,
                    action_type: OPERATION_CONFIRM_STATUS.NEW,
                    vessel: {...vessels[index], index}
                };
                state.level = upLevel(state.level, list[id], list, opMode);
                return list;
            }, operationsOld);
            state.harvests = updateHarvests;
            state.operations = operationsNew;
        },
        getActivityLog: (state, action: PayloadAction<IGetActivityLog>) => {
        },
        lazyLoadingRequest: (state, action) => {
        },
        lazyLoadingSuccess: (state: any, action) => {
            const {operations: operationsOld, level: levelOld} = state;
            const {key, weeks, result, opMode} = action.payload;
            const {vessels} = result;
            const vesselsOld = JSON.parse(JSON.stringify(state.vessels));

            const vesselsNew = vessels.reduce((list: any, item: any) => {
                const index = list.findIndex((sub: any) => sub.id === item.vessel.id);
                if (index !== -1)
                    list[index].available_times = [...list[index].available_times || [], ...item.vessel.available_times || []]
                else
                    list = [...list || [], item]

                return list;
            }, vesselsOld);

            const {
                operations,
                level
            } = convertOpsFromResponse(result.operations, vesselsNew, opMode, {
                operations: operationsOld,
                level: levelOld
            });

            state.level = {...level}
            state.weeks = weeks;
            state.operations = {...operations};
            state.vessels = [...vesselsNew];
            state[key] = result[key];
        },
        lazyLoadingFailure: () => {
        },
        getVesselsRequest: (state, action) => {
            state.loadingVessels = true
        },
        getVesselsSuccess: (state, action) => {
            const {tenantId, vesselIds = [], opMode, plan, autoConfig} = action.payload;
            const {before_count: beforeCount, after_count: afterCount} = plan;
            const {vessel: configVessel = initAutoPlanConfig.vessel} = autoConfig;
            let isFlag = false;

            const vessels: IVessel[] = plan.vessels.map((item: any, index: number) => {
                const {vessel} = item
                const isOwn = `${tenantId}` === `${vessel.tenant_id}`;
                const {priority} = configVessel[vessel.type] || {};


                if (!isOwn) {
                    const {contracts} = vessel;
                    const contract = contracts.find((sub: IContract) => sub.tenant_id === tenantId
                        && sub.type === CONTRACT_TYPE.SINGLE
                        && sub.permission === CONTRACT_PERMISSION_TYPE.FULL)
                    if (contract)
                        vessel.permission = contract.id
                }
                const isShow = vesselIds.indexOf(vessel.id) !== -1;
                if (isShow)
                    isFlag = true;
                return {...vessel, isShow, priority, index, isOwn};
            });

            const {operations, level} = convertOpsFromResponse(plan.operations, vessels, opMode);

            state.level = level;
            state.opMode = opMode;
            state.operations = operations;
            state.beforeCount = beforeCount;
            state.afterCount = afterCount;
            state.vessels = isFlag ? vessels : vessels.map((item: any) => ({...item, isShow: true}));
            state.loadingVessels = false
        },
        getVesselsFailure: (state) => {
            state.loadingVessels = false
        },
        deleteOps: (state, action: PayloadAction<string[]>) => {
            const ids = action.payload;
            const {operations, canceled, opMode} = state;

            let newCanceled = [...canceled];
            const operationsNew = ids.reduce((list: IOperations, key: string) => {
                const {[key]: item = null, ...args} = list;
                const currentOp = cloneObj(item);
                if (currentOp) {
                    state.fishChanges = removeFishChanges(state.fishChanges, currentOp);
                    state.level = downLevel(state.level, currentOp, operations, opMode);
                }
                newCanceled = newCanceled.filter((sub: any) => sub.operation.id !== key);
                return args;
            }, operations || {});
            state.canceled = newCanceled;
            state.operations = operationsNew;
        },
        clearOps: (state, action) => {
            const {listOfDelete, listOfUpdate} = action.payload;
            const {operations, vessels, opMode} = state;
            const operationsNew = listOfDelete.reduce((list: any, key: string) => {
                const {[key]: item, ...args} = list;
                list = args;
                state.fishChanges = removeFishChanges(state.fishChanges, item);
                state.level = downLevel(state.level, item, operations, opMode);
                return list;
            }, operations)

            state.operations = listOfUpdate.reduce((rs: any, item: any) => {
                const {id, vessel_id} = item.operation;
                const vessel = vessels.find(sub => sub.id === vessel_id);
                state.level = downLevel(state.level, operations[id], operations, opMode);
                if (vessel) {
                    rs[id] = {...item, vessel};
                    state.fishChanges = removeFishChanges(state.fishChanges, item);
                    state.level = upLevel(state.level, rs[id], operations, opMode);
                }
                return rs;
            }, operationsNew);
        },
        mergeOp: (state, action) => {
            const {data, deletedId} = action.payload;
            const {operations, factories, operationsDelete = {}, opMode} = state;
            const {action_type} = data;
            const {id} = data.operation;
            const {[deletedId]: opDelete, ...args} = operations;

            if (opDelete.action_type !== OPERATION_CONFIRM_STATUS.NEW) {
                operationsDelete[id] = [...operationsDelete[id] || [], opDelete];
            }

            if (operationsDelete[deletedId]) {
                operationsDelete[id] = [...operationsDelete[id] || [], ...operationsDelete[deletedId]]
                delete operationsDelete[deletedId];
            }

            state.operationsDelete = operationsDelete;
            state.operations = {
                ...args,
                [id]: {
                    ...data,
                    ...checkValidOperation({
                        operations: args,
                        data,
                        level: state.level,
                        factories,
                        opMode
                    }),
                    action_type: action_type || OPERATION_CONFIRM_STATUS.UPDATE
                }
            }
        },
        cancelOp: (state, action) => {
            const id = action.payload;
            const {canceled} = state;
            const {[id]: data, ...args} = state.operations;

            state.operations = args;
            state.canceled = [...canceled, {...data, operation: {...data.operation, status: OP_STATUS.CANCELED}}];
        },
        deleteCancelOp: (state, action) => {
            const id = action.payload;
            const {canceled, opMode} = state;
            const {[id]: op, ...operations} = state.operations;
            state.canceled = canceled.map(item => item.operation.id === id ? {...item, isShow: true} : item);
            state.operations = operations;
            state.level = downLevel(state.level, op, operations, opMode);
        },
        closePopup: (state) => {
            state.popupClose = true;
        }
    },
})

export const planOpActions = planOperationSlice.actions;
export const getActivityLog = planOpActions.getActivityLog;

const planOperationReducer = planOperationSlice.reducer;

export default planOperationReducer;

