import React, {Component} from 'react';
import styles from "./style.module.scss";

class Empty extends Component {
    render() {
        return <div className={styles.empty}>
            <div/>
            <span>The list is currently empty.</span>
        </div>;
    }
}

export default Empty;
