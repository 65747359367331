import callApi, {api} from "../api/callApi";
import {urlAutoRoute, urlRoute, urlRouteSetting} from "../api/v1";
import {createParamsForGet} from "../varibles/global";
import {IRoute} from "../varibles/interface";

export function getRoutesByLocation(source_id: string, destination_id: string) {
    return callApi(urlRoute, {
        method: 'POST',
        body: JSON.stringify({source_id, destination_id})
    });
}

export function getRoutesPlanFetch() {
    return callApi(urlRoute + '/plan', {
        method: 'GET',
    });
}

interface IGetAutoRoute {
    departure: {
        position: string,
        port: any
    },
    "arrival": {
        position: string,
        port: any
    },
    "mode": string | "Fast"
    request_id: string
    connection_id: string
}

export function getAutoRoutePlanFetch(params: IGetAutoRoute) {
    return callApi(urlRoute + '/auto', {
        method: 'POST',
        body: JSON.stringify(params)
    });
}

interface IGetAutoRoute2 {
    departure_position: string
    arrival_position: string
    "mode": string | "Fast"
    safety_draft: any
    safety_airDraft: any
}

export function getAutoRoutePlan2Fetch(params: IGetAutoRoute2) {
    return callApi(urlAutoRoute + createParamsForGet(params), {
        method: 'GET',
    }, false);
}

export function getLocationTestFetch() {
    return callApi(urlRoute + '/test', {
        method: 'GET',
    }, false);
}


export function updateRouteFetch(body: any) {
    return callApi(urlRouteSetting, {
        method: 'PUT',
        body: JSON.stringify(body)
    });
}

export function createRouteFetch(body: any) {
    return callApi(urlRouteSetting, {
        method: 'POST',
        body: JSON.stringify(body)
    });
}

export function deleteRouteFetch(body: any) {
    return callApi(urlRouteSetting, {
        method: 'DELETE',
        body: JSON.stringify(body)
    });
}

export function setRouteDefaultFetch(body: any) {
    return callApi(urlRoute, {
        method: 'PUT',
        body: JSON.stringify(body)
    });
}

export function getLocationsFetch() {
    return callApi(urlRoute + "/location", {
        method: 'GET',
    });
}

export const RouteService = {
    gets: () => api.get<IRoute[]>(urlRoute + '/plan')
}