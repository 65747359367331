import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {Tooltip} from "antd";

interface IProps {
    data: any
}

const UnitDiseases: React.FC<IProps> = ({data}) => {
    const rootRef: any = React.createRef();
    const [limit, setLimit] = useState(0);

    useEffect(() => {
        if (rootRef.current && rootRef.current.parentNode && rootRef.current.parentNode.parentNode) {
            const {offsetTop} = rootRef.current;
            const style = window.getComputedStyle(rootRef.current.parentNode.parentNode);
            const {maxHeight, paddingBottom} = style;
            const height = Number((maxHeight || '').replace('px', '')) - Number((paddingBottom || '').replace('px', '')) - offsetTop;
            setLimit(Math.floor(height / 19) || 1)
        }
    }, [rootRef]);

    if (!data)
        return null;


    const {types = []} = data;
    const content = [];
    if (limit && limit < types.length) {
        for (let i = 0; i < limit - 1; i++) {
            const item = types[i];
            content.push(<div key={i}>{item.name}</div>)
        }
        const last = limit - 1;
        content.push(<div key={last}>{types[last].name} (+{types.length - 1})</div>)
    } else {
        types.forEach((item: any, i: number) => content.push(<div key={i}><Tooltip title={item.description}>
            {item.name}
        </Tooltip></div>))
    }

    return <div ref={rootRef} className={styles.wrapper}>
        {content}
    </div>;
};

export default UnitDiseases;
