import {
    clearElements,
    getMetersPerPx,
    ICreateFactoryMarker,
    ICreatePoiMarker,
    ICreatePointMarker,
    ICreateSiteMarker,
    ICreateVesselMarker,
    IPropsMap,
    labelFont,
    limitShowZoom,
    MAP_ELEMENT,
    markerIcon,
    ZOOM_DEFAULT
} from "./constants";
import {diseaseType} from "../../varibles/constants";
import {closeAllInfoWindow, createDialog} from "./Dialog";
import {percentBrightness} from "../../varibles/global";

declare const google: any;

function getLabel(map: google.maps.Map | null, title: string) {
    const zoomLevel = map?.getZoom() || ZOOM_DEFAULT;
    const isHideLabel = zoomLevel < limitShowZoom;
    return isHideLabel ? null : {...labelFont, text: title}
}

const labelOrigin = () => ({labelOrigin: new google.maps.Point(0, 20)})

/*
* Date: 19/09/2022
* Author: Vinh.Pham
* Description: Create site marker
*/
export function createSiteMarker(args: ICreateSiteMarker & IPropsMap): {
    [key: string]: { element: google.maps.Marker, info: any }
} {
    const {markers, map, elements} = args
    const type = MAP_ELEMENT.SITE;
    const {getIcon, zIndex} = markerIcon[type];

    const {sites: old = {}} = elements;

    const result = markers.reduce((rs: any, item: any) => {
        const {id, name: title, lat, lng, diseases = {}, visible = true} = item;
        const {cases = []} = diseases;
        const {disease = null} = cases[0] || {};
        const {color = '#1B6399'} = diseaseType[disease] || {};

        if (rs[id]) return rs;

        const position = new google.maps.LatLng(lat, lng);
        const icon = getIcon({...labelOrigin(), color});

        if (old[id]) {
            if (!position.equals(old[id].element.getPosition()))
                old[id].element.setPosition(position);
            old[id].element.setIcon(icon)
            rs[id] = {...old[id], info: item};
            delete old[id];
        } else {
            const label = getLabel(map, title)
            const element: google.maps.Marker = new google.maps.Marker({
                position,
                title,
                zIndex,
                label,
                optimized: true,
                icon
            });
            rs[id] = {element, info: item};
        }
        addListenerForMarker({...args, ...rs[id], type})
        rs[id].element.setMap(visible ? map : null);

        return rs;
    }, {});

    clearElements(Object.keys(old).map(key => old[key].element))
    return result;
}


/*
* Date: 19/09/2022
* Author: Vinh.Pham
* Description: Create factory marker
*/
export function createFactoryMarker(args: ICreateFactoryMarker & IPropsMap): {
    [key: string]: { element: google.maps.Marker, info: any }
} {
    const {markers, map, elements} = args
    const type = MAP_ELEMENT.FACTORY;
    const {getIcon, zIndex} = markerIcon[type];
    const {factories: old = {}} = elements;

    const result = markers.reduce((rs: any, item: any) => {
        const {id, name: title, lat, lng, visible = true} = item
        const position = new google.maps.LatLng(lat, lng);
        const icon = getIcon({...labelOrigin()});

        if (old[id]) {
            if (!position.equals(old[id].element.getPosition()))
                old[id].element.setPosition(position);
            old[id].element.setIcon(icon)
            rs[id] = {...old[id], info: item};
            delete old[id];
        } else {
            const label = getLabel(map, title)
            rs[id] = {
                element: new google.maps.Marker({
                    position,
                    title,
                    zIndex,
                    label,
                    optimized: true,
                    icon
                }),
                info: item
            };
        }
        addListenerForMarker({...args, ...rs[id], type})
        rs[id].element.setMap(visible ? map : null);

        return rs;
    }, {});

    clearElements(Object.keys(old).map(key => old[key].element))
    return result;
}


/*
* Date: 26/12/2023
* Author: Vinh.Pham
* Description: Create POIs marker
*/
export function createPoiMarker(args: ICreatePoiMarker & IPropsMap): {
    [key: string]: { element: google.maps.Marker, info: any }
} {
    const {markers, map, elements} = args
    const type = MAP_ELEMENT.POI;
    const {getIcon, zIndex} = markerIcon[type];
    const {factories: old = {}} = elements;

    const result = markers.reduce((rs: any, item: any) => {
        const {id, name: title, lat, lng, visible = true} = item
        const position = new google.maps.LatLng(lat, lng);
        const icon = getIcon({...labelOrigin()});

        if (old[id]) {
            if (!position.equals(old[id].element.getPosition()))
                old[id].element.setPosition(position);
            rs[id] = {...old[id], info: item};
            delete old[id];
        } else {
            const label = getLabel(map, title);
            const element: google.maps.Marker = new google.maps.Marker({
                position,
                title,
                zIndex,
                label,
                optimized: true,
                icon
            });
            rs[id] = {element, info: item};
        }
        addListenerForMarker({...args, ...rs[id], type})
        rs[id].element.setMap(visible ? map : null);

        return rs;
    }, {});

    clearElements(Object.keys(old).map(key => old[key].element))
    return result;
}

/*
* Date: 19/09/2022
* Author: Vinh.Pham
* Description: Create vessel marker
*/
export function createVesselMarker(args: ICreateVesselMarker & IPropsMap): {
    [key: string]: { element: google.maps.Marker, info: any }
} {
    const {markers, map, elements} = args
    const type = MAP_ELEMENT.VESSEL;
    const {getIcon, zIndex} = markerIcon[type];
    const zoomLevel = map?.getZoom() || ZOOM_DEFAULT;
    const lat = (map?.getCenter() || {lat: () => 0}).lat();
    const {vessels: old = {}} = elements;

    const result = markers.reduce((rs: any, item: any) => {
        if (!item.gps) return rs;
        const {id, type: vesselType, visible = true} = item;
        const {width = 10, length = 20, Heading, Latitude, Longitude} = item.gps;
        const position = new google.maps.LatLng(Latitude, Longitude);
        const metersPerPx = getMetersPerPx(lat, zoomLevel);
        const icon = getIcon({width, length, metersPerPx, rotation: Number(Heading), vesselType})

        if (old[id]) {
            if (!position.equals(old[id].element.getPosition()))
                old[id].element.setPosition(position);
            old[id].element.setIcon(icon)
            rs[id] = {...old[id], info: item};
            delete old[id];
        } else {
            const element: google.maps.Marker = new google.maps.Marker({
                position,
                zIndex,
                optimized: true,
                icon
            });
            rs[id] = {element, info: item};
        }
        addListenerForMarker({...args, ...rs[id], type})
        rs[id].element.setMap(visible ? map : null);
        return rs;
    }, {});

    clearElements(Object.keys(old).map(key => old[key].element))
    return result;
}

interface IAddListener extends IPropsMap {
    element: any
    info: any
    type: MAP_ELEMENT
}

const addListenerForMarker = (props: IAddListener) => {
    const {element, info, type, event, elements} = props;
    const {click} = event;
    const propsDialog = {...props, contentType: type, data: info,}
    if (info.dialog) {
        createDialog({...propsDialog, position: element.position, currentTime: Date.now(),})
    }

    element.addListener('click', (e: any) => {
        if (click)
            click(info, e)
    });

    element.addListener('mouseover', (e: any) => {
        closeAllInfoWindow(elements.dialog || {}, [info.id]);
        createDialog({...propsDialog, position: e.latLng, currentTime: Date.now()})
    });
    element.addListener('mouseout', () => {
        closeAllInfoWindow(elements.dialog || {}, info.lock ? [info.id] : []);
    });
}


export function createPointsMarker(args: ICreatePointMarker & IPropsMap): { [key: string]: google.maps.Marker[] } {
    const {id, markers, map, color, elements, isEdit = false, event} = args;
    const old: google.maps.Marker[] = elements.points[id] || [];
    const type = MAP_ELEMENT.POINT;
    const fontColor = percentBrightness(color) < 85 ? '#FFF' : '#002c4d';
    const result = markers.reduce((list: any, item: any, index: number) => {
        const {lat, lng} = item;
        const position = new google.maps.LatLng(lat, lng);
        const label = isEdit ? {fontSize: "10px", color: fontColor, fontWeight: '300', text: `${index + 1}`} : '';
        let marker: google.maps.Marker;
        if (old[index] && old[index].getDraggable() === isEdit) {
            marker = old[index];
            marker.setPosition(position);
            marker.setLabel(label)
        } else {
            const {getIcon, zIndex} = markerIcon[type];
            marker = new google.maps.Marker({
                position,
                label,
                map,
                zIndex,
                icon: getIcon({color: '#' + color, size: 6}),
                draggable: isEdit,
            })
            const params = {id, type, color, index};
            const {dragstart, dragend} = event;
            if (dragstart)
                marker.addListener('dragstart', (e: any) => dragstart(params, e));
            if (dragend)
                marker.addListener('dragend', (e: any) => dragend(params, e));
        }

        return [...list, marker];
    }, []);
    const count = old.length;

    if (markers.length < count) {
        for (let i = markers.length; i < count; i++) {
            old[i].setMap(null);
        }
    }

    return {[id]: result};
}
