import React, {Component} from 'react';
import {Empty} from "antd";
import styles from './style.module.scss';
import {IPropsChildren} from "../../../../../util/varibles/interface";
import LoadingBox from "../../../../../components/LoadingBox";

interface IProps extends IPropsChildren {
    colPerRow: number
    loading: boolean
    list: any
    className?: string
}

class Stores extends Component<IProps> {
    render() {
        const {colPerRow, loading, list, className} = this.props;
        const style = {gridTemplateColumns: `repeat(${colPerRow},1fr)`};

        return <>
            <div className={[styles.list, className].join(' ')} style={style}>
                {loading ? <LoadingBox loading={true} className={styles.loading}/> : this.props.children}
            </div>
            {(!loading && list.length === 0) && <div className={styles['tab-empty']}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            </div>}
        </>;
    }
}

export default Stores;