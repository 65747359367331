import callApi from "../api/callApi";
import {urlWeather} from "../api/v1";

export function getWeathersFetch() {
    return callApi(urlWeather, {
        method: "GET"
    })
}

interface ICreateWeather {
    group_ids: string[],
    type: string,
    bad_condition: string,
    moderate_condition: string,
    good_condition: string,
    is_enable: boolean,
    description: string
}

export function createWeatherFetch(body: ICreateWeather) {
    return callApi(urlWeather, {
        method: "POST",
        body: JSON.stringify(body)
    })
}

interface IUpdateWeather {
    id: string,
    good_condition: string,
    bad_condition: string,
    moderate_condition: string,
    is_enable: boolean,
}

export function updateWeatherFetch(body: IUpdateWeather) {
    return callApi(urlWeather, {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export function deleteWeatherFetch(weatherId: string) {
    return callApi(urlWeather + "/" + weatherId, {
        method: "DELETE"
    })
}
