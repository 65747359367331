import React from 'react';
import styles from './style.module.scss';
import {durationTxtByStartAndFinish, getStartFinishByOpMode} from "../../util/varibles/global";
import {IActivityLog} from "../../util/varibles/interface";
import {useSelector} from "react-redux";
import {selectOpMode} from "../../util/store/selectors";
import Icon, {ICON_NAME} from "../Icon";

interface IProps {
    visible?: boolean
    activity_log: IActivityLog[]
}

const Duration: React.FC<IProps> = (props) => {
    const {visible = true, activity_log} = props;
    const mode = useSelector(selectOpMode);
    if (!visible) return null;
    const {start, finish} = getStartFinishByOpMode[mode](activity_log);

    return <div className={[styles.duration, styles['margin-left']].join(' ')} data-is-more={true}>
        <Icon icon={ICON_NAME.CLOCK_2}/>
        <span>{durationTxtByStartAndFinish(start, finish)}</span>
    </div>;
};

export default Duration;
