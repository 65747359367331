import React, {createRef, useEffect, useState} from 'react';
import styles from "./style.module.scss";
import {ElementType, IElement} from "../../constants";
import {initPropertiesElement} from "./constants";
import stylesElement from '../../Element/style.module.scss';
import stylesMiddle from '../MiddleSide/style.module.scss';

interface IProps {
    id: ElementType
    name: string
    icon: string

    onAdd(element: IElement, index: number | null): void
}

let dragElement: any, currentX = 0, currentY = 0;

const Element: React.FC<IProps> = (props) => {
    const [{left, top, isDrag}, setPosition] = useState({left: 0, top: 0, isDrag: false});
    const {icon, name} = props;
    const rootRef = createRef<HTMLDivElement>()

    const styleIcon: any = {'--icon-url': `url(${icon}) left center no-repeat`}
    const styleOnDrag: any = isDrag ? {top, left, position: 'absolute'} : {display: 'none'}

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }
    }, []);

    const onMouseDown = (e: React.MouseEvent) => {
        if (rootRef.current) {
            const {pageX, pageY} = e;
            const {offsetLeft, offsetTop} = rootRef.current;
            currentX = pageX;
            currentY = pageY;
            setPosition({left: offsetLeft, top: offsetTop, isDrag: false});
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        }
    }

    const onMouseMove = (e: MouseEvent) => {
        dragElement.style.display = 'none';
        const {pageX, pageY}: any = e;
        const target: any = document.elementFromPoint(pageX, pageY);
        const {offsetLeft = 0, offsetTop = 0} = rootRef.current || {};
        // const element = target.closest(`.${stylesElement['container-element']}`)
        // if (target && element && element.id.indexOf('_element') > -1) {
        //     const id = element.id.split('_')[0];
        // this.props.hoverElementAction(Number(id));
        // } else
        // this.props.hoverElementAction(-1);
        dragElement.style.display = 'flex';
        setPosition({left: offsetLeft + (pageX - currentX), top: offsetTop + (pageY - currentY), isDrag: true})
    }

    const onMouseUp = (e: MouseEvent) => {
        dragElement.style.display = 'none';
        const {id} = props;
        const data = initPropertiesElement(id);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        if (!isDrag) {
            props.onAdd(data, null)
        } else {
            const {pageX, pageY}: any = e;
            const target: any = document.elementFromPoint(pageX, pageY);
            const areaMiddle = target.closest(`.${stylesMiddle['form']}`)
            if (areaMiddle) {
                const element = target.closest(`.${stylesElement['container-element']}`)
                if (element && element.id.indexOf('_element') > -1) {
                    const id = element.id.split('_')[0];
                    props.onAdd(data, Number(id))
                } else {
                    props.onAdd(data, null)
                }
            }
        }
        setPosition(prev => ({...prev, isDrag: false}))
    }

    const saveDragElementRef = (element: any) => dragElement = element;

    return <>
        <div ref={rootRef} className={[styles['element'], 'no-select'].join(' ')}
             data-position='static'
             onMouseDown={onMouseDown}
        >
            <div className={styles['icon']} style={styleIcon}/>
            <span>{name}</span>
        </div>
        {
            <div className={[styles['element'], 'no-select'].join(' ')} style={styleOnDrag}
                 ref={saveDragElementRef}
                 data-position='absolute'
            >
                <div className={styles['icon']} style={styleIcon}/>
                <span>{name}</span>
            </div>
        }
    </>;
};

export default Element;

