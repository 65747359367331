import React, {Component} from 'react';
import {Button, Select, Tree} from 'antd';
import styles from './style.module.scss';
import {ConfigurationState, FORM_TYPES, IObject} from "./constants";
import Property from "./Property";
import {connect} from "react-redux";
import Form from "./Form";
import Title from "./Title";
import {configurationActions} from "./reducer";
import {AppState} from "../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    configuration: state.configuration
});

interface IProps {
    configuration: ConfigurationState
    iotName: any

    deleteServer(payload: any): void

    selectConfiguration(payload: any): void

    getDevices(payload: any): void

    changeDevice(payload: any): void

    sendDevice(payload: any): void
}

interface IState {
    formType: FORM_TYPES,
}

class ConfigurationPage extends Component<IProps, IState> {
    state = {
        formType: FORM_TYPES.SERVER,
    }

    constructor(props: IProps) {
        super(props);
        const {iotName} = props;
        if (iotName)
            this.props.getDevices({iotName});
    }

    renderTree = (servers = [], objects = [], metaData: any = {}) => {
        const result = [];
        if (servers.length > 0) {
            result.push({
                title: 'servers',
                key: 'servers',
                data: {type: FORM_TYPES.SERVER},
                children: servers.map((item: any, index: number) => {
                    const data = {type: FORM_TYPES.SERVER, data: item, index}
                    return {
                        title: <Title {...{...item, onClick: () => this.handleDeleteServer(index, data.type)}} />,
                        key: `server-${item.id}`,
                        data,
                        children: [
                            {
                                title: <Property {...{keyItem: 'id', item}} />,
                                key: `server-${item.id}-id`,
                                data
                            },
                            ...Object.keys(item).reduce((list: any[], keyItem: string) => {
                                if (keyItem !== 'id') {
                                    return [...list, {
                                        title: <Property {...{keyItem, item}} />,
                                        key: `server-${item.id}-${keyItem}`,
                                        data
                                    }]
                                }
                                return list;
                            }, [])
                        ]
                    }
                })
            })
        }
        if (objects.length > 0) {
            result.push({
                title: 'objects',
                key: 'objects',
                data: {type: FORM_TYPES.OBJECT},
                children: objects.map((item: any, index: number) => {
                    const data = {type: FORM_TYPES.OBJECT, data: item, index}
                    return {
                        title: <Title {...{...item, onClick: () => this.handleDeleteServer(index, data.type)}} />,
                        key: `object-${item.id}`,
                        data,
                        children: [
                            {
                                title: <Property {...{keyItem: 'id', item}} />,
                                key: `object-${item.id}-id`,
                                data
                            },
                            ...Object.keys(item).reduce((list: any[], keyItem: string) => {
                                switch (keyItem) {
                                    case 'id':
                                        return list;
                                    case 'tags':
                                        return [...list, {
                                            title: 'tags',
                                            key: `object-${item.id}-${keyItem}`,
                                            data: {type: FORM_TYPES.TAG, data: item},
                                            children: item.tags.map((tag: any, indexTag: number) => {
                                                const dataTag = {type: FORM_TYPES.TAG, data: item, index: indexTag}
                                                return {
                                                    title: <Title {...{
                                                        ...tag,
                                                        onClick: () => this.handleDeleteServer(indexTag, dataTag.type, item)
                                                    }} />,
                                                    key: `object-${item.id}-tag-${tag.id}`,
                                                    data: dataTag,
                                                    children: Object.keys(tag).reduce((listTag: any[], keyTag: string) => {
                                                        switch (keyTag) {
                                                            case 'id':
                                                                return listTag;
                                                            case 'transformation':
                                                                return [...listTag, {
                                                                    title: 'transformation',
                                                                    key: `object-${item.id}-tag-${tag.id}-${keyTag}-transformation`,
                                                                    data: dataTag,
                                                                    children: Object.keys(tag.transformation).reduce((listTransformation: any[], keyTransformation: string) => {
                                                                        const {
                                                                            transformation,
                                                                            transformation: {table}
                                                                        } = tag
                                                                        switch (keyTransformation) {
                                                                            case 'id':
                                                                                return listTransformation;
                                                                            case 'table':
                                                                                if (!table) return listTransformation;
                                                                                return [...listTransformation, {
                                                                                    title: 'table',
                                                                                    key: `object-${item.id}-tag-${tag.id}-${keyTag}-${keyTransformation}`,
                                                                                    data: dataTag,
                                                                                    children: table.map((itemTable: any, indexTable: number) => ({
                                                                                        title: <>
                                                                                            <span
                                                                                                className='mr-4'>x: {itemTable.x}</span>
                                                                                            <span>y: {itemTable.y}</span>
                                                                                        </>,
                                                                                        key: `object-${item.id}-tag-${tag.id}-${keyTag}-${keyTransformation}-${indexTable}`,
                                                                                        data: dataTag,
                                                                                    }))
                                                                                }];
                                                                            default:
                                                                                return [...listTransformation, {
                                                                                    title: <Property {...{
                                                                                        keyItem: keyTransformation,
                                                                                        item: transformation
                                                                                    }} />,
                                                                                    key: `object-${item.id}-tag-${tag.id}-${keyTag}-${keyTransformation}`,
                                                                                    data: dataTag
                                                                                }];
                                                                        }
                                                                    }, [])
                                                                }];
                                                            case 'link':
                                                                return [...listTag, {
                                                                    title: 'link',
                                                                    key: `object-${item.id}-tag-${tag.id}-${keyTag}-link`,
                                                                    data: dataTag,
                                                                    children: Object.keys(tag.link).reduce((listLink: any[], keyLink: string) => {
                                                                        const {link} = tag
                                                                        switch (keyLink) {
                                                                            case 'transformation':
                                                                                return listLink;
                                                                            default:
                                                                                return [...listLink, {
                                                                                    title: <Property {...{
                                                                                        keyItem: keyLink,
                                                                                        item: link
                                                                                    }} />,
                                                                                    key: `object-${item.id}-tag-${tag.id}-${keyTag}-${keyLink}`,
                                                                                    data: dataTag
                                                                                }];
                                                                        }
                                                                    }, [])
                                                                }];
                                                            default:
                                                                return [...listTag, {
                                                                    title: <Property {...{
                                                                        keyItem: keyTag,
                                                                        item: tag
                                                                    }} />,
                                                                    key: `object-${item.id}-tag-${tag.id}-${keyTag}`,
                                                                    data: dataTag
                                                                }];
                                                        }
                                                    }, [])
                                                }
                                            })
                                        }];
                                    default:
                                        return [...list, {
                                            title: <Property {...{keyItem, item}} />,
                                            key: `objects-${item.id}-${keyItem}`,
                                            data
                                        }];
                                }
                            }, [])
                        ]
                    }
                })
            })
        }
        if (Object.keys(metaData).length > 0) {
            result.push({
                title: 'meta data',
                key: 'metaData',
                data: {type: FORM_TYPES.META_DATA},
                children: Object.keys(metaData).map(keyItem => {
                    return {
                        title: <Property {...{keyItem, item: metaData}} />,
                        key: `metaData-${keyItem}-${keyItem}`,
                        data: metaData
                    }
                })
            })
        }
        return result;
    }

    handleDeleteServer = (index: number, formType: FORM_TYPES, object?: IObject) => {
        this.props.deleteServer({index, formType, object});
    }

    onSelect = (selectedKeys: any, info: any) => {
        this.props.selectConfiguration({selectedKeys, info})
    }

    handleChange = (value: any) => {
        const {devices} = this.props.configuration;
        const device = devices.find((item: any) => item.id === value);
        this.props.changeDevice(device);
    }

    handleSendDevice = () => {
        const {device} = this.props.configuration;
        if (Object.keys(device).length > 0)
            this.props.sendDevice(device)
    }

    render() {
        const {formType} = this.state;
        const {devices, isLoading, isChange} = this.props.configuration;
        let {device} = this.props.configuration
        if (this.props.iotName) {
            device = devices.length > 0 ? devices.find((item: any) => item.name === this.props.iotName) : {configuration: {}};
        }
        if (!device) return null;
        const {configuration = {}} = device;
        const {servers = [], objects = [], metaData = {}} = configuration;
        const {Option} = Select;

        return <div className={styles['container']}>
            {!this.props.iotName &&
            <Select className={styles['select-devices']} value={device.id} onChange={this.handleChange}>
                {devices.map((item: any) => <Option key={item.id} value={item.id}>
                    <div className={styles['option-device']}>
                        <span>Id: <b>{item.id}</b></span>
                        <span>Name: <b>{item.name}</b></span>
                        <span>Version: <b>{item.version}</b></span>
                    </div>
                </Option>)}
            </Select>}

            <div className={styles['content']}>
                <div className={styles['device-view']}>
                    <div className='d-flex justify-content-between align-items-center ml-2 mb-12'>
                        <h5 className='font-w5 mb-0'>{this.props.iotName ? 'Serial Number: ' : 'Device: '}<span
                            className='ml-2'>{device.name}</span></h5>
                        <Button className={[styles['btn'], styles['effect01']].join(' ')} loading={isLoading}
                                disabled={Object.keys(device).length === 0 || !isChange}
                                onClick={this.handleSendDevice}
                        >
                            Send
                        </Button>
                    </div>
                    <Tree {...{onSelect: this.onSelect, treeData: this.renderTree(servers, objects, metaData)}} />
                </div>
                <Form {...{formType}} />
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {
    deleteServer: configurationActions.deleteServer,
    selectConfiguration: configurationActions.selectConfiguration,
    getDevices: configurationActions.getDevicesRequest,
    changeDevice: configurationActions.changeDevice,
    sendDevice: configurationActions.sendDeviceRequest
})(ConfigurationPage);


