import React from 'react';
import {Select} from "antd";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../../util/store/selectors";
import {IFactory, TOperation} from "../../../../../../util/varibles/interface";
import {cloneObj} from "../../../../../../util/varibles/global";
import {checkRoute2} from "../../../../util";
import {notify, NotifyCode} from "../../../../../../util/varibles/message";

interface IProps {
    data: TOperation

    onChange(value: Partial<TOperation>): void
}

const {Option} = Select

const Factory: React.FC<IProps> = ({data, onChange}) => {
    const factories = useSelector(selectPlan.factories);
    const routeIds = useSelector(selectPlan.routeIds);

    const handleChange = (value: IFactory) => {
        const {sites} = data;
        const error_detail = cloneObj(data?.error_detail || {});
        let isRoute = false;
        const newSite = sites.map((sub: any) => {
            const {error} = checkRoute2(sub, value, routeIds);
            if (error !== 2) {
                isRoute = true;
                return {...sub, isRoute: true}
            }
            return {...sub, isRoute: false}
        })
        if (!isRoute) {
            error_detail.route = sites.map((item: any) => ({
                source_id: item.id,
                destination_id: value.id,
                message: notify[NotifyCode.E20]([item.name, value.name])
            }));
        } else {
            delete error_detail.route;
        }
        Object.assign(data, {
            ...data,
            factory_id: value.id,
            sites: newSite,
            factory_name: value.name,
            delivery_types: value.deliver_type || {},
            error_detail
        })
        const {router_id, ...opAfterDeleteRouterId} = data;
        onChange(opAfterDeleteRouterId)
    }

    return <Select
        value={data.factory_id}
        variant={"borderless"}
        onSelect={(id, option: { factory: IFactory }) => handleChange(option.factory)}
    >
        {factories.map(item => <Option key={item.id} value={item.id} factory={item}>
            {item.name}
        </Option>)}
    </Select>;
};

export default Factory;