import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import ContainerElement from "./ContainerElement";
import {Input} from "antd";
import {propsEmpty} from "../../../util/varibles/constants";

interface IProps extends IPropsElement {

}

class LongTextElement extends Component<IProps> {
    render() {
        const {TextArea} = Input;
        const {placeHolder, numberOfRows} = this.props.properties;
        return <ContainerElement {...this.props} >
            <TextArea {...{...propsEmpty, placeholder: placeHolder || '', rows: numberOfRows || 1}}/>
        </ContainerElement>;
    }
}

export default LongTextElement;
