import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../../components/Icon";

interface IProps {
    background: string
    name: string
}

const Condition: React.FC<IProps> = ({name, background}) => {
    return <div className={styles.condition}>
        <Icon icon={ICON_NAME.TANK} style={{background}}/>
        <div className={styles.name}>
            {name}
        </div>
    </div>;
};

export default Condition;
