import React, {createContext, useContext} from "react";

interface IRoundMapContext {
    isEdit?: boolean
}

const RoundMapContext = createContext<IRoundMapContext>({
})

const RoundMapProvider = ({children, isEdit}: { children: React.ReactNode, isEdit?: boolean }) => {


    return <RoundMapContext.Provider value={{
        isEdit
    }}>
        {children}
    </RoundMapContext.Provider>
}

const useRoundMap = () => useContext(RoundMapContext)

export {useRoundMap, RoundMapProvider}