import React from 'react';
import styles from "../style.module.scss";
import {Select} from "antd";
import {connect} from "react-redux";
import {loginActions} from "../../../../LoginPage/reducer";
import {AppState} from "../../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    const {priorityTime = 1} = state.login.user.setting || {};
    return {
        priorityTime,
    }
};

interface IProps {
    priorityTime: any

    updateUserSetting(payload: any): void
}

const PriorityTime: React.FC<IProps> = ({priorityTime, updateUserSetting}) => {
    const handleChange = (value: any) => {
        updateUserSetting([{priorityTime: value}]);
    }

    return <>
        <div className={styles.label}>
            If both site, factory are set arrival time, prioritize:
        </div>
        <Select variant={"borderless"} {...{
            className: styles.select,
            value: priorityTime,
            popupMatchSelectWidth: false,
            onChange: handleChange,
            options: [
                {value: 0, label: 'Site’s arrival time'},
                {value: 1, label: 'Factory’s arrival time'}
            ]
        }}/>
    </>;
};

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting,
})(PriorityTime);
