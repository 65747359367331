import React, {Component} from 'react';
import PieChartComponent from "./PreChart";
import styles from './style.module.scss';
import {getTotalAmountAndTotalWeight} from "../../../PlanOperationPage/util";
import {checkLimit, formatNumber} from "../../../../util/varibles/global";
import {OPERATION_TYPE, VESSEL_TYPE} from "../../../../util/varibles/constants";

const percent: any = [
    {"--percent-color": "#2F8ED5"},
    {"--percent-color": "#FFAA00"},
];

interface IProps {
    data: any
}

class Info extends Component<IProps> {
    render() {
        const {operation: {sub_operations, operation_type}} = this.props.data;
        const {type: vesselType, capacity, speed, harvest_capacity} = this.props.data.vessel;
        const total = getTotalAmountAndTotalWeight(sub_operations);
        const percentUsed = checkLimit(0, 100, Math.round(total.total_weight / capacity * 100));
        const percentAvailable = 100 - percentUsed;
        const capacityConvert = Math.round(capacity / 1000) / 1000;

        return <div className={styles['info']}>
            {vesselType !== VESSEL_TYPE.SERVICE && <div className={styles['info-line']}>
                <span>Capacity:</span>
                <span>{`${capacityConvert} t`}</span>
            </div>}
            <div className={styles['info-line']}>
                <span>Vessel speed:</span>
                <span>{`${speed} kt`}</span>
            </div>
            {operation_type === OPERATION_TYPE.HARVEST && <div className={styles['info-line']}>
                <span>Harvesting speed:</span>
                <span>{`${formatNumber(harvest_capacity)} fish/h`}</span>
            </div>}
            <div className="d-flex align-items-center mt-10">
                <PieChartComponent data={[percentAvailable, percentUsed]}/>
                <div className={styles['chart-info']}>
                    <p>
                        <span>{`${Math.round(total.total_weight / 1000) / 1000} / ${capacityConvert} t`}</span>
                    </p>
                    <p className={styles['percent-capacity']} style={percent[0]}>
                        <span>{`${percentUsed}% used`}</span>
                    </p>
                    <p className={styles['percent-capacity']} style={percent[1]}>
                        <span>{`${percentAvailable}% capacity available`}</span>
                    </p>
                </div>
            </div>
        </div>;
    }
}

export default Info;
