import {IIdName} from "../../util/varibles/interface";

export interface DashboardState {
    operations: any[]
    vessels: any[]
    sites: any[]
    factories: any[]
    productionAreas: IIdName[]

    weather: any
    target: any

    popupInfo: {
        isShow: boolean
        values?: any
    },

    tracking: boolean
    loadingProdAreas: boolean,
    loadingWeather: boolean
    loadingOp: boolean
    loadingVessels: boolean
}

export const initialState: DashboardState = {
    operations: [],
    vessels: [],
    sites: [],
    factories: [],
    productionAreas: [],

    weather: {},
    target: {},

    popupInfo: {
        isShow: false
    },

    tracking: false,
    loadingProdAreas: false,
    loadingWeather: false,
    loadingOp: false,
    loadingVessels: false
};