import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './style.module.scss';
import {MESSAGE_TYPE} from "../../../../../pages/MessagePage/constants";
import {IStateGlobal} from "../../../../../util/varibles/interface";
import {LoginState} from "../../../../../pages/LoginPage/constants";
import MessageTime from "./MessageTime";
import {messageActions} from "../../../../../pages/MessagePage/reducer";
import {MESSAGE_STATUS} from "../../../../../util/varibles/constants";
import Menu from "../../../../Menu";

const mapStateToProps = (state: IStateGlobal) => ({
    login: state.login
})

interface IProps {
    login: LoginState
    data: any
    avatar: any
    isSelected: boolean
    title: any

    onClick?(): void

    readMessage(payload: any): void
}

class Message extends Component<IProps> {
    static defaultProps = {
        isSelected: false,
    }

    menu = [
        {
            label: '1st menu item',
            key: '1',
        },
        {
            label: '2nd menu item',
            key: '2',
        },
        {
            label: '3rd menu item',
            key: '3',
        },
    ]

    renderSubtitle = () => {
        if (!this.props.data.message)
            return '';

        const {username, is_edited, message, message_type} = this.props.data.message;
        const currentUser = this.props.login.user.username;
        const isOwn = username === currentUser;
        const subTitle = [];
        if (isOwn) {
            subTitle.push('You')
        } else {
            const [nameText] = username.split('@');
            subTitle.push(nameText)
        }

        switch (message_type) {
            case MESSAGE_TYPE.ADD: {
                const {members} = this.props.data.message;
                const userB = members.map((item: any) => {
                    const [name] = (item.username || '').split('@');
                    return name === currentUser ? 'you' : name
                })
                subTitle.push(' added ' + userB + ' to the group');
                break;
            }
            case MESSAGE_TYPE.DELETE: {
                const {members} = this.props.data.message;
                const userB = members.map((item: any) => {
                    const [name] = (item.username || '').split('@');
                    return name === currentUser ? 'you' : name
                })
                subTitle.push(' removed ' + userB + ' from the group');
                break
            }
            case MESSAGE_TYPE.LEAVE: {
                subTitle.push(' left the group');
                break;
            }
            default: {
                if (is_edited)
                    subTitle.push(' edited: ');
                else
                    subTitle.push(': ');
                subTitle.push(message);
            }
        }


        return subTitle.join('');
    }

    onClick = () => {
        const {status} = this.props.data;
        if (status === MESSAGE_STATUS.NEW) {
            this.props.readMessage(this.props.data);
        }
        if (this.props.onClick)
            this.props.onClick();
    }

    render() {
        const {isSelected} = this.props;
        const {data} = this.props;
        const {status = 'read'} = data || {}
        const {timestamp = '-'} = data.message || {};

        return <Menu items={this.menu} trigger={['contextMenu']}>
            <div
                className={styles['wrapper']}
                data-is-new={status === MESSAGE_STATUS.NEW}
                data-selected={isSelected}
                onClick={this.onClick}
            >
                <div className={styles.avatar}>
                    {this.props.avatar}
                </div>
                <div className={styles['description']}>
                    <div className={styles['title']}>
                        {this.props.title}
                    </div>
                    <div className={styles['sub-title']}>
                        <span>{this.renderSubtitle()}</span>
                        <MessageTime timestamp={timestamp}/>
                    </div>
                </div>
            </div>
        </Menu>
    }
}

export default connect(mapStateToProps, {
    readMessage: messageActions.readMessageRequest
})(Message);