import React, {ReactNode, useState} from 'react';
import {patternFloat} from "../../../util/varibles/constants";
import {notify, NotifyCode} from "../../../util/varibles/message";
import styles from "../style.module.scss";

interface IProps {
    value: number | string
    className?: string
    placeholder?: string
    disabled?: boolean
    suffix?: string | ReactNode

    onChange(value: number): void

    onBlur?(): void
}

const InputNumber: React.FC<IProps> = ({
                                           value,
                                           disabled,
                                           className,
                                           placeholder,
                                           suffix,
                                           onChange,
                                           onBlur
                                       }) => {
    const [text, setText] = useState<number | string>(value);

    const handleBlur = () => {
        setText(value);
        if (onBlur)
            onBlur();
    };

    const handleChange = (e: any) => {
        const valueNew = e.target.value.trim();
        const reg = new RegExp(patternFloat);
        if (!reg.test(valueNew)) {
            notify.error(NotifyCode.E9)();
            return;
        }

        setText(valueNew);
        onChange(+valueNew);
    };

    const props = {
        disabled,
        placeholder,
        suffix,
        value: +text !== +value ? value : text,
        onChange: handleChange,
        onBlur: handleBlur
    }

    if (suffix) {
        return <div className={[styles.input, className].join(' ')}>
            <input className='h-32 w-full p-0' {...props} />
            <span data-lev='suffix'>{suffix}</span>
        </div>;
    }

    return <input className={className} {...props} />;
};

export default InputNumber;
