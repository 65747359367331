import React from 'react';
import styles from './style.module.scss';
import HeaderComponent from "./Header";
import Calendar from "./Calendar/index";

interface IProps {
}

const Plan: React.FC<IProps> = () => {
    return <div id='plan' className={[styles['plan-view'], 'no-select'].join(' ')}>
        <HeaderComponent/>
        <div id='content-plan' className={styles['view-body']} data-mode='calendar'>
            <div className={styles['tab-item']}>
                <Calendar/>
            </div>
        </div>
    </div>;
};

export default Plan;
