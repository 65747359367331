import React from 'react';
import styles from './style.module.scss';

const Header: React.FC = () => {
    return <div className={styles.header} >
        <div>Date</div>
        <div>Location, Time</div>
    </div>;
};

export default Header;
