import React, {useState} from 'react';
import stylesOp from "../style.module.scss";
import {KeyOfStep, listOfStep, OP_STATUS} from "../../../../../util/varibles/constants";
import Header from "../../../Component/Header";
import Note from "../../../Component/Note";
import {convertStepOfLocation, formatNumber} from "../../../../../util/varibles/global";
import ActivityLogs2 from '../../../ActivitiesLog/ActivityLogs2';
import HarvestingInfo from '../../../Component/HarvestingInfo'
import StepControl from "../../../Component/StepControl";
import {getTotalAmountAndTotalWeight} from "../../../../../pages/PlanOperationPage/util";
import Icon, {ICON_NAME} from "../../../../Icon";
import {IPropsOp} from "../index";

interface IProps extends IPropsOp {

}

const Harvest: React.FC<IProps> = ({data, step, active, changeStep, extend, onUpdate}) => {
    const [isShowActivityLog, setIsShowActivityLog] = useState(false)
    if (!data) return null;

    const {status, site, sites = [], factory_name, note = '', sub_operations = []} = data.operation;
    const {activity_log = []} = data;
    const {name: siteName = ''} = site || sites[0] || {};
    const {total_amount, total_weight} = getTotalAmountAndTotalWeight(sub_operations);

    const activityLog = activity_log.reduce((list: any, item: any, index: number) => {
        const {key = ''} = item;
        const steps = key.length > 0 ? key.split(',').map((step: string) => {
            if (step.indexOf('site') !== -1) {
                return convertStepOfLocation.site({
                    step: index,
                    key: step,
                    operation: data.operation,
                    activity_log
                });
            } else if (step.indexOf('factory') !== -1) {
                return convertStepOfLocation.factory({
                    step: index,
                    key: step,
                    operation: data.operation,
                    activity_log
                });
            }
            return listOfStep[step]
        }) : [];

        const value = {...item, steps, index};
        return [...list, {id: index, list: [value]}];
    }, []);

    const {key = ''}: any = activity_log[step] || {};

    return <>
        <Header
            data={data}
            step={step}
            icon={<Icon icon={ICON_NAME.HARVEST}/>}
            active={active}
            extend={extend}
            isShowActivityLog={isShowActivityLog}
            changeShowActivityLog={() => setIsShowActivityLog(!isShowActivityLog)}
        >
            <div className={[stylesOp['operation-route'], 'd-flex'].join(' ')}>
                <div>{siteName}</div>
                <span className={stylesOp['arrow-right']}>&#8594;</span>
                <div>{factory_name}</div>
            </div>
        </Header>
        <div className={stylesOp['operation-info']}>
            <div className={stylesOp['operation-content']}>
                <StepControl data={data} step={step} changeStep={changeStep} onUpdate={onUpdate}/>
                {isShowActivityLog ?
                    <div className={stylesOp.activityLog}>
                        <ActivityLogs2
                            activity_log={activityLog}
                            activity_log_root={activity_log}
                            current_process={data.operation.current_process}
                        />
                    </div>
                    : (status === OP_STATUS.PROCESSING && <div className={stylesOp['operation-step-content']}>
                        <div>Σ amount, Σ weight: <span
                            className={stylesOp['amount-weight']}>{formatNumber(total_amount)} | {formatNumber(Math.round(total_weight / 1000) / 1000)} t</span>
                        </div>
                        {[KeyOfStep.HARVEST, KeyOfStep.DISCHARGE_FACTORY].includes(key) &&
                        <HarvestingInfo sub_operations={sub_operations}/>}
                        <Note note={note}/>
                    </div>)}
            </div>
        </div>
    </>;
};

export default Harvest;