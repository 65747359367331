import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import {DatePicker} from "antd";
import ContainerElement from "./ContainerElement";
import {propContainer} from "../../../util/varibles/constants";

interface IProps extends IPropsElement {

}

class DateElement extends Component<IProps> {
    render() {
        const {separator}: any = this.props.properties;
        const format = ['YYYY', 'MM', 'DD'].join(separator);
        return <ContainerElement {...this.props} >
            <DatePicker {...{...propContainer, format}}/>
        </ContainerElement>;
    }
}

export default DateElement;
