import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty, onChangeRequired} from "./constants";
import styles from './style.module.scss';
import {Input} from "antd";

interface IProps extends IPropsPropertyElement {
}

class ShortTextElement extends Component<IProps> {

    render() {
        const {selected} = this.props;
        if (!selected) return null;

        const {label, properties: {required, placeHolder, subLabel}} = selected;
        return <>
            <div className={styles['title']}>Short text field Properties</div>
            <div className={styles['property-element']}>
                <label>Label</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
                <span>Type a text field label.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Required</label>
                <div className="d-flex">
                    <div className={styles.switch} onClick={() => onChangeRequired('required')}>
                        <input id='required' type="checkbox" checked={required}
                               onChange={() => onChangeProperty({required: !required}, this.props)}
                        />
                        <div className={styles.slider}/>
                    </div>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Placeholder</label>
                <Input type="text" value={placeHolder}
                       onChange={(e) => onChangeProperty({placeHolder: e.currentTarget.value}, this.props)}
                />
                <span>Add an example hint inside the field.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Sub label</label>
                <Input
                    type="text"
                    value={subLabel}
                    onChange={(e) => onChangeProperty({subLabel: e.currentTarget.value}, this.props)}
                />
                <span>Add a small description below the input field.</span>
            </div>
        </>;
    }
}

export default ShortTextElement;
