import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormInstance} from "antd/lib/form";
import {FORM_TYPES, functionTypes, IServer, ITarget, serverTypes, tagTypes} from "./constants";
import {Button, Form, Input, Select, Space} from "antd";
import styles from './style.module.scss';
import {configurationActions} from "./reducer";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {offset: 8, span: 16},
};

interface IProps {
    target: ITarget
    servers: IServer[]

    addServer(payload: any): void

    updateServer(payload: any): void
}

interface IState {
    extraFieldTag: any
    extraFieldTransformation: any
    extraFieldLink: any
    extraFieldFunctionLink: any
}

class FormTag extends Component<IProps, IState> {
    state = {
        extraFieldTag: <></>,
        extraFieldTransformation: <></>,
        extraFieldLink: <></>,
        extraFieldFunctionLink: <></>
    }

    formRef: any = React.createRef<FormInstance>();

    componentDidMount() {
        const {target: {data, index}} = this.props;
        if (data && this.formRef.current) {
            this.formRef.current.setFieldsValue(data.tags[index])
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {target: {data, index}} = this.props;
        if (this.props !== prevProps && data && this.formRef.current) {
            this.formRef.current.setFieldsValue(data.tags[index])
        }
    }

    onChangeType = (value: string) => {
        switch (value) {
            case tagTypes[2]: {
                this.setState({
                    extraFieldTag: <Form.Item className='mb-0' label="Value" name="value">
                        <Input/>
                    </Form.Item>
                })
                return;
            }
            default:
                this.setState({extraFieldTag: <></>})
                return;
        }
    }

    onChangeFunction = (value: string) => {
        switch (value) {
            case 'linear_interpolation': {
                this.setState({
                    extraFieldTransformation: <>
                        <Form.List name={['transformation', 'table']}>
                            {(fields: any, {add, remove}: any) => {
                                return <div>
                                    {fields.map(({key, ...field}: any, index: any) => (
                                        <Form.Item
                                            {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                            label={index === 0 ? 'Table' : ''}
                                            required={false}
                                            key={key}
                                        >
                                            <Space key={field.key} style={{display: 'flex', marginBottom: 0}}
                                                   align="start">
                                                <Form.Item key={key + '_x'} {...field} className='mb-0'
                                                           name={[field.name, 'x']}
                                                           fieldKey={[key, 'x']}
                                                           rules={[{required: true, message: 'Missing x'}]}
                                                >
                                                    <Input placeholder="x"/>
                                                </Form.Item>
                                                <Form.Item key={key + '_y'} {...field} className='mb-0'
                                                           name={[field.name, 'y']}
                                                           fieldKey={[key, 'y']}
                                                           rules={[{required: true, message: 'Missing y'}]}
                                                >
                                                    <Input placeholder="y"/>
                                                </Form.Item>
                                            </Space>
                                            <Button type="dashed" style={{width: '100%'}}
                                                    onClick={() => remove(field.name)}>
                                                Delele
                                            </Button>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} style={{width: '60%'}}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </div>;
                            }}
                        </Form.List>
                    </>
                })
                return;
            }
            case 'avg_two_values': {
                this.setState({
                    extraFieldTransformation: <>
                        <Form.Item className='mb-0' label="Input_1">
                            <Space style={{display: 'flex', marginBottom: 0}} align="start">
                                <Form.Item className='mb-0'
                                           name={['input_1', 'object_id']}
                                >
                                    <Input placeholder="object_id"/>
                                </Form.Item>
                                <Form.Item className='mb-0'
                                           name={['input_1', 'tag_id']}
                                >
                                    <Input placeholder="tag_id"/>
                                </Form.Item>
                            </Space>
                        </Form.Item>
                        <Form.Item className='mb-0' label="Input_2">
                            <Space style={{display: 'flex', marginBottom: 0}} align="start">
                                <Form.Item className='mb-0' name={['input_2', 'object_id']}>
                                    <Input placeholder="object_id"/>
                                </Form.Item>
                                <Form.Item className='mb-0' name={['input_2', 'tag_id']}>
                                    <Input placeholder="tag_id"/>
                                </Form.Item>
                            </Space>
                        </Form.Item>
                    </>
                })
                return;
            }
            default:
                this.setState({extraFieldTransformation: <></>})
                return;
        }
    }

    onChangeServer = (value: number) => {
        const {Option} = Select;
        const {servers} = this.props;
        const server = servers.find(item => item.id === value)
        if (!server) {
            this.setState({extraFieldLink: <></>, extraFieldFunctionLink: <></>})
            return;
        }
        switch (server.type) {
            case serverTypes[0]: {
                this.setState({
                    extraFieldFunctionLink: <></>,
                    extraFieldLink: <div className={styles['section']}>
                        <div className='font-s16 font-w3 p-1'>Link</div>
                        <Form.Item className='mb-0' label='ns'
                                   name={['link', 'ns']}
                        >
                            <Input placeholder="ns"/>
                        </Form.Item>
                        <Form.Item className='mb-0' label='s'
                                   name={['link', 's']}
                        >
                            <Input placeholder="s"/>
                        </Form.Item>
                        <div className='font-s16 font-w3 p-1'>Transformation</div>
                        <Form.Item className='mb-0' label="Function" name={['link', 'transformation', 'function']}>
                            <Select onChange={this.onChangeFunctionLink} allowClear>
                                {functionTypes.map(item => <Option key={item} value={item}>{item}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>
                })
                return;
            }
            case serverTypes[1]: {
                this.setState({
                    extraFieldFunctionLink: <></>,
                    extraFieldLink: <div className={styles['section']}>
                        <div className='font-s16 font-w3 p-1'>Link</div>
                        <Form.Item className='mb-0' label='address'
                                   name={['link', 'address']}
                        >
                            <Input placeholder="ns"/>
                        </Form.Item>
                        <Form.Item className='mb-0' label='length'
                                   name={['link', 'length']}
                        >
                            <Input placeholder="s"/>
                        </Form.Item>
                        <div className='font-s16 font-w3 p-1'>Transformation</div>
                        <Form.Item className='mb-0' label="Function" name={['link', 'transformation', 'function']}>
                            <Select onChange={this.onChangeFunctionLink} allowClear>
                                {functionTypes.map(item => <Option key={item} value={item}>{item}</Option>)}
                            </Select>
                        </Form.Item>
                    </div>
                })
                return;
            }
            default:
                this.setState({extraFieldLink: <></>, extraFieldFunctionLink: <></>})
                return;
        }
    }

    onChangeFunctionLink = (value: string) => {
        switch (value) {
            case 'linear_interpolation': {
                this.setState({
                    extraFieldFunctionLink: <>
                        <Form.List name={['link', 'transformation', 'table']}>
                            {(fields: any, {add, remove}: any) => <div>
                                {fields.map(({key, ...field}: any, index: any) => <Form.Item
                                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                    label={index === 0 ? 'Table' : ''}
                                    required={false}
                                    key={key}
                                >
                                    <Space key={field.key} style={{display: 'flex', marginBottom: 0}}
                                           align="start">
                                        <Form.Item key={key + '_x'} {...field} className='mb-0'
                                                   name={[field.name, 'x']}
                                                   fieldKey={[key, 'x']}
                                                   rules={[{required: true, message: 'Missing x'}]}
                                        >
                                            <Input placeholder="x"/>
                                        </Form.Item>
                                        <Form.Item key={key + '_y'} {...field} className='mb-0'
                                                   name={[field.name, 'y']}
                                                   fieldKey={[key, 'y']}
                                                   rules={[{required: true, message: 'Missing y'}]}
                                        >
                                            <Input placeholder="y"/>
                                        </Form.Item>
                                    </Space>
                                    <Button className='w-full mt-10' type="dashed"
                                            onClick={() => remove(field.name)}
                                    >
                                        Delele
                                    </Button>
                                </Form.Item>)}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} style={{width: '60%'}}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </div>}
                        </Form.List>
                    </>
                })
                return;
            }
            case 'avg_two_values': {
                this.setState({
                    extraFieldFunctionLink: <>
                        <Form.Item className='mb-0' label="Input_1">
                            <Space style={{display: 'flex', marginBottom: 0}} align="start">
                                <Form.Item className='mb-0' name={['input_1', 'object_id']}>
                                    <Input placeholder="object_id"/>
                                </Form.Item>
                                <Form.Item className='mb-0' name={['input_1', 'tag_id']}>
                                    <Input placeholder="tag_id"/>
                                </Form.Item>
                            </Space>
                        </Form.Item>
                        <Form.Item className='mb-0' label="Input_2">
                            <Space style={{display: 'flex', marginBottom: 0}} align="start">
                                <Form.Item className='mb-0' name={['input_2', 'object_id']}>
                                    <Input placeholder="object_id"/>
                                </Form.Item>
                                <Form.Item className='mb-0' name={['input_2', 'tag_id']}>
                                    <Input placeholder="tag_id"/>
                                </Form.Item>
                            </Space>
                        </Form.Item>
                    </>
                })
                return;
            }
            default:
                this.setState({extraFieldFunctionLink: <></>})
                return;
        }
    }

    onFinish = (values: any) => {
        if (values.server_id === undefined) delete values.server_id;
        const {target: {data, index}} = this.props;
        const {deltaTime = 0, deltaValue = 0} = values;
        values = {...values, deltaTime: Number(deltaTime), deltaValue: Number(deltaValue)}
        if (data.tags[index]) {
            data.tags.splice(index, 1, values)
            this.props.updateServer({values: data, index, formType: FORM_TYPES.TAG});
        } else {
            const {length} = data.tags
            const id = length > 0 ? data.tags[length - 1].id : 0
            values.id = id + 1;
            data.tags.push(values);
            this.props.addServer({values: data, formType: FORM_TYPES.TAG});
        }
    };

    render() {
        const {Option} = Select;
        const {target: {data, index}, servers} = this.props;
        const {extraFieldTag, extraFieldTransformation, extraFieldLink, extraFieldFunctionLink} = this.state;
        return <Form {...layout} ref={this.formRef} name="object" onFinish={this.onFinish}>
            <h5 className='mb-12'>
                <b>{data.tags[index] ? `Update tag: ${data.tags[index].name}` : 'Create tag'}</b>
            </h5>
            <Form.Item className='mb-0' label="Id" name="id">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Name" name="name">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Unit" name="unit">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Delta Time" name="deltaTime">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Delta Value" name="deltaValue">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Type" name="type">
                <Select onChange={this.onChangeType}>
                    {tagTypes.map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item className='mb-0' label="Server" name="server_id">
                <Select onChange={this.onChangeServer} allowClear>
                    {servers.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            </Form.Item>
            {extraFieldTag}
            <div className={styles['section']}>
                <div className='font-s16 font-w3 p-1'>Transformation</div>
                <Space.Compact>
                    <Form.Item className='mb-0' label="Function" name={['transformation', 'function']}>
                        <Select onChange={this.onChangeFunction} allowClear>
                            {functionTypes.map(item => <Option key={item} value={item}>{item}</Option>)}
                        </Select>
                    </Form.Item>
                    {extraFieldTransformation}
                </Space.Compact>
            </div>
            {extraFieldLink}
            {extraFieldFunctionLink}
            <Form.Item className='mt-10' {...tailLayout}>
                <Button className={[styles['btn'], styles['effect01']].join(' ')} htmlType='submit'>Save</Button>
            </Form.Item>
        </Form>;
    }
}

export default connect(null, {
    addServer: configurationActions.addServer,
    updateServer: configurationActions.updateServer,
})(FormTag);
