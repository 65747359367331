import React, {ReactNode} from 'react';
import styles from '../style.module.scss';
import {KeyChart, propertiesOfChart} from "../constants";

interface IProps {
    data: any
    remove: KeyChart[]

    onChange(value: KeyChart[]): void
}

const Legends: React.FC<IProps> = ({data, remove, onChange}) => {
    const ids = new Set(remove)
    return <div className={styles.legends}>
        {Object.keys(data).reduce((list: any, type) => {
            const {[type]: sub}: any = data;
            const names: ReactNode[] = [];
            Object.keys(sub).forEach(key => {
                if (sub[key].length === 0)
                    return

                const secondName = key.replace(/_/g, ' ')
                const {
                    name = secondName.charAt(0).toUpperCase() + secondName.slice(1),
                    color
                } = propertiesOfChart[key] || propertiesOfChart[KeyChart.DiseaseLocal];
                const props = ids.has(key as KeyChart)
                    ? {
                        "data-remove": 'true',
                        onClick: () => onChange(remove.filter(item => item !== key)),
                    }
                    : {
                        onClick: () => onChange([...remove, key as KeyChart])
                    }
                names.push(<div key={key} className={styles.legend} {...props}>
                    <div style={{background: color}}/>
                    <span>{name}</span>
                </div>)
            })
            return [...list, ...names]
        }, [])}
    </div>;
};

export default Legends;
