import React from 'react';
import stylesOp from "../Operation/style.module.scss";
import {datetime} from "../../../../util/library/datetime";
import {SHORT_DATETIME_FORMAT} from "../../../../util/varibles/constants";
import {IActivityLog} from "../../../../util/varibles/interface";

interface IProps {
    activity_log: IActivityLog[]
}

const PlannedTime: React.FC<IProps> = ({activity_log}) => {
    const est_start_time = activity_log[0].est_start_time;
    const est_finish_time = activity_log[activity_log.length - 1].est_start_time;

    return <div className={stylesOp['info-line']}>
        <span>Planned time: </span>
        <span className='d-flex align-items-center h-24'>
            <span>{datetime(est_start_time).format(SHORT_DATETIME_FORMAT)}</span>
            <span className='arrow-right'>&#8594;</span>
            <span>{datetime(est_finish_time).format(SHORT_DATETIME_FORMAT)}</span>
        </span>
    </div>;
};

export default PlannedTime;
