import React, {useMemo} from 'react';
import styles from "./style.module.scss";
import {notification} from "antd";
import Unit from "./Unit";
import {IPropsChildren} from "../../../../../../util/varibles/interface";
import {useEditOp} from "../../../../../../contexts/EditOperationContext";
import {getKeyOfUnit} from "../../../../util";

interface IProps extends IPropsChildren {
    gridTemplateColumns?: string

    nameRow(item: any): any

    addOperation(unit: any): void
}

const Units: React.FC<IProps> = (props) => {
    const {gridTemplateColumns = '1fr 2fr 1fr 1fr 50px'} = props;
    const [units] = useEditOp(state => state.units);
    const [ops] = useEditOp(state => state.ops);

    const {fishAmountByUnit, totalWeightByUnit} = useMemo(() => {
        return ops.reduce((rs: {
            fishAmountByUnit: { [id: string]: number },
            totalWeightByUnit: { [id: string]: number }
        }, item) => {
            const {sub_operations} = item;
            sub_operations.forEach(sub => {
                const key = getKeyOfUnit(sub);
                rs.fishAmountByUnit[key] = (rs.fishAmountByUnit[key] || 0) + sub.fish_amount;
                rs.totalWeightByUnit[key] = (rs.totalWeightByUnit[key] || 0) + sub.total_weight;
            })
            return rs
        }, {fishAmountByUnit: {}, totalWeightByUnit: {}})
    }, [ops]);

    const handleAdd = (unit: any, isInternal: boolean) => {
        const {unit_id, fish_amount} = unit;
        if (isInternal && fish_amount <= 0) {
            notification.error({message: 'Error', description: `${unit_id} has no more fish`});
            return;
        }
        props.addOperation(unit);
    }

    return <div className={styles.units}>
        {Object.keys(units).map((key: any) => <Unit
            key={units[key].id + units[key].harvest_id}
            fishAmountByUnit={fishAmountByUnit}
            totalWeightByUnit={totalWeightByUnit}
            gridTemplateColumns={gridTemplateColumns}
            data={units[key]}
            handleAdd={handleAdd}
        >
            {props.nameRow(units[key])}
        </Unit>)}
    </div>;
};

export default Units;

