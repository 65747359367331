export enum ACTION_SOCKET {
    REGISTER = 'registerGroups',
    UNREGISTER = 'unregisterGroups',
    GET_CONNECT_ID = 'getConnectionId'
}

export enum SOCKET_LEV {
    GLOBAL = 'global',
    PAGE = 'page',
    POPUP = 'popup'
}

export enum SOCKET_FUNC {
    VESSEL_GPS = 'onVesselGPSchange',
    OPERATION_STATUS_CHANGE = 'onOperationStatusChange',
    OPERATION_STATE_CHANGE = 'onOperationStateChange',
    OPERATION_CONFIRMED = "onOperationconfirm",
    OPERATION_DELETED = "onOperationdelete",
    OPERATION_RESET = "onOperationreset",
    OPERATION_EDITED = "onOperationchange",
    OPERATION_CHAT = 'onOperationUpdateChatbox',
    CHAT = 'onUpdateChatbox',
    AVAILABLE_TIME_CHANGE = 'onAvailabletimeChange',
    AVAILABLE_TIME_DELETED = 'onAvailabletimeDelete',
    HARVEST_CHANGE = 'onSthpHarvestChanged',
    ON_OPEN = 'onConnected',
    ON_ETA_CHANGE = 'onEtaUpdate',
    SEEN_MESSAGE = 'onSeenChatbox',
    ONGOING_UPDATE = 'onOperationOngoingUpdate',
    AUTO_ROUTE = 'onRouteauto',
    UPDATE_UNIT_HARVEST = 'onUnitupdate',
    UPDATE_TENANT = 'onTenantSettingUpdate',
    CHANGES_LOG = 'onLogUpdate',
    NOTIFICATIONS = 'onNotificationUpdate'
}