import React from 'react';
import styles from "./style.module.scss";

interface IProps {
    step: number

    changeStep(step: any): void
}

const Back: React.FC<IProps> = ({step, changeStep}) => {
    if (step <= 0)
        return <div/>;

    return <button className={styles['back-button']} onClick={() => changeStep(step - 1)}>
        <div className={styles['arrow-right']}/>
        Back
    </button>;
};

export default Back;