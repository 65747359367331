import React, {useEffect, useMemo, useState} from 'react';
import styles from './style.module.scss';

const useImage = (fileName: string) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [image, setImage] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(`../../assets/icon/${fileName}`) // change relative path to suit your needs
                setImage(response.default)
            } catch (err: any) {
                setError(err)
            } finally {
                setLoading(false)
            }
        }

        fetchImage().then()
    }, [fileName])

    return {loading, error, image}
}

export enum ICON_NAME {
    CALENDAR = 'calendar',
    OPERATION_DETAIL = 'op-detail',
    OPERATION_SETTING = 'op-setting',
    DELETE = 'delete',
    EDIT = 'edit',
    DOUBLE_ELLIPSE = 'double-ellipse',
    DOTS = 'dots',
    ROUTE = 'route',
    CLOCK = 'clock',
    CLOCK_2 = 'clock-2',
    SEND_TO_VESSEL = 'send-to-vessel',
    SEND_MESSAGE = 'send-message',
    SAVE_OPERATION = 'save',
    CANCEL = 'cancel',
    APPROVE = 'approve',
    REVERT = 'revert',
    ADD_DELAY = 'add-delay',
    LOCK = 'lock',
    ADD = 'add',
    ADD_2 = 'add-2',
    FILTER = 'filter',
    CLOSE = 'close',
    MAP = 'map',
    ATTACH = 'attach',
    NOTE = 'note',
    EXTERNAL_SITE = 'external-site',
    DISEASE = 'disease',
    DUPLICATE = 'duplicate',
    ARROW_2 = 'arrow-2',
    ARROW = 'arrow',
    VESSEL = 'vessel',
    VESSEL_3 = 'vessel_3.svg',
    START = 'start',
    MOVE_OP = 'move-op',
    HARVEST = 'harvest.svg',
    TREATMENT = 'add_2.svg',
    TRANSPORT = 'fish_1.svg',
    EVENT = 'event_operation.svg',
    EVENT_OLD = 'event',
    SERVICE = 'service_operation.svg',
    TANK = 'tank_2.svg',
    TANK_2 = 'tank.svg',
    DASHBOARD = 'dashboard',
    PLAN = 'plan',
    MESSAGE = 'message',
    OVERVIEW = 'overview',
    SETTING = 'setting',
    FACTORY = 'factory',
    CHECKLIST = 'checklist',
    WEATHER = 'weather',
    USER = 'user',
    PREFERENCE = 'preference',
    STHP_VIEW = 'sthp-view',
    ADD_USER = 'add-user',
    CHANGES_LOG = 'change-log',
    SEND_MAIL = 'send-mail',
    PDF = 'pdf',
    BELL = 'bell',
    LICE = 'lice',
    DECREASE = 'decrease',
    LOCATION = 'location.svg',
    ROUND = 'round.svg',
    PHONE = 'phone.svg',
    PRODUCTION_AREA = 'production_area.svg',
    ARROW_LEFT = 'arrow_left.svg',
    ARROW_DOWN = 'arrow_down_2.svg',
    EQUIPMENT = 'equipment.svg'
}

const sizes = {
    small: 12,
    normal: 15,
    large: 18
}

interface IProps {
    icon: ICON_NAME,
    style?: any
    className?: any
    danger?: boolean
    size?: 'small' | 'normal' | 'large' | number
    bg?: string
    dataAttribute?: any

    onClick?(e: any): void
}

const Icon: React.FC<IProps> = ({
                                    icon,
                                    style,
                                    size = 'normal',
                                    bg,
                                    className,
                                    danger = false,
                                    dataAttribute = {},
                                    onClick
                                }) => {
    const {image} = useImage(`${icon}`);
    const getIcon = useMemo(() => {
        if (styles[`icon-${icon}`] || !icon) {
            return {}
        } else {
            return {'--url-image': `url("${image}")`}
        }
    }, [icon, image])

    const width = useMemo(() => {
        return typeof size === 'number'
            ? {height: `${size}px`, width: `${size}px`}
            : {height: `${sizes[size]}px`, width: `${sizes[size]}px`};
    }, [size])

    const background = useMemo(() => bg ? {background: `var(--${bg})`} : {}, [bg])

    return <div
        {...dataAttribute}
        className={[className, 'icon', styles.icon, styles[`icon-${size}`], styles[`icon-${icon}`]].join(' ')}
        style={{...getIcon, ...width, ...background, ...style}}
        data-danger={danger}
        onClick={onClick}
    />
};

export default Icon;

