import React, {Component} from 'react';
import {connect} from "react-redux";
import {VESSEL_DEVICE_TYPE_NAME} from "../../util/varibles/constants";
import Detail from "./Detail";
import ProcessData from "./ProcessData";
import Configuration from "./Configuration";
import WSService from "../../web-socket/web-socket-service";
import {SOCKET_FUNC, SOCKET_LEV} from "../../web-socket/constants";
import Title from "./_Component/Title";
import ActivityLog from "./ActivityLog";
import {vesselDetailActions} from "./reducer";
import {getParamsFromUrl} from "../../util/library/Router";
import {Tabs} from "antd";
import styles from './style.module.scss';
import {AppState} from "../../util/store/store";

enum VIEW_TYPE {
    DETAIL = 'detail',
    GRAPH = 'graph',
    ACTIVITY_LOG = 'activityLog',
    CONFIG = 'config',
}


const mapStateToProps = (state: AppState) => ({
    permission: state.login.user.permission,
});

interface IProps {
    permission: any

    updateLocation(payload: any): void

    getVessel(args: { id: string, callback: Function }): void

    getDashboards(payload: string): void

    addGps(payload: number): void
}

class VesselDetailPage extends Component<IProps> {
    isOpenSocket = false;
    groups: string[] = [];
    socket: WSService | undefined;
    interval: any;
    timeout: any;
    timeoutWebsocket: any;
    id = '';

    constructor(props: IProps) {
        super(props);
        const {id = ''} = getParamsFromUrl();
        this.id = id;
        this.props.getVessel({
            id,
            callback: () => setTimeout(this.registerWebSocket, 300)
        });
        this.props.getDashboards(this.id);
        const newTime = (Math.floor(Date.now() / 300000) + 1) * 300000;
        this.timeout = setTimeout(() => {
            this.props.addGps(Date.now());
            this.interval = setInterval(() => this.props.addGps(Date.now()), 300000)
        }, newTime - Date.now())
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        if (this.socket) {
            this.socket.removeListener(SOCKET_LEV.PAGE);
            this.socket.unregisterGroups(this.groups)
        }
    }

    registerWebSocket = () => {
        this.groups = [this.id + "-" + VESSEL_DEVICE_TYPE_NAME.LOCATION, this.id + "-" + VESSEL_DEVICE_TYPE_NAME.SENSORS];
        this.socket = new WSService({
            groups: this.groups,
            listener: {
                lev: SOCKET_LEV.PAGE,
                func: (value: any) => this.processMessage(value)
            }
        });
    }

    processMessage = (value: any) => {
        this.isOpenSocket = true;
        if (value.function === SOCKET_FUNC.VESSEL_GPS && value.vessel_id && value.vessel_id === this.id) {
            this.props.updateLocation(value);
        }
    }

    render() {
        return <div className="m-header bgPage">
            <Tabs
                className={styles.page}
                defaultActiveKey={VIEW_TYPE.DETAIL}
                centered
                tabBarExtraContent={{
                    left: <Title/>,
                }}
                renderTabBar={(props, DefaultTabBar) => <DefaultTabBar className='h-56' {...props}/>}
                tabBarGutter={10}
                items={[
                    {
                        label: `Vessel info.`,
                        key: VIEW_TYPE.DETAIL,
                        children: <Detail actions={this.props.permission}/>
                    },
                    {
                        label: `Process data`,
                        key: VIEW_TYPE.GRAPH,
                        children: <ProcessData/>
                    },
                    {
                        label: `Activity log`,
                        key: VIEW_TYPE.ACTIVITY_LOG,
                        children: <ActivityLog vesselId={this.id}/>
                    },
                    {
                        label: `Configuration`,
                        key: VIEW_TYPE.CONFIG,
                        children: <Configuration/>
                    },
                ]}
            />
        </div>;
    }
}

export default connect(mapStateToProps, {
    updateLocation: vesselDetailActions.updateLocation,
    getVessel: vesselDetailActions.getVesselRequest,
    getDashboards: vesselDetailActions.getDashboardsRequest,
    addGps: vesselDetailActions.addGps,
})(VesselDetailPage)
