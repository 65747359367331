import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ACTION_TYPE, CUSTOMER_TYPE, customerType} from "../../../util/varibles/constants";
import styles from "../style.module.scss";
import {Button, Input, Table, Tooltip} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";
import Menu from "../../../components/Menu";
import Icon, {ICON_NAME} from "../../../components/Icon";
import {ITenant} from "../../../util/varibles/interface";
import {ACTION} from "../../../util/varibles/permission";
import WeatherCustomer from "./WeatherCustomer";
import GenerateSite from "./GenerateSite";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupEditCustomer from "../PopupEditCustomer";
import {AppState} from "../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    userTenantId: state.login.user.tenant_id,
    permission: state.login.user.permission,
    customers: state.user.customers,
    loadingCustomer: state.user.loadingCustomer,
})

interface IProps {
    permission: any
    userTenantId: string
    customers: any[]
    loadingCustomer: boolean

    openInfo(key: string, list: any): void
}

interface IState {
    name: string
    list: any[]
}

class Customers extends Component<IProps, IState> {
    state: IState = {
        name: '',
        list: []
    }

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 160
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
            width: 160
        },
        {
            title: 'Type',
            dataIndex: 'customer_type',
            key: 'type',
            width: 160,
            render: (type: CUSTOMER_TYPE) => {
                const {name} = customerType[type] || customerType[CUSTOMER_TYPE.TRIAL];
                return name;
            }
        },
        {
            title: 'Regions',
            dataIndex: 'regions',
            key: 'regions',
            render: (regions: any) => (regions || []).map((item: any) => item.name).join(', '),
        },
        {
            title: 'Vessel',
            dataIndex: 'vessels',
            key: 'vessels',
            width: 100,
            render: (vessels: any) => (vessels || []).length > 0 && <div className={styles['col-vessels']}>
                <div><Tooltip title={vessels.map((item: any) => item.name).join(', ')}>{vessels.length}</Tooltip></div>
                <Button
                    size='small'
                    icon={<UnorderedListOutlined/>}
                    onClick={() => this.props.openInfo('vessel', vessels)}
                />
            </div>,
        },
        {
            title: 'Integration',
            dataIndex: 'integration',
            key: 'integration',
            width: 110,
            render: (integration: any) => <span>{integration.type}</span>,
        },
        {
            title: 'Weather license',
            render: (data: any = {}) => <WeatherCustomer data={data}/>,
            width: 125,
        },
        {
            width: 50,
            render: (data: any) => {
                const menu = this.renderMenu(data);
                return menu.length > 0 && <Menu items={menu} placement={"bottomRight"}>
                    <div className='three-dot'/>
                </Menu>
            },
        },
    ]

    constructor(props: IProps) {
        super(props);
        const {customers} = this.props;
        this.state = {name: '', list: customers};
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        const {customers} = nextProps;
        if (JSON.stringify(this.props.customers) !== JSON.stringify(customers)) {
            const {name} = nextState;
            const data = name.toLowerCase();
            this.setState({list: customers.filter((item: any) => (item.name || '').toLowerCase().includes(data))})
            return false;
        }
        return true
    }

    handleFilter = (value: any) => {
        const {customers} = this.props
        const data = value.toLowerCase();
        this.setState({
            name: value,
            list: customers.filter((item: any) => (item.name || '').toLowerCase().includes(data)),
        })
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            const {customers} = this.props
            this.setState({name: '', list: customers})
        }
    };

    renderMenu = (data: any) => {
        const menu: any = [];

        const {customer_type, tenant_id} = data;
        const {permission, userTenantId} = this.props;
        if (permission[ACTION.TENANT.UPDATE] || tenant_id === userTenantId) {
            menu.push({
                key: 'edit',
                label: <div className='menu-line' onClick={() => this.handleEdit(ACTION_TYPE.EDIT, data)}>
                    <Icon icon={ICON_NAME.EDIT}/>
                    <div>Edit customer</div>
                </div>
            })
        }
        if (permission[ACTION.TENANT.GENERATE_SITES] && customer_type === CUSTOMER_TYPE.TRIAL) {
            const {is_generated = false} = data.integration || {};
            menu.push({
                key: 'generate',
                disabled: is_generated,
                title: is_generated ? 'Site and unit have been created' : '',
                label: <GenerateSite data={data} disabled={is_generated}/>
            })
        }
        return menu
    }

    handleEdit = (actionType: ACTION_TYPE, data?: ITenant) => {
        const el = openPopup(<PopupEditCustomer
            editMode={actionType}
            visible={true}
            customer={data}
            onClose={() => el.remove()}
        />)
    }

    render() {
        const {loadingCustomer} = this.props;
        const {permission} = this.props;
        const {name, list} = this.state;

        return <div className={styles.slide}>
            <div className={styles['container-action-tab']}>
                <Input
                    value={name}
                    className={styles['filter-by-name']}
                    placeholder='Search by name'
                    onChange={(e: any) => this.handleFilter(e.target.value)}
                    onKeyDown={this.handleKeyDown}
                />
                {permission[ACTION.TENANT.CREATE] && <Button
                    className={styles['bt-add']}
                    type="primary"
                    shape="circle"
                    onClick={() => this.handleEdit(ACTION_TYPE.CREATE)}
                >
                    <div className={styles['icon-add']}/>
                </Button>}
            </div>
            <div className={styles['list']}>
                <Table
                    rowKey='id'
                    loading={loadingCustomer}
                    pagination={false}
                    columns={this.columns}
                    dataSource={list}
                />
            </div>
        </div>;
    }
}

export default connect(mapStateToProps, {})(Customers);