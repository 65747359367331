import React from 'react';
import {confirmSign, IPropsElement} from "./constants";
import styles from './style.module.scss';
import {USER_ROLE, userRole} from "../../../util/varibles/constants";
import {useSelector} from "react-redux";
import {selectUser} from "../../../util/store/selectors";


interface IProps extends IPropsElement {
    title: string
    signer: any

    onClick(params: any): void
}


const SignElement: React.FC<IProps> = (props) => {
    const {roles = []} = useSelector(selectUser);
    const isSuper = roles.indexOf(USER_ROLE.SUPER_ADMIN) !== -1;
    const permission = isSuper ? roles.reduce((rs: any, item: any) => {
        if (item === USER_ROLE.ADMIN) {
            rs[USER_ROLE.CUSTOMER] = true;
            rs[USER_ROLE.CREW] = true;
        } else
            rs[item] = true;
        return rs;
    }, {}) : Object.keys(userRole).reduce((rs: any, key) => {
        rs[key] = true;
        return rs;
    }, {});

    function handleSigh(isChecked: boolean, data: any) {
        if (isChecked)
            return;

        confirmSign(title, data.value, props.onClick)
    }

    function Sign(signer: any, data: any, roles: any) {
        const isChecked = signer[data.value] !== undefined;
        const isPermission = roles[data.value];
        const {name = null} = userRole[data.value] || {};

        if (!name)
            return null;

        return <div className={[styles.element, styles.signElement].join(' ')} key={data.value}>
            <div className={styles.label}>{name}'s signature</div>
            <div className={styles.sign}>
                {isPermission
                    ? <div className={styles.btSign} onClick={() => handleSigh(isChecked, data)}
                           data-is-check={isChecked}>
                        {isChecked ? 'Signed' : 'Tap to sign'}
                    </div>
                    : <div className={styles.btWait} data-is-check={isChecked}>
                        {isChecked ? 'Signed' : 'Waiting to be signed'}
                    </div>}
                {signer[data.value] && <span title={signer[data.value]}>{`Note: ${signer[data.value]} `}</span>}
            </div>
        </div>
    }

    const {signer, title, properties: {options = ''}} = props;
    const data = options.length > 0 ? JSON.parse(options) : [];
    return data.reduce((list: any[], item: any) => [...list, Sign(signer, item, permission,)], []);
};

export default SignElement;
