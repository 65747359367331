import React, {Component} from 'react';
import {IPropsElement} from "./constants";
import ContainerElement from "./ContainerElement";
import styles from "./style.module.scss";
import {ClockCircleOutlined, SwapRightOutlined} from "@ant-design/icons/lib";

interface IProps extends IPropsElement {

}

class TimeElement extends Component<IProps> {
    render() {
        const placeHolderTime = 'HH : MM';
        const {isAllowSelect, label, properties: {subLabel, isTimeRange}} = this.props;
        return <ContainerElement {...this.props}>
            <label data-is-empty={label.length === 0}>
                {label || 'Label'}
            </label>
            <div className={styles['control']}>
                <div className={styles['placeholder']}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <span>{placeHolderTime}</span>
                            {isTimeRange && <>
                                <SwapRightOutlined className='ml-2 mr-2'/>
                                <span>{placeHolderTime}</span>
                            </>}
                        </div>
                        <ClockCircleOutlined className='pt-1 pr-1'/>
                    </div>
                </div>
                <div className={styles['sub-label']}>{subLabel || (isAllowSelect ? 'Sub label' : '')}</div>
            </div>
        </ContainerElement>;
    }
}

export default TimeElement;
