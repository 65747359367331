import React from 'react';
import {connect} from "react-redux";
import {IStateGlobal} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import Checklists from "../Checklists";

const mapStateToProps = (state: IStateGlobal) => ({
    vessels: state.login.vessels || [],
})

interface IProps {
    vessels: any
    children: any
}

const SectionRight: React.FC<IProps> = ({children}) => {
    return <div className={styles.wrapper}>
        {children}
        <Checklists/>
    </div>;
};

export default connect(mapStateToProps, {})(SectionRight)
