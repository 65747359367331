import React from 'react';
import {DatePicker} from 'antd';
import {propContainer, SHORT_DATETIME_FORMAT} from "../../../util/varibles/constants";
import styles from './style.module.scss';

const {RangePicker} = DatePicker

interface IProps {
    onChange(value: any): void
}

const Time: React.FC<IProps> = ({onChange}) => {

    return <div className={styles.filter}>
        <RangePicker {...{
            ...propContainer,
            allowClear: true,
            showTime: {format: 'HH:mm'},
            format: SHORT_DATETIME_FORMAT,
            placeholder: ['Start time', 'Finish time'],
            onChange,
        }} />
    </div>;
};

export default Time;
