import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectOneSetting} from "../../../util/store/selectors";
import {ProductionAreaPolyline} from "../../../contexts/WorldMapContext/Polyline";
import {DashboardSetting} from "../../../util/varibles/userSetting";
import {IProductionArea} from "../../../util/varibles/interface";
import {BarentsWatchService} from "../../../util/barentswatch/api";
import {dashboardActions} from "../reducer";
import {showErrorResponse} from "../../../util/varibles/global";

interface IProps {
}

const ProductionAreas: React.FC<IProps> = () => {
    const isProductionArea = useSelector(selectOneSetting(DashboardSetting.PRODUCT_AREA));
    const [productionAreas, setProductionAreas] = useState<IProductionArea[]>([])
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dashboardActions.updateLoadingProdAreas(true));
        BarentsWatchService.getProductionAreas()
            .then(rs => {
                dispatch(dashboardActions.updateLoadingProdAreas(false));
                setProductionAreas(rs);
                dispatch(dashboardActions.updateProductionAreas(rs.map((id, name) => ({id, name}))));
            })
            .catch(error => {
                dispatch(dashboardActions.updateLoadingProdAreas(false));
                showErrorResponse('Get production areas failed', error);
            })
    }, [dispatch]);

    return <>
        {productionAreas.map((item: any) => <ProductionAreaPolyline
            key={item.id}
            data={item}
            show={isProductionArea}
        />)}
    </>;
};

export default ProductionAreas;
