import React from 'react';
import stylesContainer from "../style.module.scss";

interface IProps {
    note: any
}

const Note: React.FC<IProps> = ({ note }) => {
    if (!note)
        return null;

    return <div className={stylesContainer['operation-note']}>
        <div className={stylesContainer['note-title']}>
            <div className={stylesContainer['note-icon']} />
            Note
        </div>
        <div>{note}</div>
    </div>;
};

export default Note;