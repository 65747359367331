import React from 'react';
import {layout} from "../../PlanOperationPage/Popup/Vessels/constants";
import {Form, Radio} from "antd";
import {ACTION_TYPE, CONTRACT_PERMISSION_TYPE, contractPermissionType} from "../../../util/varibles/constants";


interface IProps {
    values: any
    formRef: any

    onOk(formValues: any): void
}

const SingleContract: React.FC<IProps> = (props) => {
    const {onOk, formRef, values} = props;
    const {vesselName, editMode} = values;

    const handleOk = (formValues: any) => {
        onOk({permission: formValues.permission ? CONTRACT_PERMISSION_TYPE.FULL : CONTRACT_PERMISSION_TYPE.BASIC})
    }

    return <div>
        {editMode !== ACTION_TYPE.EDIT && <div className='mt-12'>
            Are you sure you want to approve single operation request on "{vesselName}"?
        </div>}

        <Form {...{
            ...layout,
            className: 'mt-10',
            ref: formRef,
            name: 'single-contract',
            layout: 'vertical',
            onFinish: handleOk,
            initialValues: {permission: values.permission}
        }}>
            <Form.Item
                className='h-36 mb-10 ml-10'
                name="permission"
            >
                <Radio.Group
                    options={Object.keys(contractPermissionType).map(key => ({
                        value: key,
                        label: contractPermissionType[key].name
                    }))}
                />
            </Form.Item>
        </Form>
    </div>;
};

export default SingleContract;