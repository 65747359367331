import {initialState} from "./constants";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUsersRequest: (state) => {
            state.loadingUser = true;
        },
        getUsersSuccess: (state, action) => {
            state.users = action.payload;
            state.loadingUser = false;
        },
        getUsersFailure: (state) => {
            state.loadingUser = false;
        },
        getTenantsRequest: (state) => {
            state.loadingCustomer = true;
        },
        getTenantsSuccess: (state, action) => {
            state.customers = action.payload;
            state.loadingCustomer = false;
        },
        getTenantsFailure: (state) => {
            state.loadingCustomer = false;
        },
        addUser: (state, action) => {
            const user = action.payload;
            const {users} = state;
            state.users = [user, ...users]
        },
        updateUser: (state, action) => {
            const user = action.payload;
            const {users} = state;
            state.users = users.map((item: any) => item.user_id === user.user_id ? user : item)
        },
        deleteUser: (state, action: PayloadAction<string>) => {
            const username = action.payload;
            const {users} = state;
            state.users = users.filter((item: any) => item.username !== username)
        },
        addTenant: (state, action) => {
            const customer = action.payload;
            const {customers} = state;
            state.customers = [{regions: [], vessels: [], ...customer}, ...customers];
        },
        updateTenant: (state, action) => {
            const customer = action.payload;
            const {customers} = state;
            state.customers = customers.map((item: any) => item.tenant_id === customer.tenant_id ? {...item, ...customer} : item)
        },
        getSitesRequest: (state) => {
            state.loadingSites = true;
        },
        getSitesSuccess: (state, action) => {
            state.sites = action.payload;
            state.loadingSites = false;
        },
        getSitesFailure: (state) => {
            state.loadingSites = false
        },
        getFactoriesRequest: (state) => {
            state.loadingFactories = true;
        },
        getFactoriesSuccess: (state, action) => {
            state.factories = action.payload;
            state.loadingFactories = false;
        },
        getFactoriesFailure: (state) => {
            state.loadingFactories = false
        }
    },
})

export const userActions = userSlice.actions

const userReducer = userSlice.reducer;

export default userReducer;
