import callApi, {api} from "../api/callApi";
import {urlServer} from "../api/v1";
import {store} from "../store/store";
import {ICombineTreatment, IDensityMap, IVessel} from "../varibles/interface";
import {updateUserSetting} from "../../pages/LoginPage/reducer";

const urlBase = urlServer + '/vessel';

export function getVesselsAndVesselContractFetch() {
    return callApi(urlBase + '/contract', {
        method: 'GET'
    })
}

export function getVesselDataFetch(vesselId: string) {
    return callApi(urlBase + '/data/' + vesselId, {
        method: 'GET'
    })
}

export function getOperationByVesselFetch(vesselId: string) {
    return callApi(urlBase + '/' + vesselId, {
        method: 'GET'
    })
}

export function createVesselFetch(vessel: any) {
    return callApi(urlBase, {
        method: 'POST',
        body: JSON.stringify(vessel)
    })
}

export function updateVesselFetch(vessel: any) {
    return callApi(urlBase, {
        method: 'PUT',
        body: JSON.stringify(vessel)
    })
}

export function getPositionVessels2Fetch(iotNames: string[]) {
    return callApi(urlBase + '/ioT', {
        method: 'POST',
        body: JSON.stringify(iotNames)
    })
}

interface IGetVesselSchedule {
    id: string,
    start_time: number,
    finish_time: number
}

export function getVesselSchedule(params: IGetVesselSchedule) {
    return callApi(urlBase + '/activity_log', {
        method: 'POST',
        body: JSON.stringify(params)
    })
}

export const VesselService = {
    vesselByTenantId: (abort?: AbortController) => {
        const state = store.getState();
        const {tenant_id} = state.login?.user || {};

        const callback = (rs: IVessel[]) => {
            if (rs.length > 0) {
                const {default_vessel} = state.login?.user || {};
                const {id = null} = rs.find((item: any) => item.id === default_vessel) || {};
                if (!id)
                    store.dispatch(updateUserSetting([{}, rs[0].id]));
            }
            return rs
        }

        return api.get<IVessel[]>(urlBase + '?tenant_id=' + tenant_id, {signal: abort?.signal, callback})
    },
    gets: () => api.get<IVessel[]>(urlBase),
    add: (vessel: any) => {
        return api.post<IVessel>(urlBase, vessel)
    },
    update: (vessel: any) => {
        return api.put<IVessel>(urlBase, vessel)
    },
    equipments: () => {
        return api.post<any[]>(urlBase + '/equipments')
    },
    storages: () => {
        return api.post<any[]>(urlBase + '/storages')
    },
    combineTreatment: (vesselId: string) => {
        return api.post<ICombineTreatment[]>(urlBase + '/treatment-type/group', {vessel_id: vesselId})
    },
    density: (value: IDensityMap, vesselId: string) => {
        return api.put(urlBase + '/density', {id: vesselId, density_map: value})
    }
}