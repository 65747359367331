import {all, call, put, takeEvery} from "redux-saga/effects";
import {getTreatmentsFetch} from "../../util/services/combine";
import {combineTreatmentActions} from "./reducer";
import {showErrorResponse} from "../../util/varibles/global";

function* getCombineTreatmentWatcher(): Generator<any, any, any> {
    try {
        const list = yield call(getTreatmentsFetch);
        yield put(combineTreatmentActions.getListSuccess(list))
    } catch (error) {
        showErrorResponse('Get data failed', error)
        yield put(combineTreatmentActions.getListFailure())
    }
}

export default function* combineTreatmentSaga() {
    yield all([
        takeEvery(combineTreatmentActions.getListRequest.type, getCombineTreatmentWatcher),
    ])
}
