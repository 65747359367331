import {convertPxToTimeByStep} from "../../../../../util";
import {OP_STATUS, OPERATION_TYPE, operationType} from "../../../../../../../util/varibles/constants";
import {checkLimit} from "../../../../../../../util/varibles/global";
import {IActivityLog, ILevel, IOperation, IVessel} from "../../../../../../../util/varibles/interface";
import {createKeyActivityLog} from "../../constants";

interface IGetNewTime {
    get(): any

    data: IOperation
    e: any
}

export function getNewTime(args: IGetNewTime) {
    const {data, get} = args;
    const {offsetX, offsetY, layerX, layerY} = args.e;

    const x = offsetX || layerX;
    const y = offsetY || layerY;

    const {vesselWidth, vessels, key, currentKey, activityLogs, targetY, loading} = get();
    const vesselIndex = Math.floor(x / vesselWidth);
    const vessel = vessels[checkLimit(0, vessels.length - 1, vesselIndex)];

    const {activity_log, operation, vessel: vesselOld} = data;

    const result = {
        x,
        newStartTime: 0,
        key,
        currentKey,
        vessel,
        isAction: false,
        error: '',
        vesselIndex
    }

    const duration = convertPxToTimeByStep(y - targetY);
    result.newStartTime = activity_log[0].est_start_time + duration;

    if (vessel.id === vesselOld.id && targetY === y) return {...result, error: '-'};

    const {operation_type} = operation;

    if (operation_type !== OPERATION_TYPE.EVENT) {
        const isError = vessel.operation_type.indexOf(operation_type) === -1;
        if (isError) {
            const {name = ''} = operationType[operation_type] || {};
            return {...result, error: `${vessel.name} does not support ${name}`}
        }

        const newKey = createKeyActivityLog(operation, vessel);
        result.currentKey = newKey;
        if (activityLogs[newKey]) {
            result.key = newKey;
        } else if (!loading)
            result.isAction = true;
    }
    return {...result, vessel}
}

export const checkVessel = ({vessel, operation_type}: { vessel: IVessel, operation_type: OPERATION_TYPE }) => {
    const isError = vessel.operation_type.indexOf(operation_type) === -1;
    if (isError) {
        const {name = ''} = operationType[operation_type] || {};
        return `${vessel.name} does not support ${name}`
    }
    return ''
}

export const findTime = ({e, activity_log, get}: { e: { layerY?: number } & MouseEvent, activity_log: IActivityLog[], get(): any }) => {
    const {offsetY, layerY = 0} = e;
    const {targetY} = get();
    const y = offsetY || layerY;
    const duration = convertPxToTimeByStep(y - targetY);
    return {y, time: activity_log[0].est_start_time + duration};
}

export const findVessel = ({e, get}: { e: { layerX?: number } & MouseEvent, get(): any }) => {
    const {offsetX, layerX = 0} = e;
    const x = offsetX || layerX;
    const {vesselWidth, vessels} = get();
    const vesselIndex = Math.floor(x / vesselWidth);
    return {x, vessel: vessels[checkLimit(0, vessels.length - 1, vesselIndex)]};
}

export const checkStatus = (status: any) => [
    OP_STATUS.NEW,
    OP_STATUS.SAVED,
    OP_STATUS.WAITING,
    OP_STATUS.APPROVED,
    OP_STATUS.PENDING
].indexOf(status) !== -1

interface IGetHeightOfOp {
    element: any
}

export function getHeightOfOp(args: IGetHeightOfOp) {
    const {element} = args;
    if (!element)
        return;

    const index = element.children.length - 1;
    const {scrollHeight} = element.children[index];
    return scrollHeight + 7;
}

export const calcWidth: { [id: string]: (level: ILevel, width: number, index: number) => any } = {
    true: (level: ILevel, width: number) => {
        return {
            width: `calc(${(width * .5) + (width * .5 / (level.sub + 1))}% - ${level.sub * 10 + 20}px)`
        }
    },
    false: (level: ILevel, width: number, index: number) => {
        return {
            left: `calc(${width * (index + 1) - (width * .5 / (level.sub + 1) * level.level)}% - ${level.level * 10}px)`,
            leftTimeLine: `calc(${width * (index + 1) - (width * .5 / (level.sub + 1) * level.level)}% - ${level.level * 10}px + 110px)`,
            width: `${(width * .5 / (level.sub + 1))}%`
        }
    }
}