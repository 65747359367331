import React from 'react';
import {Image, Upload} from "antd";
import S3Image from "../../../../components/S3Image";
import ImageDefault from "../../../../assets/icon/vessel_image_default.svg";
import styles from "../style.module.scss";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useEditVessel} from "../../../../contexts/EditVesselContext";

interface IProps {

}

const VesselImage: React.FC<IProps> = () => {
    const imgRef = React.createRef<any>();
    const [image_url, setStore] = useEditVessel(state => state.vessel?.image_url);
    const [file] = useEditVessel(state => state.file);

    const onDelete = (e: React.MouseEvent) => {
        setStore({file: null});
        e.stopPropagation();
    }

    const onEdit = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (imgRef.current) {
            const inputRef = imgRef.current.nativeElement.getElementsByTagName('input')[0];
            if (inputRef)
                inputRef.click();
        }
    }

    return <div>
        <div className={styles.img}>
            <Upload
                ref={imgRef}
                showUploadList={false}
                className={styles.upload}
                fileList={file ? [file] : []}
                multiple={false}
                onRemove={() => {
                    setStore({file});
                }}
                beforeUpload={(file: any) => {
                    setStore({file});
                    return false;
                }}
            />
            {file ? <Image {...{
                width: '100%',
                src: URL.createObjectURL(file),
                fallback: ImageDefault,
                preview: {
                    mask: <div className={styles.actionImg}>
                        <div className={styles.edit} onClick={onEdit}>
                            <EditOutlined/>
                        </div>
                        <div className={styles.delete} onClick={onDelete}>
                            <DeleteOutlined/>
                        </div>
                    </div>
                },
            }} /> : <S3Image
                preview={{
                    mask: <div className={styles.action}>
                        <div className={styles.edit} onClick={onEdit}>
                            <EditOutlined/>
                        </div>
                    </div>
                }}
                imageUrl={image_url || ''}
                height={image_url ? 210 : 180}
            />}
        </div>
        <div className='text-comment italic mt-1'>JPG, GIF or PNG. Max size of 800K</div>
    </div>;
};

export default VesselImage;
