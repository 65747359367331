import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useEditVessel} from "../../../../contexts/EditVesselContext";
import PopupAddEquipments from "../PopupAddEquipments";
import {Button} from "antd";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {IEquipment} from "../../../../util/varibles/interface";
import {equipmentForm, treatmentName} from "../constants";
import styles from '../style.module.scss';
import InputForm from "../../../../components/Basic/InputForm";
import {cloneObj, getElById} from "../../../../util/varibles/global";
import LoadingBox from "../../../../components/LoadingBox";
import {VESSEL_TYPE} from "../../../../util/varibles/constants";
import Density from "./Density";

const keys = ['name', 'value'];
export const groupEquipments = (equipments: IEquipment[]) => equipments.reduce((rs: {
    [id: string]: ({ index: number } & IEquipment)[]
}, item, i) => {
    const key = item.types.sort((a: number, b: number) => a - b).join(',');
    rs[key] = [...rs[key] || [], {...item, index: i}];
    return rs;
}, {})

const Equipment: React.FC<{
    id: number,
    data: IEquipment,
    onChange: (value: IEquipment) => void,
    onRemove: () => void
}> = ({
          id,
          data,
          onChange,
          onRemove
      }) => {
    const handleChange = (value: Partial<IEquipment>) => onChange({...data, ...value});
    const [error = {}] = useEditVessel(state => {
        const {[id]: selector} = state.error?.equipments || {};
        return selector
    })

    return <div id={`equipment-${id}`} className={styles.equipment}>
        {keys.map(item => {
            const props = equipmentForm[item];
            return <InputForm
                {...props}
                key={item}
                error={error[item]}
                value={data[item]}
                onChange={value => handleChange({[item]: value})}
            />
        })}
        <Button className='bt-ghost' data-icon='true' data-danger={true} onClick={onRemove}>
            <Icon icon={ICON_NAME.DELETE}/>
        </Button>
    </div>
}

const Equipments: React.FC<{ vesselType: VESSEL_TYPE }> = ({vesselType}) => {
    const [equipments = [], setStore] = useEditVessel(state => state.vessel?.equipments);
    const [visible, setVisible] = useState(false);
    const rootRef = useRef<HTMLDivElement>(null);

    const group = useMemo(() => groupEquipments(equipments), [equipments]);

    const handleChange = useCallback((index: number, value: IEquipment) => {
        setStore(({vessel: old}) => {
            const currentData = cloneObj(old?.equipments || []);
            currentData.splice(index, 1, value);
            return {vessel: {...old, equipments: currentData}}
        })
    }, [setStore]);

    const handleRemove = useCallback((index: number) => {
        setStore(({vessel: old}) => {
            const currentData = cloneObj(old?.equipments || []);
            currentData.splice(index, 1);
            return {vessel: {...old, equipments: currentData}}
        })
    }, [setStore]);

    return <div className='mt-4 mb-4'>
        <div className='flex items-center gap-2 mb-3'>
            <span className='text-[15px] font-[600] mt-1'>Add equipment to define in-pipe treatment</span>
            <Button className="bt-ghost" data-icon={true} size='small' onClick={() => setVisible(true)}>
                <Icon icon={ICON_NAME.ADD_2} size='small'/>
            </Button>
            {visible && <PopupAddEquipments
                onAdd={(newValue: any[]) => {
                    setStore(({vessel: old}) => {
                        const currentData = cloneObj(old?.equipments || []);
                        setTimeout(() => {
                            const el = getElById(`equipment-${currentData.length}`);
                            if (el) {
                                const input = el.getElementsByTagName('input')[0];
                                if (input) {
                                    input.focus();
                                }
                            }
                        }, 300);
                        return {vessel: {...old, equipments: [...currentData, ...newValue]}}
                    });

                }}
                onClose={() => setTimeout(() => setVisible(false), 300)}
            />}
        </div>
        {equipments.length === 0 && <LoadingBox className='mt-3 max-w-[300px]' loading={false}/>}
        <div ref={rootRef} className='flex flex-col gap-[10px] mb-4'>
            {Object.keys(group).map(key => {
                return <div key={key}>
                    <div className='flex justify-between mb-2'>
                        <div className='fill-bg-second'>{treatmentName(key)}</div>
                        {vesselType === VESSEL_TYPE.WELL_BOAT && <Density id={key}/>}
                    </div>
                    <div className='flex flex-col gap-[8px]'>
                        {group[key].map(({index, ...item}, i) => <Equipment
                            key={i}
                            id={index}
                            data={item}
                            onChange={data => handleChange(index, data)}
                            onRemove={() => handleRemove(index)}
                        />)}
                    </div>
                </div>
            })}
        </div>
    </div>;
};

export default Equipments;