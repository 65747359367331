import React from 'react';
import styles from "../../Control/LeftControl/style.module.scss";

interface IProps {
    phone: any
}

const Phone: React.FC<IProps> = ({phone}) => {
    return <div className={styles['line-info']}>
        <span>Contact number:</span>
        <span>{phone}</span>
    </div>;
};

export default Phone;
