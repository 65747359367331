import React, {Component} from 'react';
import {aWeekMillisecond, FULL_DATE_FORMAT, FULL_DATE_FORMAT_ORDINAL} from "../../../util/varibles/constants";
import styles from './style.module.scss'
import Datetime, {datetime} from "../../../util/library/datetime";

interface IProps {
    data: Datetime
}

class Timeline extends Component<IProps> {
    render() {
        const {data} = this.props;
        let text;
        const isOld = Date.now() - data.time > aWeekMillisecond;
        if (isOld)
            text = data.format(FULL_DATE_FORMAT);
        else if (datetime().format(FULL_DATE_FORMAT) === data.format(FULL_DATE_FORMAT))
            text = 'Today';
        else
            text = data.format(FULL_DATE_FORMAT_ORDINAL)
        return <div className={styles['timeline']}>
            <span>{text}</span>
        </div>;
    }
}

export default Timeline;