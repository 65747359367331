import React from 'react';
import {ISchedule, SCHEDULE_STATUS} from "../../../util/varibles/interface";
import styles from './style.module.scss';
import {diseasedZone, diseaseType, SHORT_TIME_FORMAT} from "../../../util/varibles/constants";
import Empty from "./Empty";
import {datetime} from "../../../util/library/datetime";

const statusName = {
    0: {
        [SCHEDULE_STATUS.ENTER]: 'Arrival',
        [SCHEDULE_STATUS.LEAVE]: 'Departure'
    },
    1: {
        [SCHEDULE_STATUS.ENTER]: 'Enter',
        [SCHEDULE_STATUS.LEAVE]: 'Leave'
    }
}

interface IProps {
    data?: ISchedule

    setDisease?(disease: any): void
}

const Activity: React.FC<IProps> = ({data, setDisease}) => {
    let content = null, status = '', icon = null;
    if (!data)
        return <Empty/>
    const {location_type} = data;
    switch (location_type) {
        case "site":
        case "factory": {
            if (data.status === SCHEDULE_STATUS.ENTER) {
                const {diseaseName = null} = data.diseases[0] || {}
                const {color = null} = diseaseType[diseaseName] || {};
                icon = <div className={styles.icon} data-icon={location_type} style={color ? {background: color} : {}}/>
            }
            status = statusName[1][data.status];
            content = <span className={styles.locationName}>{data.name}</span>
            break;
        }
        case "disease": {
            if (setDisease)
                setDisease(data);

            const {type = null} = data.disease || {};
            const {name = ''} = diseasedZone[type] || {};
            content = <span className={styles.locationName}>{name}</span>
            status = statusName[0][data.status];
            break;
        }
    }

    return <div className={styles.wrapper}>
        {icon}
        <div>{status} {content}</div>
        <div>{datetime(data.time).format(SHORT_TIME_FORMAT)}</div>
    </div>;
};

export default Activity;
