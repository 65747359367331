import React from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../../../Icon";

interface IProps {
    data: any
}

const Note: React.FC<IProps> = ({data = ''}) => {
    if (typeof data !== 'string' || data.trim().length === 0)
        return null;

    return <div className={styles.note}>
        <Icon className={styles.iconNote} icon={ICON_NAME.NOTE}/>
        <span>{data}</span>
    </div>;
};

export default Note;
