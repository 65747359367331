import React from 'react';
import styles from "./style.module.scss";
import {IOperation} from "../../../../util/varibles/interface";
import {OP_STATUS} from "../../../../util/varibles/constants";

interface IProps {
    data: IOperation
    step: number

    changeStep(step: any): void
}

const CurrentProcess: React.FC<IProps> = ({data, step, changeStep}) => {
    const {current_process = 0, status} = data.operation;

    if (step === current_process || status !== OP_STATUS.PROCESSING)
        return <div/>

    return <button className={styles['current-step-button']} onClick={() => changeStep(current_process)}>
        Jump to current step
    </button>;
};

export default CurrentProcess;