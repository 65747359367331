import React, {useEffect, useState} from 'react';
import {KEY_STORAGE, KEY_TAB, OP_STATUS, OPERATION_TYPE} from "../../../../util/varibles/constants";
import stylesContainer from "../style.module.scss";
import Operation from "./Operation";
import {checkOperationType, deleteDragEl, KEY_SPLIT, MODE_DRAG, onMouseDown} from "../constants";
import {updateTime} from "../../Plan/Calendar/Operations/constants";
import {getElById, showErrorResponse} from "../../../../util/varibles/global";
import {IOperation} from "../../../../util/varibles/interface";
import {openPopup} from "../../../../components/Popup/Component/WrapperPopup";
import {getNewStart} from "./constants";
import {OpService} from "../../../../util/services/operation";
import {useDispatch, useSelector} from "react-redux";
import {getActivityLog, planOpActions} from "../../reducer";
import {selectPlan} from "../../../../util/store/selectors";
import Cards from "../_Component/Cards1";
import {AppState} from "../../../../util/store/store";

let dragEl: HTMLDivElement | any;

const Cancel: React.FC = () => {
    const canceled = useSelector((state: AppState) => state.planOperation.canceled)
    const result = canceled.filter(({isShow = true}) => isShow)
    const vessels = useSelector(selectPlan.vessels)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        OpService.getCanceled()
            .then(rs => {
                setLoading(false)
                const {operations = []} = rs;
                dispatch(planOpActions.getCancelOps(Array.isArray(operations) ? operations : []))
            })
            .catch(err => {
                setLoading(false)
                showErrorResponse('Get data of canceled operation failure', err);
            })
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
            cancelDrag()
        }
    }, []);

    const onDragStart = (e: any, id: string) => {
        const el = document.getElementById(id);
        if (!el)
            return;

        const {clientWidth} = el;
        localStorage.setItem(KEY_STORAGE.CANCEL_OP_SELECTED, [id, clientWidth].join('_'));

        const mode = MODE_DRAG.START
        localStorage.setItem(KEY_STORAGE.CANCEL_DRAG, mode);

        onChangePosition(e);

        createDragEl();
        activeDrag();

        return true;
    }

    const onDragEnd = (e: any) => {
        const target = e.currentTarget;
        target.style.cursor = 'grab';
        const mode = MODE_DRAG.END;
        localStorage.setItem(KEY_STORAGE.CANCEL_DRAG, mode);
        cancelDrag();
        deleteDragEl(dragEl);
    }

    const onStorageUpdate = (e: any) => {
        const {key, newValue} = e;
        switch (key) {
            case KEY_STORAGE.CANCEL_DRAG: {
                if (newValue === MODE_DRAG.START) {
                    createDragEl();
                    activeDrag();
                } else if (newValue === MODE_DRAG.END) {
                    deleteDragEl(dragEl);
                    cancelDrag();
                }
                break;
            }
            case KEY_STORAGE.POSITION_DRAG: {
                const [x, y] = (newValue || '').split(KEY_SPLIT);
                const {screenX} = window;
                const screenY = window.outerHeight - window.innerHeight + window.screenY;
                updateDragEl(x - screenX, y - screenY);
                break;
            }
            default:
                return null;
        }
    }

    const activeDrag = () => {
        document.body.style.overflow = 'hidden';
        document.body.addEventListener('dragover', handleDragOver);
        document.body.addEventListener('drop', handleDrop);
    }

    const cancelDrag = () => {
        document.body.style.overflow = '';
        document.body.removeEventListener('dragover', handleDragOver);
        document.body.removeEventListener('drop', handleDrop);
    }

    const handleDrop = (e: any) => {
        deleteDragEl(dragEl);
        const params = onMouseDown(e, vessels)

        if (!params)
            return;

        const {startTime, vessel, contractId, availableTimeId} = params;
        if (!vessel || !startTime)
            return;

        const key = localStorage.getItem(KEY_STORAGE.CANCEL_OP_SELECTED) || '';
        const [id] = key.split('_');
        const cancel = canceled.find((item: any) => item.operation.id === id);

        if (!cancel)
            return;

        const requestType = [cancel.operation.operation_type];
        const limitOperationTypes = checkOperationType(vessel, requestType)

        if (limitOperationTypes.length === 0)
            return;

        renewOp({
            vessel,
            operation: {...cancel.operation, available_time_id: availableTimeId, contract_id: contractId},
            activity_log: updateTime(cancel.activity_log, 0, startTime)
        })
    }

    const handleDragOver = (e: any) => {
        const {pageX, pageY} = e;
        updateDragEl(pageX, pageY);
        onChangePosition(e);
        e.preventDefault();
    }

    const updateDragEl = (pageX: number, pageY: number) => {
        if (dragEl) {
            dragEl.style.cursor = 'grabbing';
            dragEl.style.display = 'block';
            dragEl.style.left = (pageX - 20) + 'px';
            dragEl.style.top = (pageY - 5) + 'px';
        }
    }

    const createDragEl = () => {
        const key = localStorage.getItem(KEY_STORAGE.CANCEL_OP_SELECTED) || '';
        if (key.length === 0)
            return;

        const [id, width] = key.split('_');
        const cancel = canceled.find((item: any) => item.operation.id === id);

        if (!cancel)
            return;

        dragEl = openPopup(<Operation
            key={cancel.operation.id}
            data={cancel}
            status={OP_STATUS.NEW}
        />)

        dragEl.style.position = 'absolute';
        dragEl.style.zIndex = '9999';
        dragEl.style.width = width;
        dragEl.style.display = 'none';
    }

    const renewOp = (data: IOperation) => {
        const {id, operation_type} = data.operation;
        if (operation_type !== OPERATION_TYPE.EVENT) {
            cancelLoading(true, id);

            dispatch(getActivityLog({
                source: [data],
                properties: {
                    [id]: {start: getNewStart(data.activity_log)}
                },
                success: (ops: any) => {
                    cancelLoading(false, id);
                    dispatch(planOpActions.renewOp(ops[id]))
                },
                failure: () => {
                    cancelLoading(false, id);
                }
            }))
        } else {
            dispatch(planOpActions.renewOp({
                ...data,
                activity_log: updateTime(data.activity_log, 0, getNewStart(data.activity_log))
            }))
        }
    }

    const cancelLoading = (loading: any, id: string) => {
        const el = getElById(id);

        if (el)
            el.dataset.loading = loading;
    }

    const onChangePosition = (e: any) => {
        const position = [e.screenX, e.screenY].join(KEY_SPLIT);
        localStorage.setItem(KEY_STORAGE.POSITION_DRAG, position);
    }


    return <div id={KEY_TAB.CANCEL} className={stylesContainer['wrapper-tab']}>
        <div className={stylesContainer['tab-body']}>
            <Cards<IOperation>
                data={result}
                loading={loading}
                child={{
                    height: 80,
                    View: ({data}) => <Operation
                        key={data.operation.id}
                        data={data}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        renewOp={renewOp}
                    />
                }}
            />
        </div>
    </div>;
};

export default Cancel;

