import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ILocation} from "../../../../util/varibles/interface";
import styles from "./style.module.scss";
import stylesContainer from "../../style.module.scss";
import ArrivalTimes from "./ArrivalTimes";
import {routeActions} from "../../../RoutePage/reducer";
import PriorityTime from "./PriorityTime";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => {
    return {
        locations: state.route.locations,
        loading: state.route.loading.routes
    }
};

interface IProps {
    locations: ILocation[]
    loading: boolean

    getLocations(): void
}

class SetArrivalTime extends Component<IProps> {

    constructor(props: IProps) {
        super(props);
        this.props.getLocations();
    }

    render() {
        const {locations, loading} = this.props
        const {sites = [], factories = []} = locations.reduce((rs: any, item: any) => {
            const {field = ''} = item;
            if (field === 'site') {
                rs.sites.push(item);
            } else if (field === 'factory')
                rs.factories.push(item);
            return rs;
        }, {sites: [], factories: []})

        return <>
            <div className={stylesContainer.header}>
                <div className={stylesContainer.title}>
                    Set arrival time at site / factory
                </div>
            </div>
            <div className={styles['wrapper-prioritize']}>
                <PriorityTime/>
            </div>
            <div className={styles['wrapper-arrival-time']}>
                <ArrivalTimes data={sites} loading={loading} type={'sitesTime'}/>
                <ArrivalTimes data={factories} loading={loading} type={'factoriesTime'}/>
            </div>
        </>;
    }
}

export default connect(mapStateToProps, {
    getLocations: routeActions.getLocationsRequest
})(SetArrivalTime);
