import React, {useState} from 'react';
import {Button, Modal} from "antd";
import styles from './style.module.scss';
import {WarningOutlined} from "@ant-design/icons";
import Header from '../../../../Popup/Component/Header';

interface IProps {
    isComplete: boolean

    onOk(): void

    onClose(): void
}

const PopupNextState: React.FC<IProps> = ({isComplete, onOk, onClose}) => {
    const [visible, setVisible] = useState(true);
    const handleClose = () => {
        setVisible(false);
        setTimeout(() => onClose(), 300);
    }

    const handleOk = () => {
        setVisible(false);
        setTimeout(() => onClose(), 300);
        onOk();
    }

    return <Modal title={null} footer={null} closable={false} keyboard open={visible} onCancel={handleClose}>
        <Header title={<span className='font-s2'>Warning</span>} onClose={handleClose}/>
        <div className={styles.body}>
            <WarningOutlined className={styles.icon}/>
            <span className='ml-2'>Do you want to {isComplete ? 'complete the operation' : 'move next step'}?</span>
        </div>
        <div className={styles.footer}>
            <Button className='bt-default' onClick={handleClose}>Cancel</Button>
            <Button className='bt-primary pl-4 pr-4' onClick={handleOk}>Ok</Button>
        </div>
    </Modal>;
};


export default PopupNextState;