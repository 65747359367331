import React, {useEffect, useState} from 'react';
import {Checkbox, Input, Modal, Select} from "antd";
import {OPERATION_TYPE, operationTypes, propsModal} from "../../../../util/varibles/constants";
import Header from "../../../../components/Popup/Component/Header";
import Content from "../Weather/Content";
import styles from './style.module.scss';
import {WEATHER_TYPE} from "../../util";
import {connect, useDispatch} from "react-redux";
import {AppState} from "../../../../util/store/store";
import {filterOption, renderOpts, showErrorResponse} from "../../../../util/varibles/global";
import Footer from "../../../../components/Popup/Component/Footer";
import {notify, NotifyCode} from "../../../../util/varibles/message";
import {createWeatherFetch} from "../../../../util/services/weather";
import {weatherActions} from "../../reducer";
import {propsSelect} from "../../../../util/varibles/template";
import {getCondition} from "../../constants";

const mapStateToProps = (state: AppState) => ({
    loadingLocations: state.weather.loadingLocations,
    locations: state.weather.locations
});

interface IProps {
    type: WEATHER_TYPE
    loadingLocations: boolean
    locations: any

    onClose(): void
}

const generateGroupId = {
    [WEATHER_TYPE.GLOBAL]: () => [WEATHER_TYPE.GLOBAL],
    [WEATHER_TYPE.OPERATION]: (value: any) => [[WEATHER_TYPE.OPERATION, value].join('#')],
    [WEATHER_TYPE.SITE]: (value: any) => value.map((item: any) => [WEATHER_TYPE.SITE, item].join('#')),
}

const PopupAdd: React.FC<IProps> = (props) => {
    const {type} = props;
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [form, setForm] = useState({
        name: '',
        is_enable: true,
        bad_condition: '',
        moderate_condition: '',
        good_condition: '',
    });
    const [selected, setSelected] = useState<null | OPERATION_TYPE | string[]>(type === WEATHER_TYPE.OPERATION ? null : []);
    const dispatch = useDispatch();
    const id = 'new'

    const handleClose = () => {
        setVisible(false);
        props.onClose();
    }

    useEffect(() => {
        if (type === WEATHER_TYPE.SITE) {
            setLocations(Object.keys(props.locations).reduce((rs: any, key) => {
                const {field} = props.locations[key];
                if (field === 'site')
                    return [...rs, props.locations[key]]
                return rs;
            }, []))
        }
    }, [type, props.locations]);

    const handleSave = () => {
        Array.isArray(selected)

        const badEl: any = document.getElementById(id + '-bad');
        const moderateEl: any = document.getElementById(id + '-moderate');
        const goodEl: any = document.getElementById(id + '-good');
        if (badEl && moderateEl && goodEl) {
            const el = [badEl, moderateEl, goodEl];
            let conditions = [], isInvalid = false;
            for (let i = 0; i < 3; i++) {
                const {condition, error} = getCondition(el[i].childNodes)
                if (error) {
                    isInvalid = true;
                    break;
                } else
                    conditions.push(condition);
            }
            if (isInvalid) return;
            const [bad_condition, moderate_condition, good_condition] = conditions
            const weather = {
                ...form,
                name: form.name.trim(),
                group_ids: generateGroupId[type](selected),
                type,
                description: '',
                bad_condition,
                moderate_condition,
                good_condition,
            }
            setLoading(true);
            new Promise(resolve => resolve(createWeatherFetch(weather)))
                .then((rs: any) => {
                    notify.success(NotifyCode.S1)([form.name]);
                    dispatch(weatherActions.add(rs))
                    handleClose();
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    showErrorResponse('Create  failed', error);
                })
        }

    }

    return <Modal
        {...propsModal}
        open={visible}
        title={<Header title='Create weather' onClose={handleClose}/>}
        onCancel={handleClose}
    >
        <div className={styles.body}>
            <div className={styles.lineInfo}>
                <label>Name</label>
                <Input
                    className='h-36'
                    value={form.name}
                    placeholder='Name'
                    onChange={({target}) => setForm({...form, name: (target.value || '')})}
                />
            </div>
            {type === WEATHER_TYPE.OPERATION && <div className={styles.lineInfo}>
                <label>Operation type</label>
                <Select
                    value={selected}
                    className='select-single'
                    placeholder='Operation type'
                    options={renderOpts(operationTypes)}
                    onChange={setSelected}
                />
            </div>}
            {type === WEATHER_TYPE.SITE && <div className={styles.lineInfo}>
                <label>Site</label>
                <Select
                    {...propsSelect}
                    value={selected}
                    loading={props.loadingLocations}
                    className='select-mode-tag'
                    mode='multiple'
                    placeholder='Site'
                    filterOption={filterOption}
                    options={renderOpts(locations)}
                    onChange={setSelected}
                />
            </div>}
            <Checkbox
                checked={form.is_enable}
                className='ml-16'
                onChange={({target}) => setForm({...form, is_enable: target.checked})}
            >
                Enable
            </Checkbox>
            <div className={styles.lineInfo}>
                <label>Conditions</label>
                <div className='pl-10'>
                    <Content
                        data={{...form, id}}
                        editing={true}
                    />
                </div>
            </div>
        </div>
        <Footer ok={{loading, click: handleSave}} cancel={{click: handleClose}}/>
    </Modal>;
};

export default connect(mapStateToProps, {})(PopupAdd);
