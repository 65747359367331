import React, {useCallback, useState} from 'react';
import {Modal} from "antd";
import {propsModal, ROUND_JOB} from "../../../../../../../../util/varibles/constants";
import Header from "../../../../../../../../components/Popup/Component/Header";
import Footer from "../../../../../../../../components/Popup/Component/Footer";
import InputDuration from "../../../../../../../../components/Basic/InputDuration";
import {IAfterProcesses} from "../../../../../../../../util/varibles/interface";
import styles from "../style.module.scss";

const bufferTimeMode: { [id: string]: (prev: any, value: number) => any } = {
    true: (current: IAfterProcesses[], value: number) => {
        return current.map((item: IAfterProcesses) => item.type === ROUND_JOB.BufferTime ? {
            ...item,
            duration: value
        } : item)
    },
    false: (current: IAfterProcesses[], value: number) => {
        current.push({type: ROUND_JOB.BufferTime, duration: value})
        return current
    },
}

interface IProps {
    duration?: number

    onSave(prev: (arr: IAfterProcesses[]) => IAfterProcesses[]): void

    onClose(): void
}

const PopupAddBufferTime: React.FC<IProps> = ({duration, onSave, onClose}) => {
    const [visible, setVisible] = useState(true);
    const [value, setValue] = useState(duration)

    const handleClose = useCallback(() => {
        setVisible(false);
        setTimeout(onClose, 300);
    }, [onClose]);

    const handleSave = () => {
        const mode = duration !== undefined;
        onSave(arr => bufferTimeMode[`${mode}`](arr, value || 0))
        handleClose();
    }

    return <Modal
        {...propsModal}
        open={visible}
        title={<Header title={duration ? 'Add buffer time' : 'Update buffer time'} onClose={handleClose}/>}
        onCancel={handleClose}
    >
        <InputDuration className={styles.inputCustom} value={duration || 0} onChange={setValue}/>
        <Footer
            ok={{click: handleSave}}
            cancel={{click: handleClose}}
        />
    </Modal>;
};

export default PopupAddBufferTime;