import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './style.module.scss'
import stylesContainer from '../../style.module.scss';
import Operation from "./Operation";
import Options from "./Options";
import {SyncOutlined} from "@ant-design/icons";
import {harvestOptions} from "../../utils";
import {initCustomizeHarvest} from "../../../PlanOperationPage/Plan/Calendar/constants";
import {loginActions} from "../../../LoginPage/reducer";
import {getSetting} from "../../../../util/varibles/defaultSetting";
import {PreferenceSetting} from "../../../../util/varibles/userSetting";
import {CERTAIN_TYPE, KeyOfStep, OP_STATUS} from "../../../../util/varibles/constants";
import {AppState} from "../../../../util/store/store";

const activity_log = [
    {est_start_time: 1614294000000},
    {est_start_time: 1614297600000},
    {est_start_time: 1614301200000},
    {est_start_time: 1614304800000},
    {est_start_time: 1614308400000},
    {est_start_time: 1614312000000, key: KeyOfStep.ARRIVE_FACTORY},
    {est_start_time: 1614315600000},
    {est_start_time: 1614319200000},
    {est_start_time: 1614322800000},
    {est_start_time: 1614326400000},
]

const operation = {
    operation_code: '123456',
    status: OP_STATUS.WAITING,
    site_name: 'Site Name',
    factory_name: 'Factory Name',
    certain_mode: CERTAIN_TYPE.NONE,
    sub_operations: [
        {unit_id: 'Unit 1', fish_amount: 10000, avg_weight: 4000, mortality_risk: true, total_weight: 40000000},
        {unit_id: 'Unit 2', fish_amount: 10000, avg_weight: 3000, mortality_risk: false, total_weight: 30000000},
        {unit_id: 'Unit 3', fish_amount: 10000, avg_weight: 5000, mortality_risk: true, total_weight: 50000000}
    ]
}
const vessel = {
    capacity: 400000000
}
const key = PreferenceSetting.CUSTOMIZE_HARVEST;

const mapStateToProps = (state: AppState) => {
    const customizeHarvest = getSetting(state.login.user.setting, key);
    return {customizeHarvest}
};

interface IProps {
    customizeHarvest: any[]

    updateUserSetting(payload: any[]): void
}

interface IState {
    options: any[]
}

class CustomizeHarvest extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        let {customizeHarvest = []} = this.props;
        if (customizeHarvest.length === 0)
            customizeHarvest = initCustomizeHarvest;

        this.state = {
            options: harvestOptions.map(item => ({...item, isCheck: customizeHarvest.indexOf(item.id) !== -1}))
        }
    }

    handleClick = (id: string) => {
        const {options} = this.state;
        const newOptions = options.map(item => item.id === id ? {...item, isCheck: !item.isCheck} : item);
        this.setState({options: newOptions});

        this.props.updateUserSetting([{
            [key]: newOptions.reduce((list: string[], item: any) => item.isCheck ? [...list, item.id] : list, [])
        }]);
    }

    handleCheckAll = (value: boolean) => {
        const {options} = this.state;
        const newOptions = options.map(item => ({...item, isCheck: value}));
        this.setState({options: newOptions});

        this.props.updateUserSetting([{
            [key]: value ? newOptions.map((item: any) => item.id) : []
        }]);
    }

    render() {
        const {options} = this.state;
        return <>
            <div className={stylesContainer.header}>
                <div>
                    <div className={stylesContainer.title}>
                        Customize harvest operation
                    </div>
                    <div className={[stylesContainer['description'], 'mt-12'].join(' ')}>
                        Check on each element in the list below to select which one will be shown in Operation box.
                    </div>
                </div>
                <div className={styles['bt-reset']}>
                    <SyncOutlined/>
                    <label>Reset</label>
                </div>
            </div>
            <div className={styles['wrapper-operation']}>
                <Operation {...{operation, activity_log, vessel}}/>
            </div>
            <div className={styles['wrapper-options']}>
                <Options {...{options, handleClick: this.handleClick, handleCheckAll: this.handleCheckAll}}/>
            </div>
        </>;
    }
}

export default connect(mapStateToProps, {
    updateUserSetting: loginActions.updateUserSetting
})(CustomizeHarvest);
