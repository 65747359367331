import React, {Component} from 'react';
import styles from './style.module.scss';

class FormEmpty extends Component {
    render() {
        return <div className={styles['empty']}>
            <p>This checklist is currently empty.</p>
            <p>Click or drag a component from the left banner here to create new one.</p>
        </div>;
    }
}

export default FormEmpty;
