import React, {Component} from 'react';
import OperationComponent from "./OperationComponent";
import {IPropsSearchItem, SEARCH_TYPE} from "./constants";
import VesselComponent from "./VesselComponent";
import SiteComponent from "./SiteComponent";
import FactoryComponent from "./FactoryComponent";

interface IProps extends IPropsSearchItem {

}

class SearchItemComponent extends Component<IProps> {

    render() {
        const {field} = this.props.data;
        switch (field) {
            case SEARCH_TYPE.OPERATION:
                return <OperationComponent {...this.props}/>
            case SEARCH_TYPE.VESSEL:
                return <VesselComponent {...this.props}/>
            case SEARCH_TYPE.SITE:
                return <SiteComponent{...this.props}/>
            case SEARCH_TYPE.FACTORY:
                return <FactoryComponent  {...this.props}/>
            default:
                return null;
        }
    }
}

export default SearchItemComponent;
