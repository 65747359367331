import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Divider, InputNumber, Modal} from "antd";
import {propsModal} from "../../../../util/varibles/constants";
import Header from "../../../../components/Popup/Component/Header";
import Checkbox from "antd/lib/checkbox";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import Footer from "../../../../components/Popup/Component/Footer";
import {showErrorResponse, uppercaseFirstLetter} from "../../../../util/varibles/global";
import FilterOpType from "./FilterOpType";
import LoadingBox from "../../../../components/LoadingBox";
import {VesselService} from "../../../../util/services/vessel";

interface IProps {
    onAdd(data: any[]): void

    onClose(): void
}

const PopupAddEquipments: React.FC<IProps> = ({onAdd, onClose}) => {
    const [{equipments, loading}, setEquipments] = useState<{ loading: boolean, equipments: any[] }>({
        loading: false,
        equipments: []
    })
    const [visible, setVisible] = useState(true);
    const [selected, setSelected] = useState([]);
    const [value, setValue] = useState<{ [id: string]: number }>({});
    const [filter, setFilter] = useState<Set<any>>(new Set());

    useEffect(() => {
        setEquipments(prev => ({...prev, loading: true}));
        VesselService.equipments()
            .then(rs => setEquipments({loading: false, equipments: rs}))
            .catch(error => {
                showErrorResponse('Get equipments failed', error);
                setEquipments({loading: false, equipments: []});
            })
    }, []);

    const handleClose = useCallback(() => {
        setVisible(false);
        onClose();
    }, [onClose])

    const handleAdd = useCallback(() => {
        const result: any[] = [];
        selected.forEach(key => {
            const {id, name, types, group_type} = JSON.parse(key);
            const quantity = value[id] || 1;
            for (let i = 0; i < quantity; i++) {
                result.push({
                    name: uppercaseFirstLetter(name),
                    types,
                    group_id: id,
                    group_name: [uppercaseFirstLetter(name), 'equipment'].join(' '),
                    group_type,
                })
            }
        })
        onAdd(result);
        handleClose();
    }, [selected, value, onAdd, handleClose]);

    const optionEquipments = useMemo(() => equipments.filter(item => filter.size === 0 || item.types.some((type: any) => filter.has(type))), [equipments, filter]);

    return <Modal
        {...propsModal}
        zIndex={1011}
        width={800}
        open={visible}
        title={<Header title="Add equipment" onClose={handleClose}/>}
        onCancel={handleClose}
    >
        <div className='text-[15px] mt-3 mb-2 flex items-center gap-2 h-[32px]'>
            <span>Equipment type</span>
            <FilterOpType
                value={filter}
                opts={useMemo(() => Array.from(new Set(equipments.reduce((rs: string[], item) => {
                    return [...rs, ...item.types]
                }, []))), [equipments])}
                onChange={setFilter}
            />
        </div>
        <Checkbox.Group className='checkbox-fill min-h-[75px] w-full' value={selected} onChange={setSelected}>
            {optionEquipments.map((item: any) => <Checkbox className='mb-2' key={item.id} value={JSON.stringify(item)}>
                {uppercaseFirstLetter(item.name)} equipment
            </Checkbox>)}
            {optionEquipments.length === 0 && <div className='flex justify-center w-full'>
                <LoadingBox loading={loading} className='h-[70px]'/>
            </div>}
        </Checkbox.Group>
        <div className='text-[15px] mb-2 mt-5'>Selected types</div>
        <table>
            <tbody>
            {selected.map((key: any) => {
                const {id, name} = JSON.parse(key);
                return <tr key={id}>
                    <td className='h-[42px] whitespace-nowrap leading-[42px] pl-5'>{uppercaseFirstLetter(name)} equipment</td>
                    <td className='h-[42px] flex items-center gap-x-1 pr-5'>
                        <Divider className='h-[28px] mx-3 mb-[-5px]' type='vertical'/>
                        <span>Quantity:</span>
                        <InputNumber
                            value={value[id] || 1}
                            onChange={value => setValue(prev => ({...prev, [id]: value}))}
                        />
                        <Button className='bt-ghost' data-icon='true' data-danger={true}>
                            <Icon icon={ICON_NAME.DELETE}/>
                        </Button>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
        <Footer
            ok={{
                text: 'Add',
                disabled: selected.length === 0,
                title: selected.length === 0 ? 'Please choose equipment' : '',
                click: handleAdd
            }}
            cancel={{click: handleClose}}
        />
    </Modal>;
};

export default PopupAddEquipments;