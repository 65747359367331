import React, {useCallback, useState} from 'react';
import {Checkbox, Modal, Tooltip} from "antd";
import styles from "./style.module.scss";
import {propsModal, SITE_TYPE} from "../../../../../../util/varibles/constants";
import {ISite, IUnit} from '../../../../../../util/varibles/interface';
import Footer from "../../../../../../components/Popup/Component/Footer";
import Header from "../../../../../../components/Popup/Component/Header";
import {UnitModel} from "../../../../BiologyBrowser/constants";

interface IProps {
    sites: ISite[]
    except: { id: string, unit_id: string }[]
    isShow: boolean
    isEmpty?: boolean // allow empty

    onAdd(units: IUnit[]): void

    onClose?(): void
}

const PopupUnit: React.FC<IProps> = (props) => {
    const {sites, except, isShow, isEmpty, onAdd, onClose} = props;
    const [visible, setVisible] = useState(isShow);
    const [selected, setSelected] = useState([]);
    const exceptIds = new Set(except.map((item: any) => item.id));

    const handleAdd = () => {
        const ids = new Set<string>(selected);
        onAdd(sites.reduce((list: IUnit[], site) => [
            ...list,
            ...site.units.reduce((subList: any, unit: any) => {
                const key = unit.id;
                if (ids.has(key)) {
                    ids.delete(key);
                    return [...subList, UnitModel(unit, site)]
                }
                return subList
            }, [])
        ], []));
        handleClose()
    }

    const handleClose = useCallback(() => {
        setVisible(false);
        if (onClose)
            setTimeout(onClose, 300);
    }, [onClose]);

    return <Modal
        {...propsModal}
        title={<Header title='Add unit' onClose={handleClose}/>}
        open={visible}
        mask={false}
        onCancel={handleClose}
    >
        <Checkbox.Group
            className={[styles.checkbox, 'checkbox-fill'].join(' ')}
            value={selected}
            onChange={setSelected}
        >
            {sites.reduce((list: any, item) => {
                const {units, type} = item;
                return [...list, ...units.reduce((rs: any, unit) => {
                    if (!exceptIds.has(unit.id)) {
                        const disabled = type === SITE_TYPE.INTERNAL && unit.fish_amount === 0 && !isEmpty;
                        return [...rs, <Checkbox
                            key={unit.id}
                            disabled={disabled}
                            value={`${unit.id}`}
                        >
                            {disabled ? <Tooltip title='No fish'>
                                {unit.unit_id}
                            </Tooltip> : unit.unit_id}
                        </Checkbox>]
                    }
                    return rs
                }, [])]
            }, [])}
        </Checkbox.Group>
        <Footer
            ok={{
                click: handleAdd,
                disabled: selected.length === 0,
                text: 'Add'
            }}
            cancel={{
                click: handleClose
            }}
        />
    </Modal>;
};

export default PopupUnit;
