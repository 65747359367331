import React, {Component} from 'react';
import stylesContainer from "../../style.module.scss";
import styles from "./style.module.scss";
import {Storage} from "aws-amplify";
import {PaperClipOutlined} from "@ant-design/icons";
import {downloadBlob} from "../../../../../util/varibles/global";

interface IProps {
    files: any
}

class AttachFile extends Component<IProps> {
    handleOpenFile = (file: any) => {
        const {isNew, name} = file;
        if (isNew) {
            const element = document.createElement("a");
            element.href = URL.createObjectURL(file.file);
            element.download = name;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            element.remove();
        } else {
            const {key} = file;
            Storage.get(`operations/${key}`, {download: true})
                .then((blob: any) => downloadBlob(blob.Body, name))
        }
    }

    render() {
        const {files} = this.props;
        if (files.length === 0)
            return null;
        return <div className={[stylesContainer['container-box'], 'mb-10'].join(' ')}>
            <PaperClipOutlined/>
            <div>Attached files:</div>
            <div className='ml-5'>
                {files.map((item: any) =>
                    <div key={item.key} className={styles['file']} onClick={() => this.handleOpenFile(item)}>
                        {item.name}
                    </div>)}
            </div>
        </div>;
    }
}

export default AttachFile;