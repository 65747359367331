import {all, call, put, takeEvery} from "redux-saga/effects";
import {locationActions} from "./reducer";
import {PoiService} from "../../services/poi";
import {ILocation} from "../../varibles/interface";
import {showErrorResponse} from "../../varibles/global";


function* getLocationsWatcher(): Generator<any, any, ILocation[]> {
    try {
        const locations = yield call(PoiService.gets);
        yield put(locationActions.getPoisSuccess(locations));
    } catch (error) {
        yield put(locationActions.getPoisFailure())
        showErrorResponse('Get locations failure', error);
    }
}


export default function* locationSaga() {
    yield all([
        takeEvery(locationActions.getPois.type, getLocationsWatcher),
    ])
}
