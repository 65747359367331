import {datetime} from "../../util/library/datetime";
import {chartUtil, colors} from "../../util/library/chart-util";


const {initLine, addUnit, drawArrow, generateArrowIcon} = chartUtil;

enum XAxisId {
    WindSpeed = 'Wind speed',
    WindGust = 'Wind gust',
    WaveHeight = 'Wave height',
    WavePeriod = 'Wave period',
    AirTemperature = 'Air temperature',
}

const xAxis: { [id: string]: { name: string, yAxisIndex: number } } = {
    [XAxisId.WindSpeed]: {name: 'Wind speed', yAxisIndex: 0},
    [XAxisId.WindGust]: {name: 'Wind gust', yAxisIndex: 0},
    [XAxisId.WaveHeight]: {name: 'Wave height', yAxisIndex: 1},
    [XAxisId.WavePeriod]: {name: 'Wave period', yAxisIndex: 2},
    [XAxisId.AirTemperature]: {name: 'Air temperature', yAxisIndex: 3},
}

const yAxisBase = [
    {
        name: 'm/s',
        position: 'left',
        color: '#2f7ed8',
    },
    {
        name: 'm',
        color: '#8bbc21',
    },
    {
        name: 's',
        color: '#f28f43',
    },
    {
        name: '°C',
        color: '#c42525',
    }
]

export const createSeries = (props: any): { series: any, yAxis: Set<number> } => {
    const series: any = [], yAxis: number[] = [];
    if (Object.keys(props || {}).length === 0)
        return {series, yAxis: new Set(yAxis)};

    const {
        wind_speed = [],
        wind_direction = [],
        gust = [],
        waves_height = [],
        waves_direction = [],
        waves_period = [],
        temp = []
    } = props;

    if (wind_speed.length > 0 && wind_direction.length > 0) {
        const icon = generateArrowIcon(colors[0]);
        const data = wind_speed.map((item: any, i: number) => [datetime(item.time).UTCText, Math.round(item.value * 100) / 100, wind_direction[i].value]);
        const {yAxisIndex} = xAxis[XAxisId.WindSpeed]
        const {color, name: yAxisName} = yAxisBase[yAxisIndex] || {}
        series.push({
            ...initLine,
            ...xAxis[XAxisId.WindSpeed],
            data,
            itemStyle: {
                color
            },
            ...addUnit(yAxisName),
            markPoint: {
                data: data.map((item: any, i: number) => ({
                    coord: item,
                    ...drawArrow(icon, wind_direction[i].value)
                }))
            },

        })
        yAxis.push(0);
    }

    if (gust.length > 0) {
        const {yAxisIndex} = xAxis[XAxisId.WindGust]
        const {color, name: yAxisName} = yAxisBase[yAxisIndex] || {}
        series.push({
            ...initLine,
            ...xAxis[XAxisId.WindGust],
            data: gust.map((item: any) => [datetime(item.time).UTCText, Math.round(item.value * 100) / 100]),
            lineStyle: {
                type: 'dashed',
            },
            itemStyle: {
                color
            },
            ...addUnit(yAxisName),
            symbol: 'triangle',
        })
        yAxis.push(0);
    }

    if (waves_height.length > 0 && waves_direction.length > 0) {
        const icon = generateArrowIcon(colors[1])
        const data = waves_height.map((item: any) => [datetime(item.time).UTCText, Math.round(item.value * 100) / 100]);
        const {yAxisIndex} = xAxis[XAxisId.WaveHeight]
        const {color, name: yAxisName} = yAxisBase[yAxisIndex] || {}
        series.push({
            ...initLine,
            ...xAxis[XAxisId.WaveHeight],
            data: waves_height.map((item: any) => [datetime(item.time).UTCText, Math.round(item.value * 100) / 100]),
            itemStyle: {color},
            ...addUnit(yAxisName),
            markPoint: {
                data: data.map((item: any, i: number) => ({
                    coord: item,
                    ...drawArrow(icon, waves_direction[i].value)
                }))
            },
            symbol: 'diamond',
        })
        yAxis.push(1);
    }

    if (waves_period.length > 0) {
        const {yAxisIndex} = xAxis[XAxisId.WavePeriod]
        const {color, name: yAxisName} = yAxisBase[yAxisIndex] || {}
        series.push({
            ...initLine,
            ...xAxis[XAxisId.WavePeriod],
            data: waves_period.map((item: any) => [datetime(item.time).UTCText, Math.round(item.value * 100) / 100]),
            itemStyle: {color},
            ...addUnit(yAxisName),
            symbol: 'rect',
        })
        yAxis.push(2);
    }

    if (temp.length > 0) {
        const {yAxisIndex} = xAxis[XAxisId.AirTemperature]
        const {color, name: yAxisName} = yAxisBase[yAxisIndex] || {}
        series.push({
            ...initLine,
            ...xAxis[XAxisId.AirTemperature],
            data: temp.map((item: any) => [datetime(item.time).UTCText, Math.round((item.value - 273.15) * 100) / 100]),
            itemStyle: {color},
            ...addUnit(yAxisName),
            symbol: 'circle',
        })
        yAxis.push(3);
    }

    return {series, yAxis: new Set(yAxis)};
}

const nameStyle: { [id: string]: { padding: any, align: any } } = {
    left: {

        padding: [0, 6, 0, 0],
        align: 'right',
    },
    right: {
        padding: [0, 0, 0, 8],
        align: 'left'
    }
}

export const createYAxis = (filter: any = {}, yAxis: Set<number> = new Set<number>()) => {
    const isShow = new Set(Object.keys(filter || {}).reduce((rs: any, key: any) => {
        if (!filter[key])
            return rs;

        const {yAxisIndex} = xAxis[key] || {};
        if (typeof yAxisIndex === 'number')
            return [...rs, yAxisIndex]
        return rs;
    }, []))


    let right = 0;
    return yAxisBase.reduce((rs: any, item, index) => {
        const {position = 'right', color, ...args} = item;
        const offset = right * 35 + 5;
        const show = (isShow.has(index) || isShow.size === 0) && (yAxis.has(index) || yAxis.size === 0);
        if (position === 'right' && show)
            right++;

        return [...rs, {
            ...args,
            show,
            position,
            nameGap: 20,
            nameTextStyle: nameStyle[position] || {},
            offset,
            type: 'value',
            alignTicks: true,
            axisLine: {
                show: false,
                lineStyle: {
                    color
                }
            },
            ...chartUtil.convertNumberAxisLabel()
        }]
    }, [])
}