import React from 'react';
import styles from "./style.module.scss";

interface IProps {
    data: any
}

const Poi: React.FC<IProps> = ({data}) => {
    const {name} = data;
    return <div className={styles['container-info']}>
        <div className={[styles.header, styles['location-name']].join(' ')}>
            <svg height="22" width="20">
                <polygon points="10 1,19 5.5,19 16.5, 10 21,1 16.5,1 5.5"
                         stroke="#F6F5F4"
                         fill="#F69C6F" strokeWidth="2" />
            </svg>
            <span>{name}</span>
        </div>
    </div>;
};

export default Poi;