import React, {useMemo} from 'react';
import {IEquipment, IVessel} from "../../../../util/varibles/interface";
import {groupEquipments} from "../../../VesselManagementPage/PopupEditVessel/Component/Equipments";
import {treatmentName} from "../../../VesselManagementPage/PopupEditVessel/constants";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {useSelector} from "react-redux";
import {selectPermission} from "../../../../util/store/selectors";
import {ACTION} from "../../../../util/varibles/permission";
import Density from "../Density";
import {VESSEL_TYPE} from "../../../../util/varibles/constants";


const Equipment: React.FC<{ data: IEquipment }> = ({data}) => {
    return <div className='flex flex-col items-center justify-center px-2'>
        <div className='flex flex-col justify-center items-center mb-2'>
            <div className='text-[15px] leading-[18px]'>{data.name}</div>
            <div>{data.value} t/h</div>
        </div>
        <Icon icon={ICON_NAME.EQUIPMENT} size={90}/>
    </div>
}

interface IProps {
    vessel: IVessel
}

const Equipments: React.FC<IProps> = ({vessel}) => {
    const {equipments = [], density_map = {}} = vessel;

    const permissions = useSelector(selectPermission);
    const group = useMemo(() => groupEquipments(equipments), [equipments]);

    return <div>
        <div className='text-[15px] font-[600] mt-4 mb-3'>In-pipe treatment capabilities</div>
        <div className='flex flex-col gap-4'>
            {Object.keys(group).map(key => {
                return <div key={key}>
                    <div className='flex justify-between mb-2'>
                        <div className='fill-bg-second'>{treatmentName(key)}</div>
                        {(permissions[ACTION.VESSEL.UPDATE] && vessel.type === VESSEL_TYPE.WELL_BOAT) &&
                            <Density id={key} vessel={vessel} value={density_map}/>}
                    </div>
                    <div className='flex flex-wrap gap-[8px]'>
                        {group[key].map(({index, ...item}) => <Equipment
                            key={index}
                            data={item}
                        />)}
                    </div>
                </div>
            })}
        </div>
    </div>;
};

export default Equipments;
