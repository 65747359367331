import React from 'react';
import {Dropdown, MenuProps} from "antd";
import styles from './style.module.scss';
import {propContainer} from "../../util/varibles/constants";
import {ItemType} from "antd/es/menu/interface";
import {DropdownProps} from "antd/es/dropdown/dropdown";

interface IProps extends Partial<DropdownProps> {
    items: ItemType[] | any[] | MenuProps['items']
    visible?: boolean
    disabled?: boolean
    children?: React.ReactElement

    container?(): React.ReactElement | Element | any
}

const Menu: React.FC<IProps> = ({
                                    container,
                                    visible,
                                    disabled,
                                    trigger = ['click'],
                                    overlayClassName,
                                    items,
                                    children,
                                    ...args
                                }) => {
    const attr: any = {};
    if (container)
        attr.getPopupContainer = container;

    if (!visible !== undefined)
        attr.open = visible;

    return <Dropdown
        {...args}
        {...propContainer}
        {...attr}
        disabled={disabled || false}
        overlayClassName={overlayClassName ? [overlayClassName, styles.dropdown].join(' ') : styles.dropdown}
        trigger={trigger}
        menu={{items}}
    >
        {children}
    </Dropdown>;
};

export default Menu;
