import React, {ReactNode} from 'react';
import styles from "../style.module.scss";
import {IAfterProcesses} from "../../../../../../../../util/varibles/interface";
import {ROUND_JOB} from "../../../../../../../../util/varibles/constants";
import BufferTime from "./BufferTime";
import GetWater from "./GetWater";
import Traveling from "./Traveling";
import Cleaning from "./Cleaning";

export interface IPropsChild extends IAfterProcesses {
    onUpdate: (data: any) => void
    onDelete: () => void
}

const views: { [id: string]: (props: IPropsChild) => ReactNode } = {
    [ROUND_JOB.Traveling]: (props: IPropsChild) => <Traveling {...props}/>,
    [ROUND_JOB.GetWater]: (props: IPropsChild) => <GetWater {...props}/>,
    [ROUND_JOB.Cleaning]: (props: IPropsChild) => <Cleaning {...props}/>,
}

interface IProps {
    bufferTime?: IAfterProcesses
    roundJobs: ({ index: number } & IAfterProcesses)[]
    onUpdateAfterRound: (prev: (arr: IAfterProcesses[]) => IAfterProcesses[]) => void
}

const RoundJob: React.FC<IProps> = React.memo((props) => {
    const {
        bufferTime,
        roundJobs,
        onUpdateAfterRound
    } = props;

    const handleUpdate = (data: ({ index: number } & IAfterProcesses), value: any) => {
        onUpdateAfterRound(arr => {
            const {index, ...args} = data;
            arr[index] = {...args, ...value}
            return arr;
        })
    }

    const handleDelete = (index: number) => {
        onUpdateAfterRound(arr => {
            arr.splice(index, 1);
            return arr;
        })
    }

    return <div className='flex flex-col relative w-full min-h-[36px]'>
        <BufferTime
            data={bufferTime}
            onSave={onUpdateAfterRound}
        />
        {roundJobs.map((item, i) => {
            const {type} = item;
            const view = views[type];
            return view ? <div key={i} className={styles.roundJob}>
                {view({
                    ...item,
                    onUpdate: (newValue: any) => handleUpdate(item, newValue),
                    onDelete: () => handleDelete(item.index)
                })}
            </div> : null
        })}
    </div>
}, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));

export default RoundJob;
