import React from 'react';
import styles from './style.module.scss';
import Disease from "./Disease";

interface IProps {
    diseases: any
}

const Diseases: React.FC<IProps> = ({diseases}) => {

    return <div className={styles.wrapper}>
        {Object.keys(diseases).map(key => <Disease key={key} data={diseases[key]}/>)}
    </div>;
};

export default Diseases;
