export const serverTypes = ['OPC-UA', 'Modbus-TCP']
export const tagTypes = ['direct', 'virtual', 'fixed']
export const functionTypes = ['linear_interpolation', 'avg_two_values', 'modbus_byteswap']

export enum FORM_TYPES {
    SERVER = 'server',
    OBJECT = 'object',
    META_DATA = 'meta_data',
    TAG = 'tag',
    TRANSFORMATION = 'information',
    LINK = 'link'
}

export interface ITarget {
    type: FORM_TYPES,
    data: any,
    index: number
}

export interface IServer {
    [index: string]: any;
}

export interface IObject {
    [index: string]: any;
}

export interface ConfigurationState {
    devices: any[],
    iotName: string,
    device: any,
    isLoading: boolean,
    isChange: boolean,
    target?: ITarget | undefined
}

export const initialState: ConfigurationState = {
    devices: [],
    iotName: '',
    device: {},
    isLoading: false,
    isChange: false
}

export const DELETE_SERVER = 'DELETE_SERVER';
export const SELECT_TARGET_CONFIGURATION = 'SELECT_TARGET_CONFIGURATION';
export const GET_DEVICES_IOT_REQUEST = 'GET_DEVICES_IOT_REQUEST';
export const CHANGE_DEVICE = 'CHANGE_DEVICE';
export const SEND_DEVICE_REQUEST = 'SEND_DEVICE_REQUEST';

// servers: [
//     {
//         "id": 1,
//         "name": "server_1",
//         "type": "OPC-UA",
//         "url": "opc.tcp://10.87.59.199",
//         "port": 4840
//     },
//     {
//         "id": 2,
//         "name": "server_2",
//         "type": "Modbus-TCP",
//         "url": "opc.tcp://10.87.59.199",
//         "port": 502,
//         "update_interval": 1000
//     }
// ],
//     objects: [
//     {
//         "id": "1",
//         "type": "TankComponent",
//         "name": "1.1P",
//         "tags": [
//             {
//                 "id": 1,
//                 "type": "direct",
//                 "name": "temperature_top",
//                 "server_id": 1,
//                 "link": {
//                     "ns": 2,
//                     "s": 14
//                 }
//             },
//             {
//                 "id": 2,
//                 "type": "direct",
//                 "name": "temperature_bottom",
//                 "server_id": 1,
//                 "link": {
//                     "ns": 2,
//                     "s": 15
//                 }
//             },
//             {
//                 "id": 3,
//                 "type": "direct",
//                 "name": "level_mm",
//                 "server_id": 1,
//                 "link": {
//                     "ns": 2,
//                     "s": 16
//                 }
//             },
//             {
//                 "id": 4,
//                 "type": "fixed",
//                 "name": "level_min",
//                 "value": 0
//             },
//             {
//                 "id": 5,
//                 "type": "fixed",
//                 "name": "level_max",
//                 "value": 5000
//             },
//             {
//                 "id": 6,
//                 "type": "virtual",
//                 "name": "level",
//                 "transformation": {
//                     "function": "linear_interpolation",
//                     "table": [
//                         {
//                             "x": 0,
//                             "y": 0
//                         },
//                         {
//                             "x": 5000,
//                             "y": 100
//                         }
//                     ],
//                     "input_1": {
//                         "object_id": 1,
//                         "tag_id": 3
//                     }
//                 }
//             },
//             {
//                 "id": 7,
//                 "type": "virtual",
//                 "name": "temperature_avg",
//                 "transformation": {
//                     "function": "avg_two_values",
//                     "input_1": {
//                         "object_id": 1,
//                         "tag_id": 1
//                     },
//                     "input_2": {
//                         "object_id": 1,
//                         "tag_id": 2
//                     }
//                 }
//             }
//         ]
//     },
//     {
//         "id": "2",
//         "type": "TankComponent",
//         "name": "1.2P",
//         "tags": [
//             {
//                 "id": 1,
//                 "type": "direct",
//                 "name": "temperature_top",
//                 "server_id": 2,
//                 "link": {
//                     "address": 0,
//                     "length": 2,
//                     "transformation": {
//                         "function": "modbus_byteswap"
//                     }
//                 }
//             }
//         ]
//     }
// ]

