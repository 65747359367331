import React, {useCallback, useEffect, useState} from 'react';
import styles from './style.module.scss';
import {CALENDAR_ID} from "../../constants";
import {addClassName, clearSelect, removeClassName} from "../../../../util/varibles/global";

let pageX = 0, scrollLeft = 0;

interface IProps {
}

const Day: React.FC<IProps> = () => {
    const [dragging, setDragging] = useState(false);

    const handleMouseMove = useCallback((e: any) => {
        const el = document.getElementById(CALENDAR_ID);
        if (el)
            el.scrollLeft = scrollLeft - e.pageX + pageX;
    }, []);

    const handleMouseUp = useCallback(() => {
        setDragging(false);
        removeClassName(document.body);
        clearSelect();
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove])

    useEffect(() => {
        return handleMouseUp;
    }, [handleMouseUp]);

    const handleMouseDown = useCallback((e: any) => {
        if (e.button !== 0)
            return;

        pageX = e.pageX;
        const el = document.getElementById(CALENDAR_ID);
        if (el)
            scrollLeft = el.scrollLeft;
        setDragging(true);
        addClassName(document.body);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove, handleMouseUp]);


    return <div
        className={styles.day}
        data-dragging={dragging}
        onMouseDown={handleMouseDown}
    />
};

export default Day;