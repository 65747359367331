import {all, call, put, takeEvery} from "redux-saga/effects";
import {getTaskTypesFetch} from "../../util/services/taskType";
import {taskTypeActions} from "./reducer";

function* getTaskTypesWatcher(): Generator {
    try {
        const taskTypes = yield call(getTaskTypesFetch);
        yield put(taskTypeActions.getListSuccess(taskTypes))
    } catch (error) {
        yield put(taskTypeActions.getListFailure())
    }
}


export default function* taskTypeSaga() {
    yield all([
        takeEvery(taskTypeActions.getListRequest.type, getTaskTypesWatcher),
    ])
}
