import React, {SetStateAction} from 'react';
import {Checkbox} from "antd";
import styles from "../style.module.scss";
import {treatmentTypes} from "../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import Menu from "../../../../components/Menu";

interface IProps {
    value: string

    onChange(value: SetStateAction<string>): void
}

const TreatmentSelect: React.FC<IProps> = ({value, onChange}) => {

    return <Menu placement='bottomRight' items={[{
        key: 'option',
        className: 'no-hover w-[200px]',
        disabled: true,
        label: <Checkbox.Group
            className={styles.treatmentOpt}
            value={value.split(',')}
            options={treatmentTypes.map(item => {
                return {
                    label: item.name,
                    value: `${item.id}`,
                }
            })}
            onChange={selected => onChange(selected.sort((a, b) => +a - +b).join(','))}
        />
    }]}>
        <div className='flex gap-1 items-center text-[15px] w-[220px] cursor-pointer leading-[32px]'>
            <span className='whitespace-nowrap'>Select the combined treatments</span>
            <Icon icon={ICON_NAME.ARROW_DOWN} size={13} bg='colorMain'/>
        </div>
    </Menu>;
};

export default TreatmentSelect;