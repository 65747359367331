import React, {useEffect, useState} from 'react';
import {convertNumberTimeToStringTime, convertStringTimeToNumberTime} from "../../../util/varibles/global";

interface IProps {
    value: number
    className?: string
    placeholder?: string
    disabled?: boolean

    onError?(empty?: boolean): void

    onChange(value: number): void

    onBlur?(): void
}

const InputDuration: React.FC<IProps> = ({
                                             value,
                                             disabled,
                                             className,
                                             placeholder = 'In duration (4d 6h 45m)',
                                             onError,
                                             onChange,
                                             onBlur
                                         }) => {
    const [text, setText] = useState(convertNumberTimeToStringTime(value));

    useEffect(() => {
        if (value === undefined)
            setText('')
    }, [value])

    const handleBlur = (e: any) => {
        const durationTxt = e.target.value.replace(/ {2,}/g, ' ').toLowerCase();
        const duration = convertStringTimeToNumberTime(durationTxt.trim());
        if (duration === undefined && onError)
            onError(!(durationTxt.length > 0));
        if (onBlur)
            onBlur();
    };

    const handleChange = (e: any) => {
        const durationTxt = e.target.value.replace(/ {2,}/g, ' ').toLowerCase();
        setText(e.target.value);
        const duration = convertStringTimeToNumberTime(durationTxt.trim());
        if (duration !== undefined)
            onChange(duration);
    };

    return <input
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        value={text || ''}
        onChange={handleChange}
        onBlur={handleBlur}
    />;
};

export default InputDuration;
