import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {
    getChatsFetch,
    getChatsOperationFetch,
    getMessagesFetch,
    seenMessageFetch,
    sendMessageFetch
} from "../../util/services/chat";
import WSService from "../../web-socket/web-socket-service";
import {showErrorResponse} from "../../util/varibles/global";
import {messageActions} from "./reducer";
import {PayloadAction} from "@reduxjs/toolkit";
import {selectUsername} from "../../util/store/selectors";

function* getChatsWatcher(args: PayloadAction<{ index: number }>): Generator<any, any, any> {
    try {
        const {index} = args.payload;
        const data = yield call(getChatsFetch, {index});
        yield put(messageActions.getChatSuccess(data))
    } catch (error) {
        yield put(messageActions.getChatFailure())
    }
}

function* getChatsOperationWatcher({...args}): Generator<any, any, any> {
    try {
        const {index} = args.payload;
        const data = yield call(getChatsOperationFetch, {index});
        yield put(messageActions.getChatOfOpSuccess(data))
    } catch (error) {
        yield put(messageActions.getChatOfOpFailure())
    }
}

function* sendMessageWatcher(args: PayloadAction<any>): Generator<any, any, any> {
    const params = args.payload;
    try {
        const data = yield call(sendMessageFetch, params);
        yield put(messageActions.sendMessageSuccess({params, data}));
    } catch (error) {
        yield put(messageActions.sendMessageFailure(params))
    }
}

function* getMessagesWatcher(args: PayloadAction<any>): Generator<any, any, any[]> {
    const params = args.payload;
    try {
        const data = yield call(getMessagesFetch, params);
        yield put(messageActions.getMessagesSuccess({data, chatId: params.id}))
    } catch (error) {
        yield put(messageActions.getMessagesFailure(params.id))
        showErrorResponse('Create chat failure', error);
    }
}

function* readMessageRequest(args: PayloadAction<any>): Generator<any, any, string> {
    try {
        const username = yield select(selectUsername);
        const data = args.payload;
        if (!data.message)
            return;
        const {id, chat_id} = data.message;
        yield call(seenMessageFetch, {
            id,
            chat_id,
            username,
            timestamp: Date.now(),
            connection_id: WSService.getConnectId()
        });
    } catch (error) {
        console.log('read message error: ', error);
    }
}

export default function* messageSaga() {
    yield all([
        takeEvery(messageActions.getChatRequest.type, getChatsWatcher),
        takeEvery(messageActions.getChatOfOpRequest.type, getChatsOperationWatcher),
        takeEvery(messageActions.sendMessageRequest.type, sendMessageWatcher),
        takeEvery(messageActions.getMessagesRequest.type, getMessagesWatcher),
        takeEvery(messageActions.readMessageRequest.type, readMessageRequest),
    ])
}

