import React, {useCallback} from 'react';
import {IChangeLog, IVessel} from "../../../../../util/varibles/interface";
import styles from './style.module.scss';
import Log from "./Log";

interface IProps {
    data: IChangeLog[]
    vessels: IVessel[]
    prev?: boolean

    loadMore(): void
}

const ChangeLogs: React.FC<IProps> = ({data, vessels, prev = false, loadMore}) => {

    const handleWheel = useCallback((e: any) => {
        if (prev) {
            const {deltaY} = e;
            const {scrollTop, scrollHeight, offsetHeight} = e.target;
            const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;
            if (isScrolledToBottom && deltaY > 0) {
                loadMore()
            }
        }
    }, [prev, loadMore])

    return <div className={styles.changeLogs} onWheel={handleWheel}>
        {data.map(item => <Log key={item.timestamp} data={item} vessels={vessels}/>)}
    </div>;
};

export default ChangeLogs;
