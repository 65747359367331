import React from 'react';
import {Select} from "antd";

interface IProps {
    value: boolean

    onChange(value: boolean): void
}

const {Option} = Select

const MortalityRisk: React.FC<IProps> = ({value, onChange}) => {
    return <Select value={value} variant={"borderless"} onChange={onChange}>
        {[[true, 'High'], [false, 'Low']].map(([value, label]: any) =>
            <Option key={value} value={value}>{label}</Option>)}
    </Select>;
};

export default MortalityRisk;
