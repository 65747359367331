import React from 'react';
import styles from './style.module.scss';
import {operationType} from "../../../util/varibles/constants";
import {getStepNameOfChecklist} from "../../../util/varibles/global";
import {openPopup} from "../../../components/Popup/Component/WrapperPopup";
import PopupChecklist from "../_Component/PopupChecklist";
import {IChecklist} from "../../../util/varibles/interface";

interface IProps {
    data: IChecklist
}

const Checklist: React.FC<IProps> = (props) => {

    const {data} = props;
    const {operation_type} = data;
    const {name: opTypeName = ''} = operationType[operation_type] || {};
    const checklistName = getStepNameOfChecklist(data);
    const openDetail = () => {
        const el = openPopup(<PopupChecklist checklist={data} onClose={() => el.remove()}/>)
    }

    return <div {...{className: styles.checklist, onClick: openDetail}}>
        {[opTypeName, checklistName].join(' - ')}
    </div>;
};

export default Checklist;
