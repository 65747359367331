import React, {Component} from 'react';
import styles from "../style.module.scss";
import {SHORT_DATETIME_FORMAT, treatmentType} from "../../../../../util/varibles/constants";
import {Divider} from "antd";
import {getStatusOfOp, uppercaseFirstLetter} from "../../../../../util/varibles/global";
import {IPropsSearchItem} from "../constants";
import {SwapRightOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import {datetime} from "../../../../../util/library/datetime";

interface IProps extends IPropsSearchItem {
}

class Treatment extends Component<IProps> {
    render() {
        const {
            operation_code,
            site_name,
            sub_operations,
            vessel_name,
            current_process,
            tenant_id,
            est_start_time,
            est_finish_time,
            id,
            processes
        } = this.props.data;
        const {treatment_types} = sub_operations[0];
        const unitIds = sub_operations.map((item: any) => item.unit_id).join(', ');
        const statusTxt = getStatusOfOp({
            current_process,
            operation: this.props.data,
            activity_log: processes,
        });

        return <div className={styles['item-result']}>
            <NavLink to={`/operation/${tenant_id}/${id}`}>
                <div className={styles['line-info-item']}>
                    <div className={styles['icon-treatment']}/>
                    <span className='ml-2 mr-3'>{'#' + operation_code}</span>
                    <span>{site_name + ' - ' + unitIds}</span>
                </div>
                <div className={styles['line-info-item']}>
                    <span>{vessel_name}</span>
                    <Divider className={styles['divider-line']} type="vertical"/>
                    <div className={styles['operation-type']}>
                        {treatment_types.reduce((list: any, type: any) => {
                            const {name} = treatmentType[type];
                            if (name)
                                list = [...list, uppercaseFirstLetter(name)];
                            return list
                        }, []).join(', ')}
                    </div>
                    <div className={[styles['operation-status'], 'ml-2'].join(' ')}>{statusTxt}</div>
                </div>
                <div className={styles['line-info-item']}>
                    {datetime(est_start_time).format(SHORT_DATETIME_FORMAT)}
                    <SwapRightOutlined className='ml-2 mr-2 font-s3'/>
                    {datetime(est_finish_time).format(SHORT_DATETIME_FORMAT)}
                </div>
            </NavLink>
        </div>
    }
}

export default Treatment;
