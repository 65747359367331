import React, {Component} from 'react';
import {connect} from 'react-redux';
import {MessageState, tabMessage} from "../../constants";
import stylesContainer from "../../style.module.scss";
import Message from "../../../../components/Chat/MessageMini/Component/Message";
import {Spin} from "antd";
import styles from "./style.module.scss";
import {LoginState} from "../../../LoginPage/constants";
import MessageBox from "../MessageBox";
import {NavLink} from "react-router-dom";
import {sendMessageFetch} from "../../../../util/services/chat";
import {getSubInfoChatOp} from "../../../../components/Chat/constants";
import {checkLoadMore, onOpenPage, showErrorResponse} from "../../../../util/varibles/global";
import {messageActions} from "../../reducer";
import {CHAT_TYPE, operationType} from "../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {AppState} from "../../../../util/store/store";

const mapStateToProps = (state: AppState) => ({
    login: state.login,
    message: state.message
})

interface IProps {
    login: LoginState
    message: MessageState
    chatId: string

    clearId(): void

    addChatOfOp(payload: any): void

    getMessages(payload: any): void

    sendMessage(payload: any): void

    getChat(payload: any): void
}

class Operation extends Component<IProps> {
    state = {
        isNew: false,
        isShowNew: true,
        selected: null,
        values: [],
    }

    rootRef: any = React.createRef();

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        const {
            chat: dataNew,
            operationsMore,
            loadingOperation
        } = this.props.message;
        const {chat: dataOld} = prevProps.message;

        if (operationsMore && !loadingOperation && JSON.stringify(dataNew) !== JSON.stringify(dataOld) && this.rootRef.current) {
            checkLoadMore(this.rootRef.current, () => {
                const {operationsIndex} = this.props.message;
                this.props.getChat({index: operationsIndex + 1})
            })
        }
    }

    showMessages = (isSelected: boolean, data: any) => {
        if (isSelected)
            return;

        if (this.props.chatId.length > 0)
            this.props.clearId();

        const {messages} = this.props.message
        const {id} = data.group_chat;
        if (messages[id]) {
            this.setState({isNew: false, selected: id})
        } else {
            this.setState({isNew: false, selected: id})
            this.props.getMessages({id, index: 1});
        }
    }

    handleSend = (params: any, target: any) => {
        const {isNew} = this.state;
        if (isNew) {
            const {id} = this.state.values[0];
            this.setState({loading: true});
            new Promise((resolve) => {
                resolve(sendMessageFetch({
                    ...params,
                    type: CHAT_TYPE.OPERATION,
                    group_id: id
                }))
            })
                .then((chat: any) => {
                    const {messages} = this.props.message;
                    if (!messages[id])
                        this.props.getMessages({id, index: 1})

                    this.props.addChatOfOp(chat);
                    this.setState({selected: chat.group_chat.id, isNew: false, isShowNew: false, loading: false})
                })
                .catch(async error => {
                    this.setState({loading: false})
                    showErrorResponse('Create chat failed', error);
                })
        } else {
            const {group_chat = {id: ''}} = target || {}
            const {id = ''} = group_chat || {};
            this.props.sendMessage({...params, type: CHAT_TYPE.OPERATION, group_id: id})
        }
    }

    handleWheel = (e: any) => {
        const {operationsMore, loadingOperation, operationsIndex} = this.props.message;
        if (!operationsMore || loadingOperation)
            return;

        const {deltaY} = e;
        const {scrollTop, scrollHeight, offsetHeight}: any = this.rootRef.current;
        const isScrolledToBottom = (scrollHeight - offsetHeight - (scrollTop + deltaY)) < 0;

        if (isScrolledToBottom && deltaY > 0) {
            this.props.getChat({index: operationsIndex + 1})
        }
    }

    newChat = () => {
        if (!this.state.isNew)
            this.setState({isNew: true, values: [], isShowNew: true})
    }

    render() {
        const {operations, loadingOperation} = this.props.message;
        const {chatId} = this.props;
        const {isNew, isShowNew, values, loading, selected}: any = this.state;

        let name: any = '', target: any;

        if (!isNew) {
            const id = chatId || selected;
            if (id) {
                target = operations.find((item: any) => item.group_chat.id === id);
            }

            if (target) {
                const {id, operation_type, name: operation_code, tenant_id} = target.group_chat;
                const {icon} = operationType[operation_type] || {};
                const link = `/operation/${tenant_id}/${id}`;
                name = <div className={styles['message-box-title']}>
                    <div className={styles['message-box-logo']}>
                        <Icon icon={icon}/>
                    </div>
                    <NavLink className={styles['op-code']} onMouseDown={(e: any) => onOpenPage(e, link)} to={link}>
                        #{operation_code}
                    </NavLink>
                    {getSubInfoChatOp(target.group_chat)}
                </div>;
            }
        }

        return <>
            <div className={stylesContainer.leftControl}>
                <div className={stylesContainer.title}>
                    <div data-lev='name'>
                        {tabMessage[CHAT_TYPE.OPERATION].name}
                    </div>
                    <div data-lev='action'>
                        <div className='bt-ghost' data-icon={true} onClick={this.newChat}>
                            <Icon icon={ICON_NAME.ADD_2}/>
                        </div>
                    </div>
                </div>

                <div ref={this.rootRef} className={stylesContainer['container-content']} onWheel={this.handleWheel}>
                    {isShowNew && <div className={styles['new-chat']} data-visible={this.state.isNew}>
                        <div/>
                        <span>New chat</span>
                    </div>}

                    <div className={stylesContainer.content}>
                        {operations.map((item: any) => {
                            const {group_chat = {}} = item || {}
                            const {id, operation_type, name} = group_chat
                            const isSelected = !isNew && target && id === target.group_chat.id;
                            const {icon} = operationType[operation_type] || {};
                            return <Message
                                title={<div className={styles['message-title']}>
                                    <div>#{name}</div>
                                    {getSubInfoChatOp(group_chat)}
                                </div>}
                                avatar={<div className={styles.avatar}>
                                    <Icon icon={icon}/>
                                </div>}
                                key={id}
                                data={item}
                                isSelected={isSelected}
                                onClick={() => this.showMessages(isSelected, item)}
                            />
                        })}
                    </div>
                    {loadingOperation && <div className={stylesContainer.loading} data-empty={operations.length === 0}>
                        <Spin/>
                    </div>}
                </div>
            </div>
            <MessageBox
                isNew={isNew}
                values={values}
                name={name}
                loading={loading}
                chatType={CHAT_TYPE.OPERATION}
                conversation={isNew ? {
                    group_id: values[0] ? values[0].id : null,
                    group_chat: {id: values[0] ? values[0].id : null}
                } : target}
                onSend={(params: any) => this.handleSend(params, target)}
                onChangeSelect={values => this.setState({values})}
            />
        </>;
    }
}

export default connect(mapStateToProps, {
    getMessages: messageActions.getMessagesRequest,
    sendMessage: messageActions.sendMessageRequest,
    getChat: messageActions.getChatOfOpRequest,
    addChatOfOp: messageActions.addChatOfOp,
})(Operation);