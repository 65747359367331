import {
    IAvailableTime,
    IFactory,
    ILevelByVessel,
    IOperation,
    IOperations,
    IUnit
} from "../../../util/varibles/interface";
import {
    AVAILABLE_TIME_TYPE,
    CONTRACT_TYPE,
    KeyOfStep,
    OPERATION_MODE,
    OPERATION_TYPE,
    operationType
} from "../../../util/varibles/constants";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {compareFactoryTime} from ".";
import {getTimeByOpMode} from "./function_operation/constants";
import {checkZero} from "../../../util/varibles/global";

export enum VALIDATION_STATUS {
    VALID,
    WARN,
    ERROR,
}

export const valid = {status: VALIDATION_STATUS.VALID, message: ""};

export enum VALIDATION {
    TYPE = 'type',
    CAPACITY = 'capacity',
    CONTRACT = 'contract',
    AVAILABLE_TIME = 'available-time',
    TIME = 'time',
    PAST = 'past',
    FACTORY = 'factory',
    OWN = 'own',
    ROUTE = 'route',
}

export interface IResultValidation {
    status: VALIDATION_STATUS,
    message: string
}

interface IPlanValidation {
    [index: string]: (data: any, args: any) => IResultValidation
}

export const planValidation: IPlanValidation = {
    [VALIDATION.TYPE]: (data: IOperation) => {
        const {operation_type: opTypeVessel, name} = data.vessel
        const {operation_type} = data.operation;

        if (opTypeVessel.indexOf(operation_type) === -1) {
            const {name: typeName = ''} = operationType[operation_type] || {};
            return {
                status: VALIDATION_STATUS.ERROR,
                message: `${name} does not support ${typeName}`
            };
        }
        return valid;
    },
    [VALIDATION.CAPACITY]: (data: IOperation) => {
        const {support} = data.operation;

        if (support)
            return valid;

        const {capacity} = data.vessel
        const {sub_operations} = data.operation;
        const totalWeight = sub_operations.reduce((total: number, item: IUnit) => {
            total += item.total_weight;
            return total
        }, 0);
        if (capacity < totalWeight)
            return {
                status: VALIDATION_STATUS.ERROR,
                message: "status, total weight of fish is larger than vessel capacity"
            };
        return valid;
    },
    [VALIDATION.CONTRACT]: (data: IOperation, args: { message?: string, opMode: OPERATION_MODE }) => {
        const {contracts, available_times, available_time_type, isOwn, permission} = data.vessel;
        const {available_time_id = '-', contract_id = '-', tenant_id} = data.operation;
        const {activity_log} = data;
        const start = getTimeByOpMode[args.opMode](activity_log[0])
        const finish = getTimeByOpMode[args.opMode](activity_log[activity_log.length - 1]);

        if (available_time_type === AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME) {
            if (!isOwn && !permission) {
                if (available_time_id !== '-') {
                    const availableTime = available_times.find((item: any) => item.id === available_time_id);
                    if (!availableTime || availableTime.start_time > start || availableTime.finish_time < finish)
                        return {status: VALIDATION_STATUS.ERROR, message: notify[NotifyCode.E6]()}
                }
                if (contract_id !== '-') {
                    const contract = contracts.find(item  => item.id === contract_id);
                    if (contract && contract.type === CONTRACT_TYPE.FIXED && (contract.start_time > start || contract.finish_time < finish))
                        return {status: VALIDATION_STATUS.ERROR, message: notify[NotifyCode.E7]()}
                }
                return valid;
            } else
                return planValidation[VALIDATION.OWN](data, args)
        } else {
            const isError = contracts.some(item => item.type === CONTRACT_TYPE.FIXED
                && item.tenant_id !== tenant_id
                && start <= item.finish_time
                && item.start_time <= finish)
            if (isError)
                return {status: VALIDATION_STATUS.ERROR, message: notify[NotifyCode.E6]()}
            return valid;
        }
    },
    [VALIDATION.TIME]: (data: IOperation, args: { operations: IOperations, level: ILevelByVessel, opMode: OPERATION_MODE }) => {
        if (data.operation.parallel)
            return valid;

        const {operations, level, opMode} = args;
        const start = getTimeByOpMode[opMode](data.activity_log[0]);
        const finish = getTimeByOpMode[opMode](data.activity_log[data.activity_log.length - 1]);
        const targetVesselId = data.vessel.id;
        const operationId = data.operation.id || '';
        const isError = Object.keys(level[targetVesselId] || {}).some((key: string) => {
            const {activity_log} = operations[key];
            const startOp = getTimeByOpMode[opMode](activity_log[0]);
            const finishOp = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
            return key.toString() !== operationId.toString()
                && !operations[key].operation.parallel
                && start <= finishOp
                && startOp <= finish
        });

        if (isError)
            return {status: VALIDATION_STATUS.ERROR, message: notify[NotifyCode.E5]()};

        return valid;
    },
    [VALIDATION.PAST]: (data: IOperation) => {
        const start = data.activity_log[0].est_start_time;

        if (start < new Date().getTime())
            return {status: VALIDATION_STATUS.WARN, message: notify[NotifyCode.W4]()};
        return valid;
    },
    [VALIDATION.FACTORY]: (data: IOperation, args: { factories: IFactory[], opMode: OPERATION_MODE }) => {
        const {factories, opMode} = args;
        const {activity_log} = data;
        const {factory_id} = data.operation;
        const factory = factories.find((item: any) => item.id === factory_id)
        const workTime = factory ? factory.work_time : 0;
        const step = activity_log.find((item: any) => item.key === KeyOfStep.ARRIVE_FACTORY) || {est_start_time: 0};
        const factoryTime = getTimeByOpMode[opMode](step);
        const hour = Math.floor(workTime);
        const min = (workTime - hour) * 60;
        const isValidFactoryTime = compareFactoryTime(factoryTime, workTime);
        if (isValidFactoryTime === -1)
            return {
                status: VALIDATION_STATUS.WARN,
                message: notify[NotifyCode.W2]([checkZero(hour), checkZero(min)])
            };
        return valid;
    },
    [VALIDATION.OWN]: (data: IOperation, args: { message?: string, opMode: OPERATION_MODE }) => {
        const {message = notify[NotifyCode.E6](), opMode} = args;
        const start = getTimeByOpMode[opMode](data.activity_log[0]);
        const finish = getTimeByOpMode[opMode](data.activity_log[data.activity_log.length - 1]);
        const {tenant_id} = data.operation

        const {contracts, available_times} = data.vessel;
        let isError = contracts.some(item => item.type === CONTRACT_TYPE.FIXED
            && item.tenant_id !== tenant_id
            && start <= item.finish_time
            && item.start_time <= finish)
        if (isError)
            return {status: VALIDATION_STATUS.ERROR, message};

        isError = available_times.some((item: any) => start <= item.finish_time && item.start_time <= finish)
        if (isError)
            return {status: VALIDATION_STATUS.ERROR, message};

        return valid;
    },
    [VALIDATION.AVAILABLE_TIME]: (data: IAvailableTime, args: { operations: IOperations,level: ILevelByVessel, opMode: OPERATION_MODE, userTenantId: string }) => {
        const {id, start_time, finish_time, vessel_id} = data;
        const {operations, level, opMode, userTenantId} = args;
        let isInside = true;
        let isValidTime = Object.keys(level[vessel_id] || {}).some((key: string) => {
            const item = operations[key];
            if (item) {
                const {available_time_id = '-', tenant_id} = operations[key].operation;
                const {activity_log} = operations[key];
                const startOp = getTimeByOpMode[opMode](activity_log[0]);
                const finishOp = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
                isInside = available_time_id === id;
                if (isInside) {
                    return !(start_time <= startOp && finishOp <= finish_time);
                } else {
                    const isOwn = tenant_id === userTenantId;
                    if (isOwn) {
                        return start_time <= finishOp && startOp <= finish_time
                    } else {
                        return !(start_time <= startOp && finishOp <= finish_time)
                            && start_time <= finishOp && startOp <= finish_time;
                    }
                }
            } else
                return false
        });
        if (isValidTime)
            return {
                status: VALIDATION_STATUS.ERROR,
                message: isInside ? notify[NotifyCode.E6]() : notify[NotifyCode.E5]()
            };

        return valid
    },
    [VALIDATION.ROUTE]: (data: any, routeIds: string[]) => {
        const {source, destination} = data;

        return new Set(routeIds).has([source.id, destination.id].join('_')) ? valid : {
            status: VALIDATION_STATUS.ERROR,
            message: notify[NotifyCode.E20]([source.name, destination.name])
        }
    }
}

export const opValidation: { [index: string]: VALIDATION[] } = {
    [OPERATION_TYPE.HARVEST]: [
        // VALIDATION.TYPE,
        // VALIDATION.CAPACITY,
        VALIDATION.CONTRACT,
        VALIDATION.TIME,
        VALIDATION.PAST,
        VALIDATION.FACTORY
    ],
    [OPERATION_TYPE.TREATMENT]: [
        // VALIDATION.TYPE,
        VALIDATION.CONTRACT,
        VALIDATION.TIME,
        VALIDATION.PAST,
    ],
    [OPERATION_TYPE.TRANSPORT]: [
        // VALIDATION.TYPE,
        VALIDATION.CONTRACT,
        VALIDATION.TIME,
        VALIDATION.PAST,
    ],
    [OPERATION_TYPE.EVENT]: [
        // VALIDATION.TYPE,
        VALIDATION.CONTRACT,
        VALIDATION.TIME,
        VALIDATION.PAST,
    ],
    [OPERATION_TYPE.SERVICE]: [
        // VALIDATION.TYPE,
        VALIDATION.CONTRACT,
        VALIDATION.TIME,
        VALIDATION.PAST,
    ],
}
