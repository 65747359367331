import React, {useEffect, useState} from 'react';
import styles from "../style.module.scss";
import {IUnit, TOperation} from "../../../../../util/varibles/interface";
import {datetime} from "../../../../../util/library/datetime";
import Legends from "./Legends";
import {addLiceThreshold, KeyChart, propertiesOfChart, UnitOfChart} from "../constants";
import Year from "./Year";
import LoadingBox from "../../../../../components/LoadingBox";
import {ISiteExtend, SiteService} from "../../../../../util/services/site";
import {cloneObj, showErrorResponse, uppercaseFirstLetter} from "../../../../../util/varibles/global";
import {Divider} from "antd";
import {notify, NotifyCode} from "../../../../../util/varibles/message";
import Operations from "../Operations";
import {getTimeByOpMode} from "../../../util/function_operation/constants";
import {operationType} from "../../../../../util/varibles/constants";
import {ReactComponent as harvestIcon} from "../../../../../assets/icon/harvest.svg"
import {ReactComponent as treatmentIcon} from "../../../../../assets/icon/add_2.svg"
import {ReactComponent as transportIcon} from "../../../../../assets/icon/fish_1.svg"
import {ReactComponent as eventIcon} from "../../../../../assets/icon/event_operation.svg"
import {ReactComponent as serviceIcon} from "../../../../../assets/icon/service_operation.svg"
import {ICON_NAME} from "../../../../../components/Icon";
import {renderToString} from "react-dom/server";
import {selectPlan} from "../../../../../util/store/selectors";
import {useSelector} from "react-redux";
import Charts from "./Charts";

const initChart = {yLine: {}, yArea: {}, line: [], area: [], count: 0}

const img = {
    [ICON_NAME.HARVEST]: harvestIcon,
    [ICON_NAME.TREATMENT]: treatmentIcon,
    [ICON_NAME.TRANSPORT]: transportIcon,
    [ICON_NAME.EVENT]: eventIcon,
    [ICON_NAME.SERVICE]: serviceIcon
}

const convertData = ([from, to]: number[], type: any) => {
    const result = [];
    const max = from === to ? from + 1 : to
    for (let i = from; i <= max; i++) {
        result.push([i - 1, type]);
    }
    return result;
}

interface IProps {
    unit?: IUnit
    site: {
        id: string
        name: string
    }
    year: number

    changeUnit(unit?: IUnit): void
}

const UnitChart: React.FC<IProps> = ({unit, site, year: initYear, changeUnit}) => {
    const [{loading, source}, setSource] = useState<{ loading: boolean, source: any }>({loading: false, source: {}});
    const [remove, setRemove] = useState<KeyChart[]>([]);
    const opMode = useSelector(selectPlan.opMode);
    const [year, setYear] = useState(`${initYear}`);
    const [chart, setChart] = useState<typeof initChart>(initChart);

    const [{loadingSite, siteOps}, setSiteOps] = useState<{
        loadingSite: boolean,
        siteOps?: ISiteExtend
    }>({loadingSite: false});
    const [{opsVisible, opsChart, rowMax}, setOpsVisible] = useState<{
        opsVisible: TOperation[],
        opsChart: any,
        rowMax: number,
    }>({opsVisible: [], opsChart: [], rowMax: 0});

    useEffect(() => {
        const operations = siteOps?.operations || [];
        let ops;
        if (unit) {
            const {id} = unit;
            ops = operations.reduce((rs: TOperation[], item) => {
                const index = item.sub_operations.findIndex(sub => sub.id === id);
                if (index >= 0) {
                    const sub_operations = cloneObj(item.sub_operations);
                    sub_operations[index].selected = true;
                    rs.push({...item, sub_operations});
                }

                return rs
            }, [])
        } else {
            ops = operations;
        }
        let yCount: { [id: string]: number } = {};
        const color = document.body.classList.contains('theme-dark') ? '#D2D6DA' : '#2D3939';
        const count: { [week: string]: number } = {};
        const stateOps = {
            opsVisible: ops,
            opsChart: ops.map(item => {
                const {processes = [], operation_type} = item;
                const {icon} = operationType[operation_type];
                const time = getTimeByOpMode[opMode](processes[0]);
                const x = datetime(time).week - 1;
                const y = yCount[x] || 0;
                yCount[x] = y + 1;
                const {[icon]: Symbol}: any = img;
                const svgEl = new DOMParser().parseFromString(renderToString(<Symbol/>), 'application/xml')
                const pathEL = svgEl.getElementsByTagName('path')[0];
                pathEL.setAttribute('style', `fill: ${color}`);
                const s = new XMLSerializer().serializeToString(svgEl);
                const path = window.btoa(s);
                pathEL.setAttribute('style', `fill:#2D3939`);
                const week = datetime(getTimeByOpMode[opMode](processes[0])).week;
                count[week] = (count[week] || 0) + 1;

                return {
                    name: `#${item.operation_code}`,
                    type: 'scatter',
                    data: [[x, y, item]],
                    symbolSize: 15,
                    symbolKeepAspect: true,
                    symbol: 'image://data:image/svg+xml;base64,' + path,
                    itemStyle: {color},
                    tooltip: {
                        formatter: function (params: any) {
                            const data = params.value[2] as TOperation;
                            const result = [
                                `W${x + 1}`,
                                `<div class='font-bold flex items-center gap-1 text-[15px]'>
                                    ${new XMLSerializer().serializeToString(svgEl)}
                                    <span class="colorMain">#${data.operation_code}</span> 
                                </div>`
                            ]
                            const starvingDates = data.sub_operations.filter(item => item.starving_days);
                            if (starvingDates.length > 0) {
                                result.push([
                                    `<div class="colorMain">`,
                                    `<span class="text-comment font-bold text-[13px]">Starving Days: </span>`,
                                    `<span class="text-[15px]">`,
                                    starvingDates.map(item => `${item.unit_id} - ${item.starving_days}`).join(', '),
                                    `</span></div>`
                                ].join(' '))

                            }
                            return result.join(' ')
                        },
                    }
                }
            }),
        }
        setOpsVisible({...stateOps, rowMax: Math.max(...Object.keys(count).map(key => count[key]))});
    }, [unit, siteOps?.operations, opMode]);

    useEffect(() => {
        setSiteOps({loadingSite: true})
        SiteService.detail({id: site.id, year})
            .then(rs => {
                setSiteOps({loadingSite: false, siteOps: rs as ISiteExtend})
            })
            .catch(err => {
                setSiteOps({loadingSite: false});
                showErrorResponse(notify[NotifyCode.E39]([site.name]), err)
            })
    }, [site, year]);

    useEffect(() => {
        const id = unit?.id || site.id;
        setSource(prevSource => {
            if (Object.keys(prevSource.source).length > 0) {
                setChart(prev => ({...prev, line: [], area: []}));
            }
            return {
                loading: true, source: {}
            }
        });
        SiteService.getUnitChart({id, year})
            .then(rs => {
                setSource({loading: false, source: rs})
            })
            .catch(error => {
                setSource({loading: false, source: {}})
                showErrorResponse('Retrieving chart data failed', error)
            });
    }, [unit, site, year]);

    useEffect(() => {
        if (Object.keys(source).length > 0) {
            const yLine: { [id: string]: string } = {[UnitOfChart.Lice]: '0'};
            const yArea: { [id: string]: string } = {};
            const {count, line, area} = source;
            const ids = new Set(remove);
            const chart = {
                line: Object.keys(line).reduce((rs: any, key) => {
                    if (ids.has(key as KeyChart)) {
                        return rs;
                    }
                    const {name, unit, color, lineColor, zlevel} = propertiesOfChart[key] || {};
                    yLine[unit || ''] = `${yLine[unit || ''] || Object.keys(yLine).length}`;
                    const {[key]: statistics}: any = line;
                    return [...rs, {
                        name: name ? name : uppercaseFirstLetter(key.replace(/_/g, ' ')),
                        type: 'line',
                        data: unit ? statistics.map((item: number) => item ? (Math.round(item * 1000) / 1000) : item) : [],
                        yAxisIndex: yLine[unit || ''],
                        symbol: !lineColor ? 'emptyCircle' : 'none',
                        itemStyle: {
                            color: color,
                        },
                        lineStyle: {
                            color: lineColor || color
                        },
                        zlevel,
                        tooltip: {
                            valueFormatter: (value = '-') => value === '-' ? value : [value, unit].join(' ')
                        }
                    }];
                }, []),
                area: Object.keys(area).reduce((rs: any, key) => {
                    if (ids.has(key as KeyChart)) {
                        return rs;
                    }
                    const {
                        name = key,
                        color,
                        lineColor
                    } = propertiesOfChart[key] || propertiesOfChart[KeyChart.DiseaseLocal];

                    const start = datetime().set({year, week: 1}).startOf('week').time;
                    const finish = datetime().set({year}).endOf('year').time;
                    const {type = 'DiseaseLocal'} = propertiesOfChart[key] || {}
                    yArea[type] = `${yArea[type] || Object.keys(yArea).length}`;
                    const {[key]: statistics}: any = area;
                    statistics.forEach((item: any) => {
                        const {from, to} = item;
                        rs.push({
                            name,
                            type: 'line',
                            symbol: 'none',
                            data: convertData([datetime(Math.max(from, start)).week, datetime(Math.min(to || Date.now(), finish)).week], Number(yArea[type])),
                            yAxisIndex: 0,
                            itemStyle: {color},
                            lineStyle: {
                                width: 10,
                                color: lineColor || color
                            },
                            tooltip: {
                                valueFormatter: () => `${datetime(from).format('MMM DD, YYYY')} - ${to ? datetime(to).format('MMM DD, YYYY') : 'Now'}`
                            },
                        });
                    })
                    return rs
                }, [])
            }
            chart.line.push(addLiceThreshold(count, yLine));
            setChart({yLine, yArea, count, ...chart})
        }
    }, [source, remove, year]);

    return <>
        <div className="flex items-center justify-between">
            <div className={[styles.lineName, 'mb-0'].join(' ')}>
                {unit ? <>
                    <span className='link' onClick={() => changeUnit()}>Back to site</span>
                    <Divider type="vertical"/>
                    <span>{unit.unit_id}’s chart & operations</span>
                </> : <span>
                    Sites chart & operations
                </span>}
            </div>
            <Year disabled={loading || loadingSite} year={year} onChange={setYear}/>
        </div>
        <div className='relative'>
            <Charts className={styles.chart} data={chart} ops={opsChart} rowMax={rowMax}/>
            <Legends data={source} remove={remove} onChange={setRemove}/>
            {loading && <LoadingBox loading={loading} level='bg'/>}
        </div>
        <Operations loading={loadingSite} ops={opsVisible} changeUnit={changeUnit}/>
    </>;
};

export default UnitChart;
