import React, {Component} from 'react';
import {IPropsSearchItem} from "./constants";

interface IProps extends IPropsSearchItem {

}

class FactoryComponent extends Component<IProps> {
    render() {
        return <div>

        </div>;
    }
}

export default FactoryComponent;
