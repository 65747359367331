import React, {createRef, useEffect} from 'react';
import styles from "./style.module.scss";
import {
    checkLimit,
    cloneObj,
    convertNumberTimeToStringTime,
    uppercaseFirstLetter
} from "../../../../../../../util/varibles/global";
import stylesContainer from "../style.module.scss";
import Icon, {ICON_NAME} from "../../../../../../../components/Icon";
import Menu from "../../../../../../../components/Menu";
import DragEl from '../../../_Component/DragEl';
import {openPopup} from "../../../../../../../components/Popup/Component/WrapperPopup";
import {IPropsChildren, ITaskService} from "../../../../../../../util/varibles/interface";
import LineMiddle2 from "../../../_Component/LineMiddle2";
import {callEventEditOp, IUnitWhenEdit, setEditOp} from "../../../../../../../contexts/EditOperationContext";

const disableSelect = (event: any) => {
    event.preventDefault();
}

interface IProps extends IPropsChildren {
    unit: IUnitWhenEdit
    data: ITaskService;
    isError: boolean
    isAllowDrag?: boolean
    isEmptyTasks?: boolean

    onDelete?(): void
}

let dragEl: HTMLDivElement | any, parentEl: HTMLDivElement | any, levelMode = 'all';
const WrapperTask: React.FC<IProps> = (props) => {
    const {data, isError, isAllowDrag, isEmptyTasks = false} = props;
    const {elementId, id: unitId, type, group_id} = props.data;
    const rootRef = createRef<HTMLDivElement>();
    const set = setEditOp();
    const {turnOnCalculate} =callEventEditOp();

    const propsWrapper: any = {
        id: elementId,
        className: [styles['wrapper-task'], stylesContainer['wrapper-task']].join(' '),
        'data-is-empty': isEmptyTasks,
        'data-error': isError,
        'data-allow-drag': isAllowDrag
    }

    useEffect(() => {
        parentEl = document.getElementById('service-operation')
        return () => {
            document.body.style.cursor = 'unset';
            window.removeEventListener('selectstart', disableSelect);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, []);

    const handleMouseDown = () => {
        window.addEventListener('selectstart', disableSelect);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        dragEl = openPopup(
            <DragEl {...{data: [{label: uppercaseFirstLetter(data.name), value: convertNumberTimeToStringTime(data.duration)}]}}/>);
        dragEl.style.position = 'absolute';
        dragEl.style.zIndex = '2000';
        dragEl.style.pointerEvents = 'none';
        dragEl.style.display = 'none';
        levelMode = 'all';
    }

    const handleMouseMove = (e: any) => {
        parentEl.dataset.isDrag = levelMode;
        if (rootRef.current)
            rootRef.current.dataset.isDrag = "true";

        const {pageX, pageY} = e;
        dragEl.style.display = 'block';
        const {offsetHeight, offsetWidth} = dragEl;
        dragEl.style.display = 'none';
        const left = checkLimit(0, window.innerWidth - offsetWidth - 5, pageX);
        const top = checkLimit(0, window.innerHeight - offsetHeight, pageY);
        dragEl.style.left = left - 10 + 'px';
        dragEl.style.top = top - 10 + 'px';
        dragEl.style.display = 'block';
        document.body.style.cursor = 'grabbing';
    }

    const handleMouseUp = (e: any) => {
        if (dragEl) {
            dragEl.remove();
            dragEl = undefined;
        }
        document.body.style.cursor = 'unset';
        delete parentEl.dataset.isDrag;
        delete rootRef.current?.dataset.isDrag;
        window.removeEventListener('selectstart', disableSelect);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        if (!e.target)
            return;
        const {taskid, unitid: newPosition,} = e.target.dataset;
        if (unitId === undefined)
            return;

        e.target.dataset.hover = false;
        const onDelete = (value: IUnitWhenEdit): IUnitWhenEdit => {
            const {tasks = []} = value;
            return {...value, tasks: tasks.filter(item => item.group_id !== group_id)}
        }

        const onAdd = (value: IUnitWhenEdit): IUnitWhenEdit => {
            const {tasks = []} = value;
            if (!taskid)
                return {...value, tasks: [data, ...tasks]}
            else {
                const result = cloneObj(tasks);
                return {
                    ...value,
                    tasks: result.reduce((subRs: ITaskService[], item: ITaskService) =>
                        item.group_id === taskid ? [...subRs, item, data] : [...subRs, item], [])
                }
            }
        }

        set(({units: prev}) => {
            const old = cloneObj(prev);
            return {
                units: Object.keys(old).reduce((rs: { [id: string]: IUnitWhenEdit }, key) => {
                    if (key === unitId)
                        rs[key] = onDelete(rs[key])

                    if (key === newPosition)
                        rs[key] = onAdd(rs[key])

                    return rs;
                }, old)
            }
        })
        turnOnCalculate();
    }


    return <div ref={rootRef} className={styles['container-task']}>
        {(isAllowDrag && type) && <div className={stylesContainer['bt-drag']} onMouseDown={handleMouseDown}/>}
        <div {...propsWrapper}>
            {props.children}
            <div className={stylesContainer['container-menu']}>
                <Menu items={[
                    {
                        key: 'delete',
                        label: <div className='menu-line' data-danger='true' onClick={props.onDelete}>
                            <Icon icon={ICON_NAME.DELETE} danger/>
                            <span>Delete task</span>
                        </div>
                    }
                ]} placement='bottomRight'>
                    <div className='three-dot'/>
                </Menu>
            </div>
        </div>
        {isAllowDrag
            && <div className={[stylesContainer['line-middle'], styles['line-middle']].join(' ')} data-level='task'>
                <LineMiddle2
                    containerId={'service-operation'}
                    attr={{
                        unitId,
                        taskId: data.group_id
                    }}
                />
            </div>}
    </div>;
};

export default WrapperTask;