import {ACTION_TYPE} from "../../util/varibles/constants";
import {speedList} from "./ProcessData/Timeline/constants";

export enum WIDGET_TYPE {
    LINE,
    GAUGE,
    TANK
}

export const initSensors = {
    sensors: undefined,
    yAxis: []
}

export const timeFrame = [
    {value: 300000, name: '5 minutes'},
    {value: 900000, name: '15 minutes'},
    {value: 3600000, name: '1 hour'},
    {value: 21600000, name: '6 hours'},
    {value: 43200000, name: '12 hours'},
    {value: 86400000, name: '24 hours'},
];

interface IYAxis {
    min?: number
    max?: number
    text?: string
    format?: string
}

export interface IWidget {
    name: string
    widgetType: WIDGET_TYPE
    devices?: any[]
    sensors?: any[]
    keys?: any[]
    yAxis?: IYAxis[]
    smooth: boolean
}

export const initWidget: IWidget = {
    name: '',
    widgetType: WIDGET_TYPE.LINE,
    smooth: true
}

export const initReplay = {
    isShow: false,
    isLoading: false,
    isPlay: true,
    speed: speedList[0].key,
    time: []
}

export enum MODE_UPDATE_HISTORY {
    NONE,
    ADD_LEFT,
    ADD_RIGHT,
    REPLACE
}

export interface VesselDetailState {
    devices: any[]
    dashboards: any[]

    dashboard: any
    vessel: any
    vesselLive: any

    isLive: boolean
    isTracking: boolean
    isDragging: boolean

    currentTime: number
    history: {
        startTime: number
        finishTime: number
    }
    deviceIds: {
        sensor: string
        device_id: string
        key: string
    }[]

    update: {
        mode: MODE_UPDATE_HISTORY,
        keys: any[]
    }

    replay: {
        isPlay: boolean
        isLoading: boolean
        speed: number
        time: number[]
    }

    loading: {
        vessel: boolean
        chart: boolean
        device: boolean
        submitWidget: boolean
    }

    popupWidget: {
        isShow: boolean,
        editMode?: ACTION_TYPE
        widget?: IWidget
    },
    popupChecklist: {
        isShow: boolean
        checklist?: any
    }
}

export const initialState: VesselDetailState = {
    devices: [],
    dashboards: [],

    dashboard: null,
    vessel: {},
    vesselLive: {},
    deviceIds: [],

    isLive: true,
    isTracking: true,
    isDragging: false,

    currentTime: 0,
    history: {
        startTime: 0,
        finishTime: 0,
    },

    update: {
        mode: MODE_UPDATE_HISTORY.NONE,
        keys: []
    },

    replay: initReplay,
    loading: {
        vessel: false,
        chart: false,
        device: false,
        submitWidget: false
    },
    popupWidget: {isShow: false, editMode: ACTION_TYPE.CREATE, widget: initWidget},
    popupChecklist: {isShow: false}
};
