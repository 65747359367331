import callApi from "../api/callApi";
import {urlTaskType} from "../api/v1";

export function getTaskTypesFetch() {
    return callApi(urlTaskType + 's', {
        method: 'GET'
    })
}

export function updateTaskTypeFetch(body: any) {
    return callApi(urlTaskType, {
        method: "PUT",
        body: JSON.stringify(body)
    })
}

export function createTaskTypeFetch(body: any) {
    return callApi(urlTaskType, {
        method: "POST",
        body: JSON.stringify(body)
    })
}

export function getStorageFetch() {
    return callApi(urlTaskType + '/storage', {
        method: 'GET'
    })
}
