import {notification} from "antd";
import {CONTRACT_TYPE, OPERATION_TYPE, operationType, PATHNAME, TIME_PER_STEP} from "../../../util/varibles/constants";
import {convertPxToTime} from "../util";
import {checkLimit, getElById} from "../../../util/varibles/global";
import stylesContract from "../Plan/Calendar/Contracts/style.module.scss";
import {ISite, IUnit, IVessel} from "../../../util/varibles/interface";
import {nextPage} from "../../../util/library/Router";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {EL_RULE} from "../Plan/Calendar/constants";
import {HEIGHT_PER_DAY} from "../Plan/Calendar/Operations/constants";
import styles from "../Plan/Calendar/Vessels/style.module.scss";
import {CALENDAR_ID} from "../constants";

export const KEY_SPLIT = '_'

export enum MODE_DRAG {
    START = 'drag_start',
    END = 'drag_end',
    OVER = 'drag_over',
    DROP = 'drop'
}

export const getCheckAll = (target: any, checked: any) => {
    if (target.length === 0 || Object.keys(checked).length === 0) {
        return {checkAll: false, indeterminate: false}
    } else {
        const checkAll = target.every(({id, fish_amount}: any) => !!checked[id] || fish_amount === 0);
        return {checkAll, indeterminate: !checkAll}
    }
}

export const filterOperationTypeOfVessel = (vessel: any, limit: any = []) => {
    const {operation_type} = vessel;
    return operation_type.reduce((list: any, item: any) => {
        if (limit.length === 0 || limit.indexOf(item) !== -1)
            return [...list, item]
        return list;
    }, []).sort((a: number, b: number) => a - b);
}

export const checkOperationType = (vessel: any, limit: OPERATION_TYPE[] = []) => {
    const listOfType = filterOperationTypeOfVessel(vessel, limit);
    if (listOfType.length === 0)
        notification.error({
            message: 'Error',
            description: `${vessel.name} does not support ${limit.map((item: any) => operationType[item].name).join(', ')}`
        })
    return listOfType
}

export const UnitModel = (unit: IUnit, site: ISite): IUnit => {
    return {
        ...unit,
        site_id: site.id,
        site_name: site.name,
        site_type: site.type,
        diseases: site.diseases,
        primary_factory: site.primary_factory
    }
}

const MIN_WIDTH = 800;

export const calculateWidth = () => {
    return Math.floor((window.innerWidth - 30) / MIN_WIDTH) || 1
};

export const deleteDragEl = (el: any) => {
    if (el)
        el.remove();
}

export const redirectCreateRoute = (planned: {site_id: string, factory_id: string}) => {
    const {site_id, factory_id} = planned;
    nextPage(`${PATHNAME.ROUTE}?source=${site_id}&destination=${factory_id}`)
}

interface IResultOfMouseDown {
    startTime: number
    operationId: string
    vessel: IVessel | undefined
    contractId: string
    availableTimeId: string
}

export const onMouseDown = (e: any, vessels: IVessel[]): IResultOfMouseDown | undefined => {
    const {pageX, pageY} = e;
    const left = checkLimit(0, window.innerWidth, pageX);
    const top = checkLimit(0, window.innerHeight, pageY);
    let element: HTMLElement | any = document.elementFromPoint(left, top);

    if (!element) return;
    const {lineType} = element.dataset;

    if (lineType === 'date')
        element = document.elementFromPoint(left, top - 2)

    const params: IResultOfMouseDown = {
        startTime: 0,
        operationId: '',
        vessel: undefined,
        contractId: '-',
        availableTimeId: '-'
    }
    let hideElements = [];
    if (element.closest(`.${stylesContract.contract}`)) {
        const {contractId, contractType, isOwn} = element.dataset;
        if (contractType === CONTRACT_TYPE.FIXED) {
            if (!isOwn) {
                notify.error(NotifyCode.E26)();
                return;
            } else {
                params.contractId = contractId;
            }
        }
        element.style.display = 'none';
        hideElements.push(element);
        element = document.elementFromPoint(left, top);
    }
    hideElements.forEach((item: any) => item.style.display = null);
    const {operationId = '', vesselId, availableTimeId, date} = element.dataset;
    if (operationId) {
        params.operationId = operationId;
    } else if (availableTimeId) {
        const {isRent} = element.dataset;
        if (isRent === 'true') {
            const {y} = element.getBoundingClientRect();
            const duration = convertPxToTime(e.pageY - y);
            params.vessel = vessels.find((item: any) => item.id === vesselId);
            params.availableTimeId = availableTimeId;
            params.startTime = Math.ceil((Number(date) + duration) / TIME_PER_STEP) * TIME_PER_STEP;
        } else {
            notify.error(NotifyCode.E26)();
            return;
        }
    } else if (vesselId) {
        const {accessFull} = element.dataset;
        if (accessFull === 'true' || (accessFull === 'false' && params.contractId !== '-')) {
            params.vessel = vessels.find((item: any) => item.id === vesselId);
            if (date)
                params.startTime = Number(date);
            else {
                const vesselEL = getElById(`${EL_RULE.VESSEL}-${vesselId}`)
                if (vesselEL) {
                    const calendarEl = getElById(CALENDAR_ID);
                    const {y} = calendarEl.getBoundingClientRect();
                    const position = Math.floor((calendarEl.scrollTop + e.pageY - y) / HEIGHT_PER_DAY);
                    const daysEl = vesselEL.getElementsByClassName(styles.day)
                    const dayEl = daysEl[position] as HTMLElement;
                    if (dayEl) {
                        const {date} = dayEl.dataset;
                        params.startTime = Number(date);
                    } else
                        return;
                } else
                    return
            }
        } else {
            notify.error(NotifyCode.E26)();
            return;
        }
    }

    return params;
}