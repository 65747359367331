import React, {useEffect, useRef, useState} from 'react';
import styles from "./style.module.scss";
import {notification, Popconfirm, TimePicker} from "antd";
import stylesContainer from "../style.module.scss";
import {datetime} from "../../../../../../util/library/datetime";
import Icon, {ICON_NAME} from "../../../../../../components/Icon";
import {openPopup} from "../../../../../../components/Popup/Component/WrapperPopup";
import PopupSetArrivalTime from "../PopupSetArrivalTime";
import {propContainer, propsConfirm} from "../../../../../../util/varibles/constants";

interface IProps {
    name: string
    value: any
    checked: any

    onChange(value: any): void

    onDelete(ids: any): void
}


const ArrivalTimeForAll: React.FC<IProps> = (props) => {
    const [focus, setFocus] = useState(false);
    const [arrivalTime, setArrivalTime]: any | null = useState(undefined);
    const [isDelete, setIsDelete] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const {value, name, checked} = props;

    useEffect(() => {
        const {arrival_time} = value
        if (arrival_time) {
            const hour = Math.floor(arrival_time / 60);
            const minute = Math.floor(arrival_time - (hour * 60));
            setArrivalTime(datetime().set({hour, minute}).value)
        } else {
            setArrivalTime(undefined)
        }
    }, [value]);

    const handleFocus = () => {
        setFocus(true);
        setTimeout(() => {
            if (inputRef.current)
                inputRef.current.focus()
        }, 100)
    }

    const handleChange = (value: any) => {
        if (value == null) {
            notification.error({message: 'Error', description: 'Time is required'})
            return;
        }

        const time = datetime(value);
        const hour = time.hour;
        const minus = time.minute;
        props.onChange({all: {arrival_time: (hour * 60) + minus}})
        setTimeout(() => {
            if (inputRef.current)
                inputRef.current.blur()
        }, 100)
    }

    const handleDelete = (ids: string[]) => {
        props.onDelete(ids);
        setIsDelete(false);
    }

    const handleEditSelected = () => {
        const div = openPopup(<PopupSetArrivalTime
            visible={true}
            name={name}
            checked={checked}
            onSave={handleChange}
            onClose={() => div.remove()}
        />)
    }

    return <div className={styles.wrapper}>
        {checked.size > 0
            ? <div className={styles.selected}>
                <div className={styles.button} onClick={handleEditSelected}>
                    <Icon icon={ICON_NAME.CLOCK_2}/>
                    <span>Arrival time for selected {name}</span>
                </div>
                <Popconfirm
                    {...propsConfirm}
                    title="Are you sure to delete this setting?"
                    open={isDelete}
                    onConfirm={() => handleDelete(Array.from(checked))}
                    onCancel={() => setIsDelete(false)}
                >
                    <div className={styles.button} data-danger='true' onClick={() => setIsDelete(true)}>
                        <Icon icon={ICON_NAME.DELETE} danger/>
                        <span>Remove selected setting</span>
                    </div>
                </Popconfirm>
            </div>
            : <div className={styles.all}>
                <div className={styles.button} onClick={handleFocus}>
                    <Icon icon={ICON_NAME.CLOCK_2}/>
                    <span>Arrival time for all {name}</span>
                </div>
                {(arrivalTime || focus) && <TimePicker
                    {...propContainer}
                    showNow={false}
                    ref={inputRef}
                    value={arrivalTime}
                    open={focus}
                    variant={"borderless"}
                    className={stylesContainer.picker}
                    popupClassName={stylesContainer['popup-picker']}
                    minuteStep={5}
                    format={'HH:mm'}
                    placeholder={''}
                    onChange={handleChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />}
                {arrivalTime && <Popconfirm
                    {...propsConfirm}
                    title="Are you sure to delete this setting?"
                    open={isDelete}
                    onConfirm={() => handleDelete(['all'])}
                    onCancel={() => setIsDelete(false)}
                >
                    <div className='bt-ghost' data-icon={true} data-danger='true' data-show={isDelete}
                         onClick={() => setIsDelete(true)}>
                        <Icon icon={ICON_NAME.DELETE} size='large'/>
                    </div>
                </Popconfirm>}
            </div>}
    </div>;
};

export default ArrivalTimeForAll;
