import React from 'react';
import {CONTRACT_TYPE} from "../../../../util/varibles/constants";
import {IVessel} from "../../../../util/varibles/interface";
import styles from './style.module.scss';
import {connect} from "react-redux";
import Month from "./Month";
import Vessel from "./Vessel";
import AvailableTimes from "../AvailableTimes";
import Contracts from "../Contracts";
import Operations from "../Operations";
import Weeks from "../Weeks";
import {checkLimit} from "../../../../util/varibles/global";
import {AppState} from "../../../../util/store/store";

export const HEIGHT_PER_OP = 38;

const mapStateToProps = (state: AppState) => {
    const {tenant_id: userTenantId} = state.login.user;
    return {
        userTenantId,
        vessels: state.overview.vessels,
    }
};

export interface IVesselHeight {
    [vesselId: string]: { height: number, index: number, position: number }
}

interface IProps {
    vessels: IVessel[]
    userTenantId: string
}

const Vessels: React.FC<IProps> = ({vessels, userTenantId}) => {
    const vesselHeight: IVesselHeight = {};
    let totalHeight = 0;
    const {list, contracts, available_times} = vessels.reduce((rs: any, item) => {
        const {id, contracts, available_times, isOwn, permission, isShow, row = 1} = item;
        const isPermission = !!(isOwn || permission);
        if (isShow) {
            const vesselIndex = rs.list.length;
            const height = checkLimit(85, undefined, ((row + 1) * HEIGHT_PER_OP + ((row + 1) * HEIGHT_PER_OP * .08) + 5));

            vesselHeight[id] = {
                index: rs.index,
                height,
                position: vesselIndex,
            };

            totalHeight += height;

            rs.index += height;

            rs.list.push(item);

            available_times.forEach((sub: any) => {
                rs.available_times = [...rs.available_times, {...sub, vesselId: id, isPermission, vesselIndex}];
            })

            contracts.forEach((sub: any) => {
                const {type, tenant_id} = sub;
                if (type === CONTRACT_TYPE.FIXED) {
                    rs.contracts = [...rs.contracts, {
                        ...sub,
                        vesselId: id,
                        isOwn: tenant_id === userTenantId,
                        isPermission,
                        vesselIndex
                    }];
                }
            })
        }
        return rs;
    }, {list: [], contracts: [], available_times: [], index: 0});

    const count = list.length;
    const piece = checkLimit(0, undefined, window.innerHeight - totalHeight - 170);
    const moreHeight = count ? piece / count : 0;

    return <>
        <div className={styles.vessels} data-max={count}>
            <Month/>
            {list.map((item: any) => <Vessel
                key={item.id}
                data={item}
                moreHeight={moreHeight}
                vesselHeight={vesselHeight}
            />)}
        </div>
        <AvailableTimes moreHeight={moreHeight} vesselHeight={vesselHeight} source={available_times}/>
        <Contracts moreHeight={moreHeight} vesselHeight={vesselHeight} source={contracts}/>
        <Operations moreHeight={moreHeight} vesselHeight={vesselHeight}/>
        <Weeks vessels={list} moreHeight={moreHeight} vesselHeight={vesselHeight}/>
    </>;
};

export default connect(mapStateToProps, {})(Vessels);
