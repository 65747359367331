import {checkLimit, cloneObj, getHourMinute, getStartFinishByOpMode} from "../../../util/varibles/global";
import {
    aDayMillisecond,
    AVAILABLE_TIME_TYPE,
    CERTAIN_TYPE,
    CONTRACT_TYPE,
    OP_STATUS,
    OPERATION_CONFIRM_STATUS,
    OPERATION_MODE,
    OPERATION_TYPE,
    showAlert,
    TASK_TYPE,
    taskTypes,
    TIME_PER_STEP,
    TREATMENT_TYPE,
    treatmentType
} from "../../../util/varibles/constants";
import {HEIGHT_PER_DAY} from "../Plan/Calendar/Operations/constants";
import {
    IActivityLog,
    IFactory,
    ILevel,
    ILevelByOp,
    ILevelByVessel,
    IOperation,
    IOperations,
    IRoute,
    ISorting,
    IUnit,
    IVessel,
    TOperation
} from "../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../util/varibles/message";
import {IResultValidation, opValidation, planValidation, valid, VALIDATION, VALIDATION_STATUS} from "./validation";
import {datetime} from "../../../util/library/datetime";
import {getTimeByOpMode} from "./function_operation/constants";
import {MAP_ELEMENT} from "../../../util/library/googlemap/constants";
import {store} from "../../../util/store/store";
import {IFishChanges} from "../constants";

/*
    Date: 27/03/2020
    Author: Vinh.Pham
    Description: Check valid operation
 */
export interface ICheckValidOperation {
    operations: IOperations,
    level: ILevelByVessel
    data: IOperation,
    factories?: IFactory[]
    opMode: OPERATION_MODE
}

export function checkValidOperation(args: ICheckValidOperation): IResultValidation {
    const {
        operations,
        level,
        data,
        factories = [],
        opMode
    } = args;
    const actions: VALIDATION[] = cloneObj(opValidation[data.operation.operation_type]);

    return actions.reduce((rs: any, key, i, arr) => {
        const common = {operations, level, factories, opMode};
        const result = planValidation[key](data, common)

        if (result.status === VALIDATION_STATUS.ERROR) {
            arr.splice(i);
            return result;
        }
        return rs;
    }, valid);
}

/*
    Date: 10/01/2020
    Author: Vinh.Pham
    Description: check factory time
 */
export function compareFactoryTime(factoryTime: number, workTime: number) {
    const hoursFactory = new Date(factoryTime).getHours(),
        minutesFactory = new Date(factoryTime).getMinutes(),
        newTime = hoursFactory + minutesFactory / 60;
    if (newTime > workTime)
        return -1;
    else if (newTime === workTime)
        return 0;
    else
        return 1;
}

/*
    Date: 12/08/2020
    Author: Vinh.Pham
*   Description: calculate total fish and total weight
 */
export function getTotalAmountAndTotalWeight(data: any): { total_amount: number, total_weight: number } {
    return data.reduce((total: { total_amount: number, total_weight: number }, unit: IUnit) => {
        total.total_amount += unit.fish_amount;
        total.total_weight += unit.total_weight;
        return total
    }, {total_amount: 0, total_weight: 0})
}

export function getAmountAndWeightFromGroup(data: { fish_amount: number, total_weight: number }[]): {
    fish_amount: number,
    total_weight: number
} {
    return data.reduce((data: { fish_amount: number, total_weight: number }, {fish_amount, total_weight}) => {
        data.fish_amount += fish_amount;
        data.total_weight += total_weight;
        return data;
    }, {fish_amount: 0, total_weight: 0})
}

export function getAmountAndWeightFromAllDeliveryTypes(data: IUnit[]) {
    let sumFishAmount = 0, sumTotalWeight = 0;
    const sub_operations = data.map((unit) => {
        const {fish_amount, total_weight} = getAmountAndWeightFromGroup(unit.delivery_types || []);
        sumFishAmount += fish_amount;
        sumTotalWeight += total_weight;
        return {...unit, fish_amount, total_weight}
    })
    return {sub_operations, total_amount: sumFishAmount, total_weight: sumTotalWeight}
}


const commonParamActivityLog = (args: any) => {
    const {data} = args;
    let result: any = {
        vessel_id: data.vessel.id,
        vessel_tenant_id: data.tenant_id,
        weather: false,
        activity_log: true,
    }

    if (data.operation.status === OP_STATUS.PROCESSING)
        result.operation_id = data.operation.id;

    return result;
}

export interface IProperty {
    start: number
    start_place?: {
        start_place_id: string
        start_place_field: string
        start_time?: number
    },
    opIds?: string[]
    support_vessel?: boolean
}

interface IParamActivityLog {
    factories: IFactory[]
    setting: {
        autoPlanConfig: any
        sitesTime: any
        factoriesTime: any
    }
    level: ILevelByOp
    operations: IOperations
    property: IProperty
    opMode: OPERATION_MODE
}

export const paramsActivityLog: {
    [id: string]: (data: { operation: TOperation, vessel: IVessel }, args: IParamActivityLog) => any
} = {
    [OPERATION_TYPE.HARVEST]: (data, args) => {
        const {setting, factories = [], opMode} = args;
        const startTime = args.property.start;
        const {
            start_place_id,
            start_place_field,
            start_time,             // Limit start
        } = args.property.start_place || getLastPlaceByTime(args.operations, args.level, startTime, args.property.opIds, opMode);
        const {priority} = setting.autoPlanConfig.vessel[data.vessel.type];
        const {factory_id} = data.operation;
        const {work_time: workTime = 360} = factories.find(item => item.id === factory_id) || {};
        const factoryTime = getTimeSetting(setting.factoriesTime, factory_id, workTime);
        const factory_time = datetime(startTime).set(getHourMinute(factoryTime)).time;
        const {site_id} = data.operation;
        const siteTime = getTimeSetting(setting.sitesTime, site_id, -1);
        let site_arrival_time = -1;
        if (siteTime !== -1) {
            site_arrival_time = datetime(factory_time).set(getHourMinute(siteTime)).time;
            if (site_arrival_time > factory_time)
                site_arrival_time -= aDayMillisecond;
        }

        const {sub_operations} = data.operation;
        const {total_amount, total_weight} = getTotalAmountAndTotalWeight(sub_operations);
        return {
            data: {
                ...data,
                operation: {...data.operation, start_place_id, start_place_field, total_amount, total_weight}
            },
            param: {
                ...commonParamActivityLog({data}),
                operation_type: OPERATION_TYPE.HARVEST,
                sites: data.operation.sites,
                start_time,
                start_place_id,
                start_place_field,
                is_waiting_unit: data.operation.is_waiting_unit,
                is_journey_to_site: data.operation.is_journey_to_site,
                is_cleaning: data.operation.is_cleaning,
                source_id: site_id,
                destination_id: factory_id,
                router_id: data.operation.router_id,
                factory_time,
                packing_time: factoryTime,
                site_arrival_time,
                priority,
                delivery_types: sub_operations.reduce((list: any, {delivery_types = []}: any) => [
                    ...list,
                    ...delivery_types.map(({id, total_weight}: any) => ({id, total_weight}))
                ], []),
                total_amount,
                total_weight,
            }
        };
    },
    [OPERATION_TYPE.TREATMENT]: (data, args) => {
        const {site_id, is_cleaning, unit_order, sub_operations, tasks, round_map} = data.operation;
        const startTime = args.property.start;
        const {support_vessel} = args.property;

        return {
            data,
            param: {
                ...commonParamActivityLog({data}),
                sub_operations,
                operation_type: OPERATION_TYPE.TREATMENT,
                est_start_time: startTime,
                is_cleaning,
                support_vessel,
                source_id: site_id,
                unit_order,
                round_map,
                tasks
            }
        }
    },
    [OPERATION_TYPE.TRANSPORT]: (data, args) => {
        const {router_id, sub_operations, source_id, destination_id} = data.operation;
        const startTime = args.property.start;

        const {
            start_place_id,
            start_place_field,
            start_time,             // Limit start
        } = args.property.start_place || getLastPlaceByTime(args.operations, args.level, startTime, args.property.opIds, args.opMode);

        const {total_amount, total_weight} = getTotalAmountAndTotalWeight(sub_operations);
        const {sitesTime} = args.setting;

        const siteTime = getTimeSetting(sitesTime, source_id, -1);
        let site_arrival_time = -1;
        if (siteTime !== -1) {
            site_arrival_time = datetime(startTime).set(getHourMinute(siteTime)).time
        }

        return {
            data: {
                ...data,
                operation: {...data.operation, start_place_id, start_place_field, total_amount, total_weight}
            },
            param: {
                ...commonParamActivityLog({data}),
                operation_type: OPERATION_TYPE.TRANSPORT,
                start_time,
                start_place_id,
                start_place_field,
                is_waiting_unit: false,
                router_id,
                source_id,
                destination_id,
                site_arrival_time,
                est_start_time: startTime,
                total_amount,
                total_weight,
            }
        }
    },
    [OPERATION_TYPE.SERVICE]: (data, args) => {
        const {operation: {tasks}} = data;
        const startTime = args.property.start;
        return {
            data,
            param: {
                ...commonParamActivityLog({data}),
                operation_type: OPERATION_TYPE.SERVICE,
                est_start_time: startTime,
                tasks
            }
        };
    }
}

export function getTimeSetting(dataTime: any, key: string, init: number) {
    return dataTime.all ? dataTime.all.arrival_time : (dataTime[key] ? dataTime[key].arrival_time : init);
}

export const sourceKeys: any = {
    [OPERATION_TYPE.HARVEST]: {key: 'site_id', type: 'site', name: 'site_name', map_id: MAP_ELEMENT.SITE},
    [OPERATION_TYPE.TRANSPORT]: {key: 'source_id', type: 'site', name: 'site_name', map_id: MAP_ELEMENT.SITE},
    [OPERATION_TYPE.TREATMENT]: {key: 'site_id', type: 'site', name: 'site_name', map_id: MAP_ELEMENT.SITE},
    [OPERATION_TYPE.SERVICE]: {key: 'site_id', type: 'site', name: 'site_name', map_id: MAP_ELEMENT.SITE},
}

export const destinationKeys: any = {
    [OPERATION_TYPE.HARVEST]: {key: 'factory_id', type: 'factory', name: 'factory_name', map_id: MAP_ELEMENT.FACTORY},
    [OPERATION_TYPE.TRANSPORT]: {
        key: 'destination_id',
        type: 'site',
        name: 'destination_name',
        map_id: MAP_ELEMENT.SITE
    },
    [OPERATION_TYPE.TREATMENT]: {key: 'site_id', type: 'site', name: 'site_name', map_id: MAP_ELEMENT.SITE},
    [OPERATION_TYPE.SERVICE]: {key: 'site_id', type: 'site', name: 'site_name', map_id: MAP_ELEMENT.SITE},
}

export const paramsWeatherOfOp = {
    [OPERATION_TYPE.HARVEST]: (op: TOperation, common: any) => {
        const {site_id, factory_id} = op;
        return {...common, source_id: site_id, destination_id: factory_id}
    },
    [OPERATION_TYPE.TRANSPORT]: (op: TOperation, common: any) => {
        const {source_id, destination_id} = op;
        return {...common, source_id, destination_id}
    },
    [OPERATION_TYPE.TREATMENT]: (op: TOperation, common: any) => {
        const {site_id} = op;
        return {...common, source_id: site_id, destination_id: site_id}
    },
    [OPERATION_TYPE.SERVICE]: (op: TOperation, common: any) => {
        const {site_id} = op;
        return {...common, source_id: site_id, destination_id: site_id}
    },
    [OPERATION_TYPE.EVENT]: (op: TOperation, common: any) => {
        const {pois = []} = op;
        return {...common, pois}
    },
}

interface IResultGetLastPlaceByTime {
    start_place_id: string
    start_place_field: string
    start_place_name: string
    start_time: number
}

export function getLastPlaceByTime(operations: IOperations, level: ILevelByOp, start: number, opIds: string[] = [], opMode: OPERATION_MODE): IResultGetLastPlaceByTime {
    let start_place_id = '', start_place_field = '', start_place_name = '', start_time = 0;
    const ids = new Set(opIds);
    Object.keys(level || {}).reduce((time: any, key) => {
        const {activity_log, operation, parallel}: any = operations[key];
        if (ids.has(operation.id || ''))
            return time;
        const finishTime = getTimeByOpMode[opMode](activity_log[activity_log.length - 1]);
        if (finishTime >= start)
            return time;

        if (start_time < finishTime && !parallel) {
            start_time = finishTime
        }

        if (operation.operation_type !== OPERATION_TYPE.EVENT
            && (!time || time < finishTime)
            && destinationKeys[operation.operation_type]) {
            const {key, type, name} = destinationKeys[operation.operation_type];
            start_place_id = operation[key];
            start_place_field = type
            start_place_name = operation[name] || ''
            return finishTime;
        }
        return time
    }, undefined);

    return {
        start_place_id,
        start_place_field,
        start_place_name,
        start_time: start_time ? Math.ceil((start_time + TIME_PER_STEP) / TIME_PER_STEP) * TIME_PER_STEP : 0
    };
}

/*
    Date: 29/11/2020
    Author: Vinh.Pham
    Description: Check exist route
 */
export function checkRoute(source: any, destination: any, routes: IRoute[] = []) {
    const route = routes.find(item => item.source_id === source.id && item.destination_id === destination.id);
    return route ? {error: 0, message: ''} : {
        error: VALIDATION_STATUS.ERROR, message: notify[NotifyCode.E20]([source.name, destination.name])
    }
}

export function checkRoute2(source: any, destination: any, routeIds: string[]) {
    const route = new Set(routeIds).has([source.id, destination.id].join('_'));
    return route ? {error: 0, message: ''} : {
        error: VALIDATION_STATUS.ERROR, message: notify[NotifyCode.E20]([source.name, destination.name])
    }
}

export function getFactory(factory_id: string, primary_factory: string, factories: any) {
    let id = '';
    if (factory_id.length > 0) {
        id = factory_id;
    } else if ((primary_factory || '').length > 0) {
        id = primary_factory
    }
    const factory = factories.find((item: any) => item.id === id);
    return factory ? factory : factories[0];
}

/*
    Date: 08/12/2020
    Author: Vinh.Pham
    Description: Update fish amount in units and harvest
 */
export function updateSitesAndHarvests(sub_operations: any, sitesUpdated: any, harvestsUpdated: any, site_id: string, dataOld: any) {
    sub_operations.forEach((sub: any) => {
        const {id, site_id: sub_site_id, harvest_id, fish_amount: fishAmountNew, total_weight: totalWeightNew} = sub;
        const indexOld = dataOld.findIndex((itemOld: any) => itemOld.id === id && itemOld.harvest_id === harvest_id);
        let subFishAmount, subTotalWeight
        if (indexOld !== -1) {
            const {fish_amount: fishAmountOld, total_weight: totalWeightOld} = dataOld[indexOld];
            subFishAmount = fishAmountOld - fishAmountNew;
            subTotalWeight = totalWeightOld - totalWeightNew;
        } else {
            subFishAmount = -fishAmountNew;
            subTotalWeight = -totalWeightNew;
        }
        if (harvest_id !== '-') {
            const indexHarvest = harvestsUpdated.findIndex((harvest: any) => harvest.harvest_id === harvest_id);
            if (indexHarvest !== -1) {
                harvestsUpdated[indexHarvest].fish_amount = checkLimit(0, undefined, harvestsUpdated[indexHarvest].fish_amount + subFishAmount)
                harvestsUpdated[indexHarvest].total_weight = checkLimit(0, undefined, harvestsUpdated[indexHarvest].total_weight + subTotalWeight)
            }
        }
        const siteId = sub_site_id || site_id;
        const indexSite = sitesUpdated.findIndex((site: any) => site.id === siteId);
        if (indexSite !== -1) {
            const {units} = sitesUpdated[indexSite];
            const indexUnit = units.findIndex((unit: any) => unit.id === id);
            if (indexUnit !== -1) {
                units.splice(indexUnit, 1, {
                    ...units[indexUnit],
                    fish_amount: checkLimit(0, undefined, units[indexUnit].fish_amount + subFishAmount),
                    total_weight: checkLimit(0, undefined, units[indexUnit].total_weight + subTotalWeight)
                })
                sitesUpdated.splice(indexSite, 1, {...sitesUpdated[indexSite], units})
                if (indexOld !== -1)
                    dataOld.splice(indexOld, 1);
            }
        }
        return {sitesUpdated, harvestsUpdated}
    });

    dataOld.forEach((itemOld: any) => {
        const {id, site_id: sub_site_id, harvest_id, fish_amount, total_weight} = itemOld;
        if (harvest_id !== '-') {
            const indexHarvest = harvestsUpdated.findIndex((harvest: any) => harvest.harvest_id === harvest_id);
            if (indexHarvest !== -1) {
                harvestsUpdated[indexHarvest].fish_amount = checkLimit(0, undefined, harvestsUpdated[indexHarvest].fish_amount + fish_amount)
                harvestsUpdated[indexHarvest].total_weight = checkLimit(0, undefined, harvestsUpdated[indexHarvest].total_weight + total_weight)
            }
        }
        const siteId = sub_site_id || site_id;
        const indexSite = sitesUpdated.findIndex((site: any) => site.id === siteId);
        if (indexSite !== -1) {
            const {units} = sitesUpdated[indexSite];
            const indexUnit = units.findIndex((unit: any) => unit.id === id);
            units.splice(indexUnit, 1, {
                ...units[indexUnit],
                fish_amount: checkLimit(0, undefined, units[indexUnit].fish_amount + fish_amount),
                total_weight: checkLimit(0, undefined, units[indexUnit].total_weight + total_weight)
            })
            sitesUpdated.splice(indexSite, 1, {...sitesUpdated[indexSite], units})
        }
    })
}

interface IUpdateNextOperation {
    startTime: number
    finishTime: number
    tenantId: string
    ids: string[]
    vesselId: string
    operations: IOperations
    level: ILevelByVessel
}

export const updateNextOperation = (args: IUpdateNextOperation): IOperations => {
    let {startTime, finishTime, tenantId, level, operations, vesselId} = args;
    const ids = new Set(args.ids || [])

    let {list, operationsNext} = Object.keys(level[vesselId] || {}).reduce((rs: any, key) => {
        const item = operations[key];
        const currentStart = item.activity_log[0].est_start_time;
        const {tenant_id: currentTenantId, id: currentOpId, status, parallel} = item.operation
        if (currentStart < startTime || ids.has(currentOpId) || parallel)
            return rs;
        if (!statusUnAllow.has(status) && tenantId === currentTenantId) {
            rs.list.push(item);
        } else
            rs.operationsNext.push(item);
        return rs
    }, {
        list: [],
        operationsNext: []
    });

    list.sort((a: any, b: any) => a.activity_log[0].est_start_time - b.activity_log[0].est_start_time);
    operationsNext.sort((a: any, b: any) => a.activity_log[0].est_start_time - b.activity_log[0].est_start_time);

    const operationsNew: IOperations = {};
    let isWarn = false;
    const pusher = args.ids.reduce((rs: any, item) => {
        rs[item] = true;
        return rs;
    }, {});
    for (let i = 0; i < list.length; i++) {
        const {activity_log: currentActivityLog} = list[i];
        const {id} = list[i].operation;
        const currentStart = currentActivityLog[0].est_start_time;
        if (currentStart <= finishTime) {
            const {activity_log: activityLogNew, isJump} = execPushOp({
                finishTime,
                currentStart,
                currentActivityLog,
                operations: operationsNext,
            });

            if (isJump)
                isWarn = true;

            operationsNew[id] = {
                ...list[i],
                action_type: list[i].action_type || OPERATION_CONFIRM_STATUS.UPDATE,
                activity_log: activityLogNew,
                pusher: {...list[i].pusher || {}, ...pusher}
            };
            pusher[id] = true;
            finishTime = activityLogNew[activityLogNew.length - 1].est_start_time;
        } else
            break;
    }

    if (isWarn)
        showAlert.pushOp();

    return operationsNew;
}

const generateCommon = (currentOp: IOperation) => {
    const {vessel} = currentOp;
    const is_send_to_vessel = !!(vessel.isOwn || vessel.permission);
    const data: any = {
        operation_code: `${currentOp.operation.operation_code}`,
        state: currentOp.action_type || OPERATION_CONFIRM_STATUS.UPDATE,
        note: currentOp.operation.note || '',
        operation_type: currentOp.operation.operation_type,
        activity_log: currentOp.activity_log,
        is_send_to_vessel,
        status: is_send_to_vessel ? OP_STATUS.WAITING : OP_STATUS.PENDING,
        available_time_id: currentOp.operation.available_time_id || '-',
        certain_mode: currentOp.operation.certain_mode || CERTAIN_TYPE.NONE,
    }
    const tenantId = store.getState().login.user.tenant_id;

    if (!vessel.isOwn) {
        if (currentOp.operation.contract_id && currentOp.operation.contract_id !== '-')
            data.contract_id = currentOp.operation.contract_id;
        else if (vessel.permission)
            data.contract_id = vessel.permission;
        else {
            const {id: contractId} = vessel.contracts.find(sub => sub.tenant_id === tenantId && sub.type === CONTRACT_TYPE.SINGLE) || {id: '-'}
            data.contract_id = contractId;
        }
    }

    if (currentOp.action_type !== OPERATION_CONFIRM_STATUS.NEW) {
        data.operation_id = `${currentOp.operation.id}`
    }

    return data
}

// Generate operation to confirm
export const generate = {
    [OPERATION_TYPE.HARVEST]: (operation: IOperation) => {
        const data: any = {
            sites: operation.operation.sites || [],
            sub_operations: operation.operation.sub_operations,
            site_id: operation.operation.site_id,
            site_name: operation.operation.site_name,
            source_site_type: operation.operation.source_site_type,
            factory_id: operation.operation.factory_id,
            factory_name: operation.operation.factory_name,
            router_id: operation.operation.router_id || '',
            prev_op_router_id: operation.operation.prev_op_router_id || '',
            is_waiting_unit: operation.operation.is_waiting_unit,
            is_journey_to_site: operation.operation.is_journey_to_site,
            is_cleaning: operation.operation.is_cleaning,
            start_place_id: operation.operation.start_place_id || '-',
            start_place_field: operation.operation.start_place_field || '-',
            destination_id: operation.operation.factory_id,
            destination_field: operation.operation.factory_name,
        }

        const {support} = operation.operation;
        if (support) {
            data.support = {
                type: support,
                related_id: operation.operation.related_id
            };
        }

        if (operation.operation.sorting)
            data.sorting = operation.operation.sorting;

        return {...data, ...generateCommon(operation)};
    },
    [OPERATION_TYPE.TREATMENT]: (operation: IOperation) => {
        const data: any = {
            start_place_id: '-',
            start_place_field: '-',
            sub_operations: operation.operation.sub_operations,
            site_id: operation.operation.site_id,
            site_name: operation.operation.site_name,
            tasks: operation.operation.tasks,
            files: operation.operation.files,
            is_cleaning: operation.operation.is_cleaning,
            round_map: operation.operation.round_map
        }
        const {support} = operation.operation;
        if (support) {
            data.support = {
                type: support,
                related_id: operation.operation.related_id
            };
        }
        return {...data, ...generateCommon(operation)};
    },
    [OPERATION_TYPE.TRANSPORT]: (operation: IOperation) => {
        const data: any = {
            sub_operations: operation.operation.sub_operations,
            sub_destinations: operation.operation.sub_destinations,
            source_id: operation.operation.source_id,
            source_name: operation.operation.source_name,
            source_site_type: operation.operation.source_site_type,
            destination_id: operation.operation.destination_id,
            destination_name: operation.operation.destination_name,
            router_id: operation.operation.router_id || '',
            prev_op_router_id: operation.operation.prev_op_router_id || '',
            start_place_id: operation.operation.start_place_id || '-',
            start_place_field: operation.operation.start_place_field || '-',
        }
        return {...data, ...generateCommon(operation)};
    },
    [OPERATION_TYPE.EVENT]: (operation: IOperation) => {
        const data: any = {
            parallel: operation.operation.parallel,
            event_type: operation.operation.event_type,
            pois: operation.operation.pois
        }
        return {...data, ...generateCommon(operation)};
    },
    [OPERATION_TYPE.SERVICE]: (operation: IOperation) => {
        const data: any = {
            sub_operations: operation.operation.sub_operations,
            start_place_id: '-',
            start_place_field: '-',
            site_id: operation.operation.site_id,
            site_name: operation.operation.site_name,
            tasks: operation.operation.tasks,
        }
        return {...data, ...generateCommon(operation)};
    },
    [OPERATION_TYPE.UNAVAILABLE]: () => {
    },
}


export function convertPxToTime(value: number): number {
    return Math.floor(value / HEIGHT_PER_DAY * aDayMillisecond)
}

export function convertPxToTimeByStep(value: number) {
    return Math.round(value / HEIGHT_PER_DAY * aDayMillisecond / TIME_PER_STEP) * TIME_PER_STEP
}

/*
    Date: 13/01/2022
    Author: Vinh.Pham
    Description: Check after change time of operation A, if operation A placed above another operation B,
                 operation B will more 15 minute until operation A is not overlap on operation B
 */
export const statusUnAllow: any = new Set([
    OP_STATUS.FINISHED,
    OP_STATUS.PROCESSING,
])

export function pushOperations(data: IOperation, operations: IOperations, isAll: boolean = true) {
    const {activity_log} = data;
    const startTime = activity_log[0].est_start_time;
    const {id: vesselId} = data.vessel;
    const {id: opId, tenant_id: tenantId} = data.operation;
    let {list, operationsNext} = Object.keys(operations).reduce((rs: any, key) => {
        const item = operations[key];
        const currentStart = item.activity_log[0].est_start_time;
        const {id: currentVesselId} = item.vessel;
        const {tenant_id: currentTenantId, id: currentOpId, status, parallel} = item.operation
        if (vesselId !== currentVesselId || currentStart < startTime || currentOpId === opId || parallel)
            return rs;

        if (!statusUnAllow.has(status) && tenantId === currentTenantId) {
            rs.list.push(item);
        } else
            rs.operationsNext.push(item);
        return rs
    }, {
        list: [],
        operationsNext: []
    });

    list.sort((a: any, b: any) => a.activity_log[0].est_start_time - b.activity_log[0].est_start_time);
    operationsNext.sort((a: any, b: any) => a.activity_log[0].est_start_time - b.activity_log[0].est_start_time);
    let finishTime = activity_log[activity_log.length - 1].est_start_time;
    let operationsNew: any = {}, isWarn = false;
    const pusher = {[opId]: true};

    for (let i = 0; i < list.length; i++) {
        const {activity_log: currentActivityLog} = list[i];
        const {id} = list[i].operation;
        const currentStart = currentActivityLog[0].est_start_time;
        if (currentStart <= finishTime) {
            const {activity_log: activityLogNew, isJump} = execPushOp({
                finishTime,
                currentStart,
                currentActivityLog,
                operations: operationsNext,
            });

            if (isJump)
                isWarn = true;

            operationsNew = {
                ...operationsNew,
                [id]: {
                    ...list[i],
                    action_type: list[i].action_type || OPERATION_CONFIRM_STATUS.UPDATE,
                    activity_log: activityLogNew,
                    pusher: {...list[i].pusher || {}, ...pusher}
                }
            };
            pusher[id] = true;
            finishTime = activityLogNew[activityLogNew.length - 1].est_start_time;
        } else
            break;
    }
    if (isWarn)
        showAlert.pushOp();

    if (isAll)
        return {...operations, ...operationsNew}
    else
        return {...operationsNew, [opId]: data}
}

interface IExecPushOp {
    finishTime: number
    currentStart: number
    currentActivityLog: IActivityLog[]
    operations: IOperation[]
}

export function execPushOp(args: IExecPushOp) {
    const {finishTime, currentStart, currentActivityLog, operations} = args;
    const duration = calcDurationToPush(finishTime, currentStart);

    let start = currentActivityLog[0].est_start_time + duration,
        finish = currentActivityLog[currentActivityLog.length - 1].est_start_time + duration,
        isJump = false;
    for (let i = 0; i < operations.length; i++) {
        const {activity_log: targetActivityLog} = operations[i];
        const startFinish = targetActivityLog[0].est_start_time;
        const targetFinish = targetActivityLog[targetActivityLog.length - 1].est_start_time;
        if (start <= targetFinish && startFinish <= finish) {
            const duration = calcDurationToPush(targetFinish, start);
            isJump = true;
            start += duration;
            finish += duration
        }
    }
    const durationNew = start - currentStart;
    return {
        activity_log: currentActivityLog.map((item: any) => ({
            ...item,
            est_start_time: item.est_start_time + durationNew
        })),
        isJump
    }
}

function calcDurationToPush(finishOther: number, startTarget: number) {
    return (Math.ceil((finishOther - startTarget) / TIME_PER_STEP) + 2) * TIME_PER_STEP
}

export function convertOpsFromResponse(operations: any, vessels: IVessel[], opMode: OPERATION_MODE, old: {
    operations: IOperations,
    level: ILevelByVessel
} = {
    operations: {},
    level: {}
}): { operations: IOperations, level: ILevelByVessel } {
    return operations.reduce((rs: any, item: any) => {
        const {vessel_id, id} = item.operation;
        const indexVessel = vessels.findIndex(vessel => vessel.id === vessel_id);
        const value = {...item, vessel: {...vessels[indexVessel], index: indexVessel}}
        rs.operations[id] = value;
        rs.level = upLevel(rs.level, value, rs.operations, opMode);
        return rs;
    }, old);
}

export function upLevel(source: ILevelByVessel, data: IOperation, ops: IOperations, opMode: OPERATION_MODE = OPERATION_MODE.PLAN, levelOld?: ILevelByOp) {
    if (!data)
        return source;

    const {id, operation_code, status, parallel = false} = data.operation;
    const {id: vessel_id} = data.vessel;

    const levelVessel: ILevelByOp = JSON.parse(JSON.stringify(source[vessel_id] || {}));
    const {start: startA, finish: finishA} = getStartFinishByOpMode[opMode](data.activity_log, status);
    const value: ILevel = {main: true, operation_code, level: 1, sub: 0, ids: []};

    // const code = new Set(['']);
    // if (code.has(operation_code))
    //     console.log('-----', operation_code)

    const ids = new Set<string>([]);
    const yes: any = {false: {}, true: {}};
    // Calculate Sub
    Object.keys(levelVessel).forEach(key => {
        const item = ops[key];
        if (!item)
            return;
        const {parallel: otherParallel, status: otherStatus} = item.operation;
        if (parallel || (!parallel && otherParallel)) {
            const {start: startB, finish: finishB} = getStartFinishByOpMode[opMode](item.activity_log, otherStatus);
            if (id !== key && startA <= finishB && startB <= finishA) {
                const {main} = levelVessel[key];
                // if (code.has(operation_code))
                //     console.log({...value, ids}, levelVessel[key], 1);
                if (levelVessel[key].ids.length === 0) {
                    const sub = levelVessel[key].sub + 1;
                    levelVessel[key] = {...levelVessel[key], sub,};
                    value.sub = sub;
                    value.level = sub;
                } else if (levelVessel[key].sub === value.sub) {
                    if (levelVessel[key].level === value.level) {
                        if (levelVessel[key].level >= levelVessel[key].sub) {
                            const isExist = Array.from(ids).some(subKey => new Set(levelVessel[subKey].ids).has(key));
                            if (isExist) {
                                const sub = levelVessel[key].sub + 1;
                                levelVessel[key] = {
                                    ...levelVessel[key],
                                    sub,
                                    level: Math.min(levelVessel[key].level + 1, sub)
                                };
                                value.sub = levelVessel[key].sub;
                                value.level = value.sub;
                            }
                        } else {
                            value.level++;
                        }
                    } else if (value.main)
                        value.level = Math.max(value.level, levelVessel[key].level);
                } else {
                    const sub = Math.max(levelVessel[key].sub, value.sub);
                    levelVessel[key] = {...levelVessel[key], sub};
                    value.sub = sub;
                }

                if (main) {
                    if (otherParallel) {
                        if (ids.size >= levelVessel[key].ids.length || !parallel) {
                            levelVessel[key] = {...levelVessel[key], main: false};
                        } else {
                            value.main = false;
                        }
                    } else if (parallel && value.main) {
                        value.main = false;
                    }
                }

                ids.add(key);
                yes[`${main}`] = {...yes[`${main}`], [`${levelVessel[key].level}`]: true}
                levelVessel[key] = {...levelVessel[key], ids: Array.from(new Set(levelVessel[key].ids).add(id))};
                // if (code.has(operation_code))
                //     console.log({...value, ids}, levelVessel[key], 2);
            }
        }
    });

    value.ids = Array.from(ids);
    levelVessel[id] = value;

    if (levelOld) {
        const {[id]: valueOld} = levelOld;
        const duplicate = new Set([...valueOld.ids, ...value.ids]);
        if (duplicate.size === ids.size && valueOld.ids.length > 0 && valueOld.ids.length === value.ids.length)
            return {...source, [vessel_id]: levelOld};
    }
    // if (code.has(operation_code))
    //     console.log(value, 'before finish');

    // Level adjustment
    if (!value.main) {
        const {level, sub} = value;
        if (yes.false[`${level}`]) {
            const no: any = [];
            for (let i = 1; i <= sub; i++) {
                if (!yes.false[`${i}`])
                    no.push(i)
            }
            if (no[0]) {
                value.level = no[0];
            } else {
                value.sub++;
                value.level = value.sub;
            }
        }

        value.ids.forEach(key => {
            if (levelVessel[key].main)
                return;
            const isHaveMain = levelVessel[key].ids.some(subKey => subKey !== id && levelVessel[subKey].main);
            if (!isHaveMain) {
                levelVessel[key] = {...levelVessel[key], main: true};
            }
        })
    }

    // Set all nodes to have the same sub
    const check = new Set(value.ids.reduce((rs: string[], key) => [...rs, ...levelVessel[key].ids], value.ids));
    check.forEach(key => levelVessel[key] = {...levelVessel[key], sub: value.sub});

    // if (code.has(operation_code))
    //     console.log(value, 'finish');
    return {...source, [vessel_id]: levelVessel};
}

export function downLevel(source: ILevelByVessel, data: IOperation, ops: IOperations, opMode: OPERATION_MODE = OPERATION_MODE.PLAN) {
    if (!data)
        return source;

    const {id: vessel_id} = data.vessel;
    if (!source[vessel_id])
        return source;

    const {id} = data.operation;
    const {[id]: old, ...args} = source[vessel_id];

    return Object.keys(args).reduce((rs: ILevelByVessel, key) => {
        const item = ops[key];
        if (!item)
            return rs;
        rs = upLevel(rs, item, ops, opMode);
        return rs;
    }, {...source, [vessel_id]: {}});
}

export function getContract(data: IOperation, vessels: IVessel[]): { contract_id: string, available_time_id: string } {
    const {id: vessel_id} = data.vessel;
    const start = data.activity_log[0].est_start_time;
    const finish = data.activity_log[data.activity_log.length - 1].est_start_time;
    const vessel = vessels.find(item => item.id === vessel_id);
    const {available_time_type, isOwn, permission} = vessel || {};
    if (available_time_type === AVAILABLE_TIME_TYPE.ONLY_AVAILABLE_TIME && !isOwn && !permission) {
        const {contracts = [], available_times = []} = vessel || {};
        const {id: contract_id = '-'} = contracts.find(item => {
            const {start_time, finish_time} = item;
            return item.type === CONTRACT_TYPE.FIXED && start <= finish_time && start_time <= finish
        }) || {}
        const {id: available_time_id = '-'} = available_times.find(item => {
            const {start_time, finish_time} = item;
            return start <= finish_time && start_time <= finish
        }) || {};
        return {contract_id, available_time_id}
    }
    return {
        contract_id: '-',
        available_time_id: '-'
    }
}


export function allowUpdateFish(op: IOperation): boolean {
    const opTypes = new Set([OPERATION_TYPE.HARVEST, OPERATION_TYPE.TRANSPORT]);
    return opTypes.has(op.operation.operation_type)
}

export function getKeyUnit(unit: IUnit) {
    const {harvest_id = '-', id} = unit || {};
    return [harvest_id, id].join('/')
}

export function addFishChanges(fishChanges: IFishChanges, newOp: IOperation, oldOp?: TOperation): IFishChanges {
    const {sub_operations, id} = newOp.operation;
    let result = cloneObj(fishChanges)
    if (!allowUpdateFish(newOp) || !newOp.action_type)
        return result

    const oldSubs = cloneObj((oldOp?.sub_operations || []).reduce((list: any, item) => {
        const key = getKeyUnit(item);
        list[key] = item.fish_amount;
        return list;
    }, {}));

    sub_operations.forEach(item => {
        const key = getKeyUnit(item);
        result[key] = {...result[key] || {}, [id]: (oldSubs[key] || 0) - item.fish_amount}
    })

    return result
}

export function removeFishChanges(fishChanges: IFishChanges, op: IOperation): IFishChanges {
    const {sub_operations, id} = op.operation;
    let result = cloneObj(fishChanges)
    if (!allowUpdateFish(op))
        return fishChanges
    sub_operations.forEach(item => {
        const key = getKeyUnit(item);
        const {[id]: old, ...args} = result[key] || {};
        if (args)
            result = {...result, [key]: args || {}}
    })
    return result || {}
}

export function getTypesAndUnitOfTreatment(tasks: any[]): {
    units: { [id: string]: boolean },
    types: { [id: string]: string }
} {
    return tasks.reduce((rs: any, item: any) => {
        const {type, treatment_type} = item;
        if (type === TASK_TYPE.COMBINE) {
            const {type_name} = item;
            rs.types[type_name] = type_name;
        } else if (treatment_type === TREATMENT_TYPE.SORTING) {
            const {sub_tasks} = item as ISorting;
            Object.keys(sub_tasks).forEach((key: string) => {
                rs.types[TREATMENT_TYPE.SORTING] = treatmentType[TREATMENT_TYPE.SORTING].name
                sub_tasks[key].forEach((task) => {
                    if (typeof task.treatment_type === 'number')
                        rs.types[task.treatment_type] = treatmentType[task.treatment_type].name
                })
            })
        } else if (treatment_type !== undefined) {
            const {name = ''} = treatmentType[treatment_type] || {};
            rs.types[treatment_type] = name;
        } else if (type === TASK_TYPE.HARVEST) {
            rs.types[type] = taskTypes[type].name;
            const {unit_id} = item.sub_operations[0];
            rs.units[unit_id] = true;
            return rs;
        }
        const {unit_id = ''} = item.sub_operation || {};
        rs.units[unit_id] = true;
        return rs;
    }, {units: {}, types: {}});
}

export function getKeyOfUnit(unit: IUnit): string {
    return [unit.id, unit.harvest_id || '-'].join('|')
}