import React from 'react';
import stylesParent from "../style.module.scss";
import styles from "./style.module.scss";
import Position from "../../Component/Position";
import {ILocation} from "../../../../util/varibles/interface";
import Weather from "../../Component/Weather";
import FishDisease from "../../Component/FishDisease";
import {convertNumberTimeToStringTime} from "../../../../util/varibles/global";
import Region from "../../Component/Region";
import Phone from "../../Component/Phone";

interface IProps {
    data: ILocation
}

const Poi: React.FC<IProps> = ({data}) => {
    const {name, phone, diseases, position, work_time} = data;

    return <>
        <div className={stylesParent.header}>
            <div className='d-flex align-items-center'>
                <span>{name}</span>
                <span className='ml-5 italic text-comment font-s4 pt-2'> - POI</span>
            </div>
        </div>
        <div className={stylesParent.body}>
            <div className={styles['container-info']}>
                <div className={styles.title}>
                    <div>POI info.</div>
                </div>
                <Phone phone={phone}/>
                <div className={styles['line-info']}>
                    <span>Work time:</span>
                    <span>{convertNumberTimeToStringTime((Number(work_time) || 0) * 60000)}</span>
                </div>
                <Region regionId={data.region_id}/>
                <Position {...{lat: position.latitude, lng: position.longitude}}/>
            </div>
            <div className={styles['container-weather']}>
                <Weather name={name}/>
            </div>
            <div className={[styles['container-weather'], 'pb-0'].join(' ')}>
                <div className={styles.title}>
                    <div>Disease</div>
                </div>
                <div className='text-comment ml-20 font-s4'>
                    (The info. is for current week and will be updated each week)
                </div>
                <FishDisease data={diseases} is_lice={false}/>
            </div>
        </div>
    </>;
};

export default Poi;
