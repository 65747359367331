import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IStateGlobal} from "../../../util/varibles/interface";
import {MessageState} from "../../../pages/MessagePage/constants";
import {Badge} from "antd";
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../Icon";

function mapStateToProps(state: IStateGlobal) {
    return {
        message: state.message
    };
}

interface IProps {
    message: MessageState
}

class MessageBadge extends Component<IProps> {
    render() {
        const {chatNew, operationsNew} = this.props.message;
        return <Badge className={styles.badge} count={chatNew + operationsNew} overflowCount={99} offset={[10, -2]}>
            <Icon icon={ICON_NAME.MESSAGE} size='large'/>
            <span data-lev='name'>Messaging</span>
        </Badge>;
    }
}

export default connect(mapStateToProps, {})(MessageBadge);
