import React, {useEffect, useState} from 'react';
import ImageDefault from "../../assets/icon/vessel_image_default.svg";
import {Storage} from "aws-amplify";
import {Image} from 'antd';
import styles from './style.module.scss';
import LoadingBox from "../LoadingBox";

interface IProps {
    imageUrl?: string
    width?: any
    height?: any
    preview?: any
}

const S3Image: React.FC<IProps> = (props) => {
    const [{loading, url}, setUrl] = useState<{ loading: boolean, url: any }>({loading: false, url: ImageDefault});
    const {width = '100%', height = '100%', imageUrl, preview} = props;

    useEffect(() => {
        if (imageUrl) {
            setUrl(prev => ({...prev, loading: true}))
            Storage.get(imageUrl)
                .then((url) => {
                    setUrl({loading: false, url})
                })
                .catch((error) => {
                    setUrl(prev => ({...prev, loading: false}))
                    console.log(error)
                })
        }
    }, [imageUrl])

    return <div className={styles.img} style={{height}}>
        {loading && <LoadingBox loading={true} level='bg' />}
        <Image {...{
            preview,
            width,
            height,
            src: url.toString(),
            fallback: ImageDefault
        }} />
    </div>
};

export default S3Image;
