import React, {useCallback, useState} from 'react';
import styles from './style.module.scss';
import Icon, {ICON_NAME} from "../../../../components/Icon";
import {Tooltip} from "antd";
import {mapTypes} from "../../../../util/library/googlemap/constants";
import {useDispatch} from "react-redux";
import {GlobalSetting} from "../../../../util/varibles/userSetting";
import {updateUserSetting} from "../../../LoginPage/reducer";

interface IProps {
}

const MapType: React.FC<IProps> = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const onClick = useCallback((e: any) => {
        setOpen(prev => {
            if (!prev) {
                setTimeout(() => e.target.focus(), 100)
            }
            return !prev
        });
    }, [])

    const handleChange = (value: any) => {
        dispatch(updateUserSetting([{[GlobalSetting.MAP_STYLE]: value}]))
    }

    return <div className={styles.wrapper} data-open={open}>
        <Tooltip title={open ? '' : 'Layers'} placement='topRight'>
            <div className={styles.toggle} onClick={onClick} tabIndex={1} onBlur={() => setOpen(!open)}>
                <Icon icon={open ? ICON_NAME.CLOSE : ICON_NAME.MAP} size="large"/>
            </div>
        </Tooltip>
        <div className={styles.mapTypes}>
            {mapTypes.map(item => <Tooltip key={item.id} title={item.name} placement='left'>
                <div className={styles.mapType} data-code={item.id} onMouseDown={() => handleChange(item.id)}/>
            </Tooltip>)}
        </div>
    </div>;
};

export default MapType;
