import React from 'react';
import styles from './style.module.scss';

interface IProps {
    source: string[]
}

const Location: React.FC<IProps> = ({source}) => {
    return <div className={styles.location} title={source.join('')}>
        {source.map((item, i) => <span key={i}>{item}</span>)}
    </div>;
};

export default Location;
