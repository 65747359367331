import React from 'react';
import {useSelector} from 'react-redux';
import styles from './style.module.scss';
import {RouteMode} from "../../constatns";
import {selectRoute} from "../../../../util/store/selectors";

const Guide: React.FC = () => {
    const mode = useSelector(selectRoute.mode)

    const styleGuide = mode === RouteMode.EDIT ? {
        WebkitAnimation: "fadeIn 7s alternate",
        animation: "fadeIn 7s alternate",
        animationIterationCount: 1,
    } : {};

    return <div className={styles['guide']} style={styleGuide}>
        <p className="m-0"><b>Right click</b> to create new way point.</p>
        <p className="m-0"><b>Drag a way point</b> to change its position.</p>
    </div>;
};

export default Guide;
