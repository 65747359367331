import React, {useMemo} from 'react';
import {IVessel} from "../../../../util/varibles/interface";
import {OPERATION_TYPE, operationType, treatmentType} from "../../../../util/varibles/constants";
import Icon, {ICON_NAME} from "../../../../components/Icon";
import styles from "../../style.module.scss";
import {Symbol} from "../../../../util/varibles/template";

interface IProps {
    vessel: IVessel
}

const Storages: React.FC<IProps> = ({vessel}) => {
    const {operation_type, tank_sub_types = [], storages = []} = vessel;

    const names = useMemo(() => {
        const result: { name: string, tankIcon?: boolean }[] = [];
        const viewIds = new Set(operation_type);
        viewIds.delete(OPERATION_TYPE.TREATMENT);
        viewIds.delete(OPERATION_TYPE.EVENT);
        Array.from(viewIds).forEach(item => result.push({name: operationType[item]?.name}));
        tank_sub_types.forEach(item => result.push({name: treatmentType[item]?.name, tankIcon: true}));
        return result.sort((a, b) => a.name.localeCompare(b.name));
    }, [operation_type, tank_sub_types]);


    return <div>
        <div className='text-[15px] font-[600] mt-4 mb-3'>Tank equipment and the corresponding capabilities</div>
        <div className='flex gap-3 flex-wrap'>
            {names.map(item => {
                return <div className={styles.boxJob} key={item.name}>
                    <span>{item.name}</span>
                    {item.tankIcon && <Icon icon={ICON_NAME.TANK_2}/>}
                </div>
            })}
        </div>
        <div className='flex w-full gap-4 mt-3'>
            {storages.map(item => <div key={item.id} className={styles.tank}>
                <div className='flex justify-between'>
                    <span>{item.name} | {item.value} {Symbol.m3}</span>
                    <span>Speed: {item.speed} t/h</span>
                </div>
            </div>)}
        </div>
    </div>;
};

export default Storages;
