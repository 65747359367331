import React, {Component} from 'react';
import {IStateGlobal} from "../../../util/varibles/interface";
import {connect} from 'react-redux';
import styles from "./style.module.scss";
import {IUser} from "../../../pages/LoginPage/constants";

const mapStateToProps = (state: IStateGlobal) => ({
    user: state.login.user
})

interface IProps {
    user: IUser
    data: any
}

class MessageLeave extends Component<IProps> {
    render() {
        const [currentUser] = this.props.user.username.split('@');
        const [username] = this.props.data.username.split('@');
        const name = username === currentUser ? 'You' : username
        return <div className={styles['change-user']}>
            {name} left the group
        </div>;
    }
}

export default connect(mapStateToProps, {})(MessageLeave);
