import {ReactNode} from "react";
import createFastContext from "../createFastContext";
import {IErrorForm, IVessel} from "../../util/varibles/interface";

const {Provider, useStore: useEditVessel, setStore: setEditVessel, getStore: getEditVessel} = createFastContext<{
    vessel: IVessel | Partial<IVessel>
    file?: any
    error?: {
        [key: string]: any
        storages: {
            [index: string]: { [key: string]: IErrorForm }
        }
        equipments: {
            [index: string]: { [key: string]: IErrorForm }
        },
    }
}>({
    state: {vessel: {}}
});

const EditVesselProvider = ({children}: {
    children: ReactNode,
}) => {

    return <Provider >
        {children}
    </Provider>
}


export {useEditVessel, setEditVessel, getEditVessel, EditVesselProvider}
