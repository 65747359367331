import React from 'react';
import styles from "./style.module.scss";
import {Button} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {ACTION_TYPE, SITE_TYPE} from "../../../../../../util/varibles/constants";
import {formatNumber} from "../../../../../../util/varibles/global";
import {IPropsChildren, IUnit} from "../../../../../../util/varibles/interface";
import {useSelector} from "react-redux";
import {selectPlan} from "../../../../../../util/store/selectors";
import {getKeyOfUnit} from "../../../../util";

interface IProps extends IPropsChildren {
    data: IUnit
    gridTemplateColumns: string
    fishAmountByUnit: { [id: string]: number }
    totalWeightByUnit: { [id: string]: number }

    handleAdd(unit: IUnit, isInternal: boolean): void
}

const Unit: React.FC<IProps> = (props) => {
    const {editMode} = useSelector(selectPlan.popupEdit);
    const {data, gridTemplateColumns, fishAmountByUnit, totalWeightByUnit} = props;
    const isInternal = data.site_type === SITE_TYPE.INTERNAL;
    const key = getKeyOfUnit(data);
    const fish_amount = Math.max(0, data.fish_amount - (fishAmountByUnit[key] || 0));
    const total_weight = Math.round(Math.max(0, data.total_weight - (totalWeightByUnit[key] || 0)) / 1000) / 1000

    return <div className={[styles.unit, styles.row].join(' ')} style={{gridTemplateColumns}}>
        {props.children}
        <div className='text-right'>
            <span>{isInternal && formatNumber(fish_amount)} </span>
        </div>
        <div className={styles['avg-weight']}>
            <span>{isInternal && formatNumber(data.avg_weight) + " gr"}</span>
        </div>
        <span className='text-right'>
                {isInternal && formatNumber(total_weight) + " t"}
            </span>
        <div className='d-flex justify-content-end'>
            {editMode === ACTION_TYPE.CREATE && <Button {...{
                shape: "circle",
                type: 'text',
                size: 'small',
                className: 'bt-add',
                icon: <PlusCircleOutlined style={{fontSize: '19px'}}/>,
                disabled: isInternal && data.fish_amount === 0,
                title: data.fish_amount === 0 ? 'No fish' : '',
                onClick: () => props.handleAdd({...data, fish_amount, total_weight}, isInternal)
            }}/>}
        </div>
    </div>;
};

export default Unit;
