export enum SEARCH_TYPE {
    OPERATION = 'operation',
    SITE = 'site',
    FACTORY = 'factory',
    VESSEL = 'vessel'
}

export interface IPropsSearchItem {
    data: any

    closePopup(): void
}
