import React, {useState} from 'react';
import {ISite} from "../../../../../util/varibles/interface";
import styles from './style.module.scss';
import {Checkbox} from "antd";
import Icon, {ICON_NAME} from "../../../../../components/Icon";
import {getProdAreaName} from "../../../../../util/varibles/global";

interface IProps {
    id: any
    name?: string
    search: string
    siteFilter: string[]
    prodAreaFilter: string[]
    list: ISite[]

    updateSetting(params: { siteFilter: string[], prodAreaFilter: string[] }): void
}

const ProductionArea: React.FC<IProps> = ({id, name = '', list, search, siteFilter, prodAreaFilter, updateSetting}) => {
    const [open, setOpen] = useState(true);
    const prodAreaIds = new Set(prodAreaFilter);
    const siteIds = new Set(siteFilter);

    if (!list)
        return null;

    const handleChangeProdArea = (e: any) => {
        const {checked} = e.target;
        let newSiteFilter, newProdAreaFilter;
        if (checked) {
            prodAreaIds.add(id)
            newProdAreaFilter = Array.from(prodAreaIds)
            newSiteFilter = Array.from(new Set([...Array.from(siteIds), ...list.map(item => item.id)]))
        } else {
            prodAreaIds.delete(id);
            newProdAreaFilter = Array.from(prodAreaIds)
            list.map(item => siteIds.delete(item.id))
            newSiteFilter = Array.from(siteIds);
        }
        updateSetting({siteFilter: newSiteFilter, prodAreaFilter: newProdAreaFilter})
    }

    const handleChangeSite = (e: any, subId: string) => {
        const {checked} = e.target;
        let newSiteFilter, newProdAreaFilter;
        if (checked) {
            siteIds.add(subId);
            newSiteFilter = Array.from(siteIds)
            const isUncheckProdArea = id === null || list.some(item => !siteIds.has(item.id));
            if (isUncheckProdArea) {
                prodAreaIds.delete(id);
                newProdAreaFilter = Array.from(prodAreaIds)
            } else {
                prodAreaIds.add(id)
                newProdAreaFilter = Array.from(prodAreaIds)
            }
        } else {
            prodAreaIds.delete(id);
            newProdAreaFilter = Array.from(prodAreaIds)
            siteIds.delete(subId);
            newSiteFilter = Array.from(siteIds);
        }
        updateSetting({siteFilter: newSiteFilter, prodAreaFilter: newProdAreaFilter})
    }

    return <div className={styles.productionArea} data-open={open}>
        <div className={styles.nameProductionArea}>
            <Checkbox checked={prodAreaIds.has(id)} onChange={handleChangeProdArea}>
                {id ? getProdAreaName({id, name}) : 'Other'}
            </Checkbox>
            <div className={styles.arrow} onClick={() => setOpen(!open)}>
                <Icon icon={ICON_NAME.ARROW} size="small"/>
            </div>
        </div>
        <div className={styles.sites}>
            {list.map(sub => {
                const {id: subId, name: subName} = sub;

                if (search.length && subName.toLowerCase().indexOf(search) === -1)
                    return null

                return <Checkbox
                    key={subId}
                    className={styles.site}
                    checked={siteIds.has(subId)}
                    onChange={(e) => handleChangeSite(e, subId)}
                >
                    {subName}
                </Checkbox>
            })}
        </div>
    </div>;
};

export default ProductionArea;