import React, {useCallback} from 'react';
import styles from './style.module.scss';
import Task from "../Task";
import stylesContainer from "../style.module.scss";
import Title from "./Title";
import {TTaskTreatment} from "../../../../../../../util/varibles/interface";
import {cloneObj} from "../../../../../../../util/varibles/global";
import {checkSorting} from "../constants";
import LineMiddle2 from "../../../_Component/LineMiddle2";
import {callEventEditOp, IUnitWhenEdit, setEditOp} from "../../../../../../../contexts/EditOperationContext";

interface IProps {
    unit: IUnitWhenEdit
}

const Unit: React.FC<IProps> = ({unit}) => {
    const {id, tasks = [],isLock,} = unit;
    const set = setEditOp();
    const {turnOnCalculate} = callEventEditOp();

    const handleUpdateTask = useCallback((value: TTaskTreatment, index: number, args?: { sorting?: boolean }) => {
        set(({units}) => {
            const newTasks = cloneObj(units[id].tasks || []);
            newTasks?.splice(index, 1, value);
            const {sorting} = args || {};
            const newValue = {tasks: newTasks, isSorting: units[id].isSorting};
            if (sorting)
                newValue.isSorting = checkSorting(newTasks);
            return ({units: {...units, [id]: {...units[id], ...newValue}}})
        })
        turnOnCalculate()
    }, [set, turnOnCalculate, id])

    const handleDeleteTask = useCallback((index: number) => {
        set(({units}) => {
            const newTasks = cloneObj(units[id].tasks || []);
            newTasks?.splice(index, 1);
            const isSorting = checkSorting(newTasks)
            return {units: {...units, [id]: {...units[id], isSorting, tasks: newTasks}}}
        })
        turnOnCalculate()
    }, [set, turnOnCalculate, id])

    return <div className={[styles.group, stylesContainer.group].join(' ')} data-lock={isLock}>
        <Title unit={unit}/>
        {!isLock && <div className={stylesContainer['line-middle']} data-level='unit'>
            <LineMiddle2 containerId={'treatment-operation'} attr={{unitId: id}}/>
        </div>}
        {!isLock &&
            <div className={[styles['line-middle'], stylesContainer['line-middle']].join(' ')} data-level='group'>
                <LineMiddle2 containerId={'treatment-operation'} attr={{unitId: id}}/>
            </div>}

        {tasks.map((item, subIndex: number) => <Task
            key={subIndex}
            data={{...item, index: subIndex} as TTaskTreatment}
            unit={unit}
            onUpdate={(value, args) => handleUpdateTask(value, subIndex, args)}
            onDelete={() => handleDeleteTask(subIndex)}
        />)}
    </div>
};

export default Unit;
