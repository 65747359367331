import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {NavLink} from "react-router-dom";
import SearchGlobal from "./SearchGlobal";
import MessageBadge from "./MessageBadge";
import Menu from "../Menu";
import {PoweroffOutlined} from "@ant-design/icons";
import {connect, useDispatch} from "react-redux";
import {ACTION} from "../../util/varibles/permission";
import ViewMode from "./ViewMode";
import Icon, {ICON_NAME} from "../Icon";
import {PATHNAME} from "../../util/varibles/constants";
import {loginActions} from "../../pages/LoginPage/reducer";
import ThemeColor from "./ThemeColor";
import {AppState} from "../../util/store/store";
import Notification from "./Noticaition";

const mapStateToProps = (state: AppState) => {
    const {username = '', email = '', permission = {}} = state.login.user || {};
    return {
        username,
        email,
        permission,
    }
};

interface IProps {
    username: string
    email: string
    permission: any
}

const Header: React.FC<IProps> = ({username, email, permission}) => {
    const dispatch = useDispatch();
    const handleLogout = useCallback(() => {
        dispatch(loginActions.logout());
    }, [dispatch])

    const accountMenu = [
        {
            className: styles['menu-group'],
            key: 'info-account',
            type: 'group',
            label: 'Account',
            children: [
                {
                    key: 'account',
                    className: 'no-hover',
                    disabled: true,
                    label: <div className={styles.accInfo}>
                        <div className={styles.account}>
                            {username.charAt(0).toUpperCase()}
                        </div>
                        {username}
                    </div>
                }
            ],
        },
        {type: 'divider'},
        {
            className: styles['menu-group'],
            key: 'info-support',
            type: 'group',
            label: 'Ocean Planner support',
            children: [
                {
                    key: 'support',
                    className: 'no-hover',
                    disabled: true,
                    label: <div className='flex flex-col'>
                        <div className={styles['line-info']}>
                            <div>Email:</div>
                            <div>support@ocean-planner.no</div>
                        </div>
                        <div className={styles['line-info']}>
                            <div>Phone:</div>
                            <div>+47 455 00 924</div>
                        </div>
                    </div>
                }
            ],
        },
        {type: 'divider'},
        {
            key: 'logout',
            label: <div className='menu-line' onMouseDown={handleLogout}>
                <PoweroffOutlined className={styles.logout}/>
                <span>Logout</span>
            </div>
        }
    ]

    const settingMenu = () => {
        let section1 = [], section2 = [];

        if (permission[ACTION.CHECKLIST.VIEW])
            section1.push({
                key: 'checklist',
                label: <NavLink className='menu-line' to={PATHNAME.CHECKLIST}>
                    <Icon icon={ICON_NAME.CHECKLIST} size='large'/>
                    <span>Checklist</span>
                </NavLink>
            });

        if (permission[ACTION.COMBINE_TREATMENT.VIEW])
            section1.push({
                key: 'combine-treatment',
                label: <NavLink className='menu-line' to={PATHNAME.COMBINED_TREATMENT}>
                    <Icon className='ml-1' icon={ICON_NAME.ADD} size='normal'/>
                    <span>Combined treatment</span>
                </NavLink>
            });

        // if (permission[ACTION.EQUIPMENT.VIEW])
        //     section1.push({
        //         key: 'equipment',
        //         label: <NavLink className='menu-line' to={PATHNAME.EQUIPMENT}>
        //             <AppstoreAddOutlined />
        //             <span>Equipment</span>
        //         </NavLink>
        //     });

        if (permission[ACTION.OP_SETTING.VIEW])
            section1.push({
                key: 'op-setting',
                label: <NavLink className='menu-line' to={PATHNAME.OPERATION_SETTING}>
                    <Icon icon={ICON_NAME.OPERATION_SETTING} size='large'/>
                    <span>Operation</span>
                </NavLink>
            });

        if (permission[ACTION.ROUTE.VIEW])
            section1.push({
                key: 'route',
                label: <NavLink className='menu-line' to={PATHNAME.ROUTE}>
                    <Icon icon={ICON_NAME.ROUTE} size='large'/>
                    <span>Route</span>
                </NavLink>
            });

        if (permission[ACTION.VESSEL.VIEW])
            section1.push({
                key: 'vessel',
                label: <NavLink className='menu-line' to={PATHNAME.VESSEL}>
                    <Icon className='ml-1' icon={ICON_NAME.VESSEL} size='large'/>
                    <span>Vessel</span>
                </NavLink>
            });

        if (permission[ACTION.LOCATION.VIEW])
            section1.push({
                key: 'location',
                label: <NavLink className='menu-line' to={PATHNAME.LOCATION}>
                    <Icon className='ml-1' icon={ICON_NAME.LOCATION} size='large'/>
                    <span>Location</span>
                </NavLink>,
            });

        if (permission[ACTION.DISEASE.VIEW])
            section1.push({
                key: 'disease',
                label: <NavLink className='menu-line' to={PATHNAME.DISEASE}>
                    <Icon icon={ICON_NAME.DISEASE} size={20}/>
                    <span>Disease</span>
                </NavLink>,
            });

        if (permission[ACTION.WEATHER.VIEW])
            section1.push({
                key: 'weather',
                label: <NavLink className='menu-line' to={PATHNAME.WEATHER}>
                    <Icon icon={ICON_NAME.WEATHER} size='large'/>
                    <span>Weather</span>
                </NavLink>
            });

        if (permission[ACTION.USER.VIEW])
            section1.push({
                key: 'user',
                label: <NavLink className='menu-line' to={PATHNAME.USER}>
                    <Icon className='ml-1' icon={ICON_NAME.USER} size='large'/>
                    <span>User</span>
                </NavLink>,
            });

        if (section1.length > 0)
            section1 = [{type: 'divider'}, ...section1];


        if (permission[ACTION.PREFERENCE.VIEW])
            section2.push({
                key: 'preference',
                label: <NavLink className='menu-line' to={PATHNAME.PREFERENCE}>
                    <Icon icon={ICON_NAME.PREFERENCE} size={20}/>
                    <span>Preference</span>
                </NavLink>
            })

        if (section2.length > 0)
            section2 = [{type: 'divider'}, ...section2];

        return [
            {
                key: 'title',
                className: 'no-hover',
                disabled: true,
                label: <span className={styles.titleMenu}>Setting & Management</span>
            },
            ...section1,
            ...section2
        ]
    }

    return <div className={styles.header}>
        <div className={styles.headerLeft}>
            <div className={styles.logo}/>
            {permission[ACTION.DASHBOARD.VIEW] &&
                <NavLink title='Dashboard' to={PATHNAME.HOME} className={styles.tab}>
                    <Icon icon={ICON_NAME.DASHBOARD} size='large'/>
                    <span data-lev='name'>Dashboard</span>
                </NavLink>}
            {permission[ACTION.OVERVIEW.VIEW] &&
                <NavLink title='Operation Overview' to={PATHNAME.OVERVIEW} className={styles.tab}>
                    <Icon icon={ICON_NAME.OVERVIEW} size='large'/>
                    <span data-lev='name'>Operation Overview</span>
                </NavLink>}
            {permission[ACTION.PLAN_OP.VIEW] &&
                <NavLink title='Plan Operation' to={PATHNAME.PLAN} className={styles.tab}>
                    <Icon className={styles.planIcon} icon={ICON_NAME.PLAN} size='large'/>
                    <span data-lev='name'>Plan Operation</span>
                </NavLink>}
            {permission[ACTION.MESSENGER.VIEW] &&
                <NavLink title='Messaging' to={PATHNAME.MESSAGE} className={styles.tab}>
                    <MessageBadge/>
                </NavLink>}
        </div>
        <div className={styles.headerRight}>
            {permission[ACTION.GLOBAL.SEARCH] && <SearchGlobal/>}
            <ViewMode/>
            <ThemeColor/>
            <Notification/>
            {permission[ACTION.MESSENGER.VIEW] && <Menu items={settingMenu()}>
                <div className={styles['action-small']} title='Setting'>
                    <Icon className={styles.setting} icon={ICON_NAME.SETTING} size={20}/>
                </div>
            </Menu>}

            <Menu items={accountMenu}>
                <div className={styles['action-small']} title='Account'>
                    <div className={styles.account}>
                        {email.charAt(0).toUpperCase()}
                    </div>
                </div>
            </Menu>
        </div>
    </div>;
};

export default connect(mapStateToProps, {})(Header);

