import React, {Component} from 'react';
import {IPropsPropertyElement, onChangeLabel, onChangeProperty, onChangeRequired, optionSeparator} from "./constants";
import styles from "./style.module.scss";

interface IProps extends IPropsPropertyElement {

}

class DateElement extends Component<IProps> {

    render() {
        const {selected} = this.props;
        if (!selected) return null;
        const {label, properties: {required, separator, subLabel, isShowTime, isCalendarPopup}} = selected;
        return <>
            <div className={styles['title']}>Date picker Properties</div>
            <div className={styles['property-element']}>
                <label>Label</label>
                <input type="text" value={label} onChange={(e) => onChangeLabel(e, this.props)}/>
                <span>Type a text field label.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Required</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('required')}>
                        <input id='required' type="checkbox" checked={required}
                               onChange={() => onChangeProperty({required: !required}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Prevent submission if this question is empty.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Separator</label>
                <div className={styles['options-text']} data-size='small'>
                    {optionSeparator.map(item =>
                        <div data-is-active={separator === item.value} key={item.value}
                             onClick={() => onChangeProperty({separator: item.value}, this.props)}
                        >
                            {item.label}
                        </div>)}
                </div>
                <span>Select which character to use between the date fields.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Calendar popup</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('calendar-popup')}>
                        <input id='calendar-popup' type="checkbox" checked={isCalendarPopup}
                               onChange={() => onChangeProperty({isCalendarPopup: !isCalendarPopup}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Display a date picker when interacting with the field.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Time field</label>
                <div className="d-flex">
                    <div className={styles['switch']} onClick={() => onChangeRequired('show-time')}>
                        <input id='show-time' type="checkbox" checked={isShowTime}
                               onChange={() => onChangeProperty({isShowTime: !isShowTime}, this.props)}
                        />
                        <div className={styles['slider']}/>
                    </div>
                </div>
                <span>Allow users to specify a time with date.</span>
            </div>
            <div className={styles['property-element']}>
                <label>Sub label</label>
                <input type="text" value={subLabel}
                       onChange={(e) => onChangeProperty({subLabel: e.currentTarget.value}, this.props)}
                />
                <span>Add a small description below the input field.</span>
            </div>
        </>;
    }
}

export default DateElement;
