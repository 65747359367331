import React from 'react';
import styles from './style.module.scss';

interface IProps {
    name: string
    isLock?: boolean
    isOngoing?: boolean
    isSelect?: boolean
}

const UnitView: React.FC<IProps> = ({name, isLock = false, isOngoing = false, isSelect = true}) => {

    return <div
        className={styles.unitName}
        data-is-lock={isLock}
        data-is-ongoing={isOngoing}
        data-is-select={isSelect}
    >
        {isOngoing ? [name, 'Ongoing'].join(' - ') : name}
    </div>;
};

export default UnitView;
