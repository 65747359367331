import React, {Component} from 'react';
import {FormInstance} from "antd/lib/form";
import ShortTextElement from "./Element/ShortTextElement";
import DropdownElement from "./Element/DropdownElement";
import CheckboxElement from "./Element/CheckboxElement";
import RadioElement from "./Element/RadioElement";
import HeaderElement from "./Element/HeaderElement";
import SignElement from "./Element/SignElement";
import styles from './style.module.scss';
import {Button, Form} from "antd";
import DateElement from "./Element/DateElement";
import TimeElement from "./Element/TimeElement";
import LongTextElement from "./Element/LongTextElement";
import {ELEMENT_BASIC, ELEMENT_DEFINED, ELEMENT_PAGE} from "../../pages/ChecklistPage/Element/constants";
import Divide from "./Element/Divide";
import WrapperStore from "../WrapperStore";
import {datetime} from "../../util/library/datetime";
import {IChecklist} from "../../util/varibles/interface";

const layout: any = {
    labelAlign: 'left',
};

interface IProps {
    checklist: IChecklist
    title?: string
    loading?: boolean
    isConfirm?: boolean

    cancel?: any

    onSubmit?(args: IChecklist): void
}

class Checklist extends Component<IProps> {
    static defaultProps = {
        isConfirm: true,
        title: ''
    }

    formRef: any = React.createRef<FormInstance>();
    formValuesDefault: any = {};
    signRule = '-';

    state = {
        signer: {}
    }

    componentDidMount() {
        this.setValueForForm()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props !== prevProps)
            this.setValueForForm()
    }

    setValueForForm = () => {
        if (this.formRef.current) {
            this.formRef.current.setFieldsValue(this.formValuesDefault);
            this.setState({signer: this.formValuesDefault[ELEMENT_DEFINED.SIGN]});
        }
    }

    renderForm = () => {
        const {checklist: {content, result}, title}: any = this.props;
        let form, data: any;

        try {
            form = JSON.parse(content);
            data = result ? JSON.parse(result) : {};
        } catch (error) {
            console.log(error)
        }

        if (!form || !data)
            return null;

        return form.map((item: any, index: number) => {
                let props = {...item, name: index}
                const {optionDefault = ""} = item.properties;
                switch (item.type) {
                    case ELEMENT_BASIC.SHORT_TEXT: {
                        this.formValuesDefault[index] = data[index];
                        return <ShortTextElement key={index} {...props}/>
                    }
                    case ELEMENT_BASIC.LONG_TEXT: {
                        this.formValuesDefault[index] = data[index];
                        return <LongTextElement key={index} {...props}/>
                    }
                    case ELEMENT_BASIC.DROPDOWN: {
                        const {multipleSelections} = item.properties;
                        if (data[index] !== undefined) {
                            this.formValuesDefault[index] = data[index];
                        } else if (optionDefault.length > 0 && optionDefault !== `""`)
                            this.formValuesDefault[index] = multipleSelections ? JSON.parse(optionDefault).map((option: any) => option.value) : JSON.parse(optionDefault).value;
                        return <DropdownElement key={index} {...props}/>
                    }
                    case ELEMENT_BASIC.CHECKBOX: {
                        if (data[index] !== undefined) {
                            this.formValuesDefault[index] = data[index];
                        } else if (optionDefault.length > 0 && optionDefault !== `""`)
                            this.formValuesDefault[index] = JSON.parse(optionDefault).map((option: any) => option.value);
                        return <CheckboxElement key={index} {...props}/>
                    }
                    case ELEMENT_BASIC.RADIO: {
                        if (data[index] !== undefined) {
                            this.formValuesDefault[index] = data[index];
                        } else if (optionDefault.length > 0 && optionDefault !== `""`)
                            this.formValuesDefault[index] = JSON.parse(optionDefault).value;
                        return <RadioElement key={index} {...props}/>
                    }
                    case ELEMENT_BASIC.HEADER: {
                        return <HeaderElement key={index} {...props}/>
                    }
                    case ELEMENT_BASIC.DATE: {
                        if (data[index] !== undefined)
                            this.formValuesDefault[index] = datetime(data[index]).value;
                        return <DateElement key={index} {...props}/>
                    }
                    case ELEMENT_BASIC.TIME: {
                        if (data[index] !== undefined)
                            this.formValuesDefault[index] = datetime(data[index]).value;
                        return <TimeElement key={index} {...props}/>
                    }
                    case ELEMENT_PAGE.DIVIDE: {
                        return <Divide key={index} {...props} />
                    }
                    case ELEMENT_DEFINED.SIGN: {
                        this.formValuesDefault[ELEMENT_DEFINED.SIGN] = data[ELEMENT_DEFINED.SIGN] || {};
                        props = {...props, title, signer: {...data[ELEMENT_DEFINED.SIGN] || {}, ...this.state.signer}}
                        return <WrapperStore key={index}>
                            <SignElement {...props} onClick={this.handleSign}/>
                        </WrapperStore>
                    }
                    default:
                        return null
                }
            }
        )
    }

    handleSign = (userType: any, note: string) => {
        const {signer}: any = this.state;
        signer[userType] = note;
        this.setState({signer})
    }

    handleSubmit = (values: any) => {
        const {checklist} = this.props;
        const {signer} = this.state;
        const params = {
            ...checklist,
            result: JSON.stringify({...values, [ELEMENT_DEFINED.SIGN]: signer}),
        }
        if (this.props.onSubmit)
            this.props.onSubmit(params);
    }

    render() {
        const {loading, isConfirm, cancel} = this.props
        return <Form
            {...layout}
            ref={this.formRef}
            name='checklist'
            className={styles.popup}
            onFinish={this.handleSubmit}
        >
            {this.renderForm()}
            {isConfirm && <div className={styles.footer}>
                {cancel}
                <Button className='bt-primary' htmlType='submit' loading={loading}>Confirm</Button>
            </div>}
        </Form>;
    }
}

export default Checklist;
