import React from 'react';
import styles from "../style.module.scss";
import {datetime} from "../../../../../../../../util/library/datetime";
import Icon, {ICON_NAME} from "../../../../../../../../components/Icon";
import {IPropsChild} from "./index";

const Traveling: React.FC<IPropsChild> = (props) => {
    const {start_time = 0, finish_time = 0, place} = props;
    return <div className={styles.traveling}>
        <div className='mr-2'>
            {[datetime(start_time).format('HH:mm'), `-- Traveling to ${place} --`, datetime(finish_time).format('HH:mm')].join(' ')}
        </div>
        <Icon icon={ICON_NAME.VESSEL_3}  style={{width: '50px', height: '30px'}}/>
    </div>
};

export default Traveling;
