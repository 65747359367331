import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {Select} from "antd";
import {deliveryUnits, initDeliveryType} from "../constants";
import {ACTION_TYPE} from "../../../../../../../util/varibles/constants";
import Menu from "../../../../../../../components/Menu";
import InputNumber from "../../../../../../../components/Basic/InputNumber";
import {cloneObj, renderOpts} from "../../../../../../../util/varibles/global";
import {getEditOp, useEditOp} from "../../../../../../../contexts/EditOperationContext";
import {getEditSubOp, useEditSubOp} from "../../../../../../../contexts/EditOperationContext/EditSubOperationProvider";
import {TOperation} from "../../../../../../../util/varibles/interface";
import {notify, NotifyCode} from "../../../../../../../util/varibles/message";

interface IInputValue {
    data: any

    onChange(value: { fish_amount: number, total_weight: number }): void

    onBlur(): void
}

const InputTons: React.FC<IInputValue> = ({data, onChange, onBlur}) => {
    const [avg_weight] = useEditSubOp(state => state.unit.avg_weight);
    const avgWeight = Math.max(avg_weight, 1);

    const handleChange = useCallback((value: number) => {
        const total_weight = Math.round(value * 1000000);
        const fish_amount = avg_weight !== -1 ? Math.round(total_weight / avgWeight) : 0;
        onChange({fish_amount, total_weight})
    }, [avg_weight, avgWeight, onChange]);


    return <InputNumber
        className='text-center'
        value={Math.round(data.total_weight / 1000) / 1000}
        onChange={handleChange}
        onBlur={onBlur}
    />
}

const InputAmount: React.FC<IInputValue> = ({data, onChange, onBlur}) => {
    const [avg_weight] = useEditSubOp(state => state.unit.avg_weight);
    const avgWeight = Math.max(avg_weight, 1);

    const handleChange = useCallback((value: number) => {
        const fish_amount = Math.round(value);
        const total_weight = Math.round(fish_amount * avgWeight);
        onChange({fish_amount, total_weight})
    }, [avgWeight, onChange]);

    return <InputNumber
        className='text-center'
        value={Math.round(data.fish_amount)}
        onChange={handleChange}
        onBlur={onBlur}
    />
}

const DeliveryType: React.FC<{
    data: any,
    opts: any[]
    onBlur: () => void
    onChange: (value: any) => void
    onChangeValue: (value: { fish_amount: number, total_weight: number }) => void
}> = (props) => {
    const {data, opts, onBlur, onChange, onChangeValue} = props;

    const {id, unit, avg_weight} = data;

    return <div className={styles.deliveryTypeControl}>
        <Select
            showSearch
            value={id}
            popupMatchSelectWidth={false}
            popupClassName={'no-scroll'}
            options={opts}
            onSelect={(...args) => onChange({
                id: args[1]?.value,
                name: args[1]?.label,
            })}
        >
        </Select>
        {unit === deliveryUnits[0].id
            ? <InputTons data={data} onChange={value => onChangeValue(value)} onBlur={onBlur}/>
            : <InputAmount data={data} onChange={value => onChangeValue(value)} onBlur={onBlur}/>}
        <Select
            value={unit || deliveryUnits[1].id}
            popupClassName={'no-scroll'}
            popupMatchSelectWidth={false}
            options={renderOpts(avg_weight !== -1 ? deliveryUnits : [deliveryUnits[0]])}
            onChange={(value: any) => onChange({unit: value})}
        />
    </div>
}

interface IProps {
    onChange(value: Partial<TOperation>, isCalculate?: boolean): void

    onDeleteOp(): void
}

const DeliveryTypes: React.FC<IProps> = ({onChange, onDeleteOp}) => {
    const [editMode] = useEditOp(state => state.editMode);
    const [delivery_types = []] = useEditSubOp(state => state.unit.delivery_types);
    const [options = {}] = useEditSubOp(state => state.delivery_types);
    const getSubOp = getEditSubOp();
    const getOp = getEditOp();

    const handleDelete = (indexDeliveryType: number) => {
        const {fish_amount} = delivery_types[indexDeliveryType];
        const {avg_weight} = getSubOp().unit;
        onChange({
            delivery_types: delivery_types.reduce((list: any, item: any, index: number) => {
                if (item.default) {
                    item.fish_amount += fish_amount;
                    item.total_weight = item.fish_amount * avg_weight
                    return [...list, item]
                } else if (index !== indexDeliveryType) {
                    return [...list, item]
                }
                return list;
            }, [])
        })
    }

    const handleSplit = () => {
        onChange({
            delivery_types: [...delivery_types, initDeliveryType]
        })
    }

    const handleUpdate = (value: any, index: number) => {
        const result = cloneObj(delivery_types);
        result.splice(index, 1, value)
        onChange({delivery_types: result})
    }

    const handleChangeValue = (value: { fish_amount: number, total_weight: number }, index: number) => {
        const {
            default: isDefault,
            fish_amount: oldFishAmount,
            total_weight: oldTotalWeight
        } = delivery_types[index];
        const {unit, op} = getSubOp();
        const {avg_weight} = unit;
        const result = cloneObj(delivery_types);
        let {fish_amount, total_weight} = value;
        if (isDefault) {
            // const key = getKeyOfUnit(getSubOp().unit);
            // const {vessel, units} = getOp();
            const {vessel} = getOp();
            const {capacity = 0} = vessel || {}
            // const {
            //     fish_amount: rootFishAmount,
            //     total_weight: rootTotalWeight
            // } = units[key];
            const {total_weight: opTotalWeight = 0} = op || {}
            if ((opTotalWeight - oldTotalWeight + total_weight) > capacity) {
                const subTotalWeight = capacity - opTotalWeight + oldTotalWeight;
                fish_amount = avg_weight < 0 ? 0 : Math.floor(subTotalWeight / avg_weight);
                total_weight = avg_weight < 0 ? subTotalWeight : fish_amount * avg_weight;
                notify.error(NotifyCode.E1)();
            } else {
                // if ((fish_amount - oldFishAmount) > Math.max(0, rootFishAmount) || (total_weight - oldTotalWeight) > rootTotalWeight)
                //     notify.warn(NotifyCode.W6)();
            }

            result.splice(index, 1, {...delivery_types[index], fish_amount, total_weight});
            onChange({delivery_types: result}, true);
        } else {
            const deliveryTypeDefault = delivery_types.find((item: any) => item.default);
            let {fish_amount: rootFishAmount = 0, total_weight: rootTotalWeight = 0} = deliveryTypeDefault || {};
            const diffFishAmount = fish_amount - oldFishAmount;
            const diffTotalWeight = total_weight - oldTotalWeight;
            if (diffFishAmount > rootFishAmount || diffTotalWeight > rootTotalWeight) {
                fish_amount = avg_weight < 0 ? 0 : Math.floor((oldFishAmount + rootTotalWeight) / avg_weight);
                total_weight = avg_weight < 0 ? (oldFishAmount + rootTotalWeight) : fish_amount * avg_weight;
                rootFishAmount = 0;
                rootTotalWeight = 0
            } else {
                rootFishAmount = rootFishAmount - diffFishAmount;
                rootTotalWeight = rootTotalWeight - diffTotalWeight;
            }
            onChange({
                delivery_types: delivery_types.map((item: any, i: number) => {
                    if (i === index) {
                        return {...item, fish_amount, total_weight}
                    } else if (item.default) {
                        return {...item, fish_amount: rootFishAmount, total_weight: rootTotalWeight};
                    } else {
                        return item
                    }
                })
            })
        }
    }

    const onBlur = () => {
        const {avg_weight} = getSubOp().unit;
        if (avg_weight > 0) {
            onChange({
                delivery_types: delivery_types.map((item: any) => ({
                    ...item,
                    total_weight: item.fish_amount * avg_weight
                }))
            }, true)
        }
    }

    const exclude = new Set(delivery_types.map((item: any) => item.id));
    const convertOpts = Object.keys(options).reduce((list: any, key: any) => [...list, {
        label: options[key].name,
        value: options[key].id,
        disabled: exclude.has(options[key].id)
    }], []).sort((a: any, b: any) => a.label.localeCompare(b.label));

    return <div className={styles['container_delivery_types']}>
        {delivery_types.map((item: any, index: number) =>
            <div key={index} className={styles.deliveryType}>
                <DeliveryType
                    data={item}
                    opts={convertOpts}
                    onChange={(value: any) => handleUpdate({...item, ...value}, index)}
                    onChangeValue={value => handleChangeValue(value, index)}
                    onBlur={onBlur}
                />
                <Menu
                    items={item.default ? [
                        ...(editMode === ACTION_TYPE.CREATE ? [{
                            key: 'delete',
                            label: <div onClick={onDeleteOp}><span>Delete unit</span></div>
                        }
                        ] : []), {
                            key: 'split',
                            label: <div onClick={handleSplit}><span>Split</span></div>
                        }
                    ] : [{
                        key: 'delete',
                        label: <div onClick={() => handleDelete(index)}><span>Delete sub delivery type</span></div>
                    }]}
                    placement="bottomRight"
                >
                    <div className='three-dot' onClick={e => e.preventDefault()}/>
                </Menu>
            </div>)}
    </div>;
};

export default DeliveryTypes;
