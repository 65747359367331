import {initialState} from "./constants";
import {createSlice} from "@reduxjs/toolkit";

const siteMonitoringSlice = createSlice({
    name: 'siteMonitoring',
    initialState,
    reducers: {
        getSitesRequest: (state) => {
            state.loading = true;
        },
        getSitesSuccess: (state, action) => {
            state.sites = action.payload
        },
        getSitesFailure: (state) => {
            state.loading = false;
        },
        updateSiteRequest: (state, action) => {
        }
    },
})

export const siteMonitoringActions = siteMonitoringSlice.actions

const siteMonitoringReducer = siteMonitoringSlice.reducer;

export default siteMonitoringReducer;

