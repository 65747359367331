import {cloneObj} from "../varibles/global";
import * as echarts from "echarts";
import {EChartsType} from "echarts";
import {datetime} from "./datetime";

export const colors = ['#2f7ed8', '#8bbc21', '#f28f43', '#c42525', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a']
const arrowImg = `<svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 20 20"><path d="M11 17V3.414l3.293 3.293a.999.999 0 1 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a.997.997 0 0 0 0 1.414 1 1 0 0 0 1.414 0L9 3.414V19a1 1 0 1 0 2 0" fill="#000"/></svg>`
const formatLabelXAxis = function (params: any) {
    return datetime(params.value).format('dddd, DD MMM, HH:mm');
}

export const chartUtil = {
    initLine: {
        smooth: true,
        showSymbol: false,
        type: 'line',
    },
    drawPlotLine: (value: string) => {
        return {
            axisPointer: {
                value,
                snap: true,
                label: {
                    show: true,
                    formatter: formatLabelXAxis,
                    backgroundColor: '#2F8ED5'
                },
                lineStyle: {
                    type: 'solid',
                    color: '#2F8ED5',
                    width: 2,
                },
                handle: {
                    show: true,
                    icon: "none"
                }
            },
        }
    },
    showTip: (chart: EChartsType, args: { index?: number, seriesIndex?: number }) => {
        chart.dispatchAction({
            type: 'showTip',
            seriesIndex: args.seriesIndex || 0,
            dataIndex: args.index,
        });
    },
    hideTip: (chart: EChartsType) => {
        chart.dispatchAction({type: 'hideTip'});
    },
    formatTimeTooltip: (opts: any = {}) => {
        return {
            trigger: opts.trigger || 'axis',
            axisPointer: {
                label: {
                    formatter: formatLabelXAxis,
                    ...opts.label || {},
                },
                ...opts.axisPointer || {},
            },
        }
    },
    formatTimeXAxis: (opts: any = {}) => {
        return {
            axisLabel: {
                ...opts,
                label: {
                    ...opts.label || {},
                    formatter: function (params: any) {
                        const seriesValue = (params.seriesData[0] || {}).value;
                        return (
                            params.value +
                            (seriesValue != null
                                ? '\n' + echarts.format.addCommas(seriesValue)
                                : '')
                        );
                    }
                },
            }
        }
    },
    convertNumberAxisLabel: (opts: any = {}) => {
        return {
            axisLabel: {
                ...opts,
                formatter: function (value: any) {
                    return Math.round(value * 100) / 100;
                }
            }
        }
    },
    addUnit: (unit: string, opts: any = {}) => {
        return {
            tooltip: {
                ...opts,
                valueFormatter: (value = '-') => value === '-' ? value : [value, unit].join(' ')
            }
        }
    },
    drawArrow: (icon: string, rotate?: number) => {
        if (typeof rotate !== "number")
            return {
                symbol: 'none'
            }

        return {
            symbolOffset: [0, -25],
            symbol: 'image://data:image/svg+xml;base64,' + icon,
            symbolSize: 18,
            symbolRotate: rotate
        }
    },
    generateArrowIcon: (color = "#000") => {
        return window.btoa(cloneObj(arrowImg).replace('fill="#000"', `fill="${color}"`))
    },
}