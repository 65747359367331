import React, {Component} from 'react';

class VesselComponent extends Component {
    render() {
        return <div>

        </div>;
    }
}

export default VesselComponent;
