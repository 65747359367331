import React, {Component} from 'react';
import {Button, Form, Input, Select} from 'antd';
import {connect} from "react-redux";
import {FORM_TYPES, ITarget, serverTypes} from "./constants";
import {FormInstance} from 'antd/lib/form';
import styles from "./style.module.scss";
import {configurationActions} from "./reducer";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};


interface IProps {
    target: ITarget

    addServer(payload: any): void

    updateServer(payload: any): void
}

interface IState {
    extraField: any
}

class FormServer extends Component<IProps, IState> {
    state = {
        extraField: <></>
    }

    formRef: any = React.createRef<FormInstance>();

    componentDidMount() {
        const {target: {data}} = this.props;
        if (data && this.formRef.current) {
            this.formRef.current.setFieldsValue(data)
            this.onChangeType(data.type)
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {target: {data}} = this.props;
        if (this.props !== prevProps && this.formRef.current) {
            this.formRef.current.resetFields()
            if (data) {
                this.formRef.current.setFieldsValue(data)
                this.onChangeType(data.type)
            }
        }
    }

    onChangeType = (value: string) => {
        switch (value) {
            case serverTypes[1]: {
                this.setState({
                    extraField: <>
                        <Form.Item
                            className='mb-0'
                            label="Update interval"
                            name="update_interval"
                        >
                            <Input/>
                        </Form.Item>
                    </>
                })
                return;
            }
            default:
                this.setState({extraField: <></>})
                return;
        }
    }

    onFinish = (values: any) => {
        const {target: {data, index}} = this.props;
        if (data) {
            this.props.addServer({values, index, formType: FORM_TYPES.SERVER});
        } else {
            this.props.addServer({values, formType: FORM_TYPES.SERVER});
        }
    };

    render() {
        const {Option} = Select;
        const {extraField} = this.state;
        const {target: {data}} = this.props;
        return <Form {...layout} ref={this.formRef} name="server" onFinish={this.onFinish}>
            <h5 className='mb-12'><b>{data ? `Update server: ${data.name}` : 'Create server'}</b></h5>
            <Form.Item className='mb-0' label="Id" name="id">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Name" name="name">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Type" name="type">
                <Select onChange={this.onChangeType}>
                    {serverTypes.map(item => <Option key={item} value={item}>{item}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item className='mb-0' label="Url" name="url">
                <Input/>
            </Form.Item>
            <Form.Item className='mb-0' label="Port" name="port">
                <Input/>
            </Form.Item>
            {extraField}
            <Form.Item className='mt-10' {...tailLayout}>
                <Button className={[styles['btn'], styles['effect01']].join(' ')} htmlType='submit'>Save</Button>
            </Form.Item>
        </Form>;
    }
}

export default connect(null, {
    addServer: configurationActions.addServer,
    updateServer: configurationActions.updateServer,
})(FormServer);

