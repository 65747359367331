import React from 'react';
import styles from './style.module.scss';
import {IPropsChildren} from "../../../../util/varibles/interface";

interface IProps extends IPropsChildren {
}

const Logo: React.FC<IProps> = ({children}) => {
    return <div className={styles.logo}>
        {children}
    </div>;
};

export default Logo;