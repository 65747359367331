import React, {useCallback, useEffect, useState} from 'react';
import {ISite} from "../../../../../util/varibles/interface";
import styles from "./style.module.scss";
import {Button, Dropdown, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {productionAreas, propContainer} from "../../../../../util/varibles/constants";
import ProductionArea from "./ProductionArea";
import Icon, {ICON_NAME} from "../../../../../components/Icon";

interface IProps {
    siteFilter: string[]
    prodAreaFilter: string[]
    sites: ISite[]

    updateSetting(params: { siteFilter: string[], prodAreaFilter: string[] }): void
}

const SiteFilter: React.FC<IProps> = (props) => {
    const {siteFilter, prodAreaFilter, sites, updateSetting} = props;

    const [visible, setVisible] = useState(false);
    const [group, setGroup] = useState<{ [productionAreaId: string]: ISite[] }>({});
    const [search, setSearch] = useState('');

    useEffect(() => {
        const data = sites.reduce((rs: any, item) => {
            const {prod_area = ''} = item;
            rs[prod_area] = [...rs[prod_area] || [], item]
            return rs;
        }, {})
        setGroup(data)
    }, [sites]);

    const handleVisibleChange = (visible: boolean) => setVisible(visible);

    const handleClear = () => {
        updateSetting({siteFilter: [], prodAreaFilter: []});
    }

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Escape' && search.length > 0) {
            setSearch('');
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }, [search]);

    const items = [
        {
            className: styles.wrapper,
            key: 'filter',
            disabled: true,
            label: <>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <div>Filter production area, site ({siteFilter.length}/{sites.length})</div>
                        <Input
                            placeholder='Search for site'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            suffix={search.length === 0 && <SearchOutlined/>}
                            allowClear
                        />
                    </div>
                    <div className='link' onClick={handleClear}>Clear all filter</div>
                </div>
                <div className={styles.productionAreas}>
                    {productionAreas.map(item => {
                        const {id, name} = item;
                        if (!group[id])
                            return null;

                        return <ProductionArea
                            key={id}
                            id={id}
                            prodAreaFilter={prodAreaFilter}
                            updateSetting={updateSetting}
                            name={name}
                            list={group[id]}
                            search={search.toLowerCase()}
                            siteFilter={siteFilter}
                        />
                    })}
                    <ProductionArea
                        key={null}
                        id={null}
                        prodAreaFilter={prodAreaFilter}
                        updateSetting={updateSetting}
                        list={group['null']}
                        search={search.toLowerCase()}
                        siteFilter={siteFilter}
                    />
                </div>
            </>
        }
    ]
    return <Dropdown
        {...propContainer}
        open={visible}
        trigger={['click']}
        onOpenChange={handleVisibleChange}
        menu={{items}}
        placement="bottom"
        arrow
        dropdownRender={(menus) => <>
            <div className={styles.layout} onClick={() => setVisible(false)}></div>
            {menus}
        </>}
    >
        <Button className='bt-ghost h-32' data-is-show={visible} disabled={true}>
            <Icon icon={ICON_NAME.FILTER}/>
            <span className='ml-5'>Filter</span>
        </Button>
    </Dropdown>;
};

export default SiteFilter;
