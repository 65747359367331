import React from 'react';
import ElementComponent from "./ElementComponent";
import {elementDefined} from "../../Element/constants";
import {IElement} from "../../constants";

interface IProps {
    onAdd(element: IElement, index: number | null): void
}

const DefinedElement: React.FC<IProps> = ({onAdd}) => {
    return <>
        {elementDefined.map(item => <ElementComponent
            {...item}
            key={item.id}
            onAdd={onAdd}
        />)}
    </>;
};

export default DefinedElement;
