import React from 'react';
import {aDayMillisecond} from "../../../../../util/varibles/constants";
import {HEIGHT_PER_DAY} from "../Operations/constants";
import styles from './style.module.scss';
import {usePlan} from "../../../../../contexts/PlanOperationContext/Plan";

interface IProps {
    count: number
    vesselId: string
    isAccessFull: boolean

    onMouseDown(time: number, e: any): void
}

const Days: React.FC<IProps> = ({vesselId, count, isAccessFull, onMouseDown}) => {
    const list: number[] = [];
    const [startPoint] = usePlan(state => state.startPoint)
    const style = {marginBottom: (HEIGHT_PER_DAY - 1) + 'px'};

    for (let i = 0; i <= count; i++) {
        const time = startPoint + (i * aDayMillisecond);
        list.push(time);
    }

    return <>
        {list.map(time => <div
            className={styles.day}
            style={style}
            key={time}
            data-date={time}
            data-access-full={isAccessFull}
            data-vessel-id={vesselId}
            onMouseDown={(e) => {
                if (e.button === 2) {
                    onMouseDown(time, e)
                }
            }}
        />)}
    </>;
};

export default Days;